import React, { useEffect, useRef, useState } from "react"
import * as C from "./style"
import { useDispatch, useSelector } from "react-redux"
import env from "env"
import toast from "react-hot-toast"
import { faTwitter, faDiscord } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Badge from "components/ui/badge"
import { faExternalLink, faQuestionCircle } from "@fortawesome/free-solid-svg-icons"
import Linkify from 'react-linkify'
import Button from "components/ui/button"
import { color } from "styles/theme"
import { Hex2Rgba, copyObject, formatDateLabel, getAvatar, getUserSigner, refreshCloudWalletBalance } from "utils/helpers"
import { useNavigate } from "react-router-dom"
import Input from "components/ui/input"
import ReactTooltip from "react-tooltip"
import Chart, { defaultChartData } from "./chart"
import { getIssuerAccountData } from "modules/solana/share/shareView"
import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js"
import { calculatePrice, computeTvl, formatPrice } from "modules/solana/share/helpers"
import BigNumber from "bignumber.js"
import { getIssuer, shareTransact } from "services/api/share"
import { getWalletSolBalance } from "modules/solana/wallet"
import { setCloudWalletBalance } from "services/slices/user"
import { IconConnectWallet } from "utils/icons"
import { displayCloudWallet, displayLoginPopup } from "utils/parts"
import { useWeb3Auth } from "hooks/useWeb3Auth"
import { buyShare, sellShare } from "modules/solana/share/shareTx"
import { setCurrentViewingShareUser } from "services/slices/data"
import { getShareError } from "modules/solana/share/shareError"

var refreshInterval: any = null

const shareAccountRentFee = 0.00159384
const transactFee = 0.00001
const issuerFeePercent = 5
const platformFeePercen = 5

const Share = () => {

    const user = useSelector((state: any) => state.user.user)
    const viewingUser = useSelector((state: any) => state.data.currentViewingShareUser)
    const navigate = useNavigate()
    const balance = useSelector((state: any) => state.user.cloudWalletBalance)

    const [action, setAction] = useState("buy")
    const [loaded, setLoaded] = useState(false)
    const [price, setPrice] = useState({ buy: { lamports: "", number: "" }, sell: { lamports: "", number: "" } })
    const [totalShares, setTotalShares] = useState("")
    const [tvl, setTvl] = useState("")
    const [ownedShares, setOwnedShares] = useState("0")
    const [holders, setHolders] = useState("")
    const [issuerMath, setIssuerMath] = useState({ a: "", b: "", d: "" })
    const [change, setChange] = useState<any>({
        value: "0",
        isPositive: null
    })
    const [hasHoldingAccount, setHasHoldingAccount] = useState(false)

    const [amount, setAmount] = useState<any>(0)
    const [slippage, setSlippage] = useState<any>("")
    const [totalPrice, setTotalPrice] = useState("")
    const [priceImpact, setPriceImpact] = useState("")
    const [fees, setFees] = useState("0.00")

    const [actionLoading, setActionLoading] = useState(false)
    const [notEnoughBalanceError, setNotEnoughBalanceError] = useState(false)
    const [customTxError, setCustomTxError] = useState("")

    const amountInput = useRef<any>(null)
    const slippageInput = useRef<any>(null)

    const dispatch = useDispatch()

    const { provider } = useWeb3Auth()
    const [chartData, setChartData] = useState<any>(defaultChartData)

    const viewingUserRef = useRef(viewingUser)
    const loadedRef = useRef(loaded)

    useEffect(() => {

        refreshOnChainData()

        refreshInterval = setInterval(() => {
            refreshOnChainData()
        }, 10000)

        return () => {
            clearInterval(refreshInterval)
        }

    }, [])

    useEffect(() => {
        viewingUserRef.current = viewingUser
    }, [viewingUser])

    useEffect(() => {
        loadedRef.current = loaded
    }, [loaded])

    const refreshOnChainData = async () => {

        const loaded = loadedRef.current
        var viewingUser = viewingUserRef.current

        if (loaded) {
            let refreshedIssuer = await getIssuer(viewingUser.id)
            dispatch(setCurrentViewingShareUser(refreshedIssuer.data.user))
        }

        viewingUser = viewingUserRef.current

        getIssuerAccountData(new PublicKey(viewingUser.shareAddress), user ? new PublicKey(user.cloudWallet) : undefined).then((res: any) => {
            const data = res.account

            let totalShares = new BigNumber(data.totalShares.toString())
            setTotalShares(totalShares.toString())
            setIssuerMath({
                a: data.a.toString(),
                b: data.b.toString(),
                d: data.d.toString()
            })

            let buy = totalShares.eq(0) ? new BigNumber(data.d.toString()) : calculatePrice(
                new BigNumber(data.totalShares.toString()),
                new BigNumber(data.d.toString()),
            )
            let sell = totalShares.eq(0) ? new BigNumber(0) : calculatePrice(
                new BigNumber(data.totalShares.toString()).minus(1),
                new BigNumber(data.d.toString()),
            )

            setPrice({
                buy: { lamports: buy.toString(), number: formatPrice(buy.div(LAMPORTS_PER_SOL)) },
                sell: { lamports: sell.toString(), number: formatPrice(sell.div(LAMPORTS_PER_SOL)) }
            })

            let tvl = formatPrice(computeTvl(
                new BigNumber(data.totalShares.toString()),
                new BigNumber(data.d.toString()),
            ).div(LAMPORTS_PER_SOL))

            setTvl(tvl)

            setHolders(data.holders.toString())

            var change = "0.00"
            var isPositive = null
            var currentPrice = buy
            var oldestPrice: any = null;
            var oldestItem: any = null;

            var date24HoursAgo = new Date().getTime() - 24 * 60 * 60 * 1000;

            for (let i = 0; i < viewingUser.shareLogs.length; i++) {
                let logDate = new Date(viewingUser.shareLogs[i].createdAt).getTime();
                if (logDate > date24HoursAgo) {
                    // If oldestPrice hasn't been set yet or current log date is older than the previously saved log date
                    if (!oldestItem || logDate < new Date(oldestItem.createdAt).getTime()) {
                        oldestPrice = new BigNumber(viewingUser.shareLogs[i].price);
                        oldestItem = viewingUser.shareLogs[i];
                    }
                }
            }

            if (!oldestPrice) oldestPrice = currentPrice;

            if (currentPrice.gt(oldestPrice)) {
                change = currentPrice.minus(oldestPrice).div(oldestPrice).multipliedBy(100).toFixed(2)
                isPositive = true
            } else if (currentPrice.lt(oldestPrice)) {
                change = oldestPrice.minus(currentPrice).div(oldestPrice).multipliedBy(100).toFixed(2)
                isPositive = false
            }

            let _change = {
                value: change,
                isPositive
            }

            setChange(_change)

            //var shareLogsDisordered = copyObject(viewingUser.shareLogs)
            var shareLogs = viewingUser.shareLogs.slice();

            // Sort the logs
            shareLogs.sort((a: any, b: any) => Date.parse(a.createdAt) - Date.parse(b.createdAt));

            const now = new Date();
            let oneDayAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);

            let filteredLogs = shareLogs.filter((log: any) => new Date(log.createdAt) >= oneDayAgo);

            // If no data within the last 24h, try to get the most recent 24h of data
            while (filteredLogs.length === 0 && shareLogs.length > 0) {
                oneDayAgo = new Date(shareLogs[shareLogs.length - 1].createdAt);
                const twoDaysAgo = new Date(oneDayAgo.getTime() - 24 * 60 * 60 * 1000);

                filteredLogs = shareLogs.filter((log: any) => new Date(log.createdAt) >= twoDaysAgo && new Date(log.createdAt) <= oneDayAgo);
            }

            const latestLoggedPrice = filteredLogs[filteredLogs.length - 1]?.price;

            if (!latestLoggedPrice /*|| latestLoggedPrice !== buy.toString()*/) {
                filteredLogs.push({
                    createdAt: now.toISOString(),
                    price: buy.toString()
                });
            }

            // Compute dates and prices once, then use them for creating chart data
            const dates = filteredLogs.map((item: any) => new Date(item.createdAt));
            const prices = filteredLogs.map((item: any) => new BigNumber(item.price).div(LAMPORTS_PER_SOL).toNumber());

            const chartData = {
                labels: dates.map((date: any) => formatDateLabel(date)),
                datasets: [
                    {
                        label: 'Price',
                        data: prices,
                        fill: false,
                        borderColor: color.primary,
                        backgroundColor: color.primary,
                        tension: 0.1
                    }
                ]
            };



            setChartData(chartData)

            if (res.holdingAccount !== null) {
                setOwnedShares(res.holdingAccount.amount.toString())
                setHasHoldingAccount(true)
            }

            setLoaded(true)
            

        }).catch((err: any) => {
            console.log(err)
        }).finally(() => {
        })
    }

    const onAmountChange = (e: any) => {
        setAmount(e.target.value)
    }

    const onSlippageChange = (e: any) => {
        setSlippage(e.target.value)
    }

    useEffect(() => {

        if (amount !== 0 && amount !== "") {
            if (action === "sell" && amount > totalShares) {
                return;
            }
            let total = new BigNumber(0)
            let simulatedPrice = new BigNumber(price.buy.lamports)
            let simulatedShares = new BigNumber(totalShares)
            for (let i = 0; i < amount; i++) {
                if (action === 'buy')
                    total = total.plus(simulatedPrice)

                simulatedShares = action === 'buy' ? simulatedShares.plus(1) : simulatedShares.minus(1)

                if (action === 'buy' || (action === 'sell' && !simulatedShares.eq(0))) {
                    simulatedPrice = calculatePrice(
                        simulatedShares,
                        new BigNumber(issuerMath.d),
                    )
                } else {
                    simulatedPrice = new BigNumber(issuerMath.d)
                }

                if (action === 'sell')
                    total = total.plus(simulatedPrice)

            }

            if (action === 'sell') {
                const issuerFee = total.div(100).times(issuerFeePercent)
                const platformFee = total.div(100).times(platformFeePercen)
                total = total.minus(issuerFee).minus(platformFee)
                setFees(new BigNumber(issuerFee).plus(new BigNumber(platformFee)).div(LAMPORTS_PER_SOL).toFixed(4))
            }

            setTotalPrice(formatPrice(total.div(LAMPORTS_PER_SOL)))

            let priceImpact = new BigNumber(0)

            if (action === 'buy') {
                priceImpact = simulatedPrice.minus(price.buy.lamports).div(price.buy.lamports).times(100)
            } else {
                priceImpact = new BigNumber(price.buy.lamports).minus(simulatedPrice).div(price.buy.lamports).times(100)
            }

            let pi = priceImpact.toFixed(2)

            if (pi !== "0.00") {
                setPriceImpact((action === 'buy' ? '+' : '-') + priceImpact.toFixed(2))
            } else {
                setPriceImpact(priceImpact.toFixed(2))
            }

            if (action === 'buy' && !hasHoldingAccount) {
                setFees(new BigNumber(shareAccountRentFee).plus(new BigNumber(transactFee)).toFixed(4))
            } else if (action === 'buy') {
                setFees(new BigNumber(transactFee).toFixed(5))
            }
        }
        else {
            setTotalPrice("0.00")
            setPriceImpact("0.00")
            setFees("0.00")
        }


    }, [amount, action])

    const transact = async () => {

        if (!user){
            return displayLoginPopup(true)
        }

        setCustomTxError("")
        setNotEnoughBalanceError(false)

        if (amount === 0 || amount === "" || isNaN(amount)) {
            toast.error("Please enter an amount")
            return
        }

        if (parseInt(amount) < 0)
            return toast.error("Amount must be a positive number")
        if (!isNaN(slippage)) {
            if (slippage < 0)
                return toast.error("Slippage must be a positive number")
        }

        if (actionLoading) {
            return
        }

        setActionLoading(true)

        const cloudWallet = await getUserSigner(provider)

        const func = action === "buy" ? buyShare : sellShare

        func(
            new PublicKey(viewingUser.shareAddress),
            cloudWallet,
            amount,
            (slippage === "") ? null : slippage,
            totalShares
        ).then((res: any) => {

            toast.success("Transaction successful")
            amountInput.current.value = ""
            slippageInput.current.value = "0"

            setAmount(0)
            setSlippage("0")
        }).catch((err: any) => {
            toast.error(err)
            if (err === getShareError("0x1"))
                setNotEnoughBalanceError(true)
            else
                setCustomTxError(err)
        }).finally(() => {
            setActionLoading(false)
            refreshOnChainData()
            refreshCloudWalletBalance()
        })

    }


    useEffect(() => {
        ReactTooltip.rebuild()
        ReactTooltip.hide()
    }, [action])

    const copyDiscord = () => {

        if (viewingUser.discord) {
            navigator.clipboard.writeText(viewingUser.discord)
            toast.success("Copied to clipboard")
        }
    }

    const closePage = () => {
        navigate("/app")
    }



    return (
        <C.Share>
            <C.ShareLeft>
                <C.User bg={viewingUser.cover ? env.API.CDN + "covers/" + viewingUser.cover : ""}>
                    <C.Avatar>
                        <img src={getAvatar(viewingUser.avatar)} alt="avatar" />
                    </C.Avatar>
                    <C.UserName>
                        <C.Name>{viewingUser.username}</C.Name>
                        {viewingUser.status && (
                            <C.Status> {viewingUser.status}</C.Status>
                        )}
                    </C.UserName>
                </C.User>


                <C.ShareLeftContainer>

                    
                    <C.ShareChart>
                    <C.Title>
                        Chart
                    </C.Title>
                        <Chart data={chartData} />
                    </C.ShareChart>


                    {(viewingUser.twitter || viewingUser.discord) && (
                        <>
                            <C.Title>
                                Social
                            </C.Title>
                            <C.Social>
                                {viewingUser.twitter && (
                                    <a data-tip="Go to Twitter Account" href={"https://twitter.com/" + viewingUser.twitter} target="_blank" >
                                        <FontAwesomeIcon icon={faTwitter} />
                                    </a>
                                )}
                                {viewingUser.discord && (
                                    <div onClick={copyDiscord} data-tip="Copy to clipboard">
                                        <FontAwesomeIcon icon={faDiscord} />
                                    </div>
                                )}
                            </C.Social>
                        </>
                    )}
                    {viewingUser.badges.length > 0 && (
                        <>
                            <C.Title>
                                Badges
                            </C.Title>
                            <C.Badges>
                                {viewingUser.badges.map((badge: any, index: any) => (
                                    <Badge type={badge} key={index} />
                                ))}
                            </C.Badges>
                        </>
                    )}

                    {viewingUser.description && (
                        <>
                            <C.Title>
                                Bio
                            </C.Title>
                            <C.Bio>
                                <Linkify
                                    componentDecorator={(decoratedHref, decoratedText, key) => (
                                        <a className="extlink" target="blank" href={decoratedHref} key={key}>
                                            {decoratedText} <FontAwesomeIcon style={{ fontSize: 10 }} icon={faExternalLink} />
                                        </a>
                                    )}
                                >
                                    {viewingUser.description}
                                </Linkify>
                            </C.Bio>
                        </>
                    )}

                    {((viewingUser.collections !== null && viewingUser.collections.length > 0) || (viewingUser.tokens !== null && viewingUser.tokens.length > 0)) && (
                        <C.Expand>
                            <Button link="routerlink" to={"/app/profile/" + viewingUser.id} block="true" theme="gradient-border-transparent-bg-on-hover" bg={color.secondary} color={color.white}>Nfts & Tokens</Button>
                        </C.Expand>
                    )}

                </C.ShareLeftContainer>
            </C.ShareLeft>
            <C.ShareRight>

                <C.Header>
                    {user && (
                        <C.Wallet onClick={() => displayCloudWallet({ status: true })}>
                            <IconConnectWallet />  {balance} SOL
                        </C.Wallet>
                    )}

                    {!user && (<div></div>)}
                    <C.Close onClick={closePage}>
                        &times;
                    </C.Close>
                </C.Header>




                <C.User bg={viewingUser.cover ? env.API.CDN + "covers/" + viewingUser.cover : ""} mobile="true">
                    <C.Avatar>
                        <img src={getAvatar(viewingUser.avatar)} alt="avatar" />
                    </C.Avatar>
                    <C.UserName>
                        <C.Name>{viewingUser.username}</C.Name>
                        {viewingUser.status && (
                            <C.Status> {viewingUser.status}</C.Status>
                        )}
                    </C.UserName>
                </C.User>


                {loaded && (
                    <C.ShareDetails>
                        <C.ShareDetail>
                            <C.ShareDetailTitle>You Own</C.ShareDetailTitle>
                            <C.ShareDetailNumber>{ownedShares}</C.ShareDetailNumber>
                        </C.ShareDetail>
                        <C.ShareDetail>
                            <C.ShareDetailTitle>Holders</C.ShareDetailTitle>
                            <C.ShareDetailNumber>{holders}</C.ShareDetailNumber>
                        </C.ShareDetail>
                        <C.ShareDetail>
                            <C.ShareDetailTitle>Keys Issued</C.ShareDetailTitle>
                            <C.ShareDetailNumber>{totalShares}</C.ShareDetailNumber>
                        </C.ShareDetail>
                        <C.ShareDetail>
                            <C.ShareDetailTitle>TVL</C.ShareDetailTitle>
                            <C.ShareDetailNumber>{tvl} SOL</C.ShareDetailNumber>
                        </C.ShareDetail>
                    </C.ShareDetails>
                )}

                {!loaded && (
                    <C.ShareDetails>
                        <C.ShareDetailSkeleton>
                            <C.Skeleton></C.Skeleton>
                            <C.Skeleton></C.Skeleton>
                        </C.ShareDetailSkeleton>
                        <C.ShareDetailSkeleton>
                            <C.Skeleton></C.Skeleton>
                            <C.Skeleton></C.Skeleton>
                        </C.ShareDetailSkeleton>
                        <C.ShareDetailSkeleton>
                            <C.Skeleton></C.Skeleton>
                            <C.Skeleton></C.Skeleton>
                        </C.ShareDetailSkeleton>
                        <C.ShareDetailSkeleton>
                            <C.Skeleton></C.Skeleton>
                            <C.Skeleton></C.Skeleton>
                        </C.ShareDetailSkeleton>
                    </C.ShareDetails>
                )}

                <C.MobileWallet>
                    <C.Wallet onClick={() => displayCloudWallet({ status: true })}>
                        <IconConnectWallet /> <span>Balance:</span> {balance} SOL
                    </C.Wallet>
                </C.MobileWallet>

                <C.Transaction>
                    <C.TransactionHeader>
                        <C.TransactionTypes>
                            <C.TransactionType isactive={action === "buy" ? "true" : "false"} onClick={() => setAction("buy")}>
                                Buy
                            </C.TransactionType>
                            <C.TransactionType isactive={action === "sell" ? "true" : "false"} onClick={() => setAction("sell")}>
                                Sell
                            </C.TransactionType>
                        </C.TransactionTypes>
                        {loaded && (
                            <C.TransactionPricesWrapper>
                                <C.TransactionPricesChange>
                                    24h Change: <C.TransactionPricesChangeValue ispositive={change.isPositive === null ? "null" : (change.isPositive === true ? "true" : "false")}>
                                        {change.isPositive === null ? "" : (change.isPositive === true ? "+" : "-")}{change.value}%
                                    </C.TransactionPricesChangeValue>
                                </C.TransactionPricesChange>
                                <C.TransactionPrices>
                                    <C.TransactionPrice>
                                        <span>Buy:</span> {price.buy.number} <img src="/images/chains/solana-icon.png" alt="solana" />
                                    </C.TransactionPrice>
                                    <C.TransactionPriceSeperator />
                                    <C.TransactionPrice>
                                        {totalShares === "0" && (
                                            <>
                                                <span>Sell:</span> &minus;
                                            </>
                                        )}
                                        {totalShares !== "0" && (
                                            <>
                                                <span>Sell:</span> {price.sell.number} <img src="/images/chains/solana-icon.png" alt="solana" />
                                            </>
                                        )}

                                    </C.TransactionPrice>
                                </C.TransactionPrices>
                            </C.TransactionPricesWrapper>
                        )}
                        {!loaded && (
                            <C.TransactionPricesSkeleton>
                                <C.Skeleton></C.Skeleton>
                                <C.TransactionPricesSkeleton2>
                                    <C.Skeleton></C.Skeleton>
                                    <C.TransactionPricesSkeleton2Seperator />
                                    <C.Skeleton></C.Skeleton>
                                </C.TransactionPricesSkeleton2>

                            </C.TransactionPricesSkeleton>
                        )}
                    </C.TransactionHeader>

                    <C.TransactionForm>
                        <C.TransactionFormAmountInput>
                            <label>Amount of keys</label>
                            <Input type="number" step="1" min="0" placeholder="Amount" onChange={onAmountChange} xref={amountInput} disabled={!loaded} />
                        </C.TransactionFormAmountInput>
                        <C.TransactionFormSlippageInput>
                            <label>Slippage <FontAwesomeIcon icon={faQuestionCircle} data-tip="Maximum shares issued impact your willing to accept" /></label>
                            <Input type="number" step="1" min="0" max="100" placeholder="Slippage" defaultValue="" onChange={onSlippageChange} xref={slippageInput} disabled={!loaded} />
                        </C.TransactionFormSlippageInput>
                    </C.TransactionForm>

                    <C.TransactionFormButton>
                        <Button block="true" theme="gradient" bg={Hex2Rgba(color.white, .05)} color={color.white} onClick={() => transact()} loading={actionLoading ? "true" : "false"} disabled={actionLoading}>
                            {action === "buy" ? "Buy" : "Sell"}
                        </Button>
                    </C.TransactionFormButton>

                    {notEnoughBalanceError && (
                        <C.TxError>
                            <C.TxErrorText>
                                Not enough balance.{" "}
                                <C.TxErrorLink onClick={() => displayCloudWallet({ status: true })}>
                                    Deposit SOL
                                </C.TxErrorLink>
                            </C.TxErrorText>
                        </C.TxError>
                    )}

                    {customTxError && (
                        <C.TxError>
                            <C.TxErrorText>
                                {customTxError}
                            </C.TxErrorText>
                        </C.TxError>
                    )}

                    <C.TransactionDetails>
                        <C.TransactionDetail>
                            <C.TransactionDetailTitle>Total {action === "buy" ? "Cost" : "Received"}</C.TransactionDetailTitle>
                            {loaded && (
                                <C.TransactionDetailValue highlight="true">{totalPrice} <img src="/images/chains/solana-icon.png" alt="solana" /></C.TransactionDetailValue>
                            )}
                            {!loaded && (
                                <C.TransactionDetailValueSkeleton>
                                    <C.Skeleton></C.Skeleton>
                                </C.TransactionDetailValueSkeleton>
                            )}
                        </C.TransactionDetail>
                        <C.TransactionDetail>
                            <C.TransactionDetailTitle>Price Impact</C.TransactionDetailTitle>
                            {loaded && (
                                <C.TransactionDetailValue>{priceImpact}%</C.TransactionDetailValue>
                            )}
                            {!loaded && (
                                <C.TransactionDetailValueSkeleton>
                                    <C.Skeleton></C.Skeleton>
                                </C.TransactionDetailValueSkeleton>
                            )}
                        </C.TransactionDetail>
                        <C.TransactionDetail>
                            <C.TransactionDetailTitle>
                                Fees
                                {action === 'sell' && (
                                    <FontAwesomeIcon icon={faQuestionCircle} data-tip="5% platform and 5% user fee" />
                                )}
                            </C.TransactionDetailTitle>
                            {loaded && (
                                <C.TransactionDetailValue>{fees} <img src="/images/chains/solana-icon.png" alt="solana" /></C.TransactionDetailValue>
                            )}
                            {!loaded && (
                                <C.TransactionDetailValueSkeleton>
                                    <C.Skeleton></C.Skeleton>
                                </C.TransactionDetailValueSkeleton>
                            )}
                        </C.TransactionDetail>
                    </C.TransactionDetails>
                </C.Transaction>

            </C.ShareRight>
        </C.Share>
    )

}


export default Share