import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CHAIN_NAMESPACES, IProvider } from '@web3auth/base';
import { Web3AuthNoModal } from '@web3auth/no-modal';
import { OpenloginAdapter } from '@web3auth/openlogin-adapter';
import { SolanaPrivateKeyProvider } from '@web3auth/solana-provider';
import env from 'env';
import { useState, useEffect, createContext, useContext } from 'react';
import styled from 'styled-components';

const Web3AuthContext = createContext<{
    web3auth: Web3AuthNoModal | null,
    provider: IProvider | null,
    connected: boolean,
    setWeb3auth: (web3auth: Web3AuthNoModal | null) => void,
    setProvider: (provider: IProvider | null) => void,
    init: () => void
}>({
    web3auth: null,
    provider: null,
    connected: false,
    setWeb3auth: (_: Web3AuthNoModal | null) => { },
    setProvider: (_: IProvider | null) => { },
    init: () => { }
})

const LoadingStyled = styled.div`
    display: flex;
    width: 100wh;
    height: 100vh;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    background-color: #0A0A0B;
`

const Web3AuthProvider = ({ children }: any) => {

    const [web3auth, setWeb3auth] = useState<Web3AuthNoModal | null>(null);
    const [provider, setProvider] = useState<IProvider | null>(null);
    const [connected, setConnected] = useState(false)

    const [loaded, setLoaded] = useState(false)

    const init = async () => {
        try {
            const chainConfig = {
                chainNamespace: CHAIN_NAMESPACES.SOLANA,
                chainId: env.WEB3AUTH.chainId, // Please use 0x1 for Mainnet, 0x2 for Testnet, 0x3 for Devnet
                rpcTarget: env.WEB3AUTH.rpcTarget,
                displayName: env.WEB3AUTH.displayName,
                blockExplorer: env.WEB3AUTH.blockExplorer,
                ticker: env.WEB3AUTH.ticker,
                tickerName: env.WEB3AUTH.tickerName,
            };

            const web3auth = new Web3AuthNoModal({
                clientId: env.WEB3AUTH.CLIENT_ID,
                chainConfig,
                web3AuthNetwork: env.WEB3AUTH.web3AuthNetwork as any,
            });

            setWeb3auth(web3auth)

            const privateKeyProvider = new SolanaPrivateKeyProvider({
                config: { chainConfig },
            });

            const openloginAdapter = new OpenloginAdapter({
                privateKeyProvider,
                adapterSettings: {
                    uxMode: "redirect",
                    loginConfig: {
                        jwt: {
                            verifier: env.WEB3AUTH.loginConfig.verifier, // Pass the Verifier name here
                            typeOfLogin: env.WEB3AUTH.loginConfig.typeOfLogin as any, // Pass on the login provider of the verifier you've created
                            clientId: env.WEB3AUTH.loginConfig.clientId, // Pass on the Auth0 `Client ID` here
                        },
                    },
                },
            });

            web3auth.configureAdapter(openloginAdapter);
            // Initialize
            await web3auth.init();

            setProvider(web3auth.provider)

            if (web3auth.connected) {
                setConnected(true)
            }
        } catch (e) {
            console.log(e)
        } finally {
            setLoaded(true)
        }
    }

    useEffect(() => {
        init()
    }, [])

    if (!loaded)
        return (<LoadingStyled>
            <FontAwesomeIcon icon={faSpinner} spin />
        </LoadingStyled>)

    return (
        <Web3AuthContext.Provider value={{ web3auth, provider, connected, setWeb3auth, setProvider, init }}>
            {children}
        </Web3AuthContext.Provider>
    )

}

const useWeb3Auth = () => {

    const { web3auth, provider, connected, setWeb3auth, setProvider, init } = useContext(Web3AuthContext);

    return { web3auth, provider, connected, setWeb3auth, setProvider, init }

}

export { Web3AuthProvider, useWeb3Auth }