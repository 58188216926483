import styled from "styled-components"
import { Getcolor } from "styles/theme"

export const Badge = styled.div<any>`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 62px;
    border: 2px solid ${props => Getcolor(props.background)};
    background-color: ${props => Getcolor(props.background)};
    color: ${props => Getcolor(props.textColor)};

    &:not(:last-child) {
        margin-right: 10px;
    }

    padding: 4px 8px;
    font-size: 12px;
    font-weight: 500;
`

export const BadgeIcon = styled.div`
    margin-right: 5px;
`

export const BadgeText = styled.div`

`