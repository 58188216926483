import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"


export const LoginNotification = styled.div`
    position: fixed;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;

    border-radius: 24px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    color: ${Hex2Rgba(color.white, .64)};
    cursor: default;
    user-select: none;


    background-color: ${Hex2Rgba("#000000", .32)};
    padding:4px 16px;

    & span{
        /*background: -webkit-linear-gradient(180deg, ${color.gradient1.start}, ${color.gradient1.middle}, ${color.gradient1.end});
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;*/
        color: ${color.primary};
        cursor: pointer;
        margin:0 8px;
        font-weight: 500;
        line-height: 1.2;
        border-bottom: 1px solid ${color.primary};
    } 

    & > svg{
        font-size:14px;
        width:14px;
        height:14px;
        cursor: pointer;
    }

`