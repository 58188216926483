import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"

export const MobilePage = styled.div`
    backdrop-filter: blur(20px);
    background: ${Hex2Rgba(color.bg1, .64)};
    position:fixed;
    top:0px;
    height:calc(100% - 66px);
    overflow:hidden;
    z-index: 10;
    width:100%;
`

export const PageHeader = styled.div`
    display: flex;
    align-items: center;
    cursor:default;
    user-select:none;display:flex;
    padding-left:10px;
    height:45px;
`

export const PageTitle = styled.div`
    font-size:14px;
    display:flex;
`

export const PageClose = styled.div`
margin-left: auto;
    cursor: pointer;
    font-size: 24px;
    padding:0px 20px;
    padding-right:10px;
`

export const PageTitleIcon = styled.div`
    & svg{
        width:20px;
        height:20px;
        color: ${Hex2Rgba(color.white, .5)};
    }
    margin-right:10px;
`

export const PageTitleText = styled.div`

`

export const PageBody = styled.div`
    width:100%;
    flex:1;
    display:flex;
    flex-direction:column;
    transition:none;
    padding:8px 16px 16px 16px;
    overflow-y: auto;
    overflow-x: hidden;
    height:calc(100% - 45px);
`