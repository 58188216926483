import { EncryptStorage } from 'encrypt-storage'
import env from 'env'

export const encryptStorage = new EncryptStorage(env.SECRET.LOCAL_STORAGE)

export const getLocalData = (key: string) => {
    try {
        return encryptStorage.getItem(key)
    } catch (e) {
        return null
    }
}

export const setLocalData = (key: string, value: any) => {
    return encryptStorage.setItem(key, value)
}

export const removeLocalData = (key: string) => {
    return encryptStorage.removeItem(key)
}