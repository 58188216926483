import React, { useEffect } from "react"
import { displayCollections, closeAllPages, displaySettings, displayProfile, MainParts, displayWalletConnectPopup, displayLoginPopup } from "utils/parts"
import { IconCollections, IconShuffle, IconWhitelist, IconCog, IconChat } from "utils/icons"
import * as C from "./style"
import ReactTooltip from "react-tooltip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSignOut, faChartSimple, faImages, faSignIn, faCog, faGlobe, faS, faRocket, faComments } from "@fortawesome/free-solid-svg-icons"
import { faUser } from "@fortawesome/free-regular-svg-icons"
import { useSelector } from "react-redux"
import toast from 'react-hot-toast'

const AppBar = () => {

    const isNavActive = (...path: any) => {

        let location = window.location.pathname

        // if one of the paths is active, return active
        for (let i = 0; i < path.length; i++) {
            //remove last slash
            if (path[i][path[i].length - 1] === "/")
                path[i] = path[i].slice(0, -1)

            if (location[location.length - 1] === "/")
                location = location.slice(0, -1)

            if (location === path[i])
                return "active"

            //if path contains * then it is a wildcard
            if (path[i].includes("*")) {
                path[i] = path[i].replace("*", "")
                if (location.includes(path[i]))
                    return "active"
            }
        }

        return ""
    }

    const currentPage = useSelector((state: any) => state.data.currentPage)
    const user = useSelector((state: any) => state.user.user)

    return (
        <C.AppBar>
            <C.AppBarItemLink className={isNavActive("/app")} to="/app">
                <C.AppBarIcon>
                    <FontAwesomeIcon icon={faGlobe} />
                </C.AppBarIcon>
                <C.AppBarItemText>Home</C.AppBarItemText>
            </C.AppBarItemLink>
            <C.AppBarItemLink className={isNavActive("/app/chat", "/app/chat/*")} to="/app/chat">
                <C.AppBarIcon>
                    <IconChat />
                </C.AppBarIcon>
                <C.AppBarItemText>Chat</C.AppBarItemText>
            </C.AppBarItemLink>
            <C.AppBarItemLink className={isNavActive("/app/shares", "/app/share/*")} to="/app/shares">
                <C.AppBarIcon>
                    <IconCollections />
                </C.AppBarIcon>
                <C.AppBarItemText>Keys</C.AppBarItemText>
            </C.AppBarItemLink>
            
            {/*<C.AppBarItemA  target="_blank" href="https://shuffle.pub/" >
                <C.AppBarIcon>
                    <IconShuffle />
                </C.AppBarIcon>
                <C.AppBarItemText>Shuffle</C.AppBarItemText>
    </C.AppBarItemA>*/}
            {/*<C.AppBarItemLink className={isNavActive("/app/whitelist", "/app/whitelist/*")} to="/app/launchmap">
                <C.AppBarIcon>
                    <FontAwesomeIcon icon={faRocket} />
                </C.AppBarIcon>
                <C.AppBarItemText>Launchmap</C.AppBarItemText>
    </C.AppBarItemLink>*/}

            {user && (
                <>
                    <C.AppBarItemLink className={isNavActive("/app/settings")} to="/app/settings">
                        <C.AppBarIcon>
                            <IconCog />
                        </C.AppBarIcon>
                        <C.AppBarItemText>Settings</C.AppBarItemText>
                    </C.AppBarItemLink>
                    <C.AppBarItemLink className={isNavActive("/app/myprofile")} to="/app/myprofile">
                        <C.AppBarIcon>
                            <FontAwesomeIcon icon={faUser} />
                        </C.AppBarIcon>
                        <C.AppBarItemText>Profile</C.AppBarItemText>
                    </C.AppBarItemLink>
                </>
            )}

            {!user && (
                <>
                    <C.AppBarItem onClick={() => displayLoginPopup(true)}>
                        <C.AppBarIcon>
                            <FontAwesomeIcon icon={faSignIn} />
                        </C.AppBarIcon>
                        <C.AppBarItemText>Login</C.AppBarItemText>
                    </C.AppBarItem>
                </>

            )}
        </C.AppBar>
    )
}

export default AppBar