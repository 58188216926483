import styled from "styled-components"
import { Collection as CollectionItem } from "components/widgets/collection/style"
import { Token as TokenItem } from "components/widgets/token/style"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { deviceMax } from "styles/media"

export const Collections = styled.div`

@media ${deviceMax.md} {
    padding-top:88px;
    position:relative;

}

`

export const FilterLeft = styled.div`
    flex:1;
    display:flex;

    @media ${deviceMax.xl} {
        flex-direction:column;
    }

    @media ${deviceMax.xxl} {
        flex:1.5;
    }
`
export const SearchSeparator = styled.div`
    width:1px;
    height:80%;
    
    background: ${Hex2Rgba(color.white, .15)};
    margin:0 16px;

    @media ${deviceMax.xl} {
        display:none;
    }
    
`
export const Search = styled.div`
display:flex;
justify-content:flex-start;
flex:1;
& > input{
    width:100%;
}
align-items:center;

`

export const FilterButtons = styled.div`
    display:flex;
    justify-content:flex-end;
    flex:4;
    & > div{
        margin-right:10px;
    }

    & > div:last-child{
        margin-right:0px;
    }

    @media ${deviceMax.xl} {

        & > div{
            flex:1;
        }
        
        & > div button{
            font-size:12px;
            width:100%;
        }

        & > div svg{
            margin-left:0px;
        
    }
    
`

export const Filter = styled.div`
    display:flex;

    @media ${deviceMax.md} {
        flex-direction:column;

        & > ${Search} > input{
            width:100%;
        }

        & > ${FilterButtons}{
            margin-top:20px;
            justify-content:space-between;
        }

    }

    padding-left:20px;
    padding-right:20px;

    @media ${deviceMax.md} {
        padding-left:10px;
        padding-right:10px;

        position:fixed;
        top:45px;
        width:100%;
        background: ${Hex2Rgba(color.bg1, 1)};
        padding-top:10px;
        padding-bottom:10px;
    }
`


export const Items = styled.div`
padding-left:20px;
    padding-right:20px;
    margin-top:25px;

    & ${CollectionItem}, & ${TokenItem}{
        margin-bottom:25px;
    }

    @media ${deviceMax.md} {
        margin-top:10px;
        & ${CollectionItem}, & ${TokenItem}{
            margin-bottom:10px;
        }
    }

    @media ${deviceMax.md} {
        padding-left:10px;
        padding-right:10px;
    }
`

export const Pagination = styled.div`
    flex:1;
    display:flex;
    justify-content:flex-end;
    align-items:center;

    @media ${deviceMax.md} {
        margin:8px 0px;
        justify-content:center;
    }

    
`

export const PaginationItem = styled.div<{disActive? : boolean}>`
    margin:0px 12.5px;
    font-size:14px;
    display:flex;
    align-items:center;
    & > svg{
        font-size:20px;
        margin:0px 5px;
        color:${Hex2Rgba(color.white, .6)};
    }

    user-select:none;

    padding:8px 16px;
    border-radius:8px;
    color:${props => props.disActive ? Hex2Rgba(color.white, .4) : color.white};

    cursor:${props => props.disActive ? "not-allowed" : "pointer"};
`

export const Strip = styled.div<{nomg:string}>`
display: flex;
    justify-content: space-between;
    background: ${color.bg2};
    align-items: center;
    padding:8px 20px;
    margin: 16px 0;

    ${props => props.nomg === "true" && `
        margin:0px;
    `}
`
export const StripTitle = styled.div`
    font-size: 18px;
`