import { useSelector } from "react-redux"
import * as P from "./style"

import BaseMobilePage from "components/mobile/base"
import { IconCollections } from "utils/icons"

import { MainParts } from "utils/parts"
import { faUser } from "@fortawesome/free-solid-svg-icons"
import Share from "components/parts/share"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const MobilePage = () => {

    const show = useSelector((state: any) => state.data.currentPage)

    return (
        <P.MobilePage>
            <BaseMobilePage title="Key" show={show === MainParts.Share} icon={<FontAwesomeIcon icon={faUser} />} glasmorph={true}>
                <Share />
            </BaseMobilePage>
        </P.MobilePage>
    )
}

export default MobilePage