import React, { useEffect } from "react"
import * as C from "./style"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { loginWithWeb3Auth, twitterLoginRequest } from "services/api/auth"
import toast from "react-hot-toast"
import { setLocalData } from "utils/localData"

import { CHAIN_NAMESPACES, WALLET_ADAPTERS } from "@web3auth/base"
import env from "env"
import { Web3AuthNoModal } from "@web3auth/no-modal"
import { SolanaPrivateKeyProvider } from "@web3auth/solana-provider"
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { useWeb3Auth } from "hooks/useWeb3Auth"
import { SolanaWallet } from "@web3auth/solana-provider";
import { Keypair, LAMPORTS_PER_SOL } from "@solana/web3.js"
import BigNumber from "bignumber.js"
import { formatPrice } from "modules/solana/share/helpers"
import { getWalletSolBalance } from "modules/solana/wallet"
import { useDispatch } from "react-redux"
import { showWelcomePopup } from "services/slices/popup"
import { setUser, setCloudWalletBalance } from "services/slices/user"
import { displayLoginPopup } from "utils/parts"
import base58 from "bs58"



const Login = () => {

    const [loading, setLoading] = React.useState(false)
    const { web3auth, setProvider, provider, connected } = useWeb3Auth()
    const dispatch = useDispatch()

    const onClick = async () => {

        if (loading) return;

        setLoading(true)

        let loadingToast = toast.loading("Loading...")

        try {
        // Login with Twitter / X
        if (!connected) {
            let web3authProvider = await web3auth!.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
                loginProvider: "jwt",
                extraLoginOptions: {
                    domain: env.WEB3AUTH.loginConfig.domain, // Pass on the Auth0 `Domain` here
                    verifierIdField: "sub", // Pass on the field name of the `sub` field in the JWT
                    connection: "twitter", // Use this to skip Auth0 Modal for Twitter / X login
                },
            });

            setProvider(web3authProvider)
        }
        } catch (err) {
            toast.dismiss(loadingToast)
            toast.error("Login failed")
            console.log(err)
            setLoading(false)
            return
        }
/*
        const user = await web3auth!.getUserInfo();

        //console.log("web3auth user",user)

        // parse the idToken from the user object
        const base64Url = user?.idToken!.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
        const parsedToken = JSON.parse(window.atob(base64));

        const solanaWallet = new SolanaWallet(provider!);
        const acc = await solanaWallet.requestAccounts();

        loginWithWeb3Auth(parsedToken.wallets[0].public_key, acc[0], user?.idToken!).then(async (res) => {
            setLocalData("token", res.data.token)
            dispatch(setUser(res.data.user))
            /*if (res.data.user.firstTime.firstTime)
                dispatch(showWelcomePopup(true))

            getWalletSolBalance(res.data.user.cloudWallet).then((res: any) => {
                dispatch(setCloudWalletBalance(
                    formatPrice(new BigNumber(res).div(LAMPORTS_PER_SOL))
                ))
            })

            displayLoginPopup(false)
            toast.success("Login successful")
        }).catch((err) => {
            console.log(err)
            toast.error("Login failed")
        }).finally(() => {
            toast.dismiss(loadingToast)
            setLoading(false)
        })*/
    }

    return (
        <C.Login>
            <C.Title>
                Welcome to hub3
            </C.Title>
            <C.Text>
                Please login with your Twitter account to continue.
            </C.Text>
            <C.LoginButton onClick={onClick}>
                <C.SocialIcon >
                    <FontAwesomeIcon icon={faTwitter} />
                </C.SocialIcon>
                <C.SocialText>
                    Login with Twitter
                </C.SocialText>
            </C.LoginButton>

            <C.Terms>
                Check out our <a href="/privacy-policy" target="_blank">Privacy Policy</a>
            </C.Terms>
        </C.Login>
    )

}

export default Login