import React from "react"
import * as P from "./style"

import { useSelector } from "react-redux"
import BasePopup from "components/popups/base"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
const LoadingPopup = () => {

    const show = useSelector((state: any) => state.popup.LoadingPopup)
    const text = useSelector((state: any) => state.data.loadingPopupText)

    return (
        <P.Popup>
            <BasePopup show={show} >

                <P.Loading>
                    <FontAwesomeIcon icon={faSpinner} spin />
                    {text && (
                        <P.LoadingText>{text}</P.LoadingText>
                    )}
                </P.Loading>

            </BasePopup>
        </P.Popup>
    )
}

export default LoadingPopup