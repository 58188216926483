import { DropdownButton } from "components/ui/button/dropdown/style"
import { Button } from "components/ui/button/style"
import styled from "styled-components"
import { deviceMax, deviceMin } from "styles/media"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"

export const Settings = styled.div`
    & hr {
        border: 0;
        height: 1px;
        background: ${Hex2Rgba(color.white, 0.2)};
    }
`

export const Title = styled.div`
    font-size: 18px;
    margin-bottom: 10px;
`

export const WalletsSection = styled.div``
export const NetworkTitle = styled.div`
    & img{
        width:20px;
        height:20px;
        margin-right:10px;
    }

    display:flex;
    align-items:center;
    padding:10px;
    border-radius:8px;
    background-color:${Hex2Rgba(color.primary, 0.1)};
`

export const Wallets = styled.div`
    display:flex;
    flex-direction:column;
`

export const Wallet = styled.div`
    display:flex;
    flex-direction:column;
    margin-top:10px;

    &:last-child{
        margin-bottom:10px;
    }

    border-radius:8px;
    background-color:${Hex2Rgba(color.white, 0.05)};
    padding:10px;
`

export const WalletHeader = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-bottom:5px;
`

export const WalletName = styled.div`
    & img{
        width:20px;
        margin-right:10px;
    }
    color:${Hex2Rgba(color.white, 0.8)};
    font-size:14px;

    display:flex;
    align-items:center;
`

export const WalletActions = styled.div`
    display:flex;
    align-items:center;

    & ${DropdownButton}{
        & > button{
            padding:4px 8px;
            background-color:${Hex2Rgba(color.black, 0.75)};
        }
    }
`

export const WalletAction = styled.div<{active? : string}>`
    &:not(:last-child){
        margin-right:10px;
    }
    cursor:pointer;

    & svg{
        ${props => props.active === "true" && `
            color:${color.green};
        `}
        ${props => props.active === "false" && `
            color:${color.danger};
        `}
    }
`

export const WalletAddress = styled.div`
    font-size:14px;
    word-break:break-all;
`

export const AccountSection = styled.div`
    margin-top:24px;
    ${Title}{
        margin:0px;
    }
`

export const AccountActions = styled.div`
    display:flex;
    flex-wrap:wrap;
    @media ${deviceMax.sm} {
        display:flex;
        flex-direction:column;

        & button{
            margin-bottom:10px;
            margin-right:0 !important;
        }
    }

    & button{
        display:flex;
        align-items:center;
        & svg{
            margin-right:5px;
        }
        margin-top:10px;
        &:not(:last-child){
            margin-right:10px;
        }
    }
`

export const Socials = styled.div`
    

    margin-bottom:24px;

    & ${Button}{
        margin-top:10px;
    }
`

export const SocialsContent = styled.div`
    display:flex;
`

export const Social = styled.div<{hc : string}>`
    display:flex;
    flex:1;

    &:last-child{
        margin-left:5px;
    }

    &:first-child{
        margin-right:5px;
    }

    
    
    align-items:center;
    border-radius:24px;
    overflow:hidden;
    padding:8px;
    background-color:${props => Hex2Rgba(props.hc, 1)};
    transition:.1s all ease-in-out;
    &:hover{
        background-color:${props => Hex2Rgba(props.hc, .64)};
    }

`

export const SocialIcon = styled.div`
    height:100%;
    flex:1;
    padding-left:5px;
`

export const SocialText = styled.div`
    font-size:14px;
    flex:6;
    text-align:center;
    cursor:pointer;
`
export const SocialTextTwitter = styled.div`
    font-size:14px;
    flex:6;
    text-align:center;
`

export const SocialAction = styled.div`
    flex:1;
    cursor:pointer;
`

export const ProfileAndShares = styled.div`
    margin-bottom:24px;    
`

export const ProfileAndSharesButtons = styled.div`
    display:flex;
    & > *{
        flex:1;
    }

    & > *:not(:last-child){
        margin-right:10px;
    }
`