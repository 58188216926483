import { faDiscord, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faGlobe, faSpinner, faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/ui/button'
import env from 'env'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { getPlace } from 'services/api/places'
import { getStory } from 'services/api/story'
import { showStoryPopup } from 'services/slices/popup'
import { color } from 'styles/theme'
import { formatLink, Hex2Rgba } from 'utils/helpers'
import * as C from './style'

const Story = () => {

    const [story, setStory] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<boolean>(false)
    const currentViewingStory = useSelector((state: any) => state.data.currentViewingStory)
    const dispatch = useDispatch()

    useEffect(() => {
        getStory(currentViewingStory).then((res: any) => {
            setStory(res.data.story)
            setLoading(false)
        }).catch((err: any) => {
            toast.error("Error loading story data")
            setLoading(false)
            setError(true)
        })
    }, [])

    useEffect(() => {
        ReactTooltip.rebuild()
        ReactTooltip.hide()
    }, [story])

    const hide = () => {
        dispatch(showStoryPopup(false))
    }

    if (loading)
        return (<C.Loading><FontAwesomeIcon icon={faSpinner} spin /></C.Loading>)

    if (error)
        return (<C.Loading>Failed to load story data</C.Loading>)

    return (
        <C.Place>
            <C.Hide onClick={hide}>&times;</C.Hide>
            <C.PlaceTop bg={story.cover ? env.API.CDN + "stories/covers/" + story.cover : ""}>
            </C.PlaceTop>

            <C.PlaceBottom>

                <C.Description style={{whiteSpace: "pre-wrap"}}>
                    {story.story}
                </C.Description>
            </C.PlaceBottom>
        </C.Place>
    )

}

export default Story