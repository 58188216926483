import styled, { keyframes } from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { deviceMax } from "styles/media"

import { WLCollection } from "components/widgets/whitelistCollection/style"
import { Button } from "components/ui/button/style"

export const Whitelist = styled.div`

    & ${WLCollection} {
        background:transparent;
        border:0;
    }

`

export const OG = styled.div`

    margin-top:48px;
    margin-bottom:36px;
    display:flex;
    flex-direction:column;
    align-items:center;
`

export const OGRocket = styled.div`
    display:flex;
    position:relative;
    align-items:center;
    justify-content:center;
    width:100%;

    &:after{
        content:" ";
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
    }
    
`
export const OGRocketBg = styled.div`

    width:160px;

    & img{
        width:100%;
    }

    
`

const rocketAnimation = keyframes`
    /*animation transform translate circle around*/
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(0, 10px);
    }
    100% {
        transform: translate(0, 0);
    }
        
    
`

export const OGRocketImage = styled.div`

    top:-5px;
    width:66px;
    position:absolute;

    & img{
        width:100%;
        animation: ${rocketAnimation} 3s ease-in-out infinite;
        transition: all 0.3s ease-in-out;
    }

`

export const OGRocketText = styled.div`
    position:absolute;
    bottom:20px;
    font-size:20px;
    font-weight:600;
    text-align:center;
    z-index:1;
    display:flex;
    align-items:center;

    & span{
        color:${Hex2Rgba(color.white, 0.8)};
        font-size:14px;
        padding:0 2px;
    }
`

export const OGTitle = styled.div`

    font-size:28px;
    text-align:center;

    & span{
        background: linear-gradient(90deg, ${color.gradient1.start}, ${color.gradient1.middle}, ${color.gradient1.end});
        font-weight:600;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

`

export const OGDesc = styled.div`
    margin-top:8px;
    margin-bottom:16px;


    text-align:center;
    font-size:12px;
    color:${color.primary};

    & span{
        color:${color.white};
    }

    padding:8px;
    border:1px solid ${Hex2Rgba(color.primary, .2)};
    background:${Hex2Rgba(color.secondaryFade, .25)};
    border-radius:4px;
`

export const NeedtoNominatedImage = styled.div`

    width:185px;

    & img{
        width:100%;
    }

`

export const NeedtoNominatedText = styled.div`
    color:${color.white};
    font-size:24px;
    text-align:center;

    & span{
        background: linear-gradient(90deg, ${color.gradient1.start}, ${color.gradient1.middle}, ${color.gradient1.end});
        font-weight:600;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    & div{
        display:inline-block;
        color:${color.tertiary};
    }
`

export const AskForNomination = styled.div`
    display:flex;
    margin:16px 0;
`

export const AskForNominationIcon = styled.div`
    & img{
        height:42px;
    }
    margin-right:4px;
`

export const AskForNominationText = styled.div`
    color:${color.white};
    & span{
        color:${color.tertiary};
    }
    font-size:16px;

`

export const RedeemCode = styled.div`
    text-decoration:underline;
    cursor:pointer;
    font-size:14px;
    color:${color.primary};
    margin-top:16px;
    
`

export const ButtonGroup = styled.div`
    margin-top:16px;
    display:flex;
    width:100%;

    & button{
        flex:1;
    }

    & button:not(:last-child){
        margin-right:16px;
    }
`

export const Eligible = styled.div`
    margin-top:48px;
    margin-bottom:36px;
    display:flex;
    flex-direction:column;
    align-items:center;

    & button{
        margin-top:16px;
    }
`

export const EligibleTitle = styled.div`
    font-size:28px;
    text-align:center;

    & span{
        background: linear-gradient(90deg, ${color.gradient1.start}, ${color.gradient1.middle}, ${color.gradient1.end});
        font-weight:500;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }   
`

export const EligibleDesc = styled.div`
    margin-top:8px;
    margin-bottom:8px;
    font-size:12px;
    text-align:center;
    color:${color.primary};
`

export const EligibleNominator = styled.div`

    font-size:14px;
    cursor:pointer;
    & a{
        background: linear-gradient(90deg, ${color.gradient3.start}, ${color.gradient3.end});
        font-weight:500;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-decoration:none;
    }
`

export const Entry = styled.div`
    margin-top:16px;
`

export const Nominate = styled.div`

`

export const Title = styled.div`
    display:flex;
    justify-content:space-between;
`

export const TitleText = styled.div`
font-size:18px;
background: linear-gradient(90deg, ${color.gradient1.start}, ${color.gradient1.middle}, ${color.gradient1.end});
font-weight:600;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
`

export const TitleStatus = styled.div<{type : string}>`
    font-size:12px;
    color:${color.inactive};
    margin-top:4px;

    ${props => props.type === "completed" && `
        color:${color.green};
    `}
`

export const NominateDesc = styled.div`
    margin-top:8px;
    margin-bottom:16px;
    font-size:12px;

    &.completed{
        color:${Hex2Rgba(color.white, 0.5)};
    }
`

export const NominateButtons = styled.div`
    display:flex;
    & button:not(:last-child){
        margin-right:16px;
    }

    &.completed{
        & button{
            opacity:1 !important;
            cursor:default;
            color:${color.secondary};
            background:${color.bg2};
            background-image:none;
            box-shadow:none !important;

            &::before{
                display:none !important;
            }
        }
    }
`

export const NominationMessage = styled.div`
    margin-top:16px;
    font-size:12px;
    color:${color.primary};

    & span{
        color:${color.white};
    }

    padding:8px;
    border:1px solid ${Hex2Rgba(color.primary, .2)};
    background:${Hex2Rgba(color.secondaryFade, .25)};
    border-radius:4px;
`

export const Quests = styled.div`
    margin-top:16px;
    border-top:1px solid ${Hex2Rgba(color.primary, .2)};
`

export const QuestsDesc = styled.div`
    margin-bottom:16px;
    font-size:12px;
`

export const QuestsTitle = styled.div`
    margin-top:8px;
    
    margin-bottom:8px;
    display:flex;
    justify-content:space-between;
`

export const QuestsList = styled.div`
    
`

export const QuestsListItem = styled.div`
    display:flex;
    justify-content:space-between;
    background:${Hex2Rgba("#000000", 0.32)};
    border-radius:8px;
    align-items:center;

    &:not(:last-child){
        margin-bottom:8px;
    }

    padding:16px;
`

export const QuestsListItemTitle = styled.div`
    display:flex;
    flex-direction:column;
    align-items:flex-start;
`

export const QuestsListItemTitleText = styled.div`
    font-size:14px;
`

export const QuestsListItemTitleInfo = styled.div`
    font-size:11px;
    color:${Hex2Rgba(color.white, 0.5)};
    line-height:1;
    & a{
        color:${Hex2Rgba(color.white, 0.5)};
        text-decoration:none;
    }
`

export const QuestsListItemCheck = styled.div`
    font-size:12px;
    color:${color.primary};
    border-bottom:1px solid ${color.primary};
    line-height:1;
    margin-top:4px;
    cursor:pointer;
`

export const QuestsListItemStatus = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;

    & ${Button}{
        padding:4px 32px;
        font-size:12px;
    }

    & .completed{
        opacity:1 !important;
        cursor:default;
        color:${color.secondary};
        background:${color.bg2};
        background-image:none;
        box-shadow:none !important;
    }
`

export const QuestsListItemStatusText = styled.div<{type : string}>`
    font-size:12px;
    color:${Hex2Rgba(color.white, 0.64)};
    margin-top:4px;

    ${props => props.type === "completed" && `
        color:${color.green};
    `}
`

export const Whitelisted = styled.div`
    margin-top:16px;
    & button{
        margin-top:16px;
    }
`

const rocketAnimationWlisted = keyframes`
    /*animation rotation shake*/
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(3deg);
    }
    75% {
        transform: rotate(-3deg);
    }
    100% {
        transform: rotate(0deg);
    }
`

export const WlistedRocket = styled.div`
    display:flex;
    position:relative;
    align-items:center;
    justify-content:center;
    width:100%;

    &:after{
        content:" ";
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
    }
    
    transition:all 0.5s ease-in-out;

    &:hover{
        animation: ${rocketAnimationWlisted} 3s infinite;
    }
`

export const WlistedRocketBg = styled.div`
    width:160px;
    
    & img{
        width:100%;
    }
`

export const WlistedRocketImage = styled.div`
    width:87px;
    position:absolute;
    transform:translate(0, 10px);
    
    & img{
        width:100%;
        animation: ${rocketAnimation} 3s ease-in-out infinite;
        transition: all 0.3s ease-in-out;
    }
`

export const WhitelistedTitle = styled.div`
    margin-top:24px;
    font-size:28px;
    background: linear-gradient(90deg, ${color.gradient3.start}, ${color.gradient3.end});
    font-weight:500;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align:center;
`

export const WhitelistedDesc = styled.div`
    margin-top:8px;
    margin-bottom:16px;
    font-size:14px;
    text-align:center;

    & a{
        color:${color.primary};
    }
`

export const TotalNominated = styled.div`
    & span{
        color:${color.tertiary};
    }
    font-size:14px;
    margin-top:8px;
    margin-bottom:16px;
    text-align:center;
`

export const WhitelistFull = styled.div`
    margin-top:48px;

    text-align:center;

    & a{
        margin-top:16px;
    }
`

export const WhitelistFullTitle = styled.div`
    font-size:28px;
    text-align:center;

    margin-top:8px;

    & span{
        background: linear-gradient(90deg, ${color.gradient1.start}, ${color.gradient1.middle}, ${color.gradient1.end});
        font-weight:600;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }  
`

export const DiscordRecruit = styled.div`
    margin-top:16px;
`

export const DiscordRecruitImage = styled.div`

    display:flex;
    justify-content:center;
    color:${Hex2Rgba(color.secondary, 0.64)};
    margin-bottom:16px;

    & svg{
        font-size:86px;
    }
`

export const DiscordRecruitTitle = styled.div`
    font-size:28px;
    text-align:center;
    background: linear-gradient(90deg, ${color.gradient1.start}, ${color.gradient1.middle}, ${color.gradient1.end});
        font-weight:500;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
`

export const DiscordRecruitDesc = styled.div`
 

    text-align:center;
    margin-top:8px;
    font-size:14px;
    color:${color.white};

    & span{
        color:${color.primary};
    }

    padding:8px;
    border:1px solid ${Hex2Rgba(color.primary, .2)};
    background:${Hex2Rgba(color.secondaryFade, .25)};
    border-radius:4px;
`

export const DiscordRecruitButtons = styled.div`
    display:flex;
    justify-content:center;
    margin-top:16px;
    
    & button:not(:last-child){
        margin-right:16px;
    }
`

export const DiscordRecruitEntry = styled.div``

export const SelectWallet = styled.div`
    background:${Hex2Rgba("#000000", 0.32)};
    border-radius:8px;
    padding:16px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    cursor:pointer;

    &:hover{
        background:${Hex2Rgba("#000000", 0.48)};
    }
`

export const SelectWalletTitle = styled.div`
    font-size:14px;
    color:${color.primary};

    & span{
        color:${color.white};
    }
`

export const SelectWalletLeft = styled.div`
    display:flex;
    justify-content:space-between;
    width:100%;
    align-items:center;
    padding-right:16px;
`

export const SelectWalletAddress = styled.div`
    font-size:13px;
    word-break:break-all;
    color:${Hex2Rgba(color.white, 0.64)};

    & svg{
        color:${color.error}
    }
`

export const WhitelistClosed = styled.div`
    margin-top:48px;
`

export const WhitelistClosedTitle = styled.div`
    font-size:28px;
    text-align:center;
    background: linear-gradient(90deg, ${color.gradient1.start}, ${color.gradient1.middle}, ${color.gradient1.end});
        font-weight:500;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
`

export const WhitelistClosedText = styled.div`
    text-align:center;
    font-size:14px;
    margin-top:8px;

    & span{
        background: linear-gradient(90deg, ${color.gradient3.start}, ${color.gradient3.end});
        font-weight:500;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    & span.n{
        color:${color.primary};
        background:unset;
        -webkit-background-clip: unset;
        -webkit-text-fill-color: unset;
    }
`

export const WhitelistStatus = styled.div<{whitelisted : string}>`
    ${props => props.whitelisted === "true" && `
        background: linear-gradient(90deg, ${color.gradient3.start}, ${color.gradient3.end});
    `}
    text-align:center;
    padding:8px 16px;

    ${props => props.whitelisted === "false" && `
        background:${Hex2Rgba(color.error, .23)};
    `}

    border-radius: 48px;

    margin-bottom:16px;

    & * {
        ${props => props.whitelisted === "true" && `
            color:${color.black} !important;
        `}
    }

    line-height:1.2;
`

export const WhitelistStatusTitle = styled.div`
    font-size:16px;
    color:${Hex2Rgba(color.white, 0.86)};

    & span{
        font-weight:500;
        color:${color.white};
    }
`

export const WhitelistStatusDesc = styled.div`
    font-size:12px;
    color:${Hex2Rgba(color.white, 0.64)};
`

export const Body = styled.div`
    padding-left:20px;
    padding-right:20px;
    padding-bottom:20px;
`

export const Header = styled.div`
    background:${Hex2Rgba(color.secondary, 0.08)};
    padding:20px;
    padding-bottom:10px;
    border-top-left-radius:16px;
    border-top-right-radius:16px;
`

export const HeaderTop = styled.div`
    display: flex;
    cursor:default;
    user-select:none;
`
export const HeaderTitle = styled.div`
    font-size:14px;
    display:flex;
`
export const HeaderTitleIcon = styled.div`
    & svg{
        width:20px;
        height:20px;
        color: ${Hex2Rgba(color.white, .5)};
    }
    margin-right:10px;
`

export const HeaderTitleText = styled.div`
    margin:0;
    font-size:14px;
    font-weight:400;
`

export const HeaderClose = styled.div`
    margin-left: auto;
    cursor: pointer;
    font-size: 24px;
    padding:0px 20px;
    padding-right:0px;
`

export const SocialMediaRequired = styled.div`
    margin-top:48px;
`

export const SocialMediaRequiredTitle = styled.div`
    font-size:28px;
    text-align:center;
    background: linear-gradient(90deg, ${color.gradient1.start}, ${color.gradient1.middle}, ${color.gradient1.end});
    font-weight:600;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`

export const SocialMediaRequiredText = styled.div`
    text-align:center;
    margin-top:8px;
    font-size:14px;
    color:${color.primary};

    & span{
        color:${color.white};
    }

    padding:8px;
    border:1px solid ${Hex2Rgba(color.primary, .2)};
    background:${Hex2Rgba(color.secondaryFade, .25)};
    border-radius:4px;
`

export const SocialMediaRequiredButtons = styled.div`
    display:flex;
    justify-content:center;
    margin-top:16px;
`

export const Social = styled.div<{hc: string}>`
    cursor:pointer;
    width:50%;
    &:last-child{
        margin-left:5px;
    }

    &:first-child{
        margin-right:5px;
    }

    border-radius:24px;
    padding:8px;
    background-color:${props => Hex2Rgba(props.hc, 1)};
    transition:.1s all ease-in-out;
    &:hover{
        background-color:${props => Hex2Rgba(props.hc, .64)};
    }

    text-align:center;
`

export const GhostImage = styled.div`
    display:flex;
    justify-content:center;

    & img{
        width:155px;
    }
`

export const Loading = styled.div`
    margin-top:48px;
    display:flex;
    align-items:center;
    justify-content:center;
    font-size:28px;
    height:100%;
`
export const Failed = styled.div`
    margin-top:48px;
    text-align:center;
    width:100%;
    height:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    font-size:24px;
    flex-direction:column;

    & button{
        margin-top:8px;
    }
`