import { faDiscord, faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faGlobe, faSpinner, faUpRightAndDownLeftFromCenter } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Badge from "components/ui/badge"
import Button from "components/ui/button"
import env from "env"
import React, { useEffect, useState } from "react"
import toast from "react-hot-toast"
import ReactTooltip from "react-tooltip"
import { getPlace } from "services/api/places"
import { store } from "services/store"
import { color } from "styles/theme"
import { formatLink, GetTwitterHandle, Hex2Rgba, toLink } from "utils/helpers"
import { displayHolderProfile } from "utils/parts"
import * as C from "./style"
import { history } from 'components/etc/history'
import { Recruit } from "models/enums/recruit"

const PlaceMap = (props: any) => {

    const [place, setPlace] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<boolean>(false)
    const currentWhitelist = store.getState().whitelist.currentWhitelist
    const wlentry = store.getState().whitelist.entry

    useEffect(() => {
        getPlace(props.place.id).then((res: any) => {
            setPlace(res.data.place)
            setLoading(false)
        }).catch((err: any) => {
            toast.error("Error loading user data")
            setLoading(false)
            setError(true)
        })
    }, [])

    useEffect(() => {
        ReactTooltip.rebuild()
        ReactTooltip.hide()
    }, [place])

    const copyDiscord = () => {

        if (place.discord) {
            navigator.clipboard.writeText(place.discord)
            toast.success("Copied to clipboard")
        }
    }

    const expand = () => {
        //displayHolderProfile(user)
        //history.push(`/app/profile/${user.id}`)
    }

    if (loading)
        return (<C.Loading><FontAwesomeIcon icon={faSpinner} spin /></C.Loading>)

    if (error)
        return (<C.Loading>Failed to load place data</C.Loading>)

    return (
        <C.ProfileMap>
            <C.User>

                <C.UserTop bg={place.cover ? env.API.CDN + "places/covers/" + place.cover : ""}>
                    <C.UserTopLeft>
                        <C.Avatar>
                            <img src={place.avatar ? env.API.CDN + "places/avatars/" + place.avatar : "/images/etc/user.webp"} alt="avatar" />
                        </C.Avatar>
                        <C.UserName>
                            <C.Name>{place.name}</C.Name>
                        </C.UserName>
                    </C.UserTopLeft>
                    <C.Social>
                        {place.twitter && (
                            <a data-tip="Go to Twitter Account" href={formatLink(place.twitter)} target="_blank" >
                                <FontAwesomeIcon icon={faTwitter} />
                            </a>
                        )}
                        {place.discord && (
                            <a data-tip="Go to Discord" href={formatLink(place.discord)} target="_blank" >
                                <FontAwesomeIcon icon={faDiscord} />
                            </a>
                        )}
                        {place.website && (
                            <a data-tip="Go to Website" href={formatLink(place.website)} target="_blank" >
                                <FontAwesomeIcon icon={faGlobe} />
                            </a>
                        )}
                    </C.Social>
                </C.UserTop>
                <C.SubTop>
                    <C.Badges>

                    </C.Badges>
                </C.SubTop>
                <C.Info>

                    <C.Description>
                        {place.description}
                    </C.Description>
                </C.Info>
            </C.User>

            <C.Expand>
                {place.website && (
                    <Button link="link" target="_blank" href={formatLink(place.website)} block="true" theme="gradient" bg={Hex2Rgba(color.white, .05)} color={color.white}>
                        Visit Website <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                    </Button>
                )}
            </C.Expand>

        </C.ProfileMap>
    )
}

export default PlaceMap