import styled, { keyframes } from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { deviceMax } from "styles/media"

import { Button } from "components/ui/button/style"

export const Launch = styled.div`
    position:relative;
`


export const Loading = styled.div`
    margin-top:48px;
    display:flex;
    align-items:center;
    justify-content:center;
    font-size:28px;
    height:100%;
`

export const FullPageLoading = styled.div`
    height:100%;
    background:${Hex2Rgba(color.black, 0.75)};
    position:fixed;
    z-index:2;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    font-size:28px;
`

export const Failed = styled.div`
    margin-top:48px;
    text-align:center;
    width:100%;
    height:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    font-size:24px;
    flex-direction:column;

    & button{
        margin-top:8px;
    }
`

export const Body = styled.div`
    padding-left:20px;
    padding-right:20px;
    padding-bottom:20px;
`

export const Header = styled.div`
    background:${Hex2Rgba(color.secondary, 0.08)};
    padding:20px;
    padding-bottom:10px;
    border-top-left-radius:16px;
    border-top-right-radius:16px;
`

export const HeaderTop = styled.div`
    display: flex;
    cursor:default;
    user-select:none;
`
export const HeaderTitle = styled.div`
    font-size:14px;
    display:flex;
`
export const HeaderTitleIcon = styled.div`
    & svg{
        width:20px;
        height:20px;
        color: ${Hex2Rgba(color.white, .5)};
    }
    margin-right:10px;
`

export const HeaderTitleText = styled.div`
    margin:0;
    font-size:14px;
    font-weight:400;
`

export const HeaderClose = styled.div`
    margin-left: auto;
    cursor: pointer;
    font-size: 24px;
    padding:0px 20px;
    padding-right:0px;
`

export const HeaderBottom = styled.div`
    display:flex;
    justify-content:space-between;
`

export const HeaderBottomTitle = styled.div`
    & > img{
        width:20px;
        height:20px;
        margin-right:5px;
    }

    font-size:18px;
    font-weight:500;

    display:flex;
    align-items:center;

`

export const Social = styled.div`

`

export const SocialItem = styled.a<{hc : string}>`
    color:${Hex2Rgba(color.white, 1)};

    &:not(:last-child){
        margin-right:8px;
    }

    &:hover{
        color:${props => props.hc};
    }
`

export const HeaderDescription = styled.div`
    margin-top:16px;
    margin-bottom:8px;
    font-size:12px;
    color:${Hex2Rgba(color.white, .5)};
    line-height:1.2;
`

export const Covers = styled.div`
    margin-top:16px;
`

export const Cover = styled.div`
& img{
    width:100%;
    border-radius:16px;
}
`

export const CoverSlider = styled.div`

    & *{
        outline: none !important;
    }

    & .slick-list {
        padding-top: 50px !important;
        padding-bottom: 50px !important;
    }

    & .slick-slide {
        transition: transform 0.2s ease;
    }

    & .slick-center {
        transform: scale(1.3);

        & ${Cover} {
            box-shadow: 0px 0px 20px 0px ${Hex2Rgba(color.black, 0.2)};
        }
    }

    & .slick-next {
        right: 16px;
        z-index: 1;
    }

    & .slick-prev {
        left: 16px;
        z-index: 1;
    }
`


export const SelectWallet = styled.div`
    background:${Hex2Rgba("#000000", 0.32)};
    border-radius:8px;
    padding:16px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    cursor:pointer;

    &:hover{
        background:${Hex2Rgba("#000000", 0.48)};
    }
`

export const SelectWalletTitle = styled.div`
    font-size:14px;
    color:${color.primary};

    & span{
        color:${color.white};
    }
`

export const SelectWalletLeft = styled.div`
    display:flex;
    justify-content:space-between;
    width:100%;
    align-items:center;
    padding-right:16px;
`

export const SelectWalletAddress = styled.div`
    font-size:13px;
    word-break:break-all;
    color:${Hex2Rgba(color.white, 0.64)};

    & svg{
        color:${color.error}
    }
`

export const CurrentPhase = styled.div`
    margin-top:16px;
    border-radius:8px;
    overflow:hidden;
    
`

export const CurrentPhaseTop = styled.div`
    padding:16px;
    background:${Hex2Rgba(color.secondaryFade, .64)};
`

export const CurrentPhaseTitle = styled.div`

`

export const CurrentPhaseTotalMinted = styled.div`
    
`

export const CurrentPhaseTotalMintedTop = styled.div`
    display:flex;
    justify-content:space-between;
`

export const CurrentPhaseTotalMintedTopText = styled.div`

`

export const CurrentPhaseTotalMintedTopNumber = styled.div`
    & span{
        font-weight:500;
        margin-left:5px;
    }
`

export const CurrentPhaseTotalMintedBar = styled.div`
    background:${Hex2Rgba(color.bg2, 1)};
    height:12px;
    border-radius:89px;
    width:100%;
`

export const CurrentPhaseTotalMintedBarFill = styled.div<{percent:number}>`
    background:${Hex2Rgba(color.primary, 1)};
    height:12px;
    border-radius:89px;
    width:${props => props.percent}%;
`

export const Mint = styled.div`
    background:${Hex2Rgba(color.secondary, .16)};
    padding:16px;
`

export const MintPrice = styled.div`
    & span{
        font-weight:500;
        color:${color.primary};
    }
    margin-bottom:8px;
`

export const MintActions = styled.div`
    display:flex;

    & > *{
        flex:1;
        padding:0px 8px;
    }

    & > *:not(:last-child){
        margin-right:8px;
    }
`

export const MintNumber = styled.div`
    display:flex;
    background:${Hex2Rgba(color.secondaryFade, .64)};
    border-radius:24px;
    align-items:center;
`

export const MintNumberButton = styled.div`
    cursor:pointer;
    height:100%;
    display:flex;
    align-items:center;
    font-size:18px;
    flex:1;
    justify-content:center;
    user-select:none;
`

export const MintNumberInputBox = styled.div`
    display:flex;
    flex:2;
`

export const MintNumberInput = styled.input`
    border:none;
    padding:8px 16px;
    color:${color.white};
    font-size:16px;
    font-weight:500;
    width:100%;
    background:transparent;
    text-align:center;
    outline:none;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    -moz-appearance: textfield;

`

export const MintTimer = styled.div`
    background:${Hex2Rgba(color.secondary, .16)};
    padding:16px;
`

export const MintTimerBox = styled.div`
    width:100%;
    background:${Hex2Rgba(color.secondaryFade, .64)};
    border-radius:100px;
    padding:8px;
    text-align:center;
    cursor:default;
    align-items:center;
    display:flex;
    justify-content:center;

    & span{
        color:${color.primary};
        font-size:14px;
        margin-right:8px;
    }

    box-shadow: inset 0px 0px 20px ${Hex2Rgba(color.primary, .56)};
    background:linear-gradient(45deg,${Hex2Rgba(color.gradient2.start, .48)},${Hex2Rgba(color.gradient2.middle, .48)},${Hex2Rgba(color.gradient2.end, .48)}); 
`

export const Phases = styled.div`
    margin-top:16px;
`

export const Phase = styled.div<{active? : string}>`
    background:${Hex2Rgba(color.secondary, .16)};
    padding:16px;
    margin-bottom:16px;
    display:flex;
    justify-content:space-between;
    border-radius:8px;

    ${props => props.active === "true" && `
        background:${Hex2Rgba(color.secondaryFade, .64)};
        position:relative;
        &::after {
            content: "";
            position: absolute;
            inset: 0;
            border-radius: 8px; 
            padding: 2px; 
            background:linear-gradient(45deg,${Hex2Rgba(color.gradient1.start, .48)},${Hex2Rgba(color.gradient1.middle, .48)},${Hex2Rgba(color.gradient1.end, .48)}); 
            -webkit-mask: 
            linear-gradient(#fff 0 0) content-box, 
            linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
                    mask-composite: exclude; 
            pointer-events: none;
        }
    `}
`

export const PhaseLeft = styled.div`
    flex:2.5;
`

export const PhaseLeftTitle = styled.div`

`

export const PhaseLeftDescription = styled.div`
    font-size:12px;
`

export const PhaseRight = styled.div`
    font-size:12px;
    flex:1;
    text-align:right;
    display:flex;
    flex-direction:column;
    align-items:flex-end;
`

export const PhaseRightTimer = styled.div`

`

export const PhaseRightTimerTitle = styled.div`
    height:24px;
    text-align:right;
    color:${color.primary};
`

export const PhaseRightTimerTime = styled.div`
    font-weight:500;
`

export const PhaseRightLabel = styled.div`
    background:${color.primary};
    border-radius:55px;
    font-size:10px;
    padding:4px 8px;
    color:${color.black};
    font-weight:600;

`

export const Timeout = styled.div`

`

export const TimeoutName = styled.div`
    text-align:center;
    font-size:18px;
    margin-bottom:16px;
`

export const NonWhitelisted = styled.div`
    margin-top:16px;
    padding:16px;
    position:relative;
    border-radius:8px;
    display:flex;
    align-items:center;
    justify-content:center;
    &::after {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 8px; 
        padding: 2px; 
        background:linear-gradient(45deg,${Hex2Rgba(color.gradient1.start, .48)},${Hex2Rgba(color.gradient1.middle, .48)},${Hex2Rgba(color.gradient1.end, .48)}); 
        -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
                mask-composite: exclude; 
        pointer-events: none;
    }

    & svg{
        color:${color.error};
        margin-right:8px;
    }
`