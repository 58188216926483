import React, { useEffect } from "react"
import * as C from "./style"

import { useDispatch, useSelector } from "react-redux"
import { Network } from "models/enums/network"
import Button from "components/ui/button"
import { capitalizeFirstLetter, Hex2Rgba } from "utils/helpers"
import { color } from "styles/theme"
import { SolanaWallets } from "modules/solana/wallet"
import { AptosWallets } from "modules/aptos/wallet"
import { ConnectWallet, findWallet, RequestSignature, SignMessage, Wallets } from "modules/wallet"
import { login, validateTokenAndGetWallets, validateWallet } from "services/api/auth"
import { setGlobalSelectedWallet, setUser } from "services/slices/user"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleDot } from "@fortawesome/free-regular-svg-icons"
import { showWalletConnectPopup } from "services/slices/popup"
import ReactTooltip from "react-tooltip"
import { setFirstTime } from "services/slices/data"
import { displayWalletConnectPopup } from "utils/parts"
import { setSelectedSolanaWallet } from "services/managers/user"
import toast from "react-hot-toast"

const WalletConnect = () => {


    const [network, setNetwork] = React.useState<any>("solana")
    const [solanaWallets, setSolanaWallets] = React.useState<any>([])
    const [aptosWallets, setAptosWallets] = React.useState<any>([])
    const user = useSelector((state: any) => state.user.user)
    const globalSelectedWallet = useSelector((state: any) => state.user.globalSelectedWallet)
    const dispatch = useDispatch()

    const _walletConnectSettings = useSelector((state: any) => state.data._walletConnectSettings)

    const handleNetwork = (network: string) => {
        setNetwork(network)
    }

    useEffect(() => {

        let _solanaWallets = []
        let _aptosWallets = []

        for (const [key, value] of Object.entries(SolanaWallets)) {
            _solanaWallets.push({
                name: (value as any).provider.name,
                installed: (value as any).isInstalled(),
                connected: false,
                loading:false
            })
        }

        //rearrange the order of the wallets by installed
        _solanaWallets.sort((a: any, b: any) => {
            return b.installed - a.installed
        })

        for (const [key, value] of Object.entries(AptosWallets)) {
            _aptosWallets.push({
                name: (value as any).provider.name,
                installed: (value as any).isInstalled(),
                connected: false,
                loading:false
            })
        }

        //rearrange the order of the wallets by installed
        _aptosWallets.sort((a: any, b: any) => {
            return b.installed - a.installed
        })

        initializeWallets(_solanaWallets, _aptosWallets)

        /*if (_walletConnectSettings.network === "any") {
            setNetwork("")
        } else {
            setNetwork(_walletConnectSettings.network)
        }*/
    }, [])

    const initializeWallets = async (_solanaWallets: any, _aptosWallets: any) => {
        if (user) {
            let connectedWalletsAptos: any = []
            let connectedWalletsAptosAddresses: any = []
            let connectedWalletsSolana: any = []
            let connectedWalletsSolanaAddresses: any = []
            for (const usrWallet of user.wallets) {
                let wallet = findWallet(usrWallet.address)

                if (wallet) {
                    let isConnected = await wallet.classInstance.isConnected()
                    if (isConnected) {
                        if (wallet.network == Network.Aptos) {
                            connectedWalletsAptos.push(wallet.provider)
                            connectedWalletsAptosAddresses.push(wallet.address)
                        } else if (wallet.network == Network.Solana) {
                            connectedWalletsSolana.push(wallet.provider)
                            connectedWalletsSolanaAddresses.push(wallet.address)
                        }
                    }
                }
            }

            if (connectedWalletsAptos.length > 0) {
                _aptosWallets.forEach((wallet: any) => {
                    if (connectedWalletsAptos.includes(wallet.name)) {
                        wallet.connected = true
                        wallet.address = connectedWalletsAptosAddresses[connectedWalletsAptos.indexOf(wallet.name)]
                    }
                })
            }

            if (connectedWalletsSolana.length > 0) {
                _solanaWallets.forEach((wallet: any) => {
                    if (connectedWalletsSolana.includes(wallet.name)) {
                        wallet.connected = true
                        wallet.address = connectedWalletsSolanaAddresses[connectedWalletsSolana.indexOf(wallet.name)]
                    }
                })
            }
        }

        setAptosWallets([..._aptosWallets])
        setSolanaWallets([..._solanaWallets])

    }

    const hideLoading = (wallet: any, network: Network, index: number) => {
        if (network == Network.Solana) {
            let _solanaWallets = [...solanaWallets]
            _solanaWallets[index].loading = true
            setSolanaWallets([..._solanaWallets])
        } else if (network == Network.Aptos) {
            let _aptosWallets = [...aptosWallets]
            _aptosWallets[index].loading = true
            setAptosWallets([..._aptosWallets])
        }
    }

    const connect = async (wallet: any, network: Network, index : number) => {

        if (wallet.connected){
            if (network == Network.Solana) {
                dispatch(setGlobalSelectedWallet(wallet.address[0]))
            }

            //close popup
            dispatch(showWalletConnectPopup(false))

            return;
        }

        if (network == Network.Solana) {
            let _solanaWallets = [...solanaWallets]
            _solanaWallets[index].loading = true
            setSolanaWallets([..._solanaWallets])
        } else if (network == Network.Aptos) {
            let _aptosWallets = [...aptosWallets]
            _aptosWallets[index].loading = true
            setAptosWallets([..._aptosWallets])
        }


        let provider = wallet.name
        ConnectWallet(provider, network).then(async (address: any) => {

            let nonce = user ? user.nonce : "0"

            if (!user) {
                try {
                    const user: any = await login(provider, address[0])
                    nonce = user.nonce

                    if (user.firstTime.firstTime)
                        dispatch(setFirstTime(true))

                } catch (err) {
                    //console.log(err)
                    //toast
                    hideLoading(wallet, network, index)
                    return false
                }
            }

            let wallets: any = await validateTokenAndGetWallets()
            let passed = false

            if (wallets) {
                for (const usrWallet of wallets) {
                    if (usrWallet.address == address[0]) {
                        passed = true
                        break
                    }
                }
            }

            if (!passed) {
                //console.log("request signature")
                RequestSignature(provider, network, SignMessage, nonce, address[0]).then((res: any) => {
                    //console.log(res)
                    validateWallet(provider, address[0], res, address[1] ?? "").then((res: any) => {
                        //console.log(res)

                        if (network === Network.Solana && !globalSelectedWallet) {
                            setSelectedSolanaWallet(address[0])
                        }

                        dispatch(setUser(res.user))
                        displayWalletConnectPopup(false)
                    }).catch((err) => {
                        console.log(err)
                         //if error is 409 
                        if (err.response && err.response.status == 409) {
                            toast.error("Wallet linked to another account, unlink it from the other account and try again")
                        } 
                        hideLoading(wallet, network, index)
                    })

                }).catch((err) => {

                    hideLoading(wallet, network, index)

                    console.log(err)
                })
            } else {
                if (network === Network.Solana && !globalSelectedWallet) {
                    setSelectedSolanaWallet(address[0])
                }
                displayWalletConnectPopup(false)
            }

        }).catch((err) => {
            hideLoading(wallet, network, index)
            console.log(err)
        })
    }

    useEffect(() => {
        ReactTooltip.rebuild()
        ReactTooltip.hide()
    }, [network])


    return (
        <C.WalletConnect>
            {network === "" && (
                <>
                    <C.Header>
                        <C.Title>
                            Select network
                        </C.Title>
                    </C.Header>
                    <C.Networks>
                        <C.Network onClick={() => handleNetwork(Network.Solana)}>
                            <C.NetworkIcon>
                                <img src="/images/chains/solana-icon.png" alt="Solana Icon" />
                            </C.NetworkIcon>
                            <C.NetworkName>SOLANA</C.NetworkName>
                        </C.Network>
                         {/*<C.Network onClick={() => handleNetwork(Network.Aptos)}>
                            <C.NetworkIcon>
                                <img src="/images/chains/aptos-icon.png" alt="Aptos Icon" />
                            </C.NetworkIcon>
                            <C.NetworkName>APTOS</C.NetworkName>
            </C.Network>*/}
                    </C.Networks>
                </>
            )}
            {network === Network.Solana && (
                <>
                    <C.Header>
                        {/*_walletConnectSettings.network === "any" && (
                            <Button theme="gradient-border-transparent-bg-on-hover" bg={Hex2Rgba(color.white, .05)} color="white" onClick={() => handleNetwork("")}>Back</Button>
                        )*/}
                        <C.Title>
                            Connect to Solana
                        </C.Title>
                    </C.Header>
                    <C.Providers>
                        {solanaWallets.map((wallet: any, index: number) => (
                            <C.Provider key={index} onClick={() => connect(wallet, Network.Solana, index)}>
                                <C.ProviderDetails>
                                    <C.ProviderConnected>
                                        {wallet.connected && (
                                            <FontAwesomeIcon data-tip="Connected" icon={faCircleDot} />
                                        )}
                                    </C.ProviderConnected>
                                    <C.ProviderIcon>
                                        <img src={"/images/wallets/" + wallet.name + ".svg"} alt={wallet.name + " wallet Icon"} />
                                    </C.ProviderIcon>
                                    <C.ProviderName>
                                        {capitalizeFirstLetter(wallet.name)}
                                    </C.ProviderName>
                                </C.ProviderDetails>
                                <C.ProviderDetect>
                                    {wallet.installed && "detected"}
                                </C.ProviderDetect>
                            </C.Provider>
                        ))}
                    </C.Providers>
                </>
            )}

            {network === Network.Aptos && (
                <>
                    <C.Header>
                        {_walletConnectSettings.network === "any" && (
                            <Button theme="gradient-border-transparent-bg-on-hover" bg={Hex2Rgba(color.white, .05)} color="white" onClick={() => handleNetwork("")}>Back</Button>
                        )}
                        <C.Title>
                            Connect to Aptos
                        </C.Title>
                    </C.Header>
                    <C.Providers>
                        {aptosWallets.map((wallet: any, index: number) => (
                            <C.Provider key={index} onClick={() => connect(wallet, Network.Aptos, index)}>
                                <C.ProviderDetails>
                                    <C.ProviderConnected>
                                        {wallet.connected && (
                                            <FontAwesomeIcon data-tip="Connected" icon={faCircleDot} />
                                        )}
                                    </C.ProviderConnected>
                                    <C.ProviderIcon>
                                        <img src={"/images/wallets/" + wallet.name + ".svg"} alt={wallet.name + " wallet Icon"} />
                                    </C.ProviderIcon>
                                    <C.ProviderName>
                                        {capitalizeFirstLetter(wallet.name)}
                                    </C.ProviderName>
                                </C.ProviderDetails>
                                <C.ProviderDetect>
                                    {wallet.installed && "detected"}
                                </C.ProviderDetect>
                            </C.Provider>
                        ))}
                    </C.Providers>
                </>
            )}
        </C.WalletConnect>
    )
}

export default WalletConnect