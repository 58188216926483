import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { deviceMax } from "styles/media"
import { Link } from "react-router-dom"
import { WLCollection } from "components/widgets/whitelistCollection/style"

export const WhitelistCollections = styled.div``

export const SliderItem = styled.div`
    aspect-ratio: 192 / 25;
    display: flex !important;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    border-radius: 8px;
    & img {
        height: 100%;
        border-radius: 8px;
    }

    @media (max-width: 1200px) {
        aspect-ratio: 192 / 35;
    }

    @media ${deviceMax.md} {
        aspect-ratio: 192 / 45;
    }

    cursor: pointer;
`

export const Slider = styled.div`

    & *{
        outline: none !important;
    }

    & .slick-list {
        padding-top: 50px !important;
        padding-bottom: 50px !important;
    }

    & .slick-center {
        transform: scale(1.2);
        transition: transform 0.5s ease;

        & ${SliderItem} {
            box-shadow: 0px 0px 20px 0px ${Hex2Rgba(color.black, 0.2)};
        }
    }

    @media (max-width: 1200px) {
        & .slick-list {
            padding-top: 10px !important;
            padding-bottom: 20px !important;
        }
    }

`


export const Search = styled.div`
    display:flex;
    justify-content:flex-start;
    flex:1;
    & > input{
        width:100%;
    }
    align-items:center;
`

export const FilterButtons = styled.div`
    display:flex;
    justify-content:flex-end;
    flex:4;
    & > div{
        margin-right:10px;
    }

    & > div:last-child{
        margin-right:0px;
    }

    @media ${deviceMax.sm} {

        & > div{
            flex:1;
        }
        
        & > div button{
            font-size:12px;
            width:100%;
        }

        & > div svg{
            margin-left:0px;
        
    }
`

export const Filter = styled.div`
    display:flex;

    @media ${deviceMax.sm} {
        flex-direction:column;

        & > ${Search} > input{
            width:100%;
        }

        & > ${FilterButtons}{
            margin-top:20px;
            justify-content:space-between;
        }

    }
`

export const Items = styled.div`
    margin-top:25px;

    & ${WLCollection}{
        margin-bottom:30px;
    }
`