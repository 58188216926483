import React, { useEffect, useMemo, useRef } from "react"
import * as C from "./style"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { twitterLoginRequest } from "services/api/auth"
import toast from "react-hot-toast"
import Button from "components/ui/button"
import { color } from "styles/theme"
import { Hex2Rgba, getUserSigner, shortenPublicKey } from "utils/helpers"
import { faCopy, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { useDispatch, useSelector } from "react-redux"
import { getWalletSolBalance } from "modules/solana/wallet"
import { setCloudWalletBalance } from "services/slices/user"
import BigNumber from "bignumber.js"
import { Keypair, LAMPORTS_PER_SOL } from "@solana/web3.js"
import { formatPrice } from "modules/solana/share/helpers"
import Input from "components/ui/input"
import { useYupValidationResolver } from "hooks/useYupValidationResolver"
import { Controller, useForm } from "react-hook-form"
import * as yup from "yup"
import base58 from "bs58"
import { getCloudWallet } from "services/api/share"
import nacl from "tweetnacl"
import { getLocalData } from "utils/localData"
import crypto from "crypto"
import env from "env"
import { sendSol } from "modules/solana/cloudWallet"
import { useWeb3Auth } from "hooks/useWeb3Auth"
import { SolanaWallet } from "@web3auth/solana-provider"

const CloudWalletPart = () => {

    const user = useSelector((state: any) => state.user.user)
    const balance = useSelector((state: any) => state.user.cloudWalletBalance)
    const dispatch = useDispatch()

    const [page, setPage] = React.useState(0)
    const { web3auth, provider } = useWeb3Auth()

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text)
        toast.success("Copied to clipboard")
    }

    const schema = useMemo(
        () =>
            yup.object({
                destination: yup.string().trim().max(44).required(),
                amount: yup.number().positive().required().max(balance)
            }),
        [balance]
    )

    const resolver = useYupValidationResolver(schema)

    const { handleSubmit, control, formState: { errors }, setValue } = useForm({ resolver })

    const [withdrawError, setWithdrawError] = React.useState("")
    const [withdrawing, setWithdrawing] = React.useState(false)
    const onSubmit = async (data: any) => {
        if (withdrawing) return;

        setWithdrawing(true)

        try {
            let cloudWallet = await getUserSigner(provider)
            let rent = 3039280;
            let gasFee = 10000 * 2;
            let minAmount = new BigNumber(rent + gasFee);
            let amountToSend = new BigNumber(data.amount).times(LAMPORTS_PER_SOL);

            // Check if the withdrawal amount is less than the minimum amount required to keep the account rent-exempt
            if (amountToSend.lt(minAmount)) {
                toast.error("Insufficient funds to maintain rent exemption after withdrawal.");
                setWithdrawing(false)
                return;
            }

            // If the withdrawal amount is greater than or equal to the minAmount, subtract the minAmount from the amountToSend to ensure the account remains rent-exempt
            if (amountToSend.gte(minAmount)) {
                amountToSend = amountToSend.minus(minAmount);
            }

            let loading = toast.loading("Withdrawing...")
            sendSol(cloudWallet, data.destination, amountToSend).then((res: any) => {
                toast.success("Withdraw successful")
                setPage(0)
                getWalletSolBalance(user.cloudWallet).then((res: any) => {
                    dispatch(setCloudWalletBalance(
                        formatPrice(new BigNumber(res).div(LAMPORTS_PER_SOL))
                    ))
                })
                setWithdrawing(false)
            }).catch((err: any) => {
                toast.error("Something went wrong")
                console.log(err)
                setWithdrawing(false)
            }).finally(() => {
                toast.dismiss(loading)
            })

        } catch (err) {
            toast.error("Something went wrong")
            console.log(err)
            setWithdrawing(false)
        }

    }

    const setToMax = () => {
        setValue("amount", balance)
    }

    const [privateKey, setPrivateKey] = React.useState("")
    const [privateKeyVisible, setPrivateKeyVisible] = React.useState(false)
    const [privateKeyLoading, setPrivateKeyLoading] = React.useState(false)
    const privateKeyInput = useRef<any>(null)

    const getPrivateKey = async () => {
        setPrivateKeyLoading(true)
        setPrivateKeyVisible(false)
        try {
            let signer = await getUserSigner(provider)

            setPrivateKey(base58.encode(signer.secretKey))
            setPrivateKeyLoading(false)
        } catch (e) {
            console.log(e)
            toast.error("Something went wrong")
        }

    }

    const togglePrivateKeyVisibility = (show: boolean) => {
        if (show) {
            privateKeyInput.current.type = "text"
        } else {
            privateKeyInput.current.type = "password"
        }
        setPrivateKeyVisible(show)
    }

    const copyPrivateKey = () => {
        navigator.clipboard.writeText(privateKey)
        toast.success("Copied to clipboard")
    }

    useEffect(() => {
        if (page === 2) {
            setValue("amount", "")
            setValue("destination", "")
        } else if (page === 0) {
            getWalletSolBalance(user.cloudWallet).then((res: any) => {
                dispatch(setCloudWalletBalance(
                    formatPrice(new BigNumber(res).div(LAMPORTS_PER_SOL))
                ))
            })
        } else if (page === 3) {
            getPrivateKey()
        }
    }, [page])





    return (
        <C.CloudWalletPart>
            {page === 0 && (
                <>
                    <C.WalletArea>
                        <C.Address onClick={() => copyToClipboard(user.cloudWallet)}>
                            <C.AddressText>
                                {shortenPublicKey(user.cloudWallet)}
                            </C.AddressText>
                            <C.CopyButton>
                                <FontAwesomeIcon icon={faCopy} />
                            </C.CopyButton>
                        </C.Address>
                        <C.WalletText>
                            Cloud Wallet Balance
                        </C.WalletText>
                        <C.WalletBalance>
                            {balance} SOL
                        </C.WalletBalance>
                    </C.WalletArea>

                    <C.Actions>
                        <Button theme="gradient-border-transparent-bg-on-hover" bg={color.secondary} color={color.white} onClick={() => setPage(2)}>
                            Withdraw
                        </Button>
                        <Button theme="gradient" bg={Hex2Rgba(color.white, .05)} color={color.white} onClick={() => setPage(1)}>
                            Deposit
                        </Button>
                    </C.Actions>

                    <C.Seperator />

                    <C.ExportButton onClick={() => setPage(3)}>
                        Export Wallet
                    </C.ExportButton>
                </>
            )}

            {page === 1 && (
                <>
                    <C.Title>
                        Deposit to Your Cloud Wallet
                    </C.Title>
                    <C.Description>
                        Directly send SOL to your Cloud Wallet address. This is a Solana wallet address that is linked to your account. Powered by Web3Auth.
                    </C.Description>
                    <C.FormGroup>
                        <C.Label>
                            Cloud Wallet Address
                        </C.Label>
                        <Input type="text" disabled value={user.cloudWallet} />
                        <C.DepositCopyButton onClick={() => copyToClipboard(user.cloudWallet)}>
                            <FontAwesomeIcon icon={faCopy} />
                        </C.DepositCopyButton>
                    </C.FormGroup>
                    <C.Actions>
                        <Button block="true" theme="gradient" bg={Hex2Rgba(color.white, .05)} color={color.white} onClick={() => setPage(0)}>
                            Okay
                        </Button>
                    </C.Actions>
                </>
            )}

            {page === 2 && (
                <>
                    <C.Title>
                        Withdraw From Your Cloud Wallet
                    </C.Title>
                    <C.Description>
                        Withdraw SOL from your Cloud Wallet to any Solana address.
                    </C.Description>

                    <C.Form onSubmit={handleSubmit((data) => onSubmit(data))}>
                        <C.FormGroup normal="true">
                            <C.Label>
                                Destination Address
                            </C.Label>
                            <Controller name="destination" control={control}
                                render={({ field }) => <Input onBlur={field.onBlur} onChange={field.onChange} checked={field.value} xref={field.ref} type="text" placeholder="Enter a Solana address" />}
                            />
                            {errors.destination && (<p>{errors.destination.message as string}</p>)}
                        </C.FormGroup>

                        <C.FormGroup normal="true">
                            <C.FormGroupHead>
                                <C.Label>
                                    Amount
                                </C.Label>
                                <C.Balance onClick={setToMax}>
                                    balance: {balance} SOL
                                </C.Balance>
                            </C.FormGroupHead>
                            <Controller name="amount" control={control}
                                render={({ field }) => <Input onBlur={field.onBlur} onChange={field.onChange} checked={field.value} xref={field.ref} value={field.value} type="text" placeholder="Enter an amount" />}
                            />
                            {errors.amount && (
                                <>
                                    {errors.amount.type === 'typeError' && (
                                        <p>amount must be a number</p>
                                    )}
                                    {errors.amount.type !== 'typeError' && (
                                        <p>{errors.amount.message as string}</p>
                                    )}
                                </>
                            )}
                        </C.FormGroup>

                        {withdrawError && (
                            <C.WithdrawError>
                                {withdrawError}
                            </C.WithdrawError>
                        )}

                        <C.Actions>
                            <Button theme="gradient-border-transparent-bg-on-hover" bg={color.secondary} color={color.white} onClick={() => setPage(0)}>
                                Go Back
                            </Button>
                            <Button type="submit" theme="gradient" bg={Hex2Rgba(color.white, .05)} color={color.white} disabled={withdrawing} loading={withdrawing ? "true" : "false"}>
                                Withdraw
                            </Button>
                        </C.Actions>
                    </C.Form>
                </>
            )}

            {page === 3 && (
                <>
                    <C.Title>
                        Export Your Cloud Wallet
                    </C.Title>
                    <C.Description>
                        Here is your Cloud Wallet private key. Keep it safe and secure.
                    </C.Description>
                    {!privateKeyLoading && (
                        <>
                            <C.FormGroup>
                                <C.FormGroupHead>
                                    <C.Label>
                                        Private Key
                                    </C.Label>
                                    <C.Balance onClick={() => togglePrivateKeyVisibility(!privateKeyVisible)}>
                                        {privateKeyVisible ? "Hide" : "Show"}
                                    </C.Balance>
                                </C.FormGroupHead>
                                <Input type="password" disabled value={privateKey} xref={privateKeyInput} />
                                <C.DepositCopyButton onClick={() => copyPrivateKey()}>
                                    <FontAwesomeIcon icon={faCopy} />
                                </C.DepositCopyButton>
                            </C.FormGroup>
                            <C.Actions>
                                <Button block="true" theme="gradient" bg={Hex2Rgba(color.white, .05)} color={color.white} onClick={() => setPage(0)}>
                                    Okay
                                </Button>
                            </C.Actions>
                        </>
                    )}

                    {privateKeyLoading && (
                        <C.Loading>
                            <FontAwesomeIcon icon={faSpinner} spin />
                        </C.Loading>
                    )}
                </>
            )}

        </C.CloudWalletPart>
    )

}

export default CloudWalletPart