import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { Dialog as Base, Popup as BasePopup, Overlay, Dialog, DialogHeader, DialogTitle } from "../base/style"
import { Avatar, UserTop, Name, SubTop, Badges, ProfileMap, Description, ShareDetails, Info, Expand, Share, Loading, Social } from "components/parts/profileMap/style"
import { deviceMin } from "styles/media"
import { Badge } from "components/ui/badge/style"

export const Popup = styled.div`
    & ${Dialog} {
        width: 100%;
        max-height:290px;
        position: fixed;
        bottom: 64px;
        padding:0px;
        backdrop-filter: unset;
        pointer-events: all;
        display:flex;
        flex-direction:column;
        border-radius:8px 8px 0px 0px;
    }

    & ${BasePopup} {
        pointer-events:none;
        display:block;
        background: transparent;
        z-index: 9;
    }
    & ${DialogTitle} {
        display:none;
    }
    & ${DialogHeader} {
        position: absolute;
        top:0;
        z-index: 9;
        right:16px;
    }

    & ${Overlay} {
        display:none;
    }


    & ${ProfileMap} {
        padding-bottom:4px;
        flex:1;
    }

    & ${Avatar} {
        width: 80px;
        height: 80px;

        & img {
            width: 80px;
            height: 80px;
        }
    }

    & ${UserTop} {
        padding:20px 16px 0px 16px;
        height:unset;
    }

    & ${Name} {
        font-size: 18px;
    }

    & ${SubTop} {
        padding-left:16px;
        padding-right:16px;
        margin:12px 0;
    }

    & ${Badges} {
        margin:0;
        min-height:unset;

        & ${Badge}:not(:first-child){
            margin-left:3px;
        }
    
        & ${Badge}{
            margin-bottom:4px;
        }
    }
    & ${Badge} {
        padding:0px 4px;
        font-size: 10px;
    }

    & ${Description}{
        font-size: 12px;
    }

    & ${Info}{
        padding-left:16px;
        padding-right:16px;
    }

    & ${Expand} {
        margin-top:0;
        padding:0px 16px 0px 16px;
    }

    & ${Share} > button{
        margin-right:8px;
    }

    & ${Loading} {
        height:100%;
    }

    & ${ShareDetails} {
        margin-top:8px;
    }

    & ${Social} {
        display:none;
    }

    @media ${deviceMin.md}{
        display:none;

    }
`

