import styled from "styled-components"
import { deviceMax, deviceMin } from "styles/media"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"


export const CurrentTokenContent = styled.div`
    backdrop-filter: blur(20px);
    background: ${Hex2Rgba(color.bg1, .64)};
    width:100%;
    height:100%;
    border-radius: 8px;
    padding:20px 11px;
`

export const CurrentToken = styled.div<{overlay : boolean, active : boolean, visible:boolean}>`
    position:absolute;
    left:${props => props.active ? "140px" : "-25%"};
    top:40px;
    width: 400px;
    z-index: 11;
    transition: all .3s ease-in-out;

    ${props => (!props.visible) && `
        
        display:none;
        
    `}

    ${props => props.overlay && `
        & > ${CurrentTokenContent}{
            background: ${Hex2Rgba(color.black, .6)};
        }
    `}

    

    & ${CurrentTokenContent}{
        height: calc(100% - 15px);
    }

`