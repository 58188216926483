import { WalletClient } from "@martiandao/aptos-web3-bip44.js"
import env from "env"
import { Network } from "models/enums/network";
import { Wallet } from "models/interfaces/wallet";
import axios from "axios";

import * as Gen from "@martiandao/aptos-web3-bip44.js/src/generated/index";
import cache from "@martiandao/aptos-web3-bip44.js/src/utils/cache";
import { request, gql } from "graphql-request"

const client = new WalletClient(env.WEB3.APTOS.NODE, env.WEB3.APTOS.FAUCET)

const getAllNftsByOwner = async (address: string, collectionAddress: string, collectionName: string, limit: number, start: number) => {
  const query = gql`
    {
        current_token_ownerships(
          where: {
            owner_address: {_eq: "${address}"},
            creator_address: {_eq: "${collectionAddress}"}
            collection_name: {_eq: "${collectionName}"}
          }
          limit: ${limit},
          offset: ${start}

        ) {
          collection_name,
          creator_address,
          current_token_data {
            metadata_uri,
            name,
            default_properties
          }
        }
    }
    `

  let res = await request("https://indexer.mainnet.aptoslabs.com/v1/graphql", query)

  return res.current_token_ownerships
}

export const getAllNftsOfUserAptos = async (wallets: Wallet[], collections: any) => {

  var nfts: any[] = [];

  for (const wallet of wallets) {
    for (const collection of collections) {
      if (wallet.network !== Network.Aptos)
        continue

      let nftsOfAdress = await getAllNftsByOwner(wallet.address, collection[0], collection[1], 100, 0)

      nfts = nfts.concat(nftsOfAdress)
    }
  }

  nfts = nfts.filter((thing: any, index: any, self: any) =>
    index === self.findIndex((t: any) => (
      t.current_token_data.name === thing.current_token_data.name
    ))
  )

  return nfts

}

async function getTokens(
  address: string,
  client: WalletClient = new WalletClient(env.WEB3.APTOS.NODE, env.WEB3.APTOS.FAUCET),
  limit?: number,
  depositStart?: number,
  withdrawStart?: number
) {
  const { tokenIds } = await client.getTokenIds(
    address,
    limit,
    depositStart,
    withdrawStart
  );
  const tokens: any = [];
  await Promise.all(
    tokenIds.map(async (tokenId) => {
      try {
        let resources: Gen.MoveResource[];
        if (cache.has(`resources--${tokenId.data.token_data_id.creator}`)) {
          resources = cache.get(
            `resources--${tokenId.data.token_data_id.creator}`
          );
        } else {
          resources = await client.aptosClient.getAccountResources(
            tokenId.data.token_data_id.creator
          );
          cache.set(
            `resources--${tokenId.data.token_data_id.creator}`,
            resources
          );
        }

        const accountResource: any = resources.find(
          (r) => r.type === "0x3::token::Collections"
        );
        const tableItemRequest: Gen.TableItemRequest = {
          key_type: "0x3::token::TokenDataId",
          value_type: "0x3::token::TokenData",
          key: tokenId.data.token_data_id,
        };

        const cacheKey = JSON.stringify(tableItemRequest);

        let token: any;
        if (cache.has(cacheKey)) {
          token = cache.get(cacheKey);
        } else {
          token = await client.aptosClient.getTableItem(
            accountResource.data.token_data.handle,
            tableItemRequest
          );
          cache.set(cacheKey, token);
        }
        token.collection = tokenId.data.token_data_id.collection;
        token.creator = tokenId.data.token_data_id.creator;
        tokens.push({ token, sequence_number: tokenId.sequence_number });
      } catch (e) {
        // Errors happening because of token handle not found will lead here
      }
    })
  );

  return tokens;
}