import axios from "axios"
import env from "env"
import { getLocalData } from "utils/localData"

export const getHolders = async (type: string, chain?: string, id?: string, wlid?: any, launch?:any) => {

    let url = `${env.API.URL}map/get/data`

    let options = {}

    if (getLocalData("token"))
        options = {
            headers: {
                Authorization: "Bearer " + getLocalData("token")
            }
        }

    if (launch){
        return axios.post(`${env.API.URL}map/get/mint`, {
            id:launch
        }, options)
    }

    return axios.post(url, {
        collection: {
            type: type,
            chain: chain,
            id: id
        },
        whitelist:{
            id: wlid
        },
        place:true,
        story:true,
    }, options)
}

export const getHolder = async (id: string) => {
    return axios.get(env.API.URL + "user/actions/get/" + id)
}