import { Connection, Transaction } from "@solana/web3.js"
import bs58 from "bs58"
import env from "env"
import { WalletProvider } from "models/interfaces/walletProvider"
import { WalletProviderDetails } from "models/interfaces/walletProviderDetails"

export class Backpack extends WalletProvider {

    constructor() {
        super()
        Backpack.isInstalled() && this.getProvider().on('accountChanged', () => {
            this.disconnect()
        })
    }

    static provider = WalletProviderDetails.backpack

    static isInstalled(): boolean {
        return (window as any).backpack && (window as any).backpack && (window as any).backpack.isBackpack
    }

    getProvider(): any {
        return (window as any).backpack
    }

    static isConnected(): boolean {
        return Backpack.isInstalled() && (window as any).backpack.isConnected
    }

    async connect(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            if (!Backpack.isInstalled())
                return false

            try {
                let conn = await this.getProvider().connect()

                return resolve(true)
            }
            catch (err: any) {
                //if has provider already connected
                if (err.message.includes("already connected")) {
                    return resolve(true)
                }
                return resolve(false)
            }
        })
    }

    async disconnect(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            if (!Backpack.isInstalled())
                return reject(false)

            try {
                await this.getProvider().disconnect()
            }
            catch (err) {
                return reject(false)
            }

            resolve(true)
        })
    }

    setOnDisconnect(callback: () => void): void {
        //this.getProvider().on('disconnect', callback)
    }

    async address(): Promise<string[]> {
        return new Promise(async (resolve, reject) => {
            if (!Backpack.isInstalled() || !Backpack.isConnected())
                return reject(false)


            resolve([this.getProvider().publicKey.toString()])
        })
    }

    async signMessage(message: string, nonce: string): Promise<string> {
        return new Promise(async (resolve, reject) => {
            if (!Backpack.isInstalled() || !Backpack.isConnected()) {
                return reject(false)
            }

            let address = await this.address()

            let encoded = new TextEncoder().encode(message + nonce)

            this.getProvider().signMessage(encoded, address[0]).then((signature: any) => {
                resolve(bs58.encode(signature))
            }).catch((e:any) => {
                reject(false)
            })
        })
    }

    install(): void {
        window.open('https://phantom.app/', '_blank')
    }

    signTransaction(transaction: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            if (!Backpack.isInstalled() || !Backpack.isConnected()) {
                return reject(false)
            }

            let address = await this.address()

            try {
                const tx = (window as any).backpack.signTransaction(transaction, address[0])
                resolve(tx)
            } catch (e) {
                reject(e)
            }

        })
    }

    signAllTransactions(transactions: any[]): Promise<any[]> {
        return new Promise(async (resolve, reject) => {
            if (!Backpack.isInstalled() || !Backpack.isConnected()) {
                return reject(false)
            }

            let address = await this.address()

            try {
                const signatures = (window as any).backpack.signAllTransactions(transactions, address[0])
                resolve(signatures)
            } catch (e) {
                reject(e)
            }

        })

    }
}