import React, { useState, useEffect } from "react"
import * as C from './style'
import { useDispatch, useSelector } from "react-redux"
import { getAllRaffles, getMultipleTickets, getTicketsOf } from "modules/solana/shuffle/raffle"
import { bufferToText, Hex2Rgba, shortenPublicKey } from "utils/helpers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretRight, faChevronRight, faCopy, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { toast } from "react-hot-toast"
import { showShuffleHistoryPopup } from "services/slices/popup"
import Button from "components/ui/button"
import { color } from "styles/theme"
import ShuffleWinner from "../shuffleWinner"
import { ParseAwardAddress, ParseCollectionAddress } from "modules/solana/shuffle/helpers"
import { getAllNftsByMintList } from "modules/solana/nft"
import axios from "axios"
import { getUsersByAddresses } from "services/api/user"
import { setParticipantMapping } from "services/slices/shuffle"
import { useLightbox } from "hooks/lightbox"
import ShuffleTicketsLB from "../shuffleTicketsLB"

const ShuffleWinners = (props: any) => {

    const programId = useSelector((state: any) => state.shuffle.programId)
    const [viewType, setViewType] = useState("home")
    const [viewingItem, setViewingItem] = useState<any>(null)
    const [winners, setWinners] = useState<any>([])
    const selectedWallet = useSelector((state: any) => state.user.selectedWallet)
    const participantMapping = useSelector((state: any) => state.shuffle.participantMapping)
    const [loading, setLoading] = useState(true)

    const dispatch = useDispatch()
    const lightBox = useLightbox()


    const viewItem = (item: any) => {
        setViewType("item")
        setViewingItem(item.address)
    }

    useEffect(() => {

        refresh()

    }, [])

    const refresh = async () => {

        setLoading(true)

        let allcollections = props.raffle.account.inventory.filter((i: any) => typeof i.itemType.nft !== "undefined").map((i: any) => i.address.toBase58())
        let collections: any = []
        let alreadyParsedcollections: any = {}

        //console.log(allcollections)

        for (let i = 0; i < allcollections.length; i++) {
            console.log(allcollections[i])
            let check = ParseCollectionAddress(allcollections[i])
            if (check === null) {
                collections.push(allcollections[i])
            } else {
                alreadyParsedcollections[allcollections[i]] = { image: check.image }
            }
        }

        //console.log(collections)


        let parsedCollections: any = {}

        try {
            let parsedCollectionsMints: any = await getAllNftsByMintList(collections)
            for (let i = 0; i < parsedCollectionsMints.length; i++) {
                try {
                    let metadata = await axios.get(parsedCollectionsMints[i].uri)
                    parsedCollections[parsedCollectionsMints[i].mintAddress.toString()] = { image: metadata.data.image }
                } catch (err) {
                    parsedCollections[parsedCollectionsMints[i].mintAddress.toString()] = { image: "" }
                }
            }
        } catch (e: any) { }

        parsedCollections = { ...parsedCollections, ...alreadyParsedcollections }

        let _winners: any = {}

        let winningTickets = props.raffle.account.winningTickets

        let winnersPKs = props.raffle.purchasePDAs.map((pda: any) => pda.account.buyer.toBase58())
        let winnersToFetch = winnersPKs.filter((p: any) => participantMapping[p] === undefined)
        if (winnersToFetch.length > 0)
            getUsersByAddresses(winnersToFetch).then((res: any) => {
                let newMapping = { ...participantMapping, ...res.data }
                dispatch(setParticipantMapping(newMapping))
            })

        getMultipleTickets(programId, props.raffle.publicKey.toBase58(), winningTickets).then(async (res: any) => {

            for (let i = 0; i < res.length; i++) {
                let winner = props.raffle.purchasePDAs.find((pda: any) => pda.account.sales.includes(res[i].ticket))
                if (!winner)
                    continue;

                winner = winner.account

                let target = props.raffle.account.inventory[res[i].target]

                if (!_winners[winner.buyer.toBase58()]) {

                    _winners[winner.buyer.toBase58()] = ({
                        userName: "",
                        address: winner.buyer.toBase58(),
                        items: [
                            {
                                data: typeof parsedCollections[target.address.toBase58()] === "undefined" ? ParseAwardAddress(target.address.toBase58()) : parsedCollections[target.address.toBase58()],
                                address: target.address.toBase58(),
                                count: 1
                            }
                        ],
                        total: 1
                    })
                } else {
                    let parsed = typeof parsedCollections[target.address.toBase58()] === "undefined" ? ParseAwardAddress(target.address.toBase58()) : parsedCollections[target.address.toBase58()]
                    let found = false
                    for (let i = 0; i < _winners[winner.buyer.toBase58()].items.length; i++) {
                        const item = _winners[winner.buyer.toBase58()].items[i];
                        if (item.address === target.address.toBase58()) {
                            found = true
                        }
                    }

                    if (!found) {
                        _winners[winner.buyer.toBase58()].items.push({
                            data: parsed,
                            address: target.address.toBase58(),
                            count: 1
                        })
                    } else {
                        _winners[winner.buyer.toBase58()].items.forEach((item: any) => {
                            if (item.address === target.address.toBase58()) {
                                item.count++
                            }
                        })
                    }

                    _winners[winner.buyer.toBase58()].total++
                }

            }

            let vals: any = Object.values(_winners)

            vals.sort((a: any, b: any) => {
                return b.total - a.total
            })

            // console.log(vals)

            if (selectedWallet) {
                for (let i = 0; i < vals.length; i++) {
                    const winner = vals[i];
                    if (winner.address === selectedWallet) {
                        vals.splice(i, 1)
                        winner.userName = "You"
                        vals.unshift(winner)
                    }
                }
            }


            setWinners(vals)

        }).catch((err: any) => {
            console.log(err)
            toast.error("Error loading shuffle")
        }).finally(() => {
            setLoading(false)
        })


    }

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text)
        toast.success("Copied to clipboard")
    }

    const [viewMyTicketsLoading, setViewMyTicketsLoading] = useState(false)

    const viewMyTickets = () => {

        if (viewMyTicketsLoading)
            return;

        let loading = toast.loading("Loading")
        setViewMyTicketsLoading(true)

        getTicketsOf(programId, props.raffle.publicKey.toBase58(), selectedWallet).then((res: any) => {
            if (!res) {
                toast.error("No tickets found")
                toast.dismiss(loading)
                return;
            }

            lightBox(<ShuffleTicketsLB tickets={res} />, "My Tickets")
            setViewMyTicketsLoading(false)

            toast.dismiss(loading)

        }).catch((err: any) => {
            console.log(err)
            toast.error("Error loading tickets")
            toast.dismiss(loading)
            setViewMyTicketsLoading(false)
        })
    }

    const viewAllTickets = () => {
        lightBox(<ShuffleTicketsLB tickets={props.raffle.account.winningTickets} />, "Winning Tickets")
    }

    return (
        <C.ShuffleWinners>

            {viewType === "home" && (
                <>
                    {props.close && (
                        <C.Header>
                            <Button theme="gradient-border-transparent-bg-on-hover" bg={Hex2Rgba(color.white, .05)} color="white" onClick={props.close}>Back</Button>
                            <C.Title>
                                Viewing Winners
                            </C.Title>
                        </C.Header>
                    )}

                    <C.ItemIdContainer>
                        <C.ItemId onClick={() => copyToClipboard(props.raffle.publicKey.toBase58())}>
                            <C.ItemIdText>
                                <C.ItemIdTitle>Shuffle ID</C.ItemIdTitle>
                                <C.ItemIdValue>{shortenPublicKey(props.raffle.publicKey.toBase58(), 16)}...</C.ItemIdValue>
                            </C.ItemIdText>
                            <C.ItemIdCopy >
                                <FontAwesomeIcon icon={faCopy} />
                            </C.ItemIdCopy>
                        </C.ItemId>
                        <C.ItemId onClick={() => copyToClipboard(bufferToText(props.raffle.account.hash))}>
                            <C.ItemIdText>
                                <C.ItemIdTitle>Hash</C.ItemIdTitle>
                                <C.ItemIdValue>{shortenPublicKey(bufferToText(props.raffle.account.hash), 16)}...</C.ItemIdValue>
                            </C.ItemIdText>
                            <C.ItemIdCopy >
                                <FontAwesomeIcon icon={faCopy} />
                            </C.ItemIdCopy>
                        </C.ItemId>
                    </C.ItemIdContainer>

                    <C.Tickets>
                        <C.TicketButton onClick={viewMyTickets}>
                            View Your Tickets
                        </C.TicketButton>
                        <C.TicketButton onClick={viewAllTickets}>
                            View Winning Tickets
                        </C.TicketButton>
                    </C.Tickets>

                    {loading && (
                        <C.Loading>
                            <FontAwesomeIcon icon={faSpinner} spin />
                        </C.Loading>
                    )}

                    {winners.map((winner: any, index: number) => (

                        <C.Win onClick={() => viewItem(winner)} key={index}>
                            <C.WinLeft>
                                <C.WinWinner>
                                    <C.WinWinnerName>
                                        {participantMapping[winner.address] ? participantMapping[winner.address].username : winner.userName}
                                        {winner.userName === "You" && (
                                            <C.WinWinnerYou>
                                                (<span>you</span>)
                                            </C.WinWinnerYou>
                                        )}
                                    </C.WinWinnerName>
                                    <C.WinWinnerAddress>
                                        {shortenPublicKey(winner.address)}
                                    </C.WinWinnerAddress>
                                </C.WinWinner>

                                <C.WinCount me={winner.userName === "You" ? "true" : "false"}>
                                    Won {winner.total} prizes
                                </C.WinCount>
                            </C.WinLeft>

                            <C.WinRight>
                                <C.WinItems>
                                    <C.WinItem order={0}>
                                        <C.WinItemMore>
                                            +2
                                        </C.WinItemMore>
                                    </C.WinItem>

                                    {winner.items.map((item: any, index: number) => (

                                        <C.WinItem order={index} key={index}>
                                            <img src={item.data.image ? item.data.image : "/images/etc/ghost.png"} onError={(e: any) => { e.target.onerror = null; e.target.src = "/images/etc/ghost.png" }} />
                                        </C.WinItem>

                                    ))}

                                </C.WinItems>
                                <C.WinAction>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </C.WinAction>
                            </C.WinRight>
                        </C.Win>

                    ))}
                </>
            )}

            {viewType === "item" && (
                <ShuffleWinner raffle={props.raffle} address={viewingItem} close={() => setViewType("home")} />
            )}

        </C.ShuffleWinners>
    )
}

export default ShuffleWinners
