import { WalletProvider } from "models/interfaces/walletProvider"
import { WalletProviderDetails } from "models/interfaces/walletProviderDetails"

export class Petra extends WalletProvider {

    constructor() {
        super()
        Petra.isInstalled() && this.getProvider().onAccountChange(() => {
            this.disconnect()
        })
    }

    static provider = WalletProviderDetails.petra

    static isInstalled(): boolean {
        return (window as any).petra ? true : false
    }

    getProvider(): any {
        return (window as any).petra
    }

    static async isConnected(): Promise<boolean> {
        return Petra.isInstalled() && (window as any).petra.isConnected()
    }

    async connect(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            if (!Petra.isInstalled())
                return reject(false)

            try {
                await this.getProvider().connect()
            }
            catch (err) {
                return reject(false)

            }

            resolve(true)
        })
    }

    async disconnect(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            if (!Petra.isInstalled())
                return reject(false)

            try {
                await this.getProvider().disconnect()
            }
            catch (err) {
                return reject(false)
            }

            resolve(true)
        })
    }

    setOnDisconnect(callback: () => void): void {
        this.getProvider().onDisconnect(callback)
    }

    async address(): Promise<string[]> {
        return new Promise(async (resolve, reject) => {
            if (!Petra.isInstalled())
                return reject(false)

            let isConnected = await Petra.isConnected()

            if (!isConnected)
                return reject(false)

            try {
                let accounts = await this.getProvider().account()
                return resolve([
                    accounts.address,
                    accounts.publicKey
                ])
            } catch (err) {
                return reject(false)
            }
        })
    }

    async signMessage(message: string, nonce: string): Promise<string> {
        return new Promise(async (resolve, reject) => {
            if (!Petra.isInstalled())
                return reject(false)

            let isConnected = await Petra.isConnected()

            if (!isConnected)
                return reject(false)

            this.getProvider().signMessage({ nonce, message }).then((signature: any) => {
                resolve(signature.signature)
            }).catch(() => {
                reject(false)
            })
        })
    }

    install(): void {
        window.open('https://petra.app/', '_blank')
    }

    signTransaction(transaction: any): Promise<any> {
        return Promise.resolve(transaction)
    }

    signAllTransactions(transactions: any[]): Promise<any[]> {
        return Promise.resolve(transactions)
    }
}