import { useSelector } from "react-redux"
import * as P from "./style"

import BaseMobilePage from "components/mobile/base"
import { IconCollections } from "utils/icons"

import { MainParts } from "utils/parts"
import Collections from "components/parts/collections"

const MobilePage = () => {

    const currentPage = useSelector((state: any) => state.data.currentPage)

    return (
        <P.MobilePage>
            <BaseMobilePage title="Communities" show={currentPage === MainParts.Collections} icon={<IconCollections/>} >
                <Collections />
            </BaseMobilePage>
        </P.MobilePage>
    )
}

export default MobilePage