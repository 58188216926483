import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { deviceMax } from "styles/media"
import { Row } from "styles"

export const ShuffleLiquidityLocks = styled.div`

`

export const Description = styled.div`
    color: ${color.whiteShade};
    & span{
        color: ${color.white};
    }
    font-size: 14px;
    margin-bottom:16px;
`

export const Note = styled.div`
    margin-top: 16px;
    font-size: 10px;
    color: ${color.whiteShade};
`

export const Collections = styled.div`

`

export const Collection = styled.div`
    display: flex;
    align-items: center;
    background-color: ${Hex2Rgba(color.secondary, .16)};
    border-radius: 8px;
    padding:16px;
    
`

export const CollectionImage = styled.div`
    flex:1;
    & img{
        width: 100%;
        border-radius: 8px;
    }
    display: flex;
    align-items: center;
`

export const CollectionDetails = styled.div`
    flex:3;
    margin-left:8px;
`

export const CollectionName = styled.div`
    font-size: 16px;
`

export const CollectionDeposit = styled.div`
    font-size: 14px;
    color: ${color.whiteShade};
    & span{
        color: ${color.white};
    }
`

export const CollectionActions = styled.div`
    flex:2;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & > *:not(:last-child){
        margin-right: 8px;
    }
`

export const Whitelisted = styled.div`
    font-size: 14px;
    margin-bottom: 16px;
    color: ${color.green};
`

export const Loading = styled.div`
    font-size:18px;
    display:flex;
    align-items:center;
    justify-content:center;
    height:100%;
    margin: 32px 0;
`

export const Deposit = styled.div`

`

export const Header = styled.div`
    margin-bottom: 16px;
    display: flex;
    align-items: center;
`

export const HeaderTitle = styled.div`
    margin-left: 8px;
`


export const Nfts = styled(Row)`

`

export const NftInfo = styled.div`
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    padding:4px;
    background:${Hex2Rgba(color.bg1, .64)};
    transition:all .1s ease-in-out;
    font-size:12px;
    text-align:center;
`

export const NftActiveMark = styled.div``

export const Nft = styled.div<{image:string, isselected?:string}>`
    background-image:url(${props => props.image});
    background-size:cover;
    background-position:center center;
    background-repeat:no-repeat;
    height:155px;
    border-radius: 8px;
    position:relative;
    margin-bottom:30px;
    cursor:pointer;
    transition:all .1s ease-in-out;
    overflow:hidden;
    &:hover{
        & ${NftInfo}{
            background:${Hex2Rgba(color.bg1, .84)};
        }

        opacity:.8;
    }   

    ${props => props.isselected === 'true' && `
        

        & ${NftActiveMark}{
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            background:${Hex2Rgba(color.secondary, .32)};
            border: 2px solid ${color.secondary};
            border-radius: 8px;
        }
    `}
`



export const NftDepositActions = styled.div`
    position:sticky;
    bottom:0;
    width:100%;
    display:flex;
    align-items:center;
    
    & button{
        flex:1;
    }

    & button:not(:last-child){
        margin-right:8px;
    }
    
`