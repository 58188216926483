import React from "react"
import * as P from "./style"

import { useSelector } from "react-redux"
import BasePopup from "components/popups/base"
import { IconCollections, IconConnectWallet, IconShuffle } from "utils/icons"

import { showShuffleLiquidityTokenPopup } from "services/slices/popup"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBook } from "@fortawesome/free-solid-svg-icons"
import ShuffleLiquidityToken from "components/parts/shuffle/shuffleLiquidityToken"


const ShuffleLiquidityTokenPopup = () => {

    const show = useSelector((state: any) => state.popup.ShuffleLiquidityTokenPopup)
    
    return (
        <P.Popup>
            <BasePopup title="Liquidity" show={show} hide={() => showShuffleLiquidityTokenPopup(false)} icon={<IconShuffle />} glasmorph={true}>
                <ShuffleLiquidityToken/>
            </BasePopup>
        </P.Popup>
    )
}

export default ShuffleLiquidityTokenPopup