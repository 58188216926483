import React from "react"
import * as P from "./style"

import { useSelector } from "react-redux"
import BasePopup from "components/popups/base"
import { IconCollections, IconConnectWallet, IconGuide } from "utils/icons"

import { showInfoPopup } from "services/slices/popup"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBook, faRocket } from "@fortawesome/free-solid-svg-icons"
import Fronk from "components/parts/fronk"


const InfoPopup = () => {

    const show = useSelector((state: any) => state.popup.InfoPopup)
    
    return (
        <P.Popup>
            <BasePopup title="Info" show={show} hide={() => showInfoPopup(false)} icon={<FontAwesomeIcon icon={faRocket}/>} glasmorph={true}>
                <Fronk/>
            </BasePopup>
        </P.Popup>
    )
}

export default InfoPopup