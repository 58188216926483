import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { color } from 'styles/theme'
import { Hex2Rgba } from 'utils/helpers'
import { deviceMax, deviceMin } from 'styles/media'

export const BarContainer = styled.div`
    position: fixed;
    display:flex;
    align-items:center;
    justify-content:center;
    top: 40px;
    width:100%;
    @media ${deviceMax.md} {
        top: 10px;
    }
    z-index:10;
`

export const Bar = styled.div`
    display:flex;
    justify-content:center;
`

export const BarContent = styled.div`
    background:${Hex2Rgba("#000000", .48)};
    backdrop-filter: blur(20px);
    display:flex;
    padding:8px 16px;
    border-radius:58px;
`

export const Item = styled.div<{ active?: any, type?: string }>`
    cursor: pointer;
    background-color: ${color.bg1};
    position:relative;
    display:flex;
    align-items:center;
    justify-content:center;
    height:32px;
    border-radius: 24px; 
    padding:10px 14px;
    color: ${color.primary};

    & > svg{
        color: ${color.primary};
        font-size:14px;
        width:14px;
        height:14px;
    }

    transition: all .1s ease-in-out;

    outline:none;

    font-size:14px;
    &:not(:last-child){
        margin-right:10px;
    }

    &:hover, &:focus {
        background-color: ${color.secondary};
        color: ${color.white};

        & > svg{
            color: ${color.white};
        }
    }

    ${props => props.active === "true" && `
        background-color: ${color.secondary};
        color: ${color.white};

        & > svg{
            color: ${color.white};
        }
    `}

    ${props => props.type === "search" && `
        cursor: default;
        background-color: ${color.secondary} !important;
        color: ${color.white} !important;

        & > svg{
            color: ${color.white} !important;
        }
    `}

    ${props => props.type === "whitelist" && `

        & > svg:last-child{
            margin-left: 4px;
        }
    `}
`

export const ItemLink = styled(Link)`
    cursor: pointer;
    background-color: ${color.bg1};
    position:relative;
    display:flex;
    align-items:center;
    justify-content:center;
    height:32px;
    border-radius: 24px; 
    padding:10px 14px;
    color: ${color.primary};
    text-decoration:none;

    & > svg{
        color: ${color.primary};
        font-size:14px;
        width:14px;
        height:14px;
    }

    transition: all .1s ease-in-out;

    outline:none;

    font-size:14px;
    &:not(:last-child){
        margin-right:10px;
    }

    &:hover, &:focus {
        background-color: ${color.secondary};
        color: ${color.white};

        & > svg{
            color: ${color.white};
        }
    }
`

export const ItemText = styled.div`
    font-size:14px;
    margin-left:10px;
`

export const SearchInput = styled.input`
    background-color: transparent;
    border: none;
    outline: none;
    color: ${color.white};
    margin-left:10px;

    &::placeholder{
        color: ${Hex2Rgba(color.white, .64)};
    }
`

export const MobileSearch = styled.div`
    position: fixed;
    top:65px;

    display:none;

    @media ${deviceMax.md} {
        display:block;
    }

    & ${SearchInput}{
        width:250px;
        background-color: ${color.secondary};
        padding:10px 14px;
        border-radius: 24px;
        border: 1px solid ${Hex2Rgba(color.black, .24)};
        box-shadow: 0px 0px 4px ${Hex2Rgba(color.black, .24)};
    }
`

export const Wallet = styled.div`
    background:${Hex2Rgba("#000000", .48)};
    backdrop-filter: blur(20px);
    display:flex;
    padding:8px 16px;
    border-radius:58px;
    margin-left:16px;
`

export const WalletItem = styled.div`

    cursor: pointer;
    background-color: ${color.bg1};
    position:relative;
    display:flex;
    align-items:center;
    justify-content:center;
    height:32px;
    border-radius: 24px; 
    padding:10px 14px;
    color: ${color.primary};

    & > svg{
        color: ${color.primary};
        font-size:14px;
        width:14px;
        height:14px;
    }

    transition: all .1s ease-in-out;

    outline:none;

    font-size:14px;
    &:not(:last-child){
        margin-right:10px;
    }

    &:hover, &:focus {
        background-color: ${color.secondary};
        color: ${color.white};

        & > svg{
            color: ${color.white};
        }
    }

`

export const WalletText = styled.div`
    font-size:14px;
    margin-left:10px;

    @media ${deviceMax.md}{
        display:none;
    }
`