import { useSelector } from "react-redux"
import * as P from "./style"

import BasePage from "components/pages/base"
import { IconCollections, IconWhitelist } from "utils/icons"

import { MainParts } from "utils/parts"
import Launches from "components/parts/launches"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faRocket } from "@fortawesome/free-solid-svg-icons"

const Page = () => {

    const show = useSelector((state: any) => state.data.currentPage)

    
    return (
        <P.Page>
            <BasePage title="Launches" show={show === MainParts.Launches} icon={<FontAwesomeIcon icon={faRocket} />} glasmorph={true}>
                <Launches />
            </BasePage>
        </P.Page>
    )
}

export default Page