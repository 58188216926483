import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { User } from 'models/user'
import { hex2a } from 'utils/helpers'

export interface ShuffleState {
    liquidationType: string | null,
    amountToBuy: number,
    programId: string,
    vault: string,
    treasury: string,
    treasuryAccount: any,
    currentRaffle: string,
    viewingWinner: boolean,
    ledger: any,
    selectedLedger: any,
    loadedLedger: any,
    tokenViewAddress: string,
    nftViewAddress: string,
    refreshLiquidations: boolean,
    widgetRedirect: boolean,
    participantMapping: any,
    locksInNftPage:boolean,
}

const initialState: ShuffleState = {
    liquidationType: null,
    amountToBuy: 0,
    programId: "",
    vault: "",
    treasury: "",
    treasuryAccount: null,
    currentRaffle: "",
    viewingWinner: false,
    ledger: null,
    selectedLedger: null,
    loadedLedger: null,
    tokenViewAddress: "",
    nftViewAddress: "",
    refreshLiquidations: false,
    widgetRedirect: false,
    participantMapping: {},
    locksInNftPage:false,
}

export const shuffleSlice = createSlice({
    name: 'shuffle',
    initialState,
    reducers: {
        setLiquidationType: (state, action: PayloadAction<any | null>) => {
            state.liquidationType = action.payload
        },
        setAmountToBuy: (state, action: PayloadAction<number>) => {
            state.amountToBuy = action.payload
        },
        setShuffleProgramId: (state, action: PayloadAction<string>) => {
            state.programId = action.payload
        },
        setShuffleVault: (state, action: PayloadAction<string>) => {
            state.vault = action.payload
        },
        setShuffleTreasury: (state, action: PayloadAction<string>) => {
            state.treasury = action.payload
        },
        setShuffleTreasuryAccount: (state, action: PayloadAction<any>) => {
            state.treasuryAccount = action.payload
        },
        setShuffleCurrentRaffle: (state, action: PayloadAction<string>) => {
            state.currentRaffle = action.payload
        },
        setShuffleViewingWinner: (state, action: PayloadAction<boolean>) => {
            state.viewingWinner = action.payload
        },
        setShuffleLedger: (state, action: PayloadAction<any>) => {
            state.ledger = action.payload
        },
        setShuffleSelectedLedger: (state, action: PayloadAction<any>) => {
            state.selectedLedger = action.payload
        },
        setShuffleLoadedLedger: (state, action: PayloadAction<any>) => {
            state.loadedLedger = action.payload
        },
        setTokenViewAddress: (state, action: PayloadAction<string>) => {
            state.tokenViewAddress = action.payload
        },
        setNftViewAddress: (state, action: PayloadAction<string>) => {
            state.nftViewAddress = action.payload
        },
        setRefreshLiquidations: (state, action: PayloadAction<boolean>) => {
            state.refreshLiquidations = action.payload
        },
        setWidgetRedirect: (state, action: PayloadAction<boolean>) => {
            state.widgetRedirect = action.payload
        },
        setParticipantMapping: (state, action: PayloadAction<any>) => {
            state.participantMapping = action.payload
        },
        setLocksInNftPage: (state, action: PayloadAction<boolean>) => {
            state.locksInNftPage = action.payload
        },
    }
})

export const { setLiquidationType,setParticipantMapping, setShuffleCurrentRaffle, setWidgetRedirect, setNftViewAddress, setRefreshLiquidations, setTokenViewAddress, setShuffleTreasury, setShuffleTreasuryAccount, setShuffleVault, setAmountToBuy, setShuffleProgramId, setShuffleViewingWinner, setShuffleLedger, setShuffleSelectedLedger, setShuffleLoadedLedger, setLocksInNftPage} = shuffleSlice.actions

export default shuffleSlice.reducer