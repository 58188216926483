import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { setCurrentPage } from "services/slices/data"
import { closeAllPages, MainParts } from "utils/parts"
import * as C from "./style"

const Page = (...props: any) => {

    const navigate = useNavigate()

    const closePage = () => {
        if (props[0].hide) {
            props[0].hide()
        }else{
            closeAllPages()
            navigate("/app")
        }
    }

    const dispatch = useDispatch()
    const sideBarActive = useSelector((state: any) => state.data.sidebarActive)

    const HeaderExtra = props[0].headerExtra ? props[0].headerExtra : null

    if (props[0].show) {

        return (
            <C.Page>
                <C.Overlay onClick={closePage}></C.Overlay>
                <C.Dialog full={false} glasmorph={props[0].glasmorph ? "true" : "false"}>
                    {props[0].header !== false && (
                        <C.DialogHeader>
                            <C.DialogTitle>
                                {props[0].icon && (
                                    <C.DialogTitleIcon> {props[0].icon} </C.DialogTitleIcon>
                                )}
                                <C.DialogTitleText>
                                    {props[0].title ? props[0].title : ""}
                                </C.DialogTitleText>
                            </C.DialogTitle>
                            
                            {props[0].headerExtra && (
                                HeaderExtra
                            )}

                            <C.DialogClose onClick={closePage}>&times;</C.DialogClose>
                        </C.DialogHeader>
                    )}

                    <C.DialogBody>
                        {props[0].children}
                    </C.DialogBody>
                </C.Dialog>
            </C.Page>
        )
    } else {
        return <></>
    }
}

export default Page