import React from 'react'

import { Logo } from "./style"

const logo = (...props: any) => {

    return (

        <Logo {...props[0]}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 77 35"
            >
                <g fill="#F8F7FF" fillOpacity="0.87" clipPath="url(#clip0_1_140)">
                    <path d="M0 26.152V0h7.147v10.216h9.461V0h7.148v26.152h-7.148V15.936H7.147v10.216H0z"></path>
                    <path d="M74.088 17.5a10.4 10.4 0 012.638 5.349c.337 2 .08 4.053-.739 5.91a10.447 10.447 0 01-3.875 4.544A10.548 10.548 0 0166.365 35H0v-6.74h66.359c.987 0 1.933-.389 2.63-1.082a3.683 3.683 0 001.09-2.613c0-.98-.392-1.92-1.09-2.613a3.732 3.732 0 00-2.63-1.082H54.544V14.13h11.815c.987 0 1.933-.389 2.63-1.082a3.682 3.682 0 001.09-2.613c0-.98-.392-1.92-1.09-2.613a3.733 3.733 0 00-2.63-1.082H49.876v10.014c0 1.992-.477 3.714-1.433 5.165-.956 1.45-2.286 2.57-3.99 3.356-1.71.788-3.69 1.182-5.942 1.182-2.27 0-4.259-.394-5.964-1.181-1.706-.787-3.033-1.906-3.982-3.356-.946-1.451-1.42-3.173-1.42-5.165V0h7.146v16.14a4.311 4.311 0 00.541 2.165 3.849 3.849 0 001.507 1.487 4.699 4.699 0 004.364 0 3.88 3.88 0 001.492-1.487 4.31 4.31 0 00.54-2.165V0h23.629c2.04.002 4.034.593 5.741 1.702a10.454 10.454 0 013.871 4.544 10.372 10.372 0 01.74 5.906 10.408 10.408 0 01-2.63 5.347l.002.002z"></path>
                </g>
                <defs>
                    <clipPath id="clip0_1_140">
                        <path fill="#fff" d="M0 0H76.863V35H0z"></path>
                    </clipPath>
                </defs>
            </svg>
        </Logo>

    )

}

export default logo