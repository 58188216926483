import { useSelector } from "react-redux"
import Collections from "components/parts/collections"
import * as P from "./style"

import BasePage from "components/pages/base"
import { IconChat, IconCollections } from "utils/icons"

import { MainParts } from "utils/parts"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faComments } from "@fortawesome/free-solid-svg-icons"
import Chat from "components/parts/chat"

const Page = () => {

    const show = useSelector((state: any) => state.data.currentPage)

    return (
        <P.Page>
            <BasePage title="Chat" show={show === MainParts.Chat} icon={<IconChat/>} glasmorph={true}>
                <Chat />
            </BasePage>
        </P.Page>
    )
}

export default Page