import React, { useEffect, useState } from 'react'
import * as C from './style'

import { useDispatch, useSelector } from "react-redux"

import env from 'env'
import ReactTooltip from 'react-tooltip'
import { Launch } from 'models/launch'
import { store } from 'services/store'
import { copyObject, Hex2Rgba, toLink } from 'utils/helpers'
import Input from 'components/ui/input'
import DropdownButton, { DropdownItem } from 'components/ui/button/dropdown'
import { Col, Row } from 'styles'
import { Chains } from 'models/enums/network';
import { color } from 'styles/theme'
import { State } from 'models/enums/state'
import { useNavigate } from 'react-router-dom'
import { setFilterValues, setSearchValue, set_Launches } from 'services/slices/_launches'
import LaunchWidget from 'components/widgets/launch'

const Launches = () => {

    const allLaunches: Launch[] = useSelector((state: any) => state.data.launches)

    const userLoaded = useSelector((state: any) => state.user.userLoaded)
    const launches = useSelector((state: any) => state._launches._launches)
    const _searchText = useSelector((state: any) => state._launches.searchText)
    const _chain = useSelector((state: any) => state._launches.chain)
    const _filter = useSelector((state: any) => state._launches.filter)
    const _sort = useSelector((state: any) => state._launches.sort)

    const searchInput = React.useRef<any>(null)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        ReactTooltip.rebuild()
        ReactTooltip.hide()
    }, [])

    useEffect(() => {
        ReactTooltip.rebuild()
        ReactTooltip.hide()
    }, [launches])

    useEffect(() => {

        if (allLaunches.length > 0 && userLoaded) {
            if (_searchText.length === 0)
                filterItems(_chain, _filter, _sort)
            else
                handleSearch(_searchText)
        }


    }, [allLaunches, userLoaded])

    const filterItems = (chain: any, filter: any, sort: any, page = 1) => {

        let user = store.getState().user.user

        let filtered = copyObject(allLaunches)

        if (chain !== "all") {
            filtered = filtered.filter((launch: any) => launch.chain === chain)
        }

        if (filter === "Live") {
            filtered = filtered.filter((launch: any) => launch.status === State.ACTIVE)
        }


        if (sort === "A-Z") {
            filtered = filtered.sort((a: any, b: any) => a.name.localeCompare(b.name))
        }


        searchInput!.current.value = ""

        dispatch(setSearchValue(""))
        dispatch(setFilterValues({ chain, filter, sort }))
        dispatch(set_Launches(filtered))
    }

    const handleSearch = (searchText: any) => {
        dispatch(setSearchValue(searchText))

        let filtered = allLaunches

        if (searchText) {
            filtered = filtered.filter((launch: any) => launch.name.toLowerCase().includes(searchText.toLowerCase()))
        }

        if (_chain !== "all") {
            filtered = filtered.filter((launch: any) => launch.chain === _chain)
        }

        dispatch(set_Launches(filtered))
        dispatch(setFilterValues({ chain: _chain, filter: "all", sort: "all" }))
    }

    return (
        <C.Launches>

            <C.Filter>
                <C.Search>
                    <Input type="text" xref={searchInput} placeholder="Search" onChange={(e: any) => handleSearch(e.target.value)} defaultValue={_searchText} />
                </C.Search>

                <C.FilterButtons>

                    <DropdownButton mobileSide="left" text={_chain === "all" ? "Chain" : (_chain.charAt(0).toUpperCase() + _chain.slice(1))} buttonStyle={{ theme: "gradient-border-transparent-bg-on-hover", bg: Hex2Rgba(color.secondaryFade, .5), color: "white" }}>
                        <DropdownItem active={_chain === "all" ? "true" : "false"} onClick={() => filterItems("all", _filter, _sort)}>
                            All
                        </DropdownItem>
                        <DropdownItem active={_chain === Chains.solana.name ? "true" : "false"} onClick={() => filterItems(Chains.solana.name, _filter, _sort)}>
                            <img src="/images/chains/solana-icon.png" alt="solana icon" width="15" style={{ marginRight: 5 }} /> Solana
                        </DropdownItem>
                        <DropdownItem active={_chain === Chains.aptos.name ? "true" : "false"} onClick={() => filterItems(Chains.aptos.name, _filter, _sort)}>
                            <img src="/images/chains/aptos-icon.png" alt="aptos icon" width="15" style={{ marginRight: 5 }} /> Aptos
                        </DropdownItem>
                    </DropdownButton>

                    <DropdownButton text={_filter === "all" ? "Filter" : _filter} buttonStyle={{ theme: "gradient-border-transparent-bg-on-hover", bg: Hex2Rgba(color.secondaryFade, .5), color: "white" }}>
                        <DropdownItem active={_filter === "all" ? "true" : "false"} onClick={() => filterItems(_chain, "all", _sort)}>
                            All
                        </DropdownItem>
                        {/*<DropdownItem active={_filter === "Registered" ? "true" : "false"} onClick={() => filterItems(_chain, "Registered", _sort)}>
                            Registered
                        </DropdownItem>*/}
                        <DropdownItem active={_filter === "Live" ? "true" : "false"} onClick={() => filterItems(_chain, "Live", _sort)}>
                            Live
                        </DropdownItem>
                    </DropdownButton>

                    <DropdownButton text={_sort === "all" ? "Sort" : _sort} buttonStyle={{ theme: "gradient-border-transparent-bg-on-hover", bg: Hex2Rgba(color.secondaryFade, .5), color: "white" }}>
                        <DropdownItem active={_sort === "all" ? "true" : "false"} onClick={() => filterItems(_chain, _filter, "all")}>
                            Default
                        </DropdownItem>
                        <DropdownItem active={_sort === "A-Z" ? "true" : "false"} onClick={() => filterItems(_chain, _filter, "A-Z")}>
                            A-Z
                        </DropdownItem>
                    </DropdownButton>
                </C.FilterButtons>
            </C.Filter>

            <C.Items>
                <Row>
                    {launches.map((item: any, index: number) => (
                        <Col col="12" md="12" lg="12" xl="6" xxl="6" xxxl="6" xxxxl='4' _2k="3" _4k="2" key={index}>
                            <LaunchWidget item={item} />
                        </Col>
                    ))}
                </Row>
            </C.Items>

        </C.Launches>
    )

}

export default Launches