import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"


export const WalletConnect = styled.div``

export const Header = styled.div`
    display: flex;
    align-items: center;
    margin-bottom:10px;

    & > button {
        margin-right: 10px;
    }
`

export const Title = styled.div`
    
`

export const Networks = styled.div`
    display:flex;
    justify-content:space-between;
`

export const Network = styled.div`
    flex:1;
    background: ${Hex2Rgba(color.white, .05)};
    display:flex;
    padding:25px;
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius:8px;

    &:not(:last-child) {
        margin-right: 10px;
    }

    cursor:pointer;

    &:hover {
        background: ${Hex2Rgba(color.white, .1)};
    }
`

export const NetworkIcon = styled.div`
    height:50px;
    width:50px;

    img {
        height:100%;
        width:100%;
    }
`

export const NetworkName = styled.div`
    margin-left: 10px;
    font-size: 18px;
`

export const Providers = styled.div`
    display:flex;
    flex-direction:column;
`

export const Provider = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding:16px 10px;

    &:hover {
        background: ${Hex2Rgba(color.white, .1)};
    }

    cursor:pointer;
    border-radius:8px;
`

export const ProviderDetails = styled.div`
    display:flex;
    align-items:center;
`

export const ProviderConnected = styled.div`
    width:20px;
    font-size:12px;
    color:${color.green};
`

export const ProviderIcon = styled.div`
    & > img {
        height:40px;
        width:40px;
    }
    margin-right: 10px;
    display:flex;
    align-items:center;
`

export const ProviderName = styled.div`
    font-size:18px;
`

export const ProviderDetect = styled.div`
    font-size:14px;
    color:${Hex2Rgba(color.white, .5)};
`