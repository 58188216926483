import { useDispatch, useSelector } from "react-redux"
import * as P from "./style"

import BaseMobilePage from "components/mobile/base"
import { IconCollections } from "utils/icons"

import { closeAllPages, MainParts } from "utils/parts"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCog, faUser } from "@fortawesome/free-solid-svg-icons"
import Settings from "components/parts/settings"
import { setCurrentPage } from "services/slices/data"
import { useNavigate } from "react-router-dom"
import EditProfile from "components/parts/editProfile"

const MobilePage = () => {

    const currentPage = useSelector((state: any) => state.data.currentPage)
    const navigate = useNavigate()
    
    const hide = () => {
        closeAllPages()
        navigate("/app")
    }

    return (
        <P.MobilePage>
            <BaseMobilePage hide={hide} title="Profile" show={currentPage === MainParts.MyProfile} icon={<FontAwesomeIcon icon={faUser}/>}>
                <EditProfile />
            </BaseMobilePage>
        </P.MobilePage>
    )
}

export default MobilePage