import React, { useState, useEffect } from "react"
import * as C from './style'
import { useDispatch, useSelector } from "react-redux"
import { Hex2Rgba, shortenPublicKey } from "utils/helpers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretRight, faChevronRight, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { toast } from "react-hot-toast"
import Button from "components/ui/button"
import { color } from "styles/theme"
import { showShuffleHowToPlayPopup } from "services/slices/popup"

const ShuffleHowToPlay = () => {

    const dispatch = useDispatch()

    return (
        <C.ShuffleHowToPlay>
            <C.Title>
                Connect wallet:
            </C.Title>
            <C.Text>
                Sign up, connect your Solana wallet, and ensure sufficient SOL balance.
            </C.Text>

            <C.Title>
                Join the shuffle:
            </C.Title>
            <C.Text>
                Review the prize pool and ticket price, and join the active Shuffle event. All prizes are held in an on-chain shuffle contract.
            </C.Text>

            <C.Title>
                Buy tickets:
            </C.Title>
            <C.Text>
                Purchase tickets to increase your chances of winning.
            </C.Text>

            <C.Title>
                Wait for end:
            </C.Title>
            <C.Text>
                The Shuffle ends when all tickets are sold, and the algorithm randomly selects winners.
            </C.Text>

            <C.Title>
                Check results:
            </C.Title>
            <C.Text>
                View the winning tickets and prizes after the Shuffle ends
            </C.Text>

            <C.Title>
                Claim rewards:
            </C.Title>
            <C.Text>
                Connect your wallet and claim your prize. It will be transferred to your wallet upon successful collection.
            </C.Text>

            <C.Highlight>
                Enjoy the Shuffle event on Hub3 and play responsibly! 🎉
            </C.Highlight>

            <C.Note>
                Notes;
                <ul>
                <li>Shuffle tickets cannot be refunded once bought.</li>
                <li>Each ticket has a % chance of winnings. You can win multiple prizes with multiple tickets.</li>
                <li>You can only buy %30 of the total tickets</li>
                </ul>
            </C.Note>

            <Button theme="gradient" block="true" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)} onClick={() => dispatch(showShuffleHowToPlayPopup(false))}>Okay</Button>
        </C.ShuffleHowToPlay>
    )

}

export default ShuffleHowToPlay