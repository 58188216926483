import React from "react"
import * as P from "./style"

import { useSelector } from "react-redux"
import BasePopup from "components/popups/base"
import { IconCollections, IconConnectWallet } from "utils/icons"

import { showWalletConnectPopup } from "services/slices/popup"
import WalletConnect from "components/parts/walletConnect"
import { displayWalletConnectPopup } from "utils/parts"


const WalletConnectPopup = () => {

    const show = useSelector((state: any) => state.popup.WalletConnectPopup)
    
    return (
        <P.Popup>
            <BasePopup title="Connect a Wallet" show={show} isredux={false} hide={() => displayWalletConnectPopup(false)} icon={<IconConnectWallet />} glasmorph={true}>
                <WalletConnect/>
            </BasePopup>
        </P.Popup>
    )
}

export default WalletConnectPopup