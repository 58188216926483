import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import { Collection } from "models/collection"
import { Network } from "models/enums/network"
import { GetMetadata } from "modules/solana/nft"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { store } from "services/store"
import * as C from "./style"

const NftView = (props: any) => {

    const nft = useSelector((state: any) => state.data.currentViewingNft)
    const [nftData, setNftData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<boolean>(false)

    useEffect(() => {

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        const collections = store.getState().data.collections

        let collection : Collection = collections.find((c: any) => c.id === nft.collection.id) as any

        if (collection.network === Network.Solana){

            axios.get(nft.uri, {cancelToken: source.token}).then((res) => {
                if (res.data.image) {
                    setNftData({
                        image: res.data.image,
                        name: res.data.name,
                        description: res.data.description,
                        traits: res.data.attributes,
                    })
                } else {
                    setNftData({
                        image: nft.uri,
                        name: nft.uri.name,
                        description: nft.uri.description ?? "",
                        traits: nft.uri.traits ?? []
                    })
                }
                setLoading(false)
            }).catch((err) => {
                setLoading(false)
                setError(true)
                source.cancel()
            })

        }
        else if (collection.network === Network.Aptos){
            axios.get(nft.uri.uri, {cancelToken: source.token}).then((res) => {
                if (res.data.image) {
                    setNftData({
                        image: res.data.image,
                        name: res.data.name,
                        description: res.data.description,
                        traits: res.data.attributes,
                    })
                } else {
                    setNftData({
                        image: nft.uri.uri,
                        name: nft.uri.name,
                        description: nft.uri.description ?? "",
                        traits: nft.uri.traits ?? []
                    })
                }
                setLoading(false)
            }).catch((err) => {
                setLoading(false)
                setError(true)
                source.cancel()
            })
        }

        return () => {
            if (source.cancel){ source.cancel() }
        }

    }, [nft])


    if (!nft) return (<></>)

    if (loading){
        return (
            <C.Loading>
                <FontAwesomeIcon icon={faSpinner} spin />
            </C.Loading>
        )
    }

    if (error){
        return (
            <C.Loading>
                There was an error loading this NFT
            </C.Loading>
        )
    }

    return (
        <C.NftView>
            <C.NftImage>
                <img src={nftData.image} />
            </C.NftImage>
            <C.NftInfo>
                <C.NftName>
                    {nftData.name}
                </C.NftName>
                <C.NftDescription>
                    {nftData.description}
                </C.NftDescription>
            </C.NftInfo>
            <C.Traits>
                {nftData.traits.map((trait: any, index : any) => {
                    return (
                        <C.Trait key={index}>
                            <C.TraitName>
                                {trait.trait_type.toUpperCase()}
                            </C.TraitName>
                            <C.TraitValue>
                                {trait.value}
                            </C.TraitValue>
                        </C.Trait>
                    )
                })}
            </C.Traits>
        </C.NftView>
    )

}

export default NftView