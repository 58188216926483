import React from "react"
import * as P from "./style"

import { useSelector } from "react-redux"
import BasePopup from "components/popups/base"
import { IconCollections } from "utils/icons"

import { showWalletConnectPopup, showWelcomePopup } from "services/slices/popup"
import Welcome from "components/parts/welcome"


const WelcomePopup = () => {

    const show = useSelector((state: any) => state.popup.WelcomePopup)
    
    return (
        <P.Popup>
            <BasePopup title="Welcome" show={show} hide={() => showWelcomePopup(false)} icon={<IconCollections />} glasmorph={true}>
                <Welcome/>
            </BasePopup>
        </P.Popup>
    )
}

export default WelcomePopup