import React from "react"
import * as P from "./style"

import { useDispatch, useSelector } from "react-redux"
import BasePopup from "components/popups/base"
import { IconCollections, IconConnectWallet } from "utils/icons"

import { showTreasurePopup } from "services/slices/popup"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBook } from "@fortawesome/free-solid-svg-icons"
import Treasure from "components/parts/treasure"
import { useNavigate } from "react-router-dom"


const TreasurePopup = () => {

    const show = useSelector((state: any) => state.popup.TreasurePopup)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const hide = () => {
        dispatch(showTreasurePopup(false))
        navigate("/app")
    }
    
    return (
        <P.Popup>
            <BasePopup title="Treasure" show={show} hide={hide} isredux={false} icon={<FontAwesomeIcon icon={faBook} />} glasmorph={true}>
                <Treasure/>
            </BasePopup>
        </P.Popup>
    )
}

export default TreasurePopup