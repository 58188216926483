import * as anchor from "@project-serum/anchor"
import env from 'env'
import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js"
import BigNumber from "bignumber.js"
import { SOLANA_CONNECTION_SHARE } from "../connection"
import { anchorWallet } from "../wallet"
import { IDL } from "./types"


export const getShareConfig = () => {
    const [address, _] = anchor.web3.PublicKey.findProgramAddressSync(
        [anchor.utils.bytes.utf8.encode('config')],
        new PublicKey(env.WEB3.SOLANA.SHARE_PROGRAM)
    )

    return address
}

export const getShareHoldingAccount = (cloudWalletPubkey: PublicKey, issuerKey: PublicKey) => {
    const [address, _] = anchor.web3.PublicKey.findProgramAddressSync(
        [anchor.utils.bytes.utf8.encode('share-holding'), cloudWalletPubkey.toBuffer(), issuerKey.toBuffer()],
        new PublicKey(env.WEB3.SOLANA.SHARE_PROGRAM)
    )

    return address
}

export const getIssuerAccount = (issuerKey: PublicKey) => {
    const [address, _] = anchor.web3.PublicKey.findProgramAddressSync(
        [anchor.utils.bytes.utf8.encode('issuer'), issuerKey.toBuffer()],
        new PublicKey(env.WEB3.SOLANA.SHARE_PROGRAM)
    )

    return address
}

export const calculatePrice = (totalShares: BigNumber, d: BigNumber) => {

    if (totalShares.isEqualTo(0)) return d;

    /*let term1 = a.times(totalShares.pow(2))
    let term2 = b.times(totalShares.pow(2))
    let term3 = c.times(totalShares)

    return term1
        .minus(term2)
        .plus(term3)
        .plus(d)*/

    return totalShares.pow(2).times(LAMPORTS_PER_SOL).div(200).plus(d)
}

export const computeTvl = (totalShares: BigNumber, d: BigNumber) => {
    let totalLiquidity = new BigNumber(0);
    for (let i = 0; i < totalShares.toNumber(); i++) {
        totalLiquidity = totalLiquidity.plus(calculatePrice(new BigNumber(i),  d))
    }
    return totalLiquidity;
}

export const formatPrice = (price: BigNumber) => {
    if (price.isLessThan(1)) {
        // If price is less than 1, format with up to 4 decimals
        let result = price.toFixed(4);
        // Remove trailing zeros after the decimal point
        while (result.includes('.') && (result.endsWith('0') || result.endsWith('.'))) {
            result = result.slice(0, -1);
        }
        return result;
    } else {
        // If price is 1 or greater, format with 2 decimals
        return price.toFixed(2);
    }
}

export const getIssuerAccountDataFromAddress: any = async (issuer: PublicKey) => new Promise(async (resolve, reject) => {

    try {

        const anchorConnection = new anchor.AnchorProvider(SOLANA_CONNECTION_SHARE, anchorWallet(), anchor.AnchorProvider.defaultOptions())
        const program = new anchor.Program(IDL as anchor.Idl, env.WEB3.SOLANA.SHARE_PROGRAM, anchorConnection)

        var account: any;

        try {
            account = await program.account.issuer.fetch(issuer)
        } catch (e) {
            return resolve(404)
        }

        return resolve(account)

    } catch (e) {
        reject(500)
    }

})