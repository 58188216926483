import React, { useEffect, useState } from "react"
import * as C from "./style"

import { useDispatch, useSelector } from "react-redux"
import Button from "components/ui/button"
import { color } from "styles/theme"
import { GetRandomGif, GetTwitterHandle, Hex2Rgba, toLink } from "utils/helpers"
import { WhitelistCollection } from "models/whitelist/collection"
import { _EntryRecruitTwitterNomination, _RecruitTwitterNomination } from "models/interfaces/recruitTypes"
import Input from "components/ui/input"
import { Controller, useForm } from "react-hook-form"
import { RemoveMoreNominations, SetInitialNominations, SetMoreNominations, VerifyNomination } from "services/api/whitelist"
import toast from "react-hot-toast"
import { showWhitelistNominatePopup } from "services/slices/popup"
import { setEntry } from "services/slices/whitelist"
import env from "env"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes, faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons"
import { ErrorCodes } from "utils/errorcodes"
import { store } from "services/store"

const WhitelistNominate = () => {

    const dispatch = useDispatch()

    const item: WhitelistCollection = useSelector((state: any) => state.whitelist.currentWhitelist)
    const wldata: any = useSelector((state: any) => state.whitelist)
    const recruit = item.recruitDetails as _RecruitTwitterNomination

    const [nominateMoreList, setNominateMoreList] = useState<any>([])
    const [checkingNomination, setCheckingNomination] = useState(false)
    const [page, setPage] = useState("")
    const [forgetNomination, setForgetNomination] = useState<any>([])

    const { handleSubmit, control, formState: { errors }, register, unregister } = useForm()

    useEffect(() => {
        if (wldata.entry.recruitDetails && wldata.entry.recruitDetails.nominations) {
            let rd = wldata.entry.recruitDetails as _EntryRecruitTwitterNomination
            if (rd.nominations.filter((x) => !x.verified).length > 0) {
                setPage("verify")
            } else {
                setPage("nominate")
            }
        } else {
            setPage("nominate")
        }
    }, [])

    const onSubmit = (data: any) => {

        const nominations: any = Object.values(data)

        SetInitialNominations(item.id, nominations).then((res) => {

            //if nominations doesnt have @ symbol on start add it
            const nominationsWithAt = nominations.map((nomination: string) => {
                if (nomination[0] !== "@") {
                    return "@" + nomination
                }
                return nomination
            })

            //open new tweet tab with tweet text
            const tweetText = `I ${recruit.nominationWord} ${nominationsWithAt.join(", ")}%0afor the${GetTwitterHandle(item.twitter)} ${item.name} whitelist!${item.nominationSuffix ? " " + item.nominationSuffix : ""}%0a%0aClaim your whitelist below%0a${env.API.WEB}app/whitelist/${toLink(item.name)}%0a${GetRandomGif(item)}`
            //const tweetText = `V abzvangr ${nominationsWithAt.join(", ")} sbe gur ${item.name} juvgryvfg! purpx vg bhg uggcf://uho3.rr/ncc/juvgryvfg/znyrsvphf-bevtvaf-tra-2`
            const tweetUrl = `https://twitter.com/intent/tweet?text=${tweetText}`
            if (store.getState().data.isMobile)
                window.location.href = tweetUrl
            else
                window.open(tweetUrl, '_blank')

            //dispatch(showWhitelistNominatePopup(false))
            setPage("verify")
            dispatch(setEntry(res.data.entry))

        }).catch((err) => {
            if (err.response.status !== 429) {
                if (err.response.data.code)
                    toast.error(ErrorCodes[err.response.data.code])
                else
                    toast.error("Error setting nominations")
            }
        })

    }

    const onSubmitNominateMore = (data: any) => {

        const nominations: any = Object.values(data)

        SetMoreNominations(item.id, nominations).then((res) => {

            //if nominations doesnt have @ symbol on start add it
            const nominationsWithAt = nominations.map((nomination: string) => {
                if (nomination[0] !== "@") {
                    return "@" + nomination
                }
                return nomination
            })

            const tweetText =`I ${recruit.nominationWord} ${nominationsWithAt.join(", ")}%0afor the${GetTwitterHandle(item.twitter)} ${item.name} whitelist!${item.nominationSuffix ? " " + item.nominationSuffix : ""}%0a%0aClaim your whitelist below%0a${env.API.WEB}app/whitelist/${toLink(item.name)}%0a${GetRandomGif(item)}`
            const tweetUrl = `https://twitter.com/intent/tweet?text=${tweetText}`
            if (store.getState().data.isMobile)
                window.location.href = tweetUrl
            else
                window.open(tweetUrl, '_blank')

            setPage("verify")
            dispatch(setEntry(res.data.entry))

        }).catch((err) => {
            //console.log(err)
            if (err.response.status !== 429) {
                if (err.response.data.code)
                    toast.error(ErrorCodes[err.response.data.code])
                else
                    toast.error("Error setting nominations")
            }
        })

    }

    const getTweetUrl = () => {

        const nominations: any = Object.values(wldata.entry.recruitDetails.nominations)

        const nominationsWithAt = nominations.filter((nomination : any) => nomination.verified === false).map((nomination: any) => {
            if (nomination.twitter[0] !== "@") {
                return "@" + nomination.twitter
            }
            return nomination.twitter
        })

        const tweetText = `I ${recruit.nominationWord} ${nominationsWithAt.join(", ")}%0afor the${GetTwitterHandle(item.twitter)} ${item.name} whitelist!${item.nominationSuffix ? " " + item.nominationSuffix : ""}%0a%0aClaim your whitelist below%0a${env.API.WEB}app/whitelist/${toLink(item.name)}%0a${GetRandomGif(item)}`
        const tweetUrl = `https://twitter.com/intent/tweet?text=${tweetText}`

        return tweetUrl

    }

    const isNominationsDone = () => {
        if (wldata.entry.recruitDetails && wldata.entry.recruitDetails.nominations) {
            let rd = wldata.entry.recruitDetails as _EntryRecruitTwitterNomination
            return rd.nominations.filter((x) => x.verified).length >= (item!.recruitDetails as _RecruitTwitterNomination).minimumNominationCount
        }

        return false
    }

    const hasMoreNominations = () => {
        if (wldata.entry.recruitDetails && wldata.entry.recruitDetails.nominations) {
            let rd = wldata.entry.recruitDetails as _EntryRecruitTwitterNomination
            //non verified nominations > minimumNominationCount
            return rd.nominations.filter((x) => !x.verified).length > 0
        }
    }

    const getNominationInputs = (index: number) => {

        //check if index is in forgetNomination list
        if (forgetNomination.includes(index)) {
            return ""
        }

        if (wldata.entry.recruitDetails && wldata.entry.recruitDetails.nominations && wldata.entry.recruitDetails.nominations[index]) {
            return wldata.entry.recruitDetails.nominations[index].twitter ? "@" + wldata.entry.recruitDetails.nominations[index].twitter : ""
        }
    }

    const nominateMore = () => {

        if (nominateMoreList.length >= recruit.maximumNominationCount - recruit.minimumNominationCount) {
            toast.error("Maximum nominations reached")
            return
        }

        //add 1 item to nominateMoreList
        let tw = {
            twitter: "",
            verified: false
        }

        setNominateMoreList([...nominateMoreList, tw])
    }

    const removeNominateMore = (index: number, name: any) => {
        //remove 1 item from nominateMoreList
        let list = [...nominateMoreList]
        list.splice(index, 1)
        setNominateMoreList(list)

        //check if name is in forgetNomination list
        let forget = [...forgetNomination]
        if (!forget.includes(name)) {
            forget.push(name)
            setForgetNomination(forget)
        }

        //unregister input
        unregister(name)

        if (list.length === 0) {
            if (hasMoreNominations()) {
                RemoveMoreNominations(item.id).then((res) => {
                    dispatch(setEntry(res.data.entry))
                }).catch((err) => {
                    //console.log(err)
                    toast.error("Error removing nominations")
                })
            }
        }
    }

    const checkNomination = () => {
        if (checkingNomination) return;

        setCheckingNomination(true)

        VerifyNomination(item!.id!).then((res: any) => {
            toast.success("Nominations verified")
            dispatch(setEntry(res.data.entry))
            setCheckingNomination(false)
            dispatch(showWhitelistNominatePopup(false))
        }).catch((err: any) => {
            if (err.response.status !== 429) {
                if (err.response.data.code)
                    toast.error(ErrorCodes[err.response.data.code])
                else
                    toast.error("Error verifying nomination")
            }
            setCheckingNomination(false)
        })
    }

    useEffect(() => {

        //add non verified nominations to nominateMoreList if more nominations than minimumNominationCount
        if (wldata.entry.recruitDetails && wldata.entry.recruitDetails.nominations) {
            let rd = wldata.entry.recruitDetails as _EntryRecruitTwitterNomination

            
            let nonVerifiedNominations = rd.nominations.filter((x) => x.verified === false)



            setNominateMoreList(nonVerifiedNominations)

        }

    }, [])

    return (
        <C.WhitelistNominate>



            {page === "verify" && (
                <>
                    <C.Verify>

                        <C.Title>
                            <span>Verify nominations for</span> {item.name}
                        </C.Title>

                        <C.DescriptionVerify>
                            After you <span>tweet your nominations,</span> we need to verify them. This is done by you clicking the "<span>Verify Nominations</span>" button below.
                        </C.DescriptionVerify>

                        <C.NominationsToBeVerifiedTitle>
                            Nominations to be verified

                            <C.TweetAgain>
                                <a href={getTweetUrl()} target="_blank">Tweet again <FontAwesomeIcon icon={faUpRightFromSquare} /></a>
                            </C.TweetAgain>
                        </C.NominationsToBeVerifiedTitle>

                        <C.NominationsToBeVerified>
                            {wldata.entry.recruitDetails.nominations.filter((x: any) => !x.verified).map((wl: any, i: number) => (

                                    <C.Nomination key={i}>
                                        <C.NominationTwitter>
                                            @{wldata.entry.recruitDetails && wl.twitter}
                                        </C.NominationTwitter>
                                    </C.Nomination>

                            

                            ))}
                        </C.NominationsToBeVerified>



                        <C.Description>
                            <span>We will be checking your last 10 tweet for the nominations you made.</span>
                        </C.Description>

                        <C.Buttons>
                            <Button block="true" type="button" theme="gradient-border-transparent-bg-on-hover" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)} onClick={() => setPage("nominate")}>Change Nominations</Button>
                            <Button block="true" disabled={checkingNomination} onClick={checkNomination} loading={checkingNomination ? "true" : "false"} type="button" theme="gradient" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)}>Verify Nominations</Button>
                        </C.Buttons>


                    </C.Verify>
                </>
            )}

            {page === "nominate" && (
                <>
                    <C.Title>
                        <span>Nominate people for</span> {item.name}
                    </C.Title>
                    <C.Description>
                        Write down <span>Twitter usernames</span> of people you want to nominate.
                    </C.Description>

                    {!isNominationsDone() && (
                        <form onSubmit={handleSubmit(onSubmit)}>

                            {Array(recruit.minimumNominationCount).fill('').map((_, i) => (
                                <React.Fragment key={i}>
                                    <Controller  name={"account_" + i} control={control} rules={{ required: true, minLength: 4 }} defaultValue={getNominationInputs(i)}
                                        render={({ field }) => <Input onBlur={field.onBlur} onChange={field.onChange} checked={field.value} xref={field.ref} placeholder="@..." type="text" defaultValue={getNominationInputs(i)} />}
                                    />
                                    {errors["account_" + i] && errors["account_" + i]!.type === "required" && <p>Twitter Username Required</p>}
                                    {errors["account_" + i] && errors["account_" + i]!.type === "minLength" && <p>Enter valid username</p>}
                                </React.Fragment>
                            ))}

                            <Button block="true" type="submit" theme="gradient" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)}>Nominate & Tweet</Button>

                        </form>
                    )}

                    {isNominationsDone() && (
                        <>
                            <C.AlreadyNominated>
                                {wldata.entry.recruitDetails.nominations.filter((x: any) => x.verified).map((wl: any, i: number) => (
                                    <React.Fragment  key={i}>
                                        {wldata.entry.recruitDetails && wldata.entry.recruitDetails.nominations[i].verified && (
                                            <C.Nomination>
                                                <C.NominationTwitter>
                                                    @{wldata.entry.recruitDetails && wldata.entry.recruitDetails.nominations[i].twitter}
                                                </C.NominationTwitter>
                                                <C.NominationVerified>
                                                    Verified
                                                </C.NominationVerified>
                                            </C.Nomination>
                                        )}
                                    </React.Fragment>

                                ))}
                            </C.AlreadyNominated>

                        </>
                    )}

                    {(wldata.entry.recruitDetails && wldata.entry.recruitDetails.nominations.filter((x: any) => x.verified).length >= (item.recruitDetails as _RecruitTwitterNomination).minimumNominationCount) && (
                        <C.NominateMore>
                            {(wldata.entry.recruitDetails as _EntryRecruitTwitterNomination).nominations.filter((x) => x.verified).length < recruit.maximumNominationCount && (
                                <Button onClick={nominateMore} block="true" theme="gradient-border-transparent-bg-on-hover" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)}>Nominate More +</Button>
                            )}

                            {nominateMoreList.length > 0 && (
                                <form onSubmit={handleSubmit(onSubmitNominateMore)}>


                                    {nominateMoreList.map((nm: any, i: number) => (
                                        <React.Fragment key={i}>
                                            <C.NominatemoreGroup >
                                                <Controller  name={"account_" + (recruit.minimumNominationCount + i)} control={control} rules={{ required: true, minLength: 4 }} defaultValue={getNominationInputs(recruit.minimumNominationCount + i)}
                                                    render={({ field }) => <Input onBlur={field.onBlur} onChange={field.onChange} checked={field.value} xref={field.ref} placeholder="@..." type="text" defaultValue={getNominationInputs((recruit.minimumNominationCount + i))} />}
                                                />
                                                <C.NominateMoreRemove onClick={() => removeNominateMore(i, "account_" + (recruit.minimumNominationCount + i))}>
                                                    <FontAwesomeIcon icon={faTimes} />
                                                </C.NominateMoreRemove>
                                            </C.NominatemoreGroup>
                                            {errors["account_" + (recruit.minimumNominationCount + i)] && errors["account_" + (recruit.minimumNominationCount + i)]!.type === "required" && <p>Required</p>}
                                            {errors["account_" + (recruit.minimumNominationCount + i)] && errors["account_" + (recruit.minimumNominationCount + i)]!.type === "minLength" && <p>Enter valid username</p>}
                                        </React.Fragment>
                                    ))}


                                    <Button block="true" type="submit" theme="gradient" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)}>Save & Tweet</Button>
                                    {/*
                                    {hasMoreNominations() && (
                                        <Button disabled={checkingNomination} onClick={checkNomination} loading={checkingNomination ? "true" : "false"} type="button" theme="gradient-border-transparent-bg-on-hover" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)}>Verify Nominations</Button>
                                    )}
                                    */}
                                </form>
                            )}
                        </C.NominateMore>
                    )}
                </>
            )}


        </C.WhitelistNominate >
    )
}

export default WhitelistNominate