import styled, { keyframes } from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { deviceMax } from "styles/media"
import { Link } from "react-router-dom"

export const ShuffleWinners = styled.div`
    
`
export const Loading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 24px;
    margin:32px 0;
`

export const Header = styled.div`
    display: flex;
    align-items: center;
    margin-bottom:24px;

    & > button {
        margin-right: 10px;
    }
`

export const Title = styled.div`
    
`

export const Tickets = styled.div`
    display: flex;
    justify-content: space-between;

    & > div:not(:last-child) {
        margin-right: 16px;
    }
    margin-bottom: 16px;
`

export const TicketButton = styled.div`
    background: ${Hex2Rgba(color.secondary, .16)};
    padding:8px 16px;
    border-radius: 8px;
    flex:1;
    text-align: center;
    cursor: pointer;
    &:hover {
        background: ${Hex2Rgba(color.secondary, .32)};
    }
    font-size: 14px;
`

export const ItemIdContainer = styled.div`
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    background: ${Hex2Rgba(color.secondary, .16)};
    
    border-radius: 8px;
`

export const ItemIdText = styled.div`
    
`

export const ItemId = styled.div`
    flex:1;
    justify-content: space-between;
    cursor: pointer;    
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding:8px 16px;
    &:hover {
        background: ${Hex2Rgba(color.secondary, .32)};
    }
`
export const ItemIdTitle = styled.div`

`

export const ItemIdValue = styled.div`
    color:${color.whiteShade};
    font-size: 13px;
   word-break: break-all;
`

export const ItemIdCopy = styled.div`
    background: ${Hex2Rgba(color.secondary, .16)};
    padding: 4px 8px;
    border-radius: 4px;
    margin-left: 8px;
`

export const Win = styled.div`
    display: flex;
    background: ${Hex2Rgba(color.black, 0.64)};
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
    position: relative;
    transition: all 0.1s ease-in-out;
    &:hover {
        background: ${Hex2Rgba(color.black, 1)};
    }
`

export const WinLeft = styled.div`

`

export const WinWinner = styled.div`
    display: flex;
    font-size: 14px;
`

export const WinWinnerName = styled.div`
    margin-right: 4px;
`

export const WinWinnerYou = styled.span`
    margin-left: 4px;
    font-size: 10px;
    background: linear-gradient(90deg, ${color.gradient3.start}, ${color.gradient3.end});
    font-weight:500;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    & span{
        font-size: 12px;
    }
`

export const WinWinnerAddress = styled.div`
    color:${color.whiteShade};
`

export const WinCount = styled.div<{me:string}>`
    background: linear-gradient(90deg, ${color.gradient1.start}, ${color.gradient1.middle}, ${color.gradient1.end});
    

    ${props => props.me === "true" && `
        background: linear-gradient(90deg, ${color.gradient3.start}, ${color.gradient3.end});
    `}

    font-weight:500;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 14px;
`

export const WinRight = styled.div`
    display: flex;
    align-items: center;
`

export const WinItems = styled.div`
    display: flex;
    position: relative;
    align-items: center;
`

export const WinItem = styled.div<{order:number}>`

    width: 50px;
    height: 50px;

    img {
        width:100%;
        height:100%;
        border-radius: 8px;
        object-fit: cover;
    }

    position: absolute;

    right: ${props => props.order * 35 }px;
    z-index: ${props => props.order};
    
    box-shadow: 0px 0px 5px 1px ${Hex2Rgba(color.black, .32)};
    border-radius: 8px;

    &:hover {
        z-index: 100;
    }

    
`

export const WinItemMore = styled.div`
    background: ${color.secondary};
    height: 50px;
    width: 50px;
    border-radius: 8px;
    font-size: 14px;
    color:${color.white};
    display: flex;
    align-items: center;
    justify-content: center;
`

export const WinAction = styled.div`
    padding-left:16px;
    font-size: 14px;
`