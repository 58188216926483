import React from "react"
import * as P from "./style"

import { useSelector } from "react-redux"
import BasePopup from "components/popups/base"
import { IconCollections } from "utils/icons"

import { showEditProfilePopup } from "services/slices/popup"
import EditProfile from "components/parts/editProfile"
import { displayEditProfile } from "utils/parts"


const EditProfilePopup = () => {

    const show = useSelector((state: any) => state.popup.EditProfilePopup)
    
    return (
        <P.Popup>
            <BasePopup title="Profile" show={show} hide={() => showEditProfilePopup(false)} icon={<IconCollections />} glasmorph={true}>
                <EditProfile hide={() => displayEditProfile(false)}/>
            </BasePopup>
        </P.Popup>
    )
}

export default EditProfilePopup