import React from "react"
import * as P from "./style"

import { useSelector } from "react-redux"
import BasePopup from "components/popups/base"
import { IconCollections } from "utils/icons"

import { showWhitelistNominatePopup } from "services/slices/popup"
import WhitelistNominate from "components/parts/whitelistNominate"


const WhitelistNominatePopup = () => {

    const show = useSelector((state: any) => state.popup.WhitelistNominatePopup)
    
    return (
        <P.Popup>
            <BasePopup title="Nominate People" show={show} hide={() => showWhitelistNominatePopup(false)} icon={<IconCollections />} glasmorph={true}>
                <WhitelistNominate/>
            </BasePopup>
        </P.Popup>
    )
}

export default WhitelistNominatePopup