import React from "react"
import * as P from "./style"

import { useSelector } from "react-redux"
import BasePopup from "components/popups/base"
import { IconCollections, IconConnectWallet, IconShuffle } from "utils/icons"

import { showShuffleMyTicketsPopup } from "services/slices/popup"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBook } from "@fortawesome/free-solid-svg-icons"
import ShuffleMyTicts from "components/parts/shuffle/shuffleMyTickets"

const ShuffleMyTicketsPopup = () => {

    const show = useSelector((state: any) => state.popup.ShuffleMyTicketsPopup)
    const viewingWinner = useSelector((state: any) => state.shuffle.viewingWinner)
    
    return (
        <P.Popup viewingWinner={viewingWinner ? "true" : "false"}>
            <BasePopup title="My Tickets" show={show} hide={() => showShuffleMyTicketsPopup(false)} icon={<IconShuffle />} glasmorph={true}>
                <ShuffleMyTicts />
            </BasePopup>
        </P.Popup>
    )
}

export default ShuffleMyTicketsPopup