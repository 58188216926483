import { PublicKey, Connection } from "@solana/web3.js"
import * as spltoken from '@solana/spl-token'
import { METADATA_PROGRAM_ID, SOLANA_CONNECTION } from "../connection"
import { shortenPublicKey } from "utils/helpers"
import { findWallet } from "modules/wallet"
import { store } from "services/store"
import { toAnchorWallet } from "../wallet"
import { IDL } from "./types"
import * as anchor from "@project-serum/anchor"

export const ParseAwardAddress = (address: string) => {

    switch (address) {
        case "So11111111111111111111111111111111111111111": {
            return {
                symbol: "SOL",
                name: "SOL",
                image: "https://hub3cdn.b-cdn.net/shuffle/solana.png"
            }
        }
        case "FoXyMu5xwXre7zEoSvzViRk3nGawHUp9kUh97y2NDhcq": {
            return {
                symbol: "FOXY",
                name: "FOXY",
                image: "https://hub3cdn.b-cdn.net/shuffle/foxy.png"
            }
        }
        case "DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263": {
            return {
                symbol: "BONK",
                name: "BONK",
                image: "https://hub3cdn.b-cdn.net/shuffle/bonk.png"
            }
        }
        case "DUSTawucrTsGU8hcqRdHDCbuYhCPADMLM2VcCb8VnFnQ": {
            return {
                symbol: "DUST",
                name: "DUST",
                image: "https://hub3cdn.b-cdn.net/shuffle/dust.png"
            }
        }
        case "ErW36kRZKzGDmfbqRBnsZ3tRrMXeQSkRRQfdCpHEhCwc": {
            return {
                symbol: "BONK",
                name: "BONK",
                image: "https://hub3cdn.b-cdn.net/shuffle/bonk.png"
            }
        }
        case "6FnBennzSUCrY1fMdmdppyWGsUuWG16gkkcAww6nzooQ": {
            return {
                symbol: "TRBZ",
                name: "Tribuzz Whitelist",
                image: "https://hub3cdn.b-cdn.net/shuffle/tribuzz.png"
            }
        }
        default: {
            return {
                symbol: shortenPublicKey(address),
                name: shortenPublicKey(address),
                image: ""
            }
        }
    }
    /*

    if (address === "So11111111111111111111111111111111111111111") {
        return {
            symbol: "SOL",
            name: "SOL",
            image: "https://hub3cdn.b-cdn.net/shuffle/solana.png"
        }
    } else if (address === "ErW36kRZKzGDmfbqRBnsZ3tRrMXeQSkRRQfdCpHEhCwc") {
        return {
            symbol: "BONK",
            name: "BONK",
            image: "https://hub3cdn.b-cdn.net/shuffle/bonk.png"
        }
    \
    } else {
        return {
            symbol: shortenPublicKey(address),
            name: shortenPublicKey(address),
            image: ""
        }
    }*/
}

export const ParseCollectionAddress = (address: string) => {

    if (address === "G86Ygxj8dZpqtJd755DkmodG5crCgCCcv5oW1tn8UKm5") {
        return {
            symbol: "CURSED",
            name: "The cursed",
            image: "https://hub3cdn.b-cdn.net/shuffle/cursed.png"
        }
    }
    else if (address === "5ucdKHbPfmsWowXSNfHoiYEJ55kfFmvEdHii5RtjHpiH") {
        return {
            symbol: "WOLF",
            name: "Wolf Capital",
            image: "https://bafkreigkpk7dhvloh4bozoiclsam7idsdpdpoq2z3j7ax6i27tsekkqhdy.ipfs.nftstorage.link/"
        }
    }
    else if (address === "aLs8rXD8NoYwgnCFzBofsGdwaocePAkzT1UFX1WmwaR") {
        return {
            symbol: "BASC",
            name: "Bored Ape Solana Club",
            image: "https://hub3cdn.b-cdn.net/shuffle/basc.png"
        }
    }
    else if (address === "DWnfqwBVrauAFuXwA78HZCokm8bHvWKjJJtgeS4DYHVY") {
        return {
            symbol: "SD",
            name: "SoDead",
            image: "https://hub3cdn.b-cdn.net/shuffle/sodead.png"
        }
    }
    else if (address === "DFkPzn2cbTV3QXEoFJDeFhQ3r7rnguar7bVdrTvLswwH") {
        return {
            symbol: "GMers",
            name: "GMers",
            image: "https://hub3cdn.b-cdn.net/shuffle/gmers.png"
        }
    }
    else if (address === "GAoTBSfDhZXptwmERbDULmXUCSBwsmLXP3ydQK5AtVtr") {
        return {
            symbol: "JellyDawgs",
            name: "JellyDawgs",
            image: "https://hub3cdn.b-cdn.net/shuffle/jellydawgs.png"
        }
    }
    else if (address === "LouoPer39a8x9KZ4nWXmeSFetgorr82pEErhU9EmxZW") {
        return {
            symbol: "CETS",
            name: "CETS",
            image: "https://hub3cdn.b-cdn.net/shuffle/cets.png"
        }
    }
    else if (address === "FSw4cZhK5pMmhEDenDpa3CauJ9kLt5agr2U1oQxaH2cv") {
        return {
            symbol: "GHOST",
            name: "GhostKidDao",
            image: "https://hub3cdn.b-cdn.net/shuffle/ghostkid.webp"
        }
    }else if (address === "CKPYygUZ9aA4JY7qmyuvxT67ibjmjpddNtHJeu1uQBSM") {
        return {
            symbol: "CC",
            name: "Critters Cult",
            image: "https://hub3cdn.b-cdn.net/shuffle/critterscult.png"
        }
    }else if (address === "8GA5R4BqCnPehnsJqVnN53i34V2SKGJTNsEEZsdC6pj6") {
        return {
            symbol: "ABAS",
            name: "Auto-Breacher by Anti Socials",
            image: "https://hub3cdn.b-cdn.net/shuffle/abas.gif"
        }
    }else if (address === "HbPtffcEzzSzZ1VJaTjaAJqTUQpfNeMkPqNG81dWE2Bi") {
        return {
            symbol: "TFF",
            name: "Transdimensional Fox Federation",
            image: "https://hub3cdn.b-cdn.net/shuffle/tff.png"
        }
    }else if (address === "2CNP3MVmCj5FEFja676PkvS8Rm7ZVCxdsPWkLgqHb87e") {
        return {
            symbol: "DC",
            name: "Doge Capital",
            image: "https://hub3cdn.b-cdn.net/shuffle/dogecapital.png"
        }
    }else if (address === "DQZJ8QdZb1Qm6Vm35EzkxrXFUkrJqNAZEzZzTNiuXywE") {
        return {
            symbol: "TFC",
            name: "The Fox Club",
            image: "https://hub3cdn.b-cdn.net/shuffle/foxclub.png"
        }
    }
    else {
        return null;
    }
}

export const getAssociatedTokenAccount = async (ownerPubkey: PublicKey, mintPk: PublicKey): Promise<PublicKey> => {
    let associatedTokenAccountPubkey = (await PublicKey.findProgramAddress(
        [
            ownerPubkey.toBuffer(),
            spltoken.TOKEN_PROGRAM_ID.toBuffer(),
            mintPk.toBuffer(), // mint address
        ],
        spltoken.ASSOCIATED_TOKEN_PROGRAM_ID
    ))[0];
    return associatedTokenAccountPubkey;
}

export const getATokenAccountsNeedCreate = async (wallet: PublicKey, owner: PublicKey, mint: PublicKey) => {

    let atokenix: any = null;
    let destatokenAccount: any = null;

    const destinationAccountPubkey = await getAssociatedTokenAccount(owner, mint)
    let response = await SOLANA_CONNECTION.getAccountInfo(destinationAccountPubkey)
    if (!response) {
        const createATAIx = spltoken.createAssociatedTokenAccountInstruction(
            wallet,
            destinationAccountPubkey,
            owner,
            mint
        )
        atokenix = createATAIx
    }
    destatokenAccount = destinationAccountPubkey

    return { atokenix, destatokenAccount };
}

export const getRulesetOfPNFT = async (programId:string, address:string) => {

    const wallet = findWallet(store.getState().user.selectedWallet)

    if (!wallet) {
        throw new Error('No wallet selected')
    }

    const anchorConnection = new anchor.AnchorProvider(SOLANA_CONNECTION, toAnchorWallet(wallet), anchor.AnchorProvider.defaultOptions())

    const program = new anchor.Program(IDL as anchor.Idl, programId, anchorConnection)

    const [collectionDatasPDA, __] = anchor.web3.PublicKey.findProgramAddressSync(
        [anchor.utils.bytes.utf8.encode('collection-datas')],
        program.programId
    )

    const collectionDatas : any = await program.account.collectionDatas.fetch(collectionDatasPDA)

    let returnData = {
        collectionDatasPDA,
        ruleset: PublicKey.unique()
    }

    for (let i = 0; i < collectionDatas.datas.length; i++) {

        if (collectionDatas.datas[i].address.toBase58() === address) {
            
            if (collectionDatas.datas[i].standard === 1) {
                returnData.ruleset = collectionDatas.datas[i].ruleset
            }

            break;
        }
    }

    return returnData;

}

export function findTokenRecordPda(mint: PublicKey, token: PublicKey): PublicKey {
    return PublicKey.findProgramAddressSync(
      [
        Buffer.from('metadata'),
        METADATA_PROGRAM_ID.toBuffer(),
        mint.toBuffer(),
        Buffer.from('token_record'),
        token.toBuffer(),
      ],
      METADATA_PROGRAM_ID,
    )[0];
  }
  