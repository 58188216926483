import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface _CollectionsState {
    _collections : any,
    _collectionsPaginated : any,
    chain : any,
    filter : any,
    sort : any,
    searchText : any,
    page : any,
}

const initialState: _CollectionsState = {
    _collections : [],
    _collectionsPaginated : [],
    chain : "all",
    filter : "all",
    sort : "all",
    searchText : "",
    page : 1,
}

export const _collectionsSlice = createSlice({
    name: '_collections',
    initialState,
    reducers: {
        set_Collections: (state, action: PayloadAction<any[]>) => {
            state._collections = action.payload
        },
        set_CollectionsPaginated: (state, action: PayloadAction<any[]>) => {
            state._collectionsPaginated = action.payload
        },
        setFilterValues: (state, action: PayloadAction<any>) => {
            state.filter = action.payload.filter
            state.chain = action.payload.chain
            state.sort = action.payload.sort
        },
        setSearchValue: (state, action: PayloadAction<any>) => {
            state.searchText = action.payload
        },
        setPageValue: (state, action: PayloadAction<any>) => {
            state.page = action.payload
        }
    }
})

export const { set_Collections, setFilterValues, setSearchValue, setPageValue, set_CollectionsPaginated } = _collectionsSlice.actions

export default _collectionsSlice.reducer