import Input from "components/ui/input"
import React, { useState, useEffect, useMemo } from "react"
import * as C from './style'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { useSelector } from "react-redux";
import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { useYupValidationResolver } from "hooks/useYupValidationResolver";
import Button from "components/ui/button"
import { color } from "styles/theme";
import { Hex2Rgba } from "utils/helpers";
import { verifyShuffleTicket } from "modules/solana/shuffle/raffle";
import { toast } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";

const ShuffleFair = () => {

    const programId = useSelector((state: any) => state.shuffle.programId)
    const [loading, setLoading] = useState(false)
    const [result, setResult] = useState<any>(null)
    const [pdasVisible, setPdasVisible] = useState(false)
    const [ticketsVisible, setTicketsVisible] = useState(false)
    const [inventoryVisible, setInventoryVisible] = useState(false)
    const [salesVisible, setSalesVisible] = useState(false)

    const schema = useMemo(
        () =>
            yup.object({
                shuffleId: yup.string().required("Shuffle Id is required"),
                hash: yup
                    .string()
                    .required("Hash is required")
                    .matches(
                        /^[a-f0-9]{64}$/i,
                        "Hash must be a valid SHA256 hash"
                    ),
            }),
        []
    );

    const resolver = useYupValidationResolver(schema)

    const onSubmit = (data: any) => {

        if (loading) return;

        setLoading(true)

        verifyShuffleTicket(programId, data.shuffleId, data.hash).then((res) => {

            setResult(res)

        }).catch((err) => {
            toast.error("Something went wrong")
        }).finally(() => {
            setLoading(false)
        })

    }

    const { handleSubmit, control, formState: { errors } } = useForm({ resolver })

    return (
        <C.ShuffleFair>
            <C.Whatis>
                <C.Title>What is Provably Fair?</C.Title>
                <C.Text>
                    "Provably fair" is a term used to describe a gaming system that lets players verify that the game's outcome hasn't been tampered with by the operator. It uses transparent and verifiable algorithms, such as hashing and random number generation, to ensure that the outcome is fair and random. This promotes trust and transparency.
                </C.Text><br />
                <C.Title>How does it work in Shuffle?</C.Title>
                <C.Text>
                    Shuffle provably fair code is used to generate winners for the Shuffle game. The first step involves creating a seed by combining the public keys of the accounts used to purchase tickets for the game. This seed is then hashed using the SHA256 algorithm.
                    <br /><br />
                    The code uses two rounds of random number generation to select the winners. First, it generates a set of random numbers from the previously generated hash. These random numbers are then used to select winning tickets from the available pool of tickets.
                    <br /><br />
                    Once the winning tickets have been selected, a second set of random numbers is generated to determine the corresponding prize for each winner. These random numbers are generated using the previous hash and the length of the inventory.
                    <br /><br />
                    The code then iterates through the list of winning tickets and assigns them their corresponding prize. The winning tickets and their corresponding prizes are stored in an array.
                    <br /><br />
                    Overall, this code ensures that the winners for the Shuffle game are selected in a fair and random manner using a combination of hashing and random number generation.
                </C.Text>
            </C.Whatis>

            <C.SourceCode>
                <C.Title>Shuffle Algorithm</C.Title>
                <C.Code>
                    <SyntaxHighlighter language="javascript" style={a11yDark} >
                        {`const seed = purchasePDAs.map(p => p.publicKey.toBase58()).join("")
const hash = crypto.createHash('sha256').update(seed).digest('hex')
const tickets = shuffle.ticketSales

const inventory = []
for (let i = 0; i < shuffle.inventory.length; i++) {
    for (let j = 0; j < shuffle.inventory[i].count[0].toNumber(); j++) {
        inventory.push({type: shuffle.inventory[i].itemType, index: i })
    }
}

const winners = generateRandomNumbersFromHash(hash, inventory.length)
const wins = generateRandomNumbersFromHash(winners.previousHash, inventory.length)

const winnerTickets = []
for (let i = 0; i < inventory.length; i++) {
    let winnerIndex = Math.round(winners.randomNumbers[i] * (shuffle.ticketSales.length - 1))
    let winner = tickets[winnerIndex]
    tickets.splice(winnerIndex, 1)

    let winIndex = Math.round(wins.randomNumbers[i] * (inventory.length - 1))
    let win = inventory[winIndex]
    inventory.splice(winIndex, 1)
    winnerTickets.push({
        ticket: winner,
        win: win
    })
}

const generateRandomNumbersFromHash = (hashValue, numOfRandoms) => {
    let randomNumbers = [];
    let currentHash = hashValue;
    let hashLen = currentHash.length;
    let n = 0;
    for (let i = 0; i < numOfRandoms; i++) {
        let start = n * 8;
        let end = (n + 1) * 8;

        if (end > hashLen) {
            let hasher = crypto.createHash("sha256");
            hasher.update(currentHash + i);
            currentHash = hasher.digest("hex");
            hashLen = currentHash.length;
            n = 0;
            start = 0;
            end = 8;
        }

        let hashInt = parseInt(currentHash.slice(start, end), 16);
        let randomNumber = hashInt / Math.pow(16, 8);
        randomNumbers.push(randomNumber);
        n += 1;
    }

    return {randomNumbers, previousHash: currentHash};
}
`}
                    </SyntaxHighlighter>
                </C.Code>
            </C.SourceCode>


            <C.VerificationForm onSubmit={handleSubmit((data) => onSubmit(data))}>
                <C.Title>Verification</C.Title>
                <label>Shuffle Id</label>
                <Controller name="shuffleId" control={control}
                    render={({ field }) => <Input onBlur={field.onBlur} onChange={field.onChange} checked={field.value} xref={field.ref} type="text" placeholder="Shuffle Hash..." />}
                />
                {errors.shuffleId && (<p>{errors.shuffleId.message as string}</p>)}
                <label>Hash</label>
                <Controller name="hash" control={control}
                    render={({ field }) => <Input onBlur={field.onBlur} onChange={field.onChange} checked={field.value} xref={field.ref} type="text" placeholder="Shuffle Hash..." />}
                />
                {errors.hash && (<p>{errors.hash.message as string}</p>)}
                <Button type="submit" theme="gradient-border-transparent-bg-on-hover" bg={Hex2Rgba(color.secondaryFade, .5)} color="white" disabled={loading} loading={loading ? "true" : "false"}>Submit</Button>
            </C.VerificationForm>

            <C.Title>Output</C.Title>
            <C.Result>

                {result && (
                    <>

                        <C.ResultItem>
                            <C.ResultTitle onClick={() => setPdasVisible(!pdasVisible)}>
                                Purchase Accounts <FontAwesomeIcon icon={pdasVisible ? faCaretDown : faCaretRight} />
                            </C.ResultTitle>
                            <C.ResultValue visible={pdasVisible ? "true" : "false"}>{result && result.pdas.join(", ")}</C.ResultValue>
                        </C.ResultItem>

                        <C.ResultItem>
                            <C.ResultTitle onClick={() => setTicketsVisible(!ticketsVisible)}>
                                Winning Tickets  <FontAwesomeIcon icon={ticketsVisible ? faCaretDown : faCaretRight} />
                            </C.ResultTitle>
                            <C.ResultValueTickets visible={ticketsVisible ? "true" : "false"}>
                                {result && result.tickets.map((ticket: any, index: number) => (
                                    <div key={index}>
                                        <span>#{ticket.ticket} • item: {ticket.win.index}</span>
                                    </div>
                                ))}
                            </C.ResultValueTickets>
                        </C.ResultItem>

                        <C.ResultItem>
                            <C.ResultTitle onClick={() => setInventoryVisible(!inventoryVisible)}>
                                Inventory  <FontAwesomeIcon icon={inventoryVisible ? faCaretDown : faCaretRight} />
                            </C.ResultTitle>
                            <C.ResultValueTickets visible={inventoryVisible ? "true" : "false"}>
                                {result && result.inventory.map((item: any, index: number) => (
                                    <div key={index}>
                                        <span>item: {item.index} count: {item.count} address: {item.address.toBase58()}</span>
                                    </div>
                                ))}
                            </C.ResultValueTickets>
                        </C.ResultItem>

                        <C.ResultItem>
                            <C.ResultTitle onClick={() => setSalesVisible(!salesVisible)}>
                                Sold Tickets  <FontAwesomeIcon icon={salesVisible ? faCaretDown : faCaretRight} />
                            </C.ResultTitle>
                            <C.ResultValueTickets visible={salesVisible ? "true" : "false"}>
                                {result && result.sales.map((sale: any, index: number) => (
                                    <div key={index}>
                                        <span># {sale} </span>
                                    </div>
                                ))}
                            </C.ResultValueTickets>
                        </C.ResultItem>

                    </>
                )}


            </C.Result>
        </C.ShuffleFair>
    )
}

export default ShuffleFair