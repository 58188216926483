import styled, { keyframes } from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { deviceMax } from "styles/media"
import { Link } from "react-router-dom"

export const ShuffleWinnerWidget = styled.div`
    display:flex;
    align-items:center;

    & button{
        font-weight: 600;
        font-size: 14px;
    }
`

export const Loading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 24px;
    margin:32px 0;
`

export const WinnerItem = styled.div`

    & img{
        width:100%;
        border-radius:8px;
    }

`

export const ShuffleWinningItems = styled.div`
    width:40%;
    & *{
        outline: none !important;
    }

    & .slick-list {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }

    & .slick-slide {
        transition: transform 0.2s ease;
    }

    & .slick-center {
        transform: scale(1.4);

        & > div {
            display: flex;
            align-items: center;
        }

    }

    & .slick-next {
        right: 16px;
        z-index: 1;
    }

    & .slick-prev {
        left: 16px;
        z-index: 1;
    }

`

export const ShuffleWinnerText = styled.div`
    margin-left: 12px;
    display:flex;
    flex-direction:column;
    align-items:center;

`
export const ShuffleWinnerTitle = styled.div`

    background: linear-gradient(90deg, ${color.gradient3.start}, ${color.gradient3.end});
    font-weight:bold;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 20px;
    line-height: 1.1;

`

export const ShuffleWinnerSubTitle = styled.div`
    font-size: 12px;
    color: ${color.whiteShade};
    margin-bottom: 4px;
`