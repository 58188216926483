import React from 'react'
import styled from 'styled-components'
import { color as colors } from "styles/theme"
import { Link as link } from "react-router-dom"

const color : any = colors;

const custom = styled(
    ({ tag = 'div', children, ...props }) =>
        tag === "div" ? 
        React.createElement(tag, {...props, tabIndex:"0"}, children)
        :
        React.createElement(tag, props, children)
)

const getbg = (props: any) => {
    if (typeof props.bg === "undefined")
        return color.white
    else
        return typeof color[props.bg] === "undefined" ? props.bg : color[props.bg]
}

const getborder = (props: any) => {
    if (typeof props.border === "undefined")
        return color.white
    else
        return typeof color[props.border] === "undefined" ? props.border : color[props.border]
}

const getcolor = (props: any) => {
    if (typeof props.color === "undefined")
        return color.black
    else
        return typeof color[props.color] === "undefined" ? props.color : color[props.color]
}

export const Button = custom`
    display: inline-block;
    font-weight: ${props => props.weight ? props.weight : "normal"};
    line-height: 1.5;
    font-size:14px;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    user-select: none;
    cursor:pointer;
    width: ${props => typeof props.width !== "undefined" ? props.width : props.block ? "100%" : "auto"};

    

    color: ${props => {
        if (props.theme === "primary-solid")
            return color.black
        else if (props.theme === "primary")
            return color.white
        else
            return getcolor(props)
    }};

    ${props => {
        if (props.theme === "gradient") {
            if (props.fromcolor && props.tocolor) {
            return `
                background-image: linear-gradient(to right, ${props.fromcolor ? props.fromcolor : color.secondary}, ${props.tocolor ? props.tocolor : color.primary});
            `
            }
            else {
            return `
                background-image: linear-gradient(to right, ${color.gradient2.start}, ${color.gradient2.middle}, ${color.gradient2.end});
            `
            }
        }

        if (props.theme === "gradient-border") {
            return `
                background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #78e4ff, #ff48fa);
                background-origin: border-box;
                background-clip: content-box, border-box;
                -webkit-background-clip: content-box, border-box;
                border:2px solid transparent;
                box-shadow: 2px 1000px 1px ${props.bg ? props.bg : "#fff"} inset !important;
            `
        }

        if (props.theme === "gradient-border-transparent-bg") {
            return `
            position:relative;
            border:0px !important;
            &::before {
                content: "";
                position: absolute;
                inset: 0;
                border-radius: 24px; 
                padding: 2px; 
                background:linear-gradient(45deg,${props.fromcolor ? props.fromcolor : color.primary},${props.fromcolor ? props.fromcolor : color.secondary}); 
                -webkit-mask: 
                linear-gradient(#fff 0 0) content-box, 
                linear-gradient(#fff 0 0);
                -webkit-mask-composite: xor;
                        mask-composite: exclude; 
            }
            `
        }

        if (props.theme === "gradient-border-transparent-bg-on-hover") {
            return `
            position:relative;
            border:0px !important;
            &:hover::before {
                content: "";
                position: absolute;
                inset: 0;
                border-radius: 24px; 
                padding: 2px; 
                background:linear-gradient(45deg,${props.fromcolor ? props.fromcolor : color.primary},${props.fromcolor ? props.fromcolor : color.secondary}) !important; 
                -webkit-mask: 
                linear-gradient(#fff 0 0) content-box, 
                linear-gradient(#fff 0 0);
                -webkit-mask-composite: xor;
                        mask-composite: exclude; 
            }
            `
        }
    }}
    
    background-color: ${props => {
        if (props.theme === "primary-solid")
            return color.white
        else if (props.theme === "primary")
            return color.primary
        else
            return getbg(props)
    }};
    border-radius:24px;
    ${props => {
        if (!props.border) {
            if (props.theme === "primary-solid")
                return "border:1px solid " + color.primary + ";"
            else if (props.theme === "primary")
                return "border:1px solid " + color.primary + ";"
            else if (props.theme === "gradient-border" || props.theme === "gradient-border-inactive")
                return "border:2px solid transparent" + ";"
            else if (props.theme === "gradient")
                return "border:0px" + ";"
            else
                return "border:1px solid " + getbg(props) + ";"
        } else {
            return "border:1px solid " + getborder(props) + ";"
        }
    }};
    padding:${props => props.large ? "12px 20px" : props.small ? "4px 12px" : "8px 16px"};
    ${props => props.theme !== "gradient-border-inactive" ? "transition: all 0.1s ease-in-out;" : ""}

    &:hover{

        ${props => {
            if (props.theme === "gradient")
                return `
                    opacity: 0.8;
                `   
        }}

        ${props => {
            if (props.theme !== "gradient-border-transparent-bg-on-hover" && props.theme !== "gradient-border-transparent-bg") {

                if (props.theme === "primary-solid")
                    return "background-color:" + color.white + ";"
                else if (props.theme === "primary")
                    return "background-color:" + color.primary + ";"
                else if (props.theme === "gradient-border" || props.theme === "gradient-border-inactive" || props.theme === "gradient")
                    return "background-color:" + (props.bg ? props.bg : getcolor(props)) + ";"
                else
                    return "background-color:" + getcolor(props) + ";"
            }
        }}

        
        ${props => {
            if (!props.border) {
                if (props.theme === "primary-solid")
                    return "border: 1px solid " + color.primary + ";"
                else if (props.theme === "primary")
                    return "border:1px solid " + color.primary + ";"
                else if (props.theme === "gradient-border" || props.theme === "gradient-border-inactive")
                    return "border:2px solid transparent" + ";"
                else if (props.theme === "gradient")
                    return "border:0px solid transparent" + ";"
                else
                    return "border:1px solid " + getbg(props) + ";"
            } else {
                return "border: 1px solid " + getborder(props) + ";"
            }
        }};

        ${props => {
            if (props.theme !== "gradient-border-transparent-bg-on-hover" && props.theme !== "gradient-border-transparent-bg")
                if (props.theme === "primary-solid")
                    return "color:" + color.primary + ";"
                else if (props.theme === "primary") 
                    return "color:" + color.white + ";"
                else if (props.theme === "gradient-border" || props.theme === "gradient-border-inactive" || props.theme === "gradient")
                    return "color:" + (props.color ? props.color : color.white) + ";"
                else
                    return "color:" + getbg(props) + ";"
            }
        }

        box-shadow:0px 8px 12px ${props => {
            if (props.theme === "primary-solid")
                return color.primaryShadow
            else if (props.theme === "primary")
                return color.primaryShadow
            else if (props.theme === "gradient-border" || props.theme === "gradient-border-inactive")
                return getbg(props) + " !important"
            else
                return getbg(props)
        }};
    }

    &:focus{
        ${props => {
            if (props.theme !== "gradient-border-transparent-bg-on-hover" && props.theme !== "gradient-border-transparent-bg") {

                if (props.theme === "primary-solid")
                    return "background-color:" + color.white + ";"
                else if (props.theme === "primary")
                    return "background-color:" + color.primary+ ";"
                else if (props.theme === "gradient-border" || props.theme === "gradient-border-inactive" || props.theme === "gradient")
                    return "background-color:" + (props.bg ? props.bg : getcolor(props))+ ";"
                else
                    return "background-color:" + getcolor(props)+ ";"
            }
        }}
        
        ${props => {
            if (!props.border) {
                if (props.theme === "primary-solid")
                    return "border: 1px solid " + color.primary + ";"
                else if (props.theme === "primary")
                    return "border:1px solid " + color.primary + ";"
                else if (props.theme === "gradient-border" || props.theme === "gradient-border-inactive")
                    return "border:2px solid transparent" + ";"
                else if (props.theme === "gradient")
                    return "border:0px solid transparent" + ";"
                else
                    return "border:1px solid " + getbg(props) + ";"
            } else {
                return "border: 1px solid " + getborder(props) + ";"
            }
        }};

        ${props => {
            if (props.theme !== "gradient-border-transparent-bg-on-hover" && props.theme !== "gradient-border-transparent-bg") {
                if (props.theme === "primary-solid")
                    return "color:" + color.primary+ ";"
                else if (props.theme === "primary")
                    return "color:" + color.white+ ";"
                else if (props.theme === "gradient-border" || props.theme === "gradient-border-inactive" || props.theme === "gradient")
                    return "color:" + (props.color ? props.color : color.white) + ";"
                else
                    return "color:" + getbg(props)+ ";"
            }
        }}
    }

    ${props => {
        if (props.theme === "gradient-border-inactive") {
            return `
                &:hover,&:focus{
                    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #78e4ff, #ff48fa);
                    background-origin: border-box;
                    background-clip: content-box, border-box;
                    -webkit-background-clip: content-box, border-box;
                    border:2px solid transparent;
                    box-shadow: 2px 1000px 1px ${props.bg ? props.bg : "#fff"} inset !important;
                    color:${color.white} !important;
                }
            `
        }
    }}

    & > .spinner___btn{
        margin-right:8px;
    }

    &:disabled{
        opacity:0.8;
        cursor:default
    }
`

