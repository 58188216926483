import styled from "styled-components"

import { Dialog, Page as BasePage, DialogHeader } from "../base/style"
import { color } from "styles/theme"

export const Page = styled.div`
    & ${Dialog}{
        padding:0;
    }
    & ${DialogHeader}{
        padding:20px 20px 0px 20px;
    }
     @media (max-height: 960px) {
        & ${Dialog} {
            width: calc(100% - 200px);
            margin:40px 55px 40px auto;
        }
    }
`