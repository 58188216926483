import styled, { keyframes } from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { deviceMax } from "styles/media"
import { Link } from "react-router-dom"

export const ShuffleWinner = styled.div`

`

export const Confetti = styled.div`
    
`

export const WinningHeader = styled.div`
    border-radius: 8px;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 8px;
        padding: 1px;
        background:linear-gradient(45deg,${Hex2Rgba(color.gradient3.start, 1)},${Hex2Rgba(color.gradient3.end, 1)}); 
        -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude; 
        pointer-events: none;
    }

    text-align: center;
    background:linear-gradient(45deg,${Hex2Rgba(color.gradient3.start, .08)},${Hex2Rgba(color.gradient3.end, .08)}); 
    padding: 20px 0;

    margin-bottom: 16px;

`

export const WinningTitle = styled.div`

    background: linear-gradient(90deg, ${color.gradient3.start}, ${color.gradient3.end});
    font-weight:600;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size:20px;
    margin-bottom: 4px;
`

export const WinningText = styled.div`
    color: ${color.whiteShade};
    font-size: 14px;
`

export const Share = styled.div`
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top:10px;
    right: 10px;
    width: 100%;
    z-index: 1;
    pointer-events: none;
`

export const ShareButton = styled.div`
    background: ${Hex2Rgba(color.twitter, 1)};
    padding: 4px 8px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    font-size: 12px;
    & svg {
        margin-right: 4px;
    }
    pointer-events: all;
    
    cursor: pointer;
    transition: all .1s ease-in-out;

    &:hover {
        background: #095f93;
    }
`

export const Prizes = styled.div`

`

export const Prize = styled.div<{claimed:string}>`
    background: ${Hex2Rgba(color.secondary, 0.16)};
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 8px;

    ${props => props.claimed === "true" && `
        opacity: .5;
    `}
`

export const PrizeImage = styled.div`
    flex:.8;
    display: flex;
    align-items: center;
    & img {
        width: 100%;
        border-radius: 8px;
    }
    position: relative;

    /*&::after {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 8px;
        padding: 1px;
        background:linear-gradient(45deg,${Hex2Rgba(color.gradient3.start, 1)},${Hex2Rgba(color.gradient3.end, 1)}); 
        -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude; 
        pointer-events: none;
    }*/
`

export const PrizeInfo = styled.div`
    flex:2;
    padding-left: 12px;
`

export const PrizeTitle = styled.div`

`

export const PrizeText = styled.div`
    color: ${color.whiteShade};
    font-size: 12px;
`

export const PrizeClaim = styled.div`
    flex:1;
`
export const PrizeCountInfo = styled.div`
    display: none;
    position: absolute;
    top: 1px;
    background: ${Hex2Rgba(color.secondary, 0.64)};
    backdrop-filter: blur(20px);
    border-radius: 8px;
    padding: 4px 8px;
    font-size: 12px;
    color: ${color.whiteShade};
    z-index: 10;
    cursor: pointer;  

`

export const PrizeCountInfoView = styled.div`
    cursor: pointer;
   
`


export const PrizeCount = styled.div<{claimed:string}>`
    ${props => props.claimed === "true" && `
        text-decoration: line-through;
    `}
`

export const TicketNumber = styled.div`
    font-size: 14px;
    color: ${color.whiteShade};
`

export const ClaimAll = styled.div`
    margin-top: 16px;
    display: flex;

    & button:not(:last-child) {
        margin-right: 8px;
    }
`

export const WinnerProfile = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
`

export const WinnerProfileImage = styled.div`
    width: 80px;
    height: 80px;
    position: relative;
    & > img {
        width: 100%;
        border-radius: 50%;
    }

    &::after {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 50%; 
        padding: 2px;
        transform: scale(1.02);
        background:linear-gradient(45deg,${Hex2Rgba(color.gradient1.start, 1)},${Hex2Rgba(color.gradient1.middle, 1)},${Hex2Rgba(color.gradient1.end, 1)}); 
        -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
                mask-composite: exclude; 
    }
`

export const WinnerProfileText = styled.div`
margin-left: 10px;
`
export const WinnerProfileTicketCount = styled.div`
    font-size: 14px;
    color: ${color.whiteShade};

`
export const WinnerProfileName = styled.div`
    
`

export const WinnerProfileNameLink = styled(Link)`
    color: ${color.white};
    text-decoration: none;

    &:hover {
        color: ${color.primary};
    }

    & span {
        font-size: 14px;
        color: ${color.whiteShade};
    }
`


export const Header = styled.div`
    display: flex;
    align-items: center;
    margin-bottom:24px;

    & > button {
        margin-right: 10px;
    }
`

export const Title = styled.div`
    
`

export const PrizeClaimedAmount = styled.div`
    font-size: 12px;
    text-align: right;
    margin-top: 4px;
    color: ${color.whiteShade};
`