import React from "react"
import { useNavigate } from "react-router-dom"
import { closeAllPages } from "utils/parts"
import * as C from "./style"

const MobilePage = (props: any) => {

    const navigate = useNavigate()
    const closePage = () => {
        if (props.hide) {
            props.hide()
        }else{
            closeAllPages()
            navigate("/app")
        }
    }

    if (props.show) {

        return (
            <C.MobilePage>
                <C.PageHeader>
                    <C.PageTitle>
                        {props.icon && (
                            <C.PageTitleIcon> {props.icon} </C.PageTitleIcon>
                        )}
                        <C.PageTitleText>
                            {props.title ? props.title : ""}
                        </C.PageTitleText>
                    </C.PageTitle>
                        <C.PageClose onClick={closePage}>&times;</C.PageClose>
                    
                </C.PageHeader>
                <C.PageBody>
                    {props.children}
                </C.PageBody>
            </C.MobilePage>
        )

    } else {
        return <></>
    }

}

export default MobilePage