import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "components/ui/button"
import mapboxgl from "mapbox-gl"
import React, { useEffect, useRef, useState } from "react"
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { useParams, useNavigate } from "react-router-dom"
import { login } from "services/api/auth"
import { updateLocation } from "services/api/profile"
import { GetHolders, ManageHolderStates } from "services/managers/holders"
import { resetFilters } from "services/slices/holders"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import * as C from "./style"

const SetLocationMapPart = () => {

    const mapContainer = useRef(null)
    const map = useRef<mapboxgl.Map>()
    const [lng, setLng] = useState(9.1076929)
    const [lat, setLat] = useState(45.4627124)
    const [zoom, setZoom] = useState(4.5)
    const [dragging, setDragging] = useState(false)
    const params = useParams()

    const navigate = useNavigate() 
    const dispatch = useDispatch()
    const user = useSelector((state: any) => state.user.user)

    const [saving, setSaving] = useState(false)

    const holders = useSelector((state: any) => state.holders.holders)
    const userLoaded = useSelector((state: any) => state.user.userLoaded)

    useEffect(() => {
        map.current = new mapboxgl.Map({
            container: mapContainer.current!,
            style: 'mapbox://styles/mapbox/dark-v10',
            center: [lng, lat],
            zoom: zoom,
        })

        map.current.on('load', () => {
            // Set the default atmosphere style
            map.current?.setFog({
                'range': [0.5, 0.9],
                'color': "#353535",
                'horizon-blend': 0.1,
                //@ts-ignore
                'high-color': color.primary,
                //@ts-ignore
                'space-color': '#0B1026',
                //@ts-ignore
                'star-intensity': 0.5
            })

            // Add a layer showing the holders.
            map.current?.addSource('holders', {
                type: 'geojson',
                cluster: true,
                clusterMaxZoom: 14,
                clusterRadius: 50
            })

            // marker of a single holder
            map.current?.loadImage("/images/map/marker.png", (error, image: any) => {
                map.current?.addImage('user-marker', image);
                map.current?.addLayer({
                    id: 'unclustered-point',
                    type: 'symbol',
                    source: 'holders',
                    layout: {
                        'icon-image': 'user-marker',
                        'icon-size': 0.4
                    }
                })
            })

            

            map.current?.getCanvas().setAttribute('style', 'cursor: grab')

            map.current?.on("dragstart", () => {
                setDragging(true)
            })

            map.current?.on("dragend", () => {
                setDragging(false)
            })

            if (params.lat && params.lng) {
                if (!isNaN(Number(params.lat)) && !isNaN(Number(params.lng))) {
                    let lat = parseFloat(params.lat)
                    let lng = parseFloat(params.lng)
                    if (lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180) {
                        map.current?.setCenter([lng, lat])
                        map.current?.setZoom(13)
                    }
                }
            }
        })

        GetHolders("all")
    }, [])

    useEffect(() => {
        if (!map.current) return

        // try till map.current.loaded() returns true then call PatchDatas()
        const interval = setInterval(() => {
            if (map.current?.loaded()) {
                PatchDatas()
                clearInterval(interval)
            }
        }, 1000)

    }, [holders])

    useEffect(() => {

        if (userLoaded){
            if (!user){
                navigate("/app")
                toast.error("You need to be logged in to access this page")
            }
        }

    }, [userLoaded] )

    const PatchDatas = () => {

        let source : any = map.current?.getSource('holders')
        source.setData(holders)
    }

    const save = () => {
        let center = map.current?.getCenter()
        let lng = center?.lng
        let lat = center?.lat

        if (saving)
            return;

        setSaving(true)

        updateLocation({lat, lng}).then((res) => {

            setSaving(false)
            toast.success("Location updated")

            dispatch(resetFilters())
            ManageHolderStates(true, "location updated")
            navigate("/app")
            
            
        }, (err) => {
            toast.error("Error updating location")
            //console.log(err)

        })
    }

    const geoloc = () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                map.current?.setCenter([position.coords.longitude, position.coords.latitude])
                map.current?.setZoom(13)
            })
        }
    }

    return (
        <>
            <C.Map ref={mapContainer}></C.Map>
            <C.Marker style={{fontSize: dragging ? "34px" : "24px"}}>
                <FontAwesomeIcon icon={faMapMarkerAlt}/>
            </C.Marker>

            <C.Buttons>
                <C.ButtonsContainer>
                <Button loading={saving ? "true" : "false"} theme="gradient" bg={Hex2Rgba(color.secondaryFade, .5)} color="white" onClick={save}>Save</Button>
                <Button theme="gradient-border-transparent-bg" bg={Hex2Rgba(color.secondaryFade, .5)} color="white" onClick={geoloc}><FontAwesomeIcon icon={faMapMarkerAlt} /> Find me</Button>
                <Button theme="gradient-border-transparent-bg-on-hover" bg={Hex2Rgba(color.secondaryFade, .5)} color="white" onClick={() => navigate("/app")}>Cancel</Button>
                </C.ButtonsContainer>
            </C.Buttons>
            
        </>
    )
}

export default SetLocationMapPart