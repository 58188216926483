import axios from "axios"
import env from "env"
import { getLocalData } from "utils/localData"

export const GetLaunch = async (id : string) => {
    return axios.get(`${env.API.URL}launchpad/get/${id}`,{
        headers: {
            Authorization: "Bearer " + getLocalData("token"),
            
        }
    })
}

export const MintedVerification = async (id : string, address: string) => {
    return axios.post(`${env.API.URL}launchpad/minted/${id}`,{
        address
    },{
        headers: {
            Authorization: "Bearer " + getLocalData("token"),
        }
    })
}