import { faShuffle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ActiveWalletBar from 'components/widgets/activeWalletBar';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { showShuffleLiquidityLocksPopup } from 'services/slices/popup';
import { setShuffleLoadedLedger, setShuffleSelectedLedger } from 'services/slices/shuffle';
import * as C from './style';

const ShuffleLiquidityHeader = () => {

    const shuffleLoadedLedger = useSelector((state: any) => state.shuffle.loadedLedger)

    useEffect(() => {
        ReactTooltip.rebuild()
        ReactTooltip.hide()
    }, [shuffleLoadedLedger])

    const dispatch = useDispatch()

    const handleAccountSwap = () => {
        dispatch(setShuffleSelectedLedger(null))
        dispatch(setShuffleLoadedLedger(null))
    }

    const lockedNfts = () => {
        dispatch(showShuffleLiquidityLocksPopup(true))
    }

    return (
        <C.Header>

            {shuffleLoadedLedger && (
                <>
                    <C.LocksView onClick={lockedNfts}>
                        Locked NFTs
                    </C.LocksView>

                    <C.AccountSwap data-tip="Swap Accounts" onClick={handleAccountSwap}>
                        <FontAwesomeIcon icon={faShuffle} />
                    </C.AccountSwap>
                </>
            )}

            <ActiveWalletBar />
        </C.Header>
    )

}

export default ShuffleLiquidityHeader