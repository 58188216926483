import React from "react"
import * as P from "./style"

import { useSelector } from "react-redux"
import BasePopup from "components/popups/base"
import { IconCollections } from "utils/icons"

import { showNftViewPopup } from "services/slices/popup"
import NftView from "components/parts/nftView"


const NftViewPopup = () => {

    const show = useSelector((state: any) => state.popup.NftViewPopup)
    
    return (
        <P.Popup>
            <BasePopup title="View Nft" show={show} hide={() => showNftViewPopup(false)} icon={<IconCollections />} glasmorph={true}>
                <NftView/>
            </BasePopup>
        </P.Popup>
    )
}

export default NftViewPopup