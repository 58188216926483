import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { deviceMax } from "styles/media"
import { Link } from "react-router-dom"
import { Row } from "styles"

export const ShuffleLiquidation = styled.div`
    padding-top:16px;
`

export const Loading = styled.div`
    font-size:24px;
    display:flex;
    align-items:center;
    justify-content:center;
    height:100%;
`

export const Error = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    height:100%;
    flex-direction:column;
`

export const Title = styled.div`
    font-size:18px;
    margin-top:32px;
`

export const Actions = styled(Row)`
`

export const Action = styled.div<{disabled?:boolean}>`
    flex:1;
    background-color:${Hex2Rgba(color.secondary, .16)};
    display:flex;
    align-items:center;

    &:not(:last-child){
        margin-right:16px;
    }

    padding:16px;
    border-radius: 8px;

    ${props => props.disabled && `
        opacity:.5;
        pointer-events:none;
    `}
`

export const ActionLeft = styled.div`
    flex:3;
`

export const ActionTitle = styled.div`
    font-size:18px;
`

export const ActionDescription = styled.div`
    color:${color.whiteShade};
    font-size:12px;
`

export const ActionRight = styled.div`
    flex:1;

    & button{
        width:100%;
    }
`

export const Liquidations = styled(Row)`
    margin-top:16px;
`

export const LiquidationInfo = styled.div`
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    padding:16px;
    background:${Hex2Rgba(color.bg1, .64)};
    transition:all .1s ease-in-out;
`


export const Liquidation = styled.div<{image:string}>`
    background-image:url(${props => props.image});
    background-size:cover;
    background-position:center center;
    background-repeat:no-repeat;
    height:230px;
    border-radius: 8px;
    position:relative;
    margin-bottom:30px;
    cursor:pointer;
    transition:all .1s ease-in-out;

    &:hover{
        & ${LiquidationInfo}{
            background:${Hex2Rgba(color.bg1, .84)};
        }

        opacity:.8;
    }
`

export const NoLiquidations = styled.div`

    flex-direction:column;
    font-size:18px;
    text-align:center;
    margin-top:32px;
    width:100%;
    color:${color.whiteShade};
`

export const LedgerSelect = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    & > div{
        max-width:700px;
    }
`

export const LockAlert = styled.div`
    background-color:${Hex2Rgba(color._badgeArtistText, .16)};
    border-radius: 8px;
    border:1px solid ${color._badgeArtistText};
    padding:16px;
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-bottom:30px;
`

export const LockAlertLeft = styled.div`

`

export const LockAlertTitle = styled.div`

`

export const LockAlertDescription = styled.div`
    font-size:12px;
    color:${color.whiteShade};
`

export const LockAlertAction = styled.div`
    & button:hover, & button:focus{
        background-color:${color._badgeArtistText};
        color:${color.white};
        box-shadow:none;
        opacity:0.8;
    }
`