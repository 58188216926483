import button from "components/ui/button"
import { Button } from "components/ui/button/style"
import styled, {keyframes} from "styled-components"
import { Col, Row } from "styles"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"

export const FcfsRedeem = styled.form`

    & p {
        font-size: 14px;
        color: ${color.gray};
        margin:0;
        padding-top:5px;
    }

    & button{
        width: 100%;
        margin-top: 16px;
    }

`