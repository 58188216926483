import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface AnalyticsState {
    collection : string | null //id of the collection being viewed on analytics
}

const initialState: AnalyticsState = {
    collection : null
}

export const analyticsSlice = createSlice({
    name: 'analytics',
    initialState,
    reducers: {
        setCollection: (state, action: PayloadAction<string | null>) => {
            state.collection = action.payload
        },
    }
})

export const { setCollection } = analyticsSlice.actions

export default analyticsSlice.reducer