import { faEllipsisVertical, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Button from "components/ui/button";
import DropdownButton, { DropdownItem } from "components/ui/button/dropdown";
import { signAllAndSend, signAllAndSendAll, SOLANA_CONNECTION } from "modules/solana/connection";
import { getAllNftsByMintList, getAllNftsByOwner } from "modules/solana/nft";
import { getLedgerLocksAccount, lockNft, unlockNft } from "modules/solana/shuffle/liquidity";
import { getShuffleError } from "modules/solana/shuffle/shuffleErrors";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { setLocksInNftPage, setRefreshLiquidations } from "services/slices/shuffle";
import { Col } from "styles";
import { color } from "styles/theme";
import { Hex2Rgba } from "utils/helpers";
import * as C from "./style";

const ShuffleLiquidityLocks = () => {

    const treasury = useSelector((state: any) => state.shuffle.treasuryAccount)
    const treasuryPk = useSelector((state: any) => state.shuffle.treasury)
    const programId = useSelector((state: any) => state.shuffle.programId)
    const loadedLedger = useSelector((state: any) => state.shuffle.loadedLedger)
    const vault = useSelector((state: any) => state.shuffle.vault)
    const [loading, setLoading] = useState(true)
    const [collections, setCollections] = useState<any>([])

    const [page, setPage] = useState("list")
    const [selectedCollection, setSelectedCollection] = useState<any>(null)
    const [nftsLoading, setNftsLoading] = useState(false)
    const selectedWallet = useSelector((state: any) => state.user.selectedWallet)
    const [walletNfts, setWalletNfts] = useState<any>([])
    const [selectedNfts, setSelectedNfts] = useState<any>([])
    const [actionLoading, setActionLoading] = useState(false)

    const [whitelisted, setWhitelisted] = useState<any>(false)

    const dispatch = useDispatch()

    useEffect(() => {
        refresh();
        return () => {
            dispatch(setLocksInNftPage(false))
        }

    }, [loadedLedger])

    const refresh = async () => {
        let mintlist = treasury.requirements.map((nft: any) => nft.address)
        setLoading(true)
        getAllNftsByMintList(mintlist).then(async (res: any) => {
            let _collections: any = []

            for (let i = 0; i < res.length; i++) {
                try {
                    let metadata = await axios.get(res[i].uri)
                    let image = metadata.data.image;
                    let name = metadata.data.name;

                    let item = treasury.requirements.find((nft: any) => nft.address === res[i].mintAddress.toBase58())
                    let depositedCount = loadedLedger.lockAccount.lockedNfts.filter((nft: any) => nft.address === res[i].mintAddress.toBase58()).length

                    _collections.push({
                        address: res[i].mintAddress.toBase58(),
                        name: name,
                        image: image,
                        count: item.count,
                        depositedCount
                    })
                } catch (err) {
                    console.log(err)
                }
            }

            setCollections(_collections)
        }).catch((err: any) => {
            console.log(err)
            toast.error("Error fetching NFTs")
        }).finally(() => {
            setLoading(false)
        })

        if (treasury.whitelisted.indexOf(selectedWallet) !== -1) {
            setWhitelisted(true)
        } else {
            setWhitelisted(false)
        }

    }

    const handleCollectionClick = async (address: any) => {
        let collection = collections.find((collection: any) => collection.address === address)
        setSelectedCollection(collection!)
        setPage("deposit")
        dispatch(setLocksInNftPage(true))
        setNftsLoading(true)

        getAllNftsByOwner(selectedWallet, SOLANA_CONNECTION).then(async (res: any) => {

            let _nfts: any = []
            for (let i = 0; i < res.length; i++) {
                let nft = res[i]
                if (nft.collection && nft.collection.address.toBase58() === address) {
                    _nfts.push({
                        metadata: nft.address.toBase58(),
                        mint: nft.mintAddress.toBase58(),
                        collection: nft.collection.address.toBase58(),
                        uri: nft.uri,
                        cmid: nft.creators[0].address.toBase58(),
                        active: false,
                    })
                }
            }

            for (let i = 0; i < _nfts.length; i++) {
                let nft = _nfts[i]
                try {
                    let metadata = await axios.get(nft.uri)
                    let image = metadata.data.image;
                    let name = metadata.data.name;
                    nft.image = image;
                    nft.name = name;
                } catch (err) {
                    nft.image = "/images/etc/ghost.png"
                    nft.name = "Unknown"
                }
            }

            setWalletNfts(_nfts)
            setNftsLoading(false)
            setSelectedNfts([])

        }).catch((err: any) => {
            setPage("list")
            dispatch(setLocksInNftPage(false))
            console.log(err)
            toast.error("Error fetching NFTs")
        })
    }

    const selectNft = (mint: any) => {
        let _selectedNfts = selectedNfts
        let index = _selectedNfts.findIndex((nftItem: any) => nftItem === mint)
        if (index > -1) {
            _selectedNfts.splice(index, 1)
        } else {
            _selectedNfts.push(mint)
        }
        setSelectedNfts([..._selectedNfts])
    }

    const depositSelectedNfts = async () => {
        if (selectedNfts.length === 0) {
            toast.error("No NFTs Selected")
            return
        }

        setActionLoading(true)

        let txs = []

        for (let i = 0; i < selectedNfts.length; i++) {
            let nft = walletNfts.find((nftItem: any) => nftItem.mint === selectedNfts[i])
            let ix = await lockNft(programId, treasuryPk, vault, loadedLedger.publicKey, nft.collection, nft.cmid, nft.mint, nft.metadata)
            txs.push(ix)
        }

        signAllAndSendAll(txs, "Depositing...").then((res: any) => {
            setActionLoading(false)
            refresh()
            setPage("list")
            dispatch(setLocksInNftPage(false))
            dispatch(setRefreshLiquidations(true))

        }).catch((e) => {
            if (typeof e === "string")
                toast.error(e)
            else if (e.message && e.message.indexOf("Attempt to debit an account but found no record of a prior credit.") !== -1) {
                toast.error(getShuffleError("0x1"))
            } else if (e.InstructionError && e.InstructionError[1] && e.InstructionError[1].Custom) {
                let errorMessage = getShuffleError(e.InstructionError[1].Custom)
                if (!errorMessage)
                    errorMessage = "Error Depositing"
                toast.error(errorMessage)
            } else {
                toast.error("Error Depositing NFTs")
                setActionLoading(false)
            }
        })
    }

    useEffect(() => {
        if (page === "list") {
            dispatch(setLocksInNftPage(false))
        }
    }, [page])

    const withdrawAll = async (address: string) => {
        let collection: any = collections.find((collection: any) => collection.address === address)


        if (collection.depositedCount === 0) {
            toast.error("No NFTs to withdraw")
            return
        }

        let lockAccount: any = await getLedgerLocksAccount(programId, loadedLedger.publicKey)

        let lockedNfts = lockAccount.lockedNfts.filter((nft: any) => nft.address.toBase58() === address)

        if (lockedNfts.length === 0) {
            toast.error("No NFTs to withdraw")
            return
        }

        let nfts: any = await getAllNftsByMintList(lockedNfts.map((nft: any) => nft.mint.toBase58()))

        let txs = []

        for (let i = 0; i < nfts.length; i++) {
            let nft = nfts[i]
            let ix = await unlockNft(programId, treasuryPk, vault, loadedLedger.publicKey, nft.mintAddress.toBase58(), nft.address.toBase58(), nft.collection.address.toBase58())
            txs.push(ix)
        }

        signAllAndSendAll(txs, "Withdrawing...").then((res: any) => {
            setActionLoading(false)
            refresh()
            setPage("list")
            dispatch(setLocksInNftPage(false))
            dispatch(setRefreshLiquidations(true))

        }).catch((e) => {
            if (typeof e === "string")
                toast.error(e)
            else if (e.message && e.message.indexOf("Attempt to debit an account but found no record of a prior credit.") !== -1) {
                toast.error(getShuffleError("0x1"))
            } else if (e.InstructionError && e.InstructionError[1] && e.InstructionError[1].Custom) {
                let errorMessage = getShuffleError(e.InstructionError[1].Custom)
                if (!errorMessage)
                    errorMessage = "Error Withdrawing"
                toast.error(errorMessage)
            } else {
                toast.error("Error Withdrawing NFTs")
            }
        })


    }

    if (loading) {
        return (
            <C.Loading>
                <FontAwesomeIcon icon={faSpinner} spin />
            </C.Loading>
        )
    }

    return (
        <C.ShuffleLiquidityLocks>

            {page === "list" && (
                <>

                    <C.Description>
                        To become a liquidator you need to deposit
                        {collections.map((collection: any, index: number) => (
                            <span key={index}> "{collection.count} {collection.name}" {index === collections.length - 1 ? '' : 'and'} </span>
                        ))}. You can withdraw these NFTs at any time.


                    </C.Description>

                    {whitelisted && (
                        <C.Whitelisted>
                            You are whitelisted, you don't need to deposit any NFTs.
                        </C.Whitelisted>
                    )}

                    <C.Collections>
                        {collections.map((collection: any, index: number) => (
                            <C.Collection key={index}>
                                <C.CollectionImage>
                                    <img src={collection.image} alt={collection.name} />
                                </C.CollectionImage>
                                <C.CollectionDetails>
                                    <C.CollectionName>
                                        {collection.name}
                                    </C.CollectionName>
                                    <C.CollectionDeposit>
                                        Locked: <span>{collection.depositedCount}/{collection.count}</span>
                                    </C.CollectionDeposit>
                                </C.CollectionDetails>
                                <C.CollectionActions>
                                    {collection.depositedCount > 0 && (
                                        <DropdownButton text="" buttonStyle={{ theme: "gradient-border-transparent-bg-on-hover", bg: Hex2Rgba(color.secondaryFade, .5), color: "white" }} icon={<FontAwesomeIcon icon={faEllipsisVertical} />}>
                                            <DropdownItem onClick={() => withdrawAll(collection.address)}>
                                                Withdraw All
                                            </DropdownItem>
                                        </DropdownButton>

                                    )}

                                    <Button theme="gradient-border-transparent-bg-on-hover" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)} onClick={() => handleCollectionClick(collection.address)}>
                                        Deposit
                                    </Button>
                                </C.CollectionActions>
                            </C.Collection>
                        ))}
                    </C.Collections>

                    <C.Note>
                        Note: you will not be able to withdraw your locked NFTs if you have any open liquidations. You must withdraw your liquidations before you can withdraw your locked NFTs.
                    </C.Note>
                </>
            )}

            {page === "deposit" && (
                <C.Deposit>


                    {nftsLoading && (
                        <C.Loading>
                            <FontAwesomeIcon icon={faSpinner} spin />
                        </C.Loading>
                    )}

                    {!nftsLoading && (
                        <>
                            <C.Header>
                                <Button theme="gradient-border-transparent-bg-on-hover" bg={Hex2Rgba(color.white, .05)} color="white" onClick={() => setPage("list")}>Back</Button>
                                <C.HeaderTitle>
                                    Deposit {selectedCollection.name} {selectedCollection.depositedCount}/{selectedCollection.count}
                                </C.HeaderTitle>
                            </C.Header>

                            <C.Nfts>
                                {walletNfts.map((nft: any, i: number) => (
                                    <Col col="3" key={i}>
                                        <C.Nft image={nft.image} isselected={selectedNfts.includes(nft.mint) ? "true" : "false"} onClick={() => selectNft(nft.mint)}>
                                            <C.NftInfo>
                                                {nft.name}
                                            </C.NftInfo>
                                            <C.NftActiveMark></C.NftActiveMark>
                                        </C.Nft>
                                    </Col>
                                ))}
                            </C.Nfts>

                            <C.NftDepositActions>
                                <Button theme="gradient-border-transparent-bg-on-hover" color={color.white} bg={Hex2Rgba(color.secondaryFade, 1)} onClick={() => setSelectedNfts([])}>Clear Selection</Button>
                                <Button theme="gradient" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)} onClick={() => depositSelectedNfts()} loading={actionLoading ? "true" : "false"} disabled={actionLoading || selectedNfts.length === 0}>
                                    Deposit Selected {selectedNfts.length > 0 && (<>"{selectedNfts.length}"</>)} NFTs
                                </Button>
                            </C.NftDepositActions>
                        </>
                    )}
                </C.Deposit>
            )}

        </C.ShuffleLiquidityLocks>
    )

}

export default ShuffleLiquidityLocks