import Button from "components/ui/button"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { introduced } from "services/api/profile"
import { showEditProfilePopup, showInfoPopup, showIntroductionPopup } from "services/slices/popup"
import { setUserIntroduced } from "services/slices/user"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import * as C from "./style"

const Introduction = () => {

    const dispatch = useDispatch()

    const close = () => {
        dispatch(showInfoPopup(false))
    }

    return (
        <C.Introduction>
            <C.Fronk>
            <img style={{width:"200px"}} src="" alt="image" />
            </C.Fronk>
            <C.Title>
            Mint Live! 🚀
            </C.Title>

            <C.Message>
            Press Mint button to redirect to the Fronk Minting page.
            </C.Message>

            <C.Buttons>
                <Button onClick={close} theme="gradient-border-transparent-bg-on-hover" block="true" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)}>
                    Close
                </Button>
                <Button onClick={close} link="link" target="_blank" href="" theme="gradient" block="true" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)}>
                    Mint
                </Button>
            </C.Buttons>
        </C.Introduction>
    )
}

export default Introduction