import { faChartSimple, faCheck, faCode, faMusic, faPalette, faPenNib } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import * as C from "./style"

export const Badges = [
    {
        label: "Developer",
        value: "developer"
    },
    {
        label: "Designer",
        value: "designer"
    },
    {
        label: "Artist",
        value: "artist"
    },
    {
        label: "Musician",
        value: "musician"
    },
    {
        label: "Marketing",
        value: "marketing"
    },
    {
        label: "Collab Manager",
        value: "collab-manager"
    }
]

const Badge = (props: any) => {

    const color = React.useMemo(() => {

        switch (props.type) {
            case "developer":
                return {
                    background: "_badgeDeveloperBg",
                    textColor: "_badgeDeveloperText"
                }
            case "designer":
                return {
                    background: "_badgeDesignerBg",
                    textColor: "_badgeDesignerText"
                }
            case "artist":
                return {
                    background: "_badgeArtistBg",
                    textColor: "_badgeArtistText"
                }
            case "musician":
                return {
                    background: "_badgeMusicianBg",
                    textColor: "_badgeMusicianText"
                }
            case "marketing":
                return {
                    background: "_badgeMarketingBg",
                    textColor: "_badgeMarketingText"
                }
            case "collab-manager":
                return {
                    background: "_badgeCollabManagerBg",
                    textColor: "_badgeCollabManagerText"
                }
            default:
                return {
                    background: "primary",
                    textColor: "black"
                }
        }
    }, [props])

    const icon = React.useMemo(() => {

        switch (props.type) {
            case "developer":
                return faCode
            case "designer":
                return faPenNib
            case "artist":
                return faPalette
            case "musician":
                return faMusic
            case "marketing":
                return faChartSimple
            case "collab-manager":
                return faCheck
            default:
                return faCheck
        }

    }, [props])

    const text = React.useMemo(() => {

        switch (props.type) {
            case "developer":
                return "Developer"
            case "designer":
                return "Designer"
            case "artist":
                return "Artist"
            case "musician":
                return "Musician"
            case "marketing":
                return "Marketing"
            case "collab-manager":
                return "Collab M."
            default:
                return ""
        }

    }, [props])

    let { type, ...rest } = props

    return (
        <C.Badge background={color.background} textColor={color.textColor} {...rest} >
            <C.BadgeIcon>
                <FontAwesomeIcon icon={icon} />
            </C.BadgeIcon>
            <C.BadgeText>{text}</C.BadgeText>
        </C.Badge>
    )
}

export default Badge