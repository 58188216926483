import { configureStore } from "@reduxjs/toolkit"
import analyticsReducer from "./slices/analytics"
import dataReducer from "./slices/data"
import userReducer from "./slices/user"
import popupReducer from "./slices/popup"
import _collectionsReducer from "./slices/_collections"
import _whitelistCollectionsReducer from "./slices/_whitelistCollections"
import _launches from "./slices/_launches"
import holdersReducer from "./slices/holders"
import whitelistReducer from "./slices/whitelist"
import launchReducer from "./slices/launch"
import shuffleReducer from "./slices/shuffle"
import chatReducer from "./slices/chat"

export const store = configureStore({
    reducer: {
        analytics: analyticsReducer,
        data: dataReducer,
        user: userReducer,
        popup: popupReducer,
        _collections: _collectionsReducer,
        _whitelistCollections: _whitelistCollectionsReducer,
        _launches: _launches,
        holders: holdersReducer,
        whitelist: whitelistReducer,
        launch: launchReducer,
        shuffle: shuffleReducer,
        chat: chatReducer,
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch