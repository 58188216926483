import { Metaplex, keypairIdentity, bundlrStorage } from "@metaplex-foundation/js"
import { Connection, clusterApiUrl, Keypair, PublicKey } from "@solana/web3.js"
import env from "env"
import { Network } from "models/enums/network";
import { Wallet } from "models/interfaces/wallet";
import axios from "axios";
import { SOLANA_CONNECTION } from "./connection";

export const connection = new Connection(env.WEB3.SOLANA.RPC);
export const metaplex = new Metaplex(connection);

export const getAllNftsByOwner = (address: string, conn? : any, mplex? : any) => new Promise((resolve, reject) => {
    if (!conn)
        conn = new Connection(env.WEB3.SOLANA.RPC);
    if (!mplex)
        mplex = new Metaplex(conn);

    return mplex.nfts().findAllByOwner({ owner: new PublicKey(address) }).then((nfts: any) => {
        resolve(nfts)
    }, () => {
        resolve([])
    })
})

export const getAllNftsOfUserSolana = async (wallets: Wallet[]) => {

    var nfts: any[] = [];

    for (const wallet of wallets) {
        if (wallet.network !== Network.Solana)
            continue

        const data: any = await getAllNftsByOwner(wallet.address, connection, metaplex)
        
        nfts = nfts.concat(data)
    }

    return nfts

}

export const GetMetadata = async (url: string, cb : any) => new Promise((resolve, reject) => {

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    if (cb)
        cb(source)

    axios.get(url, {cancelToken: source.token}).then((res) => {
        resolve({
            image: res.data.image,
            name: res.data.name,
            description: res.data.description,
            traits: res.data.attributes,
        })
    }, (err) => {
        reject(err)
    })
})

export const getAllNftsByMintList = (mints: string[]) => new Promise((resolve, reject) => {
    
    const metaplex = new Metaplex(SOLANA_CONNECTION)

    return metaplex.nfts().findAllByMintList({ mints: mints.map((mint) => new PublicKey(mint)) }).then((nfts: any) => {
        resolve(nfts)
    }, () => {
        resolve([])
    })
})
