import Button from "components/ui/button";
import { Network } from "models/enums/network";
import { createLedgerAccount, getAllLedgersOfUser } from "modules/solana/shuffle/liquidity";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { setWalletConnectSettings } from "services/slices/data";
import { showWalletConnectPopup, showShuffleLedgerSetupPopup } from "services/slices/popup";
import { setShuffleLedger, setShuffleSelectedLedger } from "services/slices/shuffle";
import { color } from "styles/theme";
import { Hex2Rgba, shortenPublicKey } from "utils/helpers";
import * as C from "./style";

const ShuffleLedgerSelect = () => {

    const accounts = useSelector((state: any) => state.shuffle.ledger)
    const selectedWallet = useSelector((state: any) => state.user.selectedWallet)
    const programId = useSelector((state: any) => state.shuffle.programId)
    const treasury = useSelector((state: any) => state.shuffle.treasury)
    const dispatch = useDispatch()

    const load = (pubkey:string) => {
        dispatch(setShuffleSelectedLedger(pubkey))
    }

    const [loading, setLoading] = useState<boolean>(false)

    const newAccount = () => {
        if (!selectedWallet) {
            dispatch(setWalletConnectSettings({ network: Network.Solana }))
            dispatch(showWalletConnectPopup(true))
            return;
        }
        setLoading(true)
        createLedgerAccount(programId, treasury).then((res) => {
            /*getAllLedgers(programId, treasury,selectedWallet).then((res) => {
                setLoading(false)
                dispatch(setShuffleLedger(res))
                dispatch(showShuffleLedgerSetupPopup(false))
            }).catch((err) => {
                toast.error("Error Initializing Ledger Account Please Try Again")
                setLoading(false)
            })*/

            getAllLedgersOfUser(programId, treasury, selectedWallet).then(async (res) => {
                if (res.length > 0) {
                    setLoading(false)
                    dispatch(setShuffleLedger(res))
                    dispatch(showShuffleLedgerSetupPopup(false))
                }
                else {
                    toast.error("Error Initializing Ledger Account Please Try Again (try refreshing the page)")
                    setLoading(false)
                }
            }).catch((err) => {
                toast.error("Error Initializing Ledger Account Please Try Again (try refreshing the page)")
                setLoading(false)
            })

        }).catch((err) => {
            toast.error(err)
            setLoading(false)
        })
    }

    return (
        <C.ShuffleLedgerSelect>

            <C.Title>
                Select Liquidator Account
            </C.Title>

            <C.Ledgers>
                {accounts.map((account: any, index: number) => (
                    <C.Ledger key={index}>
                        <C.LedgerAddress>
                            {shortenPublicKey(account.publicKey)}
                        </C.LedgerAddress>
                        <Button theme="gradient" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)} onClick={() => load(account.publicKey)}>Load</Button>
                    </C.Ledger>
                ))}
            </C.Ledgers>

            <C.CreateNew>
                <Button block="true" theme="gradient-border-transparent-bg-on-hover" bg={Hex2Rgba(color.secondaryFade, .5)} color="white" onClick={() => newAccount()} loading={loading ? "true" : "false"} disabled={loading}>
                    Create New Account
                    </Button>
            </C.CreateNew>

        </C.ShuffleLedgerSelect>
    )

}

export default ShuffleLedgerSelect;