import { useSelector } from "react-redux"
import * as P from "./style"

import BasePage from "components/pages/base"
import { IconCollections, IconWhitelist } from "utils/icons"

import { MainParts } from "utils/parts"
import Whitelist from "components/parts/whitelist"
import { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom"

const Page = () => {

    const show = useSelector((state: any) => state.data.currentPage)
    const currentViewingWhitelist = useSelector((state: any) => state.holders.currentViewingWhitelist)
    const [display, setDisplay] = useState(true)
    const navigate = useNavigate()

    const toggleDisplay = () => {
        setDisplay(!display)
    }

    const hide = () => {
        navigate("/app/whitelist")
    }

    useEffect(() => {
        setDisplay(true)
    }, [currentViewingWhitelist])
    
    return (
        <P.Page display={display ? "true" : "false"}>
            <BasePage hide={hide} title="Whitelist" show={currentViewingWhitelist !== null} icon={<IconWhitelist />} glasmorph={true}>
                <Whitelist />

                <P.ToggleControl onClick={toggleDisplay} >
                    {display ? <FontAwesomeIcon icon={faChevronRight}/> : <FontAwesomeIcon icon={faChevronLeft}/>}
                </P.ToggleControl>
            </BasePage>
            
        </P.Page>
    )
}

export default Page