import styled from 'styled-components'
import { Container } from 'styles/grid'
import { color } from 'styles/theme'
import { Hex2Rgba } from 'utils/helpers'
import { deviceMax } from 'styles/media'
import { Link } from 'react-router-dom'

export const Header = styled.header`
    height:100vh;
    background:${Hex2Rgba("#000000", .48)};
    display:flex;
    flex-direction:column;
    & > ${Container}{
        width:100%;
        display:flex;
        justify-content:space-between;
        align-items:center;
    }
    justify-content:space-between;

    position:fixed;
    z-index:12;
    width:92px;
    top:0;
    left:0;

    @media ${deviceMax.md}{
        height:60px;
    }

    align-items:center;
    padding:40px 0px;
    backdrop-filter: blur(20px);
`

export const Top = styled.div`
    padding:0px 18px;
    width:100%;
`

export const Logo = styled.div`
 & svg{
    width:50px;
 }
 margin-bottom:40px;
`

export const Nav = styled.div`
    background:${Hex2Rgba(color.bg1, .64)};
    display:flex;
    flex-direction:column;
    align-items:center;
    border-radius:64px;
    padding:16px 8px;
    position:relative;
    &::after {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 64px; 
        padding: 2px; 
        background:linear-gradient(45deg,${Hex2Rgba(color.gradient1.start, .16)},${Hex2Rgba(color.gradient1.middle, .16)},${Hex2Rgba(color.gradient1.end, .16)}); 
        -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
                mask-composite: exclude; 
    }
`

export const NavItem = styled.div`
    position:relative;
    z-index:1;
    font-size:20px;

    & svg{
        width:20px;
        height:20px;
    }

    & > *{
        cursor:pointer;
        display:flex;
        align-items:center;
        justify-content:center;
        color:${color.primary};
        width:40px;
        height:40px;
        border-radius:100%;
        outline:none;               

        &.active, &:hover, &:focus, &:active{
            background:${color.secondary};
            color:${color.white};
        }

        &.active::after, &:hover::after, &:focus::after, &:active::after{
            content: "";
            position: absolute;
            inset: 0;
            border-radius: 24px; 
            padding: 2px; 
            background:linear-gradient(45deg,${Hex2Rgba(color.gradient1.start, .32)},${Hex2Rgba(color.gradient1.middle, .32)},${Hex2Rgba(color.gradient1.end, .32)}); 
            -webkit-mask: 
            linear-gradient(#fff 0 0) content-box, 
            linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
                    mask-composite: exclude; 
        }
    }

    &:not(:last-child){
        margin-bottom:16px;
    }
`

export const NavItemBadge = styled.div`
    position:absolute;
    top:-6px;
    right:-9px;
    font-size:9px;
    font-weight:600;
    background:linear-gradient(45deg,${Hex2Rgba(color.gradient3.start, 1)},${Hex2Rgba(color.gradient3.end, 1)}); 
    color:${color.black};
    border-radius:12px;
    padding:2px 4px;
    cursor:default;
` 

export const Bottom = styled.div`
    padding:0px 18px;
    width:100%;
`
export const Profile = styled.div`

background:${Hex2Rgba(color.bg1, .64)};
    display:flex;
    flex-direction:column;
    align-items:center;
    border-radius:64px;
    padding:16px 8px;
    position:relative;
    &::after {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 64px; 
        padding: 2px; 
        background:linear-gradient(45deg,${Hex2Rgba(color.gradient1.start, .16)},${Hex2Rgba(color.gradient1.middle, .16)},${Hex2Rgba(color.gradient1.end, .16)}); 
        -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
                mask-composite: exclude; 
    }
`

export const ProfileItem = styled.div<{type? : string}>`
    position:relative;
    z-index:1;
    font-size:20px;

    & svg{
        width:20px;
        height:20px;
    }

    & > *{
        cursor:pointer;
        display:flex;
        align-items:center;
        justify-content:center;
        color:${color.primary};
        width:40px;
        height:40px;
        border-radius:100%;
        outline:none;               

        &.active, &:hover, &:focus, &:active{
            background:${color.secondary};
            color:${color.white};
        }

        &.active::after, &:hover::after, &:focus::after, &:active::after{
            content: "";
            z-index:1;
            position: absolute;
            inset: 0;
            border-radius: 24px; 
            padding: 2px; 
            background:linear-gradient(45deg,${Hex2Rgba(color.gradient1.start, .32)},${Hex2Rgba(color.gradient1.middle, .32)},${Hex2Rgba(color.gradient1.end, .32)}); 
            -webkit-mask: 
            linear-gradient(#fff 0 0) content-box, 
            linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
                    mask-composite: exclude; 
        }

        ${props => props.type === 'profile-picture' && `
            & img{
                width:40px;
                height:40px;
                border-radius:100%;
                position:relative;
                
            }

            &::after{
                content: "";
                z-index:1;
                position: absolute;
                inset: 0;
                border-radius: 24px; 
                padding: 2px; 
                background:linear-gradient(45deg,${color.gradient1.start},${color.gradient1.middle},${color.gradient1.end}); 
                -webkit-mask: 
                linear-gradient(#fff 0 0) content-box, 
                linear-gradient(#fff 0 0);
                -webkit-mask-composite: xor;
                        mask-composite: exclude; 
            }
        `}
    }

    &:not(:last-child){
        margin-bottom:16px;
    }

`


export const ProfilePicture = styled.div`
    & img{
        width:40px;
        height:40px;
    }
`
