import { getHolder, getHolders } from "services/api/holders"
import { store } from "services/store"
import { setHolders, setHoldersCount } from "services/slices/holders"
import { copyObject, isArraysEqual, ReverseGeoJson, ToGeoJson } from "utils/helpers"
import toast from "react-hot-toast"
import { displayHolderProfile } from "utils/parts"
import { Collection } from "models/collection"

var currentState: any = {
    whitelist: null,
    launch: null
}

export const originalData: any = {
    data: null
}

export const GetHolders = async (type: string, chain?: string, id?: string, wl?: any, launch?:any, cb?: any, resolve?: any) => {

    if (chain === "all")
        chain = undefined

    const loading = toast.loading("Loading...")

    store.dispatch(setHoldersCount(0))

    getHolders(type, chain, id, wl, launch).then((res) => {

        toast.remove(loading)

        originalData.data = ToGeoJson(res.data)


        if (cb) {
            cb(res.data).then((newres: any) => {
                const data = ToGeoJson(newres ? newres : res.data)
                store.dispatch(setHolders(data))

                if (resolve)
                    resolve(data)
            })
        } else {
            const data = ToGeoJson(res.data)

            store.dispatch(setHolders(data))

            if (resolve)
                resolve(data)
        }
    }).catch((err) => {
        toast.remove(loading)
        store.dispatch(setHolders(ToGeoJson([])))
        //console.log(err)
        //cb([])

        if (resolve)
            resolve([])
    })

}

export const ManageHolderStates = async (force: boolean = false, msg?: any) => new Promise((resolve, reject) => {

    //console.log("ManageHolderStates " + msg)

    const currentViewingCollection = store.getState().holders.currentViewingCollection
    const currentViewingToken = store.getState().holders.currentViewingToken
    const onlyWhitelisted = store.getState().holders.onlyWhitelisted
    const currentViewingWhitelist = store.getState().whitelist.currentWhitelist
    const currentViewingLaunch = store.getState().launch.currentLaunch


    const filters = store.getState().holders.filters
    const search = store.getState().holders.search

    let get = false

    if (currentState.whitelist !== currentViewingWhitelist) {
        get = true
        currentState.whitelist = currentViewingWhitelist
    }

    if (currentState.launch !== currentViewingLaunch) {
        get = true
        currentState.launch = currentViewingLaunch
    }

    if (force) {
        get = true
    }

    if (originalData.data === null) {
        get = true
    }

    if (!get) {

        let newHolders = filterOut(ReverseGeoJson(originalData.data), { filters, search, onlyWhitelisted, currentViewingCollection, currentViewingToken, currentViewingWhitelist })


        store.dispatch(setHolders(ToGeoJson(newHolders)))
    }
    else {
        GetHolders("all", undefined, undefined, currentViewingWhitelist ? currentViewingWhitelist.id : null, currentViewingLaunch ? currentViewingLaunch.identity[0] : null, (holders: any) => {

            let newHolders = filterOut(holders, { filters, search, onlyWhitelisted, currentViewingCollection, currentViewingToken, currentViewingWhitelist })

            return Promise.resolve(newHolders)
        }, resolve)
    }


})

export const userProfileAndDisplayProfile = async (id: any) => {
    getHolder(id).then((res: any) => {

        displayHolderProfile(res.data.user)

    }).catch((err: any) => {
        toast.error("Error loading user data")

    })
}

export const filterOut = (data: any, params: any) => {



    const { filters, search, onlyWhitelisted, currentViewingWhitelist, currentViewingToken, currentViewingCollection } = params

    let newHolders = copyObject(data)

    if (currentViewingCollection !== null) {
        newHolders = newHolders.filter((holder: any) => {
            if (holder.type !== "holder")
                return false
            else if (currentViewingCollection === null)
                return true
            else if (holder.collections === null)
                return false
            else
                return holder.collections.includes(currentViewingCollection)
        })
    }

    if (currentViewingToken !== null) {
        newHolders = newHolders.filter((holder: any) => {
            if (holder.type !== "holder")
                return false
            else if (currentViewingToken === null)
                return true
            else if (holder.tokens === null)
                return false
            else
                return holder.tokens.includes(currentViewingToken)
        })
    }

    if (filters.chain !== "all") {
        //get all collections
        const collections = store.getState().data.collections

        const collectionIds = collections.filter((collection: Collection) => {
            return collection.chain === filters.chain
        }).map((collection: any) => {
            return collection.id
        })

        newHolders = newHolders.filter((holder: any) => {
            if (holder.type === "place")
                return false
            else if (holder.collections === null)
                return false
            else
                return holder.collections.some((collection: any) => collectionIds.includes(collection))
        })

    }

    if (filters.type !== "all") {
        if (filters.type === "owned"){
            const user = store.getState().user.user
            if (user){
                const userCollections = user.collections ? user.collections : []
                newHolders = newHolders.filter((holder: any) => {
                    if (holder.type !== "holder")
                        return false
                    else
                        return holder.collections.some((collection: any) => userCollections.includes(collection))
                        
                })
            }
        }else if (filters.type === "favorites"){
            const user = store.getState().user.user
            if (user){
                const userFavs = user.favorites ? user.favorites : []
                newHolders = newHolders.filter((holder: any) => {
                    if (holder.type !== "holder")
                        return false
                    else
                        return holder.collections.some((collection: any) => userFavs.includes(collection))
                        
                })
            }
        }
    }

    if (filters.markerType !== "all") {
        newHolders = newHolders.filter((holder: any) => {
            return holder.type === filters.markerType
        })
    }



    if (search !== null && search !== "") {
        newHolders = newHolders.filter((holder: any) => {
            if (holder.type === "place")
                return holder.name.toLowerCase().includes(search!.toLowerCase())
            else if (holder.type === "holder")
                return holder.username.toLowerCase().includes(search!.toLowerCase())
            else
                return false
        })
    }


    if (filters.badges.length > 0) {
        newHolders = newHolders.filter((holder: any) => {
            if (holder.type !== "holder")
                return false
            else{
                //if any of the badges are in holder
                return filters.badges.some((badge: any) => holder.badges.includes(badge))
            }
        })
    }

    if (onlyWhitelisted && currentViewingWhitelist) {
        newHolders = newHolders.filter((holder: any) => {
            return holder.whitelisted
        })
    }

    return newHolders

}