import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { deviceMax } from "styles/media"

export const WalletSelect = styled.div`

`

export const Title = styled.div`
    font-size: 18px;
    margin-bottom: 10px;
`

export const Wallets = styled.div`
    display:flex;
    flex-direction:column;
`

export const Wallet = styled.div<{active : string}>`
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-top:10px;

    &:last-child{
        margin-bottom:10px;
    }

    border-radius:8px;
    background-color:${Hex2Rgba(color.white, 0.05)};
    padding:10px;
    cursor:pointer;

    &:hover{
        background-color:${Hex2Rgba(color.white, 0.1)};
    }

    ${props => props.active === "true" && `
        background-color:${Hex2Rgba(color.primary, 0.1)};
        border:1px solid ${color.primary};
    `}
`

export const WalletHeader = styled.div`
    display:flex;
    flex-direction:column;
    margin-bottom:5px;
`

export const WalletAddress = styled.div`
    font-size:14px;
    word-break:break-all;
`

export const WalletName = styled.div`
    color:${Hex2Rgba(color.white, 0.8)};
    font-size:14px;

    display:flex;
    align-items:center;
`

export const WalletSelectIcon = styled.div`

`

export const NoWallets = styled.div``
export const NoWalletsText = styled.div`
    font-size:14px;
    color:${Hex2Rgba(color.white, 0.8)};
    margin-top:16px;
    margin-bottom:32px;
    text-align:center;
`