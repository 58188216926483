import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"

export const MapFilter = styled.div`
    font-size:14px;
`
export const Filter = styled.div`
    margin-bottom:20px;
`

export const FilterTitle = styled.div`
    margin-bottom:10px;
    display:flex;
    justify-content:space-between;
    align-items:center;

    & small {
        color:${color.gray};
    }
`

export const FilterOptions = styled.div`
    display:flex;
    & > button{
        display:flex;
        align-items:center;
        & img{
            width:15px;
            margin-right:5px;
        }
        flex:1;

        justify-content:center;

        &:not(:last-child){
            margin-right:10px;
        }
    }
`

export const FilterBadgesList = styled.div`
    display:flex;
    flex-wrap:wrap;

    & > div{
        margin-right:10px;
        margin-bottom:10px;
        cursor:pointer;

        opacity:0.5;

        &:hover{
            opacity:.75;
        }

        user-select:none;
    }

    & > .a{
        opacity:1 !important;
    }
`
export const FilterBadges = styled.div`
    margin-bottom:10px; 
`

export const Actions = styled.div`
    display:flex;

    & > button{
        flex:1;

        &:not(:last-child){
            margin-right:10px;
        }
    }
`

export const FilterTitleText = styled.div``

export const NewBadge = styled.div`
    position:absolute;
    top:-6px;
    right:-9px;
    font-size:9px;
    font-weight:600;
    background:linear-gradient(45deg,${Hex2Rgba(color.gradient3.start, 1)},${Hex2Rgba(color.gradient3.end, 1)}); 
    color:${color.black};
    border-radius:12px;
    padding:2px 4px;
    cursor:default;
`