import toast from "react-hot-toast"
import { getAllCollections } from "services/api/collections"
import { GetInitialData } from "services/api/data"
import { setCollections, setWhitelistCollections, setLaunches, setTokens } from "services/slices/data"
import { set_Collections } from "services/slices/_collections"
import { set_WhitelistCollections } from "services/slices/_whitelistCollections"
import { set_Launches } from "services/slices/_launches"
import { store } from "services/store"

export const GetAllData = async () => {
    GetInitialData().then((res) => {
        store.dispatch(setCollections(res.data.collections))
        store.dispatch(set_Collections(res.data.collections))
        store.dispatch(setWhitelistCollections(res.data.whitelistCollections))
        store.dispatch(set_WhitelistCollections(res.data.whitelistCollections))
        store.dispatch(setLaunches(res.data.launches))
        store.dispatch(set_Launches(res.data.launches))
        store.dispatch(setTokens(res.data.tokens))
    }).catch((err : any) => {
        toast.error("Failed to connect to server")
        console.log(err)
    })
}
