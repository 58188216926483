import Button from "components/ui/button"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { setDisplayGuide } from "services/slices/data"
import { showGuidePopup } from "services/slices/popup"
import { store } from "services/store"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { setLocalData } from "utils/localData"
import * as C from "./style"

const lengths: any = {
    map: 5,
    whitelist: 4,
}

const Guide = () => {

    const [step, setStep] = useState(1)
    const [currentGuide, setCurrentGuide] = useState("map")

    const switchGuide = (guide: string) => {
        setCurrentGuide(guide)
        setStep(1)
    }

    const nextStep = () => {
        if (step < lengths[currentGuide]) {
            setStep(step + 1)
        }
    }

    const prevStep = () => {
        if (step > 1) {
            setStep(step - 1)
        }
    }

    const hide = () => {
    
        if (store.getState().popup.GuidePopup) {
            dispatch(showGuidePopup(false))
        }else{
            setLocalData("guide", "no")
            dispatch(setDisplayGuide(false))
        }
    }

    const dispatch = useDispatch()

    return (
        <C.Guide>
            <C.Header>
                <C.StepsDots>
                    {Array.from(Array(lengths[currentGuide])).map((_, i) => {
                        return (
                            <C.StepDot key={i + 1} active={i + 1 === step ? "true" : "false"} />
                        )
                    })}
                </C.StepsDots>
                <C.Close onClick={hide}>&times;</C.Close>
            </C.Header>
            <C.SwitchGuide>
                <C.SwitchGuideItem onClick={() => switchGuide("map")} active={currentGuide === "map" ? "true" : "false"}>Map Guide</C.SwitchGuideItem>
                <C.SwitchGuideItem onClick={() => switchGuide("whitelist")} active={currentGuide === "whitelist" ? "true" : "false"}>Whitelist Guide</C.SwitchGuideItem>
            </C.SwitchGuide>
            <C.Content>
                <C.ContentImage>
                    <img src={"/images/guide/" + (currentGuide === "map" ? "m" : "w") + step + ".png"} alt="map guide step 1" />
                </C.ContentImage>
                {currentGuide === "map" && (
                    <>
                        {step === 1 && (
                            <>

                                <C.ContentTitle>
                                    🔍 Welcome to the hub3
                                </C.ContentTitle>
                                <C.ContentText>
                                    Hub3 is an early access <span>Web 3.0 App</span> in development. Let's take a quick look at the Hub3 ecosystem with its <span>renewed design!</span>
                                </C.ContentText>
                            </>
                        )}

                        {step === 2 && (
                            <>

                                <C.ContentTitle>
                                    🌐 Hub3 Geolocation System
                                </C.ContentTitle>
                                <C.ContentText>
                                    You can view a live globe map via the world button in the menu on the left.<br /><br />
                                    • This map displays in <span>real-time</span> which users hold which NFT collections.<br /><br />
                                    • You can explore web3 <span>communities and individuals</span> in further detail using the above filtering tools and menu.
                                </C.ContentText>
                            </>
                        )}

                        {step === 3 && (
                            <>

                                <C.ContentTitle>
                                    😶 User Profile on the map
                                </C.ContentTitle>
                                <C.ContentText>
                                    By clicking on a user's marker on the map, you can view the user's profile.<br /><br />

                                    • This section contains a user's <span>bio, social media accounts, status, and skills</span>.

                                    • With the Expand NFT’s button, you can <span>list, filter, and explore</span> in detail the user's NFTs.
                                </C.ContentText>
                            </>
                        )}

                        {step === 4 && (
                            <>

                                <C.ContentTitle>
                                    ⚙️ The Profile and Settings
                                </C.ContentTitle>
                                <C.ContentText>
                                    You can set up your profile and wallets using these sections.<br /><br />

                                    • From the <span>settings tab</span> above your profile picture, you can <span>add your wallets and social media accounts, and change your location</span> on the map.<br/><br/>

                                    • If you click on your <span>profile picture</span>, you will see a preview of your profile and be able to <span>edit it</span>.
                                </C.ContentText>
                            </>
                        )}

                        {step === 5 && (
                            <>

                                <C.ContentTitle>
                                    🧾 The Left bar
                                </C.ContentTitle>
                                <C.ContentText>
                                    This menu allows you to switch to other features on the hub3 ecosystem.<br /><br />

                                    • If you click on the <span>"Sparkling"</span> list icon, you will enter to the <span>Whitelist section</span>.<br /><br />

                                    • In the whitelist section, you can see <span>upcoming projects and the status of your whitelist. You can also join the whitelist of new projects</span><br /><br />
                                    • Unfortunately, we cannot give information about the <span>“shuffle”</span> at the moment, but it will be with you very soon.
                                </C.ContentText>
                            </>
                        )}
                    </>
                )}

                {currentGuide === "whitelist" && (
                    <>
                        {step === 1 && (
                            <>
                                <C.ContentTitle>
                                    Alpha Spots, How does it work?
                                </C.ContentTitle>
                                <C.ContentText>
                                    First comes, first serves.<br/><br/>

                                    • The number of <span>Alpha Spots</span> is <span>determined</span> by the project team. <br/><br/>

                                    • By joining Alpha Spots, you can be one of the <span>first project whitelist members</span> and <span>invite others</span> to participate in the whitelisting process.
                                </C.ContentText>
                            </>
                        )}
                        {step === 2 && (
                            <>
                                <C.ContentTitle>
                                    The Nomination System
                                </C.ContentTitle>
                                <C.ContentText>
                                    Alpha spot holders can nominate you on Twitter for the whitelist after you complete the required quests.<br /><br />

                                    • If you have <span>received a nomination, you can use</span> this system to complete the necessary quests, earn your whitelist spots, <span>and nominate other users</span>.<br /><br />
                                    • If <span>no one has nominated</span> you for the whitelist and you want to join, you can <span>request a nomination</span> by clicking the "<span className="t">Ask for nominate</span>" button.<br /><br />

                                    • Make sure that your <span>Discord</span> and <span>Twitter</span> accounts are linked so you can join whitelists.
                                </C.ContentText>
                            </>
                        )}
                        {step === 3 && (
                            <>
                                <C.ContentTitle>
                                    Quests and <span>Golden pickers</span>
                                </C.ContentTitle>
                                <C.ContentText>
                                    The quests required to join the whitelist are in this section.<br /><br />

                                    • After completing the tasks, press the “<span>check</span>” button to see if the quests were completed.

                                </C.ContentText>
                            </>
                        )}
                        {step === 4 && (
                            <>
                                <C.ContentTitle>
                                    Acquiring the Whitelist
                                </C.ContentTitle>
                                <C.ContentText>
                                    Congratulations, you are now whitelisted!

                                    • Your picker has turned <span className="t">golden</span> on the map and now people can <span>beg you to nominate them!</span><br /><br />
                                    • Please don't forget to <span>select a mint wallet</span>, otherwise your whitelist may be wasted.<br /><br />
                                    • You can help more people join the whitelist by clicking the <span>“Nominate More People”</span> button!
                                </C.ContentText>
                            </>
                        )}
                    </>
                )}
            </C.Content>
            <C.Actions>
                {step > 1 && (
                    <Button onClick={prevStep} disabled={step === 1} theme="gradient-border-transparent-bg-on-hover" bg={Hex2Rgba(color.secondaryFade, .5)} color="white">Back</Button>
                )}

                {step === 1 && (
                    <div></div>
                )}

                {step < lengths[currentGuide] && (
                    <Button disabled={!(step < lengths[currentGuide])} theme="gradient" bg={Hex2Rgba(color.secondaryFade, .5)} onClick={nextStep} color="white">Next</Button>
                )}
            </C.Actions>
        </C.Guide>
    )
}

export default Guide