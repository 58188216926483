import axios from "axios"
import env from "env"
import { getLocalData, removeLocalData, setLocalData } from "utils/localData"
import nacl from "tweetnacl"
import base58 from "bs58"

export const autoLogin = async () => new Promise((resolve, reject) => {
    axios.get(env.API.URL + "user/auto-login", {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    }).then((res) => {
        setLocalData("token", res.data.token)
        let keypair = nacl.box.keyPair()
        setLocalData("key", base58.encode(keypair.secretKey))
        resolve(res.data.user)
    }).catch((err) => {
        if (err.response && err.response.status === 401 && getLocalData("token"))
            removeLocalData("token")
        
        reject(err)
    })
})

export const login = async (provider : string, address: string) => new Promise((resolve, reject) => {
    axios.post(env.API.URL + "user/login", { provider, address }).then((res) => {
        setLocalData("token", res.data.token)
        resolve(res.data.user)
    }).catch((err) => {
        reject(err)
    })
})

export const validateWallet = async (provider : string, address: string, signedMessage : string, publicKey : string = "") => new Promise((resolve, reject) => {
    axios.post(env.API.URL + "user/validate", { provider, address, signedMessage, publicKey }, {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    }).then((res : any) => {
        //setLocalData("token", res.data.token)
        resolve(res.data)
    }).catch((err) => {
        reject(err)
    })
})

export const failedValidation = () => {
    axios.put(env.API.URL + "user/failed-validation", {}, {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    }).then((res) => {
        if (!res.data.acceptable)
            removeLocalData("token")
    }).catch((err) => {})
}

export const validateTokenAndGetWallets = async () => new Promise((resolve, reject) => {

    if (!getLocalData("token"))
        return resolve(false)

    axios.get(env.API.URL + "user/validate-token", {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    }).then((res) => {
        resolve(res.data.wallets)
    }).catch((err) => {
        resolve(false)
    })
})

export const removeWallet = async (address : any) => {
    return axios.delete(env.API.URL + "user/actions/remove-wallet/"+ address, {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}

export const connectDiscord = async (code : string, state : string) => {
    return axios.post(env.API.URL + "user/social/connect/discord", { code, state }, {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}

export const disconnectDiscord = async () => {
    return axios.delete(env.API.URL + "user/social/disconnect/discord", {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}

export const twitterRequestToken = async () => {
    return axios.get(env.API.URL + "user/social/twitter-request-token", {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}

export const connectTwitter = async (oauth_token : string, oauth_verifier : string) => {
    return axios.post(env.API.URL + "user/social/connect/twitter", { oauth_token, oauth_verifier }, {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}

export const disconnectTwitter = async () => {
    return axios.delete(env.API.URL + "user/social/disconnect/twitter", {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}
export const twitterLoginRequest = async () => {
    return axios.get(env.API.URL + "user/login-request")
}
export const twitterLoginComplete = async (oauth_token : string, oauth_verifier : string,id:string) => {
    return axios.post(env.API.URL + "user/login-complete", { oauth_token, oauth_verifier,id })
}

export const loginWithWeb3Auth = async (appPubKey : string, wallet:string, jwt: string) => {
    return axios.post(env.API.URL + "user/login", { appPubKey, wallet }, {
        headers: {
            Authorization: "Bearer " +jwt
        }
    })
}
