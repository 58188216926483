import { useSelector } from "react-redux"
import * as P from "./style"

import BaseMobilePage from "components/mobile/base"
import { IconCollections, IconShuffle, IconWhitelist } from "utils/icons"

import { MainParts } from "utils/parts"
import ShuffleLiquidation from "components/parts/shuffle/shuffleLiquidation"

const MobilePage = () => {

    const currentPage = useSelector((state: any) => state.data.currentPage)

    return (
        <P.MobilePage>
            <BaseMobilePage title="Shuffle Liquidation" show={currentPage === MainParts.ShuffleLiquidation} icon={<IconShuffle/>} >
                <ShuffleLiquidation />
            </BaseMobilePage>
        </P.MobilePage>
    )
}

export default MobilePage