import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect } from "react"
import * as C from "./style"
import { faBell, faGlobe, faHeart, faSearch } from '@fortawesome/free-solid-svg-icons';
import { faBell as faBellAlt, faHeart as faHeartAlt } from '@fortawesome/free-regular-svg-icons';
import { faDiscord, faTwitter } from "@fortawesome/free-brands-svg-icons"
import LineChartSvg from "components/ui/lineChartSvg"
import { color } from "styles/theme";
import { InverseLerp, Lerp } from "utils/math";
import { showAnalyticsOfCollection } from "utils/parts";
import { formatLink, Hex2Rgba, toLink } from "utils/helpers";
import env from "env";
import { addCollectionToFavorites, removeCollectionFromFavorites } from "services/api/profile";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { addCollectionToUsersFavorites, removeCollectionFromUsersFavorites } from "services/slices/user";
import { store } from "services/store";
import { increaseCollectionFavoriteCount, decreaseCollectionFavoriteCount } from "services/slices/data";
/*
var rawData = [
    23.90,
    24.27,
    24.29,
    24.27,
    24.14,
    24,
    23.89,
    23.89,
    23.98,
    23.84,
    23.80,
    23.30
]

var data: any = []

//get max and min
var max = Math.max.apply(Math, rawData);
var min = Math.min.apply(Math, rawData);

for (var i = 0; i < rawData.length; i++) {
    data.push({ x: i, y: Lerp(0, 100, InverseLerp(min, max, rawData[i])) })

}*/

const Token = (props: any) => {

    const dispatch = useDispatch()

    const onClick = () => {
        if (props.onClick) {
            props.onClick(props.item.id)
        }
    }

    if (!props.item)
        return <></>

    return (
        <C.Token>


            <C.TokenImg to={`/app/tokens/${toLink(props.item.name)}`}>
                <img src={env.API.CDN + "tokens/covers/" + props.item.cover} alt={props.item.name + " Image"} />
            </C.TokenImg>
            <C.TokenContent>
                <C.TokenName to={`/app/tokens/${toLink(props.item.name)}`}>{props.item.name}</C.TokenName>

            </C.TokenContent>

            <C.Actions></C.Actions>
        </C.Token>
    )

}

export default Token