import React, { useEffect, useState } from "react"
import * as C from "./style"

import { useDispatch, useSelector } from "react-redux"
import Button from "components/ui/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import DropdownButton, { DropdownItem } from "components/ui/button/dropdown"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons"
import { ParseAwardAddress } from "modules/solana/shuffle/helpers"
import { LAMPORTS_PER_SOL } from "@solana/web3.js"
import { withdrawLiquidityNative, withdrawLiquidityToken } from "modules/solana/shuffle/liquidity"
import toast from "react-hot-toast"
import { RefreshLiquidations } from "services/managers/shuffle"
import { showShuffleLiquidityTokenPopup } from "services/slices/popup"

const ShuffleLiquidityToken = () => {

    const shuffleLedger = useSelector((state: any) => state.shuffle.loadedLedger)
    const tokenViewAddress = useSelector((state: any) => state.shuffle.tokenViewAddress)
    const programId = useSelector((state: any) => state.shuffle.programId)
    const vault = useSelector((state: any) => state.shuffle.vault)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const Withdraw = (index : number) => {
        let item = shuffleLedger.account.liquidations[index]

        if (loading) {
            toast.error("Please wait for the previous transaction to complete")
            return;
        }

        setLoading(true)

        if (item.address === "So11111111111111111111111111111111111111111"){
            withdrawLiquidityNative(programId, vault, shuffleLedger.publicKey, index).then((res: any) => {
                RefreshLiquidations()
            }).catch((err) => {
                toast.error(err)
            }).finally(() => {
                setLoading(false)
            })
        }else {
            withdrawLiquidityToken(programId, vault, shuffleLedger.publicKey, index, item.address).then((res: any) => {
                RefreshLiquidations()
            }).catch((err) => {
                toast.error(err)
            }).finally(() => {
                setLoading(false)
            })
        }
    }

    useEffect(() => {

        if (shuffleLedger.account.liquidations.filter((item: any) => item.address === tokenViewAddress).length === 0){
            dispatch(showShuffleLiquidityTokenPopup(false))
        }

    }, [shuffleLedger])

    return (
        <C.ShuffleLiquidityToken>
            <C.Title>
                {ParseAwardAddress(tokenViewAddress).name} Liquidations
            </C.Title>

            <C.Description>
                {/*On the right side is the approximate estimation of how many shuffles later your liquidation will be used.*/}
            </C.Description>

            <C.Liquidations>
                {shuffleLedger.account.liquidations.filter((item: any) => item.address === tokenViewAddress).map((item: any, i: number) => {
                    let data = ParseAwardAddress(item.address)
                    return (
                        <C.Liquidation key={i}>
                            <C.LiquidationLeft>
                                <C.LiquidationTotal>{item.amount[1] / LAMPORTS_PER_SOL * item.amount[0]} {data.name}</C.LiquidationTotal>
                                <C.LiquidationCount>{item.amount[0]}  x <span>{item.amount[1] / LAMPORTS_PER_SOL} {data.name}</span></C.LiquidationCount>
                            </C.LiquidationLeft>

                            <C.LiquidationRight>
                                <C.LiquidationRightText>
                                  {/*  ~  1 shuffle*/}
                                </C.LiquidationRightText>

                                <C.LiquidationRightButton>
                                    <DropdownButton text="" icon={<FontAwesomeIcon icon={faEllipsisVertical} />} buttonStyle={{ theme: "gradient-border-transparent-bg-on-hover", bg: Hex2Rgba(color.secondaryFade, 1), color: "white" }} >
                                        <DropdownItem onClick={() => Withdraw(i)}>
                                            Withdraw All
                                        </DropdownItem>
                                    </DropdownButton>
                                </C.LiquidationRightButton>
                            </C.LiquidationRight>
                        </C.Liquidation>
                    )
                })}
            </C.Liquidations>
        </C.ShuffleLiquidityToken>
    )

}

export default ShuffleLiquidityToken