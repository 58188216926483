import React from "react"
import CollectionsMobilePage from "components/mobile/collections"
import ProfileMobilePage from "components/mobile/profile"
import SettingsMobilePage from "components/mobile/settings"
import WhitelistCollectionsMobilePage from "components/mobile/whitelistCollections"
import CurrentCollectionMobileWidget from "components/mobile/currentCollection"
import CurrentTokenMobileWidget from "components/mobile/currentToken"
import LaunchesMobilePage from "components/mobile/launches"
import ShuffleLiquidationMobilePage from "components/mobile/shuffleLiquidation"
import ShuffleMobilePage from "components/mobile/shuffle"
import ShareMobilePage from "components/mobile/share"
import MyProfileMobilePage from "components/mobile/myProfile"
import Chat from "components/mobile/chat"
import Shares from "components/mobile/shares"
import { useSelector } from "react-redux"

const MobilePages = () => {

    const isMobile = useSelector((state: any) => state.data.isMobile)

    if (!isMobile)
        return (<></>)

    return (
        <>
            <CollectionsMobilePage/>
            <ProfileMobilePage/>
            <SettingsMobilePage/>
            <WhitelistCollectionsMobilePage/>
            <CurrentCollectionMobileWidget/>
            <CurrentTokenMobileWidget/>
            <LaunchesMobilePage/>
            <ShuffleLiquidationMobilePage/>
            <ShuffleMobilePage/>
            <ShareMobilePage/>
            <MyProfileMobilePage/>
            <Chat/>
            <Shares/>
        </>
    )
}

export default MobilePages