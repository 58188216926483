import axios from "axios"
import env from "env"
import { getLocalData, removeLocalData, setLocalData } from "utils/localData"

export const ICcheck = async (data: any) => {
    return axios.post(env.API.URL + "share/ic", data, {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}

export const submitShareAccountBroadcast = async () => {
    return axios.get(env.API.URL + "share/account-broadcast", {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}

export const getMyShareProfile = async () => {
    return axios.get(env.API.URL + "share/my-share", {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}

export const getIssuer = async (id: string) => {
    return axios.post(env.API.URL + "share/view/", { id })
}

export const shareTransact = async (data: any) => {
    return axios.post(env.API.URL + "share/transact", data, {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}

export const listShares = async (data: any) => {
    return axios.post(env.API.URL + "share/list", data, {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}

export const listSharesCustom = async (data: any) => {
    return axios.post(env.API.URL + "share/list-custom", data, {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}
export const getCloudWallet = async (data: any) => {
    return axios.post(env.API.URL + "share/cloud-wallet", data, {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}