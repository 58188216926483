import React, { useEffect, useMemo, useState } from "react"
import * as C from "./style"
import { faChevronRight, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Input from "components/ui/input"
import Button from "components/ui/button"
import { color } from "styles/theme"
import { Hex2Rgba, getUserSigner, refreshCloudWalletBalance, shortenPublicKey } from "utils/helpers"
import { closeAllPopups, showConnectedWalletSelectPopup, showWalletConnectPopup } from "services/slices/popup"
import { useDispatch, useSelector } from "react-redux"
import { setWalletConnectSettings } from "services/slices/data"
import { ICcheck, getMyShareProfile, submitShareAccountBroadcast } from "services/api/share"
import { getLocalData } from "utils/localData"
import base58 from "bs58"
import toast from "react-hot-toast"
import nacl from "tweetnacl"
import env from "env"
import crypto from "crypto"
import { Keypair, LAMPORTS_PER_SOL } from "@solana/web3.js"
import { createShareAccount } from "modules/solana/share/shareTx"
import { RefreshUser } from "services/managers/user"
import BigNumber from "bignumber.js"
import { useWeb3Auth } from "hooks/useWeb3Auth"
import { getShareError } from "modules/solana/share/shareError"
import { displayCloudWallet, displayEditProfile } from "utils/parts"

var broadcastRetries = 0

const SharesTab = () => {

    const dispatch = useDispatch()

    const [pageLoading, setPageLoading] = React.useState<boolean>(true)

    const [listInviteCodes, setListInviteCodes] = React.useState<boolean>(false)

    const [inviteCodeError, setInviteCodeError] = React.useState<any>(null)
    const [walletError, setWalletError] = React.useState<any>(false)
    const [inviteCode, setInviteCode] = React.useState<string>("")
    const [createAccountLoading, setCreateAccountLoading] = React.useState<boolean>(false)

    const [waitingForFinalization, setWaitingForFinalization] = React.useState<boolean>(false)
    const balance = useSelector((state: any) => state.user.cloudWalletBalance)
    const user = useSelector((state: any) => state.user.user)

    const [myShareProfile, setMyShareProfile] = React.useState<any>(null)
    const [txerror, setTxerror] = React.useState<any>(null)

    const { provider } = useWeb3Auth()

    /*useEffect(() => {
        if (selectedWallet) {
            setWalletError(false)
        }
        broadcastRetries = 0
    }, [selectedWallet])*/

    useEffect(() => {

        if (user.shareAddress !== null) {
            setPageLoading(true)
            getMyShareProfile().then((res: any) => {
                setMyShareProfile(res.data)
            }).catch((err: any) => {

            }).finally(() => {
                setPageLoading(false)
            })
        } else {
            setPageLoading(false)
        }

    }, [user])

    /*const selectWallet = () => {
        if (solanaWallets.length === 0) {
            dispatch(showWalletConnectPopup({ status: true }))
        } else {
            dispatch(showConnectedWalletSelectPopup({ status: true }))
        }
    }*/

    const onCodeChange = (e: any) => {
        setInviteCode(e.target.value)
        if (inviteCodeError) {
            setInviteCodeError(null)
        }
    }

    const createAccount = () => {
        setInviteCodeError(null)

        if (createAccountLoading) {
            return
        }

        setCreateAccountLoading(true)
        setWalletError(false)
        setTxerror(null)

        checkInviteCodeAndGetCloudWallet().then(({ cloudWallet, ic }: any) => {

            createShareAccount(cloudWallet, ic).then((res: any) => {
                setWaitingForFinalization(true)
                broadcast()
                refreshCloudWalletBalance()
            }).catch((err: any) => {
                console.log(err)
                if (err !== getShareError("0x1")) {
                    toast.error(err)
                } else {
                    toast.error("You don't have enough SOL to create a key account")
                    setTxerror("You don't have enough SOL to create a key account.")
                    setWalletError(true)
                }
                setCreateAccountLoading(false)
            })
        }).catch((err: any) => {
            setCreateAccountLoading(false)
        })
    }

    const checkInviteCodeAndGetCloudWallet = () => new Promise((resolve, reject) => {
        ICcheck({ ic: inviteCode.trim() }).then(async (res: any) => {
            const cloudWallet = await getUserSigner(provider)

            let ic = res.data.ic

            //remove H3- from invite code if it exists
            if (ic.includes("H3-")) {
                ic = ic.replace("H3-", "")
            }

            resolve({ cloudWallet, ic })

        }).catch((err: any) => {
            console.log(err)
            if (err.response) {
                //if 400
                if (err.response.status === 400) {
                    setInviteCodeError("Invite code used by someone else")
                } else if (err.response.status === 404) {
                    setInviteCodeError("Invite code does not exist")
                } else {
                    toast.error("Something went wrong")
                }
            } else {
                toast.error("Something went wrong")
            }

            reject(err)
        })
    })

    const broadcast = () => {
        submitShareAccountBroadcast().then((res: any) => {
            RefreshUser()
            toast.success("Key account created")
        }).catch((err: any) => {
            console.log(err)
            if (err.response) {
                if (err.response.status === 400) {
                    RefreshUser()
                    toast.success("Key account created")
                } else if (err.response.status === 404 || err.response.status === 500) {
                    if (broadcastRetries > 5) {
                        toast("This is taking longer than usual. Please wait a few minutes")
                        setTimeout(() => {
                            broadcast()
                        }, 60000)
                    } else {
                        broadcastRetries++
                        setTimeout(() => {
                            broadcast()
                        }, 2000)
                    }
                }
            }
        })
    }

    const isMobile = useSelector((state: any) => state.data.isMobile)

    return (
        <C.SharesTab>

            {pageLoading && (
                <C.PageLoading>
                    <FontAwesomeIcon icon={faSpinner} spin={true} />
                </C.PageLoading>
            )}

            {!pageLoading && (
                <>
                    {user.shareAddress === null && (
                        <>

                            <C.Title>
                                Create Key Account
                            </C.Title>
                            <C.Description>
                                Create a key account and let other users buy keys from you, chat with them and earn fees from trades.
                            </C.Description>

                            <C.WalletStuff>
                                <C.Wallet iserror={walletError ? "true" : "false"} isdisabled={waitingForFinalization ? "true" : "false"}>
                                    <C.WalletLeft>
                                        <C.WalletTitle>
                                            Wallet Balance
                                        </C.WalletTitle>
                                        <C.WalletAddress>
                                            {balance} SOL 
                                            {new BigNumber(balance).times(LAMPORTS_PER_SOL).lt(110000000) && (
                                                <C.WalletDeposit onClick={() => displayCloudWallet({ status: true })}>
                                                    Deposit
                                                </C.WalletDeposit>
                                            )}
                                        </C.WalletAddress>
                                    </C.WalletLeft>
                                </C.Wallet>

                                <C.Wallet iserror={"false"} isdisabled={waitingForFinalization ? "true" : "false"}>
                                    <C.WalletLeft>
                                        <C.WalletTitle>
                                            Total Cost
                                        </C.WalletTitle>
                                        <C.WalletAddress>
                                            0.1 SOL + gas fees
                                        </C.WalletAddress>
                                    </C.WalletLeft>
                                </C.Wallet>
                            </C.WalletStuff>

                            {txerror && (
                                <C.TxError>
                                    <C.TxErrorText>
                                        {txerror}&nbsp;
                                        <C.TxErrorLink onClick={() => displayCloudWallet({ status: true })}>
                                            Deposit SOL
                                        </C.TxErrorLink>
                                    </C.TxErrorText>
                                </C.TxError>
                            )}

                            <C.Code iserror={inviteCodeError !== null ? "true" : "false"} isdisabled={waitingForFinalization ? "true" : "false"}>
                                <C.CodeTitle>
                                    Invite code
                                </C.CodeTitle>
                                <Input type="text" placeholder="H3-XXXXXXXX" onChange={onCodeChange} />
                                {inviteCodeError && (
                                    <p>Invite code is invalid or used</p>
                                )}
                            </C.Code>



                            {!waitingForFinalization && (
                                <Button block="true" theme="gradient" bg={Hex2Rgba(color.white, .05)} color={color.white} onClick={createAccount} disabled={createAccountLoading} loading={createAccountLoading ? "true" : "false"}>Create Key Account</Button>
                            )}
                            {waitingForFinalization && (
                                <C.Finalization>

                                    <C.FinalizationText>
                                        Transaction confirmed. Waiting for finalization <FontAwesomeIcon icon={faSpinner} spin={true} />
                                    </C.FinalizationText>

                                </C.Finalization>
                            )}
                        </>
                    )}

                    {user.shareAddress !== null && (
                        <>
                            <C.StatsContainer>
                                <Button link="routerlink" to={"/app/share/" + user.id} onClick={() => {if (!isMobile){dispatch(closeAllPopups());}}} block="true" theme="gradient" bg={Hex2Rgba(color.white, .05)} color={color.white}>View My Key Profile</Button>
                                <C.Title>
                                    Your Stats
                                </C.Title>
                                <C.Stats>

                                    <C.StatsItem>
                                        <C.StatsItemTitle>
                                            Total volume on your keys
                                        </C.StatsItemTitle>
                                        <C.StatsItemValue>
                                            {new BigNumber(user.share.volume).div(LAMPORTS_PER_SOL).toString()} SOL
                                        </C.StatsItemValue>
                                    </C.StatsItem>
                                    <C.StatsItem>
                                        <C.StatsItemTitle>
                                            Total fees earned
                                        </C.StatsItemTitle>
                                        <C.StatsItemValue>
                                            {new BigNumber(user.share.totalFees).div(LAMPORTS_PER_SOL).toString()} SOL
                                        </C.StatsItemValue>
                                    </C.StatsItem>
                                </C.Stats>
                            </C.StatsContainer>

                            {!listInviteCodes && (
                                <C.InviteCodeView onClick={() => setListInviteCodes(true)}>
                                    View My Invite Codes
                                </C.InviteCodeView>
                            )}

                            {listInviteCodes && (
                                <>
                                    <C.InviteCodeTitle>
                                        Invite Codes
                                    </C.InviteCodeTitle>
                                    <C.InviteCodes>
                                        {myShareProfile.inviteCodes.map((ic: any, index: number) => (
                                            <C.InviteCode used={ic.usedBy !== null ? "true" : "false"} key={index}>
                                                {ic.id}
                                            </C.InviteCode>
                                        ))}
                                    </C.InviteCodes>
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </C.SharesTab>
    )

}

export default SharesTab