import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface _WhitelistCollectionsState {
    _whitelistCollections : any,
    chain : any,
    filter : any,
    sort : any,
    searchText : any,
}

const initialState: _WhitelistCollectionsState = {
    _whitelistCollections : [],
    chain : "all",
    filter : "all",
    sort : "all",
    searchText : "",
}

export const _whitelistCollectionsSlice = createSlice({
    name: '_wlcollections',
    initialState,
    reducers: {
        set_WhitelistCollections: (state, action: PayloadAction<any[]>) => {
            state._whitelistCollections = action.payload
        },
        setFilterValues: (state, action: PayloadAction<any>) => {
            state.filter = action.payload.filter
            state.chain = action.payload.chain
            state.sort = action.payload.sort
        },
        setSearchValue: (state, action: PayloadAction<any>) => {
            state.searchText = action.payload
        }
    }
})

export const { set_WhitelistCollections, setFilterValues, setSearchValue } = _whitelistCollectionsSlice.actions

export default _whitelistCollectionsSlice.reducer