import { Connection, GetProgramAccountsFilter, PublicKey, Transaction } from "@solana/web3.js"
import env from "env";
import { findWallet, Wallets } from "modules/wallet";
import toast from "react-hot-toast";
import { store } from "services/store";
import { sleep } from "utils/helpers";
import { getShuffleError } from "./shuffle/shuffleErrors";

const SESSION_HASH = 'HUB3' + Math.ceil(Math.random() * 1e9)
//export const SOLANA_CONNECTION = new Connection(env.WEB3.SOLANA.RPC, { commitment: 'finalized', httpHeaders: { "x-session-hash": SESSION_HASH } });
//export const SOLANA_CONNECTION = new Connection("http://127.0.0.1:8899", { commitment: 'finalized', httpHeaders: { "x-session-hash": SESSION_HASH } });
export const SOLANA_CONNECTION = new Connection(env.WEB3.SOLANA.RPC_SHUFFLE, { commitment: 'finalized', httpHeaders: { "x-session-hash": SESSION_HASH } });
export const SOLANA_CONNECTION_CONFIRMED = new Connection(env.WEB3.SOLANA.RPC_SHUFFLE, { commitment: 'confirmed', httpHeaders: { "x-session-hash": SESSION_HASH } });
export const SOLANA_CONNECTION_SHARE = new Connection(env.WEB3.SOLANA.RPC_SHARE, { commitment: 'confirmed', httpHeaders: { "x-session-hash": SESSION_HASH } });
export const SOLANA_RPC_CONNECTION = new Connection(env.WEB3.SOLANA.RPC_MINT, { commitment: 'finalized', httpHeaders: { "x-session-hash": SESSION_HASH } });

export const METADATA_PROGRAM_ID = new PublicKey("metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s");
export const AUTH_RULES_PROGRAM_ID = new PublicKey("auth9SigNpDKz4sJJ1DfCTuZrZNSAgh9sFD3rboVmgg");

export const WaitForTransaction = (signature: string, connection?:any) => new Promise(async (resolve, reject) => {
    let done = false
    //90 second timeout
    let timeout = 90 * 1000

    //if timeout is reached, reject
    setTimeout(() => {
        if (!done) {
            reject("timeout")
            done = true
        }
    }, timeout)

    let conn = connection ? connection : SOLANA_CONNECTION

    while (!done) {
        try {
            const latestBlockHash = await conn.getLatestBlockhash();
            let confirm = await conn.confirmTransaction({
                signature,
                blockhash: latestBlockHash.blockhash,
                lastValidBlockHeight: latestBlockHash.lastValidBlockHeight
            })

            if (confirm.value.err) {
                console.error(confirm.value)
                done = true
                reject(confirm.value.err);
            }

            done = true
        } catch (error) {
            console.log(error)
            await sleep(1000)
        }
    }

    resolve(true)
})

export const signAllAndSend = async (txs: Transaction[], message?:string) => {

    const wallet = findWallet(store.getState().user.selectedWallet)

    if (!wallet) {
        throw new Error('No wallet selected')
    }

    const tx = new Transaction()

    txs.forEach((txx) => {
        tx.add(txx)
    })

    tx.recentBlockhash = (await SOLANA_CONNECTION.getLatestBlockhash("finalized")).blockhash;
    tx.feePayer = new PublicKey(wallet.address[0])
    let signed ;
    try {
        signed = await wallet.instance.signTransaction(tx)
    } catch (e:any) {

        console.log(e)

        if (e.message.indexOf("Transaction too large") !== -1) {
            return Promise.reject(getShuffleError("0x2"))
        }

        return Promise.reject(getShuffleError("0x0"))
    }

    //console.log("signed", signed)

    let loading = toast.loading(message || "Processing...")

    try {
        const sig = await SOLANA_CONNECTION.sendRawTransaction(signed.serialize(), { skipPreflight: true, preflightCommitment: "finalized" })
       //console.log("sig", sig)

        let confirm = await WaitForTransaction(sig)
        //console.log("confirm", confirm)
        toast.dismiss(loading)

    } catch (e) {
        //console.log("error", e)
        toast.dismiss(loading)
        return Promise.reject(e)
    }

    return Promise.resolve(true)
}

export const signAllAndSendAll = async (txs: Transaction[], message?:string) => {

    const wallet = findWallet(store.getState().user.selectedWallet)

    if (!wallet) {
        throw new Error('No wallet selected')
    }

    let signedTxs : any;


    try {
        signedTxs = await wallet.instance.signAllTransactions(txs)
    } catch (error) {
        return Promise.reject(getShuffleError("0x0"))
    }

    let loading = toast.loading(message || "Processing...")

    var processing = 0
    var success = 0
    var failed = 0
    var concurrent = 5

    for (let i = 0; i < signedTxs.length; i++) {
        processing++
        try {
            let sig = await SOLANA_CONNECTION.sendRawTransaction(signedTxs[i].serialize(), {
                skipPreflight: true,
                preflightCommitment: "confirmed"
            })

            //console.log("sig", sig)

            try {
                WaitForTransaction(sig).then(() => {
                    success++
                    processing--
                }).catch((err) => {
                    failed++
                    processing--
                })
            } catch (err) {
                failed++
                processing--
            }
        } catch (err) {
            failed++
            processing--
        }

        while (processing >= concurrent) {
            await sleep(1000)
        }
    }

    while (processing > 0) {
        await sleep(1000)
    }

    toast.dismiss(loading)

    toast.dismiss(loading)
    if (success > 0 && failed > 0) {
        toast.success(success + " transaction send successfully and " + failed + " failed")
    } else if (success > 0) {
        toast.success(success + " transaction send successfully")
    } else if (failed > 0) {
        toast.error(failed + " transaction failed")
    }

    return Promise.resolve(true)

}

