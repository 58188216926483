import { Link } from "react-router-dom"

export const LD = (props : any) => { 
    if (props.view)
        return (
            <div {...props}>
                {props.children}
            </div>
        )
    else
        return (
            <Link {...props}>
                {props.children}
            </Link>
        )
}