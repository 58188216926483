import { io, Socket } from "socket.io-client"
import env from "env"
import { store } from "./store"

export const SocketResponseCodes = {
    userUpdate: "1000",
    twitterStream: "1001",
    whitelistUpdate: "1002",
    og: "1003",
    mint: "1004",
} as const

export var socket: Socket

var listening = {
    userUpdate: false,
    whitelistUpdate: false,
    og: false,
    mint: false
}

export const initSocket = (onUserUpdate: any, onOgUpdate: any, onMint : any, onWhitelist:any) => {
    /*socket = io(env.API.SOCKET, {
        transports: ["websocket", "polling"],
    })

    socket.on("connect", () => {
        //console.log("Socket connected")

        //first 10 seconds dont accept any data
        //setTimeout(() => {

        if (!listening.userUpdate) {
            socket.on(SocketResponseCodes.userUpdate, (data: any) => {

                if (onUserUpdate) {
                    onUserUpdate(data)
                    //console.log("Socket user update", data)
                    listening.userUpdate = true
                }

            })
        }
        if (!listening.og) {
            socket.on(SocketResponseCodes.og, (data: any) => {

                if (onOgUpdate) {
                    onOgUpdate(data)
                    //console.log("Socket og update", data)
                    listening.og = true
                }

            })
        }
        if (!listening.mint) {
            socket.on(SocketResponseCodes.mint, (data: any) => {

                if (onMint) {
                    onMint(data)
                    //console.log("Socket mint update", data)
                    listening.mint = true
                }

            })
        }
        if (!listening.whitelistUpdate) {
            socket.on(SocketResponseCodes.whitelistUpdate, (data: any) => {

                if (onWhitelist) {
                    onWhitelist(data)
                   // console.log("Socket wl update", data)
                    listening.whitelistUpdate = true
                }

            })
        }

        if (store.getState().holders.currentViewingWhitelist !== null) {
            if (store.getState().whitelist.currentWhitelist && store.getState().whitelist.currentWhitelist.id !== null) {
                socket.emit("join", store.getState().whitelist.currentWhitelist.id)
            }
        }

        if (store.getState().holders.currentViewingLaunch !== null) {
            if (store.getState().launch.currentLaunch && store.getState().launch.currentLaunch.id !== null) {
                socket.emit("join", store.getState().launch.currentLaunch.id)
            }
        }
        //}, 10000)
    })

    socket.on("disconnect", () => {
        //console.log("Socket disconnected")
    })

    socket.on("error", (err) => {
        //console.log("Socket error", err)
    })

    socket.on("reconnect", () => {
        //console.log("Socket reconnected")
    })

    socket.on("reconnect_attempt", () => {
        //console.log("Socket reconnect attempt")
    })*/


}