export const errors = {
    "0x0": "User denied transaction signature",
    "0x1": "Not enough SOL to cover fee",
    "0x2": "Transaction too large",
    "0x1773": "Ticket count is invalid",
    "0x1783": "Maximum ticket count exceeded",
    "0x1779": "No Permission"
}

export const getShuffleError = (code: any) => {
    if (typeof code === "string") {

        if (typeof (errors as any)[code] === "undefined")
            return false;

        return (errors as any)[code]
    } else {
        //convert to hex
        let hex = code.toString(16)
        return (errors as any)["0x"+hex]
    }
}

export const parseCustomProgramError = (error: any) => {
    let errorString = error.toString()
    let errorCode = errorString.substring(errorString.indexOf("0x"), errorString.indexOf("0x") + 6)

    if (errorCode) {
        return errorCode
    } else {
        return false
    }
}