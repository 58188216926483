import React, { useEffect, useState } from "react"
import { displayCollections, displayWalletConnectPopup, displayProfile, displaySettings, displayMapFilterPopup, displayGuide, displayCloudWallet } from "utils/parts"
import { IconCollections, IconConnectWallet, IconFilter, IconGuide, IconWhitelist } from "utils/icons"
import * as C from "./style"
import ReactTooltip from "react-tooltip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSignOut, faChartSimple, faImages, faSignIn, faCog, faFilter, faSearch, faTimes, faCheck, faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { faUser } from "@fortawesome/free-regular-svg-icons"
import MapFilter from "../mapFilter"
import { useDispatch, useSelector } from "react-redux"
import { logout } from "services/managers/user"
import { setHoldersSearch, setOnlyWhitelisted } from "services/slices/holders"
import { ManageHolderStates } from "services/managers/holders"

var searchCheck: any = false

const Bar = () => {
    useEffect(() => {
        ReactTooltip.rebuild()
        ReactTooltip.hide()
    }, [])

    const user = useSelector((state: any) => state.user.user)
    const mapFilter = useSelector((state: any) => state.holders.filters)
    const isMobile = useSelector((state: any) => state.data.isMobile)
    const [searching, setSearching] = useState(false)
    const input = React.useRef<any>(null)
    const dispatch = useDispatch()
    const currentViewingWhitelist = useSelector((state: any) => state.holders.currentViewingWhitelist)
    const currentViewingLaunch = useSelector((state: any) => state.holders.currentViewingLaunch)
    const isOnlyWhitelisted = useSelector((state: any) => state.holders.onlyWhitelisted)
    const balance = useSelector((state: any) => state.user.cloudWalletBalance)

    const isNavActive = (path: string) => {

        let location = window.location.pathname

        //remove last slash
        if (path[path.length - 1] === "/")
            path = path.slice(0, -1)

        if (location[location.length - 1] === "/")
            location = location.slice(0, -1)

        return location === path ? "active" : ""
    }

    const activateSearch = () => {
        setSearching(true)
    }

    useEffect(() => {
        if (searching) {
            document.getElementById("searchMap")?.focus()
        }
    }, [searching])

    const deactivateSearch = () => {
        setSearching(false)
        dispatch(setHoldersSearch(null))
        ManageHolderStates(false, "search deactivated")
    }

    const onSearchBlur = () => {
        if ((document.getElementById("searchMap") as any).value === "") {
            deactivateSearch()
        }
    }

    const onKeyDown = (e: any) => {
        //if user not typing for 3 seconds, search
        clearTimeout(searchCheck)
        if (input.current.value.length > 2) {
            searchCheck = setTimeout(() => {
                dispatch(setHoldersSearch(input.current.value))
                //console.log("search")
                ManageHolderStates(false, "search activated")
            }, 1000)
        } else if (input.current.value.length === 0) {
            dispatch(setHoldersSearch(null))
            ManageHolderStates(false, "search deactivated (empty)")
        }
    }

    const onlyWhitelisted = () => {
        dispatch(setOnlyWhitelisted(!isOnlyWhitelisted))
        dispatch(setHoldersSearch(null));
        try {
            (document.getElementById("searchMap") as any).value = ""
        } catch (e) { }
        setSearching(false)
        ManageHolderStates(false, "only whitelisted: " + !isOnlyWhitelisted)
    }

    return (
        <C.BarContainer>
            <C.Bar className="SEARCH__">
                <C.BarContent>

                    {(!searching || isMobile) && (
                        <C.Item tabIndex={0} active={searching ? "true" : "false"} onClick={() => activateSearch()}>
                            <FontAwesomeIcon icon={faSearch} />
                            {!isMobile && <C.ItemText>Search</C.ItemText>}
                        </C.Item>
                    )}

                    {(searching && !isMobile) && (
                        <C.Item tabIndex={0} active="false" type="search" >
                            <FontAwesomeIcon icon={faSearch} />
                            <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                                <C.SearchInput ref={input} onChange={onKeyDown} onKeyDown={onKeyDown} onBlur={onSearchBlur} id="searchMap" type="text" placeholder="User Name..." />
                            </form>
                            <FontAwesomeIcon className="cursor-pointer" icon={faTimes} onClick={() => deactivateSearch()} />
                        </C.Item>
                    )}

                    {(!currentViewingWhitelist && !currentViewingLaunch) && (
                        <>
                            {/*<C.ItemLink className={isNavActive("/app/shares")} to="/app/shares" >
                            <IconCollections />
                            <C.ItemText>Shares</C.ItemText>
                </C.ItemLink>*/}
                            <C.Item tabIndex={0} active={
                                (mapFilter.type !== "all" || mapFilter.chain !== "all" || mapFilter.badges.length > 0) ? "true" : "false"
                            } onClick={() => displayMapFilterPopup(true)}>
                                <IconFilter />
                                <C.ItemText>Filter</C.ItemText>
                            </C.Item>
                        </>
                    )}

                    {(!currentViewingWhitelist && currentViewingLaunch) && (
                        <>
                            <C.Item tabIndex={0} active={
                                (mapFilter.type !== "all" || mapFilter.chain !== "all" || mapFilter.badges.length > 0) ? "true" : "false"
                            } onClick={() => displayMapFilterPopup(true)}>
                                <IconFilter />
                                <C.ItemText>Filter</C.ItemText>
                            </C.Item>
                        </>
                    )}

                    {currentViewingWhitelist && (
                        <C.Item type="whitelist" active={isOnlyWhitelisted ? "true" : "false"} onClick={onlyWhitelisted}>
                            <IconWhitelist />
                            <C.ItemText>Filter Whitelisted</C.ItemText>
                            {isOnlyWhitelisted && (<FontAwesomeIcon icon={faCheckCircle} />)}
                        </C.Item>
                    )}

                    {isMobile && (
                        <C.Item type="guide" onClick={displayGuide}>
                            <IconGuide />
                        </C.Item>
                    )}



                </C.BarContent>

                {(searching && isMobile) && (
                    <C.MobileSearch>
                        <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                            <C.SearchInput ref={input} onKeyDown={onKeyDown} onBlur={onSearchBlur} id="searchMap" type="text" placeholder="User Name..." />
                        </form>
                    </C.MobileSearch>
                )}

            </C.Bar>
            {user && (
                <C.Wallet>
                    <C.WalletItem onClick={() => displayCloudWallet(true)}>
                        <IconConnectWallet />
                        <C.WalletText>
                           {balance} SOL
                        </C.WalletText>
                    </C.WalletItem>
                </C.Wallet>
            )}
        </C.BarContainer>
    )
}

export default Bar