import { Button } from "components/ui/button/style"
import styled from "styled-components"
import { Col, Row } from "styles"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"

export const Profile = styled.div`

`

export const Nomination = styled.div`
    position: absolute;
    bottom: 10px;

    width: 100%;
`