import React, { useEffect, useState } from "react"
import * as C from "./style"

import { useDispatch, useSelector } from "react-redux"
import { WhitelistCollection } from "models/whitelist/collection"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import Button from "components/ui/button"
import { copyObject, Hex2Rgba } from "utils/helpers"
import { color } from "styles/theme"
import { updateWalletOfWhitelistEntry } from "services/api/whitelist"
import toast from "react-hot-toast"
import { showLaunchMintResultPopup, showConnectedWalletSelectPopup, showWhitelistWalletSelectPopup } from "services/slices/popup"
import { setEntry } from "services/slices/whitelist"
import { displaySettings, displayWalletConnectPopup } from "utils/parts"
import { findWallet } from "modules/wallet"
import { Network } from "models/enums/network"
import Slider from "react-slick"
import env from "env"

const LaunchMintResult = () => {

    const mintResult = useSelector((state: any) => state.launch.mintResult)
    const dispatch = useDispatch()
    const item = useSelector((state: any) => state.launch.currentLaunch)

    const close = () => {
        dispatch(showLaunchMintResultPopup(false))

    }

    const sliderSettings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "105px",
        slidesToShow: 1,
        speed: 500,
        arrows: true,
        mobileFirst: true
    }

    if (!mintResult)
        return <></>


    return (
        <C.LaunchMintResult>

            <C.Title>
                You minted {mintResult.length} "{item.name}" NFT!
            </C.Title>

            <C.Covers>


                <C.CoverSlider>
                    <Slider {...sliderSettings}>
                        {mintResult.map((mint: any, i: number) => {
                            if (mint !== null)

                                return (
                                    <C.Cover key={i} >
                                        <img src={mint.json.image} alt="cover" />
                                    </C.Cover>
                                )
                        })}
                    </Slider>
                </C.CoverSlider>

                <C.Text>
                    Thank you for minting, here is the list of minted NFTs.<br />
                    
                </C.Text>

                <C.Mints>
                    {mintResult.map((mint: any, i: number) => {
                        if (mint !== null)
                            return (
                                <C.Mint key={i}>
                                    <C.MintName>{mint.json.name}</C.MintName>
                                    <C.View>
                                        <Button link="link" href={"https://solscan.io/tx/"+mint.signature} target="_blank" small="true" theme="gradient-border-transparent-bg-on-hover" bg={Hex2Rgba(color.secondaryFade, .5)} color="white">View on solscan</Button>
                                    </C.View>
                                </C.Mint>

                            )
                    })}

                </C.Mints>


            </C.Covers>

            <C.Buttons>


                <Button onClick={close} theme="gradient" bg={Hex2Rgba(color.secondaryFade, .5)} color="white">Okay</Button>

            </C.Buttons>


        </C.LaunchMintResult >
    )
}

export default LaunchMintResult