import { useSelector } from "react-redux"
import Collections from "components/parts/collections"
import * as P from "./style"

import BasePage from "components/pages/base"
import { IconCollections } from "utils/icons"

import { MainParts } from "utils/parts"

const Page = () => {

    const show = useSelector((state: any) => state.data.currentPage)

    return (
        <P.Page>
            <BasePage title="Communities" show={show === MainParts.Collections} icon={<IconCollections/>} glasmorph={true}>
                <Collections />
            </BasePage>
        </P.Page>
    )
}

export default Page