import { Button } from "components/ui/button/style"
import styled from "styled-components"
import { Col, Row } from "styles"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"

/*NFTS */
export const Nfts = styled.div`

`

export const NftsHeader = styled.div`
    display: flex;
    justify-content: space-between;
    background: ${color.bg2};
    align-items: center;
    padding:8px 20px;
    margin-bottom: 16px;
`

export const NftsTitle = styled.div`
    font-size: 18px;
`

export const NftsActions = styled.div`
    display: flex;
    align-items: center;
    
    & > button {
        &:not(:last-child) {
            margin-right: 10px;
        }

        align-items: center;
        display: flex;

        & img{
            width:15px;
            height:15px;
            margin-right:5px;
        }
    }
`

export const Collections = styled.div`
    display: flex;
    margin-bottom: 16px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;

    &::-webkit-scrollbar {
        width: 4px;
        height:4px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius:10px;
    }

    &:hover::-webkit-scrollbar-thumb {
        background: #888;
    }
`

export const Collection = styled(Button)<{ wide? : string}>`
    color:${color.white};
    display: flex;
    align-items: center;

    padding: 5px 10px;
    border-radius: 24px;

    cursor: pointer;

    &:not(:last-child) {
        margin-right: 10px;
    }

    ${props => props.wide === "true" && `
        padding: 5px 20px;
    `}
`

export const CollectionImage = styled.div`
    width:30px;
    height:30px;

    & > img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        
    }
    margin-right: 10px;
`

export const CollectionName = styled.div`
    & > img{
        width:10px;
        height:10px;
        margin-left: 5px;
    }
    display: flex;
    align-items: center;
    
    font-size: 14px;
`

export const NftsContent = styled(Row)`
    display: flex;
`

export const NftImage = styled.div`
    width:100%;
    overflow: hidden;
    max-height: 330px;
    aspect-ratio: 1/1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    transition: .1s;
    border-color:${Hex2Rgba(color.primary, .2)};


    & > img {
        width: 100%;
        height: 100%;
        
    }
`

export const Nft = styled(Col)`
    width:100%;
    margin-bottom: 30px;
    cursor: pointer;

    &:hover  {
        & > ${NftImage} {
            border:2px solid ${Hex2Rgba(color.primary, .2)};
        }
    }
`



export const NftName = styled.div`
    margin-top: 10px;
    text-align: center;
`

export const NftImageLoading = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex:1;
    background: ${color.bg2};
    height: 100%;
    font-size: 18px;
    color: ${Hex2Rgba(color.white, .5)};
`

export const Loading = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    font-size: 24px;
`

export const NoNfts = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const NoNftsImage = styled.div`
    max-width: 100%;
    width: 155px;

    & > img {
        width: 100%;
    }
`

export const NoNftsText = styled.div`
    font-size: 18px;
    color: ${Hex2Rgba(color.white, .5)};
`