import styled from 'styled-components'
import { deviceMax } from 'styles/media'
import { color } from 'styles/theme'

export const App = styled.div`
    width:100vw;
    min-height:100vh;
`

export const TestSign = styled.div`
    position:fixed;
    top:0;
    left:0;
    width:100vw;
    height:20px;
    background-color:${color.tertiary};
    color:${color.black};
    opacity:0.5;
    font-size:12px;
    font-weight:600;
    pointer-events:none;
    z-index:1000;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    
`

export const ShuffleAd = styled.a`
    position:absolute;
    right:16px;
    bottom:16px;

    & img{
        width:100px;
        transition:.1s ease-in-out;
        opacity:.5;
    }

    &:hover{
        & img{
            transform:scale(1.1);
            opacity:1;
        }
    }

    @media ${deviceMax.md} {
        bottom:72px;

        & img{
            width:72px;
        }
    }
`