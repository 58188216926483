import { faEdit, faMapMarkerAlt, faSignOut, faTrashCan, faXmark } from "@fortawesome/free-solid-svg-icons"
import { faCircleDot } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect } from "react"
import ReactTooltip from "react-tooltip"
import * as C from "./style"
import Button from "components/ui/button"
import { color } from "styles/theme"
import { copyObject, Hex2Rgba } from "utils/helpers"
import { displayEditProfile, displayWalletConnectPopup } from "utils/parts"
import { faDiscord, faTwitter } from "@fortawesome/free-brands-svg-icons"
import { useDispatch, useSelector } from "react-redux"
import { DisconnectWallet, findWallet } from "modules/wallet"
import { Network } from "models/enums/network"
import DropdownButton, { DropdownItem } from "components/ui/button/dropdown"
import { disconnectDiscord, disconnectTwitter, removeWallet, twitterRequestToken } from "services/api/auth"
import toast from "react-hot-toast"
import { removeWalletFromUsersWallets } from "services/slices/user"
import { removeLocation } from "services/api/profile"
import env from "env"
import { RefreshUser } from "services/managers/user"
import { SetEditProfilePopupSharesFirst, showCloudWalletPopup, showSettingsPopup } from "services/slices/popup"
import { useNavigate } from "react-router-dom"
import { logout } from "services/managers/user"
import { IconConnectWallet } from "utils/icons"
import { useWeb3Auth } from "hooks/useWeb3Auth"

const Settings = () => {

    const [solanaWallets, setSolanaWallets] = React.useState<any>([])
    const [aptosWallets, setAptosWallets] = React.useState<any>([])
    const user = useSelector((state: any) => state.user.user)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { web3auth } = useWeb3Auth()

    useEffect(() => {

        initializeWallets()

    }, [user])

    const initializeWallets = async () => {

        let wallets: any = copyObject(user.wallets)

        for (var i = 0; i < wallets.length; i++) {
            let wallet = findWallet(wallets[i].address)

            if (wallet) {
                let isConnected = await wallet.classInstance.isConnected()
                if (isConnected) {
                    wallets[i].provider = wallet.provider
                    wallets[i].connected = true
                }
            } else {
                wallets[i].connected = false
            }
        }

        setSolanaWallets(wallets.filter((w: any) => w.network === Network.Solana))
        setAptosWallets(wallets.filter((w: any) => w.network === Network.Aptos))
    }

    useEffect(() => {
        ReactTooltip.rebuild()
        ReactTooltip.hide()
    }, [solanaWallets, aptosWallets])

    const disconnectWallet = async (address: any) => {
        DisconnectWallet(address).then(() => {
            initializeWallets()
        })
    }

    const deleteWallet = async (address: any) => {

        removeWallet(address).then(() => {
            toast.success("Wallet deleted successfully")

            dispatch(removeWalletFromUsersWallets(address))

            DisconnectWallet(address).then(() => { })

        }).catch((err: any) => {
            toast.error("Error deleting wallet")
        })
    }

    const deleteLocation = () => {
        removeLocation().then(() => {
            toast.success("Removed from map")
        }).catch((err: any) => {
            toast.error("Error removing from map")
        })
    }

    const oauthDiscordRedirect = () => {
        if (!user.discord)
            window.location.href = `https://discord.com/api/oauth2/authorize?client_id=${env.DISCORD.client_id}&redirect_uri=${encodeURIComponent(env.API.WEB)}app&response_type=code&scope=guilds.members.read%20identify%20guilds&state=${user.nonce}`
    }

    const discordDisconnect = () => {
        disconnectDiscord().then(() => {
            toast.success("Disconnected from Discord")
            RefreshUser()
        }).catch((err: any) => {
            toast.error("Error disconnecting from Discord")
        })
    }

    /* const oauthTwitterRedirect = () => {
         if (!user.twitter) {
             twitterRequestToken().then((res: any) => {
                 window.location.href = `https://api.twitter.com/oauth/authenticate?oauth_token=${res.data.token}`
             }).catch((err: any) => {
                 toast.error("Error connecting to Twitter")
             })
         }
     }
 
     const twitterDisconnect = () => {
         disconnectTwitter().then(() => {
             toast.success("Disconnected from Twitter")
             RefreshUser()
         }).catch((err: any) => {
             toast.error("Error disconnecting from Twitter")
         })
     }*/

    const setLocation = () => {
        dispatch(showSettingsPopup(false))
        navigate("/app/set-location")
    }

    const opencloudwallet = () => {
        dispatch(showSettingsPopup(false))
        dispatch(showCloudWalletPopup(true))
    }

    const onlogout = async () => {
        try {
            await web3auth!.logout()
        } catch (err) {
            localStorage.removeItem("openlogin_store")
        }
        logout()
    }

    const ismobile = useSelector((state: any) => state.data.isMobile)

    const toEditProfile = (shares?:boolean) => {
        if (ismobile){
            if (shares)
                dispatch(SetEditProfilePopupSharesFirst(true))
            navigate("/app/myprofile")
        }else{
            displayEditProfile(true, shares)
        }
    }

    return (
        <C.Settings>
            <C.Socials>
                <C.Title>
                    Profile
                </C.Title>
                <C.SocialsContent>
                    <C.Social hc="#5865F2" onClick={oauthDiscordRedirect}>
                        <C.SocialIcon >
                            <FontAwesomeIcon icon={faDiscord} />
                        </C.SocialIcon>
                        {!user.discord && (
                            <C.SocialText>Connect Discord</C.SocialText>
                        )}
                        {user.discord && (
                            <C.SocialText>{user.discord}</C.SocialText>
                        )}
                        {user.discord && (
                            <C.SocialAction data-tip="Disconnect" onClick={discordDisconnect}>
                                <FontAwesomeIcon icon={faSignOut} />
                            </C.SocialAction>
                        )}
                    </C.Social>
                    <C.Social hc="#1DA1F2" >
                        <C.SocialIcon >
                            <FontAwesomeIcon icon={faTwitter} />
                        </C.SocialIcon>
                        <C.SocialTextTwitter>{user.twitter}</C.SocialTextTwitter>
                    </C.Social>
                </C.SocialsContent>

            </C.Socials>

            <C.ProfileAndShares>
                <C.Title>
                    Profile & Key Account
                </C.Title>
                <C.ProfileAndSharesButtons>
                    <Button theme="gradient-border-transparent-bg-on-hover" bg={Hex2Rgba(color.secondaryFade, .5)} color="white" onClick={() => toEditProfile()} >View Profile</Button>
                    <Button theme="gradient" block="true" bg={Hex2Rgba(color.white, .05)} onClick={() => toEditProfile(true)} color="white">Key Account</Button>
                </C.ProfileAndSharesButtons>
            </C.ProfileAndShares>



            <C.Title>
                Linked Wallets
            </C.Title>
            <C.WalletsSection>
                {solanaWallets && solanaWallets.length > 0 && (
                    <>
                        <C.NetworkTitle>
                            <img src="/images/chains/solana-icon.png" alt="solana icon" />
                            Solana
                        </C.NetworkTitle>
                        <C.Wallets>
                            {solanaWallets.map((w: any, index: any) => (
                                <C.Wallet key={index}>
                                    <C.WalletHeader>
                                        <C.WalletName>
                                            {w.provider && (
                                                <img src={`/images/wallets/${w.provider}.svg`} data-tip={"Connected with " + w.provider} alt="wallet icon" />
                                            )}
                                            {w.name}
                                        </C.WalletName>
                                        <C.WalletActions>
                                            {w.connected ? (
                                                <C.WalletAction active="true" data-tip="Connected">
                                                    <FontAwesomeIcon icon={faCircleDot} />
                                                </C.WalletAction>
                                            ) : (
                                                <C.WalletAction active="false" data-tip="Not Connected">
                                                    <FontAwesomeIcon icon={faCircleDot} />
                                                </C.WalletAction>
                                            )}
                                            <DropdownButton text="Actions" buttonStyle={{ theme: "gradient-border-transparent-bg-on-hover", bg: Hex2Rgba(color.black, 1), color: "white" }}>
                                                {w.connected && (
                                                    <DropdownItem onClick={() => disconnectWallet(w.address)}>
                                                        <FontAwesomeIcon icon={faSignOut} />Disconnect
                                                    </DropdownItem>
                                                )}
                                                <DropdownItem onClick={() => deleteWallet(w.address)}>
                                                    <FontAwesomeIcon icon={faTrashCan} /> Delete
                                                </DropdownItem>
                                            </DropdownButton>
                                        </C.WalletActions>
                                    </C.WalletHeader>

                                    <C.WalletAddress>{w.address}</C.WalletAddress>
                                </C.Wallet>
                            ))}

                        </C.Wallets>
                    </>
                )}
                {/*aptosWallets && aptosWallets.length > 0 && (
                    <>
                        <C.NetworkTitle>
                            <img src="/images/chains/aptos-icon.png" alt="aptos icon" />
                            Aptos
                        </C.NetworkTitle>
                        <C.Wallets>

                            {aptosWallets.map((w: any, index: any) => (
                                <C.Wallet key={index}>
                                    <C.WalletHeader>
                                        <C.WalletName>
                                            {w.provider && (
                                                <img src={`/images/wallets/${w.provider}.svg`} data-tip={"Connected with " + w.provider} alt="wallet icon" />
                                            )}
                                            {w.name}
                                        </C.WalletName>
                                        <C.WalletActions>
                                            {w.connected ? (
                                                <C.WalletAction active="true" data-tip="Connected" >
                                                    <FontAwesomeIcon icon={faCircleDot} />
                                                </C.WalletAction>
                                            ) : (
                                                <C.WalletAction active="false" data-tip="Not Connected">
                                                    <FontAwesomeIcon icon={faCircleDot} />
                                                </C.WalletAction>
                                            )}

                                            <DropdownButton text="Actions" buttonStyle={{ theme: "gradient-border-transparent-bg-on-hover", bg: Hex2Rgba(color.black, 1), color: "white" }}>
                                                {w.connected && (
                                                    <DropdownItem onClick={() => disconnectWallet(w.address)}>
                                                        <FontAwesomeIcon icon={faSignOut} />Disconnect
                                                    </DropdownItem>
                                                )}
                                                <DropdownItem onClick={() => deleteWallet(w.address)}>
                                                    <FontAwesomeIcon icon={faTrashCan} /> Delete
                                                </DropdownItem>
                                            </DropdownButton>

                                        </C.WalletActions>
                                    </C.WalletHeader>

                                    <C.WalletAddress>{w.address}</C.WalletAddress>
                                </C.Wallet>
                            ))}

                        </C.Wallets>
                    </>
                                                )*/}

                <Button theme="gradient" block="true" bg={Hex2Rgba(color.secondaryFade, .5)} onClick={() => displayWalletConnectPopup(true)} color="white">Link new Wallet</Button>

            </C.WalletsSection>


            <C.AccountSection>

                <C.Title>
                    Account
                </C.Title>
                <C.AccountActions>
                    {user.shareAddress !== null && (
                        <>
                            <Button onClick={setLocation} theme="gradient-border-transparent-bg-on-hover" bg={Hex2Rgba(color.secondaryFade, .5)} color="white"><FontAwesomeIcon icon={faMapMarkerAlt} /> Change Location</Button>
                            <Button onClick={deleteLocation} theme="gradient-border-transparent-bg-on-hover" bg={Hex2Rgba(color.secondaryFade, .5)} color="white"><FontAwesomeIcon icon={faXmark} /> Remove Me From Map</Button>
                        </>
                    )}
                    <Button onClick={onlogout} theme="gradient-border-transparent-bg-on-hover" bg={Hex2Rgba(color.secondaryFade, .5)} color="white"><FontAwesomeIcon icon={faSignOut} /> Logout</Button>
                    <Button onClick={opencloudwallet} theme="gradient-border-transparent-bg-on-hover" bg={Hex2Rgba(color.secondaryFade, .5)} color="white"><IconConnectWallet style={{ height: "18px" }} /> Cloud Wallet</Button>
                </C.AccountActions>
            </C.AccountSection>

        </C.Settings>
    )
}

export default Settings