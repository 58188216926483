export const errors = {
    "0x0": "User denied transaction signature",
    "0x1": "Not enough SOL to pay for transaction",
    "0x2": "Transaction too large",
    "0x1770": "Insufficient funds",
    "0x1771": "Invalid amount",
    "0x1772": "Invalid invite code",
    "0x1773": "Slippage hit",
}

export const getShareError = (code: any) => {
    if (typeof code === "string") {

        if (typeof (errors as any)[code] === "undefined")
            return false;

        return (errors as any)[code]
    } else {
        //convert to hex
        let hex = code.toString(16)
        return (errors as any)["0x"+hex]
    }
}

export const parseCustomProgramError = (error: any) => {
    let errorString = error.toString()
    let errorCode = errorString.substring(errorString.indexOf("0x"), errorString.indexOf("0x") + 6)

    if (errorCode) {
        return errorCode
    } else {
        return false
    }
}