export enum Network {
    Solana = 'solana',
    Aptos = 'aptos'
}

export interface Chain{
    name: string
    network: Network,
    currency?: string,
}

export const Chains : any = {
    solana:{
        name: 'solana',
        network: Network.Solana,
        currency: 'SOL',
    } as Chain,
    aptos : {
        name: 'aptos',
        network: Network.Aptos,
        currency: 'APT',
    } as Chain,
    "solana-devnet":{
        name: 'solana d',
        network: Network.Solana,
        currency: 'SOL',
    } as Chain,
}