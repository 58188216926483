import styled from "styled-components"
import { deviceMax, deviceMin } from "styles/media"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"

import { Page as _Page, Dialog, Overlay, DialogHeader, DialogBody } from "components/pages/base/style"

export const Page = styled.div`
    & ${Overlay} {
        display: none;
    }

    & ${_Page} {
        pointer-events: none;
        background: transparent;
    }

    & ${Dialog} {
        pointer-events: all;
        width: 440px;
        max-width: calc(100% - 30px);
        margin:40px 40px 40px auto;
        height:fit-content;
    }

    & ${DialogHeader} {
        display: none;
    }

    & ${DialogBody} {
        
        overflow: unset;
        overflow-y: unset;
        overflow-x: unset;
    }
`