import React from "react"
import * as P from "./style"

import { useSelector } from "react-redux"
import BasePopup from "components/popups/base"
import { IconCollections, IconConnectWallet } from "utils/icons"

import { showCloudWalletPopup } from "services/slices/popup"
import EditProfile from "components/parts/editProfile"
import { displayCloudWallet } from "utils/parts"
import CloudWalletPart from "components/parts/cloudWallet"


const CloudWalletPopup = () => {

    const show = useSelector((state: any) => state.popup.CloudWalletPopup)
    
    return (
        <P.Popup>
            <BasePopup title="Cloud Wallet" show={show} hide={() => displayCloudWallet(false)} icon={<IconConnectWallet />} glasmorph={true}>
                <CloudWalletPart/>
            </BasePopup>
        </P.Popup>
    )
}

export default CloudWalletPopup