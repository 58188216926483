import Button from "components/ui/button"
import Input from "components/ui/input"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { introduced } from "services/api/profile"
import { showEditProfilePopup, showFcfsPopup, showIntroductionPopup } from "services/slices/popup"
import { setUserIntroduced } from "services/slices/user"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import * as C from "./style"
import * as yup from "yup"
import { useYupValidationResolver } from "hooks/useYupValidationResolver"
import { Controller, useForm } from "react-hook-form"
import { JoinWhitelistFcfs } from "services/api/whitelist"
import { toast } from "react-hot-toast"
import { setEligible, setEntry } from "services/slices/whitelist"
import { ErrorCodes } from "utils/errorcodes"

const FcfsRedeem = () => {

    const schema = useMemo(
        () =>
            yup.object({
                code: yup.string().trim().required()
            }),
        []
    )

    const resolver = useYupValidationResolver(schema)

    const { handleSubmit, register, control, formState: { errors } } = useForm({ resolver })

    const item = useSelector((state: any) => state.whitelist.currentWhitelist)
    const dispatch = useDispatch()

    const onSubmit = async (data: any) => {

        if (!item) {
            toast.error("An error occured. Please try again later.")
            return
        }

        JoinWhitelistFcfs(item.id, data.code).then((res) => {
            if (res.status === 201) {
                toast.success("Successfully redeemed code")
                dispatch(setEntry(res.data.entry))
                dispatch(setEligible(true))
                dispatch(showFcfsPopup(false))
            } else {
                toast.error(ErrorCodes[res.data.code])
            }
        }).catch((err) => {
            if (err.response.status !== 429) {
                if (err.response.data.code)
                    toast.error(ErrorCodes[err.response.data.code])
                else
                    toast.error("Error redeeming code")
            }
        })
    }

    return (
        <C.FcfsRedeem onSubmit={handleSubmit((data) => onSubmit(data))}>
            <Controller name="code" control={control}
                render={({ field }) => <Input onBlur={field.onBlur} onChange={field.onChange} checked={field.value} xref={field.ref} type="text" placeholder="Enter your FCFS code" />}
            />
            {errors.code && (<p>{errors.code.message as string}</p>)}
            <Button type="submit" theme="gradient" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)}>Redeem</Button>
        </C.FcfsRedeem>
    )
}

export default FcfsRedeem