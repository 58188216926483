import { Badge } from "components/ui/badge/style"
import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import Bg from "./treasurehunt.png"

export const TreasureMap = styled.div`
    transition:height 0.5s;
    width:100%;

    display:flex;
    flex-direction:column;
    justify-content:space-between;

    & *{
        /*font:14px/1.3 Poppins, sans-serif;*/
    }

    padding-bottom:40px;
    overflow:hidden;
    border-radius:8px;

    backdrop-filter: blur(20px);
    background-color: ${Hex2Rgba("#171613", .86)};
    padding-top: 40px;

    background-image: url(${Bg});
    background-size: cover;
    background-position: center center;
`

export const TreasureMapTitle = styled.div`
    text-align:center;
    background: linear-gradient(90deg, ${color.gradient3.start}, ${color.gradient3.end});
    font-weight:600;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 24px;
`

export const TreasureMapIcon = styled.div`
    color:${color.tertiary};
    text-align:center;
    & svg{
        width:72px;
    }
`

export const TreasureMapText = styled.div`
    text-align:center;
    margin:16px 0;
`

export const TreasureMapLink = styled.div`
    padding-left:20px;
    padding-right:20px;
    & a{
        font-size:16px;
        font-weight:600;
        display:flex;
        align-items:center;
        justify-content:center;
        & svg{
            width:21px;
            height:21px;
            margin-right:4px;
        }
    }
    margin-top:8px;
`

