import { Button } from "components/ui/button/style"
import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"

export const SharesTab = styled.div`
    padding:0 25px;
    min-height:318px;
    & p {
        font-size: 14px;
        color: ${color.gray};
        margin:0;
        padding-top:5px;
    }

`

export const Title = styled.div`
    font-size:15px;
    font-weight:600;
    margin-top:32px;
`

export const Description = styled.div`
    color:${color.whiteShade};
    font-size:13px;
    margin-top:8px;
`

export const Wallet = styled.div<{iserror?:string, isdisabled?:string}>`
    margin:16px 0;
    background:${Hex2Rgba(color.secondary, 0.16)};
    border-radius:8px;
    padding:16px;
    

    ${props => props.iserror === 'true' && `
        border:1px solid ${color.error};
    `}

    ${props => props.isdisabled === 'true' && `
        opacity:0.4;
        user-select:none;
        pointer-events:none;
    `}
`

export const WalletStuff = styled.div`
    display:flex;

    & > div{
        flex:1;
    }

    & > div:not(:last-child){
        margin-right:16px;
    }
`

export const WalletLeft = styled.div`
    width:100%;
    padding-right:16px;
`

export const WalletTitle = styled.div`
    font-size:14px;
    color:${color.white};

    & span{
        color:${color.white};
    }
`

export const WalletAddress = styled.div`
    font-size:13px;
    word-break:break-all;
    color:${Hex2Rgba(color.white, 0.64)};
    display:flex;
    & svg{
        color:${color.error}
    }
`

export const WalletDeposit = styled.div`
    color:${color.primary};
    cursor:pointer;
    &:hover{
        text-decoration:underline;
    }
    margin-left:8px;
`

export const Code = styled.div<{iserror?:string, isdisabled?:string}>`
    background:${Hex2Rgba(color.secondary, 0.16)};
    padding:16px;
    border-radius:8px;
    margin-bottom:24px;

    & input{
        padding:12px;

        ${props => props.iserror === 'true' && `
            border:1px solid ${color.error};
        `}
    }

    ${props => props.isdisabled === 'true' && `
        opacity:0.4;
        user-select:none;
        pointer-events:none;
    `}
`

export const CodeTitle = styled.div`
    font-size:14px;
    margin-bottom:8px;
`

export const StatsContainer = styled.div`
    & ${Title}{
        margin-bottom:8px;
    }
    & ${Button}{
        margin-top:16px;
    }
`

export const Stats = styled.div`
    background:${Hex2Rgba(color.secondary, 0.16)};
    border-radius:8px;
    padding:16px;
`

export const StatsItem = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;

    &:not(:last-child){
        margin-bottom:16px;
    }
`

export const StatsItemTitle = styled.div`
    font-size:13px;
    line-height:125%;
`

export const StatsItemValue = styled.div`
    font-size:13px;
    color:${color.whiteShade};
    line-height:125%;
`

export const InviteCodeView = styled.div`
    cursor:pointer;
    text-align:center;
    color:${color.primary};
    margin-top:16px;
    font-size:13px;
    &:hover{
        color:${Hex2Rgba(color.primary, 0.8)};
    }
`

export const InviteCodes = styled.div`
    margin-top:8px;
    background:${Hex2Rgba(color.secondary, 0.16)};
    border-radius:8px;
    padding:16px;
`

export const InviteCode = styled.div<{used?:string}>`
    &:not(:last-child){
        margin-bottom:8px;
    }
    font-size:13px;
    color:${color.white};

    ${props => props.used === 'true' && `
        color:${color.whiteShade};
        text-decoration:line-through;
    `}
`

export const InviteCodeTitle = styled.div`
    font-size:15px;
    font-weight:600;
    margin-top:16px;
`

export const Finalization = styled.div`
    text-align:center;
    margin-top:16px;
    font-size:14px;
    color:${color.white};
    background:${Hex2Rgba(color.secondary, 0.16)};
    border-radius:8px;
    padding:16px;

    & svg{
        font-size:20px;
        color:${color.primary};
        margin-left:8px;
    }
`

export const FinalizationText = styled.div`

`

export const PageLoading = styled.div`
    text-align:center;
    font-size:20px;
    color:${color.white};
    height:300px;
    display:flex;
    align-items:center;
    justify-content:center;
`

export const TxError = styled.div`
    display:flex;
    margin-bottom:16px;
    font-size:13px;
`
export const TxErrorText = styled.div`
    
    color:${color.whiteShade};
`
export const TxErrorLink = styled.span`
    color:${color.primary};
    cursor:pointer;
    &:hover{
        text-decoration:underline;
    }
`