import Button from "components/ui/button"
import Input from "components/ui/input"
import React, { useEffect, useMemo, useState } from "react"
import { color } from "styles/theme"
import { Hex2Rgba, customSelectStyles, getAvatar } from "utils/helpers"
import * as C from "./style"
import Select from 'react-select'
import Badge, { Badges } from "components/ui/badge"

import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { useYupValidationResolver } from 'hooks/useYupValidationResolver'
import toast from "react-hot-toast"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import DropdownButton, { DropdownItem } from "components/ui/button/dropdown"
import { updateProfile, updateAvatar, updateCover, deleteAvatar, deleteCover } from "services/api/profile"
import { useDispatch, useSelector } from "react-redux"
import { setAvatar, setCover, setUser } from "services/slices/user"
import env from "env"
import SharesTab from "../sharesTab"
import { SetEditProfilePopupSharesFirst } from "services/slices/popup"

var loading = false

const EditProfile = (props: any) => {

    const [currentTab, setCurrentTab] = useState(0)

    const [selectedBadges, setSelectedBadges] = useState<any>([])

    const user = useSelector((state: any) => state.user.user)
    const EditProfilePopupSharesFirst = useSelector((state: any) => state.popup.EditProfilePopupSharesFirst)
    const dispatch = useDispatch()

    const schema = useMemo(
        () =>
            yup.object({
                //Username: yup.string().trim().max(30).required().default(user.username),
                Status: yup.string().trim().max(66).default(user.status),
                Bio: yup.string().trim().max(144).default(user.bio),
            }),
        []
    )

    const resolver = useYupValidationResolver(schema)

    const { handleSubmit, control, formState: { errors } } = useForm({ resolver })

    useEffect(() => {
        if (EditProfilePopupSharesFirst) {
            setCurrentTab(1)
            dispatch(SetEditProfilePopupSharesFirst(false))
        }
    }, [])

    useEffect(() => {
        setSelectedBadges(user.badges)
    }, [user])

    const onSubmit = (data: any) => {

        if (loading) return;

        loading = true

        data = Object.keys(data).reduce((acc: any, key: any) => {
            acc[key.toLowerCase()] = data[key];
            return acc;
        }, {})

        //change data.bio to data.description
        data.description = data.bio
        delete data.bio

        data.badges = selectedBadges

        var loadingToast = toast.loading("Updating profile...")

        updateProfile(data).then((res) => {
            toast.remove(loadingToast)
            toast.success("Profile updated!")
            loading = false
            dispatch(setUser(res.data.user))
            if (props.hide)
                props.hide()

        }).catch((err) => {
            toast.remove(loadingToast)
            toast.error("Failed to update profile!")
            loading = false
        })

        //console.log(data)
    }

    const _updateAvatar = (e: any) => {

        if (loading) return;

        var file = e.target.files[0]

        if (!file) return;

        if (file.size > 3000000) {
            toast.error("File size is too large! Max 3MB")
            return;
        }

        if (!file.type.startsWith("image/")) {
            toast.error("File is not an image!")
            return;
        }

        loading = true

        var loadingToast = toast.loading("Updating avatar...")
        var formData = new FormData()
        formData.append("avatar", file)

        updateAvatar(formData).then((res) => {
            toast.remove(loadingToast)
            toast.success("Avatar updated!")
            loading = false
            dispatch(setAvatar(res.data.avatar))
        }).catch((err) => {
            toast.remove(loadingToast)
            toast.error("Failed to update avatar!")
            loading = false
        })
    }

    const _updateCover = (e: any) => {

        if (loading) return;

        var file = e.target.files[0]

        if (!file) return;

        if (file.size > 3000000) {
            toast.error("File size is too large! Max 3MB")
            return;
        }

        if (!file.type.startsWith("image/")) {
            toast.error("File is not an image!")
            return;
        }

        loading = true

        var loadingToast = toast.loading("Updating cover...")
        var formData = new FormData()
        formData.append("cover", file)

        updateCover(formData).then((res) => {
            toast.remove(loadingToast)
            toast.success("Cover updated!")
            loading = false
            dispatch(setCover(res.data.cover))
        }).catch((err) => {
            toast.remove(loadingToast)
            toast.error("Failed to update cover!")
            loading = false
        })
    }

    const _removeAvatar = () => {

        if (loading) return;

        loading = true

        var loadingToast = toast.loading("Removing avatar...")

        deleteAvatar().then((res) => {
            toast.remove(loadingToast)
            toast.success("Avatar removed!")
            loading = false
            dispatch(setAvatar(""))
        }).catch((err) => {
            toast.remove(loadingToast)
            toast.error("Failed to remove avatar!")
            loading = false
        })
    }

    const _removeCover = () => {

        if (loading) return;

        loading = true

        var loadingToast = toast.loading("Removing cover...")

        deleteCover().then((res) => {
            toast.remove(loadingToast)
            toast.success("Cover removed!")
            loading = false
            dispatch(setCover(""))
        }).catch((err) => {
            toast.remove(loadingToast)
            toast.error("Failed to remove cover!")
            loading = false
        })
    }

    const toggleBadge = (badge: any) => {
        if (selectedBadges.includes(badge)) {
            setSelectedBadges(selectedBadges.filter((x: any) => x !== badge))
        } else {
            if (selectedBadges.length >= 4) return;
            setSelectedBadges([...selectedBadges, badge])
        }
    }

    const isMobile = useSelector((state: any) => state.data.isMobile)

    return (
        <C.EditProfile>
            <C.Cover>
                <C.CoverBg bg={user.cover ? env.API.CDN + "covers/" + user.cover : ""} htmlFor="_edit_profile_cover_file_" onClick={(e: any) => { if (currentTab === 1) e.preventDefault() }} tab={currentTab}>
                    <input type="file" id="_edit_profile_cover_file_" onChange={_updateCover} />
                </C.CoverBg>
                <C.Avatar tab={currentTab}>
                    <C.AvatarImg htmlFor="_edit_profile_avatar_file_" onClick={(e: any) => { if (currentTab === 1) e.preventDefault() }}>
                        <img src={getAvatar(user.avatar)} alt="Profile Image" />
                    </C.AvatarImg>
                    <input type="file" id="_edit_profile_avatar_file_" onChange={_updateAvatar} />
                </C.Avatar>
                {(((user.avatar && !user.avatar.startsWith("http")) || user.cover) && currentTab === 0) && (
                    <DropdownButton text="Actions" buttonStyle={{ theme: "gradient-border-transparent-bg-on-hover", bg: Hex2Rgba(color.black, 1), color: "white" }}>
                        {(user.avatar && !user.avatar.startsWith("http")) && (
                            <DropdownItem onClick={_removeAvatar}>
                                Remove Avatar
                            </DropdownItem>
                        )}
                        {user.cover && (
                            <DropdownItem onClick={_removeCover}>
                                Remove Cover
                            </DropdownItem>
                        )}
                    </DropdownButton>
                )}
            </C.Cover>

            <C.Username>
                {user.username}
            </C.Username>

            <C.TabsContainer>
                <C.Tabs>
                    <C.TabItem onClick={() => setCurrentTab(0)} isactive={currentTab === 0 ? "true" : "false"}>
                        Profile
                    </C.TabItem>
                    <C.TabItem onClick={() => setCurrentTab(1)} isactive={currentTab === 1 ? "true" : "false"}>
                        Keys
                    </C.TabItem>
                </C.Tabs>
                {(user.shareAddress !== null && currentTab === 0) && (
                    <C.ProfileLink to={"/app/share/" + user.id} onClick={() => { if (!isMobile){props.hide()}}}>
                        View Share Profile
                    </C.ProfileLink>
                )}
            </C.TabsContainer>
            {currentTab === 0 && (
                <>
                    <C.Form onSubmit={handleSubmit((data) => onSubmit(data))}>

                        <C.FormGroup>
                            <label>Status</label>
                            <Controller name="Status" control={control}
                                render={({ field }) => <Input onBlur={field.onBlur} onChange={field.onChange} checked={field.value} xref={field.ref} type="text" placeholder="Type your Status" defaultValue={user.status} />}
                            />
                            {errors.Status && (<p>{errors.Status.message as string}</p>)}
                        </C.FormGroup>

                        <C.FormGroup>
                            <label>Bio</label>
                            <Controller name="Bio" control={control}
                                render={({ field }) => <Input onBlur={field.onBlur} onChange={field.onChange} checked={field.value} xref={field.ref} rows="3" type="textarea" placeholder="Type your Bio" defaultValue={user.description} ></Input>}
                            />
                            {errors.Bio && (<p>{errors.Bio.message as string}</p>)}
                        </C.FormGroup>

                        <C.FormGroup>
                            <label>Badges <small>(max 4)</small></label>
                            <C.BadgesList>
                                {Badges.map((badge, index) => (
                                    <Badge className={selectedBadges.includes(badge.value) ? "a" : ""} key={index} type={badge.value} onClick={() => toggleBadge(badge.value)} />
                                ))}
                            </C.BadgesList>
                        </C.FormGroup>

                        <C.Actions>
                            <Button type="submit" theme="gradient" bg={Hex2Rgba(color.white, .05)} color={color.white}>Save</Button>
                        </C.Actions>
                    </C.Form>
                </>
            )}

            {currentTab === 1 && (
                <SharesTab />
            )}
        </C.EditProfile>

    )
}

export default EditProfile