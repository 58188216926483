import { useSelector } from "react-redux"
import * as P from "./style"

import BaseMobilePage from "components/mobile/base"
import { IconCollections, IconWhitelist } from "utils/icons"

import { MainParts } from "utils/parts"
import Launches from "components/parts/launches"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faRocket } from "@fortawesome/free-solid-svg-icons"

const MobilePage = () => {

    const show = useSelector((state: any) => state.data.currentPage)

    
    return (
        <P.MobilePage>
            <BaseMobilePage title="Launches" show={show === MainParts.Launches} icon={<FontAwesomeIcon icon={faRocket} />}>
                <Launches />
            </BaseMobilePage>
        </P.MobilePage>
    )
}

export default MobilePage