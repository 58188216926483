import React from "react"
import * as P from "./style"

import { useSelector } from "react-redux"
import BasePopup from "components/popups/base"
import { IconCollections } from "utils/icons"

import { showSettingsPopup } from "services/slices/popup"
import Settings from "components/parts/settings"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCog } from "@fortawesome/free-solid-svg-icons"


const SettingsPopup = () => {

    const show = useSelector((state: any) => state.popup.SettingsPopup)
    const isMobile = useSelector((state: any) => state.data.isMobile)

    if (isMobile)
        return <></>
    
    return (
        <P.Popup>
            <BasePopup title="Settings" show={show} hide={() => showSettingsPopup(false)} icon={<FontAwesomeIcon icon={faCog} />} glasmorph={true}>
                <Settings/>
            </BasePopup>
        </P.Popup>
    )
}

export default SettingsPopup