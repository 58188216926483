import { Network } from "models/enums/network"
import { WalletProvider } from "models/interfaces/walletProvider"
import { SolanaWallets } from "modules/solana/wallet"
import { setWalletsChanged } from "services/slices/data"
import { setGlobalSelectedWallet } from "services/slices/user"
import { store } from "services/store"
import { getLocalData, removeLocalData, setLocalData } from "utils/localData"
import { AptosWallets } from "./aptos/wallet"

interface wallets {
    [key: string]: WalletProvider
}

interface instance {
    [key: string]: wallets
}

export interface WalletInstace {
    provider: string,
    network: Network,
    instance: WalletProvider,
    address: string[], //[0] address, [1] publicKey (only for aptos)
    classInstance: any
}

const Instances: instance = {
    solana: {},
    aptos: {}
}

// connected wallets
export var Wallets: WalletInstace[] = []

export const findWallet = (address: string) => {
    return Wallets.find(w => w.address[0] === address)
}

export const updateWallets = (wallets: WalletInstace[]) => {
    Wallets = wallets

    let globalSelectedWallet = store.getState().user.selectedWallet
    if (globalSelectedWallet) {
        let wallet = findWallet(globalSelectedWallet)
        if (!wallet) {
            store.dispatch(setGlobalSelectedWallet(""))
            removeLocalData('selectedSolanaWallet')
        }
    }

}


// auto connect to all wallets that are connected before
export const AutoConnectAllWallets = async () => {
    try {
        const allWallets: any = { ...SolanaWallets, ...AptosWallets }

        for (const [key, instance] of <any>Object.entries(allWallets)) {
            let connected = await instance.isConnected()

            let wallet = new instance()

            let tryConnect = false
            if (!connected && typeof wallet.tryConnect === "function") {
                tryConnect = await wallet.tryConnect().catch(() => false)
            }

            if (connected || tryConnect) {

                const address = await wallet.address()

                if (typeof findWallet(address[0]) === 'undefined') {
                    Wallets.push({
                        provider: instance.provider.name,
                        network: instance.provider.network,
                        instance: wallet,
                        address,
                        classInstance: instance
                    })
                }
            } else {
                wallet = null
            }
        }
        
        if (Wallets.length > 0) {
            // select the last selected wallet for solana
            let selectedSolanaWallet = getLocalData('selectedSolanaWallet')
            if (selectedSolanaWallet) {
                let wallet = Wallets.find(w => w.network === Network.Solana && w.address[0] === selectedSolanaWallet)
                if (wallet) {
                    store.dispatch(setGlobalSelectedWallet(wallet.address[0]))
                }
            }else{
                // if no selected wallet, select the first one for solana
                
                for (const wallet of Wallets) {
                    if (wallet.network === Network.Solana) {
                        let connected = await wallet.classInstance.isConnected()
                        if (connected) {
                            store.dispatch(setGlobalSelectedWallet(wallet.address[0]))
                            setLocalData('selectedSolanaWallet', wallet.address[0])
                            break;
                        }
                    }
                }

                /*let connected = await Wallets.filter(async (w) => w.network === Network.Solana && await w.classInstance.isConnected().length === 1)
                if (connected) {
                    let wallet = await Wallets.find( async (w) => w.network === Network.Solana && await w.classInstance.isConnected())
                    if (wallet) {
                        //console.log(wallet)
                        store.dispatch(setGlobalSelectedWallet(wallet.address[0]))
                        setLocalData('selectedSolanaWallet', wallet.address[0])
                    }
                }*/
            }
        }

    } catch (err) {
        console.log(err)
    }

    return Promise.resolve(true)
}

// connect to a wallet
export const ConnectWallet = (provider: string, network: Network) => {

    return new Promise(async (resolve, reject) => {

        if (network === Network.Solana && !SolanaWallets[provider].isInstalled())
            return reject(false)

        if (network === Network.Aptos && !AptosWallets[provider].isInstalled())
            return reject(false)

        let classInstance: any

        if (network === Network.Solana)
            classInstance = SolanaWallets[provider]
        else if (network === Network.Aptos)
            classInstance = AptosWallets[provider]

        if (typeof Instances[network][provider] === 'undefined') {
            Instances[network][provider] = new classInstance()
        }

        let wallet = Instances[network][provider]

        let isConnected = await classInstance.isConnected()

        if (!isConnected) {
            try {
                await wallet.connect()
                let address = await wallet.address()

                if (typeof findWallet(address[0]) !== 'undefined') { // if address is already connected to another provider
                    try {
                        wallet.disconnect()
                    } catch (err) {
                        //console.log(err)
                    }
                    return reject(false)
                }

                // push wallet to connected wallets
                Wallets.push({
                    provider,
                    instance: wallet,
                    network,
                    address,
                    classInstance
                })

                const _address = address[0]
                wallet.setOnDisconnect(() => {
                    //remove wallet from Wallets
                    let index = Wallets.findIndex((w) => w.address[0] === _address)
                    if (index !== -1)
                        Wallets.splice(index, 1)
                })

                return resolve(address)
            }
            catch (err) {
                //console.log(err)
                return reject(false)
            }
        }

        try {
            let address = await wallet.address()

            // push wallet to connected wallets
            Wallets.push({
                provider,
                instance: wallet,
                network,
                address,
                classInstance
            })

            return resolve(address)
        } catch (err) {
            //console.log(err)
            return reject(false)
        }
    })
}

// request signature from a wallet
export const RequestSignature = (provider: string, network: Network, message: string, nonce: string, address: string) => {
    return new Promise(async (resolve, reject) => {
        if (typeof Instances[network][provider] === 'undefined')
            return reject(false)

        let wallet = Instances[network][provider]

        let isConnected = false
        if (network === Network.Solana)
            isConnected = await SolanaWallets[provider].isConnected()
        else if (network === Network.Aptos)
            isConnected = await AptosWallets[provider].isConnected()

        if (!isConnected)
            return reject(false)

        try {
            let signature = await wallet.signMessage(message, nonce)
            return resolve(signature)
        }
        catch (err) {
            DisconnectWallet(address)
            return reject(false)
        }
    })
}

export const SignMessage = "Hi there from hub3.ee! Sign this message to prove you have access to this wallet and we'll log you in. This wont cost you anything.\n\n"

export const DisconnectWallet = async (address: string) => {
    let wallet = findWallet(address)

    if (!wallet)
        return false

    try {
        await wallet.instance.disconnect()
        store.dispatch(setWalletsChanged(true))
    } catch (err) { }

    Wallets = Wallets.filter(w => w.address[0] !== address)
}

export const RemoveWallet = async (address: string) => {
    let wallet = findWallet(address)

    if (!wallet)
        return Promise.reject(false)

    Wallets = Wallets.filter(w => w.address[0] !== address)
    return Promise.resolve(true)
}