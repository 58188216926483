import React, { useEffect } from "react"
import * as C from "./style"
import ReactTooltip from "react-tooltip"
import { useSelector } from "react-redux"
import Bar from "components/parts/bar"

const BarWidget = () => {

    useEffect(() => {
        ReactTooltip.rebuild()
        ReactTooltip.hide()
    }, [])

    const isMobile = useSelector((state: any) => state.data.isMobile)
    const currentPage = useSelector((state: any) => state.data.currentPage)

    return (
        <C.BarWidget>
            <Bar />
            {/*(isMobile && currentPage === null) && <Bar />}
            {(!isMobile) && <Bar />*/}
        </C.BarWidget>
    )
}

export default BarWidget