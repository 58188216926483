import { PublicKey, LAMPORTS_PER_SOL, Connection, Transaction, ComputeBudgetProgram, Keypair, sendAndConfirmTransaction } from '@solana/web3.js'
import * as anchor from "@project-serum/anchor"
import { SOLANA_CONNECTION, SOLANA_CONNECTION_SHARE, WaitForTransaction } from '../connection'
import { anchorWallet } from '../wallet'
import { findWallet } from 'modules/wallet'
import { store } from 'services/store'
import { IDL } from './types'
import env from 'env'
import { getIssuerAccount, getIssuerAccountDataFromAddress, getShareConfig, getShareHoldingAccount } from './helpers'
import { getShareError, parseCustomProgramError } from './shareError'

export const createShareAccount = async (issuerKey: Keypair, inviteCode: string) => new Promise(async (resolve, reject) => {

    try {

        //if invitecode starts with H3 remove it
        if (inviteCode.startsWith('H3')) inviteCode = inviteCode.substring(2)

        const anchorConnection = new anchor.AnchorProvider(SOLANA_CONNECTION_SHARE, anchorWallet(), anchor.AnchorProvider.defaultOptions())
        const program = new anchor.Program(IDL as anchor.Idl, env.WEB3.SOLANA.SHARE_PROGRAM, anchorConnection)

        const [issuerBroadcast, _] = anchor.web3.PublicKey.findProgramAddressSync(
            [anchor.utils.bytes.utf8.encode('issuer-broadcast'), issuerKey.publicKey.toBuffer()],
            new PublicKey(env.WEB3.SOLANA.SHARE_PROGRAM)
        )

        const tx = await program.methods.createIssuerAccount(Buffer.from(inviteCode))
            .accounts({
                user: issuerKey.publicKey,
                issuerKey: issuerKey.publicKey,
                issuer: getIssuerAccount(issuerKey.publicKey),
                config: getShareConfig(),
                issuerBroadcast,
                systemProgram: anchor.web3.SystemProgram.programId,
            })
            .signers([issuerKey])
            .transaction()

        tx.recentBlockhash = (await SOLANA_CONNECTION_SHARE.getLatestBlockhash("finalized")).blockhash
        tx.feePayer = new PublicKey(issuerKey.publicKey.toString())

        tx.sign(issuerKey)

        try {
            const sig = await SOLANA_CONNECTION_SHARE.sendRawTransaction(tx.serialize(), { skipPreflight: false, preflightCommitment: "finalized" })

            WaitForTransaction(sig, SOLANA_CONNECTION_SHARE).then(() => {
                resolve(sig)
            }).catch((err) => {
                console.log(err)
                if (!err || !err.InstructionError || !err.InstructionError[1] || !err.InstructionError[1].Custom)
                    reject("Error creating share account")
                else {
                    let errorMessage = getShareError(err.InstructionError[1].Custom)
                    if (!errorMessage)
                        errorMessage = "Error creating share account"
                    reject(errorMessage)
                }
            })
        } catch (e: any) {
            console.log(e)

            if (e.message && e.message.indexOf("Attempt to debit an account but found no record of a prior credit.") !== -1) {
                return reject(getShareError("0x1"))
            } else {
                let custom = parseCustomProgramError(e)
                let errorMessage = getShareError(custom)
                if (!errorMessage)
                    errorMessage = "Error creating share account"
                reject(errorMessage)
            }
        }

    } catch (error) {
        console.log(error)
        reject("Error creating share account")
    }

})

export const buyShare = async (issuer: PublicKey, cloudWallet: Keypair, amount: number, slippage: any, totalShares: any) => new Promise(async (resolve, reject) => {

    try {

        const anchorConnection = new anchor.AnchorProvider(SOLANA_CONNECTION_SHARE, anchorWallet(), anchor.AnchorProvider.defaultOptions())
        const program = new anchor.Program(IDL as anchor.Idl, env.WEB3.SOLANA.SHARE_PROGRAM, anchorConnection)

        const issuerData = await getIssuerAccountDataFromAddress(issuer)
        const issuerKey = new PublicKey(issuerData.issuerKey)

        const tx = new Transaction()

        const modifyComputeUnits = ComputeBudgetProgram.setComputeUnitLimit({
            units: 1000000
        });

        const addPriorityFee = ComputeBudgetProgram.setComputeUnitPrice({
            microLamports: 1
        });

        tx.add(modifyComputeUnits).add(addPriorityFee)

        const shareHolding = getShareHoldingAccount(cloudWallet.publicKey, issuerKey)
        const shareHoldingInfo = await SOLANA_CONNECTION_SHARE.getAccountInfo(shareHolding)

        if (shareHoldingInfo === null) {
            const shareHoldingInstruction = await program.methods.createShareHoldingAccount()
                .accounts({
                    user: cloudWallet.publicKey,
                    issuerKey,
                    issuer: getIssuerAccount(issuerKey),
                    shareHolding,
                    systemProgram: anchor.web3.SystemProgram.programId,
                }).transaction()

            tx.add(shareHoldingInstruction)
        }

        const actionTx = await program.methods.buyShare(
            new anchor.BN(amount),
            slippage ? new anchor.BN(slippage) : null,
            slippage ? new anchor.BN(totalShares) : null
        ).accounts({
            user: cloudWallet.publicKey,
            issuer,
            issuerKey,
            shareHolding,
            systemProgram: anchor.web3.SystemProgram.programId,
        }).transaction()

        tx.add(actionTx)

        tx.feePayer = cloudWallet.publicKey
        tx.recentBlockhash = (await SOLANA_CONNECTION_SHARE.getLatestBlockhash("finalized")).blockhash

        try {
            let txhash = await sendAndConfirmTransaction(SOLANA_CONNECTION_SHARE, tx, [cloudWallet], {
                commitment: 'confirmed',
                preflightCommitment: 'confirmed',
                skipPreflight: false
            })

            resolve(txhash)

        } catch (e: any) {
            console.log(e)

            if (e.message && e.message.indexOf("Attempt to debit an account but found no record of a prior credit.") !== -1) {
                return reject(getShareError("0x1"))
            } else {
                let custom = parseCustomProgramError(e)
                let errorMessage = getShareError(custom)
                if (!errorMessage)
                    errorMessage = "Error buying shares"
                reject(errorMessage)
            }
        }

    } catch (e) {
        console.log(e)
        reject(e)
    }

})

export const sellShare = async (issuer: PublicKey, cloudWallet: Keypair, amount: number, slippage: any, totalShares: any) => new Promise(async (resolve, reject) => {

    try {

        const anchorConnection = new anchor.AnchorProvider(SOLANA_CONNECTION_SHARE, anchorWallet(), anchor.AnchorProvider.defaultOptions())
        const program = new anchor.Program(IDL as anchor.Idl, env.WEB3.SOLANA.SHARE_PROGRAM, anchorConnection)

        const issuerData = await getIssuerAccountDataFromAddress(issuer)
        const issuerKey = new PublicKey(issuerData.issuerKey)

        const tx = new Transaction()

        const modifyComputeUnits = ComputeBudgetProgram.setComputeUnitLimit({
            units: 1000000
        });

        const addPriorityFee = ComputeBudgetProgram.setComputeUnitPrice({
            microLamports: 1
        });

        tx.add(modifyComputeUnits).add(addPriorityFee)

        const shareHolding = getShareHoldingAccount(cloudWallet.publicKey, issuerKey)

        const actionTx = await program.methods.sellShare(
            new anchor.BN(amount),
            slippage ? new anchor.BN(slippage) : null,
            slippage ? new anchor.BN(totalShares) : null
        ).accounts({
            user: cloudWallet.publicKey,
            issuer,
            issuerKey,
            shareHolding,
            config: getShareConfig(),
            systemProgram: anchor.web3.SystemProgram.programId,
        }).transaction()

        tx.add(actionTx)

        tx.feePayer = cloudWallet.publicKey
        tx.recentBlockhash = (await SOLANA_CONNECTION_SHARE.getLatestBlockhash("finalized")).blockhash

        try {
            let txhash = await sendAndConfirmTransaction(SOLANA_CONNECTION_SHARE, tx, [cloudWallet], {
                commitment: 'confirmed',
                preflightCommitment: 'confirmed',
                skipPreflight: false
            })

            resolve(txhash)

        } catch (e: any) {
            console.log(e)

            if (e.message && e.message.indexOf("Attempt to debit an account but found no record of a prior credit.") !== -1) {
                return reject(getShareError("0x1"))
            } else {
                let custom = parseCustomProgramError(e)
                let errorMessage = getShareError(custom)
                if (!errorMessage)
                    errorMessage = "Error buying shares"
                reject(errorMessage)
            }
        }

    } catch (e) {
        console.log(e)
        reject("Error buying shares")
    }

})