import React from "react"
import ChatWidget from "components/widgets/chat"
import CurrentCollectionWidget from "components/widgets/currentCollection"
import CurrentTokenWidget from "components/widgets/currentToken"
import FeaturedCollectionsWidget from "components/widgets/featuredCollections"
import NewsWidget from "components/widgets/news"
import { useSelector } from "react-redux"
import LoginNotification from "./loginNotification"
import GuideWidget from "components/widgets/guide"

const MapWidgets = () => {

    const isMobile = useSelector((state: any) => state.data.isMobile)

    if (isMobile) {
        return (
            <></>
        )
    }

    return (
        <>
            {/*<ChatWidget/>
            <NewsWidget/>
    <FeaturedCollectionsWidget/>*/}
            <CurrentCollectionWidget/>
            <CurrentTokenWidget/>
            <LoginNotification/>
            <GuideWidget/>
            <ChatWidget/>
            
        </>
    )
}

export default MapWidgets