import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import { color } from 'styles/theme';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const labels = Array.from({ length: 12 }, (_, i) => i + 1);

const chartOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
            display: false
        },
        title: {
            display: false,
            //text: '@tsaadi_'
        },
    },
};

export const defaultChartData = {
    labels: labels,
    datasets: [
        {
            label: '',
            data: [],
            borderColor: color.primary,
            backgroundColor: color.primary
        },
    ],
};


export default (props:any) => (<Line options={chartOptions} data={props.data} />)