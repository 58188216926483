import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { deviceMax } from "styles/media"

export const WhitelistNominate = styled.div`
    & input{
        margin-bottom:8px;
    }

    form p{
        margin:0px;
        margin-bottom:8px;
        font-size:14px;
        color:${color.danger};
    }
`

export const Title = styled.div`
    & span{
        background: linear-gradient(90deg, ${color.gradient1.start}, ${color.gradient1.middle}, ${color.gradient1.end});
        font-weight:500;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`

export const Description = styled.div`
    margin:8px 0px;
    font-size:13px;
    color:${color.primary};

    & span{
        color:${color.white};
    }
`

export const AlreadyNominated = styled.div`
    border:1px solid ${Hex2Rgba(color.white, .2)};
    border-radius:4px;
    padding:8px;
`

export const Nomination = styled.div`
    display:flex;
    justify-content:space-between;
`

export const NominationTwitter = styled.div`
    color:${Hex2Rgba(color.white, .64)};
`

export const NominationVerified = styled.div`
    color:${color.primary};
`

export const NominateMore = styled.div`
    margin-top:16px;

    & form{
        margin-top:8px;

        & button:not(:last-child){
            margin-right:8px;
        }

        & input{
            margin-bottom:0px;
        }
    }
`

export const NominatemoreGroup = styled.div`
    position:relative;
    display:flex;
    align-items:center;
    margin-bottom:8px;
`

export const NominateMoreRemove = styled.div`
    position:absolute;
    right:15px;
    cursor:pointer;
    display:flex;
    align-items:center;
    color:${Hex2Rgba(color.white, .64)};
`

export const Verify = styled.div`

`

export const Buttons = styled.div`
    display:flex;
    & button:not(:last-child){
        margin-right:8px;
    }
`

export const DescriptionVerify = styled.div`
    margin:8px 0px;
    font-size:13px;
    color:${color.primary};

    & span{
        color:${color.white};
    }

    padding:8px;
    border:1px solid ${Hex2Rgba(color.primary, .2)};
    background:${Hex2Rgba(color.secondaryFade, .25)};
    border-radius:4px;
`

export const NominationsToBeVerifiedTitle = styled.div`
    margin-bottom:8px;
    padding-top:8px;
    font-size:14px;
    color:${color.primary};
    display:flex;
    justify-content:space-between;

    & a {
        color:${color.white};
        text-decoration:none;

        &:hover{
            text-decoration:underline;
        }
    }
`

export const NominationsToBeVerified = styled.div`
    border:1px solid ${Hex2Rgba(color.white, .2)};
    border-radius:4px;
    padding:8px;
    margin-bottom:8px;
`

export const TweetAgain = styled.div``