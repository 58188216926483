import { findWallet } from "modules/wallet"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setWalletsChanged } from "services/slices/data"
import { setGlobalSelectedWallet } from "services/slices/user"
import { copyObject } from "utils/helpers"

const WalletListener = () => {

    const dispatch = useDispatch()
    const walletsChanged = useSelector((state: any) => state.data.walletsChanged)
    const selectedWallet = useSelector((state: any) => state.user.selectedWallet)
    const user = useSelector((state: any) => state.user.user)

    useEffect(() => {
        if (walletsChanged) {
            manage()
        }
    }, [walletsChanged])

    const manage = async () => {
        if (!user) {

        } else {

            let wallets: any = copyObject(user.wallets)

            for (var i = 0; i < wallets.length; i++) {
                let wallet = findWallet(wallets[i].address)
    
                if (wallet) {
                    let isConnected = await wallet.classInstance.isConnected()
    
                    if (isConnected) {
                        wallets[i].provider = wallet.provider
                        wallets[i].connected = true
                    }
                } else {
                    wallets[i].connected = false
                }
            }

            // If the selected wallet is not connected, set selected wallet to null
            //console.log(wallets)
            if (selectedWallet) {
                let wallet = wallets.find((w: any) => w.address === selectedWallet && w.connected)
                if (!wallet) {
                    dispatch(setGlobalSelectedWallet(""))
                }
            }

        }

        dispatch(setWalletsChanged(false))
    }

    return <></>

}

export default WalletListener