import { useSelector } from "react-redux"
import * as P from "./style"

import BasePage from "components/pages/base"
import { IconShuffle } from "utils/icons"

import { MainParts } from "utils/parts"
import Shuffle from "components/parts/shuffle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser } from "@fortawesome/free-solid-svg-icons"
import Share from "components/parts/share"

const Page = () => {

    const show = useSelector((state: any) => state.data.currentPage)
    
    return (
        <P.Page>
            <BasePage title="Key" show={show === MainParts.Share} icon={<FontAwesomeIcon icon={faUser} />} glasmorph={true}>
              <Share />
            </BasePage>
        </P.Page>
    )
}

export default Page