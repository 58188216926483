import styled, { keyframes } from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { deviceMax } from "styles/media"
import { Link } from "react-router-dom"

export const ShuffleHowToPlay = styled.div`
    
`

export const Title = styled.div`
    font-size: 16px;
    font-weight: 500;
`

export const Text = styled.div`
    font-size: 14px;
    color: ${color.whiteShade};
    margin-bottom: 24px;
`

export const Highlight = styled.div`
    background: linear-gradient(90deg, #F2EFF8 0%, #DEC5FF 48.44%, #71C3E7 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 600;
    margin-bottom: 24px;
`

export const Note = styled.div`
    font-size: 12px;
    color: ${color.whiteShade};

    & ul{
        margin: 0;
        padding-left: 25px;
    }

    margin-bottom: 24px;
`