import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"

export const ChatContent = styled.div`
    backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background: ${Hex2Rgba(color.bg1, .64)};
    width: 525px;
    max-height:100%;
    border-radius: 8px;
    margin:40px auto 40px 132px;
`

export const Chat = styled.div`
    position:fixed;
    display:flex;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 11;
    transition: all .3s ease-in-out;
    pointer-events: none;
`

