import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { Link } from "react-router-dom"
import { LD } from "utils/templates"
import { deviceMax } from "styles/media"
            

export const WLCollection = styled.div<{view? : any}>`
    display:flex;
    flex-direction:column;
    background: ${Hex2Rgba("#000000", .32)};
    border-radius:10px;
    padding:${props => props.view ? "0px" : "8px"};
    border: 1px solid ${Hex2Rgba("#000000", .32)};

    transition:all .1s ease-in-out;

    &:hover{
        border: 1px solid ${Hex2Rgba(color.primary, .3)};
        background: linear-gradient(0deg, ${Hex2Rgba(color.black, .2)}, ${Hex2Rgba(color.black, .2)}), linear-gradient(180deg, ${Hex2Rgba(color.secondary, .05)} 0%, ${Hex2Rgba(color.primary, .05)} 100%);
    }

    color:${color.white};
    text-decoration:none;
`

export const Top = styled.div`
    display:flex;
    margin-bottom:10px;
`

export const Image = styled(LD)`
    flex:.9;
    display:flex;
    justify-content:flex-start;
    align-items:flex-start;
    margin-right:10px;
    & > img{
        width:100%;
        height:auto;
        border-radius:8px;
        
    }

    position:relative;

    ${props => props.view && `
        &::after {
            content: "";
            aspect-ratio : 1 / 1;
            position: absolute;
            inset: 0;
            border-radius: 8px; 
            padding: 1px;
            background:linear-gradient(45deg,${Hex2Rgba(color.gradient1.start, .87)},${Hex2Rgba(color.gradient1.middle, .87)},${Hex2Rgba(color.gradient1.end, .87)}); 
            -webkit-mask: 
            linear-gradient(#fff 0 0) content-box, 
            linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
                    mask-composite: exclude; 
        }
    `}
`

export const Info = styled.div`
    flex:2.5;
    
`

export const Name = styled(LD)`
    font-weight:500;
    font-size:18px;
    display:flex;
    align-items:center;
    color:${color.white};
    text-decoration:none;
`

export const Description = styled(LD)`
    color:${color.primary};
    font-size:14px;
    min-height:84px;
    text-decoration:none;
    display:block;
    line-height:1.2;

    ${props => props.view && `
        font-size:12px;
        min-height:50px !important;
    `}

    @media ${deviceMax.xxxxxl}{
        min-height:127px;
    }

    @media ${deviceMax.xxxxl}{
        min-height:84px;
    }

    @media ${deviceMax.xxxl}{
        min-height:105px;
    }

    @media ${deviceMax.xxl}{
        min-height:130px;
    }

    @media ${deviceMax.xl}{
        min-height:auto;
    }
`

export const Social = styled.div`
    display:flex;
    font-size:18px;
    align-items:center;
    height:27px;
`

export const SocialItem = styled.a<{hc : string}>`
    color:${Hex2Rgba(color.white, 1)};

    &:not(:last-child){
        margin-right:8px;
    }

    &:hover{
        color:${props => props.hc};
    }
`

export const Details = styled(LD)`
    display:flex;
    flex-direction:column;
    color:${color.white};
    text-decoration:none;
`

export const DetailsTop = styled.div`
    display:flex;
    justify-content:space-between;
    background-color:${Hex2Rgba(color.secondaryVibrant, .16)};
    border-radius:4px;
`

export const DetailsItem = styled.div`
    display:flex;
    flex-direction:column;
    
    padding:4px 8px;
    
    flex:1;

    & svg{
        margin-right:5px;
    }

    &:not(:last-child){
        margin-right:10px;
    }
`

export const DetailsItemTitle = styled.div`
    font-size:12px;
    color:${Hex2Rgba(color.white, .6)};
`

export const DetailsItemContent = styled.div`
    font-size:15px;
`

export const DetailsBottom = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-top:16px;
`

export const SpotsTaken = styled.div`
    font-size:14px;
    color:${Hex2Rgba(color.white, .86)};

    & > span{
        /*gradient text*/
        background: linear-gradient(90deg, ${color.gradient1.start}, ${color.gradient1.middle}, ${color.gradient1.end});
        font-weight:600;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`

export const Chain = styled.div`
    & > img{
        width:20px;
        height:20px;
        margin-right:5px;
    }
    user-select:none;
    cursor:default;

    display:flex;
    align-items:center;

`

export const Apply = styled.div`
    margin-left:10px;
    background-color:${Hex2Rgba(color.white, .16)};
    width:18px;
    height:18px;
    border-radius:50%;
    display:flex;
    justify-content:center;
    align-items:center;
    font-size:10px;
`

export const State = styled.div<{state : string}>`
    width:10px;
    height:10px;
    border-radius:50%;
    margin-left:5px;
    ${props => props.state === "ACTIVE" && `
        background-color:${color.green};
    `}

    ${props => props.state === "INACTIVE" && `
        background-color:${color.danger};
    `}

    ${props => props.state === "PASSIVE" && `
        background-color:${color.tertiary};
    `}
`
