import { CollectionImg, CollectionName } from "components/widgets/collection/style"
import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"


export const CurrentCollectionContent = styled.div`
    backdrop-filter: blur(20px);
    background: ${Hex2Rgba(color.bg1, .64)};
    width:100%;
    height:100%;
    border-radius: 8px;
    padding:10px 8px;
`

export const CurrentCollection = styled.div<{overlay : boolean, active : boolean, visible:boolean}>`
    position:absolute;
    bottom:76px;
    width: 100%;
    padding: 0 10px;
    z-index: 11;
    transition: all .3s ease-in-out;

    ${props => (!props.visible) && `
        
        display:none;
        
    `}

    ${props => props.overlay && `
        & > ${CurrentCollectionContent}{
            background: ${Hex2Rgba(color.black, .6)};
        }
    `}

    & ${CollectionImg}{
        flex:.6;
    }

    & ${CollectionName}{
        font-size: 14px;
    }
`