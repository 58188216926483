import toast from "react-hot-toast"
import { getAllCollections } from "services/api/collections"
import { setCollections } from "services/slices/data"
import { resetFilters, setCurrentViewingCollection } from "services/slices/holders"
import { set_Collections } from "services/slices/_collections"
import { store } from "services/store"
import { ManageHolderStates } from "./holders"
import { history } from 'components/etc/history'

export const GetAllCollections = async () => {
    getAllCollections().then((res) => {
        store.dispatch(setCollections(res.data.collections))
        store.dispatch(set_Collections(res.data.collections))
    }).catch((err: any) => {
        toast.error("Failed to get collections")
    })
}

export const ManageCurrentViewingCollection = () => {
    let code = store.getState().holders.currentViewingCollectionCode


    if (code) {
        let collection: any = store.getState().data.collections.find((wl: any) => wl.name.toLowerCase().replace(/ /g, "-") === code)
        if (collection) {
            store.dispatch(setCurrentViewingCollection(collection.id))
            store.dispatch(resetFilters())
            document.title = collection.name + " - hub3"
            ManageHolderStates(false, "ManageCurrentViewingCollection")
            return;
        }
    }

    store.dispatch(setCurrentViewingCollection(null))
    history.push(`/app/`)
    toast.error("Collection not found")
}