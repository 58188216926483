import button from "components/ui/button"
import { Button } from "components/ui/button/style"
import styled, {keyframes} from "styled-components"
import { Col, Row } from "styles"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"

export const Fronk = styled.div`
    display:flex;
    justify-content:center;
    & img{
        border-radius:50%;
    }
`

export const Introduction = styled.div`

`

export const Rocket = styled.div`
    display:flex;
    position:relative;
    align-items:center;
    justify-content:center;
    width:100%;

    &:after{
        content:" ";
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
    }

    transition:all 0.5s ease-in-out;
    
`
export const RocketBg = styled.div`
    width:160px;
        
    & img{
        width:100%;
    }
    
`

const rocketAnimation = keyframes`
    /*animation transform translate circle around*/
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(0, 10px);
    }
    100% {
        transform: translate(0, 0);
    }
        
    
`

export const RocketImage = styled.div`

    width:87px;
    position:absolute;
    transform:translate(0, 10px);

    & img{
        width:100%;
        animation: ${rocketAnimation} 3s ease-in-out infinite;
        transition: all 0.3s ease-in-out;
    }

`

export const Title = styled.div`
    font-size: 24px;
    text-align: center;
    margin-top: 24px;
`

export const Message = styled.div`
    font-size:16px;
    background: linear-gradient(90deg, ${color.gradient1.start}, ${color.gradient1.middle}, ${color.gradient1.end});
    font-weight:500;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align:center;
    margin-top: 12px;
    margin-bottom: 8px;
`

export const Description = styled.div`
    text-align:center;
    font-size:14px;
    color:${color.primary};
    & span{
        color:${color.white};
    }
`

export const Buttons = styled.div`
    display:flex;
    margin-top:12px;

    & ${Button}:not(:last-child){
        margin-right:16px;
    }
`