import styled from "styled-components"

import { Dialog, DialogHeader } from "../base/style"
import { ActiveWalletBar } from "components/widgets/activeWalletBar/style"
import { color } from "styles/theme"

export const Page = styled.div`
    & ${Dialog} {
        background: ${color.shuffleBg};
        max-width:1340px;
        margin: 100px auto 100px auto;
        left:42px;
        border:5px solid ${color.secondaryFade};
    }

    @media (max-height: 960px) {
        & ${Dialog} {
            margin: 40px auto 40px auto;
        }
    }

    @media (min-width: 2000px) {
        & ${Dialog} {
            margin: 40px auto 40px auto;
        }
    }

    & ${DialogHeader} {
        display: none;
    }

`