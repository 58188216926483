import React from "react"
import * as P from "./style"

import { useDispatch, useSelector } from "react-redux"
import BasePopup from "components/popups/base"
import { IconShuffle } from "utils/icons"

import { showShuffleLiquidityDepositPopup } from "services/slices/popup"
import ShuffleLiquidityDeposit from "components/parts/shuffle/shuffleLiquidityDeposit"
import { setLiquidationType } from "services/slices/shuffle"


const ShuffleLiquidityDepositPopup = () => {

    const dispatch = useDispatch()

    const show = useSelector((state: any) => state.popup.ShuffleLiquidityDepositPopup)
    const liquidationType = useSelector((state: any) => state.shuffle.liquidationType) 

    const hide = () => {
        dispatch(showShuffleLiquidityDepositPopup(false))
        dispatch(setLiquidationType(null))
    }
    
    return (
        <P.Popup liquidationType={liquidationType ? liquidationType : "false"}>
            <BasePopup title="Deposit to Shuffle" show={show} hide={hide} isredux={false} icon={<IconShuffle />} glasmorph={true}>
                <ShuffleLiquidityDeposit/>
            </BasePopup>
        </P.Popup>
    )
}

export default ShuffleLiquidityDepositPopup