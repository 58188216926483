import React, { useRef, useEffect, useState } from 'react'
import * as C from './style'
import mapboxgl from 'mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax
import env from 'env'
import { color } from 'styles/theme'
import { useSelector } from 'react-redux'
import { createRoot } from 'react-dom/client'
import ProfileMap from '../profileMap'
import PlaceMap from '../placeMap'
import { store } from 'services/store'
import { userProfileAndDisplayProfile } from 'services/managers/holders'
import { Hex2Rgba, copyObject } from 'utils/helpers'
import { useNavigate } from 'react-router-dom'
import { setCurrentViewingHolderWhitelisted, setCurrentViewingPlace, setCurrentViewingProfileMap, setCurrentViewingStory } from 'services/slices/data'
import { showPlacepopup, showProfileMapPopup, showStoryPopup } from 'services/slices/popup'
import StoryMap from '../storyMap'

mapboxgl.accessToken = env.API_KEYS.MAPBOX
/* @ts-ignore
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;*/
const colors = [color.tertiary, color.secondaryVibrant]
var initialLoad = false
var markers: any = {}
var markersOnScreen: any = {}
var loading = false

const Map = () => {

    const mapContainer = useRef(null)
    const map = useRef<mapboxgl.Map>()
    const [lng, setLng] = useState(9.1076929)
    const [lat, setLat] = useState(45.4627124)
    const [zoom, setZoom] = useState(1.5)
    const [error, setError] = useState(false)

    const holders = useSelector((state: any) => state.holders.holders)
    const navigate = useNavigate()

    useEffect(() => {
        try {
            map.current = new mapboxgl.Map({
                container: mapContainer.current!,
                style: 'mapbox://styles/mapbox/dark-v10',
                center: [lng, lat],
                zoom: zoom,
                projection: {
                    name: 'globe'
                }
            })
        } catch (e) {
            //console.log(e)
            setError(true)
            return;
        }

        map.current.on('load', () => {
            // Set the default atmosphere style
            map.current?.setFog({
                'range': [0.5, 0.9],
                'color': Hex2Rgba("#000000", 0.56),
                'horizon-blend': 0.1,
                //@ts-ignore
                'high-color': Hex2Rgba("#9a8ef6", .32),
                //@ts-ignore
                'space-color': "#00031a",
                //@ts-ignore
                'star-intensity': 0.1
            })

            //globalization
            map.current?.setFilter('admin-0-boundary-disputed', [
                'all',
                ['==', ['get', 'disputed'], 'true'],
                ['==', ['get', 'admin_level'], 0],
                ['==', ['get', 'maritime'], 'false'],
                ['match', ['get', 'worldview'], ['all', "MA"], true, false]
            ]);
            map.current?.setFilter('admin-0-boundary', [
                'all',
                ['==', ['get', 'admin_level'], 0],
                ['==', ['get', 'disputed'], 'false'],
                ['==', ['get', 'maritime'], 'false'],
                ['match', ['get', 'worldview'], ['all', "MA"], true, false]
            ]);
            map.current?.setFilter('admin-0-boundary-bg', [
                'all',
                ['==', ['get', 'admin_level'], 0],
                ['==', ['get', 'maritime'], 'false'],
                ['match', ['get', 'worldview'], ['all', "MA"], true, false]
            ]);


            const filter1 = ['==', ['get', 'whitelisted'], true]
            const filter2 = ['==', ['get', 'type'], "place"]
            const filter3 = ['==', ['get', 'mint'], true]
            const filter4 = ['==', ['get', 'type'], "story"]


            // Add a layer showing the holders.
            map.current?.addSource('holders', {
                type: 'geojson',
                cluster: false
            })

            /*
            Add a layer showing the holders. 
            Holders may have whitelisted property, which means that they will have a different circle color.
            Holders will be clustered if they are too close to each other.
            Clusters will be like a pie view, divided by the whitelisted property. if there is no whitelisted property, the cluster will be a single color.
            */

            let imageMarker = '/images/map/marker.png'
            let imageMarkerWhitelisted = '/images/map/whitelist.png'
            let imageMarkerPlace = '/images/map/place.png'
            let imageMarkerStory = '/images/map/story.png'

            map.current?.loadImage(imageMarker, (error, image: any) => {
                if (error) throw error
                map.current?.addImage('marker', image)
                map.current?.loadImage(imageMarkerWhitelisted, (error, image: any) => {
                    if (error) throw error
                    map.current?.addImage('markerWhitelisted', image)
                    map.current?.loadImage(imageMarkerPlace, (error, image: any) => {
                        if (error) throw error
                        map.current?.addImage('markerPlace', image)
                        map.current?.loadImage(imageMarkerStory, (error, image: any) => {
                            if (error) throw error
                            map.current?.addImage('markerStory', image)
                            map.current?.addLayer({
                                id: 'holders_circle',
                                type: 'symbol',
                                source: 'holders',
                                layout: {
                                    'icon-image': ['case', filter1, 'markerWhitelisted', filter2, 'markerPlace', filter3, 'markerWhitelisted', filter4, 'markerStory', 'marker'],
                                    'icon-size': 0.35,
                                    "icon-allow-overlap": true,
                                },
                                filter: ['!', ['has', 'point_count']],
                            })
                        })
                    })
                })
            })


            map.current?.on('error', e => {
                //console.log(e.error);
            });

            const detailPopup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false,
                className: "detail-popup"
            });

            // on click on a single marker
            map.current?.on('click', 'holders_circle', (e: any) => {

                if (store.getState().data.isMobile) {
                    if (e.features[0].properties.type === "holder") {
                        if (store.getState().popup.ProfileMapPopup) {
                            store.dispatch(showProfileMapPopup(false))
                            const data = copyObject(e.features[0])
                            setTimeout(() => {
                                //userProfileAndDisplayProfile(e.features[0].properties.id)
                                store.dispatch(setCurrentViewingHolderWhitelisted(data.properties.whitelisted))
                                store.dispatch(setCurrentViewingProfileMap(data.properties))
                                store.dispatch(showProfileMapPopup(true))
                            }, 500)
                        } else {
                            store.dispatch(setCurrentViewingHolderWhitelisted(e.features[0].properties.whitelisted))

                            store.dispatch(setCurrentViewingProfileMap(e.features[0].properties))
                            store.dispatch(showProfileMapPopup(true))
                        }
                        //navigate(`/app/profile/${e.features[0].properties.id}`)
                    } else if (e.features[0].properties.type === "place") {
                        store.dispatch(showPlacepopup(true))
                        store.dispatch(setCurrentViewingPlace(e.features[0].properties.id))
                    } else if (e.features[0].properties.type === "story") {
                        store.dispatch(showStoryPopup(true))
                        store.dispatch(setCurrentViewingStory(e.features[0].properties.id))
                    }
                    return;
                }

                const coordinates = e.features[0].geometry.coordinates.slice();

                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }

                const popupNode = document.createElement('div')
                const root = createRoot(popupNode);

                if (e.features[0].properties.type === "place")
                    root.render(<PlaceMap place={e.features[0].properties} />);
                else if (e.features[0].properties.type === "story")
                    root.render(<StoryMap story={e.features[0].properties} />);
                else
                    root.render(<ProfileMap user={e.features[0].properties} />);

                let popup = new mapboxgl.Popup()
                    .setLngLat(coordinates)
                    .setDOMContent(popupNode)
                popup.addTo(map.current!);

                detailPopup.isOpen() && detailPopup.remove()
            });


            map.current?.on('mouseenter', 'holders_circle', () => {
                map.current?.getCanvas().setAttribute('style', 'cursor: pointer')
            })
            map.current?.on('mouseleave', 'holders_circle', () => {
                map.current?.getCanvas().setAttribute('style', 'cursor: grab')
            })

            // on mouseover details
            map.current?.on('mouseenter', 'holders_circle', (e: any) => {

                map.current?.getCanvas().setAttribute('style', 'cursor: pointer')

                // Copy coordinates array.
                const coordinates = e.features[0].geometry.coordinates.slice();

                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }

                detailPopup.setLngLat(coordinates).setHTML(DetailPopupHtml({ properties: e.features[0].properties })).addTo(map.current!);

            })

            map.current?.on('mouseleave', 'holders_circle', () => {
                map.current?.getCanvas().setAttribute('style', 'cursor: grab')
                detailPopup.remove();
            });



            /*map.current?.on('draw.update', PatchDatas);
    
            if (holders) {
                PatchDatas()
            }*/

            map.current?.on('render', () => {
                if (!map.current?.isSourceLoaded("holders")) return;
                if (!map.current?.loaded()) return;
                if (loading) return;
                //updateMarkers();
            })

        })

        return () => {
            map.current?.remove()
        }
    }, [])



    function updateMarkers() {

        const newMarkers: any = {}
        // @ts-ignore
        const features: any = map.current?.querySourceFeatures("holders")

        // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
        // and add it to the map if it's not there already
        for (const feature of features) {
            const coords = feature.geometry.coordinates;
            const props = feature.properties;
            if (!props.cluster) continue;
            const id = props.cluster_id;

            let marker = markers[id];
            if (!marker) {
                const el = createPieSvgHTML(props)
                // @ts-ignore
                marker = markers[id] = new mapboxgl.Marker({ element: el }).setLngLat(coords);
            }

            newMarkers[id] = marker;

            if (!markersOnScreen[id]) marker.addTo(map.current);
        }

        // for every marker we've added previously, remove those that are no longer visible
        for (const id in markersOnScreen) {
            if (!newMarkers[id]) markersOnScreen[id].remove();
        }
        markersOnScreen = newMarkers;

    }

    // code for creating pie chart svg from feature properties
    function createPieSvgHTML(props: any) {

        let svg = ""

        if (props.whitelisted === 0) {

            let colour = color._mapcolor1

            if (props.point_count >= 25) {
                colour = color._mapcolor2
            } else if (props.point_count >= 100) {
                colour = color._mapcolor3
            }

            svg = `<div style="display:flex;align-items:center;justify-content:center;position:relative;">
                    <svg width="42" viewBox="0 0 20 20" style="display:block;">
                        <circle r="10" cx="10" cy="10" fill="${colour}" />
                    </svg>
                    <div style="position:absolute;color:#000000;font-weight:500;text-shadow:0 0 10px #ccc">
                        ${props.point_count}
                    </div>
                </div>
            `

        } else {

            let ratio = props.whitelisted / props.point_count
            let rest = 1 - ratio

            svg = `<div style="display:flex;align-items:center;justify-content:center;position:relative;">
                    <svg width="42" viewBox="0 0 20 20" style="display:block;">
                        <circle r="10" cx="10" cy="10" fill="${colors[1]}" />
                        <circle r="5" cx="10" cy="10" fill="transparent"
                                stroke="${colors[0]}"
                                stroke-width="10"
                                stroke-dasharray="calc(${ratio * 100} * 31.4 / 100) 31.4"
                                transform="rotate(-90) translate(-20)" />
                    </svg>
                    <div style="position:absolute;color:#000000;font-weight:500;text-shadow:0 0 10px #ccc">
                        ${props.point_count}
                    </div>
                </div>
            `
        }


        const div = document.createElement('div');
        div.innerHTML = svg;
        return div.firstChild;

    }

    useEffect(() => {

        if (!initialLoad) {
            initialLoad = true
            return
        }

        // try till map.current.loaded() returns true then call PatchDatas()
        const interval = setInterval(() => {
            if (map.current && map.current?.loaded()) {
                PatchDatas()
                clearInterval(interval)

            }
        }, 1000)

    }, [holders])

    const PatchDatas = () => {
        //console.log("patching datas")
        let source: any = map.current?.getSource('holders')

        source.setData(store.getState().holders.holders)

        let _markersOnScreen = Object.values(markersOnScreen)
        let _markers = Object.values(markers)

        _markersOnScreen.forEach((marker: any) => {
            marker.remove()
        })
        _markers.forEach((marker: any) => {
            marker.remove()
        })

        markers = {}
        markersOnScreen = {}
        loading = true

        const interval = setInterval(() => {
            if (map.current?.loaded()) {
                setTimeout(() => {
                    //updateMarkers()
                    loading = false
                }, 1000)
                clearInterval(interval)
            }
        }, 1000)

        try {
            //updateMarkers()
        } catch (e) {
            //console.log(e)
        }

    }

    if (error)
        return (
            <C.Error>
                Your browser does not support WebGL. Please use a different browser<br />
                or try restarting your browser and clearing your cache.
            </C.Error>
        )

    return <C.Map ref={mapContainer} />

}

export default Map


export const DetailPopupHtml = (props: any) => {
    const { properties } = props

    if (properties.type === "place")
        return `
            <div class="map-popup">
                <div class="map-popup__username">${properties.name}</div>
            </div>
        `
    else if (properties.type === "story")
        return `
            <div class="map-popup">
                <div class="map-popup__username">WEB3 Story</div>
            </div>
        `
    else
        return `
            <div class="map-popup">
                <div class="map-popup__username">${properties.username}</div>
                <div class="map-popup__status">${properties.status}</div>
            </div>
        `
}