import React from "react"
import * as W from "./style"
import { useDispatch, useSelector } from "react-redux"
import BasePage from "components/pages/base"
import Guide from "components/parts/guide"
import { setDisplayGuide } from "services/slices/data"
import { MainParts } from "utils/parts"

const GuideWidget = () => {

    const displayGuide = useSelector((state: any) => state.data.displayGuide)
    const currentPage = useSelector((state: any) => state.data.currentPage)
    const dispatch = useDispatch()

    const hide = () => {
        localStorage.setItem("guide", "no")
        dispatch(setDisplayGuide(false))
    }

    return (
        <W.Page>
            <BasePage title="Guide" hide={hide} show={displayGuide && currentPage === MainParts.Home} glasmorph={true}>
                <Guide/>
            </BasePage>
        </W.Page>
    )
}

export default GuideWidget