import styled from "styled-components"

import { ActiveWalletBar } from "components/widgets/activeWalletBar/style"
import { color } from "styles/theme"
import { PageBody, MobilePage as Page } from "../base/style"


export const MobilePage = styled.div`

    & ${PageBody} {
        padding: 0px;
    }

    & ${Page} {
        background: ${color.shuffleBg};
    }

`