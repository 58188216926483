import styled from "styled-components"
import { Dialog, DialogBody, DialogHeader, DialogTitleText, DialogClose } from "components/pages/base/style"
import { Collections, Nfts, NftsContent } from "components/parts/profile/nfts/style"
import { color } from "styles/theme"
export const Page = styled.div`
    & ${Dialog}{
        padding:0;
        position: relative;
    }

    & ${DialogHeader}{
        padding-right:20px;
        padding-left:20px;
        padding-top:20px;
        position:absolute;
        z-index:2;
        width:100%;
    }

    & ${DialogClose}, & ${DialogTitleText}{
        text-shadow: 0 0 10px ${color.black};
    }

    & ${NftsContent}{
        padding-right:20px;
        padding-left:20px;
    }

    & ${Collections}{
        padding-right:20px;
        padding-left:20px;
    }
`