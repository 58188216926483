import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { deviceMax } from "styles/media"
import { Link } from "react-router-dom"

export const Launches = styled.div``



export const Search = styled.div`
    display:flex;
    justify-content:flex-start;
    flex:1;
    & > input{
        width:100%;
    }
    align-items:center;
`

export const FilterButtons = styled.div`
    display:flex;
    justify-content:flex-end;
    flex:4;
    & > div{
        margin-right:10px;
    }

    & > div:last-child{
        margin-right:0px;
    }

    @media ${deviceMax.sm} {

        & > div{
            flex:1;
        }
        
        & > div button{
            font-size:12px;
            width:100%;
        }

        & > div svg{
            margin-left:0px;
        
    }
`

export const Filter = styled.div`
    display:flex;

    @media ${deviceMax.sm} {
        flex-direction:column;

        & > ${Search} > input{
            width:100%;
        }

        & > ${FilterButtons}{
            margin-top:20px;
            justify-content:space-between;
        }

    }
`

export const Items = styled.div`
    margin-top:25px;

    
`