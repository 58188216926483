import Input from "components/ui/input"
import React, { useEffect, useRef, useState } from "react"
import * as C from "./style"
import autosize from "autosize"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronRight, faExternalLink, faPaperPlane, faSpinner } from "@fortawesome/free-solid-svg-icons"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import { MainParts, displayChatWidget } from "utils/parts"
import { Link, useNavigate } from "react-router-dom"
import { setChatToken, setCurrentChat, setCurrentChatName, setInitialLoaded, setMessages, setRooms, setSocketConnected } from "services/slices/chat"
import { initSocketChat, joinChat, onMessage, sendMessage } from "services/socket.chat"
import { getRoomHistory, getRooms } from "services/api/chat"
import toast from "react-hot-toast"
import env from "env"
import { Hex2Rgba, getAvatar } from "utils/helpers"
import Button from "components/ui/button"
import { color } from "styles/theme"

var tokenRetried = false

const Chat = () => {

    const dispatch = useDispatch()
    const user = useSelector((state: any) => state.user.user)
    const chatWidgetVisible = useSelector((state: any) => state.data.displayChat)
    const currentPage = useSelector((state: any) => state.data.currentPage)
    const isMobile = useSelector((state: any) => state.data.isMobile)
    const navigate = useNavigate()
    

    const hide = (redirect: boolean = true) => {
        if (chatWidgetVisible) {
            displayChatWidget(false)
        } else if (redirect) {
            navigate("/app")
        }
    }

    const currentChat = useSelector((state: any) => state.chat.currentChat)
    const currentChatName = useSelector((state: any) => state.chat.currentChatName)
    const rooms = useSelector((state: any) => state.chat.rooms)
    const messages = useSelector((state: any) => state.chat.messages)
    const messageInput = useRef<any>(null);
    const chatBody = useRef<any>(null);
    const [scrollWasAtBottom, setScrollWasAtBottom] = useState(true);
    const handleSendMessage = () => {
        let val = messageInput.current.value.trim();

        if (val === "") return;

        /*dispatch(setMessages([...messages, {
            from: "me",
            sender: "Me",
            message: messageInput.current.value,
            time: new Date().toISOString()
        }]));*/
        messageInput.current.value = "";
        messageInput.current.focus();

        sendMessage(currentChat, val)


    }

    useEffect(() => {
        if (scrollWasAtBottom)
            scrollToBottom();

    }, [messages]);

    const onKeyPress = (e: any) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
        }
    }

    const scrollToBottom = () => {
        try { chatBody.current.scrollTop = chatBody.current.scrollHeight; } catch (err) { }
    }
    const isScrollAtBottom = (element: any) => {
        try {
            return Math.ceil(element.scrollTop) + element.clientHeight >= element.scrollHeight;
        } catch (err) { return false }
    };

    const handleScroll = async (e: any) => {
        if (e.target.scrollTop === 0) {
            const firstMessageId = messages[0].id;
            const prevChatBodyScrollHeight = chatBody.current.scrollHeight;
            await loadRoom(currentChat, firstMessageId);
            
            setTimeout(() => {
                chatBody.current.scrollTop = chatBody.current.scrollHeight - prevChatBodyScrollHeight;
            }, 0);
            setScrollWasAtBottom(false);
        } else if (isScrollAtBottom(e.target)) {
            setScrollWasAtBottom(true);
        } else {
            setScrollWasAtBottom(false);
        }
    };

    useEffect(() => {
        if (scrollWasAtBottom && messages.length > 0)
            scrollToBottom();

    }, [messages]);

    const initialLoaded = useSelector((state: any) => state.chat.initialLoaded)
    const socketConnected = useSelector((state: any) => state.chat.socketConnected)
    const chatToken = useSelector((state: any) => state.chat.chatToken)

    useEffect(() => {
        if (!socketConnected) {
            initSocketChat({ onMessage }, () => {
                dispatch(setSocketConnected(true))
            })
        }

        if (!initialLoaded) {
            refreshRooms()
        }
    }, [])

    const refreshRooms = () => new Promise((resolve, reject) => {
        getRooms().then((res: any) => {
            dispatch(setRooms(res.data.rooms))
            if (!initialLoaded)
                dispatch(setInitialLoaded(true))

            dispatch(setChatToken(res.data.token))
            resolve(true)
        }).catch((err: any) => {
            console.log(err)
            toast.error("Error while loading chat rooms")
            reject(false)
        })
    })

    useEffect(() => {
        if (socketConnected && initialLoaded) {
            joinChat(chatToken)
            tokenRetried = false
        }
    }, [initialLoaded, socketConnected])

    useEffect(() => {
        if (initialLoaded && chatWidgetVisible) {
            refreshRooms()
            tokenRetried = false
        }
    }, [chatWidgetVisible])

    useEffect(() => {
        if (currentPage === MainParts.Chat && initialLoaded) {
            refreshRooms()
            tokenRetried = false
        }
    }, [currentPage])

    const joinRoom = (room: any) => {
        dispatch(setCurrentChat(room.room))
        dispatch(setCurrentChatName(room.username))

    }

    useEffect(() => {

        if (currentChat) {
            loadRoom(currentChat)
        }else{
            setScrollWasAtBottom(true)
        }

    }, [currentChat])

    const loadRoom = (roomId: string, before?: string) => new Promise((resolve, reject) => {
        getRoomHistory(roomId, before).then((res: any) => {
            if (!before)
                dispatch(setMessages(res.data.chats))
            else
                dispatch(setMessages([...res.data.chats, ...messages]))
            tokenRetried = false

            resolve(true)
        }).catch((err: any) => {
            if (err.response.status === 403) {
                if (!tokenRetried) {
                    refreshRooms().then(() => {
                        tokenRetried = true
                        loadRoom(roomId, before)
                    })
                } else {
                    toast.error("Error while loading chat history")
                }
            } else
                toast.error("Error while loading chat history")

            reject(false)
        })
    })

    return (
        <C.Chat>
            {currentPage !== MainParts.Chat && (
                <C.Header>
                    <C.HeaderTitle>Chat</C.HeaderTitle>
                    <C.Actions>
                        <C.Actiona href="/app/chat" target="_blank"><FontAwesomeIcon icon={faExternalLink} /></C.Actiona>
                        <C.Action onClick={() => hide()}>&times;</C.Action>
                    </C.Actions>
                </C.Header>
            )}
            <C.ChatWrapper expanded={(currentPage === MainParts.Chat && !isMobile) ? "true" : "false"} viewingchat={currentChat !== null ? "true" : "false"}>
                <C.Chats >
                    {(!initialLoaded || !socketConnected) && (
                        <C.ChatsLoading>
                            <FontAwesomeIcon icon={faSpinner} spin />
                        </C.ChatsLoading>
                    )}
                    {rooms.map((item: any, key: any) => (
                        <C.ChatItem onClick={() => joinRoom(item)} key={key}>
                            <C.ChatItemLeft>
                                <C.ChatItemAvatar>
                                    <img src={getAvatar(item.avatar)} alt="avatar" />
                                </C.ChatItemAvatar>
                                <C.ChatItemContent>
                                    <C.ChatItemName>
                                        {item.username}'s Room
                                    </C.ChatItemName>
                                    <C.ChatItemLastMessage>
                                        {item.lastMessage ? item.lastMessage.data : ""}
                                    </C.ChatItemLastMessage>
                                </C.ChatItemContent>
                            </C.ChatItemLeft>
                            <C.ChatItemRight>
                                {/*
                                <C.ChatItemUnreadCount>
                                    99+
                                </C.ChatItemUnreadCount>
                                */}
                                <C.ChatItemArrow>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </C.ChatItemArrow>
                            </C.ChatItemRight>
                        </C.ChatItem>
                    ))}
                    {initialLoaded && rooms.length === 0 && (
                        <C.ChatItemEmpty>
                            <C.ChatItemEmptyContent>
                                <C.ChatItemEmptyImage>
                                    <img src="/images/etc/no-chat.png" alt="no room found" />
                                </C.ChatItemEmptyImage>

                                <C.ChatItemEmptyTitle>No Rooms Found</C.ChatItemEmptyTitle>
                                <C.ChatItemEmptyText>
                                    You need a key to join a room. You don't have any key yet.
                                </C.ChatItemEmptyText>

                                <Button link="routerlink" to="/app/shares" theme="gradient" bg={Hex2Rgba(color.white, .05)} color={color.white} onClick={() => hide(false)} >Buy Some Keys</Button>
                            </C.ChatItemEmptyContent>
                        </C.ChatItemEmpty>
                    )}
                </C.Chats>
                <C.ChatView>
                    {currentChat !== null && (
                        <>
                            <C.ChatHeader>
                                <C.ChatHeaderBack onClick={() => dispatch(setCurrentChat(null))}>
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </C.ChatHeaderBack>
                                <C.ChatHeaderTitle>{currentChatName}'s Room</C.ChatHeaderTitle>
                            </C.ChatHeader>
                            <C.ChatBody ref={chatBody} onScroll={handleScroll}>

                                {messages.map((item: any, key: any) => (
                                    <C.ChatMessageContainer key={key}>
                                        <C.ChatMessage from={item.from === user.id ? "me" : 'other'}>
                                            <C.ChatMessageBody>
                                                {item.from !== user.id && (
                                                    <>
                                                        {item.senderData.isIssuer && (
                                                            <C.ChatMessageSenderLink to={"/app/share/" + item.from}>{item.senderData.userName}</C.ChatMessageSenderLink>
                                                        )}
                                                        {!item.senderData.isIssuer && (
                                                            <C.ChatMessageSenderA target="_blank" href={"https://twitter.com/" + item.senderData.userName}>{item.senderData.userName}</C.ChatMessageSenderA>
                                                        )}
                                                    </>
                                                )}
                                                <C.ChatMessageText>{item.data}</C.ChatMessageText>
                                            </C.ChatMessageBody>
                                            <C.ChatMessageTime>{moment(item.createdAt).fromNow()}</C.ChatMessageTime>
                                        </C.ChatMessage>
                                    </C.ChatMessageContainer>
                                ))}

                            </C.ChatBody>
                            <C.ChatSend>
                                <C.ChatSendInput placeholder="Type a message" ref={messageInput} onKeyPress={onKeyPress} />
                                <C.ChatSendButton onClick={handleSendMessage}>
                                    <C.ChatSendButtonIcon>
                                        <FontAwesomeIcon icon={faPaperPlane} />
                                    </C.ChatSendButtonIcon>
                                </C.ChatSendButton>
                            </C.ChatSend>
                        </>
                    )}

                    {currentChat === null && (
                        <C.ChatViewEmpty>
                        </C.ChatViewEmpty>
                    )}
                </C.ChatView>
            </C.ChatWrapper>
        </C.Chat>
    )
}

export default Chat