import React, { useEffect, useState } from "react"
import * as C from "./style"
import { faDiscord, faTwitter } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Badge, { Badges } from "components/ui/badge"
import Button from "components/ui/button"
import { color } from "styles/theme"
import { Hex2Rgba, customSelectStyles, getAvatar } from "utils/helpers"
import Input from "components/ui/input"
import Select from 'react-select'
import { useDispatch, useSelector } from "react-redux"
import DropdownButton, { DropdownItem } from "components/ui/button/dropdown"
import { closeAll, displayEditProfile, displaySettings } from "utils/parts"
import env from "env"
import { useNavigate } from "react-router-dom"
import ReactTooltip from "react-tooltip"
import toast from "react-hot-toast"
import { faChevronCircleDown, faChevronDown, faEllipsisVertical, faExternalLink, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"
import Linkify from 'react-linkify'
import { IconTokens } from "utils/icons"

const ProfileInfo = (props: any) => {

    const [selectedBadges, setSelectedBadges] = useState<any>([]);
    const navigate = useNavigate()
    const tokens = useSelector((state: any) => state.data.tokens)

    var user = props.user

    const onEditClick = () => {
        displayEditProfile(true)
        //setSelectedBadges([])
        //setEditMode(!editMode)
    }

    const editLocation = () => {
        closeAll() // close all parts
        navigate("/app/set-location")
    }

    useEffect(() => {
        ReactTooltip.rebuild()
        ReactTooltip.hide()
    }, [])

    const copyDiscord = () => {

        if (user.discord) {
            navigator.clipboard.writeText(user.discord)
            toast.success("Copied to clipboard")
        }
    }

    const copyProfileUrl = () => {
        navigator.clipboard.writeText(`${env.API.WEB}app/profile/${user.id}`)
        toast.success("Copied to clipboard")
    }

    const tokenDropdownRef = React.useRef<any>(null);
    const [tokenDropdownOpen, setTokenDropdownOpen] = useState(false)

    const openTokenDropdown = () => {
        if (!tokenDropdownOpen)
            setTokenDropdownOpen(true)
        else
            setTokenDropdownOpen(false)
    }

    const handleClickOutside = (event: any) => {

        if (tokenDropdownRef.current && !tokenDropdownRef.current.contains(event.target)) {
            setTokenDropdownOpen(false);
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [])

    return (
        <>
            <C.ProfileInfo bg={user.cover ? env.API.CDN + "covers/" + user.cover : ""}>

                <C.ProfileMainStats>
                    <C.ProfileImage>
                        <img src={getAvatar(user.avatar)} alt="Profile Image" />
                    </C.ProfileImage>
                    <C.ProfileNameDesc>
                        <C.ProfileName>
                            {user.username}
                        </C.ProfileName>
                        {user.status && (
                            <C.ProfileStatus>
                                <C.ProfileStatusContent>
                                    {user.status}
                                </C.ProfileStatusContent>
                            </C.ProfileStatus>
                        )}



                    </C.ProfileNameDesc>
                </C.ProfileMainStats>


                <C.ProfileSocialAndActions>


                    <C.ProfileSocialSingle>
                        {user.twitter && (
                            <C.ProfileSocialSingleItem target="_blank" href={"https://twitter.com/" + user.twitter} data-tip="Go to Twitter Account">
                                <C.ProfileSocialSingleItemIcon hc="#1DA1F2">
                                    <FontAwesomeIcon icon={faTwitter} />
                                </C.ProfileSocialSingleItemIcon>
                            </C.ProfileSocialSingleItem>
                        )}
                        {user.discord && (
                            <C.ProfileSocialSingleItemNoLink data-tip="Copy to clipboard" onClick={copyDiscord}>
                                <C.ProfileSocialSingleItemIcon hc="#5865F2">
                                    <FontAwesomeIcon icon={faDiscord} />
                                </C.ProfileSocialSingleItemIcon>
                            </C.ProfileSocialSingleItemNoLink>
                        )}
                    </C.ProfileSocialSingle>


                    {props.self && (
                        <C.ProfileActions>
                            <DropdownButton text="" buttonStyle={{ theme: "gradient-border-transparent-bg-on-hover", bg: Hex2Rgba(color.secondaryFade, 1), color: "white" }} icon={<FontAwesomeIcon icon={faEllipsisVertical} />}>
                                <DropdownItem onClick={onEditClick}>
                                    Edit Profile
                                </DropdownItem>
                                <DropdownItem onClick={editLocation}>
                                    Edit Location
                                </DropdownItem>
                                <DropdownItem onClick={displaySettings}>
                                    Settings
                                </DropdownItem>
                                <DropdownItem onClick={copyProfileUrl}>
                                    Copy Url
                                </DropdownItem>
                            </DropdownButton>
                        </C.ProfileActions>
                    )}

                </C.ProfileSocialAndActions>
            </C.ProfileInfo>

            {props.self && (
            <C.ProfileMobileActions>
                <DropdownButton text="Actions" buttonStyle={{ theme: "gradient-border-transparent-bg-on-hover", bg: Hex2Rgba(color.secondaryFade, 1), color: "white" }} >
                    <DropdownItem onClick={onEditClick}>
                        Edit Profile
                    </DropdownItem>
                    <DropdownItem onClick={editLocation}>
                        Edit Location
                    </DropdownItem>
                    <DropdownItem onClick={() => navigate("/app/settings")}>
                        Settings
                    </DropdownItem>
                    <DropdownItem onClick={copyProfileUrl}>
                        Copy Url
                    </DropdownItem>
                </DropdownButton>
            </C.ProfileMobileActions>
            )}

            <C.ProfileInfoBottom>
                <C.ProfileInfoBottomTop>

                    <C.Badges>
                        {user.badges.map((badge: any, index: any) => (
                            <Badge type={badge} key={index} />
                        ))}
                    </C.Badges>

                    <C.ProfileDescription mobile="true">
                    <Linkify
                        componentDecorator={(decoratedHref, decoratedText, key) => (
                            <a className="extlink" target="blank" href={decoratedHref} key={key}>
                                {decoratedText} <FontAwesomeIcon style={{ fontSize: 10 }} icon={faExternalLink} />
                            </a>
                        )}
                    >
                        {user.description}
                    </Linkify>
                </C.ProfileDescription>

                    {user.tokens && user.tokens.length > 0 && (
                        <C.OwnedTokens ref={tokenDropdownRef} dropdownOpen={tokenDropdownOpen} onClick={openTokenDropdown}>
                            <C.OwnedTokensButton>
                                <C.OwnedTokensIcon>
                                    <IconTokens />
                                </C.OwnedTokensIcon>
                                <C.OwnedTokensText>
                                    Owned Tokens
                                </C.OwnedTokensText>
                                <C.OwnedTokensArrow>
                                    <FontAwesomeIcon icon={faChevronDown} />
                                </C.OwnedTokensArrow>
                            </C.OwnedTokensButton>
                            <C.OwnedTokensContent>
                                {user.tokens.map((token: any, i: any) => {
                                    const tokenData = tokens.find((t: any) => t.id === token);
                                    if (!tokenData) return <></>;
                                    return (
                                        <C.OwnedTokensContentItem key={i}>
                                            <C.OwnedTokensContentItemIcon>
                                                <img src={`${env.API.CDN}tokens/covers/${tokenData.cover}`} alt="ETH" />
                                            </C.OwnedTokensContentItemIcon>
                                            <C.OwnedTokensContentItemName>
                                                {tokenData.name} <span>${tokenData.symbol}</span>
                                            </C.OwnedTokensContentItemName>
                                        </C.OwnedTokensContentItem>
                                    )
                                })}
                            </C.OwnedTokensContent>
                        </C.OwnedTokens>
                    )}

                </C.ProfileInfoBottomTop>


                <C.ProfileDescription mobile="false">
                    <Linkify
                        componentDecorator={(decoratedHref, decoratedText, key) => (
                            <a className="extlink" target="blank" href={decoratedHref} key={key}>
                                {decoratedText} <FontAwesomeIcon style={{ fontSize: 10 }} icon={faExternalLink} />
                            </a>
                        )}
                    >
                        {user.description}
                    </Linkify>
                </C.ProfileDescription>
            </C.ProfileInfoBottom>
        </>
    )
}

export default ProfileInfo