import Confetti from 'react-confetti'
import { useDispatch, useSelector } from "react-redux"
import { setGlobalConfettiShowing } from 'services/slices/data'
import { color } from 'styles/theme'

const GlobalConfetti = () => {

    const dispatch = useDispatch()
    const showConfetti = useSelector((state: any) => state.data.globalConfettiShowing)

    return (
        <div>
            {showConfetti &&
                <Confetti
                    style={{ zIndex: 99 }}
                    gravity={0.25}
                    onConfettiComplete={() => dispatch(setGlobalConfettiShowing(false))}
                    colors={[
                        color.gradient3.start,
                        color.gradient3.end,
                        color.primary,
                        color.secondary,
                    ]}
                />
            }
        </div>
    )
}

export default GlobalConfetti