import React from "react"
import * as C from "./style"

import { useDispatch, useSelector } from "react-redux"
import Button from "components/ui/button"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { showIntroductionPopup, showWelcomePopup } from "services/slices/popup"
import { useNavigate } from "react-router-dom"
import { displayCloudWallet } from "utils/parts"

const Welcome = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector((state: any) => state.user.user)

    const close = () => {
        dispatch(showWelcomePopup(false))

        if (user && !user.firstTime.intro) {
            dispatch(showIntroductionPopup(false))
        }
    }

    const setLocation = () => {
        dispatch(showWelcomePopup(false))
        displayCloudWallet(true)
    }

    return (
        <C.Welcome>
            
            <img src="/images/etc/welcome.png" alt="welcome" />
            <C.Title>Getting Started</C.Title>
            <C.Text>
            Welcome! Begin your journey by depositing <span>SOL</span> to your <span>cloud wallet</span>. Once deposited, you'll be all set to purchase keys from other users. Dive in and explore!
            </C.Text>
            <C.Actions>
                <Button theme="gradient-border-transparent-bg-on-hover" bg={Hex2Rgba(color.white, .05)} color="white" onClick={close}>Close</Button>
                <Button theme="gradient" bg={Hex2Rgba(color.secondaryFade, .5)} color="white" onClick={setLocation}>Deposit</Button>
            </C.Actions>
        </C.Welcome>
    )
}

export default Welcome