import Collection from "components/widgets/collection"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { ManageHolderStates } from "services/managers/holders"
import { setCurrentViewingCollection, setCurrentViewingCollectionCode } from "services/slices/holders"
import * as C from "./style"

const CurrentCollection = () => {

    const dispatch = useDispatch()

    const collections = useSelector((state: any) => state.data.collections)
    const currentViewingCollection = useSelector((state: any) => state.holders.currentViewingCollection)
    const [collection, setCollection] = useState<any>(null)
    const holders = useSelector((state: any) => state.holders.holders)
    const navigate = useNavigate()
    const holdersCount = useSelector((state: any) => state.holders.count)

    useEffect(() => {
        if (currentViewingCollection) {
            setCollection(collections.find((c: any) => c.id == currentViewingCollection))
        }
    }, [currentViewingCollection, collections])

    useEffect(() => {


        
    }, [holders])

    const close = () => {
        dispatch(setCurrentViewingCollection(null))
        dispatch(setCurrentViewingCollectionCode(null))
        navigate("/app")
    }

    return (
        <C.CurrentCollection>
            <C.Header>
                <C.Title>
                    Filtered by Nft Collection Owners {holdersCount > 0 ? `(${holdersCount})` : ""}
                </C.Title>
                <C.Times onClick={() => close()}>
                    &times;
                </C.Times>
            </C.Header>
            <C.Item>
                <Collection alert={false} social={true} stats={false} item={collection} />
            </C.Item>
        </C.CurrentCollection>
    )
}

export default CurrentCollection