import { useSelector } from "react-redux"
import * as P from "./style"

import BasePage from "components/pages/base"
import { IconCollections, IconWhitelist } from "utils/icons"

import { MainParts } from "utils/parts"
import WhitelistCollections from "components/parts/whitelistCollections"

const Page = () => {

    const show = useSelector((state: any) => state.data.currentPage)

    
    return (
        <P.Page>
            <BasePage title="Whitelist Collections" show={show === MainParts.WhitelistCollections} icon={<IconWhitelist />} glasmorph={true}>
                <WhitelistCollections />
            </BasePage>
        </P.Page>
    )
}

export default Page