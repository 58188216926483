import { PublicKey, LAMPORTS_PER_SOL, Connection, Transaction, ComputeBudgetProgram, Keypair } from '@solana/web3.js'
import * as anchor from "@project-serum/anchor"
import { SOLANA_CONNECTION, SOLANA_CONNECTION_SHARE, WaitForTransaction } from '../connection'
import { anchorWallet } from '../wallet'
import { IDL } from './types'
import env from 'env'
import { getShareHoldingAccount } from './helpers'

export const getIssuerAccountData = async (issuer: PublicKey, cloudWalletPubkey?: PublicKey) => new Promise(async (resolve, reject) => {

    try {

        const anchorConnection = new anchor.AnchorProvider(SOLANA_CONNECTION_SHARE, anchorWallet(), anchor.AnchorProvider.defaultOptions())
        const program = new anchor.Program(IDL as anchor.Idl, env.WEB3.SOLANA.SHARE_PROGRAM, anchorConnection)

        var account: any;

        try {
            account = await program.account.issuer.fetch(issuer)
        } catch (e) {
            return resolve(404)
        }

        var holdingAccount: any = null

        if (cloudWalletPubkey) {
            try {
                const shareHoldingAddress = getShareHoldingAccount(cloudWalletPubkey, account.issuerKey)
                holdingAccount = await program.account.shareHolding.fetch(shareHoldingAddress)
            } catch (e) { }
        }

        return resolve({
            account,
            holdingAccount
        })

    } catch (e) {
        reject(500)
    }

})

export const getMyHoldings = async (cloudWalletPubkey: string) => new Promise(async (resolve, reject) => {
    try {

        const anchorConnection = new anchor.AnchorProvider(SOLANA_CONNECTION_SHARE, anchorWallet(), anchor.AnchorProvider.defaultOptions())
        const program = new anchor.Program(IDL as anchor.Idl, env.WEB3.SOLANA.SHARE_PROGRAM, anchorConnection)

        var accounts: any = []

        try {
            accounts = await program.account.shareHolding.all([
                {
                    memcmp: {
                        offset:8,
                        bytes: cloudWalletPubkey
                    }
                }
            ])

        } catch (e) {
        }

        return resolve(accounts.filter((account: any) => account.account.amount.toString() !== "0"))

    } catch (e) {
        resolve([])
    }
})