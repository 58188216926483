export default {
    API:{
        URL:"https://api.hub3.gg/api/",
        ROOT:"https://api.hub3.gg/",
        SOCKET:"https://api.hub3.gg/",
        SOCKET_CHAT:"https://ws.hub3.ee/",
        CDN:"https://hub3cdn.b-cdn.net/",
        WEB:"https://beta.hub3.gg/",
        PUBKEY:"2iziJJPr8a6Rb21KiRGANz44buqhbVNCAe1P5CsnqLde"
    },
    WEB3:{
        SOLANA:{
            RPC:"https://rpc.helius.xyz/?api-key=3929efa1-32e5-4164-ba40-f1d6c115f2c5",
            RPC_SHUFFLE:"https://metaplex.devnet.rpcpool.com",
            RPC_MINT:"https://rpc.helius.xyz/?api-key=65ba2801-42a5-44a8-b301-c99e16f89ed8",
            RPC_SHARE:"https://api.devnet.solana.com",
            SHARE_PROGRAM:"2pi53pUUC5S4zyUU6Wrbe6EfYXS9LNcpikpwPFahtQQw"
        },
        APTOS:{
            NODE:"https://aptos-mainnet.nodereal.io/v1/8adffad1aafb49df88829283f237eb73",
            FAUCET:"https://faucet.mainnet.aptoslabs.com/v1/",
            GRAPHQL:"https://aptos-indexer-mainnet.blockeden.xyz/WVjkZjgtdhZuEKX4iJJT/v1/graphql"
        }
    },
    SECRET:{
        LOCAL_STORAGE:"o8VT5Y7weTaZ9X2C4s6ni3Q1b0mUjWgG",
    },
    API_KEYS:{
        MAPBOX: "pk.eyJ1Ijoib2d1emhhbmthcmFkYXlpMTciLCJhIjoiY2xjdzdjZDc3MDYybzNwcDdmZzM5czRraCJ9.PAw6vT5idInTWl28L-s8_g"
    },
    DISCORD:{
        client_id:"1036048434605596682",
    },
    WEB3AUTH: {
        CLIENT_ID: "BKPlkI3BSpBrWhS8UBLA1sgdEVaH8mfudm2tWfJm-HhcZHVomkjORvU0ZDWqMEcwF1L-Eze3OnPWRGq62FpN6s8",
        chainId: "0x3",
        rpcTarget: "https://api.devnet.solana.com",
        displayName: "Solana Devnet",
        blockExplorer: "https://explorer.solana.com",
        ticker: "SOL",
        tickerName: "Solana Token",
        web3AuthNetwork:"sapphire_devnet",
        loginConfig:{
            verifier: "h3-twitter-test", 
            typeOfLogin: "twitter", 
            clientId: "81Ptbhlm5DqaPL9vvKfTyouxOmN29ysV",
            domain:"https://dev-kyh4tf5ngkeyozep.us.auth0.com" 
        }
    }
}