import styled, { keyframes } from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { deviceMax } from "styles/media"
import { Link } from "react-router-dom"

export const ShuffleHistory = styled.div`
    
`

export const Loading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 24px;
    margin:32px 0;
`

export const ItemStatus = styled.div<{status:string}>`
    font-size:12px;
    border-radius: 32px;
    display: inline-block;
    padding: 4px 8px;
    font-weight: 600;
    margin-top: 8px;

    ${props => props.status === "active" && `
        color: ${color._badgeDesignerText};
        background: ${color._badgeDesignerBg};
    `}

    ${props => props.status === "ended" && `
        color: ${color._badgeMarketingText};
        background: ${color._badgeMarketingBg};
    `}

    ${props => props.status === "drawed" && `
        color: ${color._badgeCollabManagerText};
        background: ${color._badgeCollabManagerBg};
    `}
`

export const Item = styled.div`
    background: ${Hex2Rgba(color.black, 0.64)};
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    &:hover {
        background: ${Hex2Rgba(color.black, 1)};
    }
`

export const ItemId = styled.div`
    color: ${color.whiteShade};
    font-size: 14px;
`

export const InnerLeft = styled.div`
`

export const ItemDate = styled.div`
    color: ${color.primary};
`

export const ItemTickets = styled.div`
    text-align: center;
`

export const ItemTicketAmount = styled.div`
    color: ${color.whiteShade};
    font-size: 14px;
`

export const ItemTicketWin = styled.div`
    color: ${color.primary};
    font-size: 14px;
`

export const ItemLeft = styled.div`
    flex:1;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const ItemRight = styled.div`
    font-size: 14px;
    padding-left: 16px;
`


export const ViewSwitch = styled.div`
    display: flex;
    justify-content: space-between;
    background: ${Hex2Rgba(color.secondary, 0.16)};
    padding:4px;
    border-radius: 8px;
    margin-bottom: 16px;

    & > div:not(:last-child) {
        margin-right: 8px;
    }
`

export const SwitchItem = styled.div<{active?:string}>`
    text-align: center;
    flex:1;
    ${props => props.active === "true" && `
        background: ${Hex2Rgba(color.secondary, 0.16)};
    `}
    cursor: pointer;
    border-radius: 8px;
    padding: 4px 0;
    font-size: 14px;
    &:hover {
        background: ${Hex2Rgba(color.secondary, 0.16)};
    }
`