import React from "react"
import CollectionsPage from "components/pages/collections"
import AnalyticsPage from "components/pages/analytics"
import ProfilePage from "components/pages/profile"
import WhitelistCollectionsPage from "components/pages/whitelistCollections"
import LaunchesPage from "components/pages/launches"
import ShuffleLiquidationPage from "components/pages/shuffleLiquidation"
import Shuffle from "components/pages/shuffle"
import Share from "components/pages/share"
import Shares from "components/pages/shares"
import Chat from "components/pages/chat"

import { useSelector } from "react-redux"

const Pages = () => {

    const isMobile = useSelector((state: any) => state.data.isMobile)

    if (isMobile)
        return (<></>)

    return (
        <>
            <CollectionsPage />
            {/*<AnalyticsModal />*/}
            <ProfilePage />
            <WhitelistCollectionsPage />
            <LaunchesPage />
            <ShuffleLiquidationPage />
            <Shuffle />
            <Share />
            <Shares />
            <Chat />
            
        </>
    )
}

export default Pages