import styled from "styled-components"
import { color } from 'styles/theme'
import { Hex2Rgba } from "utils/helpers"

export const Map = styled.div`
    height:100vh;
    overflow:hidden;
    & .mapboxgl-popup{
        max-width: 700px !important;
        width:100%;
        height:auto !important;
        transition:height 0.5s;

        & .mapboxgl-popup-content{
            height:100%;
            width:100%;
            /*backdrop-filter: blur(20px);*/
            background: unset;
            border-radius:8px;
            padding:0px;
        }

        & .popup{
            height:215px;
            overflow:hidden;
        }

        & .popup.active{
            height:490px;
        }

        & .mapboxgl-popup-close-button{
            color:${color.white} !important;
            font-size:20px;
        }
    }

    & .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip{
        border-top-color: rgba(18, 18, 18, .75) !important;
    }

    & .mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip{
        border-top-color: rgba(18, 18, 18, .75) !important;
    }

    & .mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip{
        border-top-color: rgba(18, 18, 18, .75) !important;
    }

    & .mapboxgl-popup-anchor-right .mapboxgl-popup-tip{
        border-left-color: rgba(18, 18, 18, .75) !important;
    }

    & .mapboxgl-popup-anchor-left .mapboxgl-popup-tip{
        border-right-color: rgba(18, 18, 18, .75) !important;
    }

    & .mapboxgl-popup-anchor-top .mapboxgl-popup-tip{
        border-bottom-color: rgba(18, 18, 18, .75) !important;
    }

    & .mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip{
        border-bottom-color: rgba(18, 18, 18, .75) !important;
    }

    & .mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip{
        border-bottom-color: rgba(18, 18, 18, .75) !important;
    }

    & canvas {
        width:100% !important;
        height:100% !important;
    }

`

export const Error = styled.div`
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    background:${Hex2Rgba(color.bg1, .64)};
    padding:20px;
    border-radius:8px;
    text-align:center;
`