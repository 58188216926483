import { faGem } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/ui/button'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { showTreasurePopup } from 'services/slices/popup'
import { color } from 'styles/theme'
import { Hex2Rgba } from 'utils/helpers'
import { IconTreasuryHunt, IconTiplink } from 'utils/icons'
import * as C from './style'

const Treasure = () => {

    const treasure = useSelector((state: any) => state.data.currentViewingTreasure)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const hide = () => {
        dispatch(showTreasurePopup(false))
        navigate("/app")
    }

    return (
        <C.Treasure>
            <C.Hide onClick={hide}>&times;</C.Hide>
            <C.TreasureMapIcon>
                <IconTreasuryHunt />
            </C.TreasureMapIcon>
            <C.TreasureMapTitle>You Found a Treasure</C.TreasureMapTitle>
            <C.TreasureMapText>
                {treasure.description}
            </C.TreasureMapText>

            {treasure.type === "TIPLINK" && (
                <C.TreasureMapLink>
                    <Button theme="gradient" fromcolor={color.gradient3.start} tocolor={color.gradient3.end} color={color.black} bg={Hex2Rgba(color.black, .32)} link="link" href={treasure.link} target="_blank" rel="noreferrer">
                        <IconTiplink /> Claim TipLink
                    </Button>
                </C.TreasureMapLink>
            )}
        </C.Treasure>
    )

}

export default Treasure 