import React, { useEffect, useState } from "react"
import * as C from "./style"
import Button from "components/ui/button"
import { color } from "styles/theme"
import { Hex2Rgba, hex2a } from "utils/helpers"
import { getAllNftsOfUserSolana } from "modules/solana/nft"
import { store } from "services/store"
import { ViewNft } from "utils/parts"
import env from "env"
import { Chain, Chains } from "models/enums/network"
import { getAllNftsOfUserAptos } from "modules/aptos/nft"
import { setCurrentViewingNftList, updateImageOnViewingNftList } from "services/slices/data"
import { useDispatch, useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import axios from "axios"
import { State } from "models/enums/state"

const Nfts = (props: any) => {

    const nfts = useSelector((state: any) => state.data.currentViewingNftList)
    const allCollections = useSelector((state: any) => state.data.collections)
    const [usersCollections, setUsersCollections] = useState<any>([])
    const [usersChains, setUsersChains] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(true)
    const me = useSelector((state: any) => state.user.user)

    const [currentCollection, setCurrentCollection] = useState("")
    const [currentChain, setCurrentChain] = useState("")
    const dispatch = useDispatch()

    useEffect(() => {

        dispatch(setCurrentViewingNftList({}))

        if (!props.user.collections) {
            setLoading(false)
            return
        }

        //getAptos()
        getSolana()

        list("all", "all")
    }, [allCollections])

    const getAptos = async () => {

        if (!props.user.collections)
            return

        const collections = store.getState().data.collections
        const userCollections = collections.filter((collection: any) => props.user.collections.includes(collection.id))

        if (userCollections.length === 0) return

        getAllNftsOfUserAptos(props.user.wallets, userCollections.map((x) => x.identity)).then(async (_nfts: any) => {

            let validNfts: any = {}

            _nfts.forEach((nft: any) => {

                //console.log(nft)

                let collection = userCollections.find((collection) => collection.identity?.includes(nft.creator_address) && collection.identity?.includes(nft.collection_name))

                if (collection) {
                    let id: any = collection.id

                    if (typeof validNfts[id] === "undefined") validNfts[id] = []

                    let traits: any = []

                    if (nft.current_token_data.default_properties) {

                        for (const [key, value] of Object.entries(nft.current_token_data.default_properties)) {
                            traits.push({
                                trait_type: key,
                                value: hex2a(value as string)
                            })
                        }

                    }

                    let uri = nft.current_token_data.metadata_uri
                    //check uri is ipfs or not
                    if (uri.includes("ipfs://")) {
                        uri = uri.replace("ipfs://", "https://ipfs.io/ipfs/")
                    }

                    validNfts[id].push({
                        identity: collection.identity![0],
                        uri: {
                            image: uri,
                            name: nft.current_token_data.name,
                            traits: traits,
                            uri: uri
                        },
                        collection: {
                            id: collection.id,
                            chain: collection.chain,
                        },
                        image: null,
                    })
                }

            })

            dispatch(setCurrentViewingNftList({ ...store.getState().data.currentViewingNftList, ...validNfts }))

            for (let collection in validNfts) {
                for (var i = 0; i < validNfts[collection].length; i++) {
                    let nft = validNfts[collection][i]
                    const index = i
                    if (nft.image === null) {
                        //load image, image may be a json or a png. if it's a json, we need to get the image property from the json
                        axios.get(nft.uri.uri).then((res) => {
                            if (res.data.image) {
                                let imageObj = new Image()
                                imageObj.src = res.data.image
                                imageObj.onload = function () {
                                    dispatch(updateImageOnViewingNftList({
                                        id: collection,
                                        index,
                                        //@ts-ignore
                                        image: this.src
                                    }))
                                }
                                imageObj.onerror = function () {
                                    dispatch(updateImageOnViewingNftList({
                                        id: collection,
                                        index,
                                        //@ts-ignore
                                        image: ""
                                    }))
                                }
                            } else {
                                dispatch(updateImageOnViewingNftList({
                                    id: collection,
                                    index,
                                    //@ts-ignore
                                    image: nft.uri.uri
                                }))
                            }
                        }).catch((err) => {
                            dispatch(updateImageOnViewingNftList({
                                id: collection,
                                index,
                                //@ts-ignore
                                image: ""
                            }))
                        })

                    }
                }
            }

            setLoading(false)

        })
    }

    const getSolana = async () => {

        if (!props.user.collections)
            return

        const collections = store.getState().data.collections
        const userCollections = collections.filter((collection: any) => props.user.collections.includes(collection.id))
        //order user collections by updatedAt
        userCollections.sort((a: any, b: any) => {
            return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        })
        setUsersCollections(userCollections)

        //get all chains that user has collections on
        let chains: any = []
        userCollections.forEach((collection: any) => {
            if (!chains.includes(collection.chain)) chains.push(collection.chain)
        })
        setUsersChains(chains)

        if (userCollections.length === 0) return

        getAllNftsOfUserSolana(props.user.wallets).then((_nfts: any) => {

            let validNfts: any = {}

            _nfts.forEach((nft: any) => {

                for (const creator of nft.creators) {

                    let collection = userCollections.find((collection) => collection.identity?.includes(creator.address.toString()))

                    if (collection) {

                        let id: any = collection.id

                        if (typeof validNfts[id] === "undefined") validNfts[id] = []

                        validNfts[id].push({
                            identity: nft.mintAddress.toString(),
                            uri: nft.uri,
                            collection: {
                                id: collection.id,
                                chain: collection.chain,
                            },
                            image: null
                        })
                        break
                    }
                }
            })

            //setNfts({ ...nfts, ...validNfts })
            dispatch(setCurrentViewingNftList({ ...store.getState().data.currentViewingNftList, ...validNfts }))

            for (let collection in validNfts) {
                for (var i = 0; i < validNfts[collection].length; i++) {
                    let nft = validNfts[collection][i]
                    const index = i
                    if (nft.image === null) {
                        //load image, image may be a json or a png. if it's a json, we need to get the image property from the json

                        let _collection = userCollections.find((item) => item.id === collection)

                        if (_collection?.caching === State.ACTIVE) {

                            let imageObj = new Image()
                            imageObj.src = env.API.CDN + "collections/images/solana/" + collection + "/" + nft.identity + ".png"
                            imageObj.onload = function () {
                                dispatch(updateImageOnViewingNftList({
                                    id: collection,
                                    index,
                                    //@ts-ignore
                                    image: this.src
                                }))
                            }
                            imageObj.onerror = function () {
                                dispatch(updateImageOnViewingNftList({
                                    id: collection,
                                    index,
                                    //@ts-ignore
                                    image: ""
                                }))
                            }
                        } else {
                            axios.get(nft.uri).then((res) => {

                                if (res.data.image) {
                                    let imageObj = new Image()
                                    imageObj.src = res.data.image
                                    imageObj.onload = function () {
                                        dispatch(updateImageOnViewingNftList({
                                            id: collection,
                                            index,
                                            //@ts-ignore
                                            image: this.src
                                        }))
                                    }
                                    imageObj.onerror = function () {
                                        dispatch(updateImageOnViewingNftList({
                                            id: collection,
                                            index,
                                            //@ts-ignore
                                            image: ""
                                        }))
                                    }
                                } else {
                                    dispatch(updateImageOnViewingNftList({
                                        id: collection,
                                        index,
                                        //@ts-ignore
                                        image: nft.uri
                                    }))
                                }
                            }).catch((e) => {
                                dispatch(updateImageOnViewingNftList({
                                    id: collection,
                                    index,
                                    //@ts-ignore
                                    image: ""
                                }))
                            })
                        }
                    }
                }
            }

            setLoading(false)
        })

    }

    const list = (collection: any, chain: any) => {

        setCurrentChain(chain)
        setCurrentCollection(collection)

    }

    return (
        <C.Nfts>
            <C.NftsHeader>
                <C.NftsTitle>
                    NFT's
                </C.NftsTitle>
                <C.NftsActions>
                    {usersChains.length > 0 && (
                        <>
                            {/*<Button theme={"gradient-border-transparent-bg" + (currentChain !== "all" ? "-on-hover" : '')} bg={Hex2Rgba(color.secondaryFade, .5)} color="white" onClick={() => list(currentCollection, "all")}>All</Button>

                            {usersChains.find((chain: any) => chain === Chains.solana.name) && (
                                <Button theme={"gradient-border-transparent-bg" + (currentChain !== Chains.solana.name ? "-on-hover" : '')} bg={Hex2Rgba(color.secondaryFade, .5)} color="white" onClick={() => list(currentCollection, Chains.solana.name)}>
                                    <img src="/images/chains/solana-icon.png" alt="Solana icon" /> Solana
                                </Button>
                            )}
                            {usersChains.find((chain: any) => chain === Chains.aptos.name) && (
                                <Button theme={"gradient-border-transparent-bg" + (currentChain !== Chains.aptos.name ? "-on-hover" : '')} bg={Hex2Rgba(color.secondaryFade, .5)} color="white" onClick={() => list(currentCollection, Chains.aptos.name)}>
                                    <img src="/images/chains/aptos-icon.png" alt="Aptos icon" /> Aptos
                                </Button>
                            )}*/}
                        </>
                    )}
                </C.NftsActions>
            </C.NftsHeader>
            <C.Collections>
                <C.Collection wide="true" theme={"gradient-border-transparent-bg" + (currentCollection !== "all" ? "-on-hover" : '')} bg={Hex2Rgba(color.secondaryFade, .5)} onClick={() => list("all", currentChain)}>
                    <C.CollectionName >
                        All
                    </C.CollectionName>
                </C.Collection>
                {usersCollections.map((collection: any, index: any) => (
                    <C.Collection theme={"gradient-border-transparent-bg" + (currentCollection !== collection.id ? "-on-hover" : '')} bg={Hex2Rgba(color.secondaryFade, .5)} key={index} onClick={() => list(collection.id, currentChain)}>
                        <C.CollectionImage>
                            <img src={env.API.CDN + "collections/covers/" + collection.cover} alt="Collection Cover" />
                        </C.CollectionImage>
                        <C.CollectionName>
                            {collection.name} <img src={`/images/chains/${collection.chain}-icon.png`} alt={collection.chain + "icon"} />
                        </C.CollectionName>
                    </C.Collection>
                ))}
            </C.Collections>
            <C.NftsContent>
                {loading && (
                    <C.Loading>
                        <FontAwesomeIcon icon={faSpinner} spin />
                    </C.Loading>
                )}

                {!loading && (
                    <>

                        {(!props.user.collections || props.user.collections.length === 0) && (
                            <C.NoNfts>
                                <C.NoNftsImage>
                                    <img src="/images/etc/ghost.png" alt="No NFT's" />
                                </C.NoNftsImage>
                                {(me && me.id === props.user.id) && (

                                    <C.NoNftsText>
                                        You don't have any NFT's yet
                                    </C.NoNftsText>
                                )}

                                {(!me || me.id !== props.user.id) && (
                                    <C.NoNftsText>
                                        This user doesn't have any NFT's
                                    </C.NoNftsText>
                                )}
                            </C.NoNfts>
                        )}

                        {(props.user.collections && props.user.collections.length !== 0) && (() => {

                            let _nfts: any = []

                            if (currentCollection === "all") {
                                for (const collection in nfts) {
                                    _nfts = _nfts.concat(nfts[collection])
                                }
                            } else {
                                if (nfts[currentCollection]) {
                                    _nfts = nfts[currentCollection]
                                }
                            }

                            if (currentChain !== "all") {
                                _nfts = _nfts.filter((nft: any) => nft.collection.chain === currentChain)
                            }

                            return _nfts.map((nft: any, index: any) => (
                                <C.Nft col="6" xxxl="2" xxl="3" xl="3" lg="3" md="3" sm="3" xs="4" onClick={() => { ViewNft(nft) }} key={index}>
                                    <C.NftImage>
                                        {nft.image === null && (
                                            <C.NftImageLoading>
                                                <FontAwesomeIcon icon={faSpinner} spin />
                                            </C.NftImageLoading>
                                        )}
                                        {nft.image !== null && (
                                            <img
                                                src={nft.image}
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null; // prevents looping
                                                    currentTarget.src = "" + env.API.CDN + "etc/broken.png"
                                                }}
                                                alt="NFT Image" />
                                        )}
                                    </C.NftImage>
                                </C.Nft>
                            ))

                        })()}
                    </>
                )}

            </C.NftsContent>
        </C.Nfts>
    )
}

export default Nfts