import React from "react"
import * as P from "./style"

import { useDispatch, useSelector } from "react-redux"
import BasePopup from "components/popups/base"
import { IconCollections, IconFilter } from "utils/icons"

import { showMapFilterPopup } from "services/slices/popup"
import MapFilter from "components/parts/mapFilter"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilter } from "@fortawesome/free-solid-svg-icons"
import { resetFilters } from "services/slices/holders"
import { ManageHolderStates } from "services/managers/holders"


const MapFilterPopup = () => {

    const show = useSelector((state: any) => state.popup.MapFilterPopup)
    const dispatch = useDispatch()

    const onHide = () => {
        dispatch(showMapFilterPopup(false))
        dispatch(resetFilters())
        ManageHolderStates(false, "map filter closed")
    }
    
    return (
        <P.Popup>
            <BasePopup title="Filter Users" show={show} hide={onHide} isredux={false} icon={<IconFilter/>} glasmorph={true}>
                <MapFilter/>
            </BasePopup>
        </P.Popup>
    )
}

export default MapFilterPopup