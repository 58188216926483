import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { User } from 'models/user'
import { hex2a } from 'utils/helpers'

export interface ChatState {
    currentChat: any | null,
    currentChatName: string,
    initialLoaded: boolean,
    socketConnected: boolean,
    messages: any[],
    rooms: any[],
    chatToken: string,
}

const initialState: ChatState = {
    currentChat: null,
    currentChatName: "",
    initialLoaded: false,
    socketConnected: false,
    messages: [],
    rooms: [],
    chatToken: "",
}

export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        setCurrentChat: (state, action: PayloadAction<any | null>) => {
            state.currentChat = action.payload
        },
        setCurrentChatName: (state, action: PayloadAction<string>) => {
            state.currentChatName = action.payload
        },
        setMessages: (state, action: PayloadAction<any[]>) => {
            state.messages = action.payload
        },
        addMessage: (state, action: PayloadAction<any>) => {
            state.messages.push(action.payload)
        },
        setInitialLoaded: (state, action: PayloadAction<boolean>) => {
            state.initialLoaded = action.payload
        },
        setSocketConnected: (state, action: PayloadAction<boolean>) => {
            state.socketConnected = action.payload
        },
        setRooms: (state, action: PayloadAction<any[]>) => {
            state.rooms = action.payload
        },
        setChatToken: (state, action: PayloadAction<string>) => {
            state.chatToken = action.payload
        },
    }
})

export const { setCurrentChat,setCurrentChatName,setMessages,setInitialLoaded,setSocketConnected, setRooms, setChatToken, addMessage} = chatSlice.actions

export default chatSlice.reducer