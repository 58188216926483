import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { Dialog } from "../../base/style"

export const Popup = styled.div<{liquidationType:string}>`
    & ${Dialog} {
        ${props => props.liquidationType === "false" && `
            width: 500px;
        `}

        ${props => props.liquidationType === "token" && `
            width: 550px;
        `}

        ${props => props.liquidationType === "nft" && `
            width: 800px;
        `}
    }
`

