import React, { useEffect, useMemo, useRef } from "react"
import * as C from "./style"
import Button from "components/ui/button"
import { displayAirDrop, displayLoginPopup } from "utils/parts"
import { color } from "styles/theme"
import { IconShuffle } from "utils/icons"
import { Hex2Rgba } from "utils/helpers"
import { getAirdropPoints } from "services/api/profile"
import { useSelector } from "react-redux"

const AirdropPart = () => {

    const [points, setPoints] = React.useState(0)
    const user = useSelector((state: any) => state.user.user)

    useEffect(() => {

        if (user)
            getAirdropPoints().then(res => {
                setPoints(res.data.airDropPoints)
            })

    }, [user])

    return (
        <C.AirdropPart>

            <C.Points>
                <C.Background>
                    <IconShuffle />
                    <C.Number>
                        {points}
                    </C.Number>
                </C.Background>

            </C.Points>

            <C.Title>
                Airdrop Points
            </C.Title>
            <C.Description>
                As more points you have, more chances you get airdrop.
            </C.Description>
            <C.SubTitle>
                How to get points?
            </C.SubTitle>
            <C.Steps>
                <C.StepTitle>
                    Boost Your Transactions! 🚀
                </C.StepTitle>
                <C.Step>
                    • Get 1 point for every 0.1 SOL you transact.
                </C.Step>
                <C.StepTitle>
                    Invite and Thrive! 👯‍♂️
                </C.StepTitle>
                <C.Step>
                    • For every friend you introduce with your invite code, you'll bag 100 points. The more you refer, the more you earn!
                </C.Step>
                <C.StepTitle>
                    Tweet & Treat! 🐦
                </C.StepTitle>
                <C.Step>
                    • Mention Hub3 on Twitter and collect more points.
                </C.Step>
            </C.Steps>
            <C.Actions>
                <Button block="true" theme="gradient-border-transparent-bg-on-hover" bg={Hex2Rgba(color.secondaryFade, .5)} color="white" onClick={() => displayAirDrop(false)}>
                    Okay
                </Button>
                {!user && <Button block="true" theme="gradient-border-transparent-bg-on-hover" bg={Hex2Rgba(color.secondaryFade, .5)} color="white" onClick={() => displayLoginPopup(true)}> Join Now </Button>}

            </C.Actions>
        </C.AirdropPart>
    )

}

export default AirdropPart