import React, { useState, useEffect } from "react"
import * as C from './style'
import { useDispatch, useSelector } from "react-redux"
import { Hex2Rgba, shortenPublicKey } from "utils/helpers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretRight, faChevronRight, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { toast } from "react-hot-toast"
import Button from "components/ui/button"
import { color } from "styles/theme"
import { Network } from "models/enums/network"
import { setWalletConnectSettings } from "services/slices/data"
import { showConnectedWalletSelectPopup, showShuffleLedgerSetupPopup, showWalletConnectPopup } from "services/slices/popup"
import { createLedgerAccount, getAllLedgersOfUser } from "modules/solana/shuffle/liquidity"
import { setShuffleLedger, setShuffleLoadedLedger, setShuffleSelectedLedger } from "services/slices/shuffle"

const ShuffleLedgerSetup = () => {

    const dispatch = useDispatch()
    const selectedWallet = useSelector((state: any) => state.user.selectedWallet)
    const programId = useSelector((state: any) => state.shuffle.programId)
    const treasury = useSelector((state: any) => state.shuffle.treasury)
    const [loading, setLoading] = useState<boolean>(false)

    const initializeLedger = () => {

        if (!selectedWallet) {
            dispatch(setWalletConnectSettings({ network: Network.Solana }))
            dispatch(showWalletConnectPopup(true))
            return;
        }
        setLoading(true)
        createLedgerAccount(programId, treasury).then((res) => {
            /*getAllLedgers(programId, treasury,selectedWallet).then((res) => {
                setLoading(false)
                dispatch(setShuffleLedger(res))
                dispatch(showShuffleLedgerSetupPopup(false))
            }).catch((err) => {
                toast.error("Error Initializing Ledger Account Please Try Again")
                setLoading(false)
            })*/

            getAllLedgersOfUser(programId, treasury, selectedWallet).then(async (res) => {
                if (res.length > 0) {
                    setLoading(false)
                    dispatch(setShuffleLedger(res))
                    dispatch(setShuffleSelectedLedger(null))
                    dispatch(setShuffleLoadedLedger(null))
                    dispatch(showShuffleLedgerSetupPopup(false))
                }
                else {
                    toast.error("Error Initializing Ledger Account Please Try Again (try refreshing the page)")
                    setLoading(false)
                }
            }).catch((err) => {
                toast.error("Error Initializing Ledger Account Please Try Again (try refreshing the page)")
                setLoading(false)
            })

        }).catch((err) => {
            toast.error(err)
            setLoading(false)
        })

    }

    const chanceWallet = () => {
        dispatch(showShuffleLedgerSetupPopup(false))
        dispatch(showConnectedWalletSelectPopup(true))
    }

    return (
        <C.ShuffleLedgerSetup>

            <C.LedgerImage>
                <img src="/images/shuffle/no-ledger.png" alt="no ledger" />
            </C.LedgerImage>

            <C.LedgerTitle>
                You need a Liquidator Account
            </C.LedgerTitle>

            <C.LedgerDescription>
                You need a Liquidator Account to be a Shuffle Liquidity Provider. Please initialize your Shuffle Liquidator Account. We will need you to sign a transaction to initialize your account.
            </C.LedgerDescription>

            <C.LedgerButton>
                <Button  theme="gradient-border-transparent-bg-on-hover"  color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)}>Close</Button>
                <Button onClick={initializeLedger} loading={loading ? "true" : "false"} disabled={loading} theme="gradient" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)} >Initialize Liquidator Account</Button>
            </C.LedgerButton>

            <C.LedgerChangeAccount>
                <C.LedgerChangeAccountText  onClick={chanceWallet}>
                    Change Wallet
                </C.LedgerChangeAccountText>
            </C.LedgerChangeAccount>

        </C.ShuffleLedgerSetup>
    )

}

export default ShuffleLedgerSetup