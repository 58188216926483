import { faAngleLeft, faAngleRight, faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "components/ui/button"
import Input from "components/ui/input"
import CollectionWidget from "components/widgets/collection"
import TokenWidget from "components/widgets/token"
import React, { useEffect, useState } from "react"
import { Col, Row } from "styles"
import { copyObject, Hex2Rgba } from "utils/helpers"
import { color } from 'styles/theme'
import * as C from "./style"
import ReactTooltip from "react-tooltip"
import { Chains, Network } from "models/enums/network"
import { Collection } from "models/collection"
import { useDispatch, useSelector } from "react-redux"
import { displayWalletConnectPopup, listHoldersOfCollection } from "utils/parts"
import DropdownButton, { DropdownItem } from "components/ui/button/dropdown"
import { store } from "services/store"
import { showWalletConnectPopup } from "services/slices/popup"
import { set_Collections, setFilterValues, setSearchValue, setPageValue, set_CollectionsPaginated } from "services/slices/_collections"

const perPage = 18;

const CollectionsPart = () => {

    const isMobile = useSelector((state: any) => state.data.isMobile)
    const allCollections = useSelector((state: any) => state.data.collections)
    const userLoaded = useSelector((state: any) => state.user.userLoaded)
    const collections = useSelector((state: any) => state._collections._collections)
    const paginatedCollections = useSelector((state: any) => state._collections._collectionsPaginated)

    const _page = useSelector((state: any) => state._collections.page)
    const _searchText = useSelector((state: any) => state._collections.searchText)
    const _chain = useSelector((state: any) => state._collections.chain)
    const _filter = useSelector((state: any) => state._collections.filter)
    const _sort = useSelector((state: any) => state._collections.sort)

    const searchInput = React.useRef<any>(null)

    const allTokens = useSelector((state: any) => state.data.tokens)

    const dispatch = useDispatch()

    useEffect(() => {
        ReactTooltip.rebuild()
        ReactTooltip.hide()
    }, [])

    useEffect(() => {

        if (allCollections.length > 0 && userLoaded) {
            if (_searchText.length === 0)
                filterItems(_chain, _filter, _sort)
            else
                handleSearch(_searchText)
        }


    }, [allCollections, userLoaded])

    const filterItems = (chain: any, filter: any, sort: any, page = 1) => {

        let user = store.getState().user.user

        let filtered = copyObject(allCollections)

        if (chain !== "all") {
            filtered = filtered.filter((collection: any) => collection.chain === chain)
        }

        if (filter === "Owned" && user) {

            if (user.collections) {
                filtered = filtered.filter((collection: any) => user?.collections?.includes(collection.id))
                //console.log(filtered)
            } else {
                filtered = []
            }

        } else if (filter === "Favorites" && user) {

            if (user.favorites) {
                filtered = filtered.filter((collection: any) => user?.favorites?.includes(collection.id))
            } else {
                filtered = []
            }

        } else if ((filter === "Owned" || filter === "Favorites") && !user) {
            displayWalletConnectPopup(true)
            return;
        }


        if (sort === "Most Popular") { //sort by favorites field
            filtered = filtered.sort((a: any, b: any) => b.favoriteCount - a.favoriteCount)
        } else if (sort === "A-Z") {
            filtered = filtered.sort((a: any, b: any) => a.name.localeCompare(b.name))
        }

        //pagination
       /* const offset = (page - 1) * perPage
        const paginatedItems = filtered.slice(offset).slice(0, perPage)*/

        searchInput!.current.value = ""

        dispatch(setSearchValue(""))
        dispatch(setPageValue(1))
        dispatch(setFilterValues({ chain, filter, sort }))
        dispatch(set_Collections(filtered))
        dispatch(set_CollectionsPaginated(filtered))
    }

    const handleSearch = (searchText: any) => {
        dispatch(setSearchValue(searchText))

        let filtered = allCollections

        if (searchText) {
            filtered = filtered.filter((collection: any) => collection.name.toLowerCase().includes(searchText.toLowerCase()))
        }

        if (_chain !== "all") {
            filtered = filtered.filter((collection: any) => collection.chain === _chain)
        }

       /* const page = 1
        const offset = (page - 1) * perPage
        const paginatedItems = filtered.slice(offset).slice(0, perPage)*/

        dispatch(set_Collections(filtered))
        dispatch(set_CollectionsPaginated(filtered))
        dispatch(setFilterValues({ chain: _chain, filter: "all", sort: "all" }))
    }

    const handlePageChange = (page: number) => {

        if (page < 1) {
            return;
        }

        if (Math.ceil(collections.length / perPage) + 1 <= page)
            return;

        dispatch(setPageValue(page))

        const offset = (page - 1) * perPage
        const paginatedItems = collections.slice(offset).slice(0, perPage)

        dispatch(set_CollectionsPaginated(paginatedItems))

    }

    return (
        <C.Collections>
            <C.Filter>
                    <C.Search>
                        <Input type="text" xref={searchInput} placeholder="Search" onChange={(e: any) => handleSearch(e.target.value)} defaultValue={_searchText} />
                    </C.Search>
                    <C.FilterButtons>

                        {/*<DropdownButton mobileSide="left" text={_chain === "all" ? "Chain" : (_chain.charAt(0).toUpperCase() + _chain.slice(1))} buttonStyle={{ theme: "gradient-border-transparent-bg-on-hover", bg: Hex2Rgba(color.secondaryFade, .5), color: "white" }}>
                            <DropdownItem active={_chain === "all" ? "true" : "false"} onClick={() => filterItems("all", _filter, _sort)}>
                                All
                            </DropdownItem>
                            <DropdownItem active={_chain === Chains.solana.name ? "true" : "false"} onClick={() => filterItems(Chains.solana.name, _filter, _sort)}>
                                <img src="/images/chains/solana-icon.png" alt="solana icon" width="15" style={{ marginRight: 5 }} /> Solana
                            </DropdownItem>
                            <DropdownItem active={_chain === Chains.aptos.name ? "true" : "false"} onClick={() => filterItems(Chains.aptos.name, _filter, _sort)}>
                                <img src="/images/chains/aptos-icon.png" alt="aptos icon" width="15" style={{ marginRight: 5 }} /> Aptos
                            </DropdownItem>
    </DropdownButton>*/}

                        <DropdownButton text={_filter === "all" ? "Filter" : _filter} buttonStyle={{ theme: "gradient-border-transparent-bg-on-hover", bg: Hex2Rgba(color.secondaryFade, .5), color: "white" }}>
                            <DropdownItem active={_filter === "all" ? "true" : "false"} onClick={() => filterItems(_chain, "all", _sort)}>
                                All
                            </DropdownItem>
                            <DropdownItem active={_filter === "Owned" ? "true" : "false"} onClick={() => filterItems(_chain, "Owned", _sort)}>
                                Owned
                            </DropdownItem>
                            <DropdownItem active={_filter === "Favorites" ? "true" : "false"} onClick={() => filterItems(_chain, "Favorites", _sort)}>
                                Favorites
                            </DropdownItem>
                        </DropdownButton>
                        
                        <DropdownButton text={_sort === "all" ? "Sort" : _sort} buttonStyle={{ theme: "gradient-border-transparent-bg-on-hover", bg: Hex2Rgba(color.secondaryFade, .5), color: "white" }}>
                            <DropdownItem active={_sort === "all" ? "true" : "false"} onClick={() => filterItems(_chain, _filter, "all")}>
                                Default
                            </DropdownItem>
                            <DropdownItem active={_sort === "A-Z" ? "true" : "false"} onClick={() => filterItems(_chain, _filter, "A-Z")}>
                                A-Z
                            </DropdownItem>
                            <DropdownItem active={_sort === "Most Popular" ? "true" : "false"} onClick={() => filterItems(_chain, _filter, "Most Popular")}>
                                Most Popular
                            </DropdownItem>
                        </DropdownButton>
                    </C.FilterButtons>

                {/*
                {!isMobile && (
                    <C.Pagination>
                        <C.PaginationItem disActive={_page === 1} onClick={() => handlePageChange(_page - 1)}>
                            <FontAwesomeIcon icon={faAngleLeft} />
                            Back
                        </C.PaginationItem>

                        <C.PaginationItem>
                            {_page}
                        </C.PaginationItem>
                        <C.PaginationItem disActive={_page === Math.ceil(collections.length / perPage)} onClick={() => handlePageChange(_page + 1)}>
                            Next
                            <FontAwesomeIcon icon={faAngleRight} />
                        </C.PaginationItem>
                    </C.Pagination>
                )}
                */}
            </C.Filter>

            <C.Strip nomg="false">
                <C.StripTitle>
                    Tokens
                </C.StripTitle>
            </C.Strip>

            <C.Items>
                <Row>
                    {allTokens.map((item: any, index: number) => (
                        <Col col="12" md="6" lg="4" xxl="3" key={index}>
                            <TokenWidget onClick={() => listHoldersOfCollection(item.id)} item={item} key={index} alert={false} social={true} stats={false} />
                        </Col>
                    ))}
                </Row>
            </C.Items>

            <C.Strip nomg="true">
                <C.StripTitle>
                    Collections
                </C.StripTitle>
            </C.Strip>

            <C.Items>
                <Row>
                    {paginatedCollections.map((item: any, index: number) => (
                        <Col col="12" md="6" lg="4" xxl="3" key={index}>
                            <CollectionWidget onClick={() => listHoldersOfCollection(item.id)} item={item} key={index} alert={false} social={true} stats={false} />
                        </Col>
                    ))}
                </Row>
            </C.Items>

            {/*
            {isMobile && (
                <C.Pagination>
                    <C.PaginationItem disActive={_page === 1} onClick={() => handlePageChange(_page - 1)}>
                        <FontAwesomeIcon icon={faAngleLeft} />
                        Back
                    </C.PaginationItem>

                    <C.PaginationItem>
                        {_page}
                    </C.PaginationItem>
                    <C.PaginationItem disActive={_page === Math.ceil(collections.length / perPage)} onClick={() => handlePageChange(_page + 1)}>
                        Next
                        <FontAwesomeIcon icon={faAngleRight} />
                    </C.PaginationItem>
                </C.Pagination>
            )}
            */}


        </C.Collections>
    )

}

export default CollectionsPart