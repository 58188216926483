import styled from "styled-components"

import { Dialog, Page as BasePage, DialogHeader } from "../base/style"
import { color } from "styles/theme"
import { deviceMax, deviceMin } from "styles/media"

export const Page = styled.div`

    & ${BasePage} {
        align-items: center;
    }


    & ${Dialog} {
        background: ${color.shuffleBg};
        max-width:822px;
        width:100%;
        margin: 100px auto 100px auto;
        padding:0px;
    }

    @media (max-height: 960px) {
        & ${Dialog} {
            margin: 40px auto 40px auto;
        }
    }

    @media (min-width: 2000px) {
        & ${Dialog} {
            margin: 40px auto 40px auto;
        }
    }

    & ${DialogHeader} {
        display: none;
    }

`