import styled from "styled-components"

import { Dialog, DialogClose } from "../base/style"
import { Header } from "components/parts/shuffle/shuffleLiquidation/header/style"

export const Page = styled.div`
    & ${Dialog} {
        max-width:1650px;
    }

    @media (min-width: 2000px) {
        & ${Dialog} {
            margin: 40px auto 40px auto;
        }
    }

    & ${DialogClose} {
        margin-left: 0;
    }

    & ${Header} {
        margin-left: auto;
    }
`