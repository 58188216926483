import { Link } from "react-router-dom"
import styled from "styled-components"
import { deviceMax } from "styles/media"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"

export const Chat = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    display: flex;
    flex-direction: column;
    pointer-events: all;
`

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px;
`

export const HeaderTitle = styled.div`
    font-size:14px;
`

export const Actions = styled.div`
    display: flex;
    align-items: center;
    font-size: 24px;
`

export const Actiona = styled.a`
    display: flex;
    align-items: center;
    & svg{
        height: 12px;
    }

    &:not(:last-child){
        margin-right: 12px;
    }
    text-decoration: none;
    color: ${color.white};
    cursor: pointer;
`

export const Action = styled.div`
    display: flex;
    align-items: center;
    & svg{
        height: 12px;
    }

    &:not(:last-child){
        margin-right: 12px;
    }

    cursor: pointer;
`


export const Chats = styled.div`
    width: 100%;
    position: relative;
`

export const ChatsLoading = styled.div`
    background-color: ${Hex2Rgba(color.bg1, 0.64)};
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    font-size: 20px;
`

export const ChatItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px;
    &:not(:last-child){
        border-bottom: 1px solid ${Hex2Rgba(color.secondary, 0.16)};
    }
    &:hover{
        background-color: ${Hex2Rgba(color.white, 0.04)};
    }
    cursor: pointer;

    transition: all .1s ease-in-out;
`

export const ChatItemLeft = styled.div`
    display: flex;
    align-items: center;
`

export const ChatItemAvatar = styled.div`
    & img{
        width: 48px;
        height: 48px;
        border-radius: 50%;
    }
    display: flex;
    align-items: center;
`

export const ChatItemContent = styled.div`
    margin-left: 16px;
`

export const ChatItemName = styled.div`
    font-size: 14px;
    font-weight: 600;
`

export const ChatItemLastMessage = styled.div`
    font-size: 12px;
    font-weight: 500;
`

export const ChatItemRight = styled.div`
    display: flex;
    align-items: center;
`

export const ChatItemUnreadCount = styled.div`
    background-color: ${color.secondary};
    border-radius: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 4px;
    height: 24px;
    font-size: 12px;
    margin-right: 8px;
`

export const ChatItemArrow = styled.div`
    background-color: ${Hex2Rgba(color.white, 0.1)};
    border-radius: 50%;
    width: 24px;
    height: 24px;

    display: flex;
    align-items: center;
    justify-content: center;

    & svg{
        height: 12px;
    }
`

export const ChatHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content:center;
    padding: 12px 8px;
    position: relative;
    background-color: ${color.bg2};
    margin-bottom: 8px;
`

export const ChatHeaderBack = styled.div`
    position: absolute;
    left: 24px;
    cursor: pointer;
    padding: 8px 16px;
    padding-left: 0px;
    & svg{
        height: 12px;
    }
`

export const ChatHeaderTitle = styled.div`
    font-size: 14px;
    font-weight: 600;
    text-align: center;
`


export const ChatView = styled.div`
    display:flex;
    flex:1;
    flex-direction:column;
    width: 100%;
    height: 100%;
    
`

export const ChatBody = styled.div`
    flex: 1;
    width: 100%;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius:10px;
    }

    &:hover::-webkit-scrollbar-thumb {
        background: #888;
    }

    padding: 0px 24px;
    padding-bottom: 80px;
`

export const ChatMessageContainer = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 16px;
`

export const ChatMessageBody = styled.div`
    padding:8px 16px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    max-width: 100%;
    word-wrap: break-word;
`

export const ChatMessageTime = styled.div`
    font-size:10px;
    color:${color.whiteShade};
    margin-top:8px;
`

export const ChatMessage = styled.div<{from:'me' | 'other'}>`
    display: flex;
    max-width: 90%;
    flex-direction: column;
    word-wrap: break-word;
    ${props => props.from === 'me' && `
        margin-left: auto;
        align-items: flex-end;
        & ${ChatMessageBody} {
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 4;
            background-color: #282360;
        }

        & ${ChatMessageTime} {
            text-align: right;
        }
    `}

    ${props => props.from === 'other' && `
        align-items: flex-start;
        margin-right: auto;
        & ${ChatMessageBody} {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 16px;
            background-color: ${color.secondary};
        }
    `}
`

export const ChatMessageSenderA = styled.a`
    font-size:12px;
    color:${color.whiteShade};
    text-decoration: none;
`

export const ChatMessageSenderLink = styled(Link)`
    font-size:12px;
    color:${color.whiteShade};
    text-decoration: none;
`

export const ChatMessageText = styled.div`
    font-size:15px;
    font-weight: 400;
    word-wrap: break-word;
    white-space: pre-wrap;
    max-width: 100%;
`

export const ChatSend = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    width: 100%;
    bottom: 0;
    background-color: ${color.bg2};
    padding: 12px 12px 12px 12px;
`

export const ChatSendInput = styled.textarea`
    border:2px solid transparent;
    background:#19173B;
    border-radius: 16px;
    padding:15px 24px;
    resize: none;
    color:${color.whiteShade};
    width: 100%;
    font-size:14px;
    font-weight: 500;
    outline: none;
    height: 52px;
    &::placeholder {
        color:${color.whiteShade};
    }

    &:focus {
        border: 2px solid ${color.secondary};
    }
`

export const ChatSendButton = styled.div`
    position: absolute;
    right: 16px;
    cursor: pointer;
`

export const ChatSendButtonIcon = styled.div`
    width: 32px;
    height: 32px;
    background-color:#3E3C5A;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color:${color.whiteShade};
    border-radius: 50%;

    &:hover {
        background-color:${color.secondary};
    }
`

export const ChatViewEmpty = styled.div``

export const ChatWrapper = styled.div<{expanded:string, viewingchat:string}>`
    display: flex;
    height: 0%;  
    flex:1; 
    ${props => (props.expanded === 'false' && props.viewingchat === 'false') && `
        & ${ChatView} {
            display: none;
        }
    `}
    ${props => (props.expanded === 'false' && props.viewingchat === 'true') && `
        & ${ChatView} {
            display: flex;
        }
        & ${Chats} {
            display: none;
        }
    `}

    ${props => props.expanded === 'true' && `
        
        & ${Chats} {
            width: 40%;
            height: 100%;
            padding-right: 16px;
        }

        & ${ChatView} {
            width: 60%;
            height: 100%;
            background-color: ${Hex2Rgba(color.secondary, 0.16)};
            border-radius:8px;
            overflow:hidden;
        }

        & ${ChatSend}{
            position: relative;
            background-color: transparent;
        }

        & ${ChatBody} {
            padding-bottom: 0;
        }
    `}

    ${props => (props.expanded === 'true' && props.viewingchat === 'true') && `
        & ${Chats} {
            @media ${deviceMax.lg} {
                display: none;
            }
        }
    `}	

    @media ${deviceMax.md} {
        & ${ChatSend}{
            position: relative;
            background-color: transparent;
        }

        & ${ChatBody} {
            padding-bottom: 0;
        }
    }
`

export const ChatItemEmpty = styled.div`

    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 16px;

    & a {
        height:40px;
        width: 100%;
    }
    
`

export const ChatItemEmptyContent = styled.div``

export const ChatItemEmptyImage = styled.div`
    & img{
        height: 150px;
    }

`

export const ChatItemEmptyTitle = styled.div`
    margin-top:32px;
    margin-bottom: 8px;
    font-size: 22px;
    font-weight: 600;
`

export const ChatItemEmptyText = styled.div`
    font-size: 14px;
    color: ${color.whiteShade};
    text-align: center;
    margin-bottom: 24px;
    margin-top: 8px;
`