import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { User } from 'models/user'

export interface WhitelistState {
    currentWhitelist : any | null,
    ogCount : number | null,
    eligible : boolean | null,
    entry : any | false | null,
    currentViewingWhitelistType: string | null // type of whitelist
}

const initialState: WhitelistState = {
    currentWhitelist : null,
    ogCount : null,
    eligible : null,
    entry : null,
    currentViewingWhitelistType: null
}

export const whitelistSlice = createSlice({
    name: 'whitelist',
    initialState,
    reducers: {
        setCurrentWhitelist: (state, action: PayloadAction<any | null>) => {
            state.currentWhitelist = action.payload
            /*state.ogCount = null
            state.eligible = null
            state.entry = null*/
        },
        setOgCount: (state, action: PayloadAction<number | null>) => {
            state.ogCount = action.payload
        },
        setEligible: (state, action: PayloadAction<boolean | null>) => {
            state.eligible = action.payload
        },
        setEntry: (state, action: PayloadAction<any>) => {
            state.entry = action.payload
        },
        setCurrentViewingWhitelistType: (state, action: PayloadAction<string | null>) => {
            state.currentViewingWhitelistType = action.payload
        }
    }
})

export const { setCurrentWhitelist, setOgCount, setEligible, setEntry, setCurrentViewingWhitelistType } = whitelistSlice.actions

export default whitelistSlice.reducer