export const color = {

    primary: "#9B8FF6",
    secondary: "#463AA1",
    secondaryFade: "#2F285B",
    secondaryVibrant: "#6957F2",
    purpleShade1: "#282360",

    bg1: "#0E1022",
    bg2: "#151130",

    tertiary: "#FFDC27",
    danger: "#E90000",
    black: "#0A0A0B",
    gray: "#8E8E8E",
    whiteShade: "#A7A7A7",
    white: "#F8F7FF",
    green: "#00FF00",
    inactive: "#8D6D50",

    _mapcolor1: "#6FA3F2",
    _mapcolor2: "#735FF4",
    _mapcolor3: "#F45F5F",

    error: "#A91414",

    // gradients
    gradient1: {
        start: "#6654F1",
        middle: "#DEC5FF",
        end: "#71C3E7"
    },
    gradient2: {
        start: "#8C5FC5",
        middle: "#665DD0",
        end: "#38A9DA"
    },
    gradient3: {
        start: "#FFDC27",
        end: "#FFA927"
    },
    gradient4: {
        start: "#F2EFF8",
        middle: "#DEC5FF",
        end: "#71C3E7"
    },

    places: "#0A151C",
    shuffleBg: "#070811",

    //badge
    _badgeDeveloperBg: "#FEE3E3",
    _badgeDeveloperText: "#CC1111",
    _badgeDesignerBg: "#CAF4FA",
    _badgeDesignerText: "#3A95A1",
    _badgeArtistBg: "#F3CCBC",
    _badgeArtistText: "#DF6A37",
    _badgeMusicianBg: "#D5FCE9",
    _badgeMusicianText: "#14A961",
    _badgeMarketingBg: "#FADEFF",
    _badgeMarketingText: "#A058AE",
    _badgeCollabManagerBg: "#F8F1CC",
    _badgeCollabManagerText: "#CAAC0E",

    twitter: "#1DA1F2",

    shareGreen: "#02C076 ",
    shareRed: "#F84960 ",
}

export const Getcolor = (colour: any) => {
    if (typeof colour === "undefined")
        return color.black
    else {
        // @ts-ignore
        return typeof color[colour] === "undefined" ? color : color[colour]
    }
}

