import React, { useEffect, useState } from 'react'
import * as C from './style'
import env from 'env'
import { useDispatch, useSelector } from "react-redux"
import Button from 'components/ui/button'
import { Hex2Rgba } from 'utils/helpers'
import { color } from 'styles/theme'
import { Col } from 'styles'
import { showShuffleLedgerSetupPopup, showShuffleLiquidityDepositPopup, showShuffleLiquidityLocksPopup, showShuffleLiquidityNftPopup, showShuffleLiquidityTokenPopup, showWalletConnectPopup } from 'services/slices/popup'
import { claimRevenue, getAllLedgersOfUser, getLedger, getTreasury } from "modules/solana/shuffle/liquidity"
import { getCurrentShuffle } from 'services/api/shuffle'
import { setNftViewAddress, setRefreshLiquidations, setShuffleLedger, setShuffleSelectedLedger, setShuffleProgramId, setShuffleTreasury, setShuffleVault, setTokenViewAddress, setShuffleLoadedLedger, setShuffleTreasuryAccount } from 'services/slices/shuffle'
import { toast } from 'react-hot-toast'
import { Network } from 'models/enums/network'
import { setWalletConnectSettings } from 'services/slices/data'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { LAMPORTS_PER_SOL } from '@solana/web3.js'
import { ParseAwardAddress } from 'modules/solana/shuffle/helpers'
import { getAllNftsByMintList } from 'modules/solana/nft'
import axios from 'axios'
import ShuffleLedgerSelect from '../shuffleLedgerSelect'

const ShuffleLiquidation = () => {

    const dispatch = useDispatch()
    const programId = useSelector((state: any) => state.shuffle.programId)
    const treasury = useSelector((state: any) => state.shuffle.treasuryAccount)
    const vault = useSelector((state: any) => state.shuffle.vault)
    const [config, setConfig] = useState<any>(null)
    const [loading, setLoading] = useState<boolean | null>(true)
    const selectedWallet = useSelector((state: any) => state.user.selectedWallet)
    const shuffleLedger = useSelector((state: any) => state.shuffle.ledger)
    const shuffleSelectedLedger = useSelector((state: any) => state.shuffle.selectedLedger)
    const shuffleLoadedLedger = useSelector((state: any) => state.shuffle.loadedLedger)
    const user = useSelector((state: any) => state.user.user)
    const userLoaded = useSelector((state: any) => state.user.userLoaded)
    const walletConnectPopup = useSelector((state: any) => state.popup.WalletConnectPopup)
    const refreshLiquidations = useSelector((state: any) => state.shuffle.refreshLiquidations)
    const [claimLoading, setClaimLoading] = useState<any>(false)

    const [lockedNftsCorrect, setLockedNftsCorrect] = useState<any>(true)

    const [liquidations, setLiquidations] = useState<any>([])

    useEffect(() => {

        if (!userLoaded || !selectedWallet)
            return;

        dispatch(showShuffleLedgerSetupPopup(false))
        setLoading(true)

        getCurrentShuffle().then((res) => {

            setConfig({
                shuffleProgram: res.data.shuffleProgram.value,
                shuffleCurrentRaffle: res.data.shuffleCurrentRaffle.value,
                shuffleVault: res.data.shuffleVault.value,
                shuffleDefaultTreasury: res.data.shuffleDefaultTreasury.value,
            })

            dispatch(setShuffleProgramId(res.data.shuffleProgram.value))
            dispatch(setShuffleTreasury(res.data.shuffleDefaultTreasury.value))
            dispatch(setShuffleVault(res.data.shuffleVault.value))

            getTreasury(res.data.shuffleProgram.value, res.data.shuffleDefaultTreasury.value).then((treasury) => {

                dispatch(setShuffleTreasuryAccount({
                    requirements: treasury.requirements.map((r: any) => {
                        return {
                            address: r.address.toString(),
                            cmid: r.cmid.map((c: any) => c.toString()),
                            count: r.count,
                        }
                    }),
                    supportedItems: {
                        nfts: treasury.supportedItems.nfts.map((n: any) => {
                            return {
                                address: n.address.toString(),
                                cmid: n.cmid.map((c: any) => c.toString()),
                            }
                        }),
                        tokens: treasury.supportedItems.tokens.map((t: any) => {
                            return {
                                address: t.address.toString(),
                                amounts: t.amounts.map((a: any) => a.toNumber()),
                            }
                        })
                    },
                    whitelisted: treasury.whitelisted.map((w: any) => w.toString()),
                }))

                init(res.data.shuffleProgram.value, res.data.shuffleDefaultTreasury.value)

            }).catch((err) => {
                console.log(err)
                toast.error("Error loading liquidity")
                setLoading(null)
            })

        }).catch((err) => {
            console.log(err)
            toast.error("Error loading liquidity")
            setLoading(null)
        })

        return () => {
            dispatch(setRefreshLiquidations(false))
            dispatch(setShuffleLedger(null))
        }

    }, [selectedWallet, userLoaded])

   useEffect(() => {
        if (userLoaded && !selectedWallet) {
            /*dispatch(setWalletConnectSettings({ network: Network.Solana }))
            dispatch(showWalletConnectPopup(true))*/
            setLoading(false)
        }
    }, [userLoaded, selectedWallet])

    useEffect(() => {

        if (!userLoaded || !config)
            return;

        if (refreshLiquidations) {
            dispatch(setRefreshLiquidations(false))
            // console.log("refreshing")
            refresh(config.shuffleProgram)
        }

    }, [refreshLiquidations])

    /*useEffect(() => {

        if (!userLoaded || shuffleLedger)
            return;

        load()

    }, [user, config, selectedWallet, userLoaded, shuffleLedger])

    useEffect(() => {

        if (!userLoaded || walletConnectPopup || !selectedWallet)
            return;

        load()

    }, [walletConnectPopup])*/

    useEffect(() => {

        if (shuffleSelectedLedger !== null)
            refresh(config.shuffleProgram)

    }, [shuffleSelectedLedger])

    const load = () => {
        if (config !== null && user && selectedWallet) {
            init(config.shuffleProgram, config.shuffleDefaultTreasury)
            //refresh(config.shuffleProgram, config.shuffleDefaultTreasury)
        } else if (config !== null && (!user || !selectedWallet)) {
            dispatch(setWalletConnectSettings({ network: Network.Solana }))
            dispatch(showWalletConnectPopup(true))
            setLoading(false)
        }
    }

    const init = (programId: string, treasury: string) => {
        getAllLedgersOfUser(programId, treasury, selectedWallet).then(async (res) => {

            if (res.length === 0) {
                dispatch(showShuffleLedgerSetupPopup(true))
                dispatch(setShuffleLedger(null))
                setLoading(false)
            } else {

                dispatch(setShuffleLedger(res))
                dispatch(setShuffleSelectedLedger(null))
                dispatch(setShuffleLoadedLedger(null))
                setLoading(false)
            }

        }).catch((err) => {
            console.log(err)
            toast.error("An error occured while loading your liquidity")
        })
    }

    const refresh = (programId: string) => {

        setLoading(true)

        getLedger(programId, shuffleSelectedLedger).then(async (res) => {

            dispatch(setShuffleLoadedLedger(res))
            //console.log("ledger", res)
            setLoading(false)

            let liqs: any = {}
            let collectionAddresses = []

            for (let i = 0; i < res.account.liquidations.length; i++) {
                if (typeof liqs[res.account.liquidations[i].address] === "undefined") {
                    liqs[res.account.liquidations[i].address] = {
                        address: res.account.liquidations[i].address,
                        count: res.account.liquidations[i].address === res.account.liquidations[i].mint ? res.account.liquidations[i].amount[1] / LAMPORTS_PER_SOL * res.account.liquidations[i].amount[0] : res.account.liquidations[i].amount[0],
                        data: res.account.liquidations[i].address === res.account.liquidations[i].mint ? ParseAwardAddress(res.account.liquidations[i].address) : null
                    }
                    if (res.account.liquidations[i].address !== res.account.liquidations[i].mint && collectionAddresses.indexOf(res.account.liquidations[i].address) === -1) {
                        collectionAddresses.push(res.account.liquidations[i].address)
                    }
                } else
                    liqs[res.account.liquidations[i].address].count += res.account.liquidations[i].address === res.account.liquidations[i].mint ? res.account.liquidations[i].amount[1] / LAMPORTS_PER_SOL * res.account.liquidations[i].amount[0] : res.account.liquidations[i].amount[0]

            }

            let collections: any = await getAllNftsByMintList(collectionAddresses)

            for (let i = 0; i < collections.length; i++) {
                if (typeof liqs[collections[i].mintAddress.toBase58()] !== "undefined") {
                    let metadata = await axios.get(collections[i].uri)
                    liqs[collections[i].mintAddress.toBase58()].data = {
                        name: metadata.data.name,
                        symbol: metadata.data.name,
                        image: metadata.data.image,
                        nft: true
                    }
                }
            }

            liqs = Object.values(liqs)

            for (let i = 0; i < liqs.length; i++) {
                if (!liqs[i].data.nft) {
                    liqs[i].count = liqs[i].count.toFixed(2)
                }
            }

            setLockedNftsCorrect(true)
            //if res.publicKey in treasury.whitelisted
            if (treasury.whitelisted.indexOf(selectedWallet) === -1) {

                for (let i = 0; i < treasury.requirements.length; i++) {
                    let depositedCount = res.lockAccount.lockedNfts.filter((nft: any) => nft.address === treasury.requirements[i].address).length
                    if (depositedCount < treasury.requirements[i].count) {
                        setLockedNftsCorrect(false)
                        break;
                    }
                }
            }

            setLiquidations(liqs)

        }).catch((err) => {

            if (err === "no-ledger") {
                dispatch(showShuffleLedgerSetupPopup(true))
                dispatch(setShuffleLedger(null))
                setLoading(false)
                return;
            }

            console.log(err)
            toast.error("Error loading liquidity")
            setLoading(null)
            dispatch(setShuffleLedger(null))
        })

    }

    const shuffleLiquidityTokenView = (address: any) => {
        dispatch(setTokenViewAddress(address));
        dispatch(showShuffleLiquidityTokenPopup(true));
    }

    const shuffleLiquidityNftView = (address: any) => {
        dispatch(setNftViewAddress(address));
        dispatch(showShuffleLiquidityNftPopup(true));
    }

    const claimRevs = () => {

        if (shuffleLoadedLedger.account.revenues === 0)
            return toast.error("No revenues to claim")


        claimRevenue(programId, vault, shuffleLoadedLedger.publicKey).then((res) => {
            load()
        }).catch((err) => {
            toast.error(err)
        })
    }

    if (loading)
        return (
            <C.Loading>
                <FontAwesomeIcon icon={faSpinner} spin />
            </C.Loading>
        )

    if (userLoaded && !selectedWallet)
        return (
            <C.Error>
                You need to connect your wallet to view your liquidity.<br />
                <Button theme="gradient-border-transparent-bg-on-hover" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)} onClick={() => {
                    dispatch(setWalletConnectSettings({ network: Network.Solana }))
                    dispatch(showWalletConnectPopup(true))
                }}> Connect </Button>
            </C.Error>
        )


    if (loading === null)
        return (
            <C.Error>
                An error occured while loading liquidity.<br />
                <Button theme="gradient-border-transparent-bg-on-hover" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)} onClick={() => load()}> Try again </Button>
            </C.Error>
        )

    if (shuffleLedger === null) {
        return (
            <C.Error>
                You don't have a Shuffle Liquidator Account.
                <Button theme="gradient-border-transparent-bg-on-hover" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)} onClick={() => load()}> Create one </Button>
            </C.Error>
        )
    }

    if (shuffleSelectedLedger === null) {
        return (
            <C.LedgerSelect>
                <ShuffleLedgerSelect />
            </C.LedgerSelect>
        )
    }

    if (shuffleLoadedLedger === null) {
        return (
            <></>
        )
    }

    return (
        <C.ShuffleLiquidation>

            {lockedNftsCorrect === false && (
                <C.LockAlert>
                    <C.LockAlertLeft>
                        <C.LockAlertTitle>
                            You need to lock NFTs to start using your Shuffle Liquidator Account.
                        </C.LockAlertTitle>
                        <C.LockAlertDescription>
                            To become a liquidator you need to deposit required NFTs to your Shuffle Liquidator Account.
                        </C.LockAlertDescription>
                    </C.LockAlertLeft>
                    <C.LockAlertAction>
                        <Button color={color.white} border={color._badgeArtistText} bg={color._badgeArtistText} onClick={() => dispatch(showShuffleLiquidityLocksPopup(true))}>
                            Deposit NFTs
                        </Button>
                    </C.LockAlertAction>
                </C.LockAlert>
            )}

            <C.Actions>
                <Col col="6" sm="6" xs="12">
                    <C.Action>
                        <C.ActionLeft>
                            <C.ActionTitle>
                                Your Revenue <span>{shuffleLoadedLedger.account.revenues / LAMPORTS_PER_SOL} SOL</span>
                            </C.ActionTitle>
                            <C.ActionDescription>
                                Your revenue is the amount of SOL you have earned from your liquidity pool.
                            </C.ActionDescription>
                        </C.ActionLeft>
                        <C.ActionRight>
                            <Button theme="gradient" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)} onClick={() => claimRevs()}>
                                Claim
                            </Button>
                        </C.ActionRight>
                    </C.Action>
                </Col>
                <Col col="6" sm="6" xs="12">
                    <C.Action disabled={lockedNftsCorrect === false}>
                        <C.ActionLeft>
                            <C.ActionTitle>
                                Deposit
                            </C.ActionTitle>
                            <C.ActionDescription>
                                Deposit your NFTs or Tokens to earn revenue.
                            </C.ActionDescription>
                        </C.ActionLeft>
                        <C.ActionRight>
                            <Button theme="gradient-border-transparent-bg-on-hover" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)} onClick={() => dispatch(showShuffleLiquidityDepositPopup(true))}>
                                Deposit
                            </Button>
                        </C.ActionRight>
                    </C.Action>
                </Col>
            </C.Actions>

            <C.Title>
                Your Liquidity
            </C.Title>

            <C.Liquidations>
                {liquidations.map((liq: any, i: number) => {
                    if (liq.count > 0)
                        return (
                            <Col col="2" xxxl="2" xxl="2" xl="3" lg="4" md="6" sm="4" xs="6" key={i}>
                                <C.Liquidation image={liq.data.image} onClick={() => liq.data.nft ? shuffleLiquidityNftView(liq.address) : shuffleLiquidityTokenView(liq.address)}>
                                    <C.LiquidationInfo>
                                        {liq.data.name} <span>({liq.count})</span>
                                    </C.LiquidationInfo>
                                </C.Liquidation>
                            </Col>
                        )
                })}

                {liquidations.length === 0 &&
                    <C.NoLiquidations>
                        You don't have any liquidity.
                    </C.NoLiquidations>
                }

            </C.Liquidations>

        </C.ShuffleLiquidation>
    )
}

export default ShuffleLiquidation