import React, { useEffect, useState } from "react"
import * as C from "./style"

import { useDispatch, useSelector } from "react-redux"
import Button from "components/ui/button"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { setLiquidationType, setShuffleLedger } from "services/slices/shuffle"
import { Col } from "styles"
import { getSplBalance, getTreasury, liquidateTreasuryNative, liquidateTreasuryNft, liquidateTreasuryToken } from "modules/solana/shuffle/liquidity"
import { toast } from "react-hot-toast"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { LAMPORTS_PER_SOL } from "@solana/web3.js"
import { ParseAwardAddress } from "modules/solana/shuffle/helpers"
import { showShuffleLiquidityDepositPopup } from "services/slices/popup"
import { RefreshLiquidations } from "services/managers/shuffle"
import { getAllNftsByMintList, getAllNftsByOwner } from "modules/solana/nft"
import axios from "axios"
import { signAllAndSend, SOLANA_CONNECTION } from "modules/solana/connection"
import { getShuffleError } from "modules/solana/shuffle/shuffleErrors"

const ShuffleLiquidityDeposit = () => {

    const [liquidityCurrency, setLiquidityCurrency] = useState<any>(null)
    const liquidationType = useSelector((state: any) => state.shuffle.liquidationType)
    const selectedWallet = useSelector((state: any) => state.user.selectedWallet)
    const [treasuryAccount, setTreasuryAccount] = useState<any>(null)
    const programId = useSelector((state: any) => state.shuffle.programId)
    const treasury = useSelector((state: any) => state.shuffle.treasury)
    const vault = useSelector((state: any) => state.shuffle.vault)
    const ledger = useSelector((state: any) => state.shuffle.loadedLedger)
    const [loading, setLoading] = useState<boolean | null>(true)
    const dispatch = useDispatch()

    const [tokenDepositCount, setTokenDepositCount] = useState<any>(1)
    const [tokenDepositAmount, setTokenDepositAmount] = useState<any>(0)
    const [tokenDepositIndex, setTokenDepositIndex] = useState<any>(null)
    const [tokens, setTokens] = useState<any>(null)

    const [actionLoading, setActionLoading] = useState<boolean>(false)
    const [collections, setCollections] = useState<any>([])
    const [walletNfts, setWalletNfts] = useState<any>([])

    const [nftsLoading, setNftsLoading] = useState<boolean>(true)

    useEffect(() => {

        dispatch(setLiquidationType(null))

        getTreasury(programId, treasury).then(async (res: any) => {

            let supportedTokens = res.supportedItems.tokens

            let parsedTokens = []
            for (let i = 0; i < supportedTokens.length; i++) {
                let token = supportedTokens[i];
                let balance = await getSplBalance(selectedWallet, token.address)

                let amounts = supportedTokens[i].amounts.map((amount: any) => {
                    return amount.toNumber() / LAMPORTS_PER_SOL
                })

                parsedTokens.push({
                    mint: token.address.toBase58(),
                    data: ParseAwardAddress(token.address.toBase58()),
                    balance: balance,
                    amounts: amounts,
                    limit: token.maxDeposit
                })

            }

            setTokens(parsedTokens)
            setTreasuryAccount(res)
            //console.log(res)

            setLoading(false)
        }).catch((err) => {
            console.log(err)
            toast.error("Error Loading Treasury")
            setLoading(null)
        })

    }, [])

    const changeLiquidityType = async (type: any, mint?: string) => {
        if (type === "nft") {
            setSelectedCollection("all")
            setNftsLoading(true)
            setSelectedNfts([])
            let mintlist = treasuryAccount.supportedItems.nfts.map((nft: any) => nft.address.toBase58())

            getAllNftsByMintList(mintlist).then(async (res: any) => {

                let _collections: any = []

                for (let i = 0; i < res.length; i++) {
                    try {
                        let metadata = await axios.get(res[i].uri)
                        let image = metadata.data.image;
                        let name = metadata.data.name;
                        _collections.push({
                            address: res[i].mintAddress.toBase58(),
                            name: name,
                            image: image,
                        })
                    } catch (err) {
                        console.log(err)
                    }
                }

                setCollections(_collections)
                getWalletNFts()

            }).catch((err) => {
                toast.error("Error Loading NFTs")
            })

            dispatch(setLiquidationType(type))
        } else {
            setNftsLoading(false)
            dispatch(setLiquidationType(type))
            if (mint) {
                let currency = tokens.find((token: any) => token.mint === mint)
                //console.log(currency)
                setLiquidityCurrency(currency)
                setTokenDepositCount(1)
                setTokenDepositAmount(currency.amounts[0])
                setTokenDepositIndex(0)
            } else {
                setLiquidityCurrency(null)
            }
        }
    }

    const getWalletNFts = async () => {
        let supportedNfts: any = []
        getAllNftsByOwner(selectedWallet, SOLANA_CONNECTION).then(async (res: any) => {
            for (let i = 0; i < res.length; i++) {
                let nft = res[i]
                for (let j = 0; j < treasuryAccount.supportedItems.nfts.length; j++) {
                    if (nft.collection && nft.collection.address.toBase58() == treasuryAccount.supportedItems.nfts[j].address.toBase58()) {
                        supportedNfts.push({
                            metadata: nft.address.toBase58(),
                            mint: nft.mintAddress.toBase58(),
                            collection: nft.collection.address.toBase58(),
                            uri: nft.uri,
                            cmid: nft.creators[0].address.toBase58(),
                            active: false,
                        })
                    }
                }
            }

            for (let i = 0; i < supportedNfts.length; i++) {
                let nft = supportedNfts[i]
                let metadata = await axios.get(nft.uri)
                let image = metadata.data.image;
                let name = metadata.data.name;
                nft.image = image;
                nft.name = name;
            }
            //console.log("supported nfts", supportedNfts)
            setWalletNfts(supportedNfts)
            setNftsLoading(false)
        })
    }

    const tokenDepositInput = React.useRef<any>(null)

    const changeTokenDepositAmount = (amount: any, i: number) => {
        setTokenDepositAmount(amount)
        setTokenDepositIndex(i)
    }

    const decrementTokenDepositCount = () => {

        if (tokenDepositCount > 1) {
            setTokenDepositCount(tokenDepositCount - 1)
            tokenDepositInput.current.value = tokenDepositCount - 1
        }

    }

    const incrementTokenDepositCount = () => {
        //if (tokenDepositCount < 20) {
        setTokenDepositCount(tokenDepositCount + 1)
        tokenDepositInput.current.value = tokenDepositCount + 1
        //}
    }

    const onTokenDepositCountChange = (e: any) => {

        if (e.target.value === "" || isNaN(e.target.value)) {
            setTokenDepositCount(1)
            tokenDepositInput.current.value = 1
        } else {

            let value = Math.floor(parseInt(e.target.value))

            /*if (value > 20)
                value = 20*/

            if (e.target.value > 0) {
                setTokenDepositCount(value)
            } else {
                setTokenDepositCount(1)
                tokenDepositInput.current.value = 1
            }
        }
    }

    const onTokenDepositCountKeyDown = (e: any) => {
        if (isNaN(e.key) && e.key !== "Backspace" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "ArrowUp" && e.key !== "ArrowDown") {
            e.preventDefault()
        }
    }

    const depositToken = () => {

        if (liquidityCurrency.balance < tokenDepositAmount * tokenDepositCount) {
            toast.error("Insufficient Balance")
            return
        }

        setActionLoading(true)

        if (liquidityCurrency.mint === "So11111111111111111111111111111111111111111") {
            liquidateTreasuryNative(programId, treasury, vault, ledger.publicKey, tokenDepositAmount, tokenDepositCount).then((res: any) => {
                setActionLoading(false)
                dispatch(showShuffleLiquidityDepositPopup(false))
                RefreshLiquidations()
            }).catch((err) => {
                toast.error(err)
                setActionLoading(false)
            })
        } else {
            liquidateTreasuryToken(programId, treasury, vault, ledger.publicKey, liquidityCurrency.mint, tokenDepositAmount, tokenDepositCount).then((res: any) => {
                setActionLoading(false)
                dispatch(showShuffleLiquidityDepositPopup(false))
                RefreshLiquidations()
            }).catch((err) => {
                toast.error(err)
                setActionLoading(false)
            })
        }

    }

    const depositSelectedNfts = async () => {
        if (selectedNfts.length === 0) {
            toast.error("No NFTs Selected")
            return
        }

        setActionLoading(true)

        let txs = []

        for (let i = 0; i < selectedNfts.length; i++) {
            let nft = walletNfts.find((nftItem: any) => nftItem.mint === selectedNfts[i])
            let ix = await liquidateTreasuryNft(programId, treasury, vault, ledger.publicKey, nft.collection, nft.cmid, nft.mint, nft.metadata)
            txs.push(ix)
        }

        signAllAndSend(txs, "Depositing...").then((res: any) => {
            setActionLoading(false)
            dispatch(showShuffleLiquidityDepositPopup(false))
            RefreshLiquidations()

        }).catch((e) => {
            if (typeof e === "string")
                toast.error(e)
            else if (e.message && e.message.indexOf("Attempt to debit an account but found no record of a prior credit.") !== -1) {
                toast.error(getShuffleError("0x1"))
            } else if (e.InstructionError && e.InstructionError[1] && e.InstructionError[1].Custom) {
                let errorMessage = getShuffleError(e.InstructionError[1].Custom)
                if (!errorMessage)
                    errorMessage = "Error Depositing"
                toast.error(errorMessage)
            } else {
                toast.error("Error Depositing NFTs")
                setActionLoading(false)
            }
        })
    }

    const [selectedCollection, setSelectedCollection] = React.useState<any>("all")
    const [selectedNfts, setSelectedNfts] = React.useState<any>([])

    const selectNft = (mint: any) => {
        let _selectedNfts = selectedNfts
        let index = _selectedNfts.findIndex((nftItem: any) => nftItem === mint)
        if (index > -1) {
            _selectedNfts.splice(index, 1)
        } else {
            _selectedNfts.push(mint)
        }
        setSelectedNfts([..._selectedNfts])
    }

    useEffect(() => {
        setSelectedNfts([])
    }, [selectedCollection])

    if (loading)
        return (
            <C.Loading>
                <FontAwesomeIcon icon={faSpinner} spin />
            </C.Loading>
        )

    if (loading === null)
        return (
            <C.Loading>
                <p>Error Loading Treasury</p>
            </C.Loading>
        )

    return (
        <C.ShuffleLiquidityDepositPopup>

            {liquidationType === null && (

                <C.LiquidityTypeSelection>
                    {treasuryAccount.supportedItems.nfts.length > 0 && (
                        <C.LiquidityType onClick={() => changeLiquidityType("nft")}>
                            <C.LiquidityTypeInfo>
                                <C.LiquidityTypeTitle>
                                    NFT Deposit
                                </C.LiquidityTypeTitle>
                                <C.LiquidityTypeDescription>
                                    Deposit your NFTs to earn revenue.
                                </C.LiquidityTypeDescription>
                            </C.LiquidityTypeInfo>

                            <C.LiquidityTypeAction>
                                <Button theme="gradient-border-transparent-bg-on-hover" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)}>
                                    Deposit
                                </Button>
                            </C.LiquidityTypeAction>
                        </C.LiquidityType>
                    )}

                    {tokens.map((token: any, i: number) => (

                        <C.LiquidityType key={i} onClick={() => changeLiquidityType("token", token.mint)}>
                            <C.LiquidityTypeInfo>
                                <C.LiquidityTypeTitle>
                                    {token.data.name} Deposit
                                </C.LiquidityTypeTitle>
                                <C.LiquidityTypeDescription>
                                    Deposit your {token.data.name} to earn revenue.
                                </C.LiquidityTypeDescription>
                            </C.LiquidityTypeInfo>

                            <C.LiquidityTypeAction>
                                <Button theme="gradient-border-transparent-bg-on-hover" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)}>
                                    Deposit
                                </Button>
                            </C.LiquidityTypeAction>
                        </C.LiquidityType>

                    ))}
                </C.LiquidityTypeSelection>

            )}

            {liquidationType === "nft" && (
                <C.LiquidityDepositNft>

                    <C.Header>
                        <Button theme="gradient-border-transparent-bg-on-hover" bg={Hex2Rgba(color.white, .05)} color="white" onClick={() => changeLiquidityType(null)}>Back</Button>
                    </C.Header>

                    {nftsLoading && (
                        <C.Loading>
                            <FontAwesomeIcon icon={faSpinner} spin />
                        </C.Loading>
                    )}

                    {!nftsLoading && (
                        <>
                            <C.Collections>
                                <C.Collection active={selectedCollection === "all" ? "true" : "false"} onClick={() => setSelectedCollection("all")}>
                                    All
                                </C.Collection>
                                {collections.map((collection: any, i: number) => (
                                    <C.Collection active={selectedCollection === collection.address ? "true" : "false"} key={i} onClick={() => setSelectedCollection(collection.address)}>
                                        {collection.name}
                                    </C.Collection>
                                ))}
                            </C.Collections>

                            <C.Nfts>
                                {walletNfts.filter((nft: any) => selectedCollection === "all" || nft.collection === selectedCollection).map((nft: any, i: number) => (
                                    <Col col="3" >
                                        <C.Nft image={nft.image} selected={selectedNfts.includes(nft.mint) ? "true" : "false"} onClick={() => selectNft(nft.mint)}>
                                            <C.NftInfo>
                                                {nft.name}
                                            </C.NftInfo>
                                            <C.NftActiveMark></C.NftActiveMark>
                                        </C.Nft>
                                    </Col>
                                ))}
                            </C.Nfts>

                            <C.NftDepositActions>
                                <Button theme="gradient-border-transparent-bg-on-hover" color={color.white} bg={Hex2Rgba(color.secondaryFade, 1)} onClick={() => setSelectedNfts([])}>Clear Selection</Button>
                                <Button theme="gradient" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)} onClick={() => depositSelectedNfts()} loading={actionLoading ? "true" : "false"} disabled={actionLoading || selectedNfts.length === 0}>
                                    Deposit Selected {selectedNfts.length > 0 && (<>"{selectedNfts.length}"</>)} NFTs
                                </Button>
                            </C.NftDepositActions>
                        </>
                    )}



                </C.LiquidityDepositNft>
            )}

            {liquidationType === "token" && (
                <C.LiquidityDepositToken>

                    <C.Header>
                        <Button theme="gradient-border-transparent-bg-on-hover" bg={Hex2Rgba(color.white, .05)} color="white" onClick={() => changeLiquidityType(null)}>Back</Button>
                    </C.Header>

                    <C.LiquidityDepositTokenInfo>
                        <C.LiquidityDepositTokenTitle>
                            Deposit {liquidityCurrency.data.name}
                        </C.LiquidityDepositTokenTitle>
                        <C.LiquidityDepositTokenDescription>
                            Deposit your {liquidityCurrency.data.name} to earn revenue.
                        </C.LiquidityDepositTokenDescription>
                    </C.LiquidityDepositTokenInfo>

                    <C.LiquidityDepositTokenAmountSelection>
                        <C.LiquidityDepositTokenAmountSelectionTitle>
                            Amounts available to deposit
                        </C.LiquidityDepositTokenAmountSelectionTitle>
                        <C.LiquidityDepositTokenAmountSelectionInput>
                            {liquidityCurrency.amounts.map((amount: any, i: number) => (
                                <C.LiquidityDepositTokenAmountSelectionInputItem key={i} onClick={() => changeTokenDepositAmount(amount, i)} active={amount === tokenDepositAmount ? "true" : "false"}>
                                    {amount} {liquidityCurrency.data.name}
                                </C.LiquidityDepositTokenAmountSelectionInputItem>
                            ))}
                        </C.LiquidityDepositTokenAmountSelectionInput>

                    </C.LiquidityDepositTokenAmountSelection>

                    {tokenDepositIndex !== null && (
                        <C.LiquidityDepositTokenAmountLimit>
                            You have {liquidityCurrency.limit[tokenDepositIndex]} of count liquidation limit for {tokenDepositAmount} {liquidityCurrency.data.name}. 
                            If you exceed the limit, your transaction will be reverted.
                        </C.LiquidityDepositTokenAmountLimit>
                    )}

                    <C.LiquidityDepositTokenAction>
                        <C.LiquidityDepositTokenCount>
                            <C.LiquidityDepositTokenCountButton onClick={decrementTokenDepositCount}>-</C.LiquidityDepositTokenCountButton>
                            <C.LiquidityDepositTokenCountInputBox>
                                <C.LiquidityDepositTokenCountInput onChange={onTokenDepositCountChange} type="text" defaultValue={1} ref={tokenDepositInput} onKeyDown={onTokenDepositCountKeyDown} />
                            </C.LiquidityDepositTokenCountInputBox>
                            <C.LiquidityDepositTokenCountButton onClick={incrementTokenDepositCount}>+</C.LiquidityDepositTokenCountButton>
                        </C.LiquidityDepositTokenCount>
                        <Button theme="gradient" onClick={depositToken} color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)} loading={actionLoading ? "true" : "false"} disabled={actionLoading} >
                            Deposit
                            <C.LiquidityDepositTokenTotal>
                                {tokenDepositCount * tokenDepositAmount} {liquidityCurrency.data.name}
                            </C.LiquidityDepositTokenTotal>
                        </Button>
                    </C.LiquidityDepositTokenAction>

                </C.LiquidityDepositToken>
            )
            }

        </C.ShuffleLiquidityDepositPopup >
    )

}

export default ShuffleLiquidityDeposit