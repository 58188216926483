import { color } from "styles/theme";
import crypto from "crypto";
import env from "env";
import { Keypair, LAMPORTS_PER_SOL } from "@solana/web3.js";
import BigNumber from "bignumber.js";
import { formatPrice } from "modules/solana/share/helpers";
import { getWalletSolBalance } from "modules/solana/wallet";
import { setCloudWalletBalance } from "services/slices/user";
import { store } from "services/store";

export const Hex2Rgba = (hex: string, alpha: number) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export const shortenPublicKey = (publicKey: string, len?:number) => {
    if (len) {
        return publicKey.slice(0, len)
    }
    return publicKey.slice(0, 4) + "..." + publicKey.slice(-4);
}

export const shortenText = (text: string, len?:number) => {
    if (len) {
        return text.slice(0, len)
    }
    return text.slice(0, 4) + "..." + text.slice(-4);
}

export const capitalizeFirstLetter = (string: any) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export const customSelectStyles = {
    control: (base: any, state: any) => ({
        ...base,
        background: `linear-gradient(0deg, ${Hex2Rgba(color.black, .2)}, ${Hex2Rgba(color.black, .2)}), linear-gradient(180deg, ${Hex2Rgba(color.secondary, .02)} 0%, ${Hex2Rgba(color.primary, .02)} 100%);`,
        // match with the menu
        borderRadius: 8,
        // Overwrittes the different states of border
        borderColor: state.isFocused ? color.primary : Hex2Rgba(color.white, .3),
        // Removes weird border around container
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
            // Overwrittes the different states of border
            borderColor: state.isFocused ? color.primary : Hex2Rgba(color.white, .3),
        }
    }),
    menu: (base: any) => ({
        ...base,
        background: color.white,
        color: color.black,
    }),
    menuList: (base: any) => ({
        ...base,
        // kill the white space on first and last option
        padding: 0,
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
    option: (styles: any, { isDisabled }: any) => {
        return {
            ...styles,
            color: color.white,
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            backgroundColor: color.black,

            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled && (styles.backgroundColor = color.primary),
            },
        };
    },
    multiValue: (styles: any) => ({
        ...styles,
        color: color.white,
        backgroundColor: Hex2Rgba(color.black, .6),
        border: `1px solid ${Hex2Rgba(color.white, .3)}`,
        borderRadius: 8,
        padding: 5,
    }),
    multiValueLabel: (styles: any) => ({
        ...styles,
        color: color.white,
    }),
    multiValueRemove: (styles: any) => ({
        ...styles,
        color: color.white,
        ':hover': {
            backgroundColor: color.white,
            color: color.black,
        },
    }),
};

export const ToGeoJson = (data: any) => {

    let features = data.map((item: any) => {
        let { lat, lng, ...properties } = item

        return {
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: [lng, lat]
            },
            properties
        }
    })

    return {
        type: "FeatureCollection",
        crs: {
            type: "name",
            properties: {
                name: "hub3ee holder list"
            }
        },
        features
    }
}

export const ReverseGeoJson = (data: any) => {

    let features = data.features.map((item: any) => {
        return {
            lat: item.geometry.coordinates[1],
            lng: item.geometry.coordinates[0],
            ...item.properties
        }
    })

    return features
}

export const formatLink = (link: string) => {
    if (!link) return link;
    if (link.startsWith("http://") || link.startsWith("https://")) return link;
    return `https://${link}`;
}

export const copyObject = (obj: any) => {
    return JSON.parse(JSON.stringify(obj))
}

export function hex2a(hexx: string) {

    if (hexx.startsWith("0x")) {
        hexx = hexx.slice(2)
    }

    var hex = hexx.toString();//force conversion
    var str = '';
    for (var i = 0; i < hex.length; i += 2)
        str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
    return str;
}

export function a2hex(str: string) {
    var arr = [];
    for (var i = 0, l = str.length; i < l; i++) {
        var hex = Number(str.charCodeAt(i)).toString(16);
        arr.push(hex);
    }
    return "0x" + (arr.join(''));
}

export const wlPrice = (price: number, chain: string) => {
    if (price === -1) return "Free";
    if (price === 0) return "TBD";
    return price + " " + chain;
}

export const wlSupply = (supply: number) => {
    if (supply === -1) return "Unlimited";
    if (supply === 0) return "TBD";
    //format number with commas
    return supply.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const wlDate = (date: string | null): any => {
    if (date === null || date === "null" || !date) return "TBD";

    //format date to utc
    let d = new Date(date);
    return d.toUTCString().split(" ").slice(0, 3).join(" ");
}

//small case with dash regex
export const toLink = (link: string) => {
    return link.toLowerCase().replace(/ /g, "-");
}

export const isArraysEqual = (a: any[], b: any[]) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
        if (!b.includes(a[i])) return false;
    }
    return true;
}

export const GetRandomGif = (wlcollection: any) => {

    let gifs = wlcollection.recruitDetails.gifs;

    if (gifs && gifs.length > 0)
        return gifs[Math.floor(Math.random() * gifs.length)];

    return ""
}

export const GetTwitterHandle = (twitterurl: string) => {
    if (twitterurl) {
        let handle = twitterurl.split("/").pop();
        if (handle) return " @" + handle
    }

    return "";
}

export function toHexString(byteArray: any) {
    return Array.from(byteArray, function (byte: any) {
        return ('0' + (byte & 0xFF).toString(16)).slice(-2);
    }).join('')
}

export const sleep = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const bufferToText = (item: any) => {
    const buffer = Buffer.from(item, 'utf8');
    const bytesu8 = new Uint8Array(buffer);
    const utf8Decoder = new TextDecoder();
    const hash = utf8Decoder.decode(bytesu8);
    return hash;
}

export const generateRandomNumbersFromHash = (hashValue: string, numOfRandoms: number) => {
    let randomNumbers = [];
    let currentHash = hashValue;
    let hashLen = currentHash.length;
    let n = 0;
    for (let i = 0; i < numOfRandoms; i++) {
        let start = n * 8;
        let end = (n + 1) * 8;

        if (end > hashLen) {
            // Generate a new hash based on the previous hash
            let hasher = crypto.createHash("sha256");
            hasher.update(currentHash + i);
            currentHash = hasher.digest("hex");
            hashLen = currentHash.length;
            n = 0;
            start = 0;
            end = 8;
        }

        let hashInt = parseInt(currentHash.slice(start, end), 16);
        let randomNumber = hashInt / Math.pow(16, 8);
        randomNumbers.push(randomNumber);
        n += 1;
    }

    return {randomNumbers, previousHash: currentHash};
}

export const getAvatar = (avatar: string) => {
    if (avatar){
        //if avatar starts with http, return it
        if (avatar.startsWith("http")) return avatar;
        return env.API.CDN + "avatars/" + avatar
    }else{
        return "/images/etc/user.webp"
    }
}

export const getUserSigner = async (provider: any) => {

    const privateKey : any = await provider.request({
        method: "solanaPrivateKey",
    })

    //hex to base58
    const privateKeyBytes = Uint8Array.from(Buffer.from(privateKey, "hex"))

    const keypair = Keypair.fromSecretKey(privateKeyBytes)
    return keypair

}

export const refreshCloudWalletBalance = async () => new Promise((resolve, reject) => {
    getWalletSolBalance(store.getState().user.user!.cloudWallet).then((res: any) => {
        store.dispatch(setCloudWalletBalance(
            formatPrice(new BigNumber(res).div(LAMPORTS_PER_SOL))
        ))
        resolve(true)
    }).catch((err: any) => {
        resolve(true)
    })
})

export function formatDateLabel(date: any) {
    const now: any = new Date();
    const diffTime = Math.abs(now - date);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays === 1) {
        // If the date is from today
        return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    } else if (diffDays <= 7) {
        // If the date is from the last week
        const day = date.toLocaleDateString('en-US', { weekday: 'short' });
        const time = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
        return `${day}, ${time}`;  // e.g., 'Mon, 10:30'
    } else {
        // If the date is older
        return date.toLocaleDateString('en-US');
    }
}
