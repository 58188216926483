import { io, Socket } from "socket.io-client"
import env from "env"
import { store } from "./store"
import { addMessage } from "./slices/chat"
import toast from "react-hot-toast"

export var socketChat: Socket

export const initSocketChat = ({onMessage}: any, callback:any) => {
    localStorage.debug = 'socket.io-client:*';
    socketChat = io(env.API.SOCKET_CHAT, {
        transports: ["websocket", "polling"],
    })

    socketChat.on("connect", () => {
        console.log("Socket connected")
        callback()
    })

    socketChat.on("disconnect", () => {
        console.log("Socket disconnected")
    })

    socketChat.on("message", (data) => {
        onMessage(data)
    })

    socketChat.on("message_too_long", (data) => {
        toast.error("Message too long")
    })

    socketChat.on("rate_limit_exceeded", (data) => {
        toast.error("You are sending messages too fast. Please wait a moment.")
    })

    socketChat.on("401", (data) => {
        let token = store.getState().chat.chatToken
        if (token)
            joinChat(token)
    })

    socketChat.on("error", (error) => {
        console.error("Socket Error:", error);
    });
    socketChat.on("connect_error", (error) => {
        console.error("Connection Error:", error);
    });
    
    socketChat.on("connect_timeout", () => {
        console.error("Connection Timeout");
    });
    socketChat.on("reconnect_attempt", () => {
        console.log("Trying to reconnect...");
    });
    socketChat.on("reconnect_error", (error) => {
        console.error("Reconnection Error:", error);
    });
    socketChat.on("reconnect_failed", () => {
        console.error("Failed to reconnect after multiple attempts.");
    });
}

export const onMessage = (data: any) => {
    console.log("message", data)

    if (data.type === "text" && data.room === store.getState().chat.currentChat) {
        store.dispatch(addMessage(data))
    }
}

export const joinChat = async (key:string) => {
    return socketChat.emit("join", {key:"Bearer " + key})
}

export const sendMessage = async (room:string, text:string) => {
    return socketChat.emit("message", {room, text})
}