import { Link, NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { color } from 'styles/theme'
import { Hex2Rgba } from 'utils/helpers'

export const AppBar = styled.div`
    position: fixed;
    bottom: 0;
    width:100%;
    height:66px;
    display:flex;
    justify-content:space-around;
    background-color: ${Hex2Rgba(color.bg1, 0.64)};
    backdrop-filter: blur(20px);
    z-index:10;
`

export const AppBarIcon = styled.div`
height:30px;
width:30px;
display:flex;
align-items:center;
justify-content:center;
    border-radius:50%;
`

export const AppBarItemText = styled.div`
    font-size: 12px;
    margin-top: 5px;
    color: ${Hex2Rgba(color.white, .5)};
`



export const AppBarItem = styled.div<{ active?: any }>`
    cursor: pointer;
    flex:1;
    position:relative;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    color: ${color.white};

    & svg{
        width:20px;
        height:20px;
        display:flex;
        align-items:center;
        justify-content:center;
        color: ${Hex2Rgba(color.white, .5)};
        
    }

    ${props => props.active === "true" && `
        & ${AppBarIcon} {
            background-color: ${Hex2Rgba(color.secondary,1)} 
        }

        & ${AppBarItemText} {
            color: ${color.primary};
        }
    `}
`


export const AppBarItemLink = styled(Link)`
    cursor: pointer;
    flex:1;
    position:relative;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    color: ${color.white};
    text-decoration:none;

    & svg{
        width:20px;
        height:20px;
        display:flex;
        align-items:center;
        justify-content:center;
        color: ${Hex2Rgba(color.white, .5)};
        
    }

    &.active {
        & ${AppBarIcon} {
            background-color: ${Hex2Rgba(color.secondary,1)} 
        }

        & ${AppBarItemText} {
            color: ${color.primary};
        }
    }
`

export const AppBarItemA = styled.a`
    cursor: pointer;
    flex:1;
    position:relative;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    color: ${color.white};
    text-decoration:none;

    & svg{
        width:20px;
        height:20px;
        display:flex;
        align-items:center;
        justify-content:center;
        color: ${Hex2Rgba(color.white, .5)};
        
    }

    &.active {
        & ${AppBarIcon} {
            background-color: ${Hex2Rgba(color.secondary,1)} 
        }

        & ${AppBarItemText} {
            color: ${color.primary};
        }
    }
`
