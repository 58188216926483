import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect } from "react"
import * as C from "./style"
import { faBell, faGlobe, faHeart, faSearch } from '@fortawesome/free-solid-svg-icons';
import { faBell as faBellAlt, faHeart as faHeartAlt } from '@fortawesome/free-regular-svg-icons';
import { faDiscord, faTwitter } from "@fortawesome/free-brands-svg-icons"
import LineChartSvg from "components/ui/lineChartSvg"
import { color } from "styles/theme";
import { InverseLerp, Lerp } from "utils/math";
import { showAnalyticsOfCollection } from "utils/parts";
import { formatLink, Hex2Rgba, toLink } from "utils/helpers";
import env from "env";
import { addCollectionToFavorites, removeCollectionFromFavorites } from "services/api/profile";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { addCollectionToUsersFavorites, removeCollectionFromUsersFavorites } from "services/slices/user";
import { store } from "services/store";
import { increaseCollectionFavoriteCount, decreaseCollectionFavoriteCount } from "services/slices/data";
/*
var rawData = [
    23.90,
    24.27,
    24.29,
    24.27,
    24.14,
    24,
    23.89,
    23.89,
    23.98,
    23.84,
    23.80,
    23.30
]

var data: any = []

//get max and min
var max = Math.max.apply(Math, rawData);
var min = Math.min.apply(Math, rawData);

for (var i = 0; i < rawData.length; i++) {
    data.push({ x: i, y: Lerp(0, 100, InverseLerp(min, max, rawData[i])) })

}*/

const Collection = (props: any) => {

    const [favoriteHover, setFavoriteHover] = React.useState(false)
    const [bellHover, setBellHover] = React.useState(false)
    const user = useSelector((state: any) => state.user.user)
    const dispatch = useDispatch()
    const [favoritable, setFavoritable] = React.useState(true)

    const onClick = () => {
        if (props.onClick) {
            props.onClick(props.item.id)
        }
    }

    const onChartClick = () => {
        //showAnalyticsOfCollection(props.item.id)
    }

    if (!props.item)
        return <></>

    const handleFavorite = () => {

        if (!user) {
            toast.error("You must be logged in to favorite a collection")
            return
        }

        if (!favoritable)
            return

        setFavoritable(false)

        if (user.favorites.includes(props.item.id)) {
            removeCollectionFromFavorites(props.item.id).then((res: any) => {
                toast.success("Removed from favorites")
                dispatch(removeCollectionFromUsersFavorites(props.item.id))
                dispatch(decreaseCollectionFavoriteCount(props.item.id))

                setTimeout(() => {
                    setFavoritable(true)
                }, 1000)

            }).catch((err) => {
                toast.error("Error removing from favorites")
                setTimeout(() => {
                    setFavoritable(true)
                }, 1000)
            })
        } else {
            addCollectionToFavorites(props.item.id).then((res: any) => {
                toast.success("Added to favorites")
                dispatch(addCollectionToUsersFavorites(props.item.id))
                dispatch(increaseCollectionFavoriteCount(props.item.id))
                setTimeout(() => {
                    setFavoritable(true)
                }, 1000)

            }).catch((err) => {
                toast.error("Error adding to favorites")
                setTimeout(() => {
                    setFavoritable(true)
                }, 1000)
            })
        }


    }

    return (
        <C.Collection>


            <C.CollectionImg to={`/app/collections/${toLink(props.item.name)}`}>
                <img src={env.API.CDN + "collections/covers/" + props.item.cover} alt={props.item.name + " Image"} />
            </C.CollectionImg>
            <C.CollectionContent>
                <C.CollectionName to={`/app/collections/${toLink(props.item.name)}`}>{props.item.name}</C.CollectionName>
                {props.stats !== false && (
                    <C.CollectionStats>
                        {props.volfloor !== false && (
                            <C.CollectionVolFloor onClick={onClick}>
                                <C.CollectionFloor>Floor: <span>0</span> <img src="/images/chains/solana-icon.png" /></C.CollectionFloor>
                                <C.CollectionVolume>Vol 24h: <span>0</span> <img src="/images/chains/solana-icon.png" /></C.CollectionVolume>
                            </C.CollectionVolFloor>
                        )}
                        {/*props.chart !== false && (
                            <C.CollectionChart onClick={onChartClick} data-tip='Analytics'>
                                <LineChartSvg data={data} color={Hex2Rgba(color.white, .6)} svgWidth={92} svgHeight={30} />
                            </C.CollectionChart>
                        )*/}
                    </C.CollectionStats>
                )}

                {(props.social && props.social === true) && (
                    <C.CollectionSocial>
                        {props.item.twitter && (
                            <C.CollectionSocialItem href={formatLink(props.item.twitter)} target="_blank" hc="#1DA1F2">
                                <FontAwesomeIcon icon={faTwitter} />
                            </C.CollectionSocialItem>
                        )}
                        {props.item.discord && (
                            <C.CollectionSocialItem href={formatLink(props.item.discord)} target="_blank" hc="#5865F2">
                                <FontAwesomeIcon icon={faDiscord} />
                            </C.CollectionSocialItem>
                        )}
                        {props.item.website && (
                            <C.CollectionSocialItem href={formatLink(props.item.website)} target="_blank" hc={color.primary}>
                                <FontAwesomeIcon icon={faGlobe} />
                            </C.CollectionSocialItem>
                        )}
                    </C.CollectionSocial>
                )}

            </C.CollectionContent>

            {props.actions !== false && (
                <C.Actions>
                    <C.Action active={(user && user.favorites.includes(props.item.id)) ? "true" : "false"}>
                        <FontAwesomeIcon icon={(favoriteHover || (user && user.favorites.includes(props.item.id))) ? faHeart : faHeartAlt} onMouseOver={() => setFavoriteHover(true)} onMouseLeave={() => setFavoriteHover(false)} onClick={handleFavorite} />
                        <C.ActionText>{props.item.favoriteCount}</C.ActionText>
                    </C.Action>
                    {props.alert !== false && (
                        <C.Action active="false" >
                            <FontAwesomeIcon icon={bellHover ? faBell : faBellAlt} onMouseOver={() => setBellHover(true)} onMouseLeave={() => setBellHover(false)} />
                        </C.Action>
                    )}
                </C.Actions>
            )}



        </C.Collection>
    )

}

export default Collection