import Button from "components/ui/button"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { introduced } from "services/api/profile"
import { showEditProfilePopup, showIntroductionPopup } from "services/slices/popup"
import { setIntroduced, setUserIntroduced } from "services/slices/user"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import * as C from "./style"
import { displayEditProfile } from "utils/parts"

const Introduction = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        return () => {
            introduced().then(res => {})
            dispatch(setIntroduced(true))
        }
    }, [])

    const close = () => {
        dispatch(showIntroductionPopup(false))
    }

    const create = () => {
        dispatch(showIntroductionPopup(false))
        displayEditProfile(true, true)
    }

    return (
        <C.Introduction>
            <C.Rocket>
                <C.RocketBg>
                    <img src="/images/etc/wlrocketbg.png" alt="rocket" />
                </C.RocketBg>
                <C.RocketImage>
                    <img src="/images/etc/wlrocket.png" alt="rocket" />
                </C.RocketImage>
            </C.Rocket>

            <C.Title>
            Almost there! 🚀
            </C.Title>

            <C.Message>
            Thank you for joining hub3
            </C.Message>

            <C.Description>
               Start by setting up your <span>key account</span>. This allows other users to purchase keys from you. Engage in chats, and <span>earn</span> from <span>trade fees</span>.
            </C.Description>

            <C.Buttons>
                <Button onClick={close} theme="gradient-border-transparent-bg-on-hover" block="true" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)}>
                    Skip for now
                </Button>
                <Button onClick={create} theme="gradient" block="true" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)}>
                    Create Key Account
                </Button>
            </C.Buttons>
        </C.Introduction>
    )
}

export default Introduction