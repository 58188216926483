import { faDiscord, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faGlobe, faSpinner, faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/ui/button'
import env from 'env'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { getPlace } from 'services/api/places'
import { showPlacepopup } from 'services/slices/popup'
import { color } from 'styles/theme'
import { formatLink, Hex2Rgba } from 'utils/helpers'
import * as C from './style'

const Place = () => {

    const [place, setPlace] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<boolean>(false)
    const currentViewingPlace = useSelector((state: any) => state.data.currentViewingPlace)
    const dispatch = useDispatch()

    useEffect(() => {
        getPlace(currentViewingPlace).then((res: any) => {
            setPlace(res.data.place)
            setLoading(false)
        }).catch((err: any) => {
            toast.error("Error loading user data")
            setLoading(false)
            setError(true)
        })
    }, [])

    useEffect(() => {
        ReactTooltip.rebuild()
        ReactTooltip.hide()
    }, [place])

    const hide = () => {
        dispatch(showPlacepopup(false))
    }

    if (loading)
        return (<C.Loading><FontAwesomeIcon icon={faSpinner} spin /></C.Loading>)

    if (error)
        return (<C.Loading>Failed to load place data</C.Loading>)

    return (
        <C.Place>
            <C.Hide onClick={hide}>&times;</C.Hide>
            <C.PlaceTop bg={place.cover ? env.API.CDN + "places/covers/" + place.cover : ""}>
                <C.PlaceTopLeft>
                    <C.Avatar>
                        <img src={place.avatar ? env.API.CDN + "places/avatars/" + place.avatar : "/images/etc/user.webp"} alt="avatar" />
                    </C.Avatar>
                    <C.UserName>
                        <C.Name>{place.name}</C.Name>
                    </C.UserName>
                </C.PlaceTopLeft>
                <C.Social>
                    {place.twitter && (
                        <a data-tip="Go to Twitter Account" href={formatLink(place.twitter)} target="_blank" >
                            <FontAwesomeIcon icon={faTwitter} />
                        </a>
                    )}
                    {place.discord && (
                        <a data-tip="Go to Discord" href={formatLink(place.discord)} target="_blank" >
                            <FontAwesomeIcon icon={faDiscord} />
                        </a>
                    )}
                    {place.website && (
                        <a data-tip="Go to Website" href={formatLink(place.website)} target="_blank" >
                            <FontAwesomeIcon icon={faGlobe} />
                        </a>
                    )}
                </C.Social>
            </C.PlaceTop>

            <C.PlaceBottom>

                <C.Description>
                    {place.description}
                </C.Description>

                <C.Expand>
                    {place.website && (
                        <Button link="link" target="_blank" href={formatLink(place.website)} block="true" theme="gradient" bg={Hex2Rgba(color.white, .05)} color={color.white}>
                            Visit Website <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                        </Button>
                    )}
                </C.Expand>
            </C.PlaceBottom>
        </C.Place>
    )

}

export default Place