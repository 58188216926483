import ReactDOM from 'react-dom/client'

// styles
import 'styles/base.scss'
import 'styles/fontStyles.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// services
import { Provider } from 'react-redux'
import { store } from 'services/store'
import { BrowserRouter } from 'react-router-dom'
import { CustomRouter } from "components/etc/CustomRouter"
import { history } from 'components/etc/history'
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";

// components
import App from 'App'
import Pages from 'components/pages'
import MobilePages from 'components/mobile'
import Popups from 'components/popups'
import WhitelistPage from "components/pages/whitelist"
import LaunchPage from "components/pages/launch"

// plugins
import ReactTooltip from 'react-tooltip'
import { Toaster } from 'react-hot-toast'
import { color } from 'styles/theme'
import { Hex2Rgba } from 'utils/helpers'
import env from 'env'
import { responseInterceptor } from 'interceptors';
import { loghub3 } from 'utils/log';
import Router from "./router"

responseInterceptor()
loghub3()
// render the app
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <Router/>
)