import { PublicKey, LAMPORTS_PER_SOL, Connection, ComputeBudgetProgram, SYSVAR_INSTRUCTIONS_PUBKEY, Transaction } from '@solana/web3.js'
import * as anchor from "@project-serum/anchor"
import { AUTH_RULES_PROGRAM_ID, METADATA_PROGRAM_ID, SOLANA_CONNECTION, WaitForTransaction } from '../connection'
import { toAnchorWallet } from '../wallet'
import { findWallet } from 'modules/wallet'
import { store } from 'services/store'
import { IDL } from './types'
import { Wallet } from "@project-serum/anchor/dist/cjs/provider"
import { getShuffleError, parseCustomProgramError } from './shuffleErrors'
import { LEDGER_SIZE } from './constants'
import toast from 'react-hot-toast'
import * as spltoken from '@solana/spl-token'
import { findTokenRecordPda, getATokenAccountsNeedCreate, getRulesetOfPNFT } from './helpers'

const systemProgram = anchor.web3.SystemProgram

const viewWallet = {
    signTransaction: null,
    signAllTransactions: null,
    publicKey: null
} as unknown as Wallet

export const getLedger = async (programId: string, ledgerAddress: string) => {

    const anchorConnection = new anchor.AnchorProvider(SOLANA_CONNECTION, viewWallet, anchor.AnchorProvider.defaultOptions())

    const program = new anchor.Program(IDL as anchor.Idl, programId, anchorConnection)

    const ledgerAccount: any = await program.account.liquidatorLedger.fetch(new anchor.web3.PublicKey(ledgerAddress))
    const lockAccount : any = await getLedgerLocksAccount(programId, ledgerAddress)

    return {
        publicKey: ledgerAddress,
        account: {
            authority: ledgerAccount.authority.toBase58(),
            liquidations: ledgerAccount.liquidations.map((liquidation: any) => {
                return {
                    address: liquidation.address.toBase58(),
                    mint: liquidation.mint.toBase58(),
                    amount: [liquidation.amount[0].toNumber(), liquidation.amount[1].toNumber()],
                    assigned: 0,
                    time: liquidation.time.toNumber(),
                    frozen: liquidation.frozen
                }
            }),
            revenues: ledgerAccount.revenues.toNumber(),
            treasury: ledgerAccount.treasury.toBase58(),
        },
        lockAccount: {
            lockedNfts: lockAccount.lockedNfts.map((nft: any) => {
                return {
                    address: nft.address.toBase58(),
                    mint: nft.mint.toBase58(),
                }
            })
        }
    }


}

export const getAllLedgersOfUser = async (programId: string, treasury: string, authority: string) => {

    const anchorConnection = new anchor.AnchorProvider(SOLANA_CONNECTION, viewWallet, anchor.AnchorProvider.defaultOptions())

    const program = new anchor.Program(IDL as anchor.Idl, programId, anchorConnection)


    const ledgerAccounts = await program.account.liquidatorLedger.all([
        {
            memcmp: {
                offset: 8,
                bytes: authority
            }
        },
        {
            memcmp: {
                offset: 8 + 32,
                bytes: treasury
            }
        }
    ])

    return ledgerAccounts.map((ledger: any) => {
        return {
            publicKey: ledger.publicKey.toBase58(),
            account: {
                authority: ledger.account.authority.toBase58(),
                liquidations: ledger.account.liquidations.map((liquidation: any) => {
                    return {
                        address: liquidation.address.toBase58(),
                        mint: liquidation.mint.toBase58(),
                        amount: [liquidation.amount[0].toNumber(), liquidation.amount[1].toNumber()],
                        assigned: 0,
                        time: liquidation.time.toNumber(),
                        frozen: liquidation.frozen
                    }
                }),
                revenues: ledger.account.revenues.toNumber(),
                treasury: ledger.account.treasury.toBase58(),
            }
        }
    })

}

export const getLedgerLocksAccount = async (programId: string, ledger: string) => {

    const anchorConnection = new anchor.AnchorProvider(SOLANA_CONNECTION, viewWallet, anchor.AnchorProvider.defaultOptions())

    const program = new anchor.Program(IDL as anchor.Idl, programId, anchorConnection)

    const [ledgerLocksPDA, _] = anchor.web3.PublicKey.findProgramAddressSync(
        [anchor.utils.bytes.utf8.encode('ledger-lock'), new PublicKey(ledger).toBuffer()],
        program.programId
    )

    const ledgerLocksAccount = await program.account.ledgerLocks.fetch(ledgerLocksPDA)

    return ledgerLocksAccount
}

export const lockNft = async (programId: string, treasuryAddress: string, vault: string, ledgerAddress: string, collectionAddress: string, cmid: string, mint: string, metadata: string) => {

    const wallet = findWallet(store.getState().user.selectedWallet)

    if (!wallet) {
        throw new Error('No wallet selected')
    }

    const anchorConnection = new anchor.AnchorProvider(SOLANA_CONNECTION, toAnchorWallet(wallet), anchor.AnchorProvider.defaultOptions())

    const program = new anchor.Program(IDL as anchor.Idl, programId, anchorConnection)

    const { atokenix, destatokenAccount } = await getATokenAccountsNeedCreate(new PublicKey(wallet.address[0]), new PublicKey(vault), new PublicKey(mint))

    const preInstructions = [];

    if (atokenix) {
        //tx.add(atokenix);
        preInstructions.push(atokenix);
    }

    const tokenAccount = await spltoken.getAssociatedTokenAddress(new PublicKey(mint), new PublicKey(wallet.address[0]))

    const [ledgerLocksPDA, _] = anchor.web3.PublicKey.findProgramAddressSync(
        [anchor.utils.bytes.utf8.encode('ledger-lock'), new PublicKey(ledgerAddress).toBuffer()],
        program.programId
    )

    const tx = await program.methods.ledgerLockNft(
        new PublicKey(collectionAddress),
        new PublicKey(cmid),
    ).accounts({
        authority: new PublicKey(wallet.address[0]),
        treasury: new PublicKey(treasuryAddress),
        ledger: new PublicKey(ledgerAddress),
        ledgerLocks: ledgerLocksPDA,
        nftMintAccount: new PublicKey(mint),
        nftTokenAcccount: tokenAccount,
        nftMetadataAccount: new PublicKey(metadata),
        vault: new PublicKey(vault),
        vaultNftTokenAccount: destatokenAccount,
        systemProgram: systemProgram.programId,
        tokenProgram: spltoken.TOKEN_PROGRAM_ID,
        associatedTokenProgram: spltoken.ASSOCIATED_TOKEN_PROGRAM_ID
    }).preInstructions(preInstructions).transaction()

    tx.recentBlockhash = (await SOLANA_CONNECTION.getLatestBlockhash("finalized")).blockhash;
    tx.feePayer = new PublicKey(wallet.address[0])

    return tx;

}

export const unlockNft = async (programId: string, treasuryAddress: string, vaultAddress: string, ledgerAddress: string, mint: string, metadata: string, collection:string) => {

    const wallet = findWallet(store.getState().user.selectedWallet)

    if (!wallet) {
        throw new Error('No wallet selected')
    }

    const anchorConnection = new anchor.AnchorProvider(SOLANA_CONNECTION, toAnchorWallet(wallet), anchor.AnchorProvider.defaultOptions())

    const program = new anchor.Program(IDL as anchor.Idl, programId, anchorConnection)

    //get token account
    const { atokenix, destatokenAccount } = await getATokenAccountsNeedCreate(new PublicKey(wallet.address[0]), new PublicKey(wallet.address[0]), new PublicKey(mint))

    const preInstructions = [];

    if (atokenix) {
        //tx.add(atokenix);
        preInstructions.push(atokenix);
    }

    const vaultTokenAccount = await spltoken.getAssociatedTokenAddress(new PublicKey(mint), new PublicKey(vaultAddress), true)

    const [ledgerLocksPDA, _] = anchor.web3.PublicKey.findProgramAddressSync(
        [anchor.utils.bytes.utf8.encode('ledger-lock'), new PublicKey(ledgerAddress).toBuffer()],
        program.programId
    )

    /*let rulesetData = await getRulesetOfPNFT(programId, collection)

    const [masterEdition] = PublicKey.findProgramAddressSync(
        [
            Buffer.from('metadata'),
            METADATA_PROGRAM_ID.toBuffer(),
            new PublicKey(mint).toBuffer(),
            Buffer.from('edition'),
        ],
        METADATA_PROGRAM_ID,
    );*/

    //console.log("vaultTokenAccount", vaultTokenAccount.toBase58())

    const modifyComputeUnits = ComputeBudgetProgram.setComputeUnitLimit({
        units: 1000000
    });

    const addPriorityFee = ComputeBudgetProgram.setComputeUnitPrice({
        microLamports: 1
    });

    const dtx = await program.methods.ledgerUnlockNft(
        new PublicKey(mint)
    ).accounts({
        authority: new PublicKey(wallet.address[0]),
        treasury: new PublicKey(treasuryAddress),
        ledger: new PublicKey(ledgerAddress),
        ledgerLocks: ledgerLocksPDA,
        vault: new PublicKey(vaultAddress),
        nftTokenAcccount: new PublicKey(destatokenAccount),
        nftMintAccount: new PublicKey(mint),
        nftMetadataAccount: new PublicKey(metadata),
        vaultNftTokenAccount: new PublicKey(vaultTokenAccount),
        systemProgram: systemProgram.programId,
        tokenProgram: spltoken.TOKEN_PROGRAM_ID,
        associatedTokenProgram: spltoken.ASSOCIATED_TOKEN_PROGRAM_ID,

        /*sysvarInstruction: SYSVAR_INSTRUCTIONS_PUBKEY,
        metadataProgram: METADATA_PROGRAM_ID,
        masterEdition: masterEdition,
        rulesetPda: rulesetData.ruleset,
        collectionDatas: rulesetData.collectionDatasPDA,
        authorizationRulesProgram: AUTH_RULES_PROGRAM_ID,
        ownerTokenRecord: findTokenRecordPda(new PublicKey(mint), vaultTokenAccount),
        destTokenRecord: findTokenRecordPda(new PublicKey(mint), destatokenAccount),*/
        
    }).preInstructions(preInstructions).transaction()

    const tx = new Transaction().add(modifyComputeUnits).add(addPriorityFee).add(dtx)

    tx.recentBlockhash = (await SOLANA_CONNECTION.getLatestBlockhash("finalized")).blockhash;
    tx.feePayer = new PublicKey(wallet.address[0])

    return tx;

}

export const createLedgerAccount = async (programId: string, treasuryAddress: string) => new Promise(async (resolve, reject) => {

    /*// Check if ledger account already exists
    try {
        let get = await getAllLedgers(programId, treasuryAddress, store.getState().user.selectedWallet)
        if (get) {
            return resolve(true) // Ledger account already exists
        }
    } catch (error) {
        if (error !== "no-ledger")
            return reject("Error creating Liquidator Account") // we dont know ledger account exists or not so we reject
    }*/

    const wallet = findWallet(store.getState().user.selectedWallet)

    if (!wallet) {
        throw new Error('No wallet selected')
    }

    const anchorConnection = new anchor.AnchorProvider(SOLANA_CONNECTION, toAnchorWallet(wallet), anchor.AnchorProvider.defaultOptions())

    const program = new anchor.Program(IDL as anchor.Idl, programId, anchorConnection)

    const ledger = anchor.web3.Keypair.generate()

    const [ledgerLocksPDA, _] = anchor.web3.PublicKey.findProgramAddressSync(
        [anchor.utils.bytes.utf8.encode('ledger-lock'), ledger.publicKey.toBuffer()],
        program.programId
    )


    const tx = await program.methods.createLedger().accounts({
        authority: new PublicKey(wallet.address[0]),
        treasury: new PublicKey(treasuryAddress),
        ledger: ledger.publicKey,
        ledgerLocks: ledgerLocksPDA,
        systemProgram: systemProgram.programId
    })
        .signers([ledger])
        .preInstructions([
            await program.account.liquidatorLedger.createInstruction(ledger, LEDGER_SIZE)
        ])
        .transaction()


    tx.recentBlockhash = (await SOLANA_CONNECTION.getLatestBlockhash("finalized")).blockhash;
    tx.feePayer = new PublicKey(wallet.address[0])
    tx.partialSign(ledger)


    //console.log("tx", tx)
    let signed;

    try {
        signed = await wallet.instance.signTransaction(tx)
    } catch (e) {
        return reject(getShuffleError("0x0"))
    }

    //console.log("signed", signed)

    let loading = toast.loading("Creating Liquidator Account...")

    try {
        const sig = await SOLANA_CONNECTION.sendRawTransaction(signed.serialize(), { skipPreflight: true, preflightCommitment: "finalized" })
        //console.log("sig", sig)

        WaitForTransaction(sig).then((res) => {

            toast.dismiss(loading)
            toast.success("Liquidator Account Created")
            resolve(true)
        }).catch((err) => {
            if (!err || !err.InstructionError || !err.InstructionError[1] || !err.InstructionError[1].Custom)
                return reject("Error creating Liquidator Account")
            else {
                toast.dismiss(loading)
                let errorMessage = getShuffleError(err.InstructionError[1].Custom)
                if (!errorMessage)
                    errorMessage = "Error creating Liquidator Account"
                return reject(errorMessage)
            }
        })

    } catch (e: any) {

        console.log("error", e)
        toast.dismiss(loading)
        if (e.message && e.message.indexOf("Attempt to debit an account but found no record of a prior credit.") !== -1) {
            return reject(getShuffleError("0x1"))
        }

        reject("Error Creating Liquidator Account")
    }


})

export const getTreasury = async (programId: string, treasuryAddress: string) => {

    const anchorConnection = new anchor.AnchorProvider(SOLANA_CONNECTION, viewWallet, anchor.AnchorProvider.defaultOptions())

    const program = new anchor.Program(IDL as anchor.Idl, programId, anchorConnection)

    const treasury: any = await program.account.treasury.fetch(new PublicKey(treasuryAddress))

    return treasury

}

export const getSplBalance = async (wallet: string, mint: PublicKey) => {


    if (mint.toBase58() === "So11111111111111111111111111111111111111111") {
        const balance = await SOLANA_CONNECTION.getBalance(new PublicKey(wallet))
        return balance / LAMPORTS_PER_SOL
    }

    try {
        const token = await spltoken.getAssociatedTokenAddress(mint, new PublicKey(wallet))
        const balance = await SOLANA_CONNECTION.getTokenAccountBalance(token)
        return balance.value.uiAmount
    } catch (error) {
        console.log(error)
        return 0
    }
}

export const liquidateTreasuryToken = async (programId: string, treasuryAddress: string, vault: string, ledgerAddress: string, tokenAddress: string, amount: number, count: number) => new Promise(async (resolve, reject) => {

    const wallet = findWallet(store.getState().user.selectedWallet)

    if (!wallet) {
        throw new Error('No wallet selected')
    }

    const anchorConnection = new anchor.AnchorProvider(SOLANA_CONNECTION, toAnchorWallet(wallet), anchor.AnchorProvider.defaultOptions())

    const program = new anchor.Program(IDL as anchor.Idl, programId, anchorConnection)

    //get token account
    const { atokenix, destatokenAccount } = await getATokenAccountsNeedCreate(new PublicKey(wallet.address[0]), new PublicKey(vault), new PublicKey(tokenAddress))

    const preInstructions = [];

    if (atokenix) {
        preInstructions.push(atokenix);
    }

    const tokenAccount = await spltoken.getAssociatedTokenAddress(new PublicKey(tokenAddress), new PublicKey(wallet.address[0]))

    const [ledgerLocksPDA, _] = anchor.web3.PublicKey.findProgramAddressSync(
        [anchor.utils.bytes.utf8.encode('ledger-lock'), new PublicKey(ledgerAddress).toBuffer()],
        program.programId
    )

    const tx = await program.methods.liquidateTreasuryToken(
        new anchor.BN(count),
        new anchor.BN(amount * LAMPORTS_PER_SOL)
    ).accounts({
        authority: new PublicKey(wallet.address[0]),
        treasury: new PublicKey(treasuryAddress),
        ledger: new PublicKey(ledgerAddress),
        tokenAccount,
        ledgerLocks: ledgerLocksPDA,
        mintAccount: new PublicKey(tokenAddress),
        systemProgram: systemProgram.programId,
        vault: new PublicKey(vault),
        vaultTokenAccount: destatokenAccount,
        tokenProgram: spltoken.TOKEN_PROGRAM_ID,
        associatedTokenProgram: spltoken.ASSOCIATED_TOKEN_PROGRAM_ID
    }).preInstructions(preInstructions).transaction()

    tx.recentBlockhash = (await SOLANA_CONNECTION.getLatestBlockhash("finalized")).blockhash;
    tx.feePayer = new PublicKey(wallet.address[0])

    //console.log("tx", tx)
    let signed;

    try {
        signed = await wallet.instance.signTransaction(tx)
    } catch (e) {
        return reject(getShuffleError("0x0"))
    }

    //console.log("signed", signed)

    let loading = toast.loading("Depositing...")

    try {
        const sig = await SOLANA_CONNECTION.sendRawTransaction(signed.serialize(), { skipPreflight: true, preflightCommitment: "finalized" })
        //console.log("sig", sig)

        WaitForTransaction(sig).then((res) => {

            toast.dismiss(loading)
            toast.success("Deposit Successful")
            resolve(true)
        }).catch((err) => {
            if (!err || !err.InstructionError || !err.InstructionError[1] || !err.InstructionError[1].Custom)
                return reject("Error Depositing")
            else {
                toast.dismiss(loading)
                let errorMessage = getShuffleError(err.InstructionError[1].Custom)
                if (!errorMessage)
                    errorMessage = "Error Depositing"
                return reject(errorMessage)
            }
        })

    } catch (e: any) {
        console.log("error", e)
        toast.dismiss(loading)
        if (e.message && e.message.indexOf("Attempt to debit an account but found no record of a prior credit.") !== -1) {
            return reject(getShuffleError("0x1"))
        }

        reject("Error Depositing")
    }

})

export const liquidateTreasuryNative = async (programId: string, treasuryAddress: string, vault: string, ledgerAddress: string, amount: number, count: number) => new Promise(async (resolve, reject) => {

    const wallet = findWallet(store.getState().user.selectedWallet)

    if (!wallet) {
        throw new Error('No wallet selected')
    }

    const anchorConnection = new anchor.AnchorProvider(SOLANA_CONNECTION, toAnchorWallet(wallet), anchor.AnchorProvider.defaultOptions())

    const program = new anchor.Program(IDL as anchor.Idl, programId, anchorConnection)

    const [ledgerLocksPDA, _] = anchor.web3.PublicKey.findProgramAddressSync(
        [anchor.utils.bytes.utf8.encode('ledger-lock'), new PublicKey(ledgerAddress).toBuffer()],
        program.programId
    )

    const tx = await program.methods.liquidateTreasuryNative(
        new anchor.BN(count),
        new anchor.BN(amount * LAMPORTS_PER_SOL)
    ).accounts({
        authority: new PublicKey(wallet.address[0]),
        treasury: new PublicKey(treasuryAddress),
        ledger: new PublicKey(ledgerAddress),
        ledgerLocks: ledgerLocksPDA,
        vault: new PublicKey(vault),
        systemProgram: systemProgram.programId
    }).transaction()

    tx.recentBlockhash = (await SOLANA_CONNECTION.getLatestBlockhash("finalized")).blockhash;
    tx.feePayer = new PublicKey(wallet.address[0])

    //console.log("tx", tx)
    let signed;

    try {
        signed = await wallet.instance.signTransaction(tx)
    } catch (e) {
        return reject(getShuffleError("0x0"))
    }

    //console.log("signed", signed)

    let loading = toast.loading("Depositing...")

    try {
        const sig = await SOLANA_CONNECTION.sendRawTransaction(signed.serialize(), { skipPreflight: false, preflightCommitment: "finalized" })
        console.log("sig", sig)

        WaitForTransaction(sig).then((res) => {

            toast.dismiss(loading)
            toast.success("Deposit Successful")
            resolve(true)
        }).catch((err) => {
            if (!err || !err.InstructionError || !err.InstructionError[1] || !err.InstructionError[1].Custom)
                return reject("Error Depositing")
            else {
                toast.dismiss(loading)
                let errorMessage = getShuffleError(err.InstructionError[1].Custom)
                if (!errorMessage)
                    errorMessage = "Error Depositing"
                return reject(errorMessage)
            }
        })

    } catch (e: any) {
        console.log("error", e)
        toast.dismiss(loading)
        if (e.message && e.message.indexOf("Attempt to debit an account but found no record of a prior credit.") !== -1) {
            return reject(getShuffleError("0x1"))
        }

        reject("Error Depositing")
    }

})

export const liquidateTreasuryNft = async (programId: string, treasuryAddress: string, vault: string, ledgerAddress: string, collectionAddress: string, cmid: string, mint: string, metadata: string) => {

    const wallet = findWallet(store.getState().user.selectedWallet)

    if (!wallet) {
        throw new Error('No wallet selected')
    }

    const anchorConnection = new anchor.AnchorProvider(SOLANA_CONNECTION, toAnchorWallet(wallet), anchor.AnchorProvider.defaultOptions())

    const program = new anchor.Program(IDL as anchor.Idl, programId, anchorConnection)

    const { atokenix, destatokenAccount } = await getATokenAccountsNeedCreate(new PublicKey(wallet.address[0]), new PublicKey(vault), new PublicKey(mint))

    const preInstructions = [];

    if (atokenix) {
        //tx.add(atokenix);
        preInstructions.push(atokenix);
    }

    const tokenAccount = await spltoken.getAssociatedTokenAddress(new PublicKey(mint), new PublicKey(wallet.address[0]))

    const [ledgerLocksPDA, _] = anchor.web3.PublicKey.findProgramAddressSync(
        [anchor.utils.bytes.utf8.encode('ledger-lock'), new PublicKey(ledgerAddress).toBuffer()],
        program.programId
    )

    const tx = await program.methods.liquidateTreasuryNft(
        new PublicKey(collectionAddress),
        new PublicKey(cmid),
    ).accounts({
        authority: new PublicKey(wallet.address[0]),
        treasury: new PublicKey(treasuryAddress),
        ledger: new PublicKey(ledgerAddress),
        ledgerLocks: ledgerLocksPDA,
        nftMintAccount: new PublicKey(mint),
        nftTokenAcccount: tokenAccount,
        nftMetadataAccount: new PublicKey(metadata),
        vault: new PublicKey(vault),
        vaultNftTokenAccount: destatokenAccount,
        systemProgram: systemProgram.programId,
        tokenProgram: spltoken.TOKEN_PROGRAM_ID,
        associatedTokenProgram: spltoken.ASSOCIATED_TOKEN_PROGRAM_ID
    }).preInstructions(preInstructions).transaction()

    tx.recentBlockhash = (await SOLANA_CONNECTION.getLatestBlockhash("finalized")).blockhash;
    tx.feePayer = new PublicKey(wallet.address[0])

    return tx;

}

export const withdrawLiquidityToken = async (programId: string, vaultAddress: string, ledgerAddress: string, targetIndex: number, tokenAddress: number) => new Promise(async (resolve, reject) => {

    const wallet = findWallet(store.getState().user.selectedWallet)

    if (!wallet) {
        throw new Error('No wallet selected')
    }

    const anchorConnection = new anchor.AnchorProvider(SOLANA_CONNECTION, toAnchorWallet(wallet), anchor.AnchorProvider.defaultOptions())

    const program = new anchor.Program(IDL as anchor.Idl, programId, anchorConnection)

    //get token account
    const { atokenix, destatokenAccount } = await getATokenAccountsNeedCreate(new PublicKey(wallet.address[0]), new PublicKey(wallet.address[0]), new PublicKey(tokenAddress))

    const preInstructions = [];

    if (atokenix) {
        //tx.add(atokenix);
        preInstructions.push(atokenix);
    }

    const vaultTokenAccount = await spltoken.getAssociatedTokenAddress(new PublicKey(tokenAddress), new PublicKey(vaultAddress), true)

    const [ledgerLocksPDA, _] = anchor.web3.PublicKey.findProgramAddressSync(
        [anchor.utils.bytes.utf8.encode('ledger-lock'), new PublicKey(ledgerAddress).toBuffer()],
        program.programId
    )

    const tx = await program.methods.withdrawTokenFromTreasury(
        new anchor.BN(targetIndex)
    ).accounts({
        authority: new PublicKey(wallet.address[0]),
        ledger: new PublicKey(ledgerAddress),
        ledgerLocks: ledgerLocksPDA,
        vault: new PublicKey(vaultAddress),
        vaultTokenAccount: new PublicKey(vaultTokenAccount),
        tokenAccount: destatokenAccount,
        mintAccount: new PublicKey(tokenAddress),
        systemProgram: systemProgram.programId,
        tokenProgram: spltoken.TOKEN_PROGRAM_ID,
        associatedTokenProgram: spltoken.ASSOCIATED_TOKEN_PROGRAM_ID
    }).preInstructions(preInstructions).transaction()

    tx.recentBlockhash = (await SOLANA_CONNECTION.getLatestBlockhash("finalized")).blockhash;
    tx.feePayer = new PublicKey(wallet.address[0])

    //console.log("tx", tx)

    let signed;

    try {
        signed = await wallet.instance.signTransaction(tx)
    } catch (e) {
        return reject(getShuffleError("0x0"))
    }

    //console.log("signed", signed)

    let loading = toast.loading("Withdrawing...")

    try {
        const sig = await SOLANA_CONNECTION.sendRawTransaction(signed.serialize(), { skipPreflight: false, preflightCommitment: "finalized" })
        //console.log("sig", sig)

        WaitForTransaction(sig).then((res) => {

            toast.dismiss(loading)
            toast.success("Withdraw Successful")
            resolve(true)
        }).catch((err) => {
            if (!err || !err.InstructionError || !err.InstructionError[1] || !err.InstructionError[1].Custom)
                return reject("Error Withdrawing")
            else {
                toast.dismiss(loading)
                let errorMessage = getShuffleError(err.InstructionError[1].Custom)
                if (!errorMessage)
                    errorMessage = "Error Withdrawing"
                return reject(errorMessage)
            }
        })

    } catch (e: any) {
        console.log("error", e)
        toast.dismiss(loading)
        if (e.message && e.message.indexOf("Attempt to debit an account but found no record of a prior credit.") !== -1) {
            return reject(getShuffleError("0x1"))
        }

        reject("Error Withdrawing")
    }

})

export const withdrawLiquidityNative = async (programId: string, vaultAddress: string, ledgerAddress: string, targetIndex: number) => new Promise(async (resolve, reject) => {

    const wallet = findWallet(store.getState().user.selectedWallet)

    if (!wallet) {
        throw new Error('No wallet selected')
    }

    const anchorConnection = new anchor.AnchorProvider(SOLANA_CONNECTION, toAnchorWallet(wallet), anchor.AnchorProvider.defaultOptions())

    const program = new anchor.Program(IDL as anchor.Idl, programId, anchorConnection)

    const tx = await program.methods.withdrawNativeFromTreasury(
        new anchor.BN(targetIndex)
    ).accounts({
        authority: new PublicKey(wallet.address[0]),
        ledger: new PublicKey(ledgerAddress),
        vault: new PublicKey(vaultAddress),
        systemProgram: systemProgram.programId
    }).transaction()

    tx.recentBlockhash = (await SOLANA_CONNECTION.getLatestBlockhash("finalized")).blockhash;
    tx.feePayer = new PublicKey(wallet.address[0])

    //console.log("tx", tx)
    let signed;

    try {
        signed = await wallet.instance.signTransaction(tx)
    } catch (e) {
        return reject(getShuffleError("0x0"))
    }

    //console.log("signed", signed)

    let loading = toast.loading("Withdrawing...")

    try {
        const sig = await SOLANA_CONNECTION.sendRawTransaction(signed.serialize(), { skipPreflight: false, preflightCommitment: "finalized" })
        //console.log("sig", sig)

        WaitForTransaction(sig).then((res) => {

            toast.dismiss(loading)
            toast.success("Withdraw Successful")
            resolve(true)
        }).catch((err) => {
            if (!err || !err.InstructionError || !err.InstructionError[1] || !err.InstructionError[1].Custom)
                return reject("Error Withdrawing")
            else {
                toast.dismiss(loading)
                let errorMessage = getShuffleError(err.InstructionError[1].Custom)
                if (!errorMessage)
                    errorMessage = "Error Withdrawing"
                return reject(errorMessage)
            }
        })

    } catch (e: any) {
        console.log("error", e)
        toast.dismiss(loading)
        if (e.message && e.message.indexOf("Attempt to debit an account but found no record of a prior credit.") !== -1) {
            return reject(getShuffleError("0x1"))
        }

        reject("Error Withdrawing")
    }

})

export const withdrawLiquidityNft = async (programId: string, treasuryAddress: string, vaultAddress: string, ledgerAddress: string, mint: string, metadata: string) => {

    const wallet = findWallet(store.getState().user.selectedWallet)

    if (!wallet) {
        throw new Error('No wallet selected')
    }

    const anchorConnection = new anchor.AnchorProvider(SOLANA_CONNECTION, toAnchorWallet(wallet), anchor.AnchorProvider.defaultOptions())

    const program = new anchor.Program(IDL as anchor.Idl, programId, anchorConnection)

    //get token account
    const { atokenix, destatokenAccount } = await getATokenAccountsNeedCreate(new PublicKey(wallet.address[0]), new PublicKey(wallet.address[0]), new PublicKey(mint))

    const preInstructions = [];

    if (atokenix) {
        //tx.add(atokenix);
        preInstructions.push(atokenix);
    }

    const vaultTokenAccount = await spltoken.getAssociatedTokenAddress(new PublicKey(mint), new PublicKey(vaultAddress), true)

    //console.log("vaultTokenAccount", vaultTokenAccount.toBase58())

    const tx = await program.methods.withdrawNftFromTreasury(
        new PublicKey(mint)
    ).accounts({
        authority: new PublicKey(wallet.address[0]),
        treasury: new PublicKey(treasuryAddress),
        ledger: new PublicKey(ledgerAddress),
        vault: new PublicKey(vaultAddress),
        nftTokenAcccount: new PublicKey(destatokenAccount),
        nftMintAccount: new PublicKey(mint),
        nftMetadataAccount: new PublicKey(metadata),
        vaultNftTokenAccount: new PublicKey(vaultTokenAccount),
        systemProgram: systemProgram.programId,
        tokenProgram: spltoken.TOKEN_PROGRAM_ID,
        associatedTokenProgram: spltoken.ASSOCIATED_TOKEN_PROGRAM_ID
    }).preInstructions(preInstructions).transaction()

    tx.recentBlockhash = (await SOLANA_CONNECTION.getLatestBlockhash("finalized")).blockhash;
    tx.feePayer = new PublicKey(wallet.address[0])

    return tx;


}

export const claimRevenue = async (programId: string, vaultAddress: string, ledgerAddress: string) => new Promise(async (resolve, reject) => {

    const wallet = findWallet(store.getState().user.selectedWallet)

    if (!wallet) {
        throw new Error('No wallet selected')
    }

    const anchorConnection = new anchor.AnchorProvider(SOLANA_CONNECTION, toAnchorWallet(wallet), anchor.AnchorProvider.defaultOptions())

    const program = new anchor.Program(IDL as anchor.Idl, programId, anchorConnection)

    const tx = await program.methods.claimRevenue()
        .accounts({
            authority: new PublicKey(wallet.address[0]),
            ledger: new PublicKey(ledgerAddress),
            vault: new PublicKey(vaultAddress),
            systemProgram: systemProgram.programId
        }).transaction()

    tx.recentBlockhash = (await SOLANA_CONNECTION.getLatestBlockhash("finalized")).blockhash;
    tx.feePayer = new PublicKey(wallet.address[0])

    //console.log("tx", tx)
    let signed;

    try {
        signed = await wallet.instance.signTransaction(tx)
    } catch (e) {
        return reject(getShuffleError("0x0"))
    }

    //console.log("signed", signed)

    let loading = toast.loading("Claiming Revenue...")

    try {
        const sig = await SOLANA_CONNECTION.sendRawTransaction(signed.serialize(), { skipPreflight: false, preflightCommitment: "finalized" })
        //console.log("sig", sig)

        WaitForTransaction(sig).then((res) => {

            toast.dismiss(loading)
            toast.success("Revenue Claimed Successfully")
            resolve(true)
        }).catch((err) => {
            if (!err || !err.InstructionError || !err.InstructionError[1] || !err.InstructionError[1].Custom)
                return reject("Error Claiming Revenue")
            else {
                toast.dismiss(loading)
                let errorMessage = getShuffleError(err.InstructionError[1].Custom)
                if (!errorMessage)
                    errorMessage = "Error Claiming Revenue"
                return reject(errorMessage)
            }
        })

    } catch (e: any) {
        console.log("error", e)
        toast.dismiss(loading)
        if (e.message && e.message.indexOf("Attempt to debit an account but found no record of a prior credit.") !== -1) {
            return reject(getShuffleError("0x1"))
        }

        reject("Error Claiming Revenue")
    }


})
