
import Button from "components/ui/button"
import env from "env"
import { Recruit } from "models/enums/recruit"
import { _RecruitTwitterNomination } from "models/interfaces/recruitTypes"
import React, { useEffect } from "react"
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { RefreshUser } from "services/managers/user"
import { setCurrentViewingHolderWhitelisted } from "services/slices/data"
import { store } from "services/store"
import { color } from "styles/theme"
import { GetTwitterHandle, Hex2Rgba, toLink, GetRandomGif } from "utils/helpers"
import ProfileInfo from "./info"
import Nfts from "./nfts"

import * as C from "./style"

const Profile = () => {

    const user = useSelector((state: any) => state.user.user)
    const viewingUser = useSelector((state: any) => state.data.currentViewingHolder)
    const userLoaded = useSelector((state: any) => state.user.userLoaded)
    const currentViewingWhitelist = useSelector((state: any) => state.holders.currentViewingWhitelist)
    const currentViewingWhitelistType = useSelector((state: any) => state.whitelist.currentViewingWhitelistType)
    const currentViewingHolderWhitelisted = useSelector((state: any) => state.data.currentViewingHolderWhitelisted)
    const wlentry = useSelector((state: any) => state.whitelist.entry)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!viewingUser)
            RefreshUser()
    }, [viewingUser])

    useEffect(() => {
        return () => {
            if (currentViewingHolderWhitelisted)
                dispatch(setCurrentViewingHolderWhitelisted(false))
        }
    }, [])

    const AskForNomination = () => {
        if (!user.twitter) {
            toast.error("User has no twitter account linked")
            return
        }

        let item = store.getState().whitelist.currentWhitelist

        const tweetText = `Hey @${user.twitter}%0aPlease kindly ${(item.recruitDetails as _RecruitTwitterNomination).nominationWord} me for%0a${item.name} whitelist${GetTwitterHandle(item.twitter)}%0a%0a${env.API.WEB}app/whitelist/${toLink(item.name)}%0a${GetRandomGif(item)}`
        const tweetUrl = `https://twitter.com/intent/tweet?text=${tweetText}`
        if (store.getState().data.isMobile)
            window.location.href = tweetUrl
        else
            window.open(tweetUrl, '_blank')
    }

    const isUserMe = () => {
        if (!user || !viewingUser)
            return false
        return user.id === viewingUser.id
    }

    if (!userLoaded)
        return <></>

    return (
        <C.Profile>
            <ProfileInfo user={viewingUser ?? user} self={viewingUser ? user ? (viewingUser.id === user.id ? true : false) : false : true} />
            <Nfts user={viewingUser ?? user} />

            <C.Nomination>
                {(currentViewingWhitelist && currentViewingWhitelistType === Recruit.TwitterNomination && currentViewingHolderWhitelisted && !isUserMe() && !wlentry) && (
                    <Button onClick={AskForNomination} block="true" theme="gradient" fromcolor={color.gradient3.start} tocolor={color.gradient3.end} color={color.black} bg={Hex2Rgba(color.secondaryFade, .5)} style={{ fontWeight: "600" }}>
                        Ask for nominate
                    </Button>
                )}
            </C.Nomination>
        </C.Profile>
    )
}

export default Profile