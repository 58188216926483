import styled from "styled-components"

import { Dialog, DialogHeader } from "../base/style"

export const Page = styled.div`
    & ${Dialog} {
        padding: 0;
    }

    & ${DialogHeader} {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
    }
`