import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { deviceMax } from "styles/media"
import { Link } from "react-router-dom"
import { Row } from "styles"

export const Header = styled.div`
    display: flex;
    align-items: center;
`

export const LocksView = styled.div`
    background-color:${color.bg1};
    cursor:pointer;
    border-radius: 100px;
    padding: 8px 24px;
    color: ${color.whiteShade};
    display: flex;
    align-items: center;
    & svg{
        width: 16px;
        color: ${color.primary};
    }

    & span{
        color:${color.white};
    }

    &:hover{
        color:${color.primary};
    }
    font-size:14px;
    margin-right: 16px;
`

export const AccountSwap = styled.div`
    background-color:${color.bg1};
    cursor:pointer;
    border-radius: 100px;
    padding: 8px 24px;
    color: ${color.whiteShade};
    display: flex;
    align-items: center;
    & svg{
        width: 16px;
        color: ${color.primary};
    }

    & span{
        color:${color.white};
    }

    &:hover{
        color:${color.primary};
    }
    font-size:14px;
    margin-right: 16px;
`