import styled from "styled-components"
import { deviceMax, deviceMin } from "styles/media"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"

import { Page as _Page, Dialog, DialogBody, Overlay, DialogHeader } from "../base/style"

export const Page = styled.div<{display : string}>`
    & ${Overlay} {
        display: none;
    }

    & ${_Page} {
        pointer-events: none;
        background: transparent;
    }

    & ${Dialog} {
        pointer-events: all;
        width: 440px;
        max-width: calc(100% - 30px);
        margin:40px 40px 40px auto;
        padding: 0;
    }

    & ${DialogHeader} {
        display: none;
    }

    & ${DialogBody} {
        /*&::-webkit-scrollbar {
            width: 0px;
            transition: all .3s ease-in-out;
        }

        &:hover {
            &::-webkit-scrollbar {
                width: 4px;
            }
        }*/
    }

    @media ${deviceMax.xxl} {
        & ${Dialog} {
            margin:${props => props.display === "true" ? "40px 0px 40px auto" : "40px -410px 40px auto"};
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
        }
    }

    @media ${deviceMax.md} {
        & ${Dialog} {
            margin:${props => props.display === "true" ? "4px 0px 70px auto" : "4px -410px 70px auto"};
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
        }
    }

    @media (max-width: 440px) {
        & ${Dialog} {
            margin:${props => props.display === "true" ? "4px 0px 70px auto" : "4px -85% 70px auto"};
        }
    }
`

export const ToggleControl = styled.div`
    position: absolute;
    left: -30px;
    top:50%;
    transform:translateY(-50%);
    cursor:pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 60px;
    height: 60px;
    background: ${Hex2Rgba(color.bg1, .64)};
    backdrop-filter: blur(20px);
    border-radius: 50%;

    &:hover {
        background: ${Hex2Rgba(color.bg1, .84)};
    }

    display: none;

    @media ${deviceMax.xxl} {
        display:flex;
    }
`