import { Link } from "react-router-dom"
import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"

export const Token = styled.div`
    display:flex;
    background: ${Hex2Rgba("#000000", .32)};
    border-radius:10px;
    padding:8px;
    border: 1px solid ${Hex2Rgba("#000000", .32)};

    transition:all .1s ease-in-out;

    &:hover{
        border: 1px solid ${Hex2Rgba(color.primary, .3)};
        background: linear-gradient(0deg, ${Hex2Rgba(color.black, .2)}, ${Hex2Rgba(color.black, .2)}), linear-gradient(180deg, ${Hex2Rgba(color.secondary, .05)} 0%, ${Hex2Rgba(color.primary, .05)} 100%);
    }
`

export const Actions = styled.div`
    flex:.4;
    text-align:center;
    display:flex;
    flex-direction:column;
    justify-content:center;
`

export const Action = styled.div<{active : string}>`
    font-size:14px;
    color:${color.primary};

    & > svg{

        

        ${props => props.active === "false" && `
            color:${color.white};
        `}

        &:hover{
            color:${color.primary};
        }

        cursor:pointer;
    }
`

export const ActionText = styled.div`
    font-size:10px;
    color:${Hex2Rgba(color.white, .3)};
    cursor:defualt;
    user-select:none;
`

export const TokenImg = styled(Link)`
    flex:.9;
    display:flex;
    justify-content:center;
    align-items:center;
    & > img{
        width:100%;
        border-radius:8px;
    }
    cursor:pointer;
`

export const TokenContent = styled.div`
    padding-left:10px;
    flex:2.5;
    text-align:left;
    & img{
        width:10px;
        height:10px;
    }
    display:flex;
    align-items:center;
`

export const TokenVolFloor = styled.div`
    flex:1.5;
    cursor:pointer;

    & span{
        color:${color.white}
    }
`

export const TokenName = styled(Link)`
    cursor:pointer;
    /*flex:1;*/
    display:flex;
    min-height:50px;
    align-items:center;
    text-decoration:none;
    color:${color.white};
    height:100%;
    width:100%;
`

export const TokenFloor = styled.div`
    font-size:11px;
    color:${Hex2Rgba(color.white, .6)};
`

export const TokenVolume = styled.div`
    font-size:11px;
    color:${Hex2Rgba(color.white, .6)};
`

export const TokenChart = styled.div`
    flex:1;
    display:flex;
    align-items:center;
    justify-content:center;
    background-color:${Hex2Rgba(color.white, .03)};
    border-radius:10px;
    padding:5px;
    cursor:pointer;
`

export const TokenStats = styled.div`
    display:flex;
    align-items:center;
    margin-top:5px;
`

export const TokenSocial = styled.div`
    /*flex:.5;*/
    display:flex;
    font-size:18px;
    align-items:center;
    height:27px;
`
export const TokenSocialItem = styled.a<{hc : string}>`
    color:${Hex2Rgba(color.white, .6)};

    &:not(:last-child){
        margin-right:5px;
    }

    &:hover{
        color:${props => props.hc};
    }


`