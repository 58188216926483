export type Shares = {
  "version": "0.1.0",
  "name": "shares",
  "instructions": [
    {
      "name": "initialize",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "config",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "createIssuerAccount",
      "accounts": [
        {
          "name": "user",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "issuerKey",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "issuer",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "config",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "issuerBroadcast",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "inviteCode",
          "type": "bytes"
        }
      ]
    },
    {
      "name": "completeIssuerBroadcast",
      "accounts": [
        {
          "name": "subAdmin",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "config",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "issuerBroadcast",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "issuerKey",
          "type": "publicKey"
        }
      ]
    },
    {
      "name": "createShareHoldingAccount",
      "accounts": [
        {
          "name": "user",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "issuer",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "issuerKey",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "shareHolding",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "buyShare",
      "accounts": [
        {
          "name": "user",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "issuer",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "issuerKey",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "shareHolding",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "shareAmount",
          "type": "u64"
        },
        {
          "name": "slippage",
          "type": {
            "option": "u64"
          }
        },
        {
          "name": "slippageTotalShares",
          "type": {
            "option": "u64"
          }
        }
      ]
    },
    {
      "name": "sellShare",
      "accounts": [
        {
          "name": "user",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "issuer",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "issuerKey",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "shareHolding",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "config",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "shareAmount",
          "type": "u64"
        },
        {
          "name": "slippage",
          "type": {
            "option": "u64"
          }
        },
        {
          "name": "slippageTotalShares",
          "type": {
            "option": "u64"
          }
        }
      ]
    },
    {
      "name": "withdrawPlatformFees",
      "accounts": [
        {
          "name": "admin",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "config",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    }
  ],
  "accounts": [
    {
      "name": "config",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "admin",
            "type": "publicKey"
          },
          {
            "name": "subAdmin",
            "type": "publicKey"
          },
          {
            "name": "bump",
            "type": "u8"
          }
        ]
      }
    },
    {
      "name": "issuer",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "issuerKey",
            "type": "publicKey"
          },
          {
            "name": "price",
            "type": "u64"
          },
          {
            "name": "totalShares",
            "type": "u64"
          },
          {
            "name": "totalFees",
            "type": "u64"
          },
          {
            "name": "bump",
            "type": "u8"
          },
          {
            "name": "holders",
            "type": "u32"
          },
          {
            "name": "migration",
            "type": "bool"
          },
          {
            "name": "a",
            "type": "u64"
          },
          {
            "name": "b",
            "type": "u64"
          },
          {
            "name": "d",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "issuerBroadcast",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "issuerKey",
            "type": "publicKey"
          },
          {
            "name": "inviteCode",
            "type": "bytes"
          },
          {
            "name": "bump",
            "type": "u8"
          }
        ]
      }
    },
    {
      "name": "shareHolding",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "owner",
            "type": "publicKey"
          },
          {
            "name": "issuerKey",
            "type": "publicKey"
          },
          {
            "name": "amount",
            "type": "u64"
          },
          {
            "name": "migrated",
            "type": "bool"
          },
          {
            "name": "bump",
            "type": "u8"
          }
        ]
      }
    }
  ],
  "errors": [
    {
      "code": 6000,
      "name": "InsufficientFundsForTransaction"
    },
    {
      "code": 6001,
      "name": "InvalidAmount"
    },
    {
      "code": 6002,
      "name": "InvalidInviteCode"
    },
    {
      "code": 6003,
      "name": "TotalSharesExceedSlippage"
    }
  ]
};

export const IDL: Shares = {
  "version": "0.1.0",
  "name": "shares",
  "instructions": [
    {
      "name": "initialize",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "config",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "createIssuerAccount",
      "accounts": [
        {
          "name": "user",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "issuerKey",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "issuer",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "config",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "issuerBroadcast",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "inviteCode",
          "type": "bytes"
        }
      ]
    },
    {
      "name": "completeIssuerBroadcast",
      "accounts": [
        {
          "name": "subAdmin",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "config",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "issuerBroadcast",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "issuerKey",
          "type": "publicKey"
        }
      ]
    },
    {
      "name": "createShareHoldingAccount",
      "accounts": [
        {
          "name": "user",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "issuer",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "issuerKey",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "shareHolding",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "buyShare",
      "accounts": [
        {
          "name": "user",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "issuer",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "issuerKey",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "shareHolding",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "shareAmount",
          "type": "u64"
        },
        {
          "name": "slippage",
          "type": {
            "option": "u64"
          }
        },
        {
          "name": "slippageTotalShares",
          "type": {
            "option": "u64"
          }
        }
      ]
    },
    {
      "name": "sellShare",
      "accounts": [
        {
          "name": "user",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "issuer",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "issuerKey",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "shareHolding",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "config",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "shareAmount",
          "type": "u64"
        },
        {
          "name": "slippage",
          "type": {
            "option": "u64"
          }
        },
        {
          "name": "slippageTotalShares",
          "type": {
            "option": "u64"
          }
        }
      ]
    },
    {
      "name": "withdrawPlatformFees",
      "accounts": [
        {
          "name": "admin",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "config",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    }
  ],
  "accounts": [
    {
      "name": "config",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "admin",
            "type": "publicKey"
          },
          {
            "name": "subAdmin",
            "type": "publicKey"
          },
          {
            "name": "bump",
            "type": "u8"
          }
        ]
      }
    },
    {
      "name": "issuer",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "issuerKey",
            "type": "publicKey"
          },
          {
            "name": "price",
            "type": "u64"
          },
          {
            "name": "totalShares",
            "type": "u64"
          },
          {
            "name": "totalFees",
            "type": "u64"
          },
          {
            "name": "bump",
            "type": "u8"
          },
          {
            "name": "holders",
            "type": "u32"
          },
          {
            "name": "migration",
            "type": "bool"
          },
          {
            "name": "a",
            "type": "u64"
          },
          {
            "name": "b",
            "type": "u64"
          },
          {
            "name": "d",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "issuerBroadcast",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "issuerKey",
            "type": "publicKey"
          },
          {
            "name": "inviteCode",
            "type": "bytes"
          },
          {
            "name": "bump",
            "type": "u8"
          }
        ]
      }
    },
    {
      "name": "shareHolding",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "owner",
            "type": "publicKey"
          },
          {
            "name": "issuerKey",
            "type": "publicKey"
          },
          {
            "name": "amount",
            "type": "u64"
          },
          {
            "name": "migrated",
            "type": "bool"
          },
          {
            "name": "bump",
            "type": "u8"
          }
        ]
      }
    }
  ],
  "errors": [
    {
      "code": 6000,
      "name": "InsufficientFundsForTransaction"
    },
    {
      "code": 6001,
      "name": "InvalidAmount"
    },
    {
      "code": 6002,
      "name": "InvalidInviteCode"
    },
    {
      "code": 6003,
      "name": "TotalSharesExceedSlippage"
    }
  ]
};
