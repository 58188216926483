import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { User } from 'models/user'
import { hex2a } from 'utils/helpers'

export interface LaunchState {
    currentLaunch: any | null,
    itemsMinted: number,
    amountToMint: number,
    mintResult : any
}

const initialState: LaunchState = {
    currentLaunch: null,
    itemsMinted: 0,
    amountToMint: 1,
    mintResult: null
}

export const launchSlice = createSlice({
    name: 'launch',
    initialState,
    reducers: {
        setCurrentLaunch: (state, action: PayloadAction<any | null>) => {
            state.currentLaunch = action.payload
            state.itemsMinted = 0
            state.amountToMint = 1
        },
        setItemsMinted: (state, action: PayloadAction<number>) => {
            state.itemsMinted = action.payload
        },
        incrementItemsMinted: (state) => {
            state.itemsMinted++
        },
        setAmountToMint: (state, action: PayloadAction<number>) => {
            state.amountToMint = action.payload
        },
        setMintResult: (state, action: PayloadAction<any>) => {
            state.mintResult = action.payload
        }
    }
})

export const { setCurrentLaunch,setMintResult, setItemsMinted, incrementItemsMinted, setAmountToMint } = launchSlice.actions

export default launchSlice.reducer