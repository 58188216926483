import { faGem } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "components/ui/button"
import react from "react"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { IconTiplink, IconTreasuryHunt } from "utils/icons"
import * as C from "./style"

const TreasureMap = (props: any) => {

    return (
        <C.TreasureMap>
            <C.TreasureMapIcon>
                <IconTreasuryHunt />
            </C.TreasureMapIcon>
            <C.TreasureMapTitle>You Found a Treasure</C.TreasureMapTitle>
            <C.TreasureMapText>
                {props.treasure.description}
            </C.TreasureMapText>

            {props.treasure.type === "TIPLINK" && (
                <C.TreasureMapLink>
                    <Button theme="gradient" fromcolor={color.gradient3.start} tocolor={color.gradient3.end} color={color.black} bg={Hex2Rgba(color.black, .32)} link="link" href={props.treasure.link} target="_blank" rel="noreferrer">
                        <IconTiplink /> Claim TipLink
                    </Button>
                </C.TreasureMapLink>
            )}

        </C.TreasureMap>
    )

}

export default TreasureMap