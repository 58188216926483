import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"


export const AirdropPart = styled.div`
`

export const Actions = styled.div`
    margin-top: 24px;

     & button{
        height: 48px;
     }

     display: flex;
     & button:not(:last-child){
            margin-right: 8px;
        }
`

export const Points = styled.div`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
`

export const Background = styled.div`
    width: 150px;
    height: 150px;
    background: ${Hex2Rgba(color.secondary, 0.16)};
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    & svg{
        position: absolute;
        width: 67px;
        height: 67px;
        top: 24px;
        color: ${color.primary};
    }
`

export const Number = styled.div`
    font-size: 16px;
    position: absolute;
    bottom: 14px;
    padding: 4px 16px;
    border-radius: 38px;
    &::after {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 38px; 
        padding: 2px;
        transform: scale(1.02);
        background:linear-gradient(45deg,${Hex2Rgba(color.gradient1.start, 1)},${Hex2Rgba(color.gradient1.middle, 1)},${Hex2Rgba(color.gradient1.end, 1)}); 
        -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
                mask-composite: exclude; 
    }

    background: linear-gradient(90deg, ${color.gradient1.start}, ${color.gradient1.middle}, ${color.gradient1.end});
    font-weight:600;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`

export const Title = styled.div`
    font-size: 24px;
    font-weight: 600;
    margin-top: 24px;
    margin-bottom: 8px;
    text-align: center;
`

export const Description = styled.div`
    color: ${color.whiteShade};
    font-size: 14px;
    margin-bottom: 24px;
    margin-top: 8px;
    text-align: center;
`

export const SubTitle = styled.div`
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
    margin-top: 24px;
`

export const Steps = styled.div`
    color: ${color.whiteShade};
    font-size: 14px;
`

export const Step = styled.div`
    &:not(:last-child){
        margin-bottom: 16px;
    }
`

export const StepTitle = styled.div`
    font-size: 14px;
    font-weight: 600;
    color: ${color.white};
`