import { store } from "services/store"
import { setCurrentPage, setCurrentViewingHolder, setCurrentViewingNft, setCurrentViewingShareUser, setCurrentViewingTreasure, setDisplayChat, setDisplayGuide, setFirstTime, setLoadingPopupText, setWalletConnectSettings } from "services/slices/data"
import { setCurrentViewingCollection, setCurrentViewingCollectionCode, setCurrentViewingLaunch, setCurrentViewingTokenCode, setCurrentViewingWhitelist } from "services/slices/holders"
import { SetEditProfilePopupSharesFirst, closeAllPopups, showAirdropPopup, showCloudWalletPopup, showEditProfilePopup, showGuidePopup, showLoadingPopup, showLoginPopup, showMapFilterPopup, showNftViewPopup, showSettingsPopup, showTreasurePopup, showWalletConnectPopup, showWelcomePopup } from "services/slices/popup"
import { Chain, Network } from "models/enums/network"
import { setCurrentWhitelist } from "services/slices/whitelist"
import { setLocalData } from "./localData"
import { setCurrentLaunch } from "services/slices/launch"
export const closeAllPages = () => {
    store.dispatch(setCurrentPage(MainParts.Home))

    if (store.getState().data.currentViewingHolder !== null)
        store.dispatch(setCurrentViewingHolder(null))
}

export const closeAllPopups_ = () => {
    store.dispatch(closeAllPopups())
}

export const closeAll = () => {
    store.dispatch(setCurrentPage(MainParts.Home))
    store.dispatch(closeAllPopups())
    store.dispatch(setCurrentViewingWhitelist(null))
    store.dispatch(setCurrentWhitelist(null))
    store.dispatch(setCurrentLaunch(null))
    store.dispatch(setCurrentViewingLaunch(null))
}

export const listWhitelisteds = (whitelist: string) => {
    store.dispatch(setCurrentPage(MainParts.Whitelist))
    store.dispatch(setCurrentViewingWhitelist(whitelist))
}

export const listHoldersOfCollection = (collection: string) => {
    store.dispatch(setCurrentPage(MainParts.Home))
    store.dispatch(setCurrentViewingCollection(collection))
}

export const displayWalletConnectPopup = (show: boolean, network?: Network) => {



    if (show && network) {
        store.dispatch(setWalletConnectSettings({ network }))
    } else {
        store.dispatch(setWalletConnectSettings({ network: 'any' }))
    }

    store.dispatch(showWalletConnectPopup(show))

    if (show === false && store.getState().data.firstTime) {
        store.dispatch(setFirstTime(false))
        {/*store.dispatch(showWelcomePopup(true))*/ }
    }
}
export const displayLoginPopup = (show: boolean,) => {
    store.dispatch(showLoginPopup(show))
}

export const displayMapFilterPopup = (show: boolean) => {
    store.dispatch(showMapFilterPopup(show))
}

export const displayCollections = () => {
    store.dispatch(setCurrentPage(MainParts.Collections))
    store.dispatch(setCurrentViewingCollectionCode(null))
    store.dispatch(setCurrentViewingTokenCode(null))
    store.dispatch(setCurrentViewingCollection(null))
}

export const displayWhitelistCollections = () => {
    store.dispatch(setCurrentPage(MainParts.WhitelistCollections))
    store.dispatch(setCurrentWhitelist(null))
    store.dispatch(setCurrentViewingWhitelist(null))
}

export const displayWhitelist = (name: string) => {

    if (store.getState().holders.currentViewingWhitelist !== name) {
        store.dispatch(setCurrentViewingCollection(null))
        store.dispatch(setCurrentWhitelist(null))
        store.dispatch(setCurrentViewingWhitelist(name))
        store.dispatch(setCurrentPage(MainParts.Whitelist))
    }
}

export const displayLaunches = () => {
    store.dispatch(setCurrentPage(MainParts.Launches))
    store.dispatch(setCurrentWhitelist(null))
    store.dispatch(setCurrentViewingWhitelist(null))
    store.dispatch(setCurrentViewingLaunch(null))
    store.dispatch(setCurrentLaunch(null))
}

export const displayLaunch = (name: string) => {

    if (store.getState().holders.currentViewingLaunch !== name) {
        store.dispatch(setCurrentViewingCollection(null))
        store.dispatch(setCurrentWhitelist(null))
        store.dispatch(setCurrentViewingLaunch(name))
        store.dispatch(setCurrentPage(MainParts.Launch))
    }
}

export const displayShuffleLiquidation = () => {
    store.dispatch(setCurrentPage(MainParts.ShuffleLiquidation))
}

export const displayShuffle = () => {
    store.dispatch(setCurrentPage(MainParts.Shuffle))
}

export const displayCollectionHolders = (name: string) => {

    if (store.getState().holders.currentViewingCollectionCode !== name) {
        store.dispatch(setCurrentViewingCollectionCode(name))
        store.dispatch(setCurrentViewingTokenCode(null))
        store.dispatch(setCurrentWhitelist(null))
        store.dispatch(setCurrentPage(MainParts.Home))
    }
}

export const displayTokenHolders = (name: string) => {

    if (store.getState().holders.currentViewingTokenCode !== name) {
        store.dispatch(setCurrentViewingTokenCode(name))
        store.dispatch(setCurrentViewingCollectionCode(null))
        store.dispatch(setCurrentWhitelist(null))
        store.dispatch(setCurrentPage(MainParts.Home))
    }
}

export const closeWhitelist = () => {
    store.dispatch(setCurrentViewingWhitelist(null))
    store.dispatch(setCurrentPage(MainParts.WhitelistCollections))
}

export const displayProfile = () => {

    if (store.getState().user.user) {
        store.dispatch(setCurrentViewingHolder(null))
        store.dispatch(setCurrentPage(MainParts.Profile))
    } else
        displayWalletConnectPopup(true)
}

export const displayHolderProfile = (user: any) => {
    store.dispatch(setCurrentPage(MainParts.Profile))
    store.dispatch(setCurrentViewingHolder(user))
}

export const displayTreasure = (treasure: any) => {
    store.dispatch(showTreasurePopup(true))
    store.dispatch(setCurrentViewingTreasure(treasure))
}

export const displaySettings = () => {
    if (store.getState().data.isMobile)
        store.dispatch(setCurrentPage(MainParts.Settings))
    else
        store.dispatch(showSettingsPopup(true))
}


export const displayGuide = () => {

    if (store.getState().data.isMobile) {
        store.dispatch(showGuidePopup(true))
    } else {

        if (store.getState().data.currentPage !== MainParts.Home) {
            store.dispatch(showGuidePopup(true))
        } else {
            store.dispatch(setDisplayGuide(true))
            localStorage.removeItem("guide")
        }
    }
}

export const displayEditProfile = (show: boolean, shares?: boolean) => {
    if (store.getState().data.isMobile) {
        if (shares)
            store.dispatch(SetEditProfilePopupSharesFirst(shares))
        store.dispatch(setCurrentPage(MainParts.MyProfile))
    } else {
        if (shares) {
            store.dispatch(showEditProfilePopup({
                status: show,
                sharesFirst: true
            }))
        } else
            store.dispatch(showEditProfilePopup(show))
    }
}
export const displayCloudWallet = (show: any) => {
    store.dispatch(showCloudWalletPopup(show))
}
export const displayAirDrop = (show: any) => {
    store.dispatch(showAirdropPopup(show))
}

export const displayShare = (user: any) => {
    store.dispatch(setCurrentPage(MainParts.Share))
    store.dispatch(setCurrentViewingShareUser(user))
}

export const displayChat = () => {
    store.dispatch(setCurrentPage(MainParts.Chat))
}

export const displayChatWidget = (show: boolean) => {
    store.dispatch(setDisplayChat(show))
}

export const displayShares = () => {
    store.dispatch(setCurrentPage(MainParts.Shares))
}

export const MainParts = {
    Home: null,
    Collections: "Collections",
    Profile: "Profile",
    Settings: "Settings",
    MyProfile: "MyProfile",
    WhitelistCollections: "WhitelistCollections",
    Whitelist: "Whitelist",
    Launches: "Launches",
    Launch: "Launch",
    Shuffle: "Shuffle",
    ShuffleLiquidation: "ShuffleLiquidation",
    Shares: "Shares",
    Share: "Share",
    Chat: "Chat",
}

export const showAnalyticsPart = () => {

}

export const showAnalyticsOfCollection = (collection: string) => {

}

export const ViewNft = (nft: any) => {
    store.dispatch(setCurrentViewingNft(nft))
    store.dispatch(showNftViewPopup(true))
}

export const DisplayLoading = (text?: string) => {
    store.dispatch(setLoadingPopupText(text ? text : ""))
    store.dispatch(showLoadingPopup(true))
}

export const UpdateLoadingText = (text?: string) => {
    store.dispatch(setLoadingPopupText(text ? text : ""))
}

export const HideLoading = () => {
    store.dispatch(showLoadingPopup(false))
}