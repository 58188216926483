import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import React from "react";
import * as C from "./style";

const ShuffleTokenTicketsLB = (props: any) => {

    return (
        <C.ShuffleTokenTickets>
            {props.prize.map((c: any, index: number) => (
                <C.Ticket key={index} >
                    <C.TicketText claimed={c.claimed ? "true" : "false"}>{(c.amount / LAMPORTS_PER_SOL).toFixed(2)} {props.symbol} </C.TicketText>  • Ticket #{c.ticket}
                </C.Ticket>
            ))}
        </C.ShuffleTokenTickets>
    )

}

export default ShuffleTokenTicketsLB;