import { useSelector } from "react-redux"
import * as P from "./style"

import BasePage from "components/pages/base"
import { IconShuffle } from "utils/icons"

import { MainParts } from "utils/parts"
import ShuffleLiquidation from "components/parts/shuffle/shuffleLiquidation"
import ActiveWalletBar from "components/widgets/activeWalletBar"
import ShuffleLiquidityHeader from "components/parts/shuffle/shuffleLiquidation/header"

const Page = () => {

    const show = useSelector((state: any) => state.data.currentPage)
    
    return (
        <P.Page>
            <BasePage title="Shuffle Liquidation" show={show === MainParts.ShuffleLiquidation} icon={<IconShuffle />} glasmorph={true} headerExtra={
                <ShuffleLiquidityHeader />
            }>
                <ShuffleLiquidation />
            </BasePage>
        </P.Page>
    )
}

export default Page