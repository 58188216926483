import { LAMPORTS_PER_SOL } from "@solana/web3.js"
import { SolanaWallet } from "@web3auth/solana-provider"
import BigNumber from "bignumber.js"
import { formatPrice } from "modules/solana/share/helpers"
import { getWalletSolBalance } from "modules/solana/wallet"
import { AutoConnectAllWallets } from "modules/wallet"
import toast from "react-hot-toast"
import { connectDiscord, connectTwitter, loginWithWeb3Auth, twitterLoginComplete } from "services/api/auth"
import { getMe } from "services/api/profile"
import { setFirstTime } from "services/slices/data"
import { showWelcomePopup } from "services/slices/popup"
import { setCloudWalletBalance, setGlobalSelectedWallet, setLoginNotification, setUser, setUserLoaded } from "services/slices/user"
import { store } from "services/store"
import { shortenPublicKey } from "utils/helpers"
import { getLocalData, setLocalData } from "utils/localData"

export const RefreshUser = async () => {
    getMe().then((res) => {
        store.dispatch(setUser(res.data.user))
    }).catch((err: any) => { })
}


export const logout = () => {
    localStorage.removeItem("token")
    window.location.href = "/app"
    store.dispatch(setUser(null))
}

export const manageDiscord = async () => {
    //discord oauth

    const params: any = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop: any) => searchParams.get(prop),
    });

    const state = params.state
    const code = params.code

    if (state && code) {

        const nonce = store.getState().user.user?.nonce

        //match nonce with state
        if (nonce === state) {
            const loading = toast.loading("Connecting Discord...")

            connectDiscord(code, state).then((res: any) => {
                toast.success("Discord connected successfully")
                RefreshUser()
            }).catch((err: any) => {
                //console.log(err)

                //if status is 409
                if (err.response.status === 409) {
                    if (err.response.data.wallet)
                        toast.error("Discord account connected to this wallet: " + shortenPublicKey(err.response.data.wallet))
                    else
                        toast.error("Discord account connected to another account contact with support")
                } else {
                    toast.error("Discord connection failed")
                }
            }).finally(() => {
                toast.remove(loading)
                window.history.replaceState({}, document.title, "/" + "app");
            })

        }
    }

}


export const manageTwitter = async ({ web3auth, setProvider, provider, connected }: any) => {

    /*toast.loading("Logging you in...")

    console.log(web3auth)

    const userInfo = await web3auth!.getUserInfo()
    console.log(userInfo)
    const idToken = userInfo.idToken
    const base64Url = idToken.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    const parsedToken = JSON.parse(window.atob(base64));
    const solanaWallet = new SolanaWallet(provider);
    const acc = await solanaWallet.requestAccounts();

    loginWithWeb3Auth(parsedToken.wallets[0].public_key, acc[0], idToken).then(async (res) => {
        setLocalData("token", res.data.token)
    }).catch((err: any) => {
        console.log(err)
        toast.error("Login failed")
        store.dispatch(setUserLoaded(true))
        if (err.response && err.response.status === 401) {
            store.dispatch(setLoginNotification(true))
        }
    })*/
}

/*
export const manageTwitter = async ( {web3auth, setProvider, provider, connected}:any) => {
    //twitter oauth

    const params: any = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop: any) => searchParams.get(prop),
    });

    const oauth_token = params.oauth_token
    const oauth_verifier = params.oauth_verifier

    if (oauth_token && oauth_verifier) {

        const nonce = getLocalData("loginid")

        if (!nonce) {
            toast.error("Twitter connection failed")
            window.history.replaceState({}, document.title, "/" + "app");
            return
        }

        //match nonce with state

        const loading = toast.loading("Logging in with Twitter...")

        twitterLoginComplete(oauth_token, oauth_verifier, nonce).then((res: any) => {
            
            toast.success("Logged in with Twitter successfully")
            setLocalData("token", res.data.token)
            store.dispatch(setUser(res.data.user))
            if (res.data.user.firstTime.firstTime)
                store.dispatch(showWelcomePopup(true))

            getWalletSolBalance(res.data.user.cloudWallet).then((res: any) => {
                store.dispatch(setCloudWalletBalance(
                    formatPrice(new BigNumber(res).div(LAMPORTS_PER_SOL))
                ))
            })
            //RefreshUser()
        }).catch((err: any) => {
            //console.log(err)

            //if status is 409, 
            /*if (err.response.status === 409) {
                if (err.response.data.wallet)
                    toast.error("Twitter account connected to this wallet: " + shortenPublicKey(err.response.data.wallet))
                else
                    toast.error("Twitter account connected to another account contact with support")

            } else {*/
//toast.error("Twitter connection failed")
//}
/*
        }).finally(() => {
            toast.remove(loading)
            window.history.replaceState({}, document.title, "/" + "app");
        })


    }

}*/

export const setSelectedSolanaWallet = (wallet: any) => {
    setLocalData('selectedSolanaWallet', wallet)
    store.dispatch(setGlobalSelectedWallet(wallet))

}