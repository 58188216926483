import React, { useEffect, useState } from "react"
import * as C from "./style"
import Input from "components/ui/input"
import Button from "components/ui/button"
import { color } from "styles/theme"
import { Hex2Rgba, getAvatar } from "utils/helpers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faChevronLeft, faChevronRight, faChevronUp, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { listShares, listSharesCustom } from "services/api/share"
import env from "env"
import BigNumber from "bignumber.js"
import { formatPrice, getIssuerAccount } from "modules/solana/share/helpers"
import { LAMPORTS_PER_SOL } from "@solana/web3.js"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { displayEditProfile, displayLoginPopup } from "utils/parts"
import { IconSortBottom, IconSortTop } from "utils/icons"
import { getMyHoldings } from "modules/solana/share/shareView"
import toast from "react-hot-toast"

var searchCheck: any = false
var refreshInterval: any = null

const Shares = () => {

    const [currentTab, setCurrentTab] = useState(0)
    const navigate = useNavigate()
    const user = useSelector((state: any) => state.user.user)

    const [page, setPage] = useState(0)
    const [shares, setShares] = useState<any>([])
    const [sort, setSort] = useState<any>({ field: "change", order: "desc" })
    const [totalShares, setTotalShares] = useState(0);
    const totalPages = Math.ceil(totalShares / 10);
    const isMobile = useSelector((state: any) => state.data.isMobile)

    const [sharesOwned, setSharesOwned] = useState<any>([])
    const [loading, setLoading] = useState(false)

    const [searchText, setSearchText] = useState("")

    const list = () => {
        setLoading(true)
        clearInterval(refreshInterval)
        listShares({ page, sort: sort.field, asc: sort.order === "asc", search:searchText }).then((res) => {
            setShares(res.data.shares)
            setTotalShares(res.data.total);
            clearInterval(refreshInterval)
            
        }).catch((err) => {
            console.log(err)
            clearInterval(refreshInterval)
        }).finally(() => {
            setLoading(false)
            refreshInterval = setInterval(() => {
                //list()
            }, 60000)
        })
    }

    const listOwned = (addresses?: any) => {
        if (!addresses) {
            addresses = sharesOwned
        }
        setLoading(true)
        //take slice based on page
        const start = page * 10
        const end = start + 10
        const addressesToGet = addresses.slice(start, end)
        clearInterval(refreshInterval)
        listSharesCustom({ addresses:addressesToGet, sharesOwned:addresses, sort: sort.field, asc: sort.order === "asc", search:searchText }).then((res) => {
            setShares(res.data.shares)
            setTotalShares(res.data.total);

            clearInterval(refreshInterval)
           
        }).catch((err) => {
            console.log(err)
            toast.error("Error getting shares")
            clearInterval(refreshInterval)
        }).finally(() => {
            setLoading(false)
            refreshInterval = setInterval(() => {
                //listOwned()
            }, 60000)
        })
    }

    const getAll = () => {
        if (loading) {
            return;
        }
        setCurrentTab(0)
        setPage(0)
        list()
    }
    const getOwned = () => {
        if (!user || !user.shareAddress) {
            return displayLoginPopup(true)
        }
        setCurrentTab(1)
        setPage(0)
        setLoading(true)
        getMyHoldings(user.cloudWallet).then((res: any) => {
            const addresses = res.map((item: any) => getIssuerAccount(item.account.issuerKey).toString())
            setSharesOwned(addresses)
            listOwned(addresses)
        }).catch((err: any) => {
            setLoading(false)
            toast.error("Error getting your shares")
            console.log(err)
        })
    }

    useEffect(() => {
        if (!loading) {
            if (currentTab === 0) {
                list()
            } else {
                listOwned()
            }
        }
    }, [page, sort, searchText])

    const openShare = (id: string) => {
        navigate("/app/share/" + id)
    }

    const sortBy = (field: string) => {
        if (sort.field === field) {
            setSort({ field, order: sort.order === "asc" ? "desc" : "asc" })
        } else {
            setSort({ field, order: "asc" })
        }
    }

    const createShareAccount = () => {
        if (!user) {
            displayLoginPopup(true)
        } else {
            displayEditProfile(true, true)
        }
    }

    const [searching, setSearching] = useState(false)
    const input = React.useRef<any>(null)

    const deactivateSearch = () => {
        setSearching(false)
        setSearchText("")
    }

    const onSearchBlur = () => {
        if (input.current.value === "") {
            deactivateSearch()
        }
    }

    const onKeyDown = (e: any) => {
        //if user not typing for 3 seconds, search
        clearTimeout(searchCheck)
        if (input.current.value.length >= 2) {
            searchCheck = setTimeout(() => {
                setSearchText(input.current.value.trim())
            }, 1000)
        } else if (input.current.value.length === 0) {
            setSearchText("")
        }
    }

    useEffect(() => {
        
        return () => {
            clearTimeout(searchCheck)
            clearTimeout(refreshInterval)
        }
    }, [])

    return (
        <C.Shares>
            <C.Header>
                <C.HeaderLeft>
                    <C.Tabs>
                        <C.TabItem onClick={() => getAll()} isactive={currentTab === 0 ? "true" : "false"}>
                            All
                        </C.TabItem>
                        <C.TabItem onClick={() => getOwned()} isactive={currentTab === 1 ? "true" : "false"}>
                            Owned
                        </C.TabItem>
                    </C.Tabs>
                </C.HeaderLeft>

                <C.HeaderRight>
                    <C.Search>
                        <Input xref={input} onChange={onKeyDown} onKeyDown={onKeyDown} onBlur={onSearchBlur} type="text" placeholder="Search" />
                    </C.Search>
                    {(user && user.shareAddress) && (
                        <Button link="routerlink" to={"/app/share/" + user.id} theme="gradient-border-transparent-bg-on-hover" bg={Hex2Rgba(color.secondaryFade, .5)} color="white">View My Key Profile</Button>
                    )}
                    {(!user || (user && !user.shareAddress)) && (
                        <Button onClick={createShareAccount} theme="gradient" bg={Hex2Rgba(color.white, .05)} color={color.white}>Create Key Account</Button>
                    )}
                </C.HeaderRight>
            </C.Header>

            <C.Content>
                <C.TableContainer>
                    {loading && (
                        <C.Loading>
                            <FontAwesomeIcon icon={faSpinner} spin />
                        </C.Loading>
                    )}
                    <C.Table>
                        <C.TableHead>
                            <C.TableRow>
                                <C.TableHeadItem ttype="profile" onClick={() => sortBy("username")}>
                                    <C.TableHeadText>
                                        Profile
                                        <C.Sort isactive={sort.field === "username" ? "true" : "false"}>
                                            <C.SortIcon isactive={sort.order === "asc" ? "true" : "false"}><IconSortTop /></C.SortIcon>
                                            <C.SortIcon isactive={sort.order === "desc" ? "true" : "false"}><IconSortBottom /></C.SortIcon>
                                        </C.Sort>
                                    </C.TableHeadText>


                                </C.TableHeadItem>
                                <C.TableHeadItem ttype="price" onClick={() => sortBy("price")}>
                                    <C.TableHeadText>
                                        Price
                                        <C.Sort isactive={sort.field === "price" ? "true" : "false"}>
                                            <C.SortIcon isactive={sort.order === "asc" ? "true" : "false"}><IconSortTop /></C.SortIcon>
                                            <C.SortIcon isactive={sort.order === "desc" ? "true" : "false"}><IconSortBottom /></C.SortIcon>
                                        </C.Sort>
                                    </C.TableHeadText>
                                </C.TableHeadItem>
                                {!isMobile && (
                                    <C.TableHeadItem ttype="change" onClick={() => sortBy("change")}>
                                        <C.TableHeadText>
                                            24h Change
                                            <C.Sort isactive={sort.field === "change" ? "true" : "false"}>
                                                <C.SortIcon isactive={sort.order === "asc" ? "true" : "false"}><IconSortTop /></C.SortIcon>
                                                <C.SortIcon isactive={sort.order === "desc" ? "true" : "false"}><IconSortBottom /></C.SortIcon>
                                            </C.Sort>
                                        </C.TableHeadText>
                                    </C.TableHeadItem>
                                )}
                                <C.TableHeadItem ttype="volume" onClick={() => sortBy("volume")}>
                                    <C.TableHeadText>
                                        Volume
                                        <C.Sort isactive={sort.field === "volume" ? "true" : "false"}>
                                            <C.SortIcon isactive={sort.order === "asc" ? "true" : "false"}><IconSortTop /></C.SortIcon>
                                            <C.SortIcon isactive={sort.order === "desc" ? "true" : "false"}><IconSortBottom /></C.SortIcon>
                                        </C.Sort>
                                    </C.TableHeadText>
                                </C.TableHeadItem>
                                <C.TableHeadItem ttype="holders" onClick={() => sortBy("holders")}>
                                    <C.TableHeadText>
                                        Holders
                                        <C.Sort isactive={sort.field === "holders" ? "true" : "false"}>
                                            <C.SortIcon isactive={sort.order === "asc" ? "true" : "false"}><IconSortTop /></C.SortIcon>
                                            <C.SortIcon isactive={sort.order === "desc" ? "true" : "false"}><IconSortBottom /></C.SortIcon>
                                        </C.Sort>
                                    </C.TableHeadText>
                                </C.TableHeadItem>
                                <C.TableHeadItem ttype="shares-issued" onClick={() => sortBy("totalShares")}>
                                    <C.TableHeadText>
                                        Keys Issued
                                        <C.Sort isactive={sort.field === "totalShares" ? "true" : "false"}>
                                            <C.SortIcon isactive={sort.order === "asc" ? "true" : "false"}><IconSortTop /></C.SortIcon>
                                            <C.SortIcon isactive={sort.order === "desc" ? "true" : "false"}><IconSortBottom /></C.SortIcon>
                                        </C.Sort>
                                    </C.TableHeadText>
                                </C.TableHeadItem>
                                <C.TableHeadItem ttype="tvl" onClick={() => sortBy("tvl")}>
                                    <C.TableHeadText>
                                        TVL
                                        <C.Sort isactive={sort.field === "tvl" ? "true" : "false"}>
                                            <C.SortIcon isactive={sort.order === "asc" ? "true" : "false"}><IconSortTop /></C.SortIcon>
                                            <C.SortIcon isactive={sort.order === "desc" ? "true" : "false"}><IconSortBottom /></C.SortIcon>
                                        </C.Sort>
                                    </C.TableHeadText>
                                </C.TableHeadItem>
                                {isMobile && (
                                    <C.TableHeadItem ttype="change" onClick={() => sortBy("change")}>
                                        <C.TableHeadText>
                                            24h Change
                                            <C.Sort isactive={sort.field === "change" ? "true" : "false"}>
                                                <C.SortIcon isactive={sort.order === "asc" ? "true" : "false"}><IconSortTop /></C.SortIcon>
                                                <C.SortIcon isactive={sort.order === "desc" ? "true" : "false"}><IconSortBottom /></C.SortIcon>
                                            </C.Sort>
                                        </C.TableHeadText>
                                    </C.TableHeadItem>
                                )}
                            </C.TableRow>
                        </C.TableHead>
                        <C.TableBody>
                            {shares.map((share: any, index: number) => {
                                let changeValue = parseFloat(share.share.change)
                                var isPositive = changeValue === 0 ? null : changeValue > 0 ? true : false
                                return (
                                    <C.TableRow key={index} onClick={() => openShare(share.id)}>
                                        <C.TableBodyItem ttype="profile">
                                            <C.Profile>
                                                <img src={getAvatar(share.avatar)} alt="profile picture" /> {share.username}&nbsp;
                                            </C.Profile>

                                        </C.TableBodyItem>
                                        <C.TableBodyItem ttype="price">
                                            {formatPrice(new BigNumber(share.share.price).div(LAMPORTS_PER_SOL))} SOL
                                        </C.TableBodyItem>
                                        {!isMobile && (
                                            <C.TableBodyItem ttype="change">
                                                <C.Change ispositive={isPositive === null ? 'null' : isPositive ? "true" : "false"}>
                                                    {isPositive === null ? "" : isPositive ? <span>+</span> : <span>-</span>}
                                                    {changeValue}%
                                                </C.Change>
                                            </C.TableBodyItem>
                                        )}
                                        <C.TableBodyItem ttype="volume">
                                            {formatPrice(new BigNumber(share.share.volume).div(LAMPORTS_PER_SOL))} SOL
                                        </C.TableBodyItem>
                                        <C.TableBodyItem ttype="holders">
                                            {share.share.holders}
                                        </C.TableBodyItem>
                                        <C.TableBodyItem ttype="shares-issued">
                                            {share.share.totalShares}
                                        </C.TableBodyItem>
                                        <C.TableBodyItem ttype="tvl">
                                            {share.share.tvl} SOL
                                        </C.TableBodyItem>
                                        {isMobile && (
                                            <C.TableBodyItem ttype="change">
                                                <C.Change ispositive={isPositive === null ? 'null' : isPositive ? "true" : "false"}>
                                                    {isPositive === null ? "" : isPositive ? <span>+</span> : <span>-</span>}
                                                    {changeValue}%
                                                </C.Change>
                                            </C.TableBodyItem>
                                        )}
                                    </C.TableRow>
                                )
                            })}
                        </C.TableBody>
                    </C.Table>
                </C.TableContainer>

                <C.Pagination>
                    <C.PaginationBox>
                        <C.PaginationItem onClick={() => setPage(Math.max(0, page - 1))} isdisabled={page === 0 ? "true" : "false"}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </C.PaginationItem>

                        {Array.from({ length: totalPages }).map((_, index) => {
                            if (index < 2 || index > totalPages - 2 || (index >= page - 1 && index <= page + 1)) {
                                return (
                                    <C.PaginationItem key={index} onClick={() => setPage(index)} isactive={index === page ? "true" : "false"}>
                                        {index + 1}
                                    </C.PaginationItem>
                                );
                            }
                            if (index === 2 && page > 2) {
                                return <C.PaginationDots key={index}>...</C.PaginationDots>;
                            }
                            if (index === totalPages - 3 && page < totalPages - 3) {
                                return <C.PaginationDots key={index}>...</C.PaginationDots>;
                            }
                            return null;
                        })}

                        <C.PaginationItem onClick={() => setPage(Math.min(totalPages - 1, page + 1))} isdisabled={page === totalPages - 1 ? "true" : "false"}>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </C.PaginationItem>
                    </C.PaginationBox>
                </C.Pagination>
            </C.Content>
        </C.Shares>
    )

}

export default Shares