import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"


export const CloudWalletPart = styled.div`
    
`

export const WalletArea = styled.div`
    background-color: ${Hex2Rgba(color.secondary, 0.16)};
    padding: 24px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const Address = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    color: ${color.primary};
    cursor: pointer;
    border-radius: 68px;
    padding: 8px 16px;
    background-color: ${Hex2Rgba(color.secondary, 0.16)};
`

export const AddressText = styled.div`
    font-size: 14px;
`

export const CopyButton = styled.div`
    font-size: 14px;
    margin-left: 8px;
`

export const WalletText = styled.div`
    color: ${color.whiteShade};
    font-size: 16px;
`

export const WalletBalance = styled.div`
    color: ${color.white};
    font-size: 40px;
    font-weight: 600;
    margin-top: 8px;
`

export const Actions = styled.div`
    display: flex;
    margin-top: 24px;
    & > * {
        flex:1;
        padding-top:16px;
        padding-bottom:16px;
        border-radius: 88px;
    }

    & > *:not(:last-child) {
        margin-right: 10px;
    }
`

export const Seperator = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${color.purpleShade1};
    margin: 24px 0;
`

export const ExportButton = styled.div`
    text-align: center;
    color: ${color.primary};
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`

export const Title = styled.div`
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
`

export const Description = styled.div`
    color: ${color.whiteShade};
    font-size: 13px;
    margin-bottom: 24px;
`

export const FormGroup = styled.div<{normal?:string}>`
    & input{
        padding:12px;
    }
    position:relative;

    &:not(:last-child){
        margin-bottom:24px;
    }

    & p {
        font-size: 14px;
        color: ${color.gray};
        margin:0;
        padding-top:5px;
    }

    ${props => props.normal !== "true" && `
        & input {
            padding-right: 40px;
        }
    `}
`

export const Label = styled.div`
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
`

export const DepositCopyButton = styled.div`
    position:absolute;
    right:0;
    bottom:8px;
    right:16px;
    cursor:pointer;
    padding-left:8px;
`

export const FormGroupHead = styled.div`
    display:flex;
    justify-content:space-between;
`

export const Balance = styled.div`
    cursor:pointer;
    font-size:14px;
    color:${color.whiteShade};

    &:hover{
        color:${color.primary};
    }
`

export const Form = styled.form``
export const WithdrawError = styled.div`
    margin-top: 8px;
    color: ${color.error};
    font-size: 13px;
`

export const Loading = styled.div`
    margin-top: 8px;
    text-align: center;
    color: ${color.primary};
    font-size: 20px;
`