import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface _LaunchesState {
    _launches : any,
    chain : any,
    filter : any,
    sort : any,
    searchText : any,
}

const initialState: _LaunchesState = {
    _launches : [],
    chain : "all",
    filter : "all",
    sort : "all",
    searchText : "",
}

export const _launchesSlice = createSlice({
    name: '_launches',
    initialState,
    reducers: {
        set_Launches: (state, action: PayloadAction<any[]>) => {
            state._launches = action.payload
        },
        setFilterValues: (state, action: PayloadAction<any>) => {
            state.filter = action.payload.filter
            state.chain = action.payload.chain
            state.sort = action.payload.sort
        },
        setSearchValue: (state, action: PayloadAction<any>) => {
            state.searchText = action.payload
        }
    }
})

export const { set_Launches, setFilterValues, setSearchValue } = _launchesSlice.actions

export default _launchesSlice.reducer