import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import React from "react";
import * as C from "./style";

const ShuffleTicketsLB = (props: any) => {

    return (
        <C.ShuffleTickets>
            {props.tickets.map((ticket: any, index: number) => (
                <C.Ticket key={index}>
                    Ticket #{ticket}
                </C.Ticket>
            ))}
        </C.ShuffleTickets>
    )

}

export default ShuffleTicketsLB;