import { Badge } from "components/ui/badge/style"
import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"

export const Loading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    font-size: 24px;

    backdrop-filter: blur(20px);
    background: ${Hex2Rgba(color.places, .64)};
`

export const ProfileMap = styled.div`
    transition:height 0.5s;
    width:100%;
    
    display:flex;
    flex-direction:column;
    justify-content:space-between;

    & *{
        /*font:14px/1.3 Poppins, sans-serif;*/
    }

    padding-bottom:40px;
    overflow:hidden;
    border-radius:8px;
    backdrop-filter: blur(20px);
    background: ${Hex2Rgba(color.places, .64)};
`

export const User = styled.div`
    display:flex;
    flex-direction:column;
`

export const UserTop = styled.div<{bg : string}>`
    display:flex;
    padding:40px 40px 0px 40px;
    position:relative;
    height:120px;
    justify-content:space-between;

    ${props => props.bg && `
        background-image:url(${props.bg});
        background-size:cover;
        background-position:center;
    `}

    ${props => !props.bg && `
        background-color:${Hex2Rgba(color.black, 0)};
    `}
`

export const UserTopLeft = styled.div`
    display:flex;
    flex:1;
`
export const UserName = styled.div`
    margin-left:20px;
    text-shadow:0px 0px 10px ${Hex2Rgba(color.black, 1)};
    display:flex;
    align-items:center;
`

export const Avatar = styled.div`
    position:relative;
    width:125px;
    height:125px;

    & img{
        width:125px;
        height:125px;
        border-radius:100%;
        z-index:1;
        position:relative;
    }

    &::after {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 50%; 
        padding: 2px;
        transform: scale(1.02);
        background:linear-gradient(45deg,${Hex2Rgba(color.gradient1.start, 1)},${Hex2Rgba(color.gradient1.middle, 1)},${Hex2Rgba(color.gradient1.end, 1)}); 
        -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
                mask-composite: exclude; 
    }
`

export const Name = styled.div`
    font-size:24px;
    font-weight:600;
    word-wrap: break-word;
    line-height:0.9;
`

export const Status = styled.div`
    font-size:14px;
    word-wrap: break-word;
    background-color:${Hex2Rgba(color.black, .75)};
    padding:5px 10px;
    border-radius:8px;
    width:fit-content;
    margin-top:8px;
    box-shadow:0px 0px 10px ${Hex2Rgba(color.black, 0.75)};
`

export const Social = styled.div`
    display:flex;
    align-items:center;


    & > *{
        width:35px;
        height:35px;
        display:flex;
        align-items:center;
        justify-content:center;
        cursor:pointer;
        text-decoration:none;
        background-color:${Hex2Rgba(color.secondaryFade, .5)};
        &:not(:last-child){
            margin-right:5px;
        }
        border-radius:50%;
        & svg{
            font-size:22px;
            color:${color.white};
            transition:all 0.3s;
        }

        & svg{
            /*filter:drop-shadow(0px 0px 5px ${color.primary});*/
        }
        
    }
`

export const SubTop = styled.div`
    padding-left:185px;
    padding-right:30px;
`
export const Info = styled.div`
    padding:0px 40px 0px 40px;
`

export const Description = styled.div`
    margin-top:16px;
    font-size:14px;
    line-height:1.5;
    word-wrap: break-word;
    margin-bottom:10px;
`

export const Expand = styled.div`
    display:flex;
    justify-content:flex-end;
    margin-top:15px;
    & button{
        font-size:12px;
        & svg{
            margin-left:5px;
        }
        padding-top:10px;
        padding-bottom:10px;
    }

    & button:not(:last-child){
        margin-right:8px;
    }

    padding:0px 40px 0px 40px;
`


export const Badges = styled.div`
    display:flex;
    &  *{
        font-size:12px;
    }
    margin:15px 0px;
    flex-wrap:wrap;
    min-height:30px;
    
    & ${Badge}:not(:first-child){
        margin-left:10px;
    }

    & ${Badge}{
        margin-bottom:10px;
    }

    & ${Badge}:not(:last-child){
        margin-right:0px;
    }
`