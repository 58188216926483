import React from "react"
import * as C from "./style"
import Chat from "components/parts/chat"
import { useSelector } from "react-redux"
import { MainParts } from "utils/parts"

const ChatWidget = () => {

    const show = useSelector((state: any) => state.data.displayChat)

    if (!show) return (<></>);

    return (
        <C.Chat  >
            <C.ChatContent>
                <Chat />
            </C.ChatContent>
        </C.Chat>
    )
}

export default ChatWidget