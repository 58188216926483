import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { deviceMax } from "styles/media"
import { Row } from "styles"
import { DropdownButton } from "components/ui/button/dropdown/style"

export const ShuffleLiquidityToken= styled.div`

`

export const Title = styled.div`
    margin-bottom:8px;
`

export const Description = styled.div`
    margin-bottom:16px;
    font-size:14px;
    color:${color.whiteShade};
`

export const Liquidations = styled.div`
    width:100%;
    border-radius:8px;
    overflow:hidden;
    padding-bottom:48px;
`

export const Liquidation = styled.div`
    display:flex;
    justify-content:space-between;
    background: ${Hex2Rgba(color.bg1, .64)};
    align-items:center;
    padding:8px;

    &:nth-child(odd) {
        background: ${Hex2Rgba(color.bg2, .64)};
    }
`

export const LiquidationLeft = styled.div`
    display:flex;
`

export const LiquidationTotal = styled.div`

`

export const LiquidationCount = styled.div`
    color:${color.whiteShade};
    margin-left:8px;

    & span {
        font-weight:bold;
    }
`

export const LiquidationRight = styled.div`
    display:flex;
    align-items:center;
`

export const LiquidationRightText = styled.div`
    font-size:14px;
    color:${color.whiteShade};
`

export const LiquidationRightButton = styled.div`
    margin-left:8px;

    & ${DropdownButton} > button {
        z-index:unset;
    }
`
