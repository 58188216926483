import { Network } from "../enums/network";

export interface WalletProviderDetail{
    name: string
    network: Network
}

export const WalletProviderDetails : any = {
    phantom:{
        name: 'phantom',
        network: Network.Solana
    } as WalletProviderDetail,
    backpack:{
        name: 'backpack',
        network: Network.Solana
    } as WalletProviderDetail,
    solflare : {
        name: 'solflare',
        network: Network.Solana
    } as WalletProviderDetail,
    coin98 : {
        name: 'coin98',
        network: Network.Solana
    } as WalletProviderDetail,
    glow : {
        name: 'glow',
        network: Network.Solana
    } as WalletProviderDetail,
    slope : {
        name: 'slope',
        network: Network.Solana
    } as WalletProviderDetail,
    petra : {
        name: 'petra',
        network: Network.Aptos
    } as WalletProviderDetail,
    martian : {
        name: 'martian',
        network: Network.Aptos
    } as WalletProviderDetail,
}