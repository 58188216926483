import { useDispatch, useSelector } from "react-redux"
import Profile from "components/parts/profile"
import * as P from "./style"

import BasePage from "components/pages/base"
import { IconCollections } from "utils/icons"

import { closeAllPages, MainParts } from "utils/parts"
import { useNavigate } from "react-router-dom"
import { toLink } from "utils/helpers"
import { setCurrentPage } from "services/slices/data"

const Page = () => {

    const show = useSelector((state: any) => state.data.currentPage)
    const currentViewingWhitelist = useSelector((state: any) => state.holders.currentViewingWhitelist)
    const currentViewingCollection = useSelector((state: any) => state.holders.currentViewingCollection)
    const currentViewingCollectionCode = useSelector((state: any) => state.holders.currentViewingCollectionCode)
    const currentViewingLaunch = useSelector((state: any) => state.holders.currentViewingLaunch)
    const currentViewingToken = useSelector((state: any) => state.holders.currentViewingTokenCode)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const hide = () => {
        
        if (!currentViewingWhitelist && !currentViewingCollection && !currentViewingLaunch && !currentViewingToken) {
            closeAllPages()
            navigate("/app")
        }else if (currentViewingWhitelist){
            navigate(`/app/whitelist/${currentViewingWhitelist}`)
            dispatch(setCurrentPage(MainParts.Whitelist))
        }else if (currentViewingCollection){
            navigate(`/app/collections/${currentViewingCollectionCode}`)
            dispatch(setCurrentPage(MainParts.Home))
        }else if (currentViewingLaunch){
            navigate(`/app/launchmap/${currentViewingLaunch}`)
            dispatch(setCurrentPage(MainParts.Launch))
        }else if (currentViewingToken){
            navigate(`/app/tokens/${currentViewingToken}`)
            dispatch(setCurrentPage(MainParts.Home))
        }
    }

    return (
        <P.Page>
            <BasePage hide={hide} title="Profile" show={show === MainParts.Profile} icon={<IconCollections/>} glasmorph={true}>
                <Profile />
            </BasePage>
        </P.Page>
    )
}

export default Page