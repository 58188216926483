import React, { useEffect, useState, useMemo } from 'react'
import * as C from './style'

import { useDispatch, useSelector } from "react-redux"
import { Launch } from 'models/launch'
import { useNavigate } from 'react-router-dom'
import { setAmountToMint, setCurrentLaunch, setItemsMinted, setMintResult } from 'services/slices/launch'
import { socket } from 'services/socket'
import ReactTooltip from 'react-tooltip'
import { resetFilters } from 'services/slices/holders'
import { ManageHolderStates } from 'services/managers/holders'
import { GetLaunch, MintedVerification } from 'services/api/launchpad'
import toast from 'react-hot-toast'
import { color } from 'styles/theme'
import { capitalizeFirstLetter, copyObject, formatLink, Hex2Rgba, shortenPublicKey } from 'utils/helpers'
import Button from 'components/ui/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faCircleCheck, faCircleExclamation, faGlobe, faRocket, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Chains, Network } from 'models/enums/network'
import { faDiscord, faTwitter } from '@fortawesome/free-brands-svg-icons'
import Slider from 'react-slick'
import env from 'env'
import { GetCandyMachine, getTokenBalance, mintCm3 } from 'modules/solana/candymachine'
import { Timer } from 'components/etc/Timer'
import { findWallet } from 'modules/wallet'
import { showLaunchMintResultPopup, showConnectedWalletSelectPopup, showWalletConnectPopup } from 'services/slices/popup'
import { setWalletConnectSettings } from 'services/slices/data'
import * as anchor from "@project-serum/anchor";
import { PublicKey } from '@metaplex-foundation/js'
import { State } from 'models/enums/state'
import { setGlobalSelectedWallet } from 'services/slices/user'

var CMRefreshInterval: any = null
var currentPhaseTimeout: any = null

var candyMachine: any = null


var phaseTimer: any = {}

const LaunchPart = () => {

    const launches = useSelector((state: any) => state.data.launches)
    const _launchesLoaded = useSelector((state: any) => state.data._launchesLoaded)
    const currentViewingLaunch = useSelector((state: any) => state.holders.currentViewingLaunch)

    const item: Launch | null = useSelector((state: any) => state.launch.currentLaunch)
    const user = useSelector((state: any) => state.user.user)
    const userLoaded = useSelector((state: any) => state.user.userLoaded)

    const [loading, setLoading] = useState<any>(true)
    const [mintLoading, setMintLoading] = useState<any>(false)
    const [cmLoading, setCmLoading] = useState<any>(true)
    const [done, setDone] = useState(false)
    const WalletConnectPopup = useSelector((state: any) => state.popup.WalletConnectPopup)
    const [currentPhaseWhitelisted, setCurrentPhaseWhitelisted] = useState<any>(null)
    const [solanaWallets, setSolanaWallets] = React.useState<any>([])
    const globalSelectedWallet = useSelector((state: any) => state.user.selectedWallet)

    const [phases, setPhases] = useState<any>([])
    const [currentPhase, setCurrentPhase] = useState<any>(null)
    const [supply, setSupply] = useState<any>(null)
    const [premint, setPremint] = useState<any>(0)
    const itemsMinted = useSelector((state: any) => state.launch.itemsMinted)
    const amountToMint = useSelector((state: any) => state.launch.amountToMint)
    const mintInput = React.useRef<any>(null)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {

        if (!userLoaded) return;

        // reset search
        try {
            let element: any = document.getElementById("searchMap")
            var nativeInputValueSetter: any = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value")?.set;
            nativeInputValueSetter.call(element, '');

            var event = new Event('input', { bubbles: true });
            element.dispatchEvent(event);
            element.focus()
            element.blur()
        } catch (e) { }

        if (_launchesLoaded) {
            //find the current launch in the launches array. currentViewingLaunch is formatted small caps with dashes
            let launch = launches.find((wl: any) => wl.name.toLowerCase().replace(/ /g, "-") === currentViewingLaunch)

            if (launch) {

                //set the current launch
                document.title = launch.name + " - Launchmap - hub3"
                dispatch(setCurrentLaunch(launch))
                if (launch.details.premint) {
                    setPremint(launch.details.premint)
                }

                //join the launch room
                if (socket.connected) {
                    socket.emit("join", launch.id)
                } else {
                    let interval = setInterval(() => {
                        if (socket.connected) {
                            socket.emit("join", launch.id)
                            //console.log("joined1", wl.id)
                            clearInterval(interval)
                        }
                    }, 1000)
                }
                load(launch.id)

            }
        }

    }, [_launchesLoaded, user, globalSelectedWallet, userLoaded])

    useEffect(() => {

        if (done) {
            ReactTooltip.rebuild()
            ReactTooltip.hide()
            dispatch(resetFilters())
            ManageHolderStates(false, "launch")

            if (item?.status === State.ACTIVE) {
                ManageCM3()
                initializeWallets()

                CMRefreshInterval = setInterval(() => {
                    ManageCM3()
                    ManageHolderStates(true, "launch")
                }, 10000)

            }
        }
    }, [done])

    useEffect(() => {
        setLoading(true)

        return () => {
            if (socket.connected && item?.id)
                socket.emit("leave", item?.id)
            dispatch(setCurrentLaunch(null))
            clearInterval(CMRefreshInterval)
            CMRefreshInterval = null
            clearTimeout(currentPhaseTimeout)
            currentPhaseTimeout = null
            dispatch(setWalletConnectSettings({ network: "any" }))
            if (phaseTimer.timeout) clearTimeout(phaseTimer.timeout)
            phaseTimer = {}
        }
    }, [])

    const load = (id: string) => {
        GetLaunch(id).then((res: any) => {
            if (res) {
                if (res.data) {
                    dispatch(setCurrentLaunch(res.data.launch))
                    setLoading(false)
                    setDone(true)
                }
            }
        }).catch((e: any) => {
            toast.error("Error loading launch")
            setLoading(null)
        })
    }

    const closePage = () => {
        navigate("/app/launchmap")
    }

    const sliderSettings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "81px",
        slidesToShow: 1,
        speed: 500,
        arrows: true,
        mobileFirst: true
    }


    //wallet stuff


    const selectWallet = () => {
        if (solanaWallets.length === 0) {
            dispatch(setWalletConnectSettings({ network: Network.Solana }))
            dispatch(showWalletConnectPopup(true))
        } else {
            dispatch(showConnectedWalletSelectPopup(true))
        }
    }


    //cm stuff

    const managePhases = () => {
        let _phases: any = []

        for (let i = 0; i < candyMachine.candyGuard?.groups!.length!; i++) {
            //match label to item.details.groups[label]
            let label = candyMachine.candyGuard?.groups![i].label
            let group = item?.details?.groups[label!]
            if (group) {
                _phases.push({
                    ...group,
                    label,
                    seed: Math.floor(Math.random() * 1000000),
                })
            }
        }


        let _currentPhase = null

        //find the current phase based on the startdate and enddate 
        for (let i = 0; i < _phases.length; i++) {
            let phase = _phases[i]
            let start = new Date(phase.startdate)
            let end = new Date(phase.enddate)
            let now = new Date()

            //if end is > 1 year
            if (end.getTime() - start.getTime() > 31536000000) {
                _phases[i].noend = true
            }

            if (now > start && now < end) {
                _currentPhase = phase
            }

            //timeout to enddate
            /*currentPhaseTimeout = setTimeout(() => {
                ManageCM3()
            }, end.getTime() - now.getTime())*/
        }

        if (_currentPhase === null) {
            //if no current phase, find closest start date to now
            let closest = null
            for (let i = 0; i < _phases.length; i++) {
                let phase = _phases[i]
                let start = new Date(phase.startdate)
                let now = new Date()

                if (closest === null) {
                    closest = phase
                } else {
                    let closestStart = new Date(closest.startdate)
                    if (start < closestStart) closest = phase
                }
            }

            _currentPhase = closest
        }


        //order phases by startdate
        _phases.sort((a: any, b: any) => {
            let aStart = new Date(a.startdate)
            let bStart = new Date(b.startdate)

            if (aStart < bStart) {
                return -1
            } else if (aStart > bStart) {
                return 1
            } else {
                return 0
            }
        })

        if (phaseTimer.label !== _currentPhase.label) {
            if (phaseTimer.timeout) clearTimeout(phaseTimer.timeout)

            const now = new Date()
            const start = new Date(_currentPhase.startdate)
            const end = new Date(_currentPhase.enddate)
            //console.log(_currentPhase)

            phaseTimer.label = _currentPhase.label

            if (now > start && now < end) {
                //current phase

                if (end.getTime() - now.getTime() < 31536000000) {
                    phaseTimer.timeout = setTimeout(() => {
                        managePhases()
                        ManageCM3()
                        phaseTimer.timeout = null
                        phaseTimer.label = null
                    }, new Date(_currentPhase.enddate).getTime() - new Date().getTime())
                } else {
                    _currentPhase.noend = true
                }
            } else if (now < start) {
                //future phase
                phaseTimer.timeout = setTimeout(() => {
                    managePhases()
                    ManageCM3()
                    phaseTimer.timeout = null
                    phaseTimer.label = null
                }, new Date(_currentPhase.startdate).getTime() - new Date().getTime())
            } else if (now > end) {
                //past phase
            }
        }
        if (globalSelectedWallet) {
            if (_currentPhase.allowlist) {
                let allowlist = _currentPhase.allowlist.find((a: any) => a === globalSelectedWallet)
                if (allowlist) {
                    setCurrentPhaseWhitelisted(true)
                } else {
                    setCurrentPhaseWhitelisted(false)
                }
            }
        } else {
            setCurrentPhaseWhitelisted(null)
        }

        setCurrentPhase(_currentPhase)
        setPhases(_phases)
    }

    const ManageCM3 = async () => {
        GetCandyMachine((item?.identity)![0]).then((res: any) => {
            //console.log(res)
            candyMachine = res
            //console.log(res)

            let _supply = 0
            if (premint) {
                _supply = res.items.length - premint
            }


            setSupply(_supply)
            let minted = res.items.filter((i: any) => i.minted).length

            if (premint) {
                minted = minted - premint
                if (minted < 0) minted = 0
            }
            dispatch(setItemsMinted(minted))


            managePhases()
            setCmLoading(false)

            //console.log(_phases)

        }).catch((e: any) => {
            console.log(e)
            toast.error("Error loading candy machine")
            setCmLoading(false)
            setLoading(null)
        })
    }

    const incrementMintAmount = () => {
        let itemsRemaining = supply - itemsMinted
        if (amountToMint < itemsRemaining) {
            mintInput.current.value = amountToMint + 1
            dispatch(setAmountToMint(amountToMint + 1))
        }
    }

    const decrementMintAmount = () => {
        if (amountToMint > 1) {
            mintInput.current.value = amountToMint - 1
            dispatch(setAmountToMint(amountToMint - 1))
        }
    }

    const onChangeMintInput = (e: any) => {
        let val = mintInput.current.value
        if (val > 0) {
            if (val > supply - itemsMinted) {
                val = supply - itemsMinted
            }
            dispatch(setAmountToMint(val))
            mintInput.current.value = val
        } else {
            dispatch(setAmountToMint(1))
            mintInput.current.value = 1
        }
    }

    const mint = async () => {


        if (!user) {
            dispatch(setWalletConnectSettings({ network: Network.Solana }))
            dispatch(showWalletConnectPopup(true))
            return;
        }

        if (solanaWallets.length === 0) {
            dispatch(setWalletConnectSettings({ network: Network.Solana }))
            dispatch(showWalletConnectPopup(true))
            return;
        }

        if (globalSelectedWallet === null) {
            dispatch(showConnectedWalletSelectPopup(true))
            return;
        }

        if (amountToMint > supply - itemsMinted) {
            toast.error("Not enough items remaining")
            return;
        }

        if (currentPhase.max !== 0) {
            if (amountToMint > currentPhase.max) {
                toast.error("You can only mint " + currentPhase.max + " items in this phase")
                return;
            }
        }

        setMintLoading(true)

        if (currentPhase.token) {

            let tokens = await getTokenBalance(globalSelectedWallet)

            if (!tokens) {
                toast.error("Mint failed try again")
                setMintLoading(false)
                return;
            }



            //find token in tokens
            let token = tokens.find((t: any) => { return t.account.data.parsed.info.mint === currentPhase.token })

            //console.log(token)

            if (!token) {
                toast.error("Mint failed try again")
                setMintLoading(false)
                return;
            }

            //@ts-ignore
            let balance = token.account.data.parsed.info.tokenAmount.uiAmount

            if (balance < amountToMint) {
                toast.error("Not enough " + currentPhase.name + " tokens to mint")
                setMintLoading(false)
                return;
            }
        }

        //console.log(amountToMint, candyMachine, anchorWallet, currentPhase.label)

        if (currentPhase.allowlist) {
            let allowlist = currentPhase.allowlist.find((a: any) => a === globalSelectedWallet)
            if (!allowlist) {
                toast.error("You are not on the allowlist for this phase")
                setMintLoading(false)
                return;
            }
        }

        mintCm3(amountToMint, candyMachine, anchorWallet, currentPhase.label, currentPhase.allowlist ? currentPhase.allowlist : null).then((res) => {

            if (res.length === 1 && res[0] === null) {
                toast.error("Mint failed :(")
                setMintLoading(false)
                return;
            }

            dispatch(setMintResult(res))
            ManageCM3()
            setMintLoading(false)

            dispatch(showLaunchMintResultPopup(true))
            MintedVerification(item?.id!, globalSelectedWallet).then(() => { }).catch(() => { })
        }).catch((e: any) => {
            console.log(e)
            toast.error("Mint failed :(")
            setMintLoading(false)
        })



    }

    const anchorWallet = React.useMemo(() => {

        if (!globalSelectedWallet) {
            setCurrentPhaseWhitelisted(null)
        }

        if (!globalSelectedWallet || !user) return;

        //ManageCM3();

        let wallet = findWallet(globalSelectedWallet)

        if (wallet) {
            return {
                publicKey: new PublicKey(globalSelectedWallet),
                signTransaction: wallet.instance.signTransaction,
                signAllTransactions: wallet.instance.signAllTransactions
            }
        }

        return;

    }, [globalSelectedWallet, user])

    useEffect(() => {

        if (item && item?.status === State.ACTIVE) {
            ManageCM3()
            initializeWallets()

            if (globalSelectedWallet) {
                if (currentPhase && currentPhase.allowlist) {
                    let allowlist = currentPhase.allowlist.find((a: any) => a === globalSelectedWallet)
                    if (allowlist) {
                        setCurrentPhaseWhitelisted(true)
                    } else {
                        setCurrentPhaseWhitelisted(false)
                    }
                }
            } else {
                setCurrentPhaseWhitelisted(null)
            }
        }

    }, [globalSelectedWallet])

    const initializeWallets = async () => {

        if (!user) return

        let wallets: any = copyObject(user.wallets)

        for (var i = 0; i < wallets.length; i++) {
            let wallet = findWallet(wallets[i].address)

            if (wallet) {
                let isConnected = await wallet.classInstance.isConnected()

                if (isConnected) {
                    wallets[i].provider = wallet.provider
                    wallets[i].connected = true
                }
            } else {
                wallets[i].connected = false
            }
        }

        if (!globalSelectedWallet)
            if (wallets.length > 0 && wallets.filter((w: any) => w.network === Network.Solana && w.connected).length === 1) {
                dispatch(setGlobalSelectedWallet(wallets.filter((w: any) => w.network === Network.Solana && w.connected)[0].address))
            }

        setSolanaWallets(wallets.filter((w: any) => w.network === Network.Solana && w.connected))
    }

    useEffect(() => {
        initializeWallets()
    }, [user, WalletConnectPopup])


    if (loading === true) {
        return (
            <C.Loading>
                <FontAwesomeIcon icon={faSpinner} spin />
            </C.Loading>
        )
    }

    if (loading === null) {
        return (
            <C.Failed>
                Launch not found
                <Button theme="gradient-border-transparent-bg-on-hover" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)} onClick={closePage}>Close Page</Button>
            </C.Failed>
        )
    }

    if (!item) {
        return (
            <></>
        )
    }

    if (!done) {
        return (
            <></>
        )
    }

    return (
        <C.Launch>

            {mintLoading && (
                <C.FullPageLoading>
                    <FontAwesomeIcon icon={faSpinner} spin />
                </C.FullPageLoading>
            )}

            <C.Header>
                <C.HeaderTop>
                    <C.HeaderTitle>
                        <C.HeaderTitleIcon> <FontAwesomeIcon icon={faRocket} /> </C.HeaderTitleIcon>
                        <C.HeaderTitleText>Launchmap</C.HeaderTitleText>
                    </C.HeaderTitle>
                    <C.HeaderClose onClick={closePage}>
                        &times;
                    </C.HeaderClose>
                </C.HeaderTop>

                <C.HeaderBottom>
                    <C.HeaderBottomTitle>
                        <img src={"/images/chains/" + item.chain + "-icon.png"} alt="chain" /> {item.name}
                    </C.HeaderBottomTitle>
                    <C.Social>
                        {item.twitter && (
                            <C.SocialItem href={formatLink(item.twitter)} target="_blank" hc="#1DA1F2">
                                <FontAwesomeIcon icon={faTwitter} />
                            </C.SocialItem>
                        )}
                        {item.discord && (
                            <C.SocialItem href={formatLink(item.discord)} target="_blank" hc="#5865F2">
                                <FontAwesomeIcon icon={faDiscord} />
                            </C.SocialItem>
                        )}
                        {item.website && (
                            <C.SocialItem href={formatLink(item.website)} target="_blank" hc={color.primary}>
                                <FontAwesomeIcon icon={faGlobe} />
                            </C.SocialItem>
                        )}
                    </C.Social>
                </C.HeaderBottom>

                <C.HeaderDescription>
                    {item.description}
                </C.HeaderDescription>

            </C.Header>

            <C.Body>

                <C.Covers>

                    {(item.covers && item.covers.length > 0) && (
                        <>
                            <C.CoverSlider>
                                <Slider {...sliderSettings}>
                                    {item.covers.map((cover: any, i: number) => (
                                        <C.Cover key={i}>
                                            <img src={env.API.CDN + "/launchpad/covers/" + cover} alt="cover" />
                                        </C.Cover>
                                    ))}
                                </Slider>
                            </C.CoverSlider>
                        </>
                    )}

                </C.Covers>

                {item.status === State.INACTIVE && (
                    <C.Timeout>

                    </C.Timeout>

                )}

                {item.status === State.PASSIVE && (
                    <C.Timeout>
                        <C.TimeoutName>
                            Mint Starts In
                        </C.TimeoutName>
                        <C.MintTimer>
                            <C.MintTimerBox>
                                <Timer date={item.timeout} />
                            </C.MintTimerBox>
                        </C.MintTimer>
                    </C.Timeout>


                )}

                {item.status === State.ACTIVE && (
                    <>


                        <C.SelectWallet onClick={selectWallet}>
                            <C.SelectWalletLeft>
                                <C.SelectWalletTitle>
                                    Minting Wallet  {globalSelectedWallet && (<span>{user.wallets.find((x: any) => x.address === globalSelectedWallet)?.name ?? ""}</span>)}
                                </C.SelectWalletTitle>
                                <C.SelectWalletAddress>
                                    {globalSelectedWallet && (
                                        <>
                                            {shortenPublicKey(globalSelectedWallet)}
                                        </>
                                    )}
                                    {!globalSelectedWallet && (
                                        <><FontAwesomeIcon icon={faCircleExclamation} /> No wallet selected</>
                                    )}
                                </C.SelectWalletAddress>
                            </C.SelectWalletLeft>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </C.SelectWallet>

                        {currentPhaseWhitelisted === false && (
                            <C.NonWhitelisted>
                                <FontAwesomeIcon icon={faCircleExclamation} /> This wallet is not whitelisted for this phase
                            </C.NonWhitelisted>
                        )}

                        {cmLoading && (
                            <C.Loading>
                                <FontAwesomeIcon icon={faSpinner} spin />
                            </C.Loading>
                        )}

                        {currentPhase && (
                            <C.CurrentPhase>

                                <C.CurrentPhaseTop>
                                {itemsMinted < supply && (
                                    <C.CurrentPhaseTitle>
                                        {currentPhase.name}
                                    </C.CurrentPhaseTitle>
                                )}

                                    <C.CurrentPhaseTotalMinted>
                                        <C.CurrentPhaseTotalMintedTop>
                                            <C.CurrentPhaseTotalMintedTopText>
                                                Total Minted
                                            </C.CurrentPhaseTotalMintedTopText>
                                            <C.CurrentPhaseTotalMintedTopNumber>
                                                {Math.floor(itemsMinted / supply * 100)}%
                                                <span>{itemsMinted}/{supply}</span>
                                            </C.CurrentPhaseTotalMintedTopNumber>
                                        </C.CurrentPhaseTotalMintedTop>
                                        <C.CurrentPhaseTotalMintedBar>
                                            <C.CurrentPhaseTotalMintedBarFill percent={itemsMinted / supply * 100} />
                                        </C.CurrentPhaseTotalMintedBar>
                                    </C.CurrentPhaseTotalMinted>

                                </C.CurrentPhaseTop>

                                {new Date(currentPhase.startdate) < new Date() && new Date(currentPhase.enddate) > new Date() && (
                                    <C.Mint>

                                        {itemsMinted < supply && (
                                            <>
                                                <C.MintPrice>
                                                    Price: <span>{(currentPhase.price * amountToMint).toFixed(2)} {Chains[(item.chain as any)].currency}</span>
                                                </C.MintPrice>
                                                <C.MintActions>
                                                    <C.MintNumber>
                                                        <C.MintNumberButton onClick={decrementMintAmount}>-</C.MintNumberButton>
                                                        <C.MintNumberInputBox>
                                                            <C.MintNumberInput onChange={onChangeMintInput} min="1" step="1" type="number" defaultValue={1} ref={mintInput} />
                                                        </C.MintNumberInputBox>
                                                        <C.MintNumberButton onClick={incrementMintAmount}>+</C.MintNumberButton>
                                                    </C.MintNumber>
                                                    <Button theme="gradient" onClick={mint} color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)} disabled={currentPhaseWhitelisted === false}>Mint</Button>
                                                </C.MintActions>
                                            </>
                                        )}

                                        {itemsMinted >= supply && (
                                            <C.MintTimerBox>
                                                SOLD OUT
                                            </C.MintTimerBox>
                                        )}

                                    </C.Mint>
                                )}

                                {new Date(currentPhase.startdate) > new Date() && (
                                    <C.MintTimer>
                                        <C.MintTimerBox>
                                            <Timer date={currentPhase.startdate} />
                                        </C.MintTimerBox>
                                    </C.MintTimer>
                                )}

                            </C.CurrentPhase>
                        )}

                        <C.Phases>

                            {phases.map((phase: any, i: number) => (

                                <C.Phase key={i} active={currentPhase.label === phase.label ? "true" : "false"}>
                                    <C.PhaseLeft>
                                        <C.PhaseLeftTitle>
                                            {phase.name}
                                        </C.PhaseLeftTitle>
                                        <C.PhaseLeftDescription>
                                            {phase.description}
                                        </C.PhaseLeftDescription>
                                    </C.PhaseLeft>
                                    <C.PhaseRight>
                                        {!phase.noend && (
                                            <>
                                                {new Date(phase.startdate) < new Date() && new Date(phase.enddate) > new Date() && (
                                                    <C.PhaseRightTimer>
                                                        <C.PhaseRightTimerTitle>
                                                            Ends in
                                                        </C.PhaseRightTimerTitle>
                                                        <C.PhaseRightTimerTime>
                                                            <Timer date={phase.enddate} />
                                                        </C.PhaseRightTimerTime>
                                                    </C.PhaseRightTimer>
                                                )}
                                            </>
                                        )}

                                        {new Date(phase.enddate) < new Date() && (
                                            <C.PhaseRightLabel>
                                                Ended
                                            </C.PhaseRightLabel>
                                        )}

                                        {new Date(phase.startdate) > new Date() && (
                                            <C.PhaseRightTimer>
                                                <C.PhaseRightTimerTitle>
                                                    Starts in
                                                </C.PhaseRightTimerTitle>
                                                <C.PhaseRightTimerTime>
                                                    <Timer date={phase.startdate} />
                                                </C.PhaseRightTimerTime>
                                            </C.PhaseRightTimer>
                                        )}
                                    </C.PhaseRight>
                                </C.Phase>

                            ))}

                            {/*<C.Phase>
                        <C.PhaseLeft>
                            <C.PhaseLeftTitle>
                                OG Phase
                            </C.PhaseLeftTitle>
                            <C.PhaseLeftDescription>
                                OG only • 1 mint per 3 holding • 1 SOL Price
                            </C.PhaseLeftDescription>
                        </C.PhaseLeft>
                        <C.PhaseRight>
                            <C.PhaseRightLabel>
                                Ended
                            </C.PhaseRightLabel>
                        </C.PhaseRight>
                    </C.Phase>*/}

                        </C.Phases>

                    </>
                )}


            </C.Body>

        </C.Launch>
    )

}

export default LaunchPart