import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { deviceMax } from "styles/media"
import { Link } from "react-router-dom"
import { Row } from "styles"

export const ShuffleLedgerSetup = styled.div`

`

export const LedgerImage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    & > img {
        width: 180px;
    }
    margin-bottom: 16px;
`

export const LedgerTitle = styled.div`
    font-size: 32px;
    text-align: center;
    background: linear-gradient(90deg, ${color.gradient1.start}, ${color.gradient1.middle}, ${color.gradient1.end});
    font-weight:500;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 8px;
`

export const LedgerDescription = styled.div`
    font-size: 14px;
    color: ${color.whiteShade};
    text-align: center;
    margin-bottom: 16px;
`

export const LedgerButton = styled.div`
    display: flex;

    & > button {
        flex: 1;

        &:not(:last-child) {
            margin-right: 8px;
        }
    }
`

export const LedgerChangeAccount = styled.div`

    text-align: center;
    

`

export const LedgerChangeAccountText = styled.div`
    color: ${color.whiteShade};
    cursor: pointer;
    margin-top: 8px;
    color: ${color.primary};
    text-decoration: underline;
    font-size: 14px;
`