import toast from "react-hot-toast"
import { getAllCollections } from "services/api/collections"
import { setCollections } from "services/slices/data"
import { resetFilters, setCurrentViewingCollection, setCurrentViewingToken } from "services/slices/holders"
import { set_Collections } from "services/slices/_collections"
import { store } from "services/store"
import { ManageHolderStates } from "./holders"
import { history } from 'components/etc/history'


export const ManageCurrentViewingToken = () => {
    let code = store.getState().holders.currentViewingTokenCode


    if (code) {
        let token: any = store.getState().data.tokens.find((wl: any) => wl.name.toLowerCase().replace(/ /g, "-") === code)
        if (token) {
            store.dispatch(setCurrentViewingToken(token.id))
            store.dispatch(resetFilters())
            document.title = token.name + " - hub3"
            ManageHolderStates(false, "ManageCurrentViewingToken")
            return;
        }
    }

    store.dispatch(setCurrentViewingToken(null))
    history.push(`/app/`)
    toast.error("Collection not found")
}