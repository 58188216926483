import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import { Button } from "./style"

const button = (...props: any) => {

    if (props[0].link === "routerlink") {
        return (
            <Button tag={Link} {...props[0]}>
                {props[0].children}
            </Button>
        )
    } else if (props[0].link === "link") {
        return (
            <Button tag="a" {...props[0]}>
                {props[0].children}
            </Button>
        )
    } else {
        return (
            <Button tag="button" {...props[0]}>
                {props[0].loading === "true" && (
                    <FontAwesomeIcon className="spinner___btn" icon={faSpinner} spin />
                )}
                {props[0].children}
            </Button>
        )
    }
}

export default button