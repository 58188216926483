import React from "react"
import * as P from "./style"

import { useSelector } from "react-redux"
import BasePopup from "components/popups/base"
import { IconCollections, IconConnectWallet } from "utils/icons"

import { showCloudWalletPopup } from "services/slices/popup"
import EditProfile from "components/parts/editProfile"
import { displayAirDrop, displayCloudWallet } from "utils/parts"
import AirdropPart from "components/parts/airdrop"


const AirdropPopup = () => {

    const show = useSelector((state: any) => state.popup.AirdropPopup)
    
    return (
        <P.Popup>
            <BasePopup title="Airdrop" show={show} hide={() => displayAirDrop(false)} glasmorph={true}>
                <AirdropPart/>
            </BasePopup>
        </P.Popup>
    )
}

export default AirdropPopup