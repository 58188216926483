import { Badge } from "components/ui/badge/style"
import { ActiveWalletBar } from "components/widgets/activeWalletBar/style"
import styled from "styled-components"
import { deviceMax, deviceMin } from "styles/media"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"

export const Share = styled.div`
    display: flex;
    border-radius:8px;
    overflow:hidden;

    @media ${deviceMax.md}{
        flex-direction:column-reverse;
        overflow:unset;
    }
`

export const ShareLeftContainer = styled.div`
    padding:0 24px 24px 24px;

    @media ${deviceMax.md}{
        padding:0 16px 16px 16px;
    }
`

export const ShareLeft = styled.div`
    width:43%;
    background-color:${color.bg2};

    @media ${deviceMax.md}{
        background-color:${Hex2Rgba(color.secondary, .16)};
        margin-top:0px;
        padding-top:24px;
        border-radius:0px;
        width:100%;
    }
    
    
`

export const User = styled.div<{bg? : string,mobile?:string}>`
    display:flex;
    align-items:center;
    margin-bottom:24px;
    padding:8px 24px 4px 24px;

    ${props => (props.mobile !== "true" && !props.bg) && `
        padding-top:16px;
    `}

    
    ${props => props.bg && `
        background-image:url(${props.bg});
        background-size:cover;
        background-position:center;
    `}

    ${props => props.mobile !== "true" && `
        @media ${deviceMax.md}{
            display:none;
        }
    `}
    ${props => props.mobile === "true" && `
        display:none;
        @media ${deviceMax.md}{
            display:flex;
            border-radius:8px;
        }
    `}
`

export const Avatar = styled.div`
    position:relative;
    width:80px;
    height:80px;

    & img{
        width:80px;
        height:80px;
        border-radius:100%;
        z-index:1;
        position:relative;
    }

    &::after {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 50%; 
        padding: 2px;
        transform: scale(1.02);
        background:linear-gradient(45deg,${Hex2Rgba(color.gradient1.start, 1)},${Hex2Rgba(color.gradient1.middle, 1)},${Hex2Rgba(color.gradient1.end, 1)}); 
        -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
                mask-composite: exclude; 
    }
`

export const UserName = styled.div`
    margin-left:20px;
    text-shadow:0px 0px 10px ${Hex2Rgba(color.black, 1)};
`

export const Name = styled.div`
    font-size:20px;
    font-weight:600;
    word-wrap: break-word;
    line-height:125%;
    text-shadow:0px 0px 10px ${Hex2Rgba(color.black, 1)};
`

export const Status = styled.div`
    font-size:14px;
    word-wrap: break-word;
    background-color:${Hex2Rgba(color.black, .75)};
    padding:5px 10px;
    border-radius:8px;
    width:fit-content;
    margin-top:8px;
    box-shadow:0px 0px 10px ${Hex2Rgba(color.black, 0.75)}; 
`

export const Title = styled.div`
    color:${color.primary};
    font-size:14px;
    line-height:125%;
    font-weight:500;
    margin-bottom:8px;
`

export const Social = styled.div`
    margin-bottom:24px;
    display:flex;
    align-items:center;
    & > *{
        width:35px;
        height:35px;
        display:flex;
        align-items:center;
        justify-content:center;
        cursor:pointer;
        text-decoration:none;
        background-color:${Hex2Rgba(color.secondaryFade, .5)};
        &:first-child{
            margin-right:8px;
        }
        border-radius:50%;
        & svg{
            font-size:18px;
            color:${color.white};
            transition:all 0.3s;
        }

        & svg{
            /*filter:drop-shadow(0px 0px 5px ${color.primary});*/
        }
        
    }
`

export const Badges = styled.div`
    display:flex;
    &  *{
        font-size:12px;
    }
    flex-wrap:wrap;
    min-height:30px;
    
    & ${Badge}:not(:last-child){
        margin-right:10px;
    }

    & ${Badge}{
        margin-bottom:10px;
    }

    margin-bottom:24px;
`

export const Bio = styled.div`
    font-size:14px;
    color:${color.white};
    word-wrap: break-word;
`

export const Expand = styled.div`
    margin-top:24px;
`

export const ShareRight = styled.div`
    padding-left:24px;
    padding-right:24px;
    padding-bottom:24px;
    background-color:${color.bg1};
    position:relative;
    width:57%;
    @media ${deviceMax.md}{
        padding:10px;
        /*background-color:unset;*/
        width:100%;
    }
`

export const Header = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding-top:16px;
    width:100%;

    @media ${deviceMax.md}{
        display:none;
    }
`

export const Wallet = styled.div`
    background-color:${color.secondary};
    border-radius:64px;
    padding:8px 16px;
    font-size:14px;
    display:flex;
    align-items:center;
    cursor:pointer;
    & svg{
        height:16px;
        margin-right:8px;
    }

    &:hover{
        background-color:${Hex2Rgba(color.secondary, .75)};
    }
`

export const MobileWallet = styled.div`
    display:none;

    @media ${deviceMax.md}{
        display:block;
        width:100%;
        & > *{
            width:100%;
            justify-content:center;
            background-color:${Hex2Rgba(color.secondary, .16)};
            border-radius:8px;
            & span{
                color:${color.whiteShade};
                margin-right:8px;
            }
        }
        margin-top:16px;
    }
`

export const Close = styled.div`
    padding-left:16px;
    padding-top:8px;
    padding-bottom:8px;
    cursor:pointer;
    font-size:24px;
`

export const ShareDetails = styled.div`
    margin-top:16px;
    background-color:${Hex2Rgba(color.secondary, .16)};
    padding:16px 24px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    border-radius:8px;

    @media ${deviceMax.md}{
        padding:16px;
    }
`

export const ShareDetail = styled.div`
    
`

export const ShareDetailTitle = styled.div`
    font-size:13px;
    color:${color.whiteShade};
    text-align:left;

`

export const ShareDetailNumber = styled.div`
    color:${color.white};
    font-size:16px;
    display:flex;
    align-items:center;
    justify-content:flex-start;
    & img{
        height:16px;
        margin-left:4px;
    }
`

export const Transaction = styled.div`
    margin-top:24px;
    background-color:${Hex2Rgba(color.secondary, .16)};
    padding:24px;
    border-radius:8px;

    @media ${deviceMax.md}{
        margin-top:16px;
        padding:16px;
    }

`

export const TransactionHeader = styled.div`
    display:flex;
    justify-content:space-between;

    @media ${deviceMax.md}{
        flex-direction:column;
        align-items:center;
    }
`

export const TransactionTypes = styled.div`
    display:flex;
    align-items:center;
    background-color:${color.bg2};
    border-radius:64px;
    @media ${deviceMax.md}{
        margin-bottom:16px;
        max-width:100%;
        width:100%;
    }
`

export const TransactionType = styled.div<{isactive?:string}>`
    position:relative;
    cursor:pointer;
    color:${color.primary};
    font-size:14px;
    line-height:125%;
    display:flex;
    align-items:center;
    justify-content:center;
    padding: 8px 20px;
    user-select:none;
    border-radius:64px;
    flex:1;

    ${props => props.isactive === "true" && `
        background-color:${color.secondary};
        color:${color.white};

        &::after{
            content: "";
            z-index:1;
            position: absolute;
            inset: 0;
            border-radius: 24px; 
            padding: 1px; 
            background:linear-gradient(45deg,${color.gradient1.start},${color.gradient1.middle},${color.gradient1.end}); 
            -webkit-mask: 
            linear-gradient(#fff 0 0) content-box, 
            linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
                    mask-composite: exclude; 
        }
    `}
    transition: all .1s ease-in-out;
    ${props => props.isactive !== "true" && `
        &:hover{
            color:${color.white};
        }
    `}
`

export const TransactionPricesWrapper = styled.div`

`

export const TransactionPricesChange = styled.div`
    font-size:11px;
    display:flex;
    align-items:center;
    justify-content:flex-end;
    color:${color.whiteShade};
    margin-bottom:4px;

    @media ${deviceMax.md}{
        justify-content:center;
        font-size:12px;
    }
`

export const TransactionPricesChangeValue = styled.div<{ispositive:string}>`
    margin-left:4px;

    ${props => props.ispositive === "true" && `
        color:${color.shareGreen};
    `}
    ${props => props.ispositive === "false" && `
        color:${color.shareRed};
    `}
    ${props => props.ispositive === "null" && `
        color:${color.whiteShade};
    `}
`

export const TransactionPrices = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
`

export const TransactionPrice = styled.div`
    font-size:14px;
    display:flex;
    align-items:center;
    & img{
        height:14px;
        margin-left:4px;
    }
    font-weight:600;
    & span{
        margin-right:4px;
        color:${color.whiteShade};
        font-weight:400;
    }
`

export const TransactionPriceSeperator = styled.div`
    background-color:${color.primary};
    height:14px;
    margin:0px 8px;
`

export const TransactionForm = styled.div`
    margin-top:24px;
    display:flex;
    align-items:center;

    & input{
        padding:16px;

        font-weight:500;
        font-family:sans-serif;
        -moz-appearance: textfield;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        border-color:${color.secondary};
        border-width:2px !important;
    }

    & label{
        font-size:12px;
        font-weight:500;
    }
`

export const TransactionFormButton = styled.div`
    margin-top:16px;
    & button{
        height:50px;
        font-size:14px;
        font-weight:500;
    }
`

export const TransactionFormAmountInput = styled.div`
    flex:1;
    margin-right:16px;
`

export const TransactionFormSlippageInput = styled.div`
    flex:.3;

    & label svg{
        color:${color.whiteShade};
    }
`

export const TransactionDetails = styled.div`
    margin-top:24px;
`

export const TransactionDetail = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin-bottom:12px;
`

export const TransactionDetailTitle = styled.div`
    color:${color.whiteShade};
    font-size:14px;

    & svg{
        margin-left:8px;
        font-size:12px;
    }
`

export const TransactionDetailValue = styled.div<{highlight?:string}>`
    color:${color.whiteShade};
    font-size:14px;
    display:flex;
    align-items:center;
    & img{
        height:15px;
        margin-left:4px;
    }

    ${props => props.highlight === "true" && `
        color:${color.white};
        font-weight:600;
        font-size:16px;
    `}
`

export const ShareChart = styled.div`
    margin-bottom:24px;

    & ${Title} {
        display:none;
    }

    @media ${deviceMax.md}{
        & ${Title} {
            display:block;
        }
    }
`



export const Skeleton = styled.div`
    background: var(--Lazy-Load, linear-gradient(90deg, #171736 0%, #28224E 0.01%, #171736 100%, #282360 100%));
    height: 16px;
    border-radius: 32px;
    width: 100%;
`

export const ShareDetailSkeleton = styled.div`
    flex:1;

    & ${Skeleton}:not(:last-child){
        margin-bottom:4px;
    }
`

export const TransactionPricesSkeleton = styled.div`
    & ${Skeleton}{
        width:70px;
    }
    display:flex;
    flex-direction:column;
    align-items:flex-end;
   
`

export const TransactionPricesSkeleton2 = styled.div`
    display:flex;
    margin-top:8px;
`

export const TransactionPricesSkeleton2Seperator = styled.div`
    background-color:${color.primary};
    height:14px;
    width:1px;
    border-radius:50%;
    margin:0px 8px;
`

export const TransactionDetailValueSkeleton = styled.div`
    & ${Skeleton}{
        width:70px;
    }
`

export const TxError = styled.div`
    display:flex;
    margin-top:16px;
    margin-bottom:8px;
    font-size:13px;
`
export const TxErrorText = styled.div`
    
    color:${color._mapcolor3};
`
export const TxErrorLink = styled.span`
    color:${color.primary};
    cursor:pointer;
    &:hover{
        text-decoration:underline;
    }
`