import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"


export const Login = styled.div`

`

export const Text = styled.div`
    text-align:center;
    margin-bottom:16px;
    font-size:15px;
    color:${color.whiteShade};
`

export const LoginButton = styled.div`
    position:relative;
    text-align:center;
    cursor:pointer;
    align-items:center;
    border-radius:48px;
    overflow:hidden;
    padding:16px 8px;
    background-color:${props => Hex2Rgba("#1DA1F2", 1)};
    transition:.1s all ease-in-out;
    &:hover{
        background-color:${props => Hex2Rgba("#1DA1F2", .64)};
    }   
`

export const SocialIcon = styled.div`
    height:100%;
    padding-left:32px;
    position:absolute;
    left:16px;

`

export const SocialText = styled.div`
    font-size:15px;
`

export const Title = styled.div`
    font-size:18px;
    text-align:center;
    margin-bottom:16px;
`

export const Terms = styled.div`
    font-size:12px;
    color:${color.whiteShade};

    & a {
        color:${color.whiteShade};
    }
    text-align:center;
    margin-top:16px;
`