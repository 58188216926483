import styled from 'styled-components'
import { color as colors} from "styles/theme"

const color : any = colors;

export const Logo = styled.div<{width? : any, color? : any}>`
    & svg{
        width: ${props => props.width ? props.width : "76px"};
    }
    & > * {
        fill: ${props => props.color ? color[props.color] : color.white }
    }
    display:flex;
    align-items:center;
`