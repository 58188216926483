import styled, { keyframes } from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { deviceMax } from "styles/media"
import { Link } from "react-router-dom"

export const ShuffleFair = styled.div`
    & label{
        font-size: 14px;
        margin-top: 8px;
        display: block;
    }
`

export const Whatis = styled.div`
    margin-bottom: 16px;
`

export const Title = styled.div`
    font-size: 18px;
    color: ${color.primary};
`

export const Text = styled.div`
    font-size: 12px;
    color: ${color.whiteShade};
`

export const SourceCode = styled.div``
export const Code = styled.div`
    font-size: 12px;

    & > *{
    border-radius: 8px;
    }
`
export const VerificationForm = styled.form`

    margin-bottom: 16px;
    margin-top: 4px;

    & p{
        margin:0;
        padding:0;
        margin-bottom: 8px;
        margin-top: 4px;
        color: ${color.error};
        font-size: 14px;
    }

    & button{
        margin-top: 8px;
    }
`
export const Result = styled.div`
    border: 1px solid ${Hex2Rgba(color.primary, .3)};
    padding: 8px;
    border-radius: 8px;
    min-height: 100px;
`

export const ResultItem = styled.div`

`

export const ResultTitle = styled.div`
    cursor: pointer;
`

export const ResultValue = styled.div<{visible:string}>`
    display: ${props => props.visible === "true" ? "block" : "none"};
    word-break: break-all;
    font-size: 12px;
`

export const ResultValueTickets = styled.div<{visible:string}>`
    display: ${props => props.visible === "true" ? "flex" : "none"};
    font-size: 12px;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;

    & div{
        border: 1px solid ${Hex2Rgba(color.primary, .3)};
        padding: 2px;
        border-radius: 8px;
    }
    
    
`