import React from "react"
import * as C from "./style"
import CurrentCollection from "components/parts/currentCollection"
import { useSelector } from "react-redux"
import { MainParts } from "utils/parts"

const CurrentCollectionMobileWidget = () => {

    const currentPage = useSelector((state: any) => state.data.currentPage)
    const active = useSelector((state: any) => state.data.sidebarActive)
    const currentViewingCollection = useSelector((state: any) => state.holders.currentViewingCollection)

    return (
        <C.CurrentCollection overlay={currentPage ? true : false} active={active} visible={currentViewingCollection && currentPage === MainParts.Home ? true : false}>
            <C.CurrentCollectionContent>
                <CurrentCollection />
            </C.CurrentCollectionContent>
            
        </C.CurrentCollection>
    )
}

export default CurrentCollectionMobileWidget