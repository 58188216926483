import { Button } from 'components/ui/button/style'
import styled, { keyframes } from 'styled-components'
import { Col,Row, Container } from 'styles'
import { color, Getcolor } from 'styles/theme'
import { Hex2Rgba } from 'utils/helpers'
import { deviceMax, deviceMin } from 'styles/media'
import Landingbg from "./assets/landing.png"

export const Home = styled.div`

`

export const Header = styled.div`
    width: 100%;
    background-color: #0A0A0B;
    border-bottom: 1px solid rgb(51, 51, 51);
    height:90px;
    display: flex;
    align-items: center;
    & ${Container} {
        display: flex;
        height: 100%;
    }

    
`

export const LogoMobile = styled.div`
    display: none;
    flex:1;
    @media ${deviceMax.md} {
        display: flex;
    }
`


export const Logo = styled.div`
    display: flex;
    margin-right: 48px;
`

export const Menu = styled.div`
    flex:1;
    display: flex;
    justify-content: center;
    align-items: center;

    & a{
        color: ${color.white};
        text-decoration: none;

        &:not(:last-child){
            margin-right: 30px;
        }

        &:hover{
            color: ${color.primary};
        }

        transition: all 0.1s ease;

        height: 100%;
        display: flex;
        align-items: center;
    }

    @media ${deviceMax.md} {
        display: none;
    }
   
`

export const Actions = styled.div`
    flex:1;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & > *:not(:last-child){
        margin-right: 10px;

    }

    & a {
        color: ${color.white};
    }

    @media ${deviceMax.sm} {
        flex:1.1;
        & > ${Button} {
            font-size: 12px;
        }
    }
`

export const Landing = styled.div`
    text-align: center;
    background-image: url(${Landingbg});
    height: calc(100vh - 90px);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`

export const LandingContent = styled.div`
    padding-top:150px;

    @media ${deviceMax.sm} {
        padding-top: 100px;
    }
`

export const LandingTitle1 = styled.div`
    font-size:56px;
    color: ${color.white};
    font-weight: 700;
    line-height: 1;

    @media ${deviceMax.sm} {
        font-size: 40px;
    }
`

export const LandingTitle2 = styled.div`
    font-size:80px;
    background: linear-gradient(90deg, ${color.gradient1.start}, ${color.gradient1.middle}, ${color.gradient1.end});
    font-weight:500;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    margin-top:10px;
    line-height: 1;

    @media ${deviceMax.sm} {
        font-size: 60px;
    }
`

export const LandingDescription = styled.div`
    font-size:16px;
    color: ${Hex2Rgba(color.white, 0.8)};
    margin-bottom: 30px;
    margin-top: 30px;

    @media ${deviceMax.sm} {
        text-shadow: 0 0 10px rgba(0,0,0,0.5);
    }
`

export const LandingActions = styled.div`
    & a{
        padding: 13px 35px;
        font-size: 16px;

        @media ${deviceMax.sm} {
            box-shadow: 0 0 10px rgba(0,0,0,0.2);
         }

         &:not(:last-child){
            margin-right: 20px;
         }
    }

    @media ${deviceMax.sm} {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & a{
            &:not(:last-child){
                margin-bottom: 20px;
                margin-right:0px;
             }
        }
    }
`

export const MenuMobile = styled.div`
    display: none;
@media ${deviceMax.md} {
        display: flex;
        align-items: center;
        font-size: 30px;

        & svg{
            cursor: pointer;
        }
    }


`

export const MenuTimes = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 30px;
    color: ${color.white};
    cursor: pointer;
`

export const MobileMenu = styled.div<{visible:string}>`
padding-top:48px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${color.black};
    z-index: 999;
    display: none;

    @media ${deviceMax.md} {
        ${props => props.visible === "true" ? "display: block;" : "display: none;"}
    }

    & a{
        color: ${color.white};
        text-decoration: none;
        display: block;
        padding: 20px 0;
        text-align: center;
        font-size: 20px;
         &:not(:last-child){
            margin-bottom: 0px;
         }

         
    }
`

export const Footer = styled.div`
    background: linear-gradient(180deg, #0A0A0B 0%, #0D0D15 100%);
    padding:40px 0px;

    & > ${Container} {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    @media ${deviceMax.sm} {
        padding:20px 0px;
    }
`

export const FooterLeft = styled.div`
`

export const FooterRight = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & > *:not(:last-child){
        margin-right: 10px;
    }

    & > *{
        color: ${color.white};
        text-decoration: none;
        font-size: 14px;
    }
`