import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import React from "react";
import * as C from "./style";

const ShufflePrizeLB = (props: any) => {

    return (
        <C.ShufflePrizes>
            {props.items.map((item: any, index: any) => (
                <C.Prize key={index}>
                    <C.PrizeAmount>
                        {(item.amount / LAMPORTS_PER_SOL).toFixed(2)} {props.name} <span>x{item.count}</span>
                    </C.PrizeAmount>
                    <C.PrizeTotal>
                        Total: {(item.amount / LAMPORTS_PER_SOL * item.count).toFixed(2)} {props.name} {/* <span>%34 chance per ticket</span> */}
                    </C.PrizeTotal>
                </C.Prize>
            ))}
        </C.ShufflePrizes>
    )

}

export default ShufflePrizeLB;