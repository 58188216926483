import { Button } from "components/ui/button/style"
import styled from "styled-components"
import { deviceMax } from "styles/media"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"

export const Map = styled.div`
    height:100vh;
    overflow:hidden;
    & canvas {
        width:100% !important;
        height:100% !important;
    }
`

export const Marker = styled.div`
    position:fixed;
    z-index:998;
    font-size:24px;
    color:${color.primary};
    left: 50%;
    top: 50%;
    transform: translate(-50%,-100%);
    transition: all 0.1s ease-in-out;
`

export const Buttons = styled.div`
    position:fixed;
    bottom:20px;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;

    & ${Button}:not(:last-child){
        margin-right:10px;
    }

    @media ${deviceMax.md}{
        bottom:80px;
    }

    
`

export const ButtonsContainer = styled.div`
    backdrop-filter: blur(20px);
    background: ${Hex2Rgba(color.bg1, .64)};
    padding: 10px 20px;
    border-radius: 8px;
`