import React, { useEffect, useState } from 'react'
import * as C from "./style"
import Logo from "components/ui/logo"
import { Container } from 'styles'
import { Link, NavLink } from 'react-router-dom'
import { IconChat, IconCog, IconGlobe, IconGuide, IconMenu, IconShuffle, IconWhitelist } from 'utils/icons'
import { useDispatch, useSelector } from 'react-redux'
import { setSidebarActive } from 'services/slices/data'
import Button from 'components/ui/button'
import { Hex2Rgba, getAvatar } from 'utils/helpers'
import { color } from 'styles/theme'
import { MainParts, displayAirDrop, displayChatWidget, displayCollections, displayEditProfile, displayGuide, displayLoginPopup, displayProfile, displaySettings, displayWalletConnectPopup } from 'utils/parts'
import env from 'env'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo, faGlobe, faSignIn, faBook, faRocket, faComments } from '@fortawesome/free-solid-svg-icons'
import { IconCollections } from "utils/icons"
import ReactTooltip from 'react-tooltip'
import toast from 'react-hot-toast'

const Header = (props: any) => {

    const sidebarActive = useSelector((state: any) => state.data.sidebarActive)
    const user = useSelector((state: any) => state.user.user)
    const dispatch = useDispatch()

    const isNavActive = (...path: any) => {

        let location = window.location.pathname

        // if one of the paths is active, return active
        for (let i = 0; i < path.length; i++) {
            //remove last slash
            if (path[i][path[i].length - 1] === "/")
                path[i] = path[i].slice(0, -1)

            if (location[location.length - 1] === "/")
                location = location.slice(0, -1)

            if (location === path[i])
                return "active"

            //if path contains * then it is a wildcard
            if (path[i].includes("*")) {
                path[i] = path[i].replace("*", "")
                if (location.includes(path[i]))
                    return "active"
            }
        }

        return ""
    }

    useEffect(() => {
        ReactTooltip.rebuild()
        ReactTooltip.hide()
    }, [])

    const chatWidgetVisible = useSelector((state: any) => state.data.displayChat)
    const currentPage = useSelector((state: any) => state.data.currentPage)
    const displayChat = (e: any) => {
        e.preventDefault();

        if (!user) {
            return displayLoginPopup(true)
        }

        if (currentPage === MainParts.Chat) {
            return;
        }
        displayChatWidget(true)
    }

    return (
        <C.Header>

            <C.Top>
                <C.Logo>
                    <Link to="/">
                        <Logo />
                    </Link>
                </C.Logo>
                <C.Nav>
                    <C.NavItem data-place="right" data-tip="Map">
                        <Link className={isNavActive("/app", "/app/collections")} to="/app">
                            <IconGlobe />
                        </Link>
                    </C.NavItem>
                    <C.NavItem data-place="right" data-tip="Keys">
                        <Link className={isNavActive("/app/shares", "/app/shares/*")} to="/app/shares" >
                            <IconCollections />
                            <C.NavItemBadge>
                                New
                            </C.NavItemBadge>
                        </Link>
                    </C.NavItem>

                    <C.NavItem data-place="right" data-tip="Chat">
                        <a className={isNavActive("/app/chat", "/app/chat/*")} href="/app/chat" onClick={displayChat} >
                            <IconChat/>
                        </a>
                    </C.NavItem>

                    <C.NavItem data-place="right" data-tip="Airdrop">
                        <div onClick={() => displayAirDrop(true)} >
                            <IconShuffle />
                        </div>
                    </C.NavItem>

                    {/*<C.NavItem data-place="right" data-tip="Shuffle">
                        <a target="_blank" href="https://shuffle.pub/" >
                            <IconShuffle />

                        </a>
                    </C.NavItem>
                    {/*<C.NavItem data-place="right" data-tip="Launchmap">
                        <Link className={isNavActive("/app/launchmap", "/app/launchmap/*")} to="/app/launchmap" >
                            <FontAwesomeIcon icon={faRocket} />
                        </Link>
    </C.NavItem>*/}
                </C.Nav>
            </C.Top>

            <C.Bottom>
                <C.Profile>
                    <C.ProfileItem onClick={() => displayGuide()} data-place="right" data-tip="Guide">
                        <div tabIndex={0}>
                            <IconGuide />
                        </div>
                    </C.ProfileItem>
                    {user && (
                        <>
                            <C.ProfileItem onClick={() => displaySettings()} data-place="right" data-tip="Settings">
                                <div tabIndex={0}>
                                    <IconCog />
                                </div>
                            </C.ProfileItem>
                            {/*<C.ProfileItem type="profile-picture" data-place="right" data-tip="Profile">
                                <C.ProfilePicture to={"/app/share/"+user.id} tabIndex={0}>
                                    <img src={user.avatar ? env.API.CDN + "avatars/" + user.avatar : "/images/etc/user.webp"} alt="profile picture" />
                                </C.ProfilePicture>
                    </C.ProfileItem>*/}
                            <C.ProfileItem type="profile-picture" data-place="right" data-tip="Profile" onClick={() => displayEditProfile(true)} >
                                <C.ProfilePicture tabIndex={0}>
                                    <img src={getAvatar(user.avatar)} alt="profile picture" />
                                </C.ProfilePicture>
                            </C.ProfileItem>
                        </>
                    )}
                    {!user && (
                        <C.ProfileItem type="connect-wallet" onClick={() => displayLoginPopup(true)} data-place="right" data-tip="Login">
                            <div tabIndex={0}>
                                <FontAwesomeIcon icon={faSignIn} />
                            </div>
                        </C.ProfileItem>
                    )}
                </C.Profile>
            </C.Bottom>

        </C.Header>
    )
}

export default Header