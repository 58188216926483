import { faDiscord, faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faAngleRight, faCalendarAlt, faCoins, faDollarSign, faGlobe } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Item } from "components/parts/bar/style"
import env from "env"
import { Chains } from "models/enums/network"
import { State } from "models/enums/state"
import React, { useEffect } from "react"
import { color } from "styles/theme"
import { capitalizeFirstLetter, formatLink, toLink, wlDate, wlPrice, wlSupply } from "utils/helpers"
import * as C from "./style"

const WhitelistCollection = (props: any) => {
    return (
        <C.WLCollection view={props.view}>
            <C.Top>
                <C.Image view={props.view} to={`/app/whitelist/${toLink(props.item.name)}`}>
                    <img src={env.API.CDN + "whitelist/covers/" + props.item.cover} alt={props.item.name} />
                </C.Image>
                <C.Info >
                    <C.Name view={props.view} to={`/app/whitelist/${toLink(props.item.name)}`}>
                        {props.item.name}
                        <C.State data-tip={props.item.status === State.ACTIVE ? "Active" : props.item.status === State.PASSIVE ? "Coming Soon" : "Closed"} state={props.item.status} ></C.State>
                    </C.Name>
                    {(props.view && (props.item.twitter || props.item.discord || props.item.website) ) && (
                        <C.Social>
                            {props.item.twitter && (
                                <C.SocialItem href={formatLink(props.item.twitter)} target="_blank" hc="#1DA1F2">
                                    <FontAwesomeIcon icon={faTwitter} />
                                </C.SocialItem>
                            )}
                            {props.item.discord && (
                                <C.SocialItem href={formatLink(props.item.discord)} target="_blank" hc="#5865F2">
                                    <FontAwesomeIcon icon={faDiscord} />
                                </C.SocialItem>
                            )}
                            {props.item.website && (
                                <C.SocialItem href={formatLink(props.item.website)} target="_blank" hc={color.primary}>
                                    <FontAwesomeIcon icon={faGlobe} />
                                </C.SocialItem>
                            )}
                        </C.Social>
                    )}
                    <C.Description view={props.view} to={`/app/whitelist/${toLink(props.item.name)}`}>{props.item.description}</C.Description>
                    {!props.view && (
                        <C.Social>
                            {props.item.twitter && (
                                <C.SocialItem href={formatLink(props.item.twitter)} target="_blank" hc="#1DA1F2">
                                    <FontAwesomeIcon icon={faTwitter} />
                                </C.SocialItem>
                            )}
                            {props.item.discord && (
                                <C.SocialItem href={formatLink(props.item.discord)} target="_blank" hc="#5865F2">
                                    <FontAwesomeIcon icon={faDiscord} />
                                </C.SocialItem>
                            )}
                            {props.item.website && (
                                <C.SocialItem href={formatLink(props.item.website)} target="_blank" hc={color.primary}>
                                    <FontAwesomeIcon icon={faGlobe} />
                                </C.SocialItem>
                            )}
                        </C.Social>
                    )}
                </C.Info>

            </C.Top>
            <C.Details view={props.view} to={`/app/whitelist/${toLink(props.item.name)}`}>
                <C.DetailsTop>
                    <C.DetailsItem>
                        <C.DetailsItemTitle>Mint Date</C.DetailsItemTitle>
                        <C.DetailsItemContent>
                            {wlDate(props.item.mintDate)}
                        </C.DetailsItemContent>
                    </C.DetailsItem>
                    <C.DetailsItem>
                        <C.DetailsItemTitle>Supply</C.DetailsItemTitle>
                        <C.DetailsItemContent>
                            {wlSupply(props.item.supply)}
                        </C.DetailsItemContent>
                    </C.DetailsItem>
                    <C.DetailsItem>
                        <C.DetailsItemTitle>Price</C.DetailsItemTitle>
                        <C.DetailsItemContent>
                            {wlPrice(props.item.price, Chains[props.item.chain].currency)}
                        </C.DetailsItemContent>
                    </C.DetailsItem>
                </C.DetailsTop>
                <C.DetailsBottom>
                    <C.SpotsTaken>
                        <span>{props.item.entryCount > props.item.whitelistCount ? props.item.whitelistCount : props.item.entryCount }/{props.item.whitelistCount}</span> Spots Taken
                    </C.SpotsTaken>
                    <C.Chain>
                        <img src={"/images/chains/" + props.item.chain + "-icon.png"} alt="chain" /> {capitalizeFirstLetter(Chains[props.item.chain].name)}
                        {!props.view && (
                            <C.Apply>
                                <FontAwesomeIcon icon={faAngleRight} />
                            </C.Apply>
                        )}

                    </C.Chain>
                </C.DetailsBottom>
            </C.Details>
        </C.WLCollection>
    )
}

export default WhitelistCollection