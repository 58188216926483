export const ErrorCodes : any = {
    "ALREADY_VERIFIED": "You already have verified nominations",
    "SELF_NOMINATION": "You cannot nominate yourself",
    "DUPLICATE_NOMINATION": "You cannot nominate the same person twice",
    "ALREADY_IN_COLLECTION": "One of your nominations are already nominated by someone else", 
    "NO_NOMINATIONS": "You have not nominated anyone",
    "OG_REACHED":"Alpha spot limit reached, you need to be nominated by someone to join",
    "ALREADY_ELIGIBLE":"One of your nominations is already eligible to join, they do not need to be nominated",
    "NO_TWITTER":"You need to connect your twitter account to continue",
    "NOT_ENOUGH_NOMINATIONS":"You did not nominate enough people",
    "NO_COLLECTION_TWEET":"Cannot find a tweet with nominated people",
    "NOT_ALL_NOMINATIONS_IN_TWEET":"Not all of your nominations are in the tweet",
    "NO_DISCORD":"You need to connect your discord account to continue",
    "NO_ROLE":"You don't have the required role to join",
    "DISCORD_REAUTH":"You need to connect your discord account to continue",
    "NO_DISCORD_JOIN":"You are not in the discord server",
    "NO_LIKE":"You need to like the tweet to continue",
    "NO_RETWEET":"You need to like & retweet the tweet to continue",
    "NO_FOLLOW":"You need to follow the twitter account to continue",
    "INVALID_TWITTER_ACCOUNTS":"One of your nominations is not a valid twitter account",
    "FULL":"The whitelist is full :(",
    "INVALID_CODE":"Invalid code",
    "USED_CODE":"Code already used",
    "TRY_AGAIN":"Something went wrong, please try again",
    "RATE_LIMIT_EXCEED":"We are experiencing high traffic, please try again later",
}