import { faDiscord, faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faGlobe, faSpinner, faUpRightAndDownLeftFromCenter } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Badge from "components/ui/badge"
import Button from "components/ui/button"
import env from "env"
import React, { useEffect, useState } from "react"
import toast from "react-hot-toast"
import ReactTooltip from "react-tooltip"
import { getPlace } from "services/api/places"
import { store } from "services/store"
import { color } from "styles/theme"
import { formatLink, GetRandomGif, GetTwitterHandle, Hex2Rgba, toLink } from "utils/helpers"
import { displayHolderProfile } from "utils/parts"
import * as C from "./style"
import { history } from 'components/etc/history'
import { Recruit } from "models/enums/recruit"
import { getStory } from "services/api/story"

const StoryMap = (props: any) => {

    const [story, setStory] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<boolean>(false)

    useEffect(() => {
        getStory(props.story.id).then((res: any) => {
            setStory(res.data.story)
            setLoading(false)
        }).catch((err: any) => {
            toast.error("Error loading story")
            setLoading(false)
            setError(true)
        })
    }, [])

    useEffect(() => {
        ReactTooltip.rebuild()
        ReactTooltip.hide()
    }, [story])

    if (loading)
        return (<C.Loading><FontAwesomeIcon icon={faSpinner} spin /></C.Loading>)

    if (error)
        return (<C.Loading>Failed to load story data</C.Loading>)

    return (
        <C.ProfileMap>
            <C.User>

                <C.UserTop bg={story.cover ? env.API.CDN + "stories/covers/" + story.cover : ""}>
                </C.UserTop>
                
                <C.Info>

                    <C.Description style={{whiteSpace: "pre-wrap"}}>
                        {story.story}
                    </C.Description>
                </C.Info>
            </C.User>

        </C.ProfileMap>
    )
}

export default StoryMap