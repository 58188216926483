import React, { useEffect, useState } from "react"
import * as C from "./style"

import { useDispatch, useSelector } from "react-redux"
import { WhitelistCollection } from "models/whitelist/collection"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import Button from "components/ui/button"
import { Hex2Rgba } from "utils/helpers"
import { color } from "styles/theme"
import { updateWalletOfWhitelistEntry } from "services/api/whitelist"
import toast from "react-hot-toast"
import { showWhitelistWalletSelectPopup } from "services/slices/popup"
import { setEntry } from "services/slices/whitelist"
import { displaySettings, displayWalletConnectPopup } from "utils/parts"

const WhitelistWalletSelect = () => {

    const item: WhitelistCollection = useSelector((state: any) => state.whitelist.currentWhitelist)
    const wldata: any = useSelector((state: any) => state.whitelist)
    const user = useSelector((state: any) => state.user.user)
    const [selectedWallet, setSelectedWallet] = useState<any>(null)
    const [saving, setSaving] = useState<boolean>(false)
    const dispatch = useDispatch()

    const isActive = (wallet: any, i: number) => {
        if (selectedWallet === wallet) return true
        if (selectedWallet !== null) return false
        if (wldata.entry.wallet === "" && i === 0) return true
        if (wldata.entry.wallet === wallet) return true
        return false
    }

    const save = () => {
        if (saving) return
        setSaving(true)

        let wallet = selectedWallet
        if (wallet === null) {
            if (user.wallets.length > 0) {
                wallet = user.wallets[0].address
            } else {
                toast.error("No wallet found")
                setSaving(false)
                return
            }
        }

        updateWalletOfWhitelistEntry(item.id, wallet).then((res) => {
            setSaving(false)
            toast.success("Wallet updated")
            dispatch(setEntry(res.data.entry))
            dispatch(showWhitelistWalletSelectPopup(false))
        }).catch((err) => {
            setSaving(false)
            toast.error("Error updating wallet")
        })

    }

    return (
        <C.WalletSelect>

            <C.Title>
                Select Wallet to Whitelist
            </C.Title>

            <C.Wallets>
                {user.wallets.map((w: any, index: number) => {
                    if (w.network === item.network) {
                        return (
                            <C.Wallet onClick={() => setSelectedWallet(w.address)} active={isActive(w.address, index) ? "true" : "false"} key={index}>
                                <C.WalletHeader>
                                    <C.WalletName>
                                        {w.name}
                                    </C.WalletName>
                                    <C.WalletAddress>{w.address}</C.WalletAddress>
                                </C.WalletHeader>
                                <C.WalletSelectIcon>
                                    {isActive(w.address, index) && <FontAwesomeIcon icon={faCheck} />}
                                </C.WalletSelectIcon>
                            </C.Wallet>
                        )
                    }
                })}

                {user.wallets.filter((w: any) => w.network === item.network).length === 0 && (
                    <>
                        <C.NoWallets>
                            <C.NoWalletsText>
                                No compatible wallets found for this network
                            </C.NoWalletsText>
                            <Button block="true" bg={Hex2Rgba(color.secondaryFade, .5)} color="white" theme="gradient" onClick={() => displayWalletConnectPopup(true)}>Connect Wallet</Button>
                        </C.NoWallets>

                    </>
                )}
            </C.Wallets>

            {user.wallets.filter((w: any) => w.network === item.network).length > 0 && (
                <Button onClick={save} loading={saving ? "true" : "false"} disabled={saving} theme="gradient-border-transparent-bg-on-hover" bg={Hex2Rgba(color.secondaryFade, .5)} color="white">Save</Button>
            )}

        </C.WalletSelect>
    )
}

export default WhitelistWalletSelect