import React, { useState, useEffect } from "react"
import * as C from './style'
import { useDispatch, useSelector } from "react-redux"
import { ClaimTickets, getAllRaffles, getMultipleTickets, WinnerClaimAll } from "modules/solana/shuffle/raffle"
import { Hex2Rgba, shortenPublicKey } from "utils/helpers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretRight, faChevronRight, faCoins, faInfo, faInfoCircle, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { toast } from "react-hot-toast"
import { showShuffleHistoryPopup, showShuffleMyTicketsPopup } from "services/slices/popup"
import Button from "components/ui/button"
import { color } from "styles/theme"

import { setShuffleViewingWinner, setWidgetRedirect } from "services/slices/shuffle"
import { ParseAwardAddress, ParseCollectionAddress } from "modules/solana/shuffle/helpers"
import { LAMPORTS_PER_SOL } from "@solana/web3.js"
import { getAllNftsByMintList } from "modules/solana/nft"
import axios from "axios"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import { ShowGlobalConfetti } from "services/managers/calls"
import { getUsersAvatar } from "services/api/user"
import env from "env"
import { useLightbox } from "hooks/lightbox"
import ShuffleTokenTicketsLB from "../shuffleTokenTicketsLB"

const ShuffleWinner = (props: any) => {

    const dispatch = useDispatch()
    const [prizes, setPrizes] = useState<any>([])
    const [purchasePDA, setPurchasePDA] = useState<any>(null)
    const programId = useSelector((state: any) => state.shuffle.programId)
    const selectedWallet = useSelector((state: any) => state.user.selectedWallet)
    const treasury = useSelector((state: any) => state.shuffle.treasury)
    const vault = useSelector((state: any) => state.shuffle.vault)
    const [pageLoading, setPageLoading] = useState<boolean>(true)
    const [showConfetti, setShowConfetti] = useState<boolean>(false)
    const [shareText, setShareText] = useState<string>("")
    const [ticketSaleCount, setTicketSaleCount] = useState<number>(0)
    const [count, setCount] = useState<number>(0)
    const [userAddress, setUserAddress] = useState<any>(null)
    const [claimAllLoading, setClaimAllLoading] = useState<boolean>(false)
    const [actionHappening, setActionHappening] = useState<boolean>(false)

    const participantMapping = useSelector((state: any) => state.shuffle.participantMapping)

    useEffect(() => {
        if (selectedWallet === props.address)
            ShowGlobalConfetti(3800)

        if (userAddress !== null && userAddress !== selectedWallet) {
            dispatch(setShuffleViewingWinner(false))
            dispatch(showShuffleHistoryPopup(false))
            dispatch(showShuffleMyTicketsPopup(false))
        }

        setUserAddress(selectedWallet)

        parseAwards()

        return () => {
            dispatch(setShuffleViewingWinner(false))
        }
    }, [selectedWallet])

    const parseAwards = async () => {

        let winner = props.raffle.purchasePDAs.find((pda: any) => pda.account.buyer.toBase58() === props.address)
        setPurchasePDA(winner.publicKey.toBase58())

        let winningTickets = props.raffle.account.winningTickets.filter((w: any) => winner.account.sales.includes(w))


        let _prizes: any = []
        let _shareText = "I won"
        let _count = 0

        getMultipleTickets(programId, props.raffle.publicKey.toBase58(), winningTickets).then(async (res: any) => {

            for (let i = 0; i < res.length; i++) {

                const win = res[i];

                let target = props.raffle.account.inventory[win.target]
                let parsed: any = ParseAwardAddress(target.address.toBase58())

                let itemType = target.itemType

                if (typeof itemType.token !== "undefined") {
                    parsed.type = "token"
                } else if (typeof itemType.nft !== "undefined") {
                    parsed.type = "nft"
                } else {
                    parsed.type = "unknown"
                }

                if (parsed.type === "token") {
                    let prize = _prizes.find((p: any) => p.mint === win.mint.toBase58())
                    if (typeof prize !== "undefined") {
                        prize.amount += target.count[1].toNumber() / LAMPORTS_PER_SOL
                        if (typeof prize.count[target.count[1].toNumber()] !== "undefined") {
                            prize.count[target.count[1].toNumber()] = prize.count[target.count[1].toNumber()] + 1
                        } else {
                            prize.count[target.count[1].toNumber()] = 1
                        }

                        prize.tickets.push({
                            ticket: win.ticket,
                            claimed: win.claimed,
                            amount: target.count[1].toNumber()
                        })
                    } else {
                        _prizes.push({
                            ...parsed,
                            address: target.address.toBase58(),
                            mint: win.mint.toBase58(),
                            amount: target.count[1].toNumber() / LAMPORTS_PER_SOL,
                            count: {
                                [target.count[1].toNumber()]: 1
                            },
                            loading: false,
                            tickets: [
                                {
                                    ticket: win.ticket,
                                    amount: target.count[1].toNumber(),
                                    claimed: win.claimed
                                }
                            ]
                        })
                    }
                } else {
                    let topush = {
                        ...parsed,
                        address: target.address.toBase58(),
                        mint: win.mint.toBase58(),
                        amount: target.count[1].toNumber() / LAMPORTS_PER_SOL,
                        claimed: win.claimed,
                        loading: false,
                        ticket: win.ticket
                    }
                    let check = ParseCollectionAddress(target.address.toBase58())
                    if (check !== null){
                        topush.image = check.image
                        topush.name = check.name
                        topush.symbol = check.symbol
                    }
                    _prizes.push(topush)
                }
                _count += 1;

            }

            let mints = _prizes.filter((p: any) => p.type === "nft").map((p: any) => p.mint)
            let nfts: any = await getAllNftsByMintList(mints)

            for (let i = 0; i < nfts.length; i++) {
                for (let j = 0; j < _prizes.length; j++) {
                    if (_prizes[j].mint === nfts[i].mintAddress.toBase58() && (_prizes[j].name === undefined || _prizes[j].name === null || _prizes[j].name === "")) {
                        try {
                            let metadata = await axios.get(nfts[i].uri)
                            _prizes[j].name = metadata.data.name
                            _prizes[j].symbol = metadata.data.name
                            _prizes[j].image = metadata.data.image
                        } catch (e) {
                            _prizes[j].name = "Unknown"
                            _prizes[j].symbol = "Unknown"
                            _prizes[j].image = ""
                        }
                    }
                }
            }

            for (let i = 0; i < _prizes.length; i++) {
                if (i === 0) {
                    if (_prizes[i].type === "token") {
                        _shareText += " " + _prizes[i].amount.toFixed(2) + " " + _prizes[i].symbol
                    } else if (_prizes[i].type === "nft") {
                        _shareText += " " + _prizes[i].name.replace("#", "")
                    }
                } else {
                    if (_prizes[i].type === "token") {
                        _shareText += ", " + _prizes[i].amount.toFixed(2) + " " + _prizes[i].symbol
                    } else if (_prizes[i].type === "nft") {
                        _shareText += ", " + _prizes[i].name.replace("#", "")
                    }
                }
            }

            _shareText += " in the @hub3ee shuffle!"

            let _ticketSaleCount = winner.account.sales.length;
            setTicketSaleCount(_ticketSaleCount)

            //console.log(_prizes)

            setPrizes([..._prizes])
            setCount(_count)
            setPageLoading(false)
            setShareText(_shareText)



        }).catch((err: any) => {
            console.log(err)
            toast.error("Error loading winner prizes")
        })

    }

    const claim = async (prize: any) => {

        if (prize.loading) return;
        if (isPrizeClaimed(prize)) return;

        prize.loading = true
        setPrizes([...prizes])
        setActionHappening(true)

        let tickets = []

        if (prize.type === "token") {

            tickets = prize.tickets.filter((t: any) => !t.claimed).map((t: any) => t.ticket)

            if (prize.address === "So11111111111111111111111111111111111111111")
                tickets = tickets.map((t: any) => {
                    return {
                        ticket: t,
                        type: "native"
                    }
                })
            else
                tickets = tickets.map((t: any) => {
                    return {
                        ticket: t,
                        mint: prize.mint,
                        type: "token"
                    }
                })

        } else if (prize.type === "nft") {

            tickets = [
                {
                    ticket: prize.ticket,
                    mint: prize.mint,
                    type: "nft"
                }
            ]

        }

        ClaimTickets(programId, vault, props.raffle.account.treasury.toBase58(), props.raffle.publicKey.toBase58(), tickets, purchasePDA).then((res: any) => {
            
            prize.loading = false
            setPrizes([...prizes])
            setPageLoading(true)
            parseAwards()

        }).catch((err: any) => {
            toast.error(err.toString())
            prize.loading = false
            setPrizes([...prizes])
            setPageLoading(true)
            parseAwards()
        }).finally(() => {
            setActionHappening(false)
        })
    }

    const claimAll = async () => {

        if (actionHappening) {
            toast.error("Please wait for the current action to complete")
            return;
        }
        if (claimAllLoading) return;

        setClaimAllLoading(true)

        let nativeTickets = prizes.filter((p: any) => p.type === "native" && !isPrizeClaimed(p)).map((p: any) => {
            return p.tickets.filter((t: any) => !t.claimed).map((t: any) => {
                return {
                    ticket: t.ticket,
                    type: "native"
                }
            })
        })

        let tokenTickets = prizes.filter((p: any) => p.type === "token" && !isPrizeClaimed(p)).map((p: any) => {
            return p.tickets.filter((t: any) => !t.claimed).map((t: any) => {
                return {
                    ticket: t.ticket,
                    mint: p.mint,
                    type: "token"
                }
            })
        })

        let nftTickets = prizes.filter((p: any) => p.type === "nft" && !isPrizeClaimed(p)).map((p: any) => {
            return p.tickets.filter((t: any) => !t.claimed).map((t: any) => {
                return {
                    ticket: t.ticket,
                    mint: p.mint,
                    type: "nft"
                }
            })
        })

        let tickets = [...nativeTickets, ...tokenTickets, ...nftTickets]

        ClaimTickets(programId, vault, props.raffle.account.treasury.toBase58(), props.raffle.publicKey.toBase58(), tickets, purchasePDA).then((res: any) => {
            setPageLoading(true)
            parseAwards()

        }).catch((err: any) => {
            toast.error(err)
            setPageLoading(true)
            parseAwards()
        }).finally(() => {
            setClaimAllLoading(false)
            setActionHappening(false)
        })


    }

    const isPrizeClaimed = (prize: any) => {

        if (prize.type === "token") {
            let claimed = true
            for (let i = 0; i < prize.tickets.length; i++) {
                if (!prize.tickets[i].claimed) {
                    claimed = false
                }
            }
            return claimed
        } else {
            return prize.claimed
        }

    }

    const claimedAmount = (prize: any) => {

        if (prize.type === "token") {
            let claimed = 0
            for (let i = 0; i < prize.tickets.length; i++) {
                if (prize.tickets[i].claimed) {
                    claimed += prize.tickets[i].amount
                }
            }
            return claimed / LAMPORTS_PER_SOL
        } else {
            return prize.amount
        }

    }

    const lightBox = useLightbox()

    const showPrizeInfo = (prize: any, symbol:any) => {
        lightBox(<ShuffleTokenTicketsLB prize={prize} symbol={symbol} />, "Prize Info")
    }

    return (
        <C.ShuffleWinner>

            {props.close && (
                <C.Header>
                    <Button theme="gradient-border-transparent-bg-on-hover" bg={Hex2Rgba(color.white, .05)} color="white" onClick={props.close}>Back</Button>
                    <C.Title>
                        Viewing Winner
                    </C.Title>
                </C.Header>
            )}



            {selectedWallet === props.address && (
                <C.WinningHeader>
                    <C.WinningTitle>
                        Shuffle Winner
                    </C.WinningTitle>
                    <C.WinningText>
                        You won {count} prizes! with {ticketSaleCount} tickets. you can claim each<br />by clicking the claim button.
                    </C.WinningText>
                    <C.Share>
                        <C.ShareButton onClick={() => window.open("https://twitter.com/intent/tweet?text=" + shareText, "_blank")}>
                            <FontAwesomeIcon icon={faTwitter} /> Share
                        </C.ShareButton>
            </C.Share>
                </C.WinningHeader>
            )}

            {selectedWallet !== props.address && (
                <C.WinnerProfile>
                    <C.WinnerProfileImage>
                        {!participantMapping[props.address] && (
                            <img src="/images/etc/user.webp" alt="" />
                        )}

                        {participantMapping[props.address] && (
                            <img src={`${env.API.URL}/user/profile/avatar-of/${participantMapping[props.address].id}`} alt="" onError={(e: any) => e.target.src = "/images/etc/user.webp"} />
                        )}
                    </C.WinnerProfileImage>
                    <C.WinnerProfileText>
                        {!participantMapping[props.address] && (
                            <C.WinnerProfileName>
                                {shortenPublicKey(props.address)}
                            </C.WinnerProfileName>
                        )}
                        {participantMapping[props.address] && (
                            <C.WinnerProfileNameLink to={`/app/profile/${participantMapping[props.address].id}`}>
                                {participantMapping[props.address].username} <span>{shortenPublicKey(props.address)}</span>
                            </C.WinnerProfileNameLink>
                        )}
                        <C.WinnerProfileTicketCount>
                            Won {count} prizes with {ticketSaleCount} tickets
                        </C.WinnerProfileTicketCount>
                    </C.WinnerProfileText>
                </C.WinnerProfile>
            )}


            <C.Prizes>
                {prizes.map((p: any, i: number) => (
                    <C.Prize key={i} claimed={selectedWallet === props.address ? (p.claimed ? "true" : "false") : "false"}>
                        <C.PrizeImage>
                            <img src={p.image ? p.image : "/images/etc/ghost.png"} alt={p.name} onError={(e: any) => { e.target.onerror = null; e.target.src = "/images/etc/ghost.png" }} />
                        </C.PrizeImage>
                        <C.PrizeInfo>
                            <C.PrizeTitle>
                                {p.type === "token" && (
                                    <>
                                        {p.amount.toFixed(2)} {p.symbol}
                                    </>
                                )}
                                {p.type === "nft" && (
                                    <>
                                        {p.name}
                                    </>
                                )}
                            </C.PrizeTitle>
                            <C.PrizeText>
                                {p.type === "token" && (
                                    <C.PrizeCountInfoView onClick={() => showPrizeInfo(p.tickets, p.symbol)}>
                                        <FontAwesomeIcon icon={faInfoCircle} />
                                    </C.PrizeCountInfoView>
                                )}
                                {p.type === "nft" && (
                                    <C.TicketNumber>
                                        Ticket #{p.ticket}
                                    </C.TicketNumber>
                                )}
                            </C.PrizeText>
                        </C.PrizeInfo>
                        <C.PrizeClaim>
                            {selectedWallet === props.address && (
                                <>
                                    <Button theme="gradient" loading={p.loading ? "true" : "false"} disabled={p.loading || isPrizeClaimed(p)} block="true" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)} onClick={() => claim(p)}>
                                        {isPrizeClaimed(p) ? "Claimed" : "Claim"}
                                    </Button>
                                    {(p.type === "token" && claimedAmount(p) > 0) && (
                                        <C.PrizeClaimedAmount>
                                            {claimedAmount(p).toFixed(2)} {p.symbol} claimed
                                        </C.PrizeClaimedAmount>
                                    )}
                                </>
                            )}
                        </C.PrizeClaim>
                    </C.Prize>
                ))}
            </C.Prizes>

            {/*selectedWallet === props.address && (
                <C.ClaimAll>
                    <Button theme="gradient-border-transparent-bg-on-hover" block="true" bg={Hex2Rgba(color.secondaryFade, .5)} color="white" onClick={() => window.open("https://twitter.com/intent/tweet?text=" + shareText, "_blank")}>Share on Twitter</Button>
                    <Button theme="gradient" block="true" bg={Hex2Rgba(color.secondaryFade, .5)} color="white" onClick={claimAll} loading={claimAllLoading ? "true" : "false"} disabled={claimAllLoading}>Claim All</Button>
                </C.ClaimAll>
            )*/}

        </C.ShuffleWinner>
    )

}

export default ShuffleWinner