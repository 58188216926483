import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ToGeoJson } from 'utils/helpers'

export interface HoldersState {
    currentViewingWhitelist: string | null
    currentViewingLaunch: string | null
    currentViewingCollection: string | null //id of the collection being viewed on map
    currentViewingCollectionCode: string | null //url param of the collection being viewed on map
    currentViewingToken: string | null //id of the token being viewed on map
    currentViewingTokenCode: string | null //url param of the token being viewed on map
    holders: any // Array of holders
    filters: {
        type: string,
        chain: string,
        markerType: string,
        badges: string[]
    },
    search: string | null,
    onlyWhitelisted: boolean,
    count: number,
}

const initialState: HoldersState = {
    holders: ToGeoJson([]),
    currentViewingCollection: null,
    currentViewingLaunch: null,
    currentViewingWhitelist: null,
    currentViewingCollectionCode: null,
    currentViewingToken: null,
    currentViewingTokenCode: null,
    filters: {
        type: "all",
        chain: "all",
        markerType: "all",
        badges: []
    },
    search: null,
    onlyWhitelisted: false,
    count: 0
}

export const holdersSlice = createSlice({
    name: 'holders',
    initialState,
    reducers: {
        setHolders: (state, action: PayloadAction<any>) => {
            state.holders = action.payload

            try {
                state.count = action.payload.features.length
            } catch (e) {
                state.count = 0
            }
        },
        setCurrentViewingCollection: (state, action: PayloadAction<string | null>) => {
            state.currentViewingCollection = action.payload
            if (action.payload !== null){
                state.currentViewingLaunch = null
                state.currentViewingToken = null
                state.currentViewingWhitelist = null
            }
        },
        setCurrentViewingToken: (state, action: PayloadAction<string | null>) => {
            state.currentViewingToken = action.payload
            if (action.payload !== null){
                state.currentViewingCollection = null
                state.currentViewingLaunch = null
                state.currentViewingWhitelist = null
            }
        },
        setCurrentViewingWhitelist: (state, action: PayloadAction<string | null>) => {
            state.currentViewingWhitelist = action.payload
            if (action.payload !== null){
                state.currentViewingCollection = null
                state.currentViewingLaunch = null
                state.currentViewingToken = null
            }
        },
        setCurrentViewingLaunch: (state, action: PayloadAction<string | null>) => {
            state.currentViewingLaunch = action.payload
            if (action.payload !== null){
                state.currentViewingCollection = null
                state.currentViewingWhitelist = null
                state.currentViewingToken = null
            }
        },
        setHoldersFilters: (state, action: PayloadAction<any>) => {
            state.filters = action.payload
        },
        setHoldersFilterType: (state, action: PayloadAction<string>) => {
            state.filters.type = action.payload
        },
        setHoldersFilterChain: (state, action: PayloadAction<string>) => {
            state.filters.chain = action.payload
        },
        setHoldersFilterBadges: (state, action: PayloadAction<string[]>) => {
            state.filters.badges = action.payload
        },
        addHoldersFilterBadges: (state, action: PayloadAction<string>) => {
            state.filters.badges.push(action.payload)
        },
        removeHoldersFilterBadges: (state, action: PayloadAction<string>) => {
            state.filters.badges = state.filters.badges.filter((badge: string) => badge !== action.payload)
        },
        resetFilters: (state) => {
            state.filters = initialState.filters
        },
        updateHolder: (state, action: PayloadAction<any>) => {
            let holder = state.holders.features.find((holder: any) => holder.properties.id === action.payload.id)

            if (holder) {

                holder.properties = {
                    ...holder.properties,
                    status: action.payload.status,
                    username: action.payload.username,
                    badges: action.payload.badges,
                    whitelisted: holder.properties.whitelisted || action.payload.whitelisted,
                }


                holder.geometry.coordinates = [action.payload.lng, action.payload.lat]
            }
        },
        addHolder: (state, action: PayloadAction<any>) => {
            state.holders.features.push(action.payload)
            state.count = state.holders.features.length
        },
        setHoldersSearch: (state, action: PayloadAction<string | null>) => {
            state.search = action.payload
        },
        setOnlyWhitelisted: (state, action: PayloadAction<boolean>) => {
            state.onlyWhitelisted = action.payload
        },
        setCurrentViewingCollectionCode: (state, action: PayloadAction<string | null>) => {
            state.currentViewingCollectionCode = action.payload
        },
        setCurrentViewingTokenCode: (state, action: PayloadAction<string | null>) => {
            state.currentViewingTokenCode = action.payload
        },
        setHoldersCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload
        }
    }
})

export const { setHolders, setCurrentViewingCollection, setCurrentViewingToken,  setCurrentViewingTokenCode, setCurrentViewingLaunch, setHoldersCount, setHoldersFilters, setCurrentViewingCollectionCode, setHoldersFilterType, setCurrentViewingWhitelist, setHoldersFilterChain, setHoldersFilterBadges, addHoldersFilterBadges, removeHoldersFilterBadges, resetFilters, updateHolder, addHolder, setHoldersSearch, setOnlyWhitelisted } = holdersSlice.actions

export default holdersSlice.reducer