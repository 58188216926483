import { Button } from "components/ui/button/style"
import styled from "styled-components"
import { Col, Row } from "styles"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"

export const Guide = styled.div`

`

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const StepsDots = styled.div`
    display: flex;
    
`

export const StepDot = styled.div<{ active: string }>`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${props => props.active === "true" ? color.white : Hex2Rgba(color.white, .32)};
    margin: 0 4px;
`

export const Close = styled.div`
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    font-size: 24px;
    line-height: 1;
`

export const SwitchGuide = styled.div`
    display: flex;
    background: ${Hex2Rgba(color.secondaryFade, .32)};
    border-radius: 24px;
    align-items: center;
    overflow: hidden;
`

export const SwitchGuideItem = styled.div<{ active: string }>`
    flex:1;
    padding: 8px 16px;
    cursor: pointer;
    text-align: center;

    ${props => props.active === "true" && `
        background: ${color.secondary};
        border-radius: 24px;
    `}

    ${props => props.active === "false" && `
        color: ${Hex2Rgba(color.white, .32)};
    `}

    &:hover{
        color: ${color.white};
    }

    transition: all .1s ease-in-out;
`

export const Content = styled.div`
    margin-top: 16px;
`

export const ContentImage = styled.div`
    width: 100%;

    & img{
        width: 100%;
    }
`

export const ContentTitle = styled.div`
    margin:8px 0px;
    font-size: 18px;

    & span{
        color: ${color.tertiary};
    }
`

export const ContentText = styled.div`
    font-size: 14px;
    color:${color.primary};
    & span{
        color:${color.white};
    }

    & span.t{
        color:${color.tertiary};
    }
`

export const Actions = styled.div`
    margin-top: 16px;

    & button:not(:last-child){
        margin-right: 8px;
    }

    & button{
        padding: 8px 24px;
    }

    display: flex;
    justify-content: space-between;

`