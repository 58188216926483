import { WalletProviderDetail } from "models/interfaces/walletProviderDetails"

export abstract class WalletProvider {

    /**
     * The provider of the wallet
     */
    static provider: WalletProviderDetail

    /**
     * Is wallet installed
     * @returns true if installed
     * @returns false if not installed
    */
    static isInstalled(): boolean { return false }

    /**
     * Get provider of wallet from window
     * @returns provider object
    **/
    abstract getProvider(): any

    /**
     * Is wallet connected
     * @returns true if connected
     * @returns false if not connected or not installed
    */
     static isConnected(): boolean | Promise<boolean> { return false }

    /**
     * Connect wallet
     * @returns true when connected
     * @returns false when not connected or not installed
    */
    abstract connect(): Promise<boolean>

    tryConnect?(): Promise<boolean>

    /**
     * Disconnect wallet
     * @returns true when disconnected
     * @returns false when not disconnected or not installed
    */
    abstract disconnect(): Promise<boolean>

    /**
     * Set callback for when wallet is disconnected
     * @param callback function to call when wallet is disconnected
    */
    abstract setOnDisconnect(callback: () => void): void

    /**
     * Get public key of wallet
     */
    abstract address(): Promise<string[]>

    /**
     * Sign message
     * @param message message to sign
     * @returns signature of message
     * @returns false if not signed or not installed or not connected 
     */
    abstract signMessage(message: string, nonce : string): Promise<string>

    /**
     * Redirect to install page
    */
    abstract install(): void

    abstract signTransaction(transaction: any): Promise<any>

    abstract signAllTransactions(transactions: any[]): Promise<any[]>
}