import styled, { keyframes } from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { deviceMax, deviceMin } from "styles/media"
import { Link } from "react-router-dom"

import shfflleft from "./assets/left.png"
import shfflright from "./assets/right.png"
import shfflsun from "./assets/sun.png"
import stars from "./assets/stars.png"


export const ShuffleBase = styled.div`

    background: url(${stars}) no-repeat bottom center fixed;
    height:100%;
    background-size: contain;

    @media ${deviceMax.md} {
        margin-bottom: 30px
    }
    padding-left: 40px;
    padding-right: 40px;
    padding-top:0px;

    @media ${deviceMax.md} {
        padding-left: 16px;
        padding-right: 16px;
    }

`

export const Loading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 24px;
` 

export const ShuffleHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1;

    @media ${deviceMax.md} {
        flex-direction: column-reverse;
        padding-top: 16px;
    }
`

export const ShuffleHeaderLeft = styled.div`
    display: flex;
    align-items: center;

    @media ${deviceMax.md} {
        flex-direction: column;
    }
`

export const ShuffleGif = styled.div`

    width: 160px;
    height: 66px;
    position: relative;

    & img {
        width:160px;
    }

    @media ${deviceMax.md} {
        display: none;
    }

`

export const ShuffleHeaderLeftTitle = styled.div`
    background: linear-gradient(90deg, ${color.gradient1.start}, ${color.gradient1.middle}, ${color.gradient1.end});
    font-weight:500;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 24px;
`

export const ShuffleHeaderLeftActions = styled.div`
    display: flex;
    align-items: center;
    margin-left: 16px;

    @media ${deviceMax.md} {
        margin-left: 0px;
        text-align:center;
        & span{
            display:block;
            
        }
    }
`

export const ShuffleHeaderLeftAction = styled.div<{noHover?:string}>`
    
    display: flex;
    background: ${color.bg1};
    border-radius: 100px;
    padding: 8px 24px;
    color: ${color.whiteShade};

    &:not(:last-child) {
        margin-right: 16px;
    }

    ${props => props.noHover !== "true" && `
        cursor: pointer;
        &:hover {
            color: ${color.primary};
        }
    `}

    @media ${deviceMax.md} {
        padding: 8px 12px;
        font-size: 14px;
        &:not(:last-child) {
            margin-right:8px;
        }

        ${props => props.noHover === "true" && `
            display: none;
        `}
    }

    & span{
        background: linear-gradient(90deg, ${color.gradient3.start}, ${color.gradient3.end});
        font-weight:bold;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    font-size: 14px;

    & svg{
        width: 16px;
        margin-right: 8px;
        color: ${color.primary};
    }
`

export const ShuffleHeaderLeftActionLink = styled(Link)`
    cursor: pointer;
    background: ${Hex2Rgba(color.black, 0.64)};
    border-radius: 100px;
    padding: 4px 16px;
    text-decoration: none;
    color: ${color.white};

    &:not(:last-child) {
        margin-right: 16px;
    }

    &:hover {
        color: ${color.primary};
    }

    @media ${deviceMax.md} {
        padding: 4px 8px;
    }
`

export const ShuffleHeaderRight = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media ${deviceMax.md} {
        margin-bottom: 16px;
    }
`

export const WalletConnect = styled.div`
    margin-right: 4px;
`

export const ShuffleHeaderRightClose = styled.div`
    cursor: pointer;
    font-size: 24px;
    padding:0px 20px;
    padding-right:0px;
    width:38px;
    @media ${deviceMax.md} {
        display: none;
    }
`

export const ShuffleAnimation = styled.div`
    pointer-events: none;
    
`

export const ShuffleAnimationBase = styled.div`
    position: absolute;
    left: 105px;
    bottom: 0;
    width:30%;
    height:100%;

    & canvas {
        display: block;
        width: 100%;
        height: 100%;
    }

    @media ${deviceMax.md} {
        position: static;
        width:100%;
        display: flex;
        justify-content: center;
        height: 300px;
        & canvas {
            position: relative;
        }
        top: 0px;
        bottom: unset;
    }
`

export const ShuffleAnimationLeft = styled.div`
    position: absolute;
    display: flex;
    width:50%;
    height: 100%;
    bottom: 0;
    left: 0;
    background: url(${shfflleft}) no-repeat;
    background-size: contain;
    background-position: bottom left;
`

export const ShuffleAnimationRight = styled.div`
    position: absolute;
    display: flex;
    width:50%;
    height: 100%;
    bottom: 0;
    right: 0;
    background: url(${shfflright}) no-repeat;
    background-size: contain;
    background-position: bottom right;
`

export const ShuffleAnimationSun = styled.div`
    position: absolute;
    display: flex;
    width:450px;
    height: 450px;
    bottom: 35%;
    right: 30%;
    background: url(${shfflsun}) no-repeat;
    background-size: contain;
    background-position: bottom right;

    @media ${deviceMax.md} {
        display: none;
    }
`


export const ShuffleRoot = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;

    @media ${deviceMax.md} {
        justify-content: center;
    }
`



export const Tabs = styled.div`
    background: ${Hex2Rgba(color.secondary, 0.16)};
    padding: 16px;
    border-radius: 16px;
    backdrop-filter: blur(20px);
`

export const TabsHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${Hex2Rgba(color.secondary, 0.16)};
    border-radius: 100px;
    font-size: 16px;
    color: ${color.primary};
`

export const TabsHeaderItem = styled.div<{active: string}>`
    cursor: pointer;
    border-radius: 24px;
    position: relative;
    flex:1;
    text-align: center;

    ${props => props.active === "true" && `
        background: ${color.secondary};
        color: ${color.white};

        &::before {
            content: "";
            position: absolute;
            inset: 0;
            border-radius: 24px; 
            padding: 2px; 
            background:linear-gradient(45deg,${color.gradient2.start},${color.gradient2.middle},${color.gradient2.end}) !important; 
            -webkit-mask: 
            linear-gradient(#fff 0 0) content-box, 
            linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
                    mask-composite: exclude; 
        }
    `}

    &:hover {
        color: ${color.white};
    }

    padding: 8px 26px;

    @media ${deviceMax.md} {
        font-size: 14px;
        padding: 8px 12px;
    }
`

export const TabsContent = styled.div`

    margin-top: 16px;

`

export const TabsContentItem = styled.div`
    
`

export const ContentBox = styled.div`
    background: ${Hex2Rgba(color.secondary, 0.16)};
    padding: 16px;
    border-radius: 8px;

    &:not(:last-child) {
        margin-bottom: 16px;
    }
`

export const Title = styled.div`
    margin-bottom:16px;
    color: ${color.primary};
    font-weight: 500;
    font-size: 18px;
`

export const Awards = styled.div<{scroll?:string}>`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;

    ${props => props.scroll === "true" && `
        overflow-y: auto;
        max-height: 200px;

        &::-webkit-scrollbar {
            width: 4px;
        }
    
        &::-webkit-scrollbar-track {
            background: transparent;
        }
    
        ::-webkit-scrollbar-thumb {
            background: transparent;
            border-radius:10px;
        }
    
        &:hover::-webkit-scrollbar-thumb {
            background: #888;
        }
    `}
`

export const Note = styled.div`
    & span {
        color: ${color.primary};
    }
    font-size: 12px;
    color: ${color.whiteShade};

    & ${ContentBox} {
        padding: 8px;
        margin-bottom: 16px;
    }
`

export const NoteContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: stretch;
`

export const NoteText = styled.div`
    text-align: center;
    flex:1;
`

export const NoteVol = styled.div`
    text-align: center;
    flex:1;

    & span{
        background: linear-gradient(90deg, ${color.gradient3.start}, ${color.gradient3.end});
        font-weight:bold;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`

export const NoteDivider = styled.div`
    width: 1px;
    background: ${Hex2Rgba(color.primary, 0.2)};
    
`

export const AwardAmount = styled.div`
    position: absolute;
    bottom:0px;
    right: 0px;
    background: ${Hex2Rgba(color.secondaryFade, 0.6)};
    border-top-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 6px 8px;
    font-size: 12px;
    backdrop-filter: blur(5px);
`

export const Award = styled.div<{expand?:string}>`
    & img {
        width: 100%;
        min-height:72px;

        @media ${deviceMax.md} {
            min-height: unset;
        }
    }
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.1s ease-in-out;
    ${props => props.expand === "true" && `
        cursor: pointer;
        &:hover {
            opacity: 0.75;
        }

        & ${AwardAmount} {
            /*color: ${color.primary};*/
        }
    `}
`



export const AwardMore = styled.div`
    background: ${Hex2Rgba(color.black, 0.8)};
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`

export const LiveActivityRoot = styled.div`
    & ${ContentBox} {
        padding: 0;
        padding-bottom: 16px;
    }

    & ${Title} {
        padding-left: 16px;
        padding-top: 16px;
        padding-right: 16px;
    }
`

export const LiveActivity = styled.div`
    max-height: 80px;
    overflow-y: auto;
    font-size: 14px;

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius:10px;
    }

    &:hover::-webkit-scrollbar-thumb {
        background: #888;
    }
`

export const LiveActivityItem = styled.div`
    display: flex;
    justify-content: space-between;
    padding:4px 16px;

    &:nth-child(odd) {
        background: ${Hex2Rgba(color.bg1, 0.64)};
    }
`

export const LiveActivityItemText = styled.div`
    display: flex;
    
`

export const LiveActivityItemTextLinkUser = styled(Link)`
    color: ${color.white};
    text-decoration: none;

    &:hover {
        color: ${color.primary};
    }
    margin-right: 5px;
`

export const LiveActivityItemTextUser = styled.div`
    margin-right: 5px;
`

export const LiveActivityItemTextAction = styled.div`
    margin-right: 5px;
    color:${color.whiteShade};
    font-size: 13px;
`

export const LiveActivityItemTextAmount = styled.div`
    color:${color.primary};
    font-size: 13px;
`

export const LiveActivityItemTime = styled.div`
    color:${color.whiteShade};
    font-size:12px;
`


export const TicketPurchaseLoading = styled.div`
    height:100%;
    background:${Hex2Rgba(color.black, 0.75)};
    position:absolute;
    z-index:2;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    font-size:28px;
    top:0;
    left:0;
`

export const TicketPurchase = styled.div`
    margin-top: 16px;
    background: ${Hex2Rgba(color.secondary, 0.16)};
    padding: 16px;
    border-radius: 16px;
    backdrop-filter: blur(20px);
    position: relative;
    overflow: hidden;
    border:2px solid ${color.secondary};
`

export const TicketPurchaseInfoText = styled.div`
    background: ${Hex2Rgba(color.secondary, 0.16)};
    border-radius: 24px;
    padding: 8px 16px;
    font-size:12px;
    margin-bottom:16px;

    & span {
        color: ${color.primary};
    }
`

export const TicketPurchaseInfo = styled.div`
    display:flex;
    justify-content:space-between;
    margin-bottom:16px;
`

export const TicketPurchaseInfoItemTitle = styled.div`
    font-size:12px;
    color:${color.whiteShade};
    margin-right:8px;
    @media ${deviceMax.md} {
        margin-right:0;
    }

`

export const TicketPurchaseInfoItemValue = styled.div`
    font-size:15px;
`
export const TicketPurchaseInfoItemPrice = styled.div`
    background: ${Hex2Rgba(color.secondary, 0.16)};
    border-radius: 8px;
    padding: 8px 12px;
    width: fit-content;
    display: flex;
    align-items: center;

    @media ${deviceMax.md} {
        flex-direction:column;
    }
`

export const TicketPurchaseInfoItem = styled.div<{shiny?:string, clickable?:string}>`
    flex:1;
    display:flex;
    align-items:center;
    justify-content:center;

    ${props => props.clickable === "true" && `
        cursor:pointer;
    `}

    ${props => props.shiny && `
        justify-content:flex-start;
        

        & ${TicketPurchaseInfoItemTitle} {
            color:${color.white};
            font-size:14px;
        }

        & ${TicketPurchaseInfoItemValue} {
            background: linear-gradient(90deg, ${color.gradient1.start}, ${color.gradient1.middle}, ${color.gradient1.end});
            font-weight:600;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    `}

    &:last-child {
        justify-content:flex-end;
    }

    @media ${deviceMax.md} {
        flex-direction:column;
    }
`


export const TicketPurchaseForm = styled.div`
    display:flex;

    & > *{
        flex:1;
        padding:2px 8px;
    }

    & > *:not(:last-child){
        margin-right:8px;
    }

    & button{
        font-size:16px;
        line-height:1.2;
    }

`

export const TicketDraw = styled.div`
    margin-top: 16px;
    background: ${Hex2Rgba(color.secondary, 0.16)};
    padding: 16px;
    border-radius: 16px;
    backdrop-filter: blur(20px);
    position: relative;
    overflow: hidden;
    display:flex;
    align-items:center;
    justify-content:center;
    border:2px solid ${color.secondary};

    @media ${deviceMax.md} {
        margin-top:0px;
        margin-bottom:16px;
    }
`

export const TicketDrawTitle = styled.div`
    font-size: 20px;
    font-weight: 600;
    margin-left: 16px;
    background: linear-gradient(90deg, ${color.gradient1.start}, ${color.gradient1.middle}, ${color.gradient1.end});
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    flex:2;
`


export const OGRocket = styled.div`
    display:flex;
    position:relative;
    align-items:center;
    justify-content:center;
    width:100%;
    flex:1.5;

    &:after{
        content:" ";
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
    }
    
`
export const OGRocketBg = styled.div`

    width:140px;

    & img{
        width:100%;
    }

    
`

const rocketAnimation = keyframes`
    /*animation transform translate circle around*/
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(0, 10px);
    }
    100% {
        transform: translate(0, 0);
    }
        
    
`

export const OGRocketImage = styled.div`

    top:-5px;
    width:66px;
    position:absolute;

    & img{
        width:100%;
        animation: ${rocketAnimation} 3s ease-in-out infinite;
        transition: all 0.3s ease-in-out;
    }

`

export const TicketCount = styled.div`
    display:flex;
    background:${Hex2Rgba(color.secondaryFade, .64)};
    border-radius:24px;
    align-items:center;
`

export const TicketCountButton = styled.div`
    cursor:pointer;
    height:100%;
    display:flex;
    align-items:center;
    font-size:18px;
    flex:1;
    justify-content:center;
    user-select:none;
`

export const TicketCountInputBox = styled.div`
    display:flex;
    flex:2;
`

export const TicketCountInput = styled.input`
    border:none;
    padding:12px 16px;
    color:${color.white};
    font-size:16px;
    font-weight:500;
    width:100%;
    background:transparent;
    text-align:center;
    outline:none;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    -moz-appearance: textfield;

`

export const TicketPrice = styled.div`
    font-size:13px;
`

export const AwardsTab = styled.div`
    ${ContentBox} {
        padding: 0;
        overflow: hidden;
    }

    ${Title} {
        padding-left: 16px;
        padding-top: 16px;
        padding-right: 16px;
    }

    ${Awards} {
        padding: 16px;
    }
`

export const SubTitle = styled.div`
    background: ${Hex2Rgba(color.secondary, 0.16)};
    padding: 8px 16px;
`

export const Tokens = styled.div`
    font-size: 14px;
`

export const TokenItem = styled.div`
    display: flex;
    justify-content: space-between;
    padding:6px 16px;

    &:nth-child(odd) {
        background: ${Hex2Rgba(color.bg1, 0.64)};
    }
`

export const TokenItemItemAmount = styled.div`
    & span {
        color: ${color.whiteShade};
        font-size: 12px;
    }
`

export const TokenItemCount = styled.div`

`

export const ParticipantsTab = styled.div`
    ${ContentBox} {
        padding: 0;
        padding-bottom: 16px;
    }

    ${Title} {
        padding-left: 16px;
        padding-top: 16px;
        padding-right: 16px;
    }
`

export const Participants = styled.div`

    max-height: 400px;
    overflow-y: auto;
    font-size: 14px;

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius:10px;
    }

    &:hover::-webkit-scrollbar-thumb {
        background: #888;
    }

`

export const ParticipantItem = styled.div`
    display: flex;
    justify-content: space-between;
    padding:6px 16px;

    &:nth-child(odd) {
        background: ${Hex2Rgba(color.bg1, 0.64)};
    }
`

export const ParticipantItemText = styled.div`

`

export const ParticipantItemTextLink = styled(Link)`
    color: ${color.white};
    text-decoration: none;

    &:hover {
        color: ${color.primary};
    }
`

export const ParticipantItemAmount = styled.div`

`

export const Winner = styled.div`
    margin-top: 16px;
    background: ${Hex2Rgba(color.secondary, 0.16)};
    padding: 16px;
    border-radius: 16px;
    position: relative;
    overflow-y: auto;
    max-height: 100%;
    border:2px solid ${color.secondary};
`

export const ShuffleEnd = styled.div`
    margin-top: 16px;
    background: ${Hex2Rgba(color.secondary, 0.16)};
    padding: 16px;
    border-radius: 16px;
    backdrop-filter: blur(20px);
    position: relative;
    overflow-y: auto;
    max-height: 100%;

    @media ${deviceMax.md} {
        margin-top:0px;
        margin-bottom:16px;
    }

`

export const ShuffleEndText = styled.div`
    & span {
       color: ${color.primary};
        
        font-size:18px;
    }
    text-align:center;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;

    & div{
        max-width: 90%;
        & a {
            color: ${color.primary};
            font-weight:500;
            text-decoration:underline;
        }
        color: ${color.whiteShade};
        font-size: 12px;

    }
`

export const NextShuffle = styled.div<{border?:string}>`
    margin-top: 16px;
    background: ${Hex2Rgba(color.secondary, 0.16)};
    padding: 16px;
    border-radius: 16px;
    position: relative;
    overflow-y: auto;
    max-height: 100%;
    display:flex;
    align-items:center;

    ${props => props.border === 'true' && `
        border:2px solid ${color.secondary};
    `}

    
`

export const NextShuffleImage = styled.div`
    flex:.7;
    & img {
        width: 100px;
    }
    display:flex;
    justify-content:center;
    align-items:center;

`

export const NextShuffleText = styled.div`
    flex:2;
    & span {
        background: linear-gradient(90deg, ${color.gradient1.start}, ${color.gradient1.middle}, ${color.gradient1.end});
        font-weight:500;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;  
        
        font-size:20px;
    }
    text-align:center;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;

    & div{
        max-width: 90%;
        & a {
            color: ${color.primary};
            font-weight:500;
            text-decoration:underline;
        }
        color: ${color.whiteShade};
        font-size: 12px;

    }


`

export const Shuffle = styled.div`
    width: 500px;
    margin-right:42px;
    display: flex;
    flex-direction: column;
    

    @media ${deviceMax.md} {
        width:100%;
        margin-right:0px;
        padding-bottom: 32px;
        flex-direction: column-reverse;

        ${TicketPurchase} {
            margin-bottom: 16px;
        }
    }
`

export const ShuffleTotalVolume = styled.div`
    margin-top: 16px;
    background: ${Hex2Rgba(color.secondary, 0.16)};
    padding: 16px;
    border-radius: 16px;
    backdrop-filter: blur(20px);
    position: relative;
    overflow: hidden;
    border:2px solid ${color.secondary};
    & span{
        background: linear-gradient(90deg, ${color.gradient3.start}, ${color.gradient3.end});
        font-weight:bold;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    text-align:center;

    font-size: 16px;    

`

export const ShuffleEndConnect = styled.div`
    margin-top: 16px;
    background: ${Hex2Rgba(color.secondary, 0.16)};
    padding: 16px;
    border-radius: 16px;
    position: relative;
    overflow-y: auto;
    max-height: 100%;
    display:flex;
    align-items:center;
    border:2px solid ${color.secondary};
`

export const ShuffleEndConnectImage = styled.div`
    flex:.7;
    & img {
        width: 100px;
    }
    display:flex;
    justify-content:center;
    align-items:center;
`

export const ShuffleEndConnectText = styled.div`
    flex:2;
    & span {
        background: linear-gradient(90deg, ${color.gradient1.start}, ${color.gradient1.middle}, ${color.gradient1.end});
        font-weight:500;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;  
        
        font-size:20px;
        margin-bottom: 8px;
    }
    text-align:center;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;

    & button{
        padding: 8px 36px;
    }
`