import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { Dialog, DialogHeader } from "../base/style"

export const Popup = styled.div`
    z-index: 1000;
    & ${Dialog} {
        width: 500px;
    }

    & ${DialogHeader} {
        display: none;
    }
`

export const Loading = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 24px;
`

export const LoadingText = styled.div``