import React from "react"
import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"

export const Input = styled.input<any>`
    width:100%;
    padding:8px 12px;
    border-radius: 10px;
    border: 1px solid ${Hex2Rgba(color.primary, .3)};
    background: transparent;
    color:${color.white};

    &:focus{
        outline:none;
        border: 1px solid ${color.primary};
    }

    &::placeholder{
        color:${Hex2Rgba(color.white, .5)};
    }
`


export const Textarea = styled.textarea<any>`
    width:100%;
    padding:8px 12px;
    border-radius: 10px;
    border: 1px solid ${Hex2Rgba(color.primary, .3)};
    resize:none;
    background: transparent;
    color:${color.white};

    &:focus{
        outline:none;
        border: 1px solid ${color.primary};
    }

    &::placeholder{
        color:${Hex2Rgba(color.white, .5)};
    }
`
