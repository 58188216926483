import React from "react"
import * as P from "./style"

import { useSelector } from "react-redux"
import BasePopup from "components/popups/base"
import { IconCollections } from "utils/icons"

import { showWhitelistWalletSelectPopup } from "services/slices/popup"
import WhitelistWalletSelect from "components/parts/whitelistWalletSelect"


const WhitelistWalletSelectPopup = () => {

    const show = useSelector((state: any) => state.popup.WhitelistWalletSelectPopup)
    
    return (
        <P.Popup>
            <BasePopup title="Select Wallet" show={show} hide={() => showWhitelistWalletSelectPopup(false)} icon={<IconCollections />} glasmorph={true}>
                <WhitelistWalletSelect/>
            </BasePopup>
        </P.Popup>
    )
}

export default WhitelistWalletSelectPopup