import React from "react"
import WalletConnectPopup from "./walletConnect"
import SettingsPopup from "./settings"
import EditProfilePopup from "./editProfile"
import NftViewPopup from "./nftView"
import MapFilterPopup from "./mapFilter"
import WelcomePopup from "./welcome"
import WhitelistNominatePopup from "./whitelistNominate"
import WhitelistWalletSelectPopup from "./whitelistWalletSelect"
import IntroductionPopup from "./introduction"
import GuidePopup from "./guide"
import FcfsRedeemPopup from "./fcfsRedeem"
import PlacePopup from "./place"
import LaunchWalletSelectPopup from "./launchWalletSelect"
import LaunchMintResultPopup from "./launchMintResult"
import Storypopup from "./story"
import Infopopup from "./info"
import ShuffleLiquidityDepositPopup from "./shuffle/shuffleLiquidityDeposit"
import ShuffleLiquidityTokenPopup from "./shuffle/shuffleLiquidityToken"
import ShuffleLiquidityTokenNft from "./shuffle/shuffleLiquidityNft"
import ShuffleHistoryPopup from "./shuffle/shuffleHistory"
import ShuffleMyTicketsPopup from "./shuffle/shuffleMyTickets"
import ShuffleHowToPlayPopup from "./shuffle/shuffleHowToPlay"
import ShuffleLedgerSetupPopup from "./shuffle/shuffleLedgerSetup"
import LoadingPopup from "./loading"
import ShuffleFairPopup from "./shuffle/shuffleFair"
import TreasurePopup from "./treasure"
import ShuffleLiquidityLocksPopup from "./shuffle/shuffleLiquidityLocks"
import ProfileMap from "./profileMap"
import LoginPopup from "./login"
import CloudWalletPopup from "./cloudWallet"
import AirdropPopup from "./airdrop"

const Popups = () => {
    return (
        <>
            
            <SettingsPopup />
            <EditProfilePopup />
            <NftViewPopup />
            <MapFilterPopup />
            <WelcomePopup />
            <WhitelistNominatePopup />
            <WhitelistWalletSelectPopup />
            <IntroductionPopup />
            <GuidePopup />
            <FcfsRedeemPopup />
            <PlacePopup />
            <LaunchWalletSelectPopup />
            <LaunchMintResultPopup/>
            <Storypopup/>
            <Infopopup/>
            <ShuffleLiquidityDepositPopup/>
            <ShuffleLiquidityTokenPopup/>
            <ShuffleLiquidityTokenNft/>
            <ShuffleHistoryPopup/>
            <ShuffleMyTicketsPopup/>
            <ShuffleHowToPlayPopup/>
            <ShuffleLedgerSetupPopup/>
            <LoadingPopup/>
            <ShuffleFairPopup/>
            <TreasurePopup/>
            <ShuffleLiquidityLocksPopup/>
            <ProfileMap/>
            <LoginPopup/>
            <CloudWalletPopup/>
            <WalletConnectPopup />
            <AirdropPopup/>
        </>
    )
}

export default Popups