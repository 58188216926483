import React from "react"
import * as C from "./style"
import CurrentToken from "components/parts/currentToken"
import { useSelector } from "react-redux"
import { MainParts } from "utils/parts"

const CurrentCollectionMobileWidget = () => {

    const currentPage = useSelector((state: any) => state.data.currentPage)
    const active = useSelector((state: any) => state.data.sidebarActive)
    const currentViewingToken = useSelector((state: any) => state.holders.currentViewingToken)

    return (
        <C.CurrentToken overlay={currentPage ? true : false} active={active} visible={currentViewingToken && currentPage === MainParts.Home ? true : false}>
            <C.CurrentTokenContent>
                <CurrentToken />
            </C.CurrentTokenContent>
            
        </C.CurrentToken>
    )
}

export default CurrentCollectionMobileWidget