import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"


export const Welcome = styled.div`
    & img {
        width:100%;
        border-radius:8px;
    }
`

export const Title = styled.div`
    font-size: 24px;
    text-align:center;
`

export const Text = styled.div`
    font-size: 16px;
    margin-top:8px;
    margin-bottom: 16px;
    color:${Hex2Rgba(color.gray, 1)};
    text-align:center;

    & span {
        color:${color.white}
    }
`

export const Actions = styled.div`
    display: flex;
    & button {
        flex: 1;
        &:not(:last-child) {
            margin-right: 10px;
        }
    }

`