import { Coin98 } from "./wallets/coin98"
import { Glow } from "./wallets/glow"
import { Phantom } from "./wallets/phantom"
import { Slope } from "./wallets/slope"
import { Backpack } from "./wallets/backpack"
import { Solflare } from "./wallets/solflare"
import { Wallet } from "@project-serum/anchor/dist/cjs/provider"
import { PublicKey } from "@solana/web3.js"
import { SOLANA_CONNECTION_SHARE } from "./connection"

export const SolanaWallets : any = {
    phantom: Phantom,
    backpack:Backpack,
    solflare: Solflare,
    /*glow: Glow,
    //slope: Slope,
    coin98: Coin98,*/
    
}

//sollet
//clover
//solong
//exodus

export const toAnchorWallet = (wallet: any) => {

    return {
        signTransaction: wallet.instance.signTransaction,
        signAllTransactions: wallet.instance.signAllTransactions,
        publicKey: new PublicKey(wallet.address[0])
    } as Wallet

}

export const anchorWallet = (wallet?: any) => {
    return {
        signTransaction: () => {},
        signAllTransactions: () => {},
        publicKey: wallet
    } as any
}

export const getWalletSolBalance = async (wallet: any) => {
    let balance = await SOLANA_CONNECTION_SHARE.getBalance(new PublicKey(wallet))

    return balance
}