import styled, { keyframes } from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { deviceMax, deviceMin } from "styles/media"
import { Link } from "react-router-dom"

export const ShufflePrizes = styled.div`

`

export const Prize = styled.div`
    display: flex;
    justify-content: space-between;

    &:not(:last-child){
        margin-bottom: 16px;
    }
`

export const PrizeAmount = styled.div`
    font-size: 14px;
    & span{
        color: ${color.whiteShade};
    }
`

export const PrizeTotal = styled.div`
    font-size: 14px;
    color: ${color.primary};

    & span{
        color: ${color.whiteShade};
        font-size: 12px;
    }
`