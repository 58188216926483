import React, { useState, useEffect } from "react"
import * as C from './style'
import { useDispatch, useSelector } from "react-redux"
import { getAllRaffles } from "modules/solana/shuffle/raffle"
import { Hex2Rgba, shortenPublicKey } from "utils/helpers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretRight, faChevronRight, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { toast } from "react-hot-toast"
import { showShuffleHistoryPopup } from "services/slices/popup"
import ShuffleWinners from "../shuffleWinners"
import Button from "components/ui/button"
import { color } from "styles/theme"
import ShuffleWinner from "../shuffleWinner"
import { setWidgetRedirect } from "services/slices/shuffle"
import moment from "moment"

const ShuffleMyTickets = () => {

    const programId = useSelector((state: any) => state.shuffle.programId)
    const [raffles, setRaffles] = useState([])
    const [loading, setLoading] = useState(true)
    const [viewType, setViewType] = useState("history")
    const [viewingRaffle, setViewingRaffle] = useState<any>(null)
    const selectedWallet = useSelector((state: any) => state.user.selectedWallet)
    const widgetRedirect = useSelector((state: any) => state.shuffle.widgetRedirect)
    const currentRaffle = useSelector((state: any) => state.shuffle.currentRaffle)

    const dispatch = useDispatch()

    useEffect(() => {

        getAllRaffles(programId).then((raffles) => {

            let rafflesOrdered = raffles.sort((a: any, b: any) => {
                //order by time(unix timestamp)
                return b.account.startDate.toNumber() - a.account.startDate.toNumber()
            })

            rafflesOrdered = rafflesOrdered.filter((raffle: any) => {
                let bought = false

                if (selectedWallet) {
                    let pda = raffle.purchasePDAs.find((pda: any) => pda.account.buyer.toBase58() === selectedWallet)
                    if (pda)
                        bought = true
                }

                return bought
            })


            rafflesOrdered = rafflesOrdered.map((raffle: any) => {

                let myTicketSales = 0
                let myTickets: number[] = []
                let myWinningTickets = 0

                if (selectedWallet) {
                    let pda = raffle.purchasePDAs.find((pda: any) => pda.account.buyer.toBase58() === selectedWallet)
                    if (pda) {
                        if (pda.account.buyer.toBase58() === selectedWallet) {
                            myTicketSales = pda.account.sales.length
                            myTickets = pda.account.sales
                        }
                    }

                    raffle.account.winningTickets.forEach((winner: any) => {
                        //if raffle.account.winning_tickets contains myTickets
                        if (myTickets.includes(winner)) {
                            myWinningTickets++
                        }
                    })
                }

                return {
                    ...raffle,
                    parsed: {
                        status: getStatus(raffle.account.status),
                        myTicketSales,
                        myWinningTickets
                    }
                }
            })

            setRaffles(rafflesOrdered)

            //console.log(rafflesOrdered)
            //console.log(widgetRedirect)
            //console.log(currentRaffle)

            if (widgetRedirect) {
                let _raffle = rafflesOrdered.find((raffle: any) => raffle.publicKey.toString() === currentRaffle)
                setViewingRaffle(_raffle)
                setViewType("raffle")
            }

        }).catch((err) => {
            console.log(err)
            toast.error("Failed to fetch history")
            dispatch(showShuffleHistoryPopup(false))
        }).finally(() => {
            setLoading(false)
        })



        return () => {
            dispatch(setWidgetRedirect(false))
        }

    }, [])

    const viewRaffle = (raffle: any) => {

        if (raffle.parsed.status[0] !== "ended" || raffle.parsed.myWinningTickets === 0) {
            return
        }

        setViewType("raffle")
        setViewingRaffle(raffle)
    }

    const getStatus = (status: any) => {
        if (typeof status.active !== "undefined") return ["active", "Active"]
        if (typeof status.drawed !== "undefined") return ["drawed", "Shuffling"]
        if (typeof status.ended !== "undefined") return ["ended", "Ended"]

        return ["unknown", "Unknown"]
    }

    return (
        <C.ShuffleMyTickets>

            {loading && <C.Loading>
                <FontAwesomeIcon icon={faSpinner} spin />
            </C.Loading>}

            {!loading && (
                <>

                    {viewType === "history" && (
                        <>
                            {raffles.map((raffle: any) => (
                                <C.Item key={raffle.publicKey.toString()} onClick={() => viewRaffle(raffle)}>
                                    <C.ItemLeft>
                                        <C.InnerLeft>
                                            <C.ItemDate>
                                                {moment(raffle.account.startDate.toNumber() * 1000).calendar()}
                                            </C.ItemDate>
                                            <C.ItemId title={raffle.publicKey.toString()}>
                                                {shortenPublicKey(raffle.publicKey.toString())}
                                            </C.ItemId>

                                            <C.ItemStatus status={raffle.parsed.status[0]}>
                                                {raffle.parsed.status[1]}
                                            </C.ItemStatus>
                                        </C.InnerLeft>


                                        <C.ItemTickets>
                                            <C.ItemTicketAmount>
                                                You have {raffle.parsed.myTicketSales} tickets
                                            </C.ItemTicketAmount>
                                            {raffle.parsed.status[0] === "ended" && (
                                                <C.ItemTicketWin>
                                                    You won {raffle.parsed.myWinningTickets} prizes
                                                </C.ItemTicketWin>
                                            )}
                                        </C.ItemTickets>

                                    </C.ItemLeft>
                                    <C.ItemRight>
                                        <FontAwesomeIcon icon={faChevronRight} />
                                    </C.ItemRight>
                                </C.Item>
                            ))}

                            {raffles.length === 0 && (
                                <C.NoTickets>
                                    You have no tickets
                                </C.NoTickets>
                            )}
                        </>
                    )}

                    {viewType === "raffle" && (
                        <>
                            <ShuffleWinner raffle={viewingRaffle} address={selectedWallet} close={() => setViewType("history")} />
                        </>
                    )}

                </>
            )}



        </C.ShuffleMyTickets>
    )
}

export default ShuffleMyTickets
