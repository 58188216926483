import { setRefreshLiquidations } from "services/slices/shuffle"
import { store } from "services/store"

export const RefreshLiquidations = () => {
    if (store.getState().shuffle.refreshLiquidations){
        store.dispatch(setRefreshLiquidations(false))
        store.dispatch(setRefreshLiquidations(true))
    } else {
        store.dispatch(setRefreshLiquidations(true))
    }
}