import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface PopupState {
    WalletConnectPopup: boolean
    LoginPopup: boolean
    SettingsPopup: boolean
    EditProfilePopup: boolean
    EditProfilePopupSharesFirst: boolean
    NftViewPopup: boolean
    MapFilterPopup: boolean
    WelcomePopup: boolean
    WhitelistNominatePopup : boolean
    WhitelistWalletSelectPopup : boolean
    IntroductionPopup : boolean,
    GuidePopup : boolean,
    FcfsPopup : boolean,
    PlacePopup: boolean,
    ConnectedWalletSelectPopup: boolean,
    LaunchMintResultPopup: boolean
    StoryPopup : boolean,
    InfoPopup : boolean,
    ShuffleLiquidityDepositPopup : boolean,
    ShuffleLiquidityTokenPopup : boolean,
    ShuffleLiquidityNftPopup : boolean,
    ShuffleMyTicketsPopup : boolean,
    ShuffleHistoryPopup : boolean,
    LoadingPopup: boolean,
    ShuffleHowToPlayPopup : boolean,
    ShuffleLedgerSetupPopup : boolean,
    ShuffleFairPopup : boolean,
    TreasurePopup : boolean,
    ProfileMapPopup : boolean,
    ShuffleLiquidityLocksPopup : boolean,
    CloudWalletPopup : boolean,
    AirdropPopup : boolean,
    Anyofthem: boolean // Is any of the popups open?
}

const initialState: PopupState = {
    WalletConnectPopup: false,
    LoginPopup: false,
    SettingsPopup: false,
    EditProfilePopup: false,
    EditProfilePopupSharesFirst: false,
    NftViewPopup: false,
    MapFilterPopup: false,
    WelcomePopup: false,
    WhitelistNominatePopup : false,
    WhitelistWalletSelectPopup : false,
    IntroductionPopup : false,
    GuidePopup : false,
    FcfsPopup : false,
    PlacePopup: false,
    ConnectedWalletSelectPopup: false,
    LaunchMintResultPopup: false,
    StoryPopup : false,
    InfoPopup : false,
    ShuffleLiquidityDepositPopup : false,
    ShuffleLiquidityTokenPopup : false,
    ShuffleLiquidityNftPopup : false,
    ShuffleMyTicketsPopup : false,
    ShuffleHistoryPopup : false,
    LoadingPopup: false,
    ShuffleHowToPlayPopup : false,
    ShuffleLedgerSetupPopup : false,
    ShuffleFairPopup : false,
    TreasurePopup : false,
    ProfileMapPopup : false,
    ShuffleLiquidityLocksPopup : false,
    CloudWalletPopup : false,
    AirdropPopup : false,
    Anyofthem: false,
}

const closeAllPopupsExcept = (state: PopupState, except:any) => {

    state.WalletConnectPopup = false
    state.LoginPopup = false
    state.SettingsPopup = false
    state.EditProfilePopup = false
    state.NftViewPopup = false
    state.MapFilterPopup = false
    state.WelcomePopup = false
    state.WhitelistNominatePopup = false
    state.WhitelistWalletSelectPopup = false
    state.IntroductionPopup = false
    state.GuidePopup = false
    state.FcfsPopup = false
    state.PlacePopup = false
    state.ConnectedWalletSelectPopup = false
    state.LaunchMintResultPopup = false
    state.StoryPopup = false
    state.InfoPopup = false
    state.ShuffleLiquidityDepositPopup = false;
    state.ShuffleLiquidityTokenPopup = false;
    state.ShuffleLiquidityNftPopup = false;
    state.ShuffleMyTicketsPopup = false;
    state.ShuffleHistoryPopup = false;
    state.ShuffleHowToPlayPopup = false;
    state.ShuffleLedgerSetupPopup = false;
    state.LoadingPopup = false;
    state.ShuffleFairPopup = false;
    state.TreasurePopup = false;
    state.ProfileMapPopup = false;
    state.ShuffleLiquidityLocksPopup = false;
    state.AirdropPopup = false;
    state.CloudWalletPopup = false;

    if (except != false) {
        (state as any)[except] = true
    }

}

export const popupSlice = createSlice({
    name: 'popup',
    initialState,
    reducers: {
        showWalletConnectPopup: (state, action: PayloadAction<any>) => {
            if (typeof action.payload === 'boolean') {
                state.WalletConnectPopup = action.payload
                
                if (action.payload === true) {
                    state.Anyofthem = true
                    closeAllPopupsExcept(state, 'WalletConnectPopup')
                } else {
                    state.Anyofthem = false
                }
            }else{
                state.WalletConnectPopup = action.payload.status
            }
        },
        showLoginPopup: (state, action: PayloadAction<boolean>) => {
            
            state.LoginPopup = action.payload

            if (action.payload === true) {
                state.Anyofthem = true
                closeAllPopupsExcept(state, 'LoginPopup')
            } else {
                state.Anyofthem = false
            }
        },
        showAirdropPopup: (state, action: PayloadAction<boolean>) => {
            
            state.AirdropPopup = action.payload

            if (action.payload === true) {
                state.Anyofthem = true
                closeAllPopupsExcept(state, 'AirdropPopup')
            } else {
                state.Anyofthem = false
            }
        },
        showSettingsPopup: (state, action: PayloadAction<boolean>) => {
            state.SettingsPopup = action.payload

            if (action.payload === true) {
                state.Anyofthem = true
                closeAllPopupsExcept(state, 'SettingsPopup')
            } else {
                state.Anyofthem = false
            }
        },
        showEditProfilePopup: (state, action: PayloadAction<any>) => {
            if (typeof action.payload === 'boolean') {
                state.EditProfilePopup = action.payload
                
                if (action.payload === true) {
                    state.Anyofthem = true
                    closeAllPopupsExcept(state, 'EditProfilePopup')
                } else {
                    state.Anyofthem = false
                }
            }else{
                state.EditProfilePopup = action.payload.status
                state.EditProfilePopupSharesFirst = action.payload.sharesFirst ? true : false
            }
        },
        SetEditProfilePopupSharesFirst: (state, action: PayloadAction<any>) => {
            state.EditProfilePopupSharesFirst = action.payload
        },
        showNftViewPopup: (state, action: PayloadAction<boolean>) => {
            state.NftViewPopup = action.payload

            if (action.payload === true) {
                state.Anyofthem = true
                closeAllPopupsExcept(state, 'NftViewPopup')
            } else {
                state.Anyofthem = false
            }
        },
        showMapFilterPopup: (state, action: PayloadAction<boolean>) => {
            state.MapFilterPopup = action.payload

            if (action.payload === true) {
                state.Anyofthem = true
                closeAllPopupsExcept(state, 'MapFilterPopup')
            } else {
                state.Anyofthem = false
            }
        },
        showWelcomePopup: (state, action: PayloadAction<boolean>) => {
            state.WelcomePopup = action.payload
            
            if (action.payload === true) {
                state.Anyofthem = true
                closeAllPopupsExcept(state, 'WelcomePopup')
            } else {
                state.Anyofthem = false
            }
        },
        showWhitelistNominatePopup: (state, action: PayloadAction<boolean>) => {
            state.WhitelistNominatePopup = action.payload
           
            if (action.payload === true) {
                state.Anyofthem = true
                closeAllPopupsExcept(state, 'WhitelistNominatePopup')
            } else {
                state.Anyofthem = false
            }
        },
        showWhitelistWalletSelectPopup: (state, action: PayloadAction<boolean>) => {
            state.WhitelistWalletSelectPopup = action.payload

            if (action.payload === true) {
                state.Anyofthem = true
                closeAllPopupsExcept(state, 'WhitelistWalletSelectPopup')
            } else {
                state.Anyofthem = false
            }
        },
        showIntroductionPopup: (state, action: PayloadAction<boolean>) => {
            if (state.WelcomePopup === true) {
                return
            }

            state.IntroductionPopup = action.payload

            if (action.payload === true) {
                state.Anyofthem = true
                closeAllPopupsExcept(state, 'IntroductionPopup')
            } else {
                state.Anyofthem = false
            }
        },
        showGuidePopup: (state, action: PayloadAction<boolean>) => {
            state.GuidePopup = action.payload
            
            if (action.payload === true) {
                state.Anyofthem = true
                closeAllPopupsExcept(state, 'GuidePopup')
            } else {
                state.Anyofthem = false
            }
        },
        showFcfsPopup: (state, action: PayloadAction<boolean>) => {
            state.FcfsPopup = action.payload
            
            if (action.payload === true) {
                state.Anyofthem = true
                closeAllPopupsExcept(state, 'FcfsPopup')
            } else {
                state.Anyofthem = false
            }
        },
        showPlacepopup: (state, action: PayloadAction<boolean>) => {
            state.PlacePopup = action.payload
            
            if (action.payload === true) {
                state.Anyofthem = true
                closeAllPopupsExcept(state, 'PlacePopup')
            } else {
                state.Anyofthem = false
            }
        },
        showConnectedWalletSelectPopup: (state, action: PayloadAction<any>) => {

            if (typeof action.payload === 'boolean') {
                state.ConnectedWalletSelectPopup = action.payload
                
                if (action.payload === true) {
                    state.Anyofthem = true
                    closeAllPopupsExcept(state, 'ConnectedWalletSelectPopup')
                } else {
                    state.Anyofthem = false
                }
            }else{
                state.ConnectedWalletSelectPopup = action.payload.status
            }
        },
        showLaunchMintResultPopup: (state, action: PayloadAction<boolean>) => {
            state.LaunchMintResultPopup = action.payload
            
            if (action.payload === true) {
                state.Anyofthem = true
                closeAllPopupsExcept(state, 'LaunchMintResultPopup')
            } else {
                state.Anyofthem = false
            }
        },
        showStoryPopup: (state, action: PayloadAction<boolean>) => {
            state.StoryPopup = action.payload
            
            if (action.payload === true) {
                state.Anyofthem = true
                closeAllPopupsExcept(state, 'StoryPopup')
            } else {
                state.Anyofthem = false
            }
        },
        showInfoPopup: (state, action: PayloadAction<boolean>) => {
            state.InfoPopup = action.payload
            
            if (action.payload === true) {
                state.Anyofthem = true
                closeAllPopupsExcept(state, 'InfoPopup')
            } else {
                state.Anyofthem = false
            }
        },
        showShuffleLiquidityDepositPopup: (state, action: PayloadAction<boolean>) => {

            state.ShuffleLiquidityDepositPopup = action.payload
            
            if (action.payload === true) {
                state.Anyofthem = true
                closeAllPopupsExcept(state, 'ShuffleLiquidityDepositPopup')
            } else {
                state.Anyofthem = false
            }
        },
        showShuffleLiquidityTokenPopup: (state, action: PayloadAction<boolean>) => {
            state.ShuffleLiquidityTokenPopup = action.payload

            if (action.payload === true) {
                state.Anyofthem = true
                closeAllPopupsExcept(state, 'ShuffleLiquidityTokenPopup')
            } else {
                state.Anyofthem = false
            }
        },
        showShuffleLiquidityNftPopup: (state, action: PayloadAction<boolean>) => {
            state.ShuffleLiquidityNftPopup = action.payload

            if (action.payload === true) {
                state.Anyofthem = true
                closeAllPopupsExcept(state, 'ShuffleLiquidityNftPopup')
            } else {
                state.Anyofthem = false
            }
        },
        showShuffleMyTicketsPopup: (state, action: PayloadAction<boolean>) => {
            state.ShuffleMyTicketsPopup = action.payload

            if (action.payload === true) {
                state.Anyofthem = true
                closeAllPopupsExcept(state, 'ShuffleMyTicketsPopup')
            } else {
                state.Anyofthem = false
            }
        },
        showShuffleHistoryPopup: (state, action: PayloadAction<boolean>) => {
            state.ShuffleHistoryPopup = action.payload

            if (action.payload === true) {
                state.Anyofthem = true
                closeAllPopupsExcept(state, 'ShuffleHistoryPopup')
            } else {
                state.Anyofthem = false
            }
        },
        showShuffleHowToPlayPopup: (state, action: PayloadAction<boolean>) => {
            state.ShuffleHowToPlayPopup = action.payload

            if (action.payload === true) {
                state.Anyofthem = true
                closeAllPopupsExcept(state, 'ShuffleHowToPlayPopup')
            } else {
                state.Anyofthem = false
            }
        },
        showShuffleLedgerSetupPopup : (state, action: PayloadAction<boolean>) => {
            state.ShuffleLedgerSetupPopup = action.payload

            if (action.payload === true) {
                state.Anyofthem = true
                closeAllPopupsExcept(state, 'ShuffleLedgerSetupPopup')
            } else {
                state.Anyofthem = false
            }
        },
        showLoadingPopup: (state, action: PayloadAction<boolean>) => {
            state.LoadingPopup = action.payload
        },
        showShuffleFairPopup: (state, action: PayloadAction<boolean>) => {
            state.ShuffleFairPopup = action.payload

            if (action.payload === true) {
                state.Anyofthem = true
                closeAllPopupsExcept(state, 'ShuffleFairPopup')
            } else {
                state.Anyofthem = false
            }
        },
        showTreasurePopup: (state, action: PayloadAction<boolean>) => {
            state.TreasurePopup = action.payload

            if (action.payload === true) {
                state.Anyofthem = true
                closeAllPopupsExcept(state, 'TreasurePopup')
            } else {
                state.Anyofthem = false
            }
        },
        showProfileMapPopup: (state, action: PayloadAction<boolean>) => {
            state.ProfileMapPopup = action.payload

            if (action.payload === true) {
                state.Anyofthem = true
                closeAllPopupsExcept(state, 'ProfileMapPopup')
            } else {
                state.Anyofthem = false
            }
        },
        showShuffleLiquidityLocksPopup: (state, action: PayloadAction<boolean>) => {
            state.ShuffleLiquidityLocksPopup = action.payload

            if (action.payload === true) {
                state.Anyofthem = true
                closeAllPopupsExcept(state, 'ShuffleLiquidityLocksPopup')
            } else {
                state.Anyofthem = false
            }
        },
        showCloudWalletPopup: (state, action: PayloadAction<any>) => {
            if (typeof action.payload === 'boolean') {
                state.CloudWalletPopup = action.payload
                
                if (action.payload === true) {
                    state.Anyofthem = true
                    closeAllPopupsExcept(state, 'CloudWalletPopup')
                } else {
                    state.Anyofthem = false
                }
            }else{
                state.CloudWalletPopup = action.payload.status
            }
        },
        closeAllPopups: (state) => {
            closeAllPopupsExcept(state, false)
        }
    }
})


export const { showWalletConnectPopup,showLoginPopup,showAirdropPopup, SetEditProfilePopupSharesFirst,showCloudWalletPopup,showShuffleFairPopup,showTreasurePopup,showProfileMapPopup, showShuffleLiquidityNftPopup, showShuffleLedgerSetupPopup, showShuffleHowToPlayPopup, showLoadingPopup, showInfoPopup, showShuffleMyTicketsPopup, showShuffleHistoryPopup, showShuffleLiquidityTokenPopup, showSettingsPopup, showShuffleLiquidityDepositPopup,showStoryPopup,closeAllPopups, showLaunchMintResultPopup, showConnectedWalletSelectPopup, showPlacepopup, showFcfsPopup, showGuidePopup, showEditProfilePopup, showNftViewPopup, showMapFilterPopup, showWelcomePopup, showWhitelistNominatePopup, showWhitelistWalletSelectPopup, showIntroductionPopup, showShuffleLiquidityLocksPopup} = popupSlice.actions
export default popupSlice.reducer