import { useDispatch, useSelector } from "react-redux"
import * as P from "./style"

import BaseMobilePage from "components/mobile/base"
import { IconCollections } from "utils/icons"

import { closeAllPages, MainParts } from "utils/parts"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCog } from "@fortawesome/free-solid-svg-icons"
import Settings from "components/parts/settings"
import { setCurrentPage } from "services/slices/data"
import { useNavigate } from "react-router-dom"

const MobilePage = () => {

    const currentPage = useSelector((state: any) => state.data.currentPage)
    const currentViewingWhitelist = useSelector((state: any) => state.holders.currentViewingWhitelist)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    
    const hide = () => {
        if (!currentViewingWhitelist) {
            closeAllPages()
            navigate("/app")
        }else{
            navigate(`/app/whitelist/${currentViewingWhitelist}`)
            dispatch(setCurrentPage(MainParts.Whitelist))
        }
    }

    return (
        <P.MobilePage>
            <BaseMobilePage hide={hide} title="Settings" show={currentPage === MainParts.Settings} icon={<FontAwesomeIcon icon={faCog}/>}>
                <Settings />
            </BaseMobilePage>
        </P.MobilePage>
    )
}

export default MobilePage