import React from "react"
import * as C from "./style"
import { useDispatch, useSelector } from "react-redux"
import { setLoginNotification } from "services/slices/user"
import { displayLoginPopup, displayWalletConnectPopup } from "utils/parts"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

const LoginNotification = () => {

    const show = useSelector((state: any) => state.user.loginNotification)
    const dispatch = useDispatch()

    if (!show)
        return (<></>)

    const close = () => {
        dispatch(setLoginNotification(false))
    }

    const login = () => {
        dispatch(setLoginNotification(false))
        displayLoginPopup(true)
    }

    return (
        <C.LoginNotification>
            you are not logged in <span onClick={login} >login with twitter</span> <FontAwesomeIcon onClick={close} icon={faTimes}/>
        </C.LoginNotification>
    )
}

export default LoginNotification