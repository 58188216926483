import Header from "components/parts/header"
import Map from "components/parts/map"
import Bar from "components/widgets/bar"
import MapWidgets from "components/widgets/mapWidgets"

import React, { useEffect } from "react"
import Menu from "components/widgets/menu"
import { closeAll, closeAllPopups_, closeAllPages, displayCollections, displayHolderProfile, displayProfile, displaySettings, displayWhitelist, displayWhitelistCollections, displayCollectionHolders, displayLaunches, displayLaunch, displayShuffleLiquidation, displayTokenHolders, displayShuffle, displayTreasure, displayShare, displayShares, displayEditProfile, displayChat, displayLoginPopup } from "utils/parts"
import * as P from "./style"
import { useNavigate, useParams } from "react-router-dom"
import { ManageHolderStates } from "services/managers/holders"
import { setCurrentWhitelist } from "services/slices/whitelist"
import { useDispatch, useSelector } from "react-redux"
import { getHolder } from "services/api/holders"
import toast from "react-hot-toast"
import { setCurrentViewingCollection, setCurrentViewingCollectionCode, setCurrentViewingLaunch, setCurrentViewingToken, setCurrentViewingTokenCode, setCurrentViewingWhitelist } from "services/slices/holders"
import { getLocalData } from "utils/localData"
import { setDisplayGuide } from "services/slices/data"
import { showInfoPopup, showIntroductionPopup } from "services/slices/popup"
import { setCurrentLaunch } from "services/slices/launch"
import WalletListener from "listeners/walletListener"
import { getIssuer } from "services/api/share"

var oldProps: any = {}

const App = (props: any) => {

    let params = useParams()

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userLoaded = useSelector((state: any) => state.user.userLoaded)
    const currentPage = useSelector((state: any) => state.data.currentPage)
    const user = useSelector((state: any) => state.user.user)

    useEffect(() => {

        if (oldProps.target !== props.target) {

            if (props.target === "collections") {
                displayCollections()

                document.title = "Communities - hub3"
            }
            else if (props.target === "collection") {
                displayCollectionHolders(params.name!)
            }
            else if (props.target === "token") {
                displayTokenHolders(params.name!)
            }
            /*else if (props.target === "whitelistCollections") {
                displayWhitelistCollections()

                document.title = "Whitelist Collections - hub3"
            }
            else if (props.target === "whitelist") {
                displayWhitelist(params.name!)
            }
            else if (props.target === "launches") {
                displayLaunches()

                document.title = "Launchmap - hub3"
            }
            else if (props.target === "launch") {
                displayLaunch(params.name!)
            }*/
            else if (props.target === "settings" && user && userLoaded) {
                displaySettings()
                document.title = "hub3 - Empower the Web3 Experience"
            }
            else if (props.target === "myprofile" && user && userLoaded) {
                displayEditProfile(true)
                document.title = "Profile - hub3"
            }
            else if (props.target === "profile" && userLoaded) {
                displayProfile()
                ManageHolderStates(false, "profile")

                document.title = "Profile - hub3"
            }
            else if (props.target === "profile-view") {
                getHolder(params.userId!).then((res: any) => {
                    if (res.data.user === null && res.data.treasure)
                        displayTreasure(res.data.treasure)
                    else
                        displayHolderProfile(res.data.user)
                }).catch((err: any) => {
                    toast.error("User not found")
                    navigate("/app")
                })
            }
            else if (props.target === "share" && userLoaded) {
                getIssuer(params.userId!).then((res: any) => {
                    displayShare(res.data.user)
                    document.title = res.data.user.username + " - hub3"
                }).catch((err: any) => {
                    toast.error("User not found")
                    navigate("/app")
                })
            }
            else if (props.target === "chat" && user && userLoaded) {
                displayChat()
                document.title = "Chat - hub3"
            }
            else if (props.target === "shares") {
                displayShares()
                document.title = "Keys - hub3"
            }
            /*else if (props.target === "shuffle") {
                window.location.href = "https://shuffle.pub/"
                //displayShuffle()
            }
            else if (props.target === "shuffle-liquidation") {
                window.location.href = "https://shuffle.pub/liquidation"
                //displayShuffleLiquidation()
            }*/
            else if (props.target === "") {
                closeAllPages()
                closeAllPopups_()
                dispatch(setCurrentWhitelist(null))
                dispatch(setCurrentViewingWhitelist(null))
                dispatch(setCurrentViewingLaunch(null))
                dispatch(setCurrentLaunch(null))
                dispatch(setCurrentViewingCollection(null))
                dispatch(setCurrentViewingCollectionCode(null))
                dispatch(setCurrentViewingToken(null))
                dispatch(setCurrentViewingTokenCode(null))
                // dispatch(showInfoPopup(true))
                ManageHolderStates(false, "home")

                document.title = "hub3 - Empower the Web3 Experience"

                if (userLoaded) {
                    if (user) {
                        if (!user.firstTime.intro) {
                            dispatch(showIntroductionPopup(true))
                        }
                    }
                }
            }

            oldProps = props

            if (getLocalData("guide") !== "no") {
                dispatch(setDisplayGuide(true))
            }
        }

    }, [props])

    useEffect(() => {

        if (userLoaded && props.target === "profile") {
            if (currentPage !== "profile") {
                displayProfile()
                ManageHolderStates(false, "profile")
            }
        }

        if (userLoaded && props.target === "settings") {
            if (currentPage !== "settings") {
                displaySettings()
            }
        }

        if (userLoaded && props.target === "myprofile") {
            if (!user){
                displayLoginPopup(true)
            }else if (currentPage !== "profile") {
                displayEditProfile(true)
                document.title = "Profile - hub3"
            }
        }

        if (userLoaded && props.target === "share") {
            if (currentPage !== "share") {
                getIssuer(params.userId!).then((res: any) => {
                    displayShare(res.data.user)
                    document.title = res.data.user.username + " - hub3"
                }).catch((err: any) => {
                    toast.error("User not found")
                    navigate("/app")
                })
            }
        }

        if (userLoaded && props.target === "chat") {
            if (!user){
                displayLoginPopup(true)
            }else if (currentPage !== "chat") {
                displayChat()
                document.title = "Chat - hub3"
            }
        }


        if (userLoaded) {
            if (user) {
                if (!user.firstTime.intro) {
                    dispatch(showIntroductionPopup(true))
                }
            }
        }

    }, [userLoaded])

    return (
        <P.App>
            <Menu />
            <Bar />
            <MapWidgets />
            <Map />

            <WalletListener />

            {process.env.REACT_APP_ENVIRONMENT === "test" && <P.TestSign>
                TESTING SERVER</P.TestSign>}

           {/* <P.ShuffleAd href="https://shuffle.pub/" target="_blank">
                <img src="/images/etc/shuffle-ad.png" alt="shuffle.pub" />
    </P.ShuffleAd>*/}
        </P.App>
    )
}

export default App