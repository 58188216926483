import React from "react"
import * as P from "./style"

import { useSelector } from "react-redux"
import BasePopup from "components/popups/base"
import { IconCollections, IconConnectWallet } from "utils/icons"

import { showConnectedWalletSelectPopup } from "services/slices/popup"
import ConnectedWalletSelect from "components/parts/connectedWalletSelect"


const ConnectedWalletSelectPopup = () => {

    const show = useSelector((state: any) => state.popup.ConnectedWalletSelectPopup)
    
    return (
        <P.Popup>
            <BasePopup title="Select Wallet" show={show} hide={() => showConnectedWalletSelectPopup(false)} icon={<IconConnectWallet />} glasmorph={true}>
                <ConnectedWalletSelect/>
            </BasePopup>
        </P.Popup>
    )
}

export default ConnectedWalletSelectPopup