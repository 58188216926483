import styled from "styled-components"
import { deviceMax } from "styles/media"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"


export const Page = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: rgba(0, 0, 0, 0.56);
    z-index: 11;
    display:flex;
`

export const Overlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index:1;
`

export const Dialog = styled.div<{glasmorph : string, full : boolean}>`
    position: relative;
    width: ${props => props.full ? "calc(100% - 15px)" : "calc(100% - 276px)"};
    max-height:100%;
    z-index:2;

    transition:all .3s ease-in-out;
    
    color: ${color.white};
    padding:20px;
    border-radius: 16px;

    ${props => props.glasmorph === "true" && `
        backdrop-filter: blur(20px);
        background: ${Hex2Rgba(color.bg1, .64)};
    `}

    ${props => props.glasmorph === "false" && `
        background: ${Hex2Rgba(color.bg1, .64)};
    `}


    
    display:flex;
    flex-direction:column;

    @media ${deviceMax.sm}{
        width:100%;
    }

    margin:40px 92px 40px auto;
`

export const DialogHeader = styled.div`
    display: flex;
    cursor:default;
    user-select:none;
`
export const DialogTitle = styled.div`
    font-size:14px;
    display:flex;
`
export const DialogTitleIcon = styled.div`
    & svg{
        width:20px;
        height:20px;
        color: ${Hex2Rgba(color.white, .5)};
    }
    margin-right:10px;
`
export const DialogTitleText = styled.h1`
    margin:0;
    font-size:14px;
    font-weight:400;
`
export const DialogClose = styled.div`
    margin-left: auto;
    cursor: pointer;
    font-size: 24px;
    padding:0px 20px;
    padding-right:0px;
`
export const DialogBody = styled.div`
    flex:1;
    display:flex;
    flex-direction:column;
    transition:none;

    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius:10px;
    }

    &:hover::-webkit-scrollbar-thumb {
        background: #888;
    }
`