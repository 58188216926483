import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { deviceMax } from "styles/media"
import { Row } from "styles"
import { DropdownButton } from "components/ui/button/dropdown/style"

export const ShuffleLiquidityNft = styled.div`

`

export const Title = styled.div`
    margin-bottom:8px;
`

export const Nfts = styled(Row)`

`


export const NftInfo = styled.div`
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    padding:4px;
    background:${Hex2Rgba(color.bg1, .64)};
    transition:all .1s ease-in-out;
    font-size:12px;
    text-align:center;
`

export const NftActiveMark = styled.div``

export const Nft = styled.div<{image:string, isSelected?:string}>`
    background-image:url(${props => props.image});
    background-size:cover;
    background-position:center center;
    background-repeat:no-repeat;
    height:155px;
    border-radius: 8px;
    position:relative;
    margin-bottom:30px;
    cursor:pointer;
    transition:all .1s ease-in-out;
    overflow:hidden;
    &:hover{
        & ${NftInfo}{
            background:${Hex2Rgba(color.bg1, .84)};
        }

        opacity:.8;
    }   

    ${props => props.isSelected === 'true' && `
        

        & ${NftActiveMark}{
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            background:${Hex2Rgba(color.secondary, .32)};
            border: 2px solid ${color.secondary};
            border-radius: 8px;
        }
    `}
`



export const NftWithdrawActions = styled.div`
    position:sticky;
    bottom:0;
    width:100%;
    display:flex;
    align-items:center;
    
    & button{
        flex:1;
    }

    & button:not(:last-child){
        margin-right:8px;
    }
    
`

export const Loading = styled.div`
    font-size:18px;
    display:flex;
    align-items:center;
    justify-content:center;
    height:100%;
    margin: 32px 0;
`