import React from "react"
import * as P from "./style"

import { useSelector } from "react-redux"
import BasePopup from "components/popups/base"
import WalletConnect from "components/parts/walletConnect"
import { displayLoginPopup } from "utils/parts"
import { faSignIn } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Login from "components/parts/login"


const LoginPopup = () => {

    const show = useSelector((state: any) => state.popup.LoginPopup)
    
    return (
        <P.Popup>
            <BasePopup title="Login to hub3" show={show} isredux={false} hide={() => displayLoginPopup(false)} icon={<FontAwesomeIcon icon={faSignIn} />} glasmorph={true}>
                <Login />
            </BasePopup>
        </P.Popup>
    )
}

export default LoginPopup