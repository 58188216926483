import { useSelector } from "react-redux"
import * as P from "./style"

import BasePage from "components/pages/base"
import { IconShuffle } from "utils/icons"

import { MainParts } from "utils/parts"
import Shuffle from "components/parts/shuffle"

const Page = () => {

    const show = useSelector((state: any) => state.data.currentPage)
    
    return (
        <P.Page>
            <BasePage title="Shuffle" show={show === MainParts.Shuffle} icon={<IconShuffle />} glasmorph={true}>
                <Shuffle />
            </BasePage>
        </P.Page>
    )
}

export default Page