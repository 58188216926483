import { PublicKey, LAMPORTS_PER_SOL, Connection, Transaction, ComputeBudgetProgram } from '@solana/web3.js'
import * as anchor from "@project-serum/anchor"
import { SOLANA_CONNECTION, SOLANA_CONNECTION_CONFIRMED, WaitForTransaction } from '../connection'
import { toAnchorWallet } from '../wallet'
import { findWallet } from 'modules/wallet'
import { store } from 'services/store'
import { IDL } from './types'
import { Wallet } from "@project-serum/anchor/dist/cjs/provider"
import { getShuffleError, parseCustomProgramError } from './shuffleErrors'
import toast from 'react-hot-toast'
import { getATokenAccountsNeedCreate } from './helpers'
import * as spltoken from '@solana/spl-token'
import { Network } from 'models/enums/network'
import { setWalletConnectSettings } from 'services/slices/data'
import { showWalletConnectPopup } from 'services/slices/popup'
import { bufferToText, generateRandomNumbersFromHash, sleep } from 'utils/helpers'
import crypto from 'crypto'

const systemProgram = anchor.web3.SystemProgram

const viewWallet = {
    signTransaction: null,
    signAllTransactions: null,
    publicKey: null
} as unknown as Wallet

const TICKET_PURCHASE_ACCOUNT_SIZE = 1085;

export const getRaffle = async (programId: string, raffleAddress: string) => {

    /*const wallet = findWallet(store.getState().user.selectedWallet)

    if (!wallet) {
        throw new Error('No wallet selected')
    }*/

    const anchorConnection = new anchor.AnchorProvider(SOLANA_CONNECTION, viewWallet, anchor.AnchorProvider.defaultOptions())

    const program = new anchor.Program(IDL as anchor.Idl, programId, anchorConnection)

    const raffle: any = await program.account.raffle.fetch(new PublicKey(raffleAddress))

    var purchasePDAs: any = await program.account.ticketPurchase.all([
        {
            memcmp: {
                offset: 8,
                bytes: raffleAddress
            }
        },
        {
            dataSize: TICKET_PURCHASE_ACCOUNT_SIZE
        }
    ])

    const ticketSaleAccount: any = await program.account.raffleTicketSales.all([
        {
            memcmp: {
                offset: 8,
                bytes: raffleAddress
            }
        },
    ])


    raffle.purchasePDAs = purchasePDAs
    raffle.ticketSales = ticketSaleAccount[0].account.ticketSales
    raffle.ticketSaleAccount = ticketSaleAccount[0].publicKey
    raffle.winningTickets = getWinnersOfRaffle(raffle)

    return raffle
}

export const getWinnersOfRaffle = (raffle: any) => {

    if (typeof raffle.status.ended !== "undefined" && raffle.hash.length > 0) {
        const hash = bufferToText(raffle.hash)
        //console.log("hash", hash)
        let totalInventoryCount = raffle.inventory.reduce((a: any, b: any) => a + b.count[0].toNumber(), 0)
        const winners = generateRandomNumbersFromHash(hash, totalInventoryCount)
        const tickets = [...raffle.ticketSales]
        let winnerTickets = []
        for (let i = 0; i < winners.randomNumbers.length; i++) {
            let winnerIndex = Math.round(winners.randomNumbers[i] * (tickets.length - 1))
            let winner = tickets[winnerIndex]
            tickets.splice(winnerIndex, 1)
            winnerTickets.push(winner)
        }

        //console.log("winnerTickets", winnerTickets.map((item: any) => item).sort((a: any, b: any) => a - b))

        return winnerTickets
    }

    return []

}

export const buyTicket = async (programId: string, raffleAddress: string, ticketSaleAccount: string, vaultAddress: string, count: number) => new Promise(async (resolve, reject) => {

    const wallet = findWallet(store.getState().user.selectedWallet)


    if (!wallet) {
        //throw new Error('No wallet selected')

        store.dispatch(setWalletConnectSettings({ network: Network.Solana }))
        store.dispatch(showWalletConnectPopup(true))

        return reject("Please connect your wallet")
    }

    try {


        const anchorConnection = new anchor.AnchorProvider(SOLANA_CONNECTION_CONFIRMED, toAnchorWallet(wallet), anchor.AnchorProvider.defaultOptions())

        const program = new anchor.Program(IDL as anchor.Idl, programId, anchorConnection)

        const [ticketPurchaseAccountPDA, _] = anchor.web3.PublicKey.findProgramAddressSync(
            [anchor.utils.bytes.utf8.encode('ticket-purchase'), new PublicKey(raffleAddress).toBuffer(), new PublicKey(wallet.address[0]).toBuffer()],
            program.programId
        )


        const modifyComputeUnits = ComputeBudgetProgram.setComputeUnitLimit({
            units: 1000000
        });

        const addPriorityFee = ComputeBudgetProgram.setComputeUnitPrice({
            microLamports: 1
        });

        const buyticketTx = await program.methods.buyRaffleTicket(
            count
        ).accounts({
            authority: new PublicKey(wallet.address[0]),
            raffle: new PublicKey(raffleAddress),
            vault: new PublicKey(vaultAddress),
            raffleTicketSales: new PublicKey(ticketSaleAccount),
            ticketPurchaseAccount: ticketPurchaseAccountPDA,
            systemProgram: systemProgram.programId,
        })
            .transaction()

        buyticketTx.recentBlockhash = (await SOLANA_CONNECTION_CONFIRMED.getLatestBlockhash("finalized")).blockhash
        buyticketTx.feePayer = new PublicKey(wallet.address[0])

        const tx = new Transaction().add(modifyComputeUnits).add(addPriorityFee).add(buyticketTx)

        tx.feePayer = new PublicKey(wallet.address[0])
        tx.recentBlockhash = (await SOLANA_CONNECTION_CONFIRMED.getLatestBlockhash("finalized")).blockhash

        let signed;

        try {
            signed = await wallet.instance.signTransaction(tx)
        } catch (error) {
            return reject(getShuffleError("0x0"))
        }

        let loading = toast.loading("Buying tickets...")

        try {
            const sig = await SOLANA_CONNECTION_CONFIRMED.sendRawTransaction(signed.serialize(), { skipPreflight: true, preflightCommitment: "finalized" })
            //console.log(sig)

            WaitForTransaction(sig, SOLANA_CONNECTION_CONFIRMED).then(() => {
                //console.log(sig + " confirmed")
                toast.dismiss(loading)
                resolve(sig)
            }).catch((err) => {
                toast.dismiss(loading)
                if (!err || !err.InstructionError || !err.InstructionError[1] || !err.InstructionError[1].Custom)
                    reject("Error buying tickets")
                else {
                    let errorMessage = getShuffleError(err.InstructionError[1].Custom)
                    if (!errorMessage)
                        errorMessage = "Error buying tickets"
                    reject(errorMessage)
                }
            })
        } catch (e: any) {
            console.log(e)
            toast.dismiss(loading)

            if (e.message && e.message.indexOf("Attempt to debit an account but found no record of a prior credit.") !== -1) {
                return reject(getShuffleError("0x1"))
            } else {
                let custom = parseCustomProgramError(e)
                let errorMessage = getShuffleError(custom)
                if (!errorMessage)
                    errorMessage = "Error buying tickets"
                reject(errorMessage)
            }
        }

    } catch (e: any) {

        console.log("error", e)

        reject("Error buying tickets")

    }
})

export const getAllRaffles = async (programId: string) => {

    const anchorConnection = new anchor.AnchorProvider(SOLANA_CONNECTION, viewWallet, anchor.AnchorProvider.defaultOptions())

    const program = new anchor.Program(IDL as anchor.Idl, programId, anchorConnection)

    const raffles: any = await program.account.raffle.all()

    var purchasePDAs: any = await program.account.ticketPurchase.all()

    for (let i = 0; i < raffles.length; i++) {
        const ticketSaleAccount: any = await program.account.raffleTicketSales.all([
            {
                memcmp: {
                    offset: 8,
                    bytes: raffles[i].publicKey.toBase58()
                }
            },
        ])

        raffles[i].account.ticketSales = ticketSaleAccount[0].account.ticketSales
        raffles[i].account.ticketSaleAccount = ticketSaleAccount[0].publicKey
    }

    for (let i = 0; i < raffles.length; i++) {
        raffles[i].purchasePDAs = purchasePDAs.filter((p: any) => p.account.raffle.toBase58() === raffles[i].publicKey.toBase58())
        raffles[i].account.winningTickets = getWinnersOfRaffle(raffles[i].account)
    }



    return raffles

}

export const ClaimTickets = async (programId: string, vaultAddress: string, treasury: string, raffleAddress: string, tickets: any, purchasePDA: string) => new Promise(async (resolve, reject) => {

    try {

        const wallet = findWallet(store.getState().user.selectedWallet)

        if (!wallet) {
            throw new Error('No wallet selected')
        }

        //console.log(wallet)

        const anchorConnection = new anchor.AnchorProvider(SOLANA_CONNECTION, toAnchorWallet(wallet), anchor.AnchorProvider.defaultOptions())

        const program = new anchor.Program(IDL as anchor.Idl, programId, anchorConnection)

        let txs = []

        for (let i = 0; i < tickets.length; i++) {
            const ticketNumber = new anchor.BN(tickets[i].ticket);
            const ticketNumberBytes = ticketNumber.toArray("le", 8);
            const ticketNumberArray = new Uint8Array(ticketNumberBytes);
            const [ticketPDA, _] = anchor.web3.PublicKey.findProgramAddressSync(
                [anchor.utils.bytes.utf8.encode('ticket'), new PublicKey(raffleAddress).toBuffer(), ticketNumberArray],
                program.programId
            )

            if (tickets[i].type === "native") {

                const modifyComputeUnits = ComputeBudgetProgram.setComputeUnitLimit({
                    units: 1000000
                });

                const addPriorityFee = ComputeBudgetProgram.setComputeUnitPrice({
                    microLamports: 1
                });

                const ctx = await program.methods.winnerClaimNative(
                    new anchor.BN(ticketNumber)
                )
                    .accounts({
                        authority: new PublicKey(wallet.address[0]),
                        raffle: new PublicKey(raffleAddress),
                        //treasury: new PublicKey(treasury),
                        vault: new PublicKey(vaultAddress),
                        ticketPurchaseAccount: new PublicKey(purchasePDA),
                        ticketAccount: ticketPDA,
                        systemProgram: systemProgram.programId,
                    })
                    .transaction()

                const tx = new Transaction().add(modifyComputeUnits).add(addPriorityFee).add(ctx)

                tx.recentBlockhash = (await SOLANA_CONNECTION.getLatestBlockhash("finalized")).blockhash;
                tx.feePayer = new PublicKey(wallet.address[0])
                txs.push(tx)
            } else if (tickets[i].type === "token") {

                const { atokenix, destatokenAccount } = await getATokenAccountsNeedCreate(new PublicKey(wallet.address[0]), new PublicKey(wallet.address[0]), new PublicKey(tickets[i].mint))

                const preInstructions = [];

                if (atokenix) {
                    //tx.add(atokenix);
                    preInstructions.push(atokenix);
                }

                const vaultTokenAccount = await spltoken.getAssociatedTokenAddress(new PublicKey(tickets[i].mint), new PublicKey(vaultAddress), true)

                const modifyComputeUnits = ComputeBudgetProgram.setComputeUnitLimit({
                    units: 1000000
                });

                const addPriorityFee = ComputeBudgetProgram.setComputeUnitPrice({
                    microLamports: 1
                });

                const ctx = await program.methods.winnerClaimToken(
                    new anchor.BN(ticketNumber),
                    new PublicKey(tickets[i].mint),
                )
                    .accounts({
                        authority: new PublicKey(wallet.address[0]),
                        raffle: new PublicKey(raffleAddress),
                        //treasury: new PublicKey(treasury),
                        vault: new PublicKey(vaultAddress),
                        ticketPurchaseAccount: new PublicKey(purchasePDA),
                        ticketAccount: ticketPDA,
                        vaultTokenAccount: new PublicKey(vaultTokenAccount),
                        tokenAccount: new PublicKey(destatokenAccount),
                        mintAccount: new PublicKey(tickets[i].mint),
                        systemProgram: systemProgram.programId,
                        tokenProgram: spltoken.TOKEN_PROGRAM_ID,
                        associatedTokenProgram: spltoken.ASSOCIATED_TOKEN_PROGRAM_ID
                    })
                    .transaction()

                const tx = new Transaction().add(modifyComputeUnits).add(addPriorityFee).add(ctx)

                tx.recentBlockhash = (await SOLANA_CONNECTION.getLatestBlockhash("finalized")).blockhash;
                tx.feePayer = new PublicKey(wallet.address[0])

                txs.push(tx)
            } else if (tickets[i].type === "nft") {

                const { atokenix, destatokenAccount } = await getATokenAccountsNeedCreate(new PublicKey(wallet.address[0]), new PublicKey(wallet.address[0]), new PublicKey(tickets[i].mint))

                const preInstructions = [];

                if (atokenix) {
                    //tx.add(atokenix);
                    preInstructions.push(atokenix);
                }

                const vaultTokenAccount = await spltoken.getAssociatedTokenAddress(new PublicKey(tickets[i].mint), new PublicKey(vaultAddress), true)

                const modifyComputeUnits = ComputeBudgetProgram.setComputeUnitLimit({
                    units: 1000000
                });

                const addPriorityFee = ComputeBudgetProgram.setComputeUnitPrice({
                    microLamports: 1
                });

                const ctx = await program.methods.winnerClaimNft(
                    new anchor.BN(ticketNumber),
                    new PublicKey(raffleAddress),
                    new PublicKey(tickets[i].mint),
                )
                    .accounts({
                        authority: new PublicKey(wallet.address[0]),
                        //raffle: new PublicKey(raffleAddress),
                        //treasury: new PublicKey(treasury),
                        vault: new PublicKey(vaultAddress),
                        ticketPurchaseAccount: new PublicKey(purchasePDA),
                        ticketAccount: ticketPDA,
                        nftTokenAccount: destatokenAccount,
                        nftMintAccount: new PublicKey(tickets[i].mint),
                        vaultNftTokenAccount: vaultTokenAccount,
                        systemProgram: systemProgram.programId,
                        tokenProgram: spltoken.TOKEN_PROGRAM_ID,
                        associatedTokenProgram: spltoken.ASSOCIATED_TOKEN_PROGRAM_ID
                    })
                    .transaction()

                const tx = new Transaction().add(modifyComputeUnits).add(addPriorityFee).add(ctx)

                tx.recentBlockhash = (await SOLANA_CONNECTION.getLatestBlockhash("finalized")).blockhash;
                tx.feePayer = new PublicKey(wallet.address[0])

                txs.push(tx)

            }
        }

        let signedTxs;

        try {
            signedTxs = await wallet.instance.signAllTransactions(txs)
        } catch (error) {
            return reject(getShuffleError("0x0"))
        }

        let loading = toast.loading("Claiming prizes...")

        var processing = 0
        var success = 0
        var failed = 0
        var concurrent = 5

        for (let i = 0; i < signedTxs.length; i++) {
            processing++
            try {
                let sig = await SOLANA_CONNECTION.sendRawTransaction(signedTxs[i].serialize(), {
                    skipPreflight: true,
                    preflightCommitment: "confirmed"
                })

                //console.log("sig", sig)

                try {
                    WaitForTransaction(sig).then(() => {
                        success++
                        processing--
                    }).catch((err) => {
                        failed++
                        processing--
                    })
                } catch (err) {
                    failed++
                    processing--
                }
            } catch (err) {
                failed++
                processing--
            }

            while (processing >= concurrent) {
                await sleep(1000)
            }
        }

        while (processing > 0) {
            await sleep(1000)
        }

        toast.dismiss(loading)

        toast.dismiss(loading)
        if (success > 0 && failed > 0) {
            toast.success(success + " prizes claimed successfully and " + failed + " failed")
        } else if (success > 0) {
            toast.success(success + " prizes claimed successfully")
        } else if (failed > 0) {
            toast.error(failed + " prizes failed to claim")
        }

        return resolve(true)

    } catch (error) {
        return reject(error)
    }


})

export const WinnerClaimAll = async (programId: string, vaultAddress: string, treasury: string, raffleAddress: string, tokens: any, nfts: any) => new Promise(async (resolve, reject) => {

    const wallet = findWallet(store.getState().user.selectedWallet)

    if (!wallet) {
        throw new Error('No wallet selected')
    }

    const anchorConnection = new anchor.AnchorProvider(SOLANA_CONNECTION, toAnchorWallet(wallet), anchor.AnchorProvider.defaultOptions())

    const program = new anchor.Program(IDL as anchor.Idl, programId, anchorConnection)

    const txs = new Transaction()

    for (let i = 0; i < tokens.length; i++) {
        if (tokens[i].mint === "So11111111111111111111111111111111111111111") {
            const tx = await program.methods.winnerClaimNative()
                .accounts({
                    authority: new PublicKey(wallet.address[0]),
                    raffle: new PublicKey(raffleAddress),
                    treasury: new PublicKey(treasury),
                    vault: new PublicKey(vaultAddress),
                    systemProgram: systemProgram.programId
                }).transaction()

            txs.add(tx)
        } else {
            //get token account
            const { atokenix, destatokenAccount } = await getATokenAccountsNeedCreate(new PublicKey(wallet.address[0]), new PublicKey(wallet.address[0]), new PublicKey(tokens[i].mint))

            const preInstructions = [];

            if (atokenix) {
                //tx.add(atokenix);
                preInstructions.push(atokenix);
            }

            const vaultTokenAccount = await spltoken.getAssociatedTokenAddress(new PublicKey(tokens[i].mint), new PublicKey(vaultAddress), true)

            const tx = await program.methods.winnerClaimToken(
                new PublicKey(tokens[i].mint),
            ).accounts({
                authority: new PublicKey(wallet.address[0]),
                raffle: new PublicKey(raffleAddress),
                treasury: new PublicKey(treasury),
                vault: new PublicKey(vaultAddress),
                vaultTokenAccount: new PublicKey(vaultTokenAccount),
                tokenAccount: new PublicKey(destatokenAccount),
                mintAccount: new PublicKey(tokens[i].mint),
                systemProgram: systemProgram.programId,
                tokenProgram: spltoken.TOKEN_PROGRAM_ID,
                associatedTokenProgram: spltoken.ASSOCIATED_TOKEN_PROGRAM_ID
            }).preInstructions(preInstructions).transaction()

            txs.add(tx)
        }
    }

    for (let i = 0; i < nfts.length; i++) {

        //get token account
        const { atokenix, destatokenAccount } = await getATokenAccountsNeedCreate(new PublicKey(wallet.address[0]), new PublicKey(wallet.address[0]), new PublicKey(nfts[i].mint))

        const preInstructions = [];

        if (atokenix) {
            //tx.add(atokenix);
            preInstructions.push(atokenix);
        }

        const vaultTokenAccount = await spltoken.getAssociatedTokenAddress(new PublicKey(nfts[i].mint), new PublicKey(vaultAddress), true)

        const tx = await program.methods.winnerClaimNft(
            new PublicKey(nfts[i].mint)
        ).accounts({
            authority: new PublicKey(wallet.address[0]),
            raffle: new PublicKey(raffleAddress),
            treasury: new PublicKey(treasury),
            vault: new PublicKey(vaultAddress),
            nftTokenAccount: destatokenAccount,
            nftMintAccount: new PublicKey(nfts[i].mint),
            vaultNftTokenAccount: vaultTokenAccount,
            systemProgram: systemProgram.programId,
            tokenProgram: spltoken.TOKEN_PROGRAM_ID,
            associatedTokenProgram: spltoken.ASSOCIATED_TOKEN_PROGRAM_ID
        }).preInstructions(preInstructions).transaction()

        /*console.log({
            authority: new PublicKey(wallet.address[0]).toBase58(),
            raffle: new PublicKey(raffleAddress).toBase58(),
            treasury: new PublicKey(treasury).toBase58(),
            vault: new PublicKey(vaultAddress).toBase58(),
            nftTokenAccount: destatokenAccount.toBase58(),
            nftMintAccount: new PublicKey(nfts[i].mint).toBase58(),
            vaultNftTokenAccount: vaultTokenAccount.toBase58(),
            systemProgram: systemProgram.programId,
            tokenProgram: spltoken.TOKEN_PROGRAM_ID,
            associatedTokenProgram: spltoken.ASSOCIATED_TOKEN_PROGRAM_ID
        })*/

        txs.add(tx)
    }

    txs.recentBlockhash = (await SOLANA_CONNECTION.getLatestBlockhash("finalized")).blockhash;
    txs.feePayer = new PublicKey(wallet.address[0])

    //console.log("tx", txs)
    let signed;

    try {
        signed = await wallet.instance.signTransaction(txs)
    } catch (e: any) {
        if (e.message.indexOf("Transaction too large") !== -1) {
            return reject(getShuffleError("0x2"))
        }

        return reject(getShuffleError("0x0"))
    }

    //console.log("signed", signed)

    let loading = toast.loading("Claiming Prizes...")

    try {
        const sig = await SOLANA_CONNECTION.sendRawTransaction(signed.serialize(), { skipPreflight: true, preflightCommitment: "finalized" })
        //console.log("sig", sig)

        WaitForTransaction(sig).then((res) => {

            toast.dismiss(loading)
            toast.success("Prizes Claimed Successfully")
            resolve(true)
        }).catch((err) => {
            console.log("err", err)
            if (!err || !err.InstructionError || !err.InstructionError[1] || !err.InstructionError[1].Custom)
                return reject("Error Claiming Prizes")
            else {
                let errorMessage = getShuffleError(err.InstructionError[1].Custom)
                if (!errorMessage)
                    errorMessage = "Error Claiming Prizes"
                return reject(errorMessage)
            }
        })

    } catch (e) {
        console.log("error", e)
        toast.dismiss(loading)
        reject("Error Claiming Prizes")
    }



})

export const totalShuffleVolume = async (programId: string) => new Promise(async (resolve, reject) => {

    let raffles: any = await getAllRaffles(programId)

    let totalVolume = 0
    for (let i = 0; i < raffles.length; i++) {
        let raffle = raffles[i]
        if (typeof raffle.account.status.ended !== "undefined") {
            let cap = raffle.account.ticketPrice.toNumber() / LAMPORTS_PER_SOL * raffle.account.ticketSupply
            totalVolume += cap
        }
    }


    resolve(totalVolume.toFixed(2))
})

export const getTicketsOf = async (programId: string, raffleAddress: string, walletAddress: string) => new Promise(async (resolve, reject) => {

    const anchorConnection = new anchor.AnchorProvider(SOLANA_CONNECTION, viewWallet, anchor.AnchorProvider.defaultOptions())
    const program = new anchor.Program(IDL as anchor.Idl, programId, anchorConnection)

    const [pda, _] = anchor.web3.PublicKey.findProgramAddressSync(
        [anchor.utils.bytes.utf8.encode('ticket-purchase'), new PublicKey(raffleAddress).toBuffer(), new PublicKey(walletAddress).toBuffer()],
        program.programId
    )

    const ticketPurchaseAccount : any= await program.account.ticketPurchase.fetch(pda)
    if (ticketPurchaseAccount && ticketPurchaseAccount.sales.length > 0)
        return resolve(ticketPurchaseAccount.sales)

    return resolve(false);


})

export const getMultipleTickets = async (programId: string, raffleAddress: string, tickets: number[]) => new Promise(async (resolve, reject) => {

    const anchorConnection = new anchor.AnchorProvider(SOLANA_CONNECTION, viewWallet, anchor.AnchorProvider.defaultOptions())
    const program = new anchor.Program(IDL as anchor.Idl, programId, anchorConnection)

    let pdas = []

    for (let i = 0; i < tickets.length; i++) {
        const ticketNumber = new anchor.BN(tickets[i]);
        const ticketNumberBytes = ticketNumber.toArray("le", 8);
        const ticketNumberArray = new Uint8Array(ticketNumberBytes);
        const [pda, _] = anchor.web3.PublicKey.findProgramAddressSync(
            [anchor.utils.bytes.utf8.encode('ticket'), new PublicKey(raffleAddress).toBuffer(), ticketNumberArray],
            program.programId
        )

        pdas.push(pda)
    }

    program.account.winningTicket.fetchMultiple(pdas).then((res) => {
        resolve(res)
    }).catch((err) => {
        reject(err)
    })


})

export const verifyShuffleTicket = async (programId: string, raffleAddress: string, hash: string) => new Promise(async (resolve, reject) => {

    try {

        const anchorConnection = new anchor.AnchorProvider(SOLANA_CONNECTION, viewWallet, anchor.AnchorProvider.defaultOptions())
        const program = new anchor.Program(IDL as anchor.Idl, programId, anchorConnection)
        const raffle: any = await program.account.raffle.fetch(new PublicKey(raffleAddress))

        const purchasePDAs: any = await program.account.ticketPurchase.all([
            {
                memcmp: {
                    offset: 8,
                    bytes: raffleAddress
                }
            },
            {
                dataSize: TICKET_PURCHASE_ACCOUNT_SIZE
            }
        ])

        const ticketSalesAccount : any = await program.account.raffleTicketSales.fetch(raffle.ticketSalesAccount)
        const tickets = ticketSalesAccount.ticketSales
        const sales = [...tickets]

        const inventory = []
        for (let i = 0; i < raffle.inventory.length; i++) {
            for (let j = 0; j < raffle.inventory[i].count[0].toNumber(); j++) {
                inventory.push({ order: j, type: raffle.inventory[i].itemType, index: i, address: raffle.inventory[i].address })
            }
        }

        const winners = generateRandomNumbersFromHash(hash, inventory.length)
        const wins = generateRandomNumbersFromHash(winners.previousHash, inventory.length)

        var winnerTickets: any = []
        for (let i = 0; i < winners.randomNumbers.length; i++) {
            let winnerIndex = Math.round(winners.randomNumbers[i] * (ticketSalesAccount.ticketSales.length - 1))
            let winner = tickets[winnerIndex]
            tickets.splice(winnerIndex, 1)

            let winIndex = Math.round(wins.randomNumbers[i] * (inventory.length - 1))
            let win = inventory[winIndex]
            inventory.splice(winIndex, 1)
            winnerTickets.push({
                ticket: winner,
                win: win
            })
        }

        let inventoryCount: any = {}

        for (let i = 0; i < raffle.inventory.length; i++) {
            for (let j = 0; j < raffle.inventory[i].count[0].toNumber(); j++) {
                inventoryCount[i] = {
                    count: inventoryCount[i] ? inventoryCount[i].count + 1 : 1,
                    address: raffle.inventory[i].address,
                    index: i,
                }
            }
        }

        resolve({
            pdas: purchasePDAs.map((p: any) => p.publicKey.toBase58()),
            tickets: winnerTickets,
            inventory: Object.values(inventoryCount),
            sales
        })

    } catch (e) {
        reject(e)
    }
})