import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { Dialog as Base, Dialog, DialogHeader } from "../base/style"

export const Popup = styled.div`
    & ${Dialog} {
        width: 500px;
        padding:0px;
        background: ${Hex2Rgba(color.places, 1)};
    }

    & ${DialogHeader} {
        display: none;
    }
`

