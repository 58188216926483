export type Shuffle = {
  "version": "0.1.0",
  "name": "shuffle",
  "instructions": [
    {
      "name": "initialize",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "setupTreasury",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "treasury",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "nts",
          "type": {
            "vec": {
              "defined": "Nft"
            }
          }
        },
        {
          "name": "tokens",
          "type": {
            "vec": {
              "defined": "Token"
            }
          }
        },
        {
          "name": "requirements",
          "type": {
            "vec": {
              "defined": "TreasuryLedgerLockRequirement"
            }
          }
        },
        {
          "name": "whitelisted",
          "type": {
            "vec": "publicKey"
          }
        },
        {
          "name": "public",
          "type": "u8"
        }
      ]
    },
    {
      "name": "updateTreasury",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "treasury",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "nts",
          "type": {
            "vec": {
              "defined": "Nft"
            }
          }
        },
        {
          "name": "tokens",
          "type": {
            "vec": {
              "defined": "Token"
            }
          }
        },
        {
          "name": "requirements",
          "type": {
            "vec": {
              "defined": "TreasuryLedgerLockRequirement"
            }
          }
        },
        {
          "name": "whitelisted",
          "type": {
            "vec": "publicKey"
          }
        },
        {
          "name": "public",
          "type": "u8"
        }
      ]
    },
    {
      "name": "createLedger",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "ledger",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ledgerLocks",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "treasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "closeAccount",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "account",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "ledgerLockNft",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "treasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ledger",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ledgerLocks",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftTokenAcccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftMintAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftMetadataAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vaultNftTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "address",
          "type": "publicKey"
        },
        {
          "name": "cmid",
          "type": "publicKey"
        }
      ]
    },
    {
      "name": "ledgerUnlockNft",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "treasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ledger",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ledgerLocks",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftTokenAcccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftMintAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftMetadataAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vaultNftTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "mint",
          "type": "publicKey"
        }
      ]
    },
    {
      "name": "liquidateTreasuryNft",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "treasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftTokenAcccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftMintAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftMetadataAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ledger",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ledgerLocks",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vaultNftTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "address",
          "type": "publicKey"
        },
        {
          "name": "cmid",
          "type": "publicKey"
        }
      ]
    },
    {
      "name": "liquidateTreasuryToken",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "treasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mintAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ledger",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ledgerLocks",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vaultTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "count",
          "type": "u64"
        },
        {
          "name": "amount",
          "type": "u64"
        }
      ]
    },
    {
      "name": "liquidateTreasuryNative",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "treasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ledger",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ledgerLocks",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "count",
          "type": "u64"
        },
        {
          "name": "amount",
          "type": "u64"
        }
      ]
    },
    {
      "name": "withdrawNftFromTreasury",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "treasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftTokenAcccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftMintAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftMetadataAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ledger",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vaultNftTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "mint",
          "type": "publicKey"
        }
      ]
    },
    {
      "name": "withdrawTokenFromTreasury",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "tokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mintAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ledger",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vaultTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "targetIndex",
          "type": "u64"
        }
      ]
    },
    {
      "name": "withdrawNativeFromTreasury",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "ledger",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "targetIndex",
          "type": "u64"
        }
      ]
    },
    {
      "name": "claimRevenue",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "ledger",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "setupRaffle",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "raffle",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "raffleTicketSales",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "treasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "inventory",
          "type": {
            "vec": {
              "defined": "InventoryItem"
            }
          }
        },
        {
          "name": "ticketSupply",
          "type": "u16"
        },
        {
          "name": "ticketPricePercent",
          "type": "u8"
        },
        {
          "name": "authorityPricePercent",
          "type": "u8"
        },
        {
          "name": "ticketLimit",
          "type": "u16"
        }
      ]
    },
    {
      "name": "buyRaffleTicket",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "raffle",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "raffleTicketSales",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ticketPurchaseAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "count",
          "type": "u16"
        }
      ]
    },
    {
      "name": "raffleAssignWinner",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "buyer",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ledger",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "raffle",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ticketPurchaseAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ticketAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "ticketNumber",
          "type": "u64"
        },
        {
          "name": "targetIndex",
          "type": "u16"
        }
      ]
    },
    {
      "name": "raffleRevertTicket",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "treasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "raffle",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ticketAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "ticketNumber",
          "type": "u64"
        }
      ]
    },
    {
      "name": "rafflePickWinners",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "treasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "raffle",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "hash",
          "type": "bytes"
        }
      ]
    },
    {
      "name": "raffleDistrubiteRevenues",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "treasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "raffle",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vaultAuthorityAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "raffleAuthorityAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "treasuryAuthorityAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "winnerClaimNft",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "ticketPurchaseAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ticketAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vaultNftTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftMintAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "ticketNumber",
          "type": "u64"
        },
        {
          "name": "rafflePubkey",
          "type": "publicKey"
        },
        {
          "name": "mint",
          "type": "publicKey"
        }
      ]
    },
    {
      "name": "winnerClaimToken",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "raffle",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ticketPurchaseAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ticketAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vaultTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mintAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "ticketNumber",
          "type": "u64"
        },
        {
          "name": "mint",
          "type": "publicKey"
        }
      ]
    },
    {
      "name": "winnerClaimNative",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "raffle",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ticketPurchaseAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ticketAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "ticketNumber",
          "type": "u64"
        }
      ]
    },
    {
      "name": "withdrawNativeFromVault",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "amount",
          "type": "u64"
        }
      ]
    },
    {
      "name": "withdrawTokenFromVault",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vaultTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mintAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "amount",
          "type": "u64"
        }
      ]
    },
    {
      "name": "withdrawNftFromVault",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vaultNftTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftMintAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftMetadataAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "metadataProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "authorizationRulesProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rulesetPda",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "ownerTokenRecord",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "destTokenRecord",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "masterEdition",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "sysvarInstruction",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "standard",
          "type": "u8"
        }
      ]
    },
    {
      "name": "depositNftToVault",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vaultNftTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftMintAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftMetadataAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "metadataProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "authorizationRulesProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rulesetPda",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "ownerTokenRecord",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "destTokenRecord",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "masterEdition",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "sysvarInstruction",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "standard",
          "type": "u8"
        }
      ]
    },
    {
      "name": "resetWinningTickets",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "raffle",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "initCollectionDatas",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "collectionDatas",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "updateCollectionDatas",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "collectionDatas",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "action",
          "type": "u8"
        },
        {
          "name": "data",
          "type": {
            "defined": "CollectionData"
          }
        }
      ]
    }
  ],
  "accounts": [
    {
      "name": "vault",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "bump",
            "type": "u8"
          },
          {
            "name": "authority",
            "type": "publicKey"
          }
        ]
      }
    },
    {
      "name": "treasury",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "authority",
            "type": "publicKey"
          },
          {
            "name": "supportedItems",
            "type": {
              "defined": "TreasurySupportedItems"
            }
          },
          {
            "name": "public",
            "type": "u8"
          },
          {
            "name": "requirements",
            "type": {
              "vec": {
                "defined": "TreasuryLedgerLockRequirement"
              }
            }
          },
          {
            "name": "whitelisted",
            "type": {
              "vec": "publicKey"
            }
          }
        ]
      }
    },
    {
      "name": "liquidatorLedger",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "authority",
            "type": "publicKey"
          },
          {
            "name": "treasury",
            "type": "publicKey"
          },
          {
            "name": "liquidations",
            "type": {
              "vec": {
                "defined": "Liquidation"
              }
            }
          },
          {
            "name": "revenues",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "ledgerLocks",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "ledger",
            "type": "publicKey"
          },
          {
            "name": "lockedNfts",
            "type": {
              "vec": {
                "defined": "LockedNft"
              }
            }
          },
          {
            "name": "bump",
            "type": "u8"
          }
        ]
      }
    },
    {
      "name": "raffle",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "authority",
            "type": "publicKey"
          },
          {
            "name": "treasury",
            "type": "publicKey"
          },
          {
            "name": "inventory",
            "type": {
              "vec": {
                "defined": "InventoryItem"
              }
            }
          },
          {
            "name": "ticketPrice",
            "type": "u64"
          },
          {
            "name": "totalCap",
            "type": "u64"
          },
          {
            "name": "ticketSupply",
            "type": "u16"
          },
          {
            "name": "ticketLimit",
            "type": "u16"
          },
          {
            "name": "ticketPricePercent",
            "type": "u8"
          },
          {
            "name": "authorityPricePercent",
            "type": "u8"
          },
          {
            "name": "status",
            "type": {
              "defined": "RaffleStatus"
            }
          },
          {
            "name": "hash",
            "type": "bytes"
          },
          {
            "name": "startDate",
            "type": "i64"
          },
          {
            "name": "ticketSalesAccount",
            "type": "publicKey"
          },
          {
            "name": "winningTickets",
            "type": {
              "vec": "u16"
            }
          }
        ]
      }
    },
    {
      "name": "raffleTicketSales",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "raffle",
            "type": "publicKey"
          },
          {
            "name": "ticketSales",
            "type": {
              "vec": "u16"
            }
          }
        ]
      }
    },
    {
      "name": "ticketPurchase",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "raffle",
            "type": "publicKey"
          },
          {
            "name": "buyer",
            "type": "publicKey"
          },
          {
            "name": "time",
            "type": "i64"
          },
          {
            "name": "bump",
            "type": "u8"
          },
          {
            "name": "sales",
            "type": {
              "vec": "u16"
            }
          }
        ]
      }
    },
    {
      "name": "winningTicket",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "raffle",
            "type": "publicKey"
          },
          {
            "name": "ticket",
            "type": "u16"
          },
          {
            "name": "winner",
            "type": "publicKey"
          },
          {
            "name": "target",
            "type": "u16"
          },
          {
            "name": "mint",
            "type": "publicKey"
          },
          {
            "name": "claimed",
            "type": "bool"
          },
          {
            "name": "bump",
            "type": "u8"
          }
        ]
      }
    },
    {
      "name": "collectionDatas",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "datas",
            "type": {
              "vec": {
                "defined": "CollectionData"
              }
            }
          },
          {
            "name": "bump",
            "type": "u8"
          }
        ]
      }
    }
  ],
  "types": [
    {
      "name": "TreasurySupportedItems",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "nfts",
            "type": {
              "vec": {
                "defined": "Nft"
              }
            }
          },
          {
            "name": "tokens",
            "type": {
              "vec": {
                "defined": "Token"
              }
            }
          }
        ]
      }
    },
    {
      "name": "Nft",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "cmid",
            "type": {
              "vec": "publicKey"
            }
          },
          {
            "name": "address",
            "type": "publicKey"
          },
          {
            "name": "maxDeposit",
            "type": "u8"
          }
        ]
      }
    },
    {
      "name": "LockedNft",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "address",
            "type": "publicKey"
          },
          {
            "name": "mint",
            "type": "publicKey"
          }
        ]
      }
    },
    {
      "name": "TreasuryLedgerLockRequirement",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "address",
            "type": "publicKey"
          },
          {
            "name": "cmid",
            "type": {
              "vec": "publicKey"
            }
          },
          {
            "name": "count",
            "type": "u8"
          }
        ]
      }
    },
    {
      "name": "Token",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "address",
            "type": "publicKey"
          },
          {
            "name": "amounts",
            "type": {
              "vec": "u64"
            }
          },
          {
            "name": "maxDeposit",
            "type": {
              "vec": "u16"
            }
          }
        ]
      }
    },
    {
      "name": "Liquidation",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "address",
            "type": "publicKey"
          },
          {
            "name": "mint",
            "type": "publicKey"
          },
          {
            "name": "amount",
            "type": {
              "array": [
                "u64",
                2
              ]
            }
          },
          {
            "name": "time",
            "type": "i64"
          }
        ]
      }
    },
    {
      "name": "InventoryItem",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "itemType",
            "type": {
              "defined": "ItemType"
            }
          },
          {
            "name": "address",
            "type": "publicKey"
          },
          {
            "name": "count",
            "type": {
              "array": [
                "u64",
                2
              ]
            }
          },
          {
            "name": "price",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "CollectionData",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "address",
            "type": "publicKey"
          },
          {
            "name": "ruleset",
            "type": "publicKey"
          },
          {
            "name": "standard",
            "type": "u8"
          }
        ]
      }
    },
    {
      "name": "RaffleStatus",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Ended"
          },
          {
            "name": "Active"
          },
          {
            "name": "Drawed"
          }
        ]
      }
    },
    {
      "name": "ItemType",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "NFT"
          },
          {
            "name": "TOKEN"
          },
          {
            "name": "NONE"
          }
        ]
      }
    }
  ],
  "events": [
    {
      "name": "TickeyBuyEvent",
      "fields": [
        {
          "name": "buyer",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "raffle",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "amount",
          "type": "u16",
          "index": false
        }
      ]
    },
    {
      "name": "LiquidateTreasuryEvent",
      "fields": [
        {
          "name": "mint",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "address",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "count",
          "type": "u64",
          "index": false
        },
        {
          "name": "amount",
          "type": "u64",
          "index": false
        },
        {
          "name": "liquidator",
          "type": "publicKey",
          "index": false
        }
      ]
    }
  ],
  "errors": [
    {
      "code": 6000,
      "name": "CollectionNotFound"
    },
    {
      "code": 6001,
      "name": "TokenNotFound"
    },
    {
      "code": 6002,
      "name": "TreasuryNoPermisson"
    },
    {
      "code": 6003,
      "name": "InvalidTicketAmount"
    },
    {
      "code": 6004,
      "name": "InvalidLiquidation"
    },
    {
      "code": 6005,
      "name": "NoMatchMetadata"
    },
    {
      "code": 6006,
      "name": "NoMatchCreator"
    },
    {
      "code": 6007,
      "name": "NoVerifiedCreator"
    },
    {
      "code": 6008,
      "name": "NoFoundNft"
    },
    {
      "code": 6009,
      "name": "NoPermission"
    },
    {
      "code": 6010,
      "name": "NumericalOverflowError"
    },
    {
      "code": 6011,
      "name": "NotEnoughLiquidate"
    },
    {
      "code": 6012,
      "name": "EndedRaffle"
    },
    {
      "code": 6013,
      "name": "NoClaimableRevenue"
    },
    {
      "code": 6014,
      "name": "InvalidRN"
    },
    {
      "code": 6015,
      "name": "LiquidationInDraw"
    },
    {
      "code": 6016,
      "name": "LiquidationUsed"
    },
    {
      "code": 6017,
      "name": "NotSoldOut"
    },
    {
      "code": 6018,
      "name": "InsufficientFundsForTransaction"
    },
    {
      "code": 6019,
      "name": "TicketOverBuyLimit"
    },
    {
      "code": 6020,
      "name": "InvalidTicketNumber"
    },
    {
      "code": 6021,
      "name": "InsufficientLockedNfts"
    },
    {
      "code": 6022,
      "name": "MaxLiquidationReached"
    }
  ]
};

export const IDL: Shuffle = {
  "version": "0.1.0",
  "name": "shuffle",
  "instructions": [
    {
      "name": "initialize",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "setupTreasury",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "treasury",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "nts",
          "type": {
            "vec": {
              "defined": "Nft"
            }
          }
        },
        {
          "name": "tokens",
          "type": {
            "vec": {
              "defined": "Token"
            }
          }
        },
        {
          "name": "requirements",
          "type": {
            "vec": {
              "defined": "TreasuryLedgerLockRequirement"
            }
          }
        },
        {
          "name": "whitelisted",
          "type": {
            "vec": "publicKey"
          }
        },
        {
          "name": "public",
          "type": "u8"
        }
      ]
    },
    {
      "name": "updateTreasury",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "treasury",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "nts",
          "type": {
            "vec": {
              "defined": "Nft"
            }
          }
        },
        {
          "name": "tokens",
          "type": {
            "vec": {
              "defined": "Token"
            }
          }
        },
        {
          "name": "requirements",
          "type": {
            "vec": {
              "defined": "TreasuryLedgerLockRequirement"
            }
          }
        },
        {
          "name": "whitelisted",
          "type": {
            "vec": "publicKey"
          }
        },
        {
          "name": "public",
          "type": "u8"
        }
      ]
    },
    {
      "name": "createLedger",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "ledger",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ledgerLocks",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "treasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "closeAccount",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "account",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "ledgerLockNft",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "treasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ledger",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ledgerLocks",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftTokenAcccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftMintAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftMetadataAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vaultNftTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "address",
          "type": "publicKey"
        },
        {
          "name": "cmid",
          "type": "publicKey"
        }
      ]
    },
    {
      "name": "ledgerUnlockNft",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "treasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ledger",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ledgerLocks",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftTokenAcccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftMintAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftMetadataAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vaultNftTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "mint",
          "type": "publicKey"
        }
      ]
    },
    {
      "name": "liquidateTreasuryNft",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "treasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftTokenAcccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftMintAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftMetadataAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ledger",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ledgerLocks",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vaultNftTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "address",
          "type": "publicKey"
        },
        {
          "name": "cmid",
          "type": "publicKey"
        }
      ]
    },
    {
      "name": "liquidateTreasuryToken",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "treasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mintAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ledger",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ledgerLocks",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vaultTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "count",
          "type": "u64"
        },
        {
          "name": "amount",
          "type": "u64"
        }
      ]
    },
    {
      "name": "liquidateTreasuryNative",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "treasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ledger",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ledgerLocks",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "count",
          "type": "u64"
        },
        {
          "name": "amount",
          "type": "u64"
        }
      ]
    },
    {
      "name": "withdrawNftFromTreasury",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "treasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftTokenAcccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftMintAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftMetadataAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ledger",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vaultNftTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "mint",
          "type": "publicKey"
        }
      ]
    },
    {
      "name": "withdrawTokenFromTreasury",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "tokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mintAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ledger",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vaultTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "targetIndex",
          "type": "u64"
        }
      ]
    },
    {
      "name": "withdrawNativeFromTreasury",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "ledger",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "targetIndex",
          "type": "u64"
        }
      ]
    },
    {
      "name": "claimRevenue",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "ledger",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "setupRaffle",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "raffle",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "raffleTicketSales",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "treasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "inventory",
          "type": {
            "vec": {
              "defined": "InventoryItem"
            }
          }
        },
        {
          "name": "ticketSupply",
          "type": "u16"
        },
        {
          "name": "ticketPricePercent",
          "type": "u8"
        },
        {
          "name": "authorityPricePercent",
          "type": "u8"
        },
        {
          "name": "ticketLimit",
          "type": "u16"
        }
      ]
    },
    {
      "name": "buyRaffleTicket",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "raffle",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "raffleTicketSales",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ticketPurchaseAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "count",
          "type": "u16"
        }
      ]
    },
    {
      "name": "raffleAssignWinner",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "buyer",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ledger",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "raffle",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ticketPurchaseAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ticketAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "ticketNumber",
          "type": "u64"
        },
        {
          "name": "targetIndex",
          "type": "u16"
        }
      ]
    },
    {
      "name": "raffleRevertTicket",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "treasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "raffle",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ticketAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "ticketNumber",
          "type": "u64"
        }
      ]
    },
    {
      "name": "rafflePickWinners",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "treasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "raffle",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "hash",
          "type": "bytes"
        }
      ]
    },
    {
      "name": "raffleDistrubiteRevenues",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "treasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "raffle",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vaultAuthorityAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "raffleAuthorityAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "treasuryAuthorityAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "winnerClaimNft",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "ticketPurchaseAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ticketAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vaultNftTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftMintAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "ticketNumber",
          "type": "u64"
        },
        {
          "name": "rafflePubkey",
          "type": "publicKey"
        },
        {
          "name": "mint",
          "type": "publicKey"
        }
      ]
    },
    {
      "name": "winnerClaimToken",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "raffle",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ticketPurchaseAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ticketAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vaultTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mintAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "ticketNumber",
          "type": "u64"
        },
        {
          "name": "mint",
          "type": "publicKey"
        }
      ]
    },
    {
      "name": "winnerClaimNative",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "raffle",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ticketPurchaseAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "ticketAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "ticketNumber",
          "type": "u64"
        }
      ]
    },
    {
      "name": "withdrawNativeFromVault",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "amount",
          "type": "u64"
        }
      ]
    },
    {
      "name": "withdrawTokenFromVault",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vaultTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mintAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "amount",
          "type": "u64"
        }
      ]
    },
    {
      "name": "withdrawNftFromVault",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vaultNftTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftMintAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftMetadataAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "metadataProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "authorizationRulesProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rulesetPda",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "ownerTokenRecord",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "destTokenRecord",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "masterEdition",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "sysvarInstruction",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "standard",
          "type": "u8"
        }
      ]
    },
    {
      "name": "depositNftToVault",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vaultNftTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftMintAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftMetadataAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "metadataProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "authorizationRulesProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rulesetPda",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "ownerTokenRecord",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "destTokenRecord",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "masterEdition",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "sysvarInstruction",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "standard",
          "type": "u8"
        }
      ]
    },
    {
      "name": "resetWinningTickets",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "raffle",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "initCollectionDatas",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "collectionDatas",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "updateCollectionDatas",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "collectionDatas",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "action",
          "type": "u8"
        },
        {
          "name": "data",
          "type": {
            "defined": "CollectionData"
          }
        }
      ]
    }
  ],
  "accounts": [
    {
      "name": "vault",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "bump",
            "type": "u8"
          },
          {
            "name": "authority",
            "type": "publicKey"
          }
        ]
      }
    },
    {
      "name": "treasury",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "authority",
            "type": "publicKey"
          },
          {
            "name": "supportedItems",
            "type": {
              "defined": "TreasurySupportedItems"
            }
          },
          {
            "name": "public",
            "type": "u8"
          },
          {
            "name": "requirements",
            "type": {
              "vec": {
                "defined": "TreasuryLedgerLockRequirement"
              }
            }
          },
          {
            "name": "whitelisted",
            "type": {
              "vec": "publicKey"
            }
          }
        ]
      }
    },
    {
      "name": "liquidatorLedger",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "authority",
            "type": "publicKey"
          },
          {
            "name": "treasury",
            "type": "publicKey"
          },
          {
            "name": "liquidations",
            "type": {
              "vec": {
                "defined": "Liquidation"
              }
            }
          },
          {
            "name": "revenues",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "ledgerLocks",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "ledger",
            "type": "publicKey"
          },
          {
            "name": "lockedNfts",
            "type": {
              "vec": {
                "defined": "LockedNft"
              }
            }
          },
          {
            "name": "bump",
            "type": "u8"
          }
        ]
      }
    },
    {
      "name": "raffle",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "authority",
            "type": "publicKey"
          },
          {
            "name": "treasury",
            "type": "publicKey"
          },
          {
            "name": "inventory",
            "type": {
              "vec": {
                "defined": "InventoryItem"
              }
            }
          },
          {
            "name": "ticketPrice",
            "type": "u64"
          },
          {
            "name": "totalCap",
            "type": "u64"
          },
          {
            "name": "ticketSupply",
            "type": "u16"
          },
          {
            "name": "ticketLimit",
            "type": "u16"
          },
          {
            "name": "ticketPricePercent",
            "type": "u8"
          },
          {
            "name": "authorityPricePercent",
            "type": "u8"
          },
          {
            "name": "status",
            "type": {
              "defined": "RaffleStatus"
            }
          },
          {
            "name": "hash",
            "type": "bytes"
          },
          {
            "name": "startDate",
            "type": "i64"
          },
          {
            "name": "ticketSalesAccount",
            "type": "publicKey"
          },
          {
            "name": "winningTickets",
            "type": {
              "vec": "u16"
            }
          }
        ]
      }
    },
    {
      "name": "raffleTicketSales",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "raffle",
            "type": "publicKey"
          },
          {
            "name": "ticketSales",
            "type": {
              "vec": "u16"
            }
          }
        ]
      }
    },
    {
      "name": "ticketPurchase",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "raffle",
            "type": "publicKey"
          },
          {
            "name": "buyer",
            "type": "publicKey"
          },
          {
            "name": "time",
            "type": "i64"
          },
          {
            "name": "bump",
            "type": "u8"
          },
          {
            "name": "sales",
            "type": {
              "vec": "u16"
            }
          }
        ]
      }
    },
    {
      "name": "winningTicket",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "raffle",
            "type": "publicKey"
          },
          {
            "name": "ticket",
            "type": "u16"
          },
          {
            "name": "winner",
            "type": "publicKey"
          },
          {
            "name": "target",
            "type": "u16"
          },
          {
            "name": "mint",
            "type": "publicKey"
          },
          {
            "name": "claimed",
            "type": "bool"
          },
          {
            "name": "bump",
            "type": "u8"
          }
        ]
      }
    },
    {
      "name": "collectionDatas",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "datas",
            "type": {
              "vec": {
                "defined": "CollectionData"
              }
            }
          },
          {
            "name": "bump",
            "type": "u8"
          }
        ]
      }
    }
  ],
  "types": [
    {
      "name": "TreasurySupportedItems",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "nfts",
            "type": {
              "vec": {
                "defined": "Nft"
              }
            }
          },
          {
            "name": "tokens",
            "type": {
              "vec": {
                "defined": "Token"
              }
            }
          }
        ]
      }
    },
    {
      "name": "Nft",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "cmid",
            "type": {
              "vec": "publicKey"
            }
          },
          {
            "name": "address",
            "type": "publicKey"
          },
          {
            "name": "maxDeposit",
            "type": "u8"
          }
        ]
      }
    },
    {
      "name": "LockedNft",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "address",
            "type": "publicKey"
          },
          {
            "name": "mint",
            "type": "publicKey"
          }
        ]
      }
    },
    {
      "name": "TreasuryLedgerLockRequirement",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "address",
            "type": "publicKey"
          },
          {
            "name": "cmid",
            "type": {
              "vec": "publicKey"
            }
          },
          {
            "name": "count",
            "type": "u8"
          }
        ]
      }
    },
    {
      "name": "Token",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "address",
            "type": "publicKey"
          },
          {
            "name": "amounts",
            "type": {
              "vec": "u64"
            }
          },
          {
            "name": "maxDeposit",
            "type": {
              "vec": "u16"
            }
          }
        ]
      }
    },
    {
      "name": "Liquidation",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "address",
            "type": "publicKey"
          },
          {
            "name": "mint",
            "type": "publicKey"
          },
          {
            "name": "amount",
            "type": {
              "array": [
                "u64",
                2
              ]
            }
          },
          {
            "name": "time",
            "type": "i64"
          }
        ]
      }
    },
    {
      "name": "InventoryItem",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "itemType",
            "type": {
              "defined": "ItemType"
            }
          },
          {
            "name": "address",
            "type": "publicKey"
          },
          {
            "name": "count",
            "type": {
              "array": [
                "u64",
                2
              ]
            }
          },
          {
            "name": "price",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "CollectionData",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "address",
            "type": "publicKey"
          },
          {
            "name": "ruleset",
            "type": "publicKey"
          },
          {
            "name": "standard",
            "type": "u8"
          }
        ]
      }
    },
    {
      "name": "RaffleStatus",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Ended"
          },
          {
            "name": "Active"
          },
          {
            "name": "Drawed"
          }
        ]
      }
    },
    {
      "name": "ItemType",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "NFT"
          },
          {
            "name": "TOKEN"
          },
          {
            "name": "NONE"
          }
        ]
      }
    }
  ],
  "events": [
    {
      "name": "TickeyBuyEvent",
      "fields": [
        {
          "name": "buyer",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "raffle",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "amount",
          "type": "u16",
          "index": false
        }
      ]
    },
    {
      "name": "LiquidateTreasuryEvent",
      "fields": [
        {
          "name": "mint",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "address",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "count",
          "type": "u64",
          "index": false
        },
        {
          "name": "amount",
          "type": "u64",
          "index": false
        },
        {
          "name": "liquidator",
          "type": "publicKey",
          "index": false
        }
      ]
    }
  ],
  "errors": [
    {
      "code": 6000,
      "name": "CollectionNotFound"
    },
    {
      "code": 6001,
      "name": "TokenNotFound"
    },
    {
      "code": 6002,
      "name": "TreasuryNoPermisson"
    },
    {
      "code": 6003,
      "name": "InvalidTicketAmount"
    },
    {
      "code": 6004,
      "name": "InvalidLiquidation"
    },
    {
      "code": 6005,
      "name": "NoMatchMetadata"
    },
    {
      "code": 6006,
      "name": "NoMatchCreator"
    },
    {
      "code": 6007,
      "name": "NoVerifiedCreator"
    },
    {
      "code": 6008,
      "name": "NoFoundNft"
    },
    {
      "code": 6009,
      "name": "NoPermission"
    },
    {
      "code": 6010,
      "name": "NumericalOverflowError"
    },
    {
      "code": 6011,
      "name": "NotEnoughLiquidate"
    },
    {
      "code": 6012,
      "name": "EndedRaffle"
    },
    {
      "code": 6013,
      "name": "NoClaimableRevenue"
    },
    {
      "code": 6014,
      "name": "InvalidRN"
    },
    {
      "code": 6015,
      "name": "LiquidationInDraw"
    },
    {
      "code": 6016,
      "name": "LiquidationUsed"
    },
    {
      "code": 6017,
      "name": "NotSoldOut"
    },
    {
      "code": 6018,
      "name": "InsufficientFundsForTransaction"
    },
    {
      "code": 6019,
      "name": "TicketOverBuyLimit"
    },
    {
      "code": 6020,
      "name": "InvalidTicketNumber"
    },
    {
      "code": 6021,
      "name": "InsufficientLockedNfts"
    },
    {
      "code": 6022,
      "name": "MaxLiquidationReached"
    }
  ]
};
