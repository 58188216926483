import styled from "styled-components"
import { deviceMax } from "styles/media"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"

export const Shares = styled.div`

    @media ${deviceMax.md}{
        padding-top:8px;
    }

`

export const Header = styled.div`
    display:flex;
    align-items:center;
    margin-bottom:16px;
    margin-top:16px;
    padding:0 20px;

    @media ${deviceMax.lg}{
        align-items:flex-start;
    }

    @media ${deviceMax.md}{
        flex-direction:column;
        padding:0 16px;
        align-items:center;
    }
`

export const HeaderLeft = styled.div`
    width:50%;
    display:flex;

    @media ${deviceMax.md}{
        width:100%;
        justify-content:center;
    }
`


export const Tabs = styled.div`
    display:flex;
    align-items:center;
    background-color:${color.bg2};
    border-radius:64px;
    
    @media ${deviceMax.md}{
        width:100%;
    }
`

export const TabItem = styled.div<{isactive?:string}>`
    position:relative;
    cursor:pointer;
    color:${color.primary};
    font-size:14px;
    min-width:88px;
    line-height:125%;
    display:flex;
    align-items:center;
    justify-content:center;
    padding: 8px 20px;
    user-select:none;
    border-radius:64px;

    ${props => props.isactive === "true" && `
        background-color:${color.secondary};
        color:${color.white};

        &::after{
            content: "";
            z-index:1;
            position: absolute;
            inset: 0;
            border-radius: 24px; 
            padding: 1px; 
            background:linear-gradient(45deg,${color.gradient1.start},${color.gradient1.middle},${color.gradient1.end}); 
            -webkit-mask: 
            linear-gradient(#fff 0 0) content-box, 
            linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
                    mask-composite: exclude; 
        }
    `}
    transition: all .1s ease-in-out;
    ${props => props.isactive !== "true" && `
        &:hover{
            color:${color.white};
        }
    `}

    @media ${deviceMax.md}{
        width:50%;
    }
`

export const HeaderRight = styled.div`
    width:50%;
    display:flex;
    align-items:center;
    justify-content:flex-end;

    & a{
        height:32px;
        display:flex;
        align-items:center;
        justify-content:center;
        padding:0 16px;
    }

    @media ${deviceMax.lg}{
        flex-direction:column;
        align-items:flex-end;

        & a{
           margin-top:8px;
        }
    }

    @media ${deviceMax.md}{
        flex-direction:column;

        & a{
            width:100%;
            margin-top:0px;
        }
        width:100%;
    }
`

export const Search = styled.div`
    min-width:220px;
    margin-right:16px;
    & input{
        border-radius:64px;
        height:32px;
    }

    @media ${deviceMax.lg}{
        margin-right:0px;
    }
    @media ${deviceMax.md}{
        margin-right:0px;
        width:100%;

        & input{
            width:100%;
            margin-top:24px;
            margin-bottom:16px;
        }
    }
`

export const Content = styled.div`
    width:100%;
`

export const TableContainer = styled.div`
    width:100%;
    position:relative;
`

export const Loading = styled.div`
    position:absolute;
    inset:0;
    display:flex;
    align-items:center;
    justify-content:center;
    background-color:${Hex2Rgba(color.bg1,0.64)};
    width:100%;
    height:100%;
    font-size:24px;
    z-index:1;
`

export const Table = styled.table`
    width:100%;
    border-collapse: collapse;
    position: relative;
`

export const TableHead = styled.thead`
    background-color:${color.bg2};
    & th{
        background-color:${color.bg2};
        border:none !important;
    }
    
    
`

export const TableRow = styled.tr`
   
`

export const TableHeadItem = styled.th<{ttype:string}>`
    & > div{
        justify-content:flex-end;
    }
    border:none !important;
    font-size:14px;
    font-weight:400;
    padding:12px 0;
    position:sticky;
    top:-1px;
    cursor:pointer;
    ${props => (props.ttype === "profile" || props.ttype === "price" || props.ttype === "change" || props.ttype === "volume") && `
    & > div{
        justify-content:flex-start;
    }
    `}

    &:first-child{
        padding-left:40px;
    }
    &:last-child{
        padding-right:40px;
    }

    ${props => props.ttype === "profile" && `width:20%;`}
    ${props => props.ttype === "price" && `width:15%;`}
    ${props => props.ttype === "change" && `width:16%;`}
    ${props => props.ttype === "volume" && `width:10%;`}
    ${props => props.ttype === "holders" && `width:10%;`}
    ${props => props.ttype === "shares-issued" && `width:16%;`}
    ${props => props.ttype === "tvl" && `width:13%;`}

    @media ${deviceMax.lg}{
        font-size:12px;
        &:first-child{
            padding-left:12px;
        }
        &:last-child{
            padding-right:12px;
        }
    }
    @media ${deviceMax.md}{
        display:none;
        ${props => (props.ttype === "profile" || props.ttype === "price" || props.ttype === "change") && `
            display:table-cell;
        `}
        ${props => (props.ttype === "profile") && `
            & > div{
                justify-content:flex-start;
            }
            width:40%;
        `}
        ${props => (props.ttype === "price") && `
        & > div{
            justify-content:center;
        }
            width:25%;
        `}
        ${props => (props.ttype === "change") && `
        & > div{
            justify-content:flex-end;
        }
            padding-right:12px;
            width:35%;
        `}
        &:first-child{
            padding-left:12px;
        }
        &:last-child{
            padding-right:12px;
        }
        font-size:12px;
    }
    
`

export const TableBodyItem = styled.td<{ttype:string}>`
    & img{
        height:24px;
        width:24px;
        border-radius:50%;
    }
    vertical-align:middle;

    text-align:right;
    padding:18px 0;
    background-color:${Hex2Rgba(color.bg1,0.64)};

    ${props => (props.ttype !== "profile" && props.ttype !== "price") && `
        color:${color.whiteShade};
    `}

    ${props => (props.ttype === "profile" || props.ttype === "price" || props.ttype === "change" || props.ttype === "volume") && `
        text-align:left;
    `}

    &:first-child{
        padding-left:40px;
    }
    &:last-child{
        padding-right:40px;
    }

    @media ${deviceMax.lg}{
        font-size:12px;
        &:first-child{
            padding-left:12px;
        }
        &:last-child{
            padding-right:12px;
        }
    }

    @media ${deviceMax.md}{
        display:none;
        ${props => (props.ttype === "profile" || props.ttype === "price" || props.ttype === "change") && `
            display:table-cell;
        `}
        ${props => (props.ttype === "profile") && `
            text-align:left;
        `}
        ${props => (props.ttype === "price") && `
            text-align:center;
        `}
        ${props => (props.ttype === "change") && `
            text-align:right;
            padding-right:12px;
        `}
        &:first-child{
            padding-left:12px;
        }
        &:last-child{
            padding-right:12px;
        }
        font-size:14px;
    }
`

export const TableBody = styled.tbody`
    & tr:not(:last-child){
        border-bottom:1px solid ${color.purpleShade1};
    }
    & tr{
        cursor:pointer;
    }
    & tr:hover{
        background-color:${Hex2Rgba(color.secondaryFade,.48)};
    }
`

export const Profile = styled.div`
    display:flex;
    align-items:center;
    justify-content:flex-start;

    & img{
        margin-right:4px;
    }
`
export const Change = styled.div<{ispositive?:string}>`
    color:${props => props.ispositive === "null" ? color.white : props.ispositive === "true" ? color.shareGreen : color.shareRed};

    & span{
        width:11px;
        display:inline-block;
        margin-right:2px;
        text-align:right;
    }
`

export const TableHeadText = styled.div`
    display:flex;
    align-items:center;
`


export const SortIcon = styled.div<{isactive?:string}>`
    ${props => props.isactive === "true" && `
        & svg{    
            color:${color.gradient1.start};
        }
    `}
    display:flex;
`

export const Sort = styled.div<{isactive?:string}>`
    display:flex;
    flex-direction:column;
    margin-left:8px;
    color:${color.secondaryFade};
    & svg{
        height:6px;
    }

    ${props => props.isactive === "false" && `
        & svg{    
            color:${color.secondaryFade} !important;
        }
    `}
    & >*:first-child{
        margin-bottom:2px;
    }
`

export const Pagination = styled.div`
    display:flex;
    align-items:center;
    justify-content:flex-end;
    margin-right:24px;
    margin-top:24px;
    
    @media ${deviceMax.md}{
        margin-right:0px;
        justify-content:center;
    }
`

export const PaginationBox = styled.div`
    background-color:${color.bg2};
    border-radius:8px;
    overflow:hidden;
    display:flex;
    align-items:center;
`

export const PaginationItem = styled.div<{isactive?:string,isdisabled?:string}>`
    padding:6px 12px;
    font-size:14px;
    display:flex;
    align-items:center;
    height:33px;
    ${props => props.isactive === "true" && `
        background-color:${color.secondary};
    `}
    ${props => props.isdisabled !== "true" && `
        cursor:pointer;
        &:hover{
            background-color:${Hex2Rgba(color.secondaryFade,.48)};
        }
    `}
    ${props => props.isdisabled === "true" && `
        cursor:default;
    `}
`

export const PaginationDots = styled.div`
    padding:6px 10px;
    font-size:14px;
    cursor:default;
    height:33px;
`