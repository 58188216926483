import { DropdownButton } from "components/ui/button/dropdown/style"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"

export const EditProfile = styled.div`
    width: 100%;
    position: relative;
`

export const Cover = styled.div`
    display:flex;
    align-items:center;
    flex-direction:column;
    margin-bottom:10px;

    & ${DropdownButton}{
        position:absolute;
        top:10px;
        right:24px;

        & > button{
            font-size:12px;
            padding:5px 10px;
            /*box-shadow:0 0 5px ${Hex2Rgba(color.white, .3)};*/
        }
    }

    & input[type="file"]{
        display:none;
    }
`

export const CoverBg = styled.label<{bg : string, tab:number}>`

    width: 100%;
    height: 90px;
    margin-bottom:50px;
    transition: all 0.3s ease;

    ${props => props.bg && `
        background-image:url(${props.bg});
        background-size:cover;
        background-position:center;
    `}

    ${props => !props.bg && `
        background-color:${Hex2Rgba(color.white, 0.2)};
    `}

    ${props => props.tab === 0 && `
        cursor: pointer;
        &:hover {
            /*background-color:${Hex2Rgba(color.white, 0.1)};*/
            filter: brightness(0.75);
        }
    `}
`

export const AvatarImg = styled.label``
export const Avatar = styled.div<{tab:number}>`
    & img {
        width: 125px;
        height: 125px;
        border-radius: 50%;
        position: relative;
    }

    & label{
        position:relative;
        width: 125px;
        height: 125px;
        display:flex;
        justify-content:center;
        align-items:center;
        transition: all 0.3s ease;
        ${props => props.tab === 0 && `
            cursor: pointer;
            &:hover {
                filter: brightness(0.75);
            }
        `}
    }

    & label::after{
        content: "";
        
        position: absolute;
        inset: 0;
        border-radius: 50%; 
        padding: 2px; 
        background:linear-gradient(45deg,${Hex2Rgba(color.gradient1.start, 1)},${Hex2Rgba(color.gradient1.middle, 1)},${Hex2Rgba(color.gradient1.end, 1)}); 
        -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
                mask-composite: exclude; 
    }
    
    position: absolute;
    top:20px;
`

export const Form = styled.form`
    padding-left:24px;
    padding-right:24px;
`

export const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    & > label {
        font-size: 13px;
        margin-bottom: 5px;
    }

    & small {
        font-size: 11px;
        color: ${color.gray};
    }

    & p {
        font-size: 14px;
        color: ${color.gray};
        margin:0;
        padding-top:5px;
    }

    & input{
        padding:12px;
    }
`

export const Actions = styled.div`
    display:flex;

    & > button {
        flex:1;
    }
`

export const BadgesList = styled.div`
display:flex;
    flex-wrap:wrap;

    & > div{
        margin-right:10px;
        margin-bottom:10px;
        cursor:pointer;

        opacity:0.4;

        &:hover{
            opacity:.75;
        }

        user-select:none;
    }

    & > .a{
        opacity:1 !important;
    }
`

export const TabsContainer = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding:0 24px;
    margin:16px 0;
`

export const Tabs = styled.div`
    display:flex;
    align-items:center;
    background-color:${color.bg2};
    border-radius:64px;
    
`

export const TabItem = styled.div<{isactive?:string}>`
    position:relative;
    cursor:pointer;
    color:${color.primary};
    font-size:14px;
    line-height:125%;
    display:flex;
    align-items:center;
    justify-content:center;
    padding: 8px 20px;
    user-select:none;
    border-radius:64px;

    ${props => props.isactive === "true" && `
        background-color:${color.secondary};
        color:${color.white};

        &::after{
            content: "";
            z-index:1;
            position: absolute;
            inset: 0;
            border-radius: 24px; 
            padding: 1px; 
            background:linear-gradient(45deg,${color.gradient1.start},${color.gradient1.middle},${color.gradient1.end}); 
            -webkit-mask: 
            linear-gradient(#fff 0 0) content-box, 
            linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
                    mask-composite: exclude; 
        }
    `}
    transition: all .1s ease-in-out;
    ${props => props.isactive !== "true" && `
        &:hover{
            color:${color.white};
        }
    `}
`


export const ProfileLink = styled(Link)`
    color:${color.primary};
    text-decoration:none;
    font-size:14px;

    &:hover{
        text-decoration:underline;
    }

    
`

export const Username = styled.div`
    text-align:center;
    margin-top:16px;
    font-size:18px;
    font-weight:600;
`