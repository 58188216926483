import React, { useEffect, useState } from "react"
import * as C from "./style"

import { useDispatch, useSelector } from "react-redux"
import Button from "components/ui/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import DropdownButton, { DropdownItem } from "components/ui/button/dropdown"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { faEllipsisVertical, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { ParseAwardAddress } from "modules/solana/shuffle/helpers"
import { LAMPORTS_PER_SOL } from "@solana/web3.js"
import { withdrawLiquidityNative, withdrawLiquidityNft, withdrawLiquidityToken } from "modules/solana/shuffle/liquidity"
import toast from "react-hot-toast"
import { RefreshLiquidations } from "services/managers/shuffle"
import { showShuffleLiquidityNftPopup } from "services/slices/popup"
import { Col } from "styles"
import { getAllNftsByMintList } from "modules/solana/nft"
import axios from "axios"
import { signAllAndSend } from "modules/solana/connection"
import { getShuffleError } from "modules/solana/shuffle/shuffleErrors"

const ShuffleLiquidityNft = () => {

    const shuffleLedger = useSelector((state: any) => state.shuffle.loadedLedger)
    const nftViewAddress = useSelector((state: any) => state.shuffle.nftViewAddress)
    const programId = useSelector((state: any) => state.shuffle.programId)
    const treasury = useSelector((state: any) => state.shuffle.treasury)
    const vault = useSelector((state: any) => state.shuffle.vault)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const [liquidations, setLiquidations] = useState<any>([])
    const [selectedNfts, setSelectedNfts] = useState<any>([])
    const [actionLoading, setActionLoading] = useState<boolean>(false)

    useEffect(() => {

        if (shuffleLedger.account.liquidations.filter((item: any) => item.address === nftViewAddress).length === 0) {
            dispatch(showShuffleLiquidityNftPopup(false))
        }else{
            loadLiqs()
        }

    }, [shuffleLedger])

    const loadLiqs = async () => {

        setLoading(true)

        let liqs = shuffleLedger.account.liquidations.filter((item: any) => item.address === nftViewAddress)
        let liqsParsed : any = []

        for (let i = 0; i < liqs.length; i++) {
            let liq = liqs[i]
            liqsParsed.push({
                address: liq.address,
                amount: liq.amount,
                mint: liq.mint,
                time: liq.time,
                data:{}
            })
        }

        let mints = liqs.map((item: any) => item.mint)

        getAllNftsByMintList(mints).then(async (nfts: any) => {
            for (let i = 0; i < liqsParsed.length; i++) {
                for (let j = 0; j < nfts.length; j++) {
                    if (nfts[i].mintAddress.toBase58() === liqsParsed[j].mint) {
                        let metadata = await axios.get(nfts[i].uri)
                        liqsParsed[i].data.name = metadata.data.name
                        liqsParsed[i].data.image = metadata.data.image
                        liqsParsed[i].metadata = nfts[i].address.toBase58()
                        break;
                    }
                }
            }
            setLoading(false)
            setLiquidations(liqsParsed)
        }).catch((err: any) => {
            setLoading(false)
            toast.error("Error loading NFTs")
        })

    }

    const selectNft = (mint: string) => {
        let _selectedNfts = selectedNfts
        let index = _selectedNfts.findIndex((nftItem: any) => nftItem === mint)
        if (index > -1) {
            _selectedNfts.splice(index, 1)
        } else {
            _selectedNfts.push(mint)
        }
        setSelectedNfts([..._selectedNfts])
    }

    const withdrawSelectedNfts = async () => {

        if (selectedNfts.length === 0) {
            toast.error("No NFTs Selected")
            return
        }

        setActionLoading(true)

        let txs = []

        for (let i = 0; i < selectedNfts.length; i++) {
            let nft = liquidations.find((nftItem: any) => nftItem.mint === selectedNfts[i])
            let ix = await withdrawLiquidityNft(programId, treasury, vault, shuffleLedger.publicKey, nft.mint, nft.metadata)
            txs.push(ix)
        }

        signAllAndSend(txs, "Withdrawing...").then((res: any) => {
            setActionLoading(false)
            RefreshLiquidations()

        }).catch((e) => {
            if (typeof e === "string")
                toast.error(e)
            else if (e.message && e.message.indexOf("Attempt to debit an account but found no record of a prior credit.") !== -1) {
                toast.error(getShuffleError("0x1"))
            }else if (e.InstructionError && e.InstructionError[1] && e.InstructionError[1].Custom) {
                let errorMessage = getShuffleError(e.InstructionError[1].Custom)
                if (!errorMessage)
                    errorMessage = "Error Withdrawing"
                toast.error(errorMessage)
            } else {
                toast.error("Error Withdrawing NFTs")
            }
            setActionLoading(false)
        })

    }

    if (loading)
        return (
            <C.Loading>
                <FontAwesomeIcon icon={faSpinner} spin />
            </C.Loading>
        )

    return (
        <C.ShuffleLiquidityNft>
            <C.Nfts>
                {liquidations.map((nft: any, index: number) => (
                    <Col col="3" key={index}>
                        <C.Nft image={nft.data.image} isSelected={selectedNfts.includes(nft.mint) ? "true" : "false"} onClick={() => selectNft(nft.mint)}>
                            <C.NftInfo>
                                {nft.data.name}
                            </C.NftInfo>
                            <C.NftActiveMark></C.NftActiveMark>
                        </C.Nft>
                    </Col>
                ))}
            </C.Nfts>

            <C.NftWithdrawActions>
                <Button theme="gradient-border-transparent-bg-on-hover" color={color.white} bg={Hex2Rgba(color.secondaryFade, 1)} onClick={() => setSelectedNfts([])}>Clear Selection</Button>
                <Button theme="gradient" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)} onClick={() => withdrawSelectedNfts()} loading={actionLoading ? "true" : "false"} disabled={actionLoading || selectedNfts.length === 0}>
                    Withdraw Selected {selectedNfts.length > 0 && (<>"{selectedNfts.length}"</>)} NFTs
                </Button>
            </C.NftWithdrawActions>
        </C.ShuffleLiquidityNft>
    )

}

export default ShuffleLiquidityNft