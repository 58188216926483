import styled, { keyframes } from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { deviceMax, deviceMin } from "styles/media"
import { Link } from "react-router-dom"

export const ShuffleTickets = styled.div`
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
`

export const Ticket = styled.div`
    width: 100px;
    font-size: 14px;
    color: ${color.whiteShade};
`