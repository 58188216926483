import styled from "styled-components"

export const CurrentCollection = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-left:4px;
`
export const Header = styled.div`
    
    position:relative;
`
export const Times = styled.div`
    cursor:pointer;
    padding-left:15px;
    padding-bottom:5px;
    position:absolute;
    right:0px;
    top:0px;
`
export const Title = styled.div`
    font-size:14px;
    user-select: none;
    cursor: default;
`

export const Item = styled.div`
    padding-top:10px;
    height:100%;
    display:flex;
    align-items:center;
    
    & > div{
        width:100%;
    }
`