import { faDiscord, faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faExternalLink, faSpinner, faUpRightAndDownLeftFromCenter } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Badge from "components/ui/badge"
import Button from "components/ui/button"
import env from "env"
import React, { useEffect, useState } from "react"
import toast from "react-hot-toast"
import ReactTooltip from "react-tooltip"
import { getHolder } from "services/api/holders"
import { store } from "services/store"
import { color } from "styles/theme"
import { GetRandomGif, GetTwitterHandle, Hex2Rgba, getAvatar, toLink } from "utils/helpers"
import { displayHolderProfile } from "utils/parts"
import * as C from "./style"
import { history } from 'components/etc/history'
import { Recruit } from "models/enums/recruit"
import Linkify from 'react-linkify'
import { _RecruitTwitterNomination } from "models/interfaces/recruitTypes"
import TreasureMap from "../treasureMap"
import BigNumber from "bignumber.js"
import { LAMPORTS_PER_SOL } from "@solana/web3.js"
import { formatPrice } from "modules/solana/share/helpers"

const ProfileMap = (props: any) => {

    const [user, setUser] = useState<any>(null)
    const [treasure, setTreasure] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<boolean>(false)
    const currentWhitelist = store.getState().whitelist.currentWhitelist
    const wlentry = store.getState().whitelist.entry

    useEffect(() => {
        getHolder(props.user.id).then((res: any) => {
            if (res.data.user === null && res.data.treasure) {
                setTreasure(res.data.treasure)
            }
            else
                setUser(res.data.user)
            setLoading(false)
        }).catch((err: any) => {
            toast.error("Error loading user data")
            setLoading(false)
            setError(true)
        })
    }, [])

    useEffect(() => {
        ReactTooltip.rebuild()
        ReactTooltip.hide()
    }, [user])

    const copyDiscord = () => {

        if (user.discord) {
            navigator.clipboard.writeText(user.discord)
            toast.success("Copied to clipboard")
        }
    }

    const expand = () => {
        //displayHolderProfile(user)
        history.push(`/app/profile/${user.id}`)
    }

    const buyShare = () => {
        //displayHolderProfile(user)
        history.push(`/app/share/${user.id}`)
    }

    const AskForNomination = () => {

        if (!user.twitter) {
            toast.error("User has no twitter account linked")
            return
        }

        let item = store.getState().whitelist.currentWhitelist

        const tweetText = `Hey @${user.twitter}%0aPlease kindly ${(item.recruitDetails as _RecruitTwitterNomination).nominationWord} me for%0a${item.name} whitelist${GetTwitterHandle(item.twitter)}%0a%0a${env.API.WEB}app/whitelist/${toLink(item.name)}%0a${GetRandomGif(item)}`
        const tweetUrl = `https://twitter.com/intent/tweet?text=${tweetText}`
        if (store.getState().data.isMobile)
            window.location.href = tweetUrl
        else
            window.open(tweetUrl, '_blank')

    }

    const isUserMe = () => {
        if (!store.getState().user.user)
            return false
        return store.getState().user.user!.id === user.id
    }

    if (loading)
        return (<C.Loading><FontAwesomeIcon icon={faSpinner} spin /></C.Loading>)

    if (error)
        return (<C.Loading>Failed to load user data</C.Loading>)

    if (treasure)
        return <TreasureMap treasure={treasure} />

    return (
        <C.ProfileMap>
            <C.User>

                <C.UserTop bg={user.cover ? env.API.CDN + "covers/" + user.cover : ""}>
                    <C.UserTopLeft>
                        <C.Avatar>
                            <img src={getAvatar(user.avatar)} alt="avatar" />
                        </C.Avatar>
                        <C.UserName>
                            <C.Name>{user.username}</C.Name>
                            {user.status && (
                                <C.Status> {user.status}</C.Status>
                            )}
                        </C.UserName>
                    </C.UserTopLeft>
                    <C.Social>
                        {user.twitter && (
                            <a data-tip="Go to Twitter Account" href={"https://twitter.com/" + user.twitter} target="_blank" >
                                <FontAwesomeIcon icon={faTwitter} />
                            </a>
                        )}
                        {user.discord && (
                            <div onClick={copyDiscord} data-tip="Copy to clipboard">
                                <FontAwesomeIcon icon={faDiscord} />
                            </div>
                        )}
                    </C.Social>
                </C.UserTop>
                <C.SubTop>
                    <C.Badges>
                        {user.badges.map((badge: any, index: any) => (
                            <Badge type={badge} key={index} />
                        ))}
                    </C.Badges>
                </C.SubTop>
                <C.Info>

                    <C.Description>
                        <Linkify
                            componentDecorator={(decoratedHref, decoratedText, key) => (
                                <a className="extlink" target="blank" href={decoratedHref} key={key}>
                                    {decoratedText} <FontAwesomeIcon style={{ fontSize: 10 }} icon={faExternalLink} />
                                </a>
                            )}
                        >
                            {user.description}
                        </Linkify>
                    </C.Description>
                </C.Info>
            </C.User>

            <C.Expand>
                {/*<Button onClick={expand} block="true" theme="gradient-border-transparent-bg-on-hover" bg={color.secondary} color={color.white}>
                    Expand NFT's <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                            </Button>*/}
                <C.Share>
                    <Button block="true" onClick={buyShare} theme="gradient" bg={Hex2Rgba(color.white, .05)} color={color.white}>
                        Buy a Share
                    </Button>
                    <C.ShareDetails>
                        <C.ShareDetailsItem>
                            <span>Share Price:</span> {formatPrice(new BigNumber(user.share.price).div(LAMPORTS_PER_SOL))} <img src="/images/chains/solana-icon.png" alt="solana" />
                        </C.ShareDetailsItem>
                        {/*<C.ShareDetailsItemSeperator />
                        <C.ShareDetailsItem>
                            <span>Sell:</span> 0.50 <img src="/images/chains/solana-icon.png" alt="solana" />
                        </C.ShareDetailsItem>*/}
                    </C.ShareDetails>
                </C.Share>

                {(currentWhitelist && currentWhitelist.recruitType === Recruit.TwitterNomination && props.user.whitelisted && !isUserMe() && !wlentry) && (
                    <Button onClick={AskForNomination} block="true" theme="gradient" fromcolor={color.gradient3.start} tocolor={color.gradient3.end} color={color.black} bg={Hex2Rgba(color.secondaryFade, .5)} style={{ fontWeight: "600" }}>
                        Ask for nominate
                    </Button>
                )}
            </C.Expand>

        </C.ProfileMap>
    )
}

export default ProfileMap