import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { deviceMax } from "styles/media"
import { Row } from "styles"

export const ShuffleLiquidityDepositPopup = styled.div``

export const LiquidityTypeSelection = styled.div`

`

export const Loading = styled.div`
    font-size:18px;
    display:flex;
    align-items:center;
    justify-content:center;
    height:100%;
    margin: 32px 0;
`

export const Header = styled.div`
`

export const LiquidityType = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${Hex2Rgba(color.black, 0.64)};
    border-radius: 8px;
    padding: 16px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    border: 1px solid ${Hex2Rgba(color.black, 0.64)};

    &:not(:last-child) {
        margin-bottom: 16px;
    }

    &:hover {
        background: ${Hex2Rgba(color.black, 0.8)};
        border: 1px solid ${color.primary};
    }
`

export const LiquidityTypeInfo = styled.div`

`

export const LiquidityTypeTitle = styled.div`
    background: linear-gradient(90deg, ${color.gradient1.start}, ${color.gradient1.middle}, ${color.gradient1.end});
    font-weight:500;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`

export const LiquidityTypeDescription = styled.div`
    color: ${color.whiteShade};
    font-size: 14px;
`

export const LiquidityTypeAction = styled.div`

`

export const LiquidityDepositNft = styled.div`

`

export const LiquidityDepositToken = styled.div`

`

export const LiquidityDepositTokenInfo = styled.div`
    text-align: center;
    margin-bottom: 24px;
`

export const LiquidityDepositTokenTitle = styled.div`
    font-size:20px;
    font-weight:500;
`

export const LiquidityDepositTokenDescription = styled.div`
    color: ${color.whiteShade};
`

export const LiquidityDepositTokenAmountSelection = styled.div`

`

export const LiquidityDepositTokenAmountSelectionTitle = styled.div`
    font-weight: 500;
`

export const LiquidityDepositTokenAmountSelectionInput = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    &::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 8px; 
        padding: 1px; 
        background:linear-gradient(45deg,${color.primary},${color.secondary}); 
        -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude; 
    }
    padding:16px;
    padding-bottom: 0;
`

export const LiquidityDepositTokenAmountSelectionInputItem = styled.div<{ active: string }>`
    background: ${Hex2Rgba(color.secondary, 0.16)};
    padding: 4px 8px;
    font-size: 14px;
    border-radius: 24px;
    position: relative;
    z-index: 1;
    cursor: pointer;

    &:not(:last-child) {
        margin-right: 8px;
    }

    margin-bottom: 16px;


    &:hover {
        background:linear-gradient(90deg,${Hex2Rgba(color.gradient2.start, 1)}, ${Hex2Rgba(color.gradient2.middle, 1)}, ${Hex2Rgba(color.gradient2.end, 1)} );  
    }

    ${props => props.active === "true" && ` 
        background:linear-gradient(90deg,${Hex2Rgba(color.gradient2.start, 1)}, ${Hex2Rgba(color.gradient2.middle, 1)}, ${Hex2Rgba(color.gradient2.end, 1)} );
    `}

`

export const LiquidityDepositTokenAction = styled.div`

    margin-top:32px;

    display:flex;

    & > *{
        flex:1;
        padding:0px 8px;
    }

    & > *:not(:last-child){
        margin-right:8px;
    }

    & button{
        font-size:18px;
        line-height:1.2;
    }
`

export const LiquidityDepositTokenCount = styled.div`
    display:flex;
    background:${Hex2Rgba(color.secondaryFade, .64)};
    border-radius:24px;
    align-items:center;
`

export const LiquidityDepositTokenCountButton = styled.div`
    cursor:pointer;
    height:100%;
    display:flex;
    align-items:center;
    font-size:18px;
    flex:1;
    justify-content:center;
    user-select:none;
`

export const LiquidityDepositTokenCountInputBox = styled.div`
    display:flex;
    flex:2;
`

export const LiquidityDepositTokenCountInput = styled.input`
    border:none;
    padding:12px 16px;
    color:${color.white};
    font-size:16px;
    font-weight:500;
    width:100%;
    background:transparent;
    text-align:center;
    outline:none;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    -moz-appearance: textfield;
`

export const LiquidityDepositTokenTotal = styled.div`
    font-size:13px;
`

export const Collections = styled.div`
    display:flex;
    margin:16px 0;
`

export const Collection = styled.div<{active:string}>`
    position: relative;
    display: flex;
    padding: 6px 12px;
    font-size: 14px;
    cursor: pointer;
    transition: all .1s ease-in-out;
    ${props => props.active === 'true' && `
        background:${Hex2Rgba(color.secondary, 1)};
        &::before {
            content: "";
            position: absolute;
            inset: 0;
            border-radius: 24px; 
            padding: 2px; 
            background:linear-gradient(45deg,${Hex2Rgba(color.gradient2.start, 1)}, ${Hex2Rgba(color.gradient2.middle, 1)}, ${Hex2Rgba(color.gradient2.end, 1)}); 
            -webkit-mask: 
            linear-gradient(#fff 0 0) content-box, 
            linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
            mask-composite: exclude; 
        }
    `}

    &:hover {
        opacity: .8;
    }

    background: ${Hex2Rgba(color.secondaryFade, 1)};
    margin-right: 8px;
    border-radius: 24px;
`

export const Nfts = styled(Row)`

`

export const NftInfo = styled.div`
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    padding:4px;
    background:${Hex2Rgba(color.bg1, .64)};
    transition:all .1s ease-in-out;
    font-size:12px;
    text-align:center;
`

export const NftActiveMark = styled.div``

export const Nft = styled.div<{image:string, selected?:string}>`
    background-image:url(${props => props.image});
    background-size:cover;
    background-position:center center;
    background-repeat:no-repeat;
    height:155px;
    border-radius: 8px;
    position:relative;
    margin-bottom:30px;
    cursor:pointer;
    transition:all .1s ease-in-out;
    overflow:hidden;
    &:hover{
        & ${NftInfo}{
            background:${Hex2Rgba(color.bg1, .84)};
        }

        opacity:.8;
    }   

    ${props => props.selected === 'true' && `
        

        & ${NftActiveMark}{
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            background:${Hex2Rgba(color.secondary, .32)};
            border: 2px solid ${color.secondary};
            border-radius: 8px;
        }
    `}
`



export const NftDepositActions = styled.div`
    position:sticky;
    bottom:0;
    width:100%;
    display:flex;
    align-items:center;
    
    & button{
        flex:1;
    }

    & button:not(:last-child){
        margin-right:8px;
    }
    
`

export const LiquidityDepositTokenAmountLimit = styled.div`
    font-size:14px;
    margin-top:16px;
    color:${color.whiteShade};
`