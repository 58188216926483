import { Connection, Keypair, PublicKey, SystemProgram, Transaction, sendAndConfirmTransaction } from "@solana/web3.js";
import { SOLANA_CONNECTION_SHARE } from "./connection";

export const sendSol = async (fromKeypair: Keypair, toAddress: string, amount: any) => {

    const toPublicKey = new PublicKey(toAddress)
    let tx = new Transaction().add(
        SystemProgram.transfer({
            fromPubkey: fromKeypair.publicKey,
            toPubkey: toPublicKey,
            lamports: amount,
        })
    )

    tx.feePayer = fromKeypair.publicKey
    tx.recentBlockhash = (await SOLANA_CONNECTION_SHARE.getLatestBlockhash("finalized")).blockhash

    let sig = await sendAndConfirmTransaction(SOLANA_CONNECTION_SHARE, tx, [fromKeypair])
    return sig

}