import { Button } from "components/ui/button/style"
import { Input, Textarea } from "components/ui/input/style"
import styled from "styled-components"
import { Col, Row } from "styles"
import { deviceMax, deviceMin } from "styles/media"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"


export const ProfileInfo = styled.div<{bg : string}>`
    display: flex;
    justify-content: space-between;
    margin-bottom:20px;
    position:relative;
    padding-left:20px;
    padding-right:20px;
    padding-top:56px;
    padding-bottom:10px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    

    ${props => props.bg && `
        background-image:url(${props.bg});
        background-size:cover;
        background-position:center;
    `}

    ${props => !props.bg && `
    `}

    @media ${deviceMax.md} {
        padding-left:10px;
        padding-right:10px;
        padding-top:30px;

        border-radius:8px;
    }
`

export const ProfileMainStats = styled.div`
    display: flex;
    flex:3;
`

export const ProfileImage = styled.div`
    
    margin-right:20px;
    
    width:150px;
    height:150px;

    position:absolute;

    & img {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        position: relative;
        z-index: 1;
    }

    &::after {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 50%; 
        padding: 2px;
        transform: scale(1.02);
        background:linear-gradient(45deg,${Hex2Rgba(color.gradient1.start, 1)},${Hex2Rgba(color.gradient1.middle, 1)},${Hex2Rgba(color.gradient1.end, 1)}); 
        -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
                mask-composite: exclude; 
    }

    @media ${deviceMax.lg} {
        width:100px;
        height:100px;
        margin-right:10px;

        & img {
            width: 100px;
            height: 100px;
        }
    }
    @media ${deviceMax.sm} {
        width:64px;
        height:64px;
        margin-right:10px;

        & img {
            width: 64px;
            height: 64px;
        }
    }
`

export const ProfileNameDesc = styled.div`
    width:100%;
    padding-left:170px;

    @media ${deviceMax.lg} {
        padding-left:120px;
    }

    @media ${deviceMax.sm} {
        padding-left:74px;
    }
`

export const ProfileName = styled.div`
    font-size: 30px;
    text-shadow: 0 0 10px ${color.black};
    font-weight: 600;

    @media ${deviceMax.xl} {
        height:90px;
    }

    @media ${deviceMax.lg} {
        font-size: 24px;
    }

    @media ${deviceMax.md} {
        height:auto;
    }

    @media ${deviceMax.sm} {
        font-size: 24px;
    }

    @media ${deviceMax.xs} {
        font-size: 20px;
    }
`

export const ProfileDescription = styled.div<{mobile:string}>`
    font-size:14px;
    @media ${deviceMin.xl} {
        width:80%;
    }
    margin-bottom:16px;

    @media ${deviceMax.sm} {
        font-size:12px;
    }

    ${props => props.mobile === "true" && `
        display:none;
    `}

    @media ${deviceMax.md} {
        ${props => props.mobile === "true" && `
            display:block;
        `}

        ${props => props.mobile === "false" && `
            display:none;
        `}
    }


`

export const Badges = styled.div`
    display: flex;
    margin:10px 0px;
    min-height: 30px;
    align-items: center;

    @media ${deviceMax.sm} {
        min-height: auto;
    }
`

export const ProfileStatus = styled.div`

    background: ${Hex2Rgba(color.secondaryFade, .64)};
    color: ${color.white};
    padding: 5px 10px;
    border-radius: 62px;
    width:fit-content;
    margin:5px 0px;
`
export const ProfileStatusHeader = styled.div`
    font-size: 11px;
    line-height: 1.2;
    color: ${Hex2Rgba(color.white, .5)};
    font-weight: 300;
`
export const ProfileStatusContent = styled.div`
    font-size: 13px;
    font-weight: 300;
`

export const ProfileSocialAndActions = styled.div`
    flex:1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media ${deviceMax.md} {
        flex:unset;
    }
`

export const ProfileActions = styled.div`
    flex:1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-top:10px;

    & > button {
        &:not(:last-child) {
            margin-right: 10px;
        }
    }

    @media ${deviceMax.sm} {
        flex:unset;
        margin-top:0px;
        margin-left:10px;
    }

    @media ${deviceMax.md} {
        display:none;
    }
`

export const ProfileMobileActions = styled.div`

    display: none;

    @media ${deviceMax.md} {
        display: block;
    }

    margin: 4px 0px;

    width:100%;

    & > * {
        width:100%;
    }

    & > * > button {
        width:100%;
        padding: 6px 8px;
        background: ${Hex2Rgba(color.secondaryFade, .5)};
    }
`

export const ProfileSocial = styled.div`
    margin-top:10px;
    & > div:not(:last-child) {
        margin-bottom: 10px;
    }
    min-width:250px;
`

export const ProfileSocialItem = styled.div`
    display: flex;
    font-size: 14px;
    background: ${Hex2Rgba(color.secondaryFade, .5)};
    border-radius: 24px;
    overflow: hidden;
`

export const ProfileSocialItemIcon = styled.div<{hc : string}>`
    width:30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.hc};
    background: ${props => Hex2Rgba(color.white, .1)};
`

export const ProfileSocialItemText = styled.div`
    padding:5px 10px;
`

export const ProfileSocialSingle = styled.div`
    display: flex;
    align-items: center;
    
    justify-content: flex-end;

    @media ${deviceMax.md} {
        flex-direction: column;
    }
`

export const ProfileSocialSingleItem = styled.a`
    background-color: ${Hex2Rgba(color.secondaryFade, .64)};
    border-radius: 24px;
    padding: 5px 10px;
    &:not(:last-child) {
        margin-right: 10px;
    }
    cursor: pointer;

    @media ${deviceMax.md} {
        background-color: ${Hex2Rgba(color.secondaryFade, 1)};
        padding: 0px;
        &:not(:last-child) {
            margin-right: 0px;
        }
        margin-bottom: 5px;

        width: 36px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`

export const ProfileSocialSingleItemNoLink = styled.div`
    background-color: ${Hex2Rgba(color.secondaryFade, .64)};
    border-radius: 24px;
    padding: 5px 10px;
    &:not(:last-child) {
        margin-right: 10px;
    }
    cursor: pointer;
    @media ${deviceMax.md} {
        background-color: ${Hex2Rgba(color.secondaryFade, 1)};
        padding: 0px;
        &:not(:last-child) {
            margin-right: 0px;
        }
        margin-bottom: 5px;

        width: 36px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
`

export const ProfileSocialSingleItemIcon = styled.div<{hc : string}>`
    color: ${color.white};
    font-size: 22px;

    &:hover {
        color: ${props => props.hc};
    }

    @media ${deviceMax.md} {
        font-size: 14px;
    }
`


/*edit mode*/
export const ProfileEditInputGroup = styled.div`
    position: relative;

    & > label{
        position: absolute;
        top: 8px;
        left: 10px;
        font-size: 11px;
        line-height: 1.2;
        color: ${Hex2Rgba(color.white, .5)};
        font-weight: 300;
    }
    margin-bottom:5px;

    &>${Input},${Textarea}{
        padding: 20px 10px 10px 10px;
    }
`

export const ProfileInfoBottom = styled.div`
    padding-left:190px;
    padding-right:20px;

    @media ${deviceMax.xl} {
        margin-top: 60px;
        padding-left:20px;
        padding-right:20px;
    }

    @media ${deviceMax.lg} {
        margin-top: 30px;
        padding-left:20px;
        padding-right:20px;
    }

    @media ${deviceMax.md} {
        margin-top: 0px;
        padding-left:0px;
        padding-right:0px;
    }
`

export const ProfileInfoBottomTop = styled.div`
    display: flex;
    justify-content: space-between;

    @media ${deviceMax.md} {
        flex-direction: column;
        margin-bottom: 20px;
    }
`

export const OwnedTokensButton = styled.div`
position:relative;
    display:flex;
    background:${color.bg2};
    border-radius:62px;
    align-items:center;
    padding:8px 16px;
    cursor:pointer;
    transition:all .1s ease-in-out;

    &:hover{
        &::after {
            content: "";
            position: absolute;
            inset: 0;
            border-radius: 62px; 
            padding: 2px;
            transform: scale(1.02);
            background:linear-gradient(45deg,${color.primary+","+color.secondary}); 
            -webkit-mask: 
            linear-gradient(#fff 0 0) content-box, 
            linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
                    mask-composite: exclude; 
        }

        box-shadow:0px 8px 12px ${Hex2Rgba(color.secondaryFade, .5)};
    }

    @media ${deviceMax.md} {
        justify-content: space-between;
    }
`

export const OwnedTokensContent = styled.div`
    width:150%;
    background:${Hex2Rgba(color.secondary, .16)};
    backdrop-filter: blur(20px);
    padding:8px;
    border-radius:8px;
    position:absolute;
    right:0px;
    top:50px;
    z-index:1;
    display:none;

    @media ${deviceMax.md} {
        width:100%;
    }
`

export const OwnedTokens = styled.div<{dropdownOpen:boolean}>`
    position:relative;

    ${props => props.dropdownOpen && `
        ${OwnedTokensContent}{
            display:block;
        }
    `}
`

export const OwnedTokensIcon = styled.div`
    display:flex;
    align-items:center;
    & svg{
        width:25px;
        height:25px;
    }
    background:${color.bg1};
    border-radius:50%;
    padding:2px;
`

export const OwnedTokensText = styled.div`
    margin:0 10px;
`

export const OwnedTokensArrow = styled.div`
    display:flex;
    align-items:center;
    background:${Hex2Rgba(color.white, .16)};
    border-radius:50%;
    padding:5px;
    font-size:12px;
`



export const OwnedTokensContentItem = styled.div`
    display:flex;
    align-items:center;
    background:${color.bg1};
    padding:8px 16px;
    border-radius:8px;

    &:not(:last-child){
        margin-bottom:8px;
    }
`

export const OwnedTokensContentItemIcon = styled.div`
    width:28px;
    height:28px;
    & img{
        width:100%;
        height:100%;
        border-radius:8px;
    }
`

export const OwnedTokensContentItemName = styled.div`
    margin-left:8px;
    & span{
        color:${Hex2Rgba(color.white, .5)};
    }
`