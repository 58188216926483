import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { deviceMax } from "styles/media"
import { Link } from "react-router-dom"
import { Row } from "styles"

export const ShuffleLedgerSelect = styled.div`
    width:100%;
`

export const Title = styled.div`
    font-size:18px;
    margin-top:32px;
    margin-bottom:16px;
`

export const Ledgers = styled.div`

`

export const Ledger = styled.div`
    background-color:${Hex2Rgba(color.secondary, .16)};
    padding:16px;
    border-radius: 8px;
    display:flex;
    align-items:center;
    justify-content:space-between;

    &:not(:last-child){
        margin-bottom:16px;
    }

    & button{
        padding:8px 32px;
    }
`

export const LedgerAddress = styled.div`

`

export const CreateNew = styled.div`
    margin-top:16px;
`