import React, { useEffect, useState } from 'react'
import * as C from './style'

import { useDispatch, useSelector } from "react-redux"

import Slider from "react-slick"
import env from 'env'
import { WhitelistCollection } from 'models/whitelist/collection'
import ReactTooltip from 'react-tooltip'
import { set_WhitelistCollections, setFilterValues, setSearchValue } from 'services/slices/_whitelistCollections'
import { store } from 'services/store'
import { copyObject, Hex2Rgba, toLink } from 'utils/helpers'
import Input from 'components/ui/input'
import DropdownButton, { DropdownItem } from 'components/ui/button/dropdown'
import { Col, Row } from 'styles'
import { Chains } from 'models/enums/network';
import { color } from 'styles/theme'
import WhitelistCollectionWidget from 'components/widgets/whitelistCollection'
import { State } from 'models/enums/state'
import { useNavigate } from 'react-router-dom'

let dragging = false

const WhitelistCollections = () => {

    const allWLCollections: WhitelistCollection[] = useSelector((state: any) => state.data.whitelistCollections)
    const [featuredWLCollections, setFeaturedWLCollections] = useState<any>([])

    const userLoaded = useSelector((state: any) => state.user.userLoaded)
    const whitelistCollections = useSelector((state: any) => state._whitelistCollections._whitelistCollections)
    const _searchText = useSelector((state: any) => state._whitelistCollections.searchText)
    const _chain = useSelector((state: any) => state._whitelistCollections.chain)
    const _filter = useSelector((state: any) => state._whitelistCollections.filter)
    const _sort = useSelector((state: any) => state._whitelistCollections.sort)

    const searchInput = React.useRef<any>(null)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        ReactTooltip.rebuild()
        ReactTooltip.hide()
    }, [])

    useEffect(() => {
        ReactTooltip.rebuild()
        ReactTooltip.hide()
    }, [whitelistCollections])

    useEffect(() => {

        if (allWLCollections.length > 0 && userLoaded) {
            if (_searchText.length === 0)
                filterItems(_chain, _filter, _sort)
            else
                handleSearch(_searchText)
        }


    }, [allWLCollections, userLoaded])

    useEffect(() => {
        let featured = allWLCollections.filter((collection: any) => collection.isFeatured === true)

        //order by collection order
        featured.sort((a: any, b: any) => a.featureOrder - b.featureOrder)

        setFeaturedWLCollections(featured)

    }, [allWLCollections])

    const filterItems = (chain: any, filter: any, sort: any, page = 1) => {

        let user = store.getState().user.user

        let filtered = copyObject(allWLCollections)

        if (chain !== "all") {
            filtered = filtered.filter((collection: any) => collection.chain === chain)
        }

        if (filter === "Active") {
            filtered = filtered.filter((collection: any) => collection.status === State.ACTIVE)
        }


        if (sort === "A-Z") {
            filtered = filtered.sort((a: any, b: any) => a.name.localeCompare(b.name))
        }


        searchInput!.current.value = ""

        dispatch(setSearchValue(""))
        dispatch(setFilterValues({ chain, filter, sort }))
        dispatch(set_WhitelistCollections(filtered))
    }

    const handleSearch = (searchText: any) => {
        dispatch(setSearchValue(searchText))

        let filtered = allWLCollections

        if (searchText) {
            filtered = filtered.filter((collection: any) => collection.name.toLowerCase().includes(searchText.toLowerCase()))
        }

        if (_chain !== "all") {
            filtered = filtered.filter((collection: any) => collection.chain === _chain)
        }

        dispatch(set_WhitelistCollections(filtered))
        dispatch(setFilterValues({ chain: _chain, filter: "all", sort: "all" }))
    }

    const sliderSettings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "15%",
        slidesToShow: 1,
        speed: 500,
        arrows: false,
        mobileFirst:true,
        autoplay: true,
        beforeChange: () => dragging = true,
        afterChange: () => dragging = false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    centerMode: false
                }
            }
        ]
    }


    return (
        <C.WhitelistCollections>
            {featuredWLCollections.length > 0 && (
                <C.Slider>
                    <Slider {...sliderSettings}>
                        {featuredWLCollections.map((collection: WhitelistCollection, index : any) => (
                            <C.SliderItem onClick={() => !dragging && navigate(`/app/whitelist/${toLink(collection.name)}`)} key={index}>
                                <img src={env.API.CDN + "whitelist/featured/" + collection.featureImage} alt={collection.name}/>
                            </C.SliderItem>
                        ))}
                    </Slider>
                </C.Slider>
            )}

            <C.Filter>
                <C.Search>
                    <Input type="text" xref={searchInput} placeholder="Search" onChange={(e: any) => handleSearch(e.target.value)} defaultValue={_searchText} />
                </C.Search>

                <C.FilterButtons>

                    <DropdownButton mobileSide="left" text={_chain === "all" ? "Chain" : (_chain.charAt(0).toUpperCase() + _chain.slice(1))} buttonStyle={{ theme: "gradient-border-transparent-bg-on-hover", bg: Hex2Rgba(color.secondaryFade, .5), color: "white" }}>
                        <DropdownItem active={_chain === "all" ? "true" : "false"} onClick={() => filterItems("all", _filter, _sort)}>
                            All
                        </DropdownItem>
                        <DropdownItem active={_chain === Chains.solana.name ? "true" : "false"} onClick={() => filterItems(Chains.solana.name, _filter, _sort)}>
                            <img src="/images/chains/solana-icon.png" alt="solana icon" width="15" style={{ marginRight: 5 }} /> Solana
                        </DropdownItem>
                        <DropdownItem active={_chain === Chains.aptos.name ? "true" : "false"} onClick={() => filterItems(Chains.aptos.name, _filter, _sort)}>
                            <img src="/images/chains/aptos-icon.png" alt="aptos icon" width="15" style={{ marginRight: 5 }} /> Aptos
                        </DropdownItem>
                    </DropdownButton>

                    <DropdownButton text={_filter === "all" ? "Filter" : _filter} buttonStyle={{ theme: "gradient-border-transparent-bg-on-hover", bg: Hex2Rgba(color.secondaryFade, .5), color: "white" }}>
                        <DropdownItem active={_filter === "all" ? "true" : "false"} onClick={() => filterItems(_chain, "all", _sort)}>
                            All
                        </DropdownItem>
                        {/*<DropdownItem active={_filter === "Registered" ? "true" : "false"} onClick={() => filterItems(_chain, "Registered", _sort)}>
                            Registered
                        </DropdownItem>*/}
                        <DropdownItem active={_filter === "Active" ? "true" : "false"} onClick={() => filterItems(_chain, "Active", _sort)}>
                            Active
                        </DropdownItem>
                    </DropdownButton>

                    <DropdownButton text={_sort === "all" ? "Sort" : _sort} buttonStyle={{ theme: "gradient-border-transparent-bg-on-hover", bg: Hex2Rgba(color.secondaryFade, .5), color: "white" }}>
                        <DropdownItem active={_sort === "all" ? "true" : "false"} onClick={() => filterItems(_chain, _filter, "all")}>
                            Default
                        </DropdownItem>
                        <DropdownItem active={_sort === "A-Z" ? "true" : "false"} onClick={() => filterItems(_chain, _filter, "A-Z")}>
                            A-Z
                        </DropdownItem>
                        <DropdownItem active={_sort === "Most Popular" ? "true" : "false"} onClick={() => filterItems(_chain, _filter, "Most Popular")}>
                            Most Popular
                        </DropdownItem>
                    </DropdownButton>
                </C.FilterButtons>
            </C.Filter>

            <C.Items>
                <Row>
                    {whitelistCollections.map((item: any, index: number) => (
                        <Col col="12" md="12" lg="12" xl="6" xxl="6" xxxl="6" xxxxl='4' _2k="3" _4k="2" key={index}>
                            <WhitelistCollectionWidget item={item} key={index}/>
                        </Col>
                    ))}
                </Row>
            </C.Items>
        </C.WhitelistCollections>
    )
}

export default WhitelistCollections