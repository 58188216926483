import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { Dialog } from "../../base/style"

export const Popup = styled.div<{locksInNftPage:string}>`
    & ${Dialog} {
        width: 550px;

        ${props => props.locksInNftPage === "true" && `
            width: 800px;
        `}
    }
`

