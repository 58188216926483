import React, { useEffect } from "react"
import * as C from "./style"
import ReactTooltip from "react-tooltip"
import { useDispatch, useSelector } from "react-redux"
import { copyObject, shortenPublicKey } from "utils/helpers"
import { findWallet } from "modules/wallet"
import { setGlobalSelectedWallet } from "services/slices/user"
import { setWalletConnectSettings } from "services/slices/data"
import { showConnectedWalletSelectPopup, showWalletConnectPopup } from "services/slices/popup"
import { Network } from "models/enums/network"
import { IconConnectWallet } from "utils/icons"

const ActiveWalletBar = () => {

    const user = useSelector((state: any) => state.user.user)
    const WalletConnectPopup = useSelector((state: any) => state.popup.WalletConnectPopup)
    const [solanaWallets, setSolanaWallets] = React.useState<any>([])
    const selectedWallet = useSelector((state: any) => state.user.selectedWallet)

    const dispatch = useDispatch()

    useEffect(() => {
        initializeWallets()
    }, [user, WalletConnectPopup])

    const initializeWallets = async () => {

        if (!user) return

        let wallets: any = copyObject(user.wallets)

        for (var i = 0; i < wallets.length; i++) {
            let wallet = findWallet(wallets[i].address)

            if (wallet) {
                let isConnected = await wallet.classInstance.isConnected()

                if (isConnected) {
                    wallets[i].provider = wallet.provider
                    wallets[i].connected = true
                }
            } else {
                wallets[i].connected = false
            }
        }

        setSolanaWallets(wallets.filter((w: any) => w.network === Network.Solana && w.connected))
    }

    const selectWallet = () => {
        if (solanaWallets.length === 0) {
            dispatch(setWalletConnectSettings({ network: Network.Solana }))
            dispatch(showWalletConnectPopup(true))
        } else {
            dispatch(showConnectedWalletSelectPopup(true))
        }
    }

    return (
        <C.ActiveWalletBar onClick={selectWallet}>

            <IconConnectWallet/>

            {!selectedWallet && (
                <span>Connect Wallet</span>
            )}

            {selectedWallet && (
                <>{shortenPublicKey(selectedWallet)}</>
            )}
        </C.ActiveWalletBar>
    )
}

export default ActiveWalletBar