import React, { useEffect, useState } from 'react'
import * as C from './style'
import env from 'env'
import { useDispatch, useSelector } from "react-redux"
import { color } from 'styles/theme'
import Button from 'components/ui/button'
import { Hex2Rgba, shortenPublicKey, shortenText } from 'utils/helpers'
import ReactTooltip from 'react-tooltip'
import Spline from '@splinetool/react-spline'
import ActiveWalletBar from 'components/widgets/activeWalletBar'
import { getCurrentShuffle } from 'services/api/shuffle'
import { buyTicket, getRaffle, getTicketsOf, totalShuffleVolume } from 'modules/solana/shuffle/raffle'
import { toast } from 'react-hot-toast'
import * as anchor from "@project-serum/anchor"
import { LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js'
import { setAmountToBuy, setParticipantMapping, setShuffleCurrentRaffle, setShuffleProgramId, setShuffleTreasury, setShuffleVault } from 'services/slices/shuffle'
import ShuffleGif from "./assets/shuffle-gif.gif"
import { getShuffleError } from 'modules/solana/shuffle/shuffleErrors'
import { faInfoCircle, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ParseAwardAddress, ParseCollectionAddress } from 'modules/solana/shuffle/helpers'
import { showShuffleFairPopup, showShuffleHistoryPopup, showShuffleHowToPlayPopup, showShuffleMyTicketsPopup, showWalletConnectPopup } from 'services/slices/popup'
import { getAllNftsByMintList } from 'modules/solana/nft'
import axios from 'axios'
import ShuffleWinner from './shuffleWinner'
import { useNavigate } from 'react-router-dom'
import ShuffleWinnerWidget from './shuffleWinnerWidget'
import useWindowDimensions from 'hooks/useWindowDimensions'
import moment from 'moment'
import { getUsersByAddresses } from 'services/api/user'
import { IconFair } from 'utils/icons'
import { store } from 'services/store'
import { setWalletConnectSettings } from 'services/slices/data'
import { Network } from 'models/enums/network'
import { useLightbox } from 'hooks/lightbox'
import ShufflePrizeLB from './shufflePrizeLB'
import ShuffleTicketsLB from './shuffleTicketsLB'

var shuffleLoadLoop: any = null
const shuffleLoadInterval = 10000;
var shuffleAnimationRocketInterval: any = null
var shuffleAnimationRocketIntervalRot: any = null

const Shuffle = () => {

    const buyTicketInput = React.useRef<any>(null);
    const [currentTab, setCurrentTab] = useState("details")

    const [shuffle, setShuffle] = useState<any>(null)
    const [parsedShuffle, setParsedShuffle] = useState<any>(null)
    const [ticketBuyLoading, setTicketBuyLoading] = useState(false)
    const userLoaded = useSelector((state: any) => state.user.userLoaded)
    const amountToBuy = useSelector((state: any) => state.shuffle.amountToBuy)
    const [myTicketCount, setMyTicketCount] = useState(0)
    const [totalVolume, setTotalVolume] = useState(0)
    const selectedWallet = useSelector((state: any) => state.user.selectedWallet)
    const participantMapping = useSelector((state: any) => state.shuffle.participantMapping)

    const [config, setConfig] = useState<any>(null)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { windowHeight, windowWidth } = useWindowDimensions()

    const lightBox = useLightbox();

    useEffect(() => {
        ReactTooltip.rebuild()
        ReactTooltip.hide()

        dispatch(setAmountToBuy(1))

        return () => {
            clearInterval(shuffleLoadLoop)
            clearInterval(shuffleAnimationRocketInterval)
            clearInterval(shuffleAnimationRocketIntervalRot)
        }
    }, [])

    useEffect(() => {

        if (userLoaded) {
            loadShuffleInfo().then((res: any) => {
                totalShuffleVolume(res.shuffleProgram).then((res: any) => {
                    setTotalVolume(res)
                })
                shuffleLoadLoop = setInterval(() => {
                    loadShuffle(res, "interval")
                }, shuffleLoadInterval)
            })


        }

    }, [userLoaded])

    useEffect(() => {
        if (shuffle) {
            setParsedShuffle(null)
            dispatch(setAmountToBuy(1))
            loadShuffle(config, "selectedwallet")
        }
    }, [selectedWallet])

    useEffect(() => {
        ReactTooltip.rebuild()
        ReactTooltip.hide()
    }, [parsedShuffle])

    useEffect(() => {
        ReactTooltip.rebuild()
        ReactTooltip.hide()
    }, [currentTab])

    const loadShuffleInfo = async () => new Promise(async (resolve, reject) => {
        getCurrentShuffle().then((res) => {

            setConfig({
                shuffleProgram: res.data.shuffleProgram.value,
                shuffleCurrentRaffle: res.data.shuffleCurrentRaffle.value,
                shuffleVault: res.data.shuffleVault.value,
                shuffleDefaultTreasury: res.data.shuffleDefaultTreasury.value,
            })

            dispatch(setShuffleProgramId(res.data.shuffleProgram.value))
            dispatch(setShuffleTreasury(res.data.shuffleDefaultTreasury.value))
            dispatch(setShuffleVault(res.data.shuffleVault.value))
            dispatch(setShuffleCurrentRaffle(res.data.shuffleCurrentRaffle.value))

            loadShuffle({
                shuffleProgram: res.data.shuffleProgram.value,
                shuffleCurrentRaffle: res.data.shuffleCurrentRaffle.value,
                shuffleVault: res.data.shuffleVault.value,
                shuffleDefaultTreasury: res.data.shuffleDefaultTreasury.value,
            }, "frominfo").catch((err) => {
                console.log(err)
                toast.error("Error loading shuffle")
            }).then(() => {
                resolve({
                    shuffleProgram: res.data.shuffleProgram.value,
                    shuffleCurrentRaffle: res.data.shuffleCurrentRaffle.value,
                    shuffleVault: res.data.shuffleVault.value,
                    shuffleDefaultTreasury: res.data.shuffleDefaultTreasury.value,
                })
            })

        })
    })

    const [metadataCache, setMetadataCache] = useState<any>({})

    const loadShuffle = async (config: any, msg?: any) => new Promise(async (resolve, reject) => {

        //console.log("loading shuffle", msg)

        getRaffle(config.shuffleProgram, config.shuffleCurrentRaffle).then(async (res: any) => {

            setShuffle(res)

            let awardsGeneral: any = {}

            let collectionMintList: any = []

            for (let i = 0; i < res.inventory.length; i++) {
                if (awardsGeneral[res.inventory[i].address.toBase58()] === undefined) {
                    if (typeof res.inventory[i].itemType.token !== "undefined")
                        awardsGeneral[res.inventory[i].address.toBase58()] = { ...ParseAwardAddress(res.inventory[i].address.toBase58()), count: res.inventory[i].count[0].toNumber(), itemType: res.inventory[i].itemType, address: res.inventory[i].address.toBase58() }
                    else {
                        let check = ParseCollectionAddress(res.inventory[i].address.toBase58())

                        if (check === null) {
                            collectionMintList.push(res.inventory[i].address.toBase58())
                            awardsGeneral[res.inventory[i].address.toBase58()] = { count: res.inventory[i].count[0].toNumber(), itemType: res.inventory[i].itemType, address: res.inventory[i].address.toBase58() }
                        } else {
                            awardsGeneral[res.inventory[i].address.toBase58()] = { ...check, count: res.inventory[i].count[0].toNumber(), itemType: res.inventory[i].itemType, address: res.inventory[i].address.toBase58() }
                        }

                    }
                } else
                    awardsGeneral[res.inventory[i].address.toBase58()].count += res.inventory[i].count[0].toNumber()
            }

            let cachedMetadata: any = metadataCache

            let parsedCollections: any = await getAllNftsByMintList(collectionMintList)

            for (let i = 0; i < parsedCollections.length; i++) {
                if (awardsGeneral[parsedCollections[i].mintAddress] !== undefined && cachedMetadata[parsedCollections[i].mintAddress] === undefined) {
                    try {
                        let metadata = await axios.get(parsedCollections[i].uri)
                        awardsGeneral[parsedCollections[i].mintAddress].name = metadata.data.name
                        awardsGeneral[parsedCollections[i].mintAddress].symbol = metadata.data.symbol
                        awardsGeneral[parsedCollections[i].mintAddress].image = metadata.data.image
                        cachedMetadata[parsedCollections[i].mintAddress] = metadata.data
                    } catch (err) {
                        awardsGeneral[parsedCollections[i].mintAddress].name = "Unknown"
                        awardsGeneral[parsedCollections[i].mintAddress].symbol = "Unknown"
                        awardsGeneral[parsedCollections[i].mintAddress].image = ""
                    }


                } else if (cachedMetadata[parsedCollections[i].mintAddress] !== undefined) {
                    awardsGeneral[parsedCollections[i].mintAddress].name = cachedMetadata[parsedCollections[i].mintAddress].name
                    awardsGeneral[parsedCollections[i].mintAddress].symbol = cachedMetadata[parsedCollections[i].mintAddress].symbol
                    awardsGeneral[parsedCollections[i].mintAddress].image = cachedMetadata[parsedCollections[i].mintAddress].image
                }

            }

            setMetadataCache(cachedMetadata)

            let awardsLimited: any = []
            let awards: any = Object.values(awardsGeneral)
            //max 9 limited awards
            for (let i = 0; i < awards.length; i++) {
                if (i < 9) {
                    awardsLimited.push(awards[i])
                }
            }

            //console.log(awardsLimited)

            let status = "active"
            if (typeof res.status.ended !== "undefined")
                status = "ended"

            //console.log(res)

            //order purchasePDAs by account.sales.length
            const participants = res.purchasePDAs.sort((a: any, b: any) => b.account.sales.length - a.account.sales.length)

            let participantsPubkeys = participants.map((p: any) => p.account.buyer.toBase58())
            const participantMapping = store.getState().shuffle.participantMapping
            //get all participants if they are not in the mapping
            let participantsToFetch = participantsPubkeys.filter((p: any) => participantMapping[p] === undefined)

            if (participantsToFetch.length > 0) {
                getUsersByAddresses(participantsToFetch).then((res: any) => {
                    let newMapping = { ...participantMapping, ...res.data }
                    dispatch(setParticipantMapping(newMapping))
                })
            }

            var liveActivity = [...participants];
            //order purchasePDAs by account.time
            liveActivity.sort((a: any, b: any) => b.account.time.toNumber() - a.account.time.toNumber())
            //console.log(liveActivity)
            //parse time to (ago) (utc)
            liveActivity = liveActivity.map((a: any) => {
                return {
                    ...a,
                    account: {
                        ...a.account,
                        //time is a BN in unix time
                        time: moment(a.account.time.toNumber() * 1000).fromNow()
                    }
                }
            })

            var myTickets: any = []

            if (selectedWallet) {
                myTickets = res.purchasePDAs.filter((p: any) => p.account.buyer.toBase58() === selectedWallet)
                //console.log("myTickets", myTickets)
                if (myTickets.length > 0)
                    myTickets = myTickets[0].account.sales
            }

            if (typeof res.status.ended !== "undefined") {
                clearInterval(shuffleLoadLoop)
            }

            const parsedShuffle = {
                price: new anchor.BN(res.ticketPrice).toNumber(),
                supply: res.ticketSupply,
                sales: res.ticketSales,
                participants: participants,
                liveActivity: liveActivity,
                myTickets: myTickets,
                winners:/* res.winners.map((w: any) => w.user.toBase58()),*/[],
                awardsGeneral: awards,
                awardsLimited,
                status,
                totalCount: res.inventory.map((i: any) => i.count[0].toNumber()).reduce((a: number, b: number) => a + b, 0),
            }

            //console.log(parsedShuffle)

            //reverse order ticketsales
            parsedShuffle.sales = parsedShuffle.sales.reverse()

            setParsedShuffle(parsedShuffle)

            let _myTicketCount = 0

            if (res.ticketSales.length === 0) {
                setMyTicketCount(0)
            } else {
                let myTickets = res.purchasePDAs.find((p: any) => p.account.buyer.toBase58() === selectedWallet)
                if (myTickets !== undefined) {
                    _myTicketCount = myTickets.account.sales.length
                }

                setMyTicketCount(_myTicketCount)
            }


            resolve(parsedShuffle)

        }).catch((err) => {
            console.log(err)
            toast.error("Error loading shuffle")
            reject(err)
        })

    })

    const onChangeBuyTicketInput = () => {
        let val = buyTicketInput.current.value
        let totalSales = parsedShuffle.sales.length
        if (val > 0) {
            if (val > parsedShuffle.supply - totalSales)
                val = parsedShuffle.supply - totalSales
            dispatch(setAmountToBuy(parseInt(val)))
            buyTicketInput.current.value = parseInt(val)
        } else {
            dispatch(setAmountToBuy(1))
            buyTicketInput.current.value = 1;
        }
    }

    const incrementTicketCount = () => {
        let val = buyTicketInput.current.value
        let totalSales = parsedShuffle.sales.length
        if (val >= parsedShuffle.supply - totalSales)
            return

        buyTicketInput.current.value = parseInt(val) + 1
        dispatch(setAmountToBuy(parseInt(val) + 1))

    }

    const decrementTicketCount = () => {
        let val = buyTicketInput.current.value
        let totalSales = parsedShuffle.sales.length
        if (val > 1) {
            if (val > parsedShuffle.supply - totalSales)
                val = parsedShuffle.supply - totalSales
            buyTicketInput.current.value = parseInt(val) - 1
            dispatch(setAmountToBuy(parseInt(val) - 1))
        }
    }

    const animationBase = React.useRef<any>(null)
    const spline = React.useRef<any>(null);
    const anim1 = React.useRef<any>(null);

    const onSplineLoad = (spl: any) => {
        const obj = spl.findObjectByName("Rectangle 3")
        anim1.current = obj;

        const rocket = spl.findObjectByName("Group")

        let reverse = false
        let reverserot = false

        //move rocket position y between 20 and 40
        shuffleAnimationRocketInterval = setInterval(() => {


            if (reverse) {
                rocket.position.y -= 0.1
                if (rocket.position.y <= -116.34)
                    reverse = false
            } else {
                rocket.position.y += 0.1
                if (rocket.position.y >= -96.34)
                    reverse = true
            }

            //rotate rocket between 360 and 0
            if (reverserot) {
                rocket.rotation.y -= 0.01
                if (rocket.rotation.y <= 0)
                    reverserot = false
            } else {
                rocket.rotation.y += 0.01
                if (rocket.rotation.y >= 360)
                    reverserot = true
            }

        }, 15)

        shuffleAnimationRocketIntervalRot = setInterval(() => {



            /*  rocket.rotation.z = Math.sin(new Date().getTime() / 100) / 5
  
              //position y crazy -90 to -120
              rocket.position.y = Math.sin(new Date().getTime() / 100) * 30 - 105*/



        }, 15)
    }

    const buyTickets = () => {

        setTicketBuyLoading(true)

        buyTicket(config.shuffleProgram, config.shuffleCurrentRaffle, shuffle.ticketSaleAccount.toBase58(), config.shuffleVault, amountToBuy).then((res) => {
            toast.success("Tickets bought successfully")
            //console.log(res)
            anim1.current.emitEvent("mouseDown")

            loadShuffle(config, "buyticket")
            dispatch(setAmountToBuy(1))
            buyTicketInput.current.value = 1
        }).catch((err) => {
            console.log(err)
            toast.error(err)
        }).finally(() => {
            setTicketBuyLoading(false)
        })

    }

    const connectWallet = () => {
        dispatch(setWalletConnectSettings({ network: Network.Solana }))
        dispatch(showWalletConnectPopup(true))

    }

    const handlePrizeView = (award: any) => {
        let item = shuffle.inventory.filter((i: any) => i.address.toBase58() === award.address).map((i: any) => {
            return {
                amount: i.count[1].toNumber(),
                count: i.count[0].toNumber(),
            }
        })
        lightBox(<ShufflePrizeLB name={award.name} items={item} />, "Prizes")
    }

    const [viewMyTicketsLoading, setViewMyTicketsLoading] = useState(false)

    const showMyTickets = () => {

        if (viewMyTicketsLoading)
            return;

        let loading = toast.loading("Loading")
        setViewMyTicketsLoading(true)

        getTicketsOf(config.shuffleProgram, config.shuffleCurrentRaffle, selectedWallet).then((res: any) => {
            if (!res){
                toast.error("You have no tickets")
                toast.dismiss(loading)
                return;
            }

            lightBox(<ShuffleTicketsLB tickets={res} />, "My Tickets")
            setViewMyTicketsLoading(false)

            toast.dismiss(loading)

        }).catch((err: any) => {
            console.log(err)
            toast.error("Error loading tickets")
            toast.dismiss(loading)
            setViewMyTicketsLoading(false)
        })
    }

    if (parsedShuffle === null)
        return (
            <C.Loading>
                <FontAwesomeIcon icon={faSpinner} spin />
            </C.Loading>
        )

    return (
        <C.ShuffleBase>

            <C.ShuffleHeader>

                <C.ShuffleHeaderLeft>

                    <C.ShuffleGif>
                        <img src={ShuffleGif} />
                    </C.ShuffleGif>

                    {/*<C.ShuffleHeaderLeftTitle>
                        Shuffle
    </C.ShuffleHeaderLeftTitle>*/}
                    <C.ShuffleHeaderLeftActions>
                        
                        <C.ShuffleHeaderLeftAction onClick={() => dispatch(showShuffleHowToPlayPopup(true))}>
                            How to Play
</C.ShuffleHeaderLeftAction>{/*
                        <C.ShuffleHeaderLeftAction noHover="true">
                            Total volume: <span>{totalVolume} SOL</span>
</C.ShuffleHeaderLeftAction>*/}
                        <C.ShuffleHeaderLeftAction onClick={() => dispatch(showShuffleHistoryPopup(true))}>
                            History
                        </C.ShuffleHeaderLeftAction>
                        {/*<C.ShuffleHeaderLeftAction onClick={() => dispatch(showShuffleMyTicketsPopup(true))}>
                            My tickets
                        </C.ShuffleHeaderLeftAction>
                        <C.ShuffleHeaderLeftActionLink to={"/app/shuffle/liquidation"}>
                            Liquidity
</C.ShuffleHeaderLeftActionLink>*/}
                    </C.ShuffleHeaderLeftActions>
                </C.ShuffleHeaderLeft>

                <C.ShuffleHeaderRight>
                    <C.ShuffleHeaderLeftAction onClick={() => dispatch(showShuffleFairPopup(true))}>
                        <IconFair /> Fair
                    </C.ShuffleHeaderLeftAction>
                    <C.WalletConnect>
                        <ActiveWalletBar />
                    </C.WalletConnect>

                    <C.ShuffleHeaderRightClose onClick={() => navigate("/app")}>
                        &times;
                    </C.ShuffleHeaderRightClose>
                </C.ShuffleHeaderRight>

            </C.ShuffleHeader>

            <C.ShuffleAnimation>

                <C.ShuffleAnimationBase ref={animationBase}>
                    {windowWidth > 768 && (
                        <Spline scene="https://prod.spline.design/FeuYPmIWjhFuuaaM/scene.splinecode" onLoad={onSplineLoad} />
                    )}
                    {windowWidth <= 768 && (
                        <Spline scene="https://prod.spline.design/u9JVJjkKcGQqKgMh/scene.splinecode" onLoad={onSplineLoad} />
                    )}
                </C.ShuffleAnimationBase>

                <C.ShuffleAnimationLeft>
                </C.ShuffleAnimationLeft>

                <C.ShuffleAnimationRight>
                </C.ShuffleAnimationRight>

                <C.ShuffleAnimationSun>
                </C.ShuffleAnimationSun>

            </C.ShuffleAnimation>

            <C.ShuffleRoot>
                <C.Shuffle>

                    {/*windowWidth <= 768 && (
                        <C.ShuffleTotalVolume>
                            Total Volume <span>{totalVolume} SOL</span>
                        </C.ShuffleTotalVolume>
                    )*/}

                    <C.Tabs>
                        <C.TabsHeader>
                            <C.TabsHeaderItem active={currentTab === "details" ? "true" : "false"} onClick={() => setCurrentTab("details")}>
                                Details
                            </C.TabsHeaderItem>
                            <C.TabsHeaderItem active={currentTab === "awards" ? "true" : "false"} onClick={() => setCurrentTab("awards")}>
                                Prizes
                            </C.TabsHeaderItem>
                            <C.TabsHeaderItem active={currentTab === "participants" ? "true" : "false"} onClick={() => setCurrentTab("participants")}>
                                Participants
                            </C.TabsHeaderItem>
                        </C.TabsHeader>

                        <C.TabsContent>

                            {currentTab === "details" && (
                                <C.TabsContentItem>


                                    <C.Note>
                                        <C.ContentBox>
                                            <C.NoteContent>
                                                <C.NoteVol>
                                                    Total volume: <span>{totalVolume} SOL</span>
                                                </C.NoteVol>
                                                <C.NoteDivider></C.NoteDivider>
                                                <C.NoteText>
                                                    Single ticket win chance <span>{((parsedShuffle.totalCount / parsedShuffle.supply) * 100).toFixed(1)}%</span>
                                                </C.NoteText>
                                            </C.NoteContent>

                                        </C.ContentBox>
                                    </C.Note>


                                    <C.ContentBox>
                                        <C.Title>
                                            Prizes ({shuffle.inventory.map((i: any) => i.count[0].toNumber()).reduce((a: number, b: number) => a + b, 0)})
                                        </C.Title>
                                        <C.Awards>

                                            {parsedShuffle.awardsLimited.map((award: any, i: number) => (
                                                <C.Award key={i} data-tip={award.name + (typeof award.itemType.token === "undefined" ? (" x" + award.count) : "")} expand={typeof award.itemType.token !== "undefined" ? "true" : "false"} onClick={typeof award.itemType.token !== "undefined" ? () => handlePrizeView(award) : () => { }}>
                                                    <img src={award.image ? award.image : "/images/etc/ghost.png"} />
                                                    {typeof award.itemType.token === "undefined" && (
                                                        <C.AwardAmount>
                                                            x{award.count}
                                                        </C.AwardAmount>
                                                    )}
                                                    {typeof award.itemType.token !== "undefined" && (
                                                        <C.AwardAmount>
                                                            <FontAwesomeIcon icon={faInfoCircle} />
                                                        </C.AwardAmount>
                                                    )}
                                                </C.Award>
                                            ))}

                                            {parsedShuffle.awardsGeneral.length > 9 && (
                                                <C.Award>
                                                    <C.AwardMore onClick={() => setCurrentTab("awards")}>
                                                        +15
                                                    </C.AwardMore>
                                                </C.Award>
                                            )}
                                        </C.Awards>
                                    </C.ContentBox>

                                    {(parsedShuffle.status !== "ended" && parsedShuffle.sales.length > 0) && (
                                        <C.LiveActivityRoot>
                                            <C.ContentBox>
                                                <C.Title >
                                                    Live Activity
                                                </C.Title>

                                                <C.LiveActivity>
                                                    {parsedShuffle.liveActivity.map((sale: any, i: number) => (
                                                        <C.LiveActivityItem key={i}>
                                                            <C.LiveActivityItemText>
                                                                {participantMapping[sale.account.buyer.toString()] && (
                                                                    <C.LiveActivityItemTextLinkUser to={"app/profile/" + participantMapping[sale.account.buyer.toString()].id}>
                                                                        {shortenText(participantMapping[sale.account.buyer.toString()].username, 11) + " (" + shortenPublicKey(sale.account.buyer.toString()) + ")"}
                                                                    </C.LiveActivityItemTextLinkUser>
                                                                )}
                                                                {!participantMapping[sale.account.buyer.toString()] && (
                                                                    <C.LiveActivityItemTextUser>
                                                                        {shortenPublicKey(sale.account.buyer.toString())}
                                                                    </C.LiveActivityItemTextUser>
                                                                )}
                                                                <C.LiveActivityItemTextAction>
                                                                    bought
                                                                </C.LiveActivityItemTextAction>
                                                                <C.LiveActivityItemTextAmount>
                                                                    {sale.account.sales.length} tickets
                                                                </C.LiveActivityItemTextAmount>
                                                            </C.LiveActivityItemText>
                                                            <C.LiveActivityItemTime>
                                                                {sale.account.time}
                                                            </C.LiveActivityItemTime>
                                                        </C.LiveActivityItem>
                                                    ))}
                                                </C.LiveActivity>
                                            </C.ContentBox>
                                        </C.LiveActivityRoot>
                                    )}

                                </C.TabsContentItem>
                            )}

                            {currentTab === "awards" && (
                                <C.TabsContentItem>
                                    <C.AwardsTab>
                                        <C.ContentBox>
                                            <C.Title>
                                                Prizes ({shuffle.inventory.map((i: any) => i.count[0].toNumber()).reduce((a: number, b: number) => a + b, 0)})
                                            </C.Title>

                                            {parsedShuffle.awardsGeneral.filter((item: any) => typeof item.itemType.nft !== "undefined").length > 0 && (
                                                <>
                                                    <C.SubTitle>
                                                        NFTs
                                                    </C.SubTitle>

                                                    <C.Awards scroll="true">

                                                        {parsedShuffle.awardsGeneral.filter((item: any) => typeof item.itemType.nft !== "undefined").map((award: any, i: number) => (
                                                            <C.Award key={i} data-tip={award.name + " x" + award.count}>
                                                                <img src={award.image ? award.image : "/images/etc/ghost.png"} onError={(e: any) => { e.target.onerror = null; e.target.src = "/images/etc/ghost.png" }} />
                                                                <C.AwardAmount>
                                                                    x{award.count}
                                                                </C.AwardAmount>
                                                            </C.Award>
                                                        ))}
                                                    </C.Awards>
                                                </>
                                            )}

                                            {shuffle.inventory.filter((item: any) => typeof item.itemType.token !== "undefined").length > 0 && (
                                                <>
                                                    <C.SubTitle>
                                                        Tokens
                                                    </C.SubTitle>

                                                    <C.Tokens>
                                                        {shuffle.inventory.filter((item: any) => typeof item.itemType.token !== "undefined").map((item: any, i: number) => {
                                                            let details = ParseAwardAddress(item.address.toString());
                                                            return (
                                                                <C.TokenItem key={i}>
                                                                    <C.TokenItemItemAmount>
                                                                        {(item.count[1].toNumber() / LAMPORTS_PER_SOL).toFixed(2)} {details.symbol} <span>x{item.count[0].toNumber()}</span>
                                                                    </C.TokenItemItemAmount>
                                                                    <C.TokenItemCount>
                                                                        total {(item.count[1].toNumber() / LAMPORTS_PER_SOL * item.count[0].toNumber()).toFixed(2)} {details.symbol}
                                                                    </C.TokenItemCount>
                                                                </C.TokenItem>
                                                            )
                                                        })}
                                                    </C.Tokens>
                                                </>
                                            )}
                                        </C.ContentBox>
                                    </C.AwardsTab>

                                </C.TabsContentItem>
                            )}

                            {currentTab === "participants" && (
                                <C.TabsContentItem>
                                    <C.ParticipantsTab>
                                        <C.ContentBox>
                                            <C.Title>
                                                Participants
                                            </C.Title>

                                            <C.Participants>
                                                {parsedShuffle.participants.map((sale: any, i: number) => (
                                                    <C.ParticipantItem key={i}>
                                                        {participantMapping[sale.account.buyer.toString()] && (
                                                            <C.ParticipantItemTextLink to={"app/profile/" + participantMapping[sale.account.buyer.toString()].id}>
                                                                {participantMapping[sale.account.buyer.toString()].username + " (" + shortenPublicKey(sale.account.buyer.toString()) + ")"}
                                                            </C.ParticipantItemTextLink>
                                                        )}
                                                        {!participantMapping[sale.account.buyer.toString()] && (
                                                            <C.ParticipantItemText>
                                                                {shortenPublicKey(sale.account.buyer.toString())}
                                                            </C.ParticipantItemText>
                                                        )}
                                                        <C.ParticipantItemAmount>
                                                            {sale.account.sales.length} Tickets
                                                        </C.ParticipantItemAmount>
                                                    </C.ParticipantItem>
                                                ))}
                                            </C.Participants>
                                        </C.ContentBox>

                                    </C.ParticipantsTab>


                                </C.TabsContentItem>
                            )}

                        </C.TabsContent>
                    </C.Tabs>


                    {(parsedShuffle.sales.length < parsedShuffle.supply) && (

                        <C.TicketPurchase>

                            <C.TicketPurchaseInfo>


                                <C.TicketPurchaseInfoItem>
                                    <C.TicketPurchaseInfoItemTitle>
                                        Total Sold
                                    </C.TicketPurchaseInfoItemTitle>
                                    <C.TicketPurchaseInfoItemValue>
                                        {parsedShuffle.sales.length}/{parsedShuffle.supply}
                                    </C.TicketPurchaseInfoItemValue>
                                </C.TicketPurchaseInfoItem>

                                <C.TicketPurchaseInfoItem onClick={showMyTickets} clickable="true">
                                    <C.TicketPurchaseInfoItemTitle>
                                        My Tickets
                                    </C.TicketPurchaseInfoItemTitle>
                                    <C.TicketPurchaseInfoItemValue>
                                        {selectedWallet ? myTicketCount : "-"}
                                    </C.TicketPurchaseInfoItemValue>
                                </C.TicketPurchaseInfoItem>

                                <C.TicketPurchaseInfoItem shiny='true'>
                                    <C.TicketPurchaseInfoItemPrice>
                                        <C.TicketPurchaseInfoItemTitle>
                                            Price
                                        </C.TicketPurchaseInfoItemTitle>
                                        <C.TicketPurchaseInfoItemValue>
                                            {(parsedShuffle.price / LAMPORTS_PER_SOL * amountToBuy).toFixed(3)} SOL
                                        </C.TicketPurchaseInfoItemValue>
                                    </C.TicketPurchaseInfoItemPrice>
                                </C.TicketPurchaseInfoItem>
                            </C.TicketPurchaseInfo>

                            <C.TicketPurchaseForm>
                                <C.TicketCount>
                                    <C.TicketCountButton onClick={decrementTicketCount}>-</C.TicketCountButton>
                                    <C.TicketCountInputBox>
                                        <C.TicketCountInput onChange={onChangeBuyTicketInput} min="1" step="1" type="number" defaultValue={1} ref={buyTicketInput} max={parsedShuffle.supply - parsedShuffle.sales.length} />
                                    </C.TicketCountInputBox>
                                    <C.TicketCountButton onClick={incrementTicketCount}>+</C.TicketCountButton>
                                </C.TicketCount>
                                <Button theme="gradient" onClick={buyTickets} color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)}>
                                    Buy Ticket
                                    {/*<C.TicketPrice>
                                        {(parsedShuffle.price / LAMPORTS_PER_SOL * amountToBuy).toFixed(3)} sol
                    </C.TicketPrice>*/}
                                </Button>
                            </C.TicketPurchaseForm>

                            {ticketBuyLoading && (
                                <C.TicketPurchaseLoading>
                                    <FontAwesomeIcon icon={faSpinner} spin />
                                </C.TicketPurchaseLoading>
                            )}

                        </C.TicketPurchase>

                    )}



                    {parsedShuffle.status === "ended" && (
                        <C.ShuffleEnd>

                            <C.ShuffleEndText>
                                <span>Shuffle Drawn. Good luck next time!</span>
                                <div>
                                    Follow us on <a href="https://twitter.com/hub3ee" target="_blank" rel="noreferrer">Twitter</a> to be first to know about next shuffle!
                                </div>
                            </C.ShuffleEndText>

                            {!selectedWallet && (
                                <C.ShuffleEndConnect>
                                    <C.ShuffleEndConnectImage>
                                        <img src="/images/shuffle/wallet-connect.png" alt="Connect wallet" />
                                    </C.ShuffleEndConnectImage>
                                    <C.ShuffleEndConnectText>
                                        <span>Connect your wallet and see the Shuffle result!</span>
                                        <Button theme="gradient-border-transparent-bg-on-hover" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)} onClick={connectWallet} >
                                            Connect Wallet
                                        </Button>
                                    </C.ShuffleEndConnectText>
                                </C.ShuffleEndConnect>
                            )}

                            {(selectedWallet && shuffle.winningTickets.some((ticket: any) => parsedShuffle.myTickets.includes(ticket))) && (
                                <C.Winner>
                                    <ShuffleWinnerWidget raffle={{ account: shuffle, tickets: parsedShuffle.myTickets, publicKey: new PublicKey(config.shuffleCurrentRaffle) }} address={selectedWallet} />
                                </C.Winner>
                            )}

                            {(selectedWallet && parsedShuffle.myTickets.length > 0 && !shuffle.winningTickets.some((ticket: any) => parsedShuffle.myTickets.includes(ticket))) && (
                                <C.NextShuffle border='true'>
                                    <C.NextShuffleImage>
                                        <img src="/images/shuffle/no-prize.png" alt="Shuffle ended" />
                                    </C.NextShuffleImage>
                                    <C.NextShuffleText>
                                        <span>Sorry you didn't win this time!</span>
                                        <div>
                                            You can try again in the next shuffle! - Total tickets bought: {parsedShuffle.myTickets.length}
                                        </div>
                                    </C.NextShuffleText>
                                </C.NextShuffle>
                            )}

                        </C.ShuffleEnd>
                    )}



                    {parsedShuffle.status !== "ended" && (
                        <>

                            {parsedShuffle.sales.length >= parsedShuffle.supply && (

                                <C.TicketDraw>
                                    <C.OGRocket>
                                        <C.OGRocketBg>
                                            <img src="/images/etc/wlrocketbg.png" alt="rocket" />
                                        </C.OGRocketBg>
                                        <C.OGRocketImage>
                                            <img src="/images/etc/wlrocket.png" alt="rocket" />
                                        </C.OGRocketImage>
                                    </C.OGRocket>

                                    <C.TicketDrawTitle>
                                        Shuffle drawing now!<br />Please wait...
                                    </C.TicketDrawTitle>
                                </C.TicketDraw>

                            )}
                        </>
                    )}



                </C.Shuffle>
            </C.ShuffleRoot>

        </C.ShuffleBase >
    )
}

export default Shuffle