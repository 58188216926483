import { Link } from "react-router-dom"
import { Input, Textarea } from "./style"

const input = (props: any) => {

    if (props.type === "textarea") {
        if (props.xref) {
            let { xref, children, ...rest } = props
            return (
                <Textarea ref={props.xref} {...rest}>
                    {props.children}
                </Textarea>
            )
        }else{
            let { children, ...rest } = props
            return (
                <Textarea {...rest}>
                    {props.children}
                </Textarea>
            )
        }
    }else{
        if (props.xref) {
            let { xref, ...rest } = props
            return (
                <Input ref={props.xref} {...rest} />
            )
        }else{
            return (
                <Input {...props} />
            )
        }
    }
}

export default input