import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"

export const Place = styled.div`
    position:relative;
`

export const Loading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    font-size: 24px;
`

export const PlaceTop = styled.div<{bg : string}>`

    display:flex;
    padding:40px 10px 0px 10px;
    position:relative;
    height:120px;
    justify-content:space-between;

    ${props => props.bg && `
        background-image:url(${props.bg});
        background-size:cover;
        background-position:center;
    `}

    ${props => !props.bg && `
        background-color:${Hex2Rgba(color.black, 0)};
    `}

`

export const PlaceTopLeft = styled.div`
    display:flex;
    flex:1;
`

export const Avatar = styled.div`
    position:relative;
    width:100px;
    height:100px;

    & img{
        width:100px;
        height:100px;
        border-radius:100%;
        z-index:1;
        position:relative;
    }

    &::after {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 50%; 
        padding: 2px;
        transform: scale(1.02);
        background:linear-gradient(45deg,${Hex2Rgba(color.gradient1.start, 1)},${Hex2Rgba(color.gradient1.middle, 1)},${Hex2Rgba(color.gradient1.end, 1)}); 
        -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
                mask-composite: exclude; 
    }
`

export const UserName = styled.div`
    margin-left:20px;
    text-shadow:0px 0px 10px ${Hex2Rgba(color.black, 1)};
    
    display:flex;
    align-items:center;
`

export const Name = styled.div`
    font-size:24px;
    font-weight:600;
    word-wrap: break-word;
    line-height:0.9;
`

export const Social = styled.div`
    display:flex;
    align-items:center;


    & > *{
        width:28px;
        height:28px;
        display:flex;
        align-items:center;
        justify-content:center;
        cursor:pointer;
        text-decoration:none;
        background-color:${Hex2Rgba(color.secondaryFade, .5)};
        &:not(:last-child){
            margin-right:5px;
        }
        border-radius:50%;
        & svg{
            font-size:18px;
            color:${color.white};
            transition:all 0.3s;
        }

        & svg{
            /*filter:drop-shadow(0px 0px 5px ${color.primary});*/
        }
        
    }
`

export const PlaceBottom = styled.div`
    padding-right:40px;
    padding-left:40px;
    padding-bottom:40px;
`
export const Description = styled.div`
    font-size:14px;
    margin-top:44px;
    margin-bottom:24px;
`

export const Expand = styled.div`

`

export const Hide = styled.div`
    position:absolute;
    top:5px;
    right:5px;
    cursor:pointer;
    z-index:2;
    padding-left:15px;
    padding-bottom:15px;
` 