import React, { useEffect, useState } from 'react'
import * as C from './style'

import { useDispatch, useSelector } from "react-redux"
import { setCurrentWhitelist, setCurrentViewingWhitelistType, setEligible, setEntry, setOgCount } from 'services/slices/whitelist'
import env from 'env'
import { State } from 'models/enums/state'
import WhitelistCollectionWidget from 'components/widgets/whitelistCollection'
import Button from 'components/ui/button'
import { color } from 'styles/theme'
import { GetRandomGif, GetTwitterHandle, Hex2Rgba, shortenPublicKey, toLink } from 'utils/helpers'
import * as WLService from 'services/api/whitelist'
import { WhitelistCollection } from 'models/whitelist/collection'
import { Recruit } from 'models/enums/recruit'
import Toast from 'react-hot-toast'
import { _EntryRecruitTwitterNomination, _RecruitDiscordRole, _RecruitTwitterNomination } from 'models/interfaces/recruitTypes'
import { showFcfsPopup, showWhitelistNominatePopup, showWhitelistWalletSelectPopup } from 'services/slices/popup'
import { WhitelistQuest, _QuestJoinDiscord, _QuestTweetInteraction, _QuestTwitterFollow } from 'models/interfaces/whitelistQuests'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faCircleExclamation, faSpinner, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from 'react-tooltip'
import { ManageHolderStates } from 'services/managers/holders'
import { resetFilters, setOnlyWhitelisted } from 'services/slices/holders'
import { socket } from 'services/socket'
import { ErrorCodes } from 'utils/errorcodes'
import { displaySettings, displayWalletConnectPopup } from 'utils/parts'
import { Link, useNavigate } from "react-router-dom"
import { IconWhitelist } from 'utils/icons'
import { faDiscord, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { twitterRequestToken } from 'services/api/auth'
import { store } from 'services/store'
import { updateWhitelistCollections } from 'services/slices/data'
import { RefreshUser } from 'services/managers/user'

const Whitelist = () => {

    const whitelistCollections = useSelector((state: any) => state.data.whitelistCollections)
    const _wlLoaded = useSelector((state: any) => state.data._wlLoaded)
    const currentViewingWhitelist = useSelector((state: any) => state.holders.currentViewingWhitelist)

    const item: WhitelistCollection | null = useSelector((state: any) => state.whitelist.currentWhitelist)
    const wldata: any = useSelector((state: any) => state.whitelist)
    const user = useSelector((state: any) => state.user.user)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [done, setDone] = useState(false)
    const [joiningWl, setJoiningWl] = useState(false)
    const [checkingNomination, setCheckingNomination] = useState(false)
    const [checkingNominated, setCheckingNominated] = useState(false)
    const [checkingQuest, setCheckingQuest] = useState(false)
    const [nominator, setNominator] = useState<any>(null)
    const [attemptedQuests, setAttemptedQuests] = useState<any>([])
    const [loading, setLoading] = useState<any>(true)

    useEffect(() => {

        // reset search
        try {
            let element: any = document.getElementById("searchMap")
            var nativeInputValueSetter: any = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value")?.set;
            nativeInputValueSetter.call(element, '');

            var event = new Event('input', { bubbles: true });
            element.dispatchEvent(event);
            element.focus()
            element.blur()
        } catch (e) {
        }

        if (_wlLoaded) {

            //find the current whitelist in the whitelistCollections array. currentViewingWhitelist is formatted small caps with dashes
            let wl = whitelistCollections.find((wl: any) => wl.name.toLowerCase().replace(/ /g, "-") === currentViewingWhitelist)

            if (wl) {
                //set the current whitelist
                document.title = wl.name + " Whitelist - hub3"
                dispatch(setCurrentWhitelist(wl))
                dispatch(setOgCount(null))
                dispatch(setEligible(null))
                dispatch(setEntry(null))
                dispatch(setCurrentViewingWhitelistType(wl.recruitType))
                if (socket.connected) {
                    socket.emit("join", wl.id)
                } else {
                    let interval = setInterval(() => {
                        if (socket.connected) {
                            socket.emit("join", wl.id)
                            //console.log("joined1", wl.id)
                            clearInterval(interval)
                        }
                    }, 1000)
                }
                load(wl)
            } else {
                dispatch(setCurrentWhitelist(null))
                dispatch(setOgCount(null))
                dispatch(setEligible(null))
                dispatch(setEntry(null))
                setLoading(null)
            }
        }


    }, [_wlLoaded, user])

    useEffect(() => {

        if (done) {
            ReactTooltip.rebuild()
            ReactTooltip.hide()
            dispatch(resetFilters())
            ManageHolderStates(false, "wl")
        }
    }, [done])

    useEffect(() => {
        setLoading(true)
        dispatch(setOnlyWhitelisted(false))

        return () => {
            if (socket.connected && item?.id)
                socket.emit("leave", item?.id)
            dispatch(setCurrentWhitelist(null))
        }
    }, [])


    const load = (wl: WhitelistCollection) => {
        WLService.AmIEligible(wl.id!).then((res: any) => {

            dispatch(setCurrentWhitelist(res.data.collection))
            dispatch(updateWhitelistCollections(res.data.collection))

            if (wl.recruitType === Recruit.TwitterNomination) {

                /*let spotsTaken = res.data.collection.entryCount;
                let ogLimit = (res.data.collection.recruitDetails as _RecruitTwitterNomination).ogLimit

                let ogCount = 0
                if (spotsTaken > ogLimit) {
                    ogCount = ogLimit
                } else {
                    ogCount = spotsTaken
                }*/

                dispatch(setOgCount(res.data.ogCount))
                dispatch(setEligible(res.data.eligible))
            }

            if (res.data.eligible) {
                setNominator(res.data.nominator)
                WLService.GetWhitelistEntry(wl.id!).then((res: any) => {

                    if (res.data.entry !== null) {
                        dispatch(setEntry(res.data.entry))
                    } else {
                        dispatch(setEntry(false)) //if the user is eligible but has no entry, set entry to false. allow user to create an entry
                    }

                    setDone(true)
                    setLoading(false)

                }).catch((err: any) => {
                    Toast.error("Error loading whitelist")
                    setLoading(null)
                })
            } else {
                setDone(true)
                setLoading(false)
            }

        }).catch((err: any) => {
            Toast.error("Error loading whitelist")
            setLoading(null)
        })

    }

    const join = () => {

        if (!user) {
            Toast.error("Please login to continue")
            displayWalletConnectPopup(true)
            return;
        }

        if (joiningWl) return;

        setJoiningWl(true)

        WLService.JoinWhitelist(item!.id!).then((res: any) => {

            if (res.status === 201) {
                Toast.success("Entry created")
                dispatch(setEntry(res.data.entry))
                dispatch(setEligible(true))
            } else {
                Toast.error(ErrorCodes[res.data.code])
            }

            setJoiningWl(false)

        }).catch((err: any) => {
            if (err.response.status !== 429) {
                if (err.response.data.code)
                    Toast.error(ErrorCodes[err.response.data.code])
                else
                    Toast.error("Error joining whitelist")
            }
            setJoiningWl(false)
        })
    }

    const nominatePopup = () => {
        if (isNominationsDone()) return;
        dispatch(showWhitelistNominatePopup(true))
    }

    const nominateMorePopup = () => {
        dispatch(showWhitelistNominatePopup(true))
    }

    const checkNomination = () => {

        if (isNominationsDone()) return;
        if (checkingNomination) return;

        setCheckingNomination(true)

        WLService.VerifyNomination(item!.id!).then((res: any) => {
            Toast.success("Nominations verified")
            dispatch(setEntry(res.data.entry))
            setCheckingNomination(false)
        }).catch((err: any) => {
            if (err.response.status !== 429) {
                if (err.response.data.code)
                    Toast.error(ErrorCodes[err.response.data.code])
                else
                    Toast.error("Error verifying nomination")
            }
            setCheckingNomination(false)
        })
    }

    const isNominationsDone = () => {
        if (wldata.entry.recruitDetails && wldata.entry.recruitDetails.nominations) {
            let rd = wldata.entry.recruitDetails as _EntryRecruitTwitterNomination
            return rd.nominations.filter((x) => x.verified).length >= (item!.recruitDetails as _RecruitTwitterNomination).minimumNominationCount
        }

        return false
    }

    const isQuestDone = (index: number) => {
        if (wldata.entry.questDetails) {
            let qd = wldata.entry.questDetails[index]
            if (qd) {
                return qd
            }
        }

        return false
    }

    const isQuestsDone = () => {
        if (wldata.entry.questDetails) {
            let qd = wldata.entry.questDetails
            if (Array.isArray(qd))
                return wldata.entry.questDetails.filter((x: any) => x).length >= item!.quests.length
        }

        return false
    }

    const doQuest = (quest: WhitelistQuest, index: number) => {

        if (quest.id === "_QuestTwitterFollow") {
            if (!store.getState().data.isMobile)
                window.open("https://twitter.com/" + (quest.details as _QuestTwitterFollow).twitter, "_blank")
            else
                window.location.href = "https://twitter.com/" + (quest.details as _QuestTwitterFollow).twitter
        } else if (quest.id === "_QuestTweetInteraction") {
            if (!store.getState().data.isMobile)
                window.open("https://twitter.com/anyuser/status/" + (quest.details as _QuestTweetInteraction).tweetId, "_blank")
            else
                window.location.href = "https://twitter.com/anyuser/status/" + (quest.details as _QuestTweetInteraction).tweetId
        } else if (quest.id === "_QuestJoinDiscord") {
            if (!store.getState().data.isMobile)
                window.open((quest.details as _QuestJoinDiscord).discord, "_blank")
            else
                window.location.href = (quest.details as _QuestJoinDiscord).discord
        }

        //check if quest is attempted
        if (!attemptedQuests.includes(index)) {
            setAttemptedQuests([...attemptedQuests, index])
        }


    }

    const checkQuest = (index: number) => {
        if (checkingQuest) return;

        setCheckingQuest(true)
        WLService.VerifyQuest(item?.id!, index).then((res: any) => {
            Toast.success("Quest completed")
            dispatch(setEntry(res.data.entry))
            setCheckingQuest(false)
        }).catch((err: any) => {
            if (err.response.status !== 429) {
                if (err.response.data.code)
                    Toast.error(ErrorCodes[err.response.data.code])
                else
                    Toast.error("Error completing quest")
            }

            setCheckingQuest(false)
        })
    }

    const checkNominated = () => {
        if (!user) {
            Toast.error("Please login to continue")
            displayWalletConnectPopup(true)
            return;
        }
        if (!item) return;
        if (checkingNominated) return;

        setCheckingNominated(true)

        WLService.AmIEligible(item.id!).then((res: any) => {

            if (item.recruitType === Recruit.TwitterNomination) {
                dispatch(setOgCount(res.data.ogCount))
                dispatch(setEligible(res.data.eligible))
            }

            if (res.data.eligible) {
                setNominator(res.data.nominator)
                WLService.GetWhitelistEntry(item.id!).then((res: any) => {

                    if (res.data.entry !== null) {
                        dispatch(setEntry(res.data.entry))
                    } else {
                        dispatch(setEntry(false)) //if the user is eligible but has no entry, set entry to false. allow user to create an entry
                        Toast.success("You are nominated")
                    }

                }).catch((err: any) => {
                    Toast.error("An error occured")
                })
            } else {
                Toast.error("You are not nominated")
            }

            setCheckingNominated(false)

        }).catch((err: any) => {
            Toast.error("An error occured")
            setCheckingNominated(false)
        })
    }

    const selectWallet = () => {
        dispatch(showWhitelistWalletSelectPopup(true))
    }

    /*DC*/
    const joinDiscord = () => {

        if (!user) {
            Toast.error("Please login to continue")
            displayWalletConnectPopup(true, item?.network)
            return;
        }

        window.open((item?.recruitDetails as _RecruitDiscordRole).channelInviteLink, "_blank")

    }

    const verifyDiscord = () => {

        if (!user) {
            Toast.error("Please login to continue")
            displayWalletConnectPopup(true, item?.network)
            return;
        }

        WLService.VerifyDiscordRole(item!.id!).then((res: any) => {
            Toast.success("Discord role verified")
            dispatch(setEntry(res.data.entry))
        }).catch((err: any) => {
            if (err.response.status !== 429) {
                if (err.response.data.code) {
                    if (err.response.data.code === "DISCORD_REAUTH") {
                        Toast.error("Please re-authenticate your discord account")
                        RefreshUser();
                        return;
                    } else if (err.response.data.code === "NO_DISCORD") {
                        Toast.error("Please connect your discord account")
                        displaySettings()
                        return;
                    }
                    else
                        Toast.error(ErrorCodes[err.response.data.code])
                } else
                    Toast.error("Error verifying discord role")
            }
        })
    }

    const closePage = () => {
        navigate("/app/whitelist")
    }

    const isRequiredSocialMediaConnected = () => {

        if (!user) return true;

        if (wldata.entry && wldata.entry.whitelisted) return true;

        let discord = false
        let twitter = false

        if (item?.recruitType === Recruit.DiscordRole) {
            discord = true
        }

        if (item?.recruitType === Recruit.TwitterNomination) {
            twitter = true
        }

        //iterate through quests
        if (item?.quests) {
            item.quests.forEach((q) => {
                if (q.id === "_QuestJoinDiscord") {
                    discord = true
                }
                if (q.id === "_QuestTwitterFollow") {
                    twitter = true
                }
                if (q.id === "_QuestTweetInteraction") {
                    twitter = true
                }
            })
        }

        if (discord && !user?.discord) {
            return false
        }

        if (twitter && !user?.twitter) {
            return false
        }

        return true
    }

    const oauthDiscordRedirect = () => {
        if (!user.discord)
            window.location.href = `https://discord.com/api/oauth2/authorize?client_id=${env.DISCORD.client_id}&redirect_uri=${encodeURIComponent(env.API.WEB)}app&response_type=code&scope=guilds.members.read%20identify%20guilds&state=${user.nonce}`
    }

    const oauthTwitterRedirect = () => {
        if (!user.twitter) {
            twitterRequestToken().then((res: any) => {
                window.location.href = `https://api.twitter.com/oauth/authenticate?oauth_token=${res.data.token}`
            }).catch((err: any) => {
                Toast.error("Error connecting to Twitter")
            })
        }
    }

    const fcfsRedeemPopup = () => {
        if (!user) {
            Toast.error("Please login to continue")
            displayWalletConnectPopup(true)
            return;
        }

        dispatch(showFcfsPopup(true))
    }

    if (loading === true) {
        return (
            <C.Loading>
                <FontAwesomeIcon icon={faSpinner} spin />
            </C.Loading>
        )
    }

    if (loading === null) {
        return (
            <C.Failed>
                Whitelist not found
                <Button theme="gradient-border-transparent-bg-on-hover" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)} onClick={closePage}>Close Page</Button>
            </C.Failed>
        )
    }

    if (!item) {
        return (
            <></>
        )
    }

    if (!done) {
        return (
            <></>
        )
    }

    return (
        <C.Whitelist>

            <C.Header>
                <C.HeaderTop>
                    <C.HeaderTitle>
                        <C.HeaderTitleIcon> <IconWhitelist /> </C.HeaderTitleIcon>
                        <C.HeaderTitleText>{item.name} Whitelist</C.HeaderTitleText>
                    </C.HeaderTitle>
                    <C.HeaderClose onClick={closePage}>
                        &times;
                    </C.HeaderClose>
                </C.HeaderTop>
                {(item.status === State.ACTIVE && wldata.entry && wldata.eligible) && (
                    <>
                        <C.WhitelistStatus whitelisted={wldata.entry.whitelisted ? "true" : "false"}>
                            <C.WhitelistStatusTitle>
                                Whitelist Status :
                                <span>
                                    {wldata.entry.whitelisted ? " Whitelisted" : " Not Whitelisted"}
                                </span>
                            </C.WhitelistStatusTitle>
                            <C.WhitelistStatusDesc>
                                {wldata.entry.whitelisted ? "" : "Please complete the following tasks to be whitelisted."}
                            </C.WhitelistStatusDesc>
                        </C.WhitelistStatus>
                    </>
                )}

                <WhitelistCollectionWidget view="true" item={item} />

            </C.Header>

            <C.Body>

                {item.status === State.INACTIVE && (
                    <C.WhitelistClosed>
                        <C.WhitelistClosedTitle>Whitelist Closed</C.WhitelistClosedTitle>
                        <C.WhitelistClosedText>This whitelist is closed. You can no longer join.</C.WhitelistClosedText>
                        {wldata.eligible !== null && (
                            <>
                                {(wldata.entry && wldata.eligible) && (
                                    <C.WhitelistClosedText>You were <span className={wldata.entry.whitelisted ? '' : 'n'}>{wldata.entry.whitelisted ? "Whitelisted" : "Not Whitelisted"}</span></C.WhitelistClosedText>
                                )}
                                {(wldata.entry && !wldata.eligible) && (
                                    <C.WhitelistClosedText>You were <span>Not Whitelisted</span></C.WhitelistClosedText>
                                )}
                            </>
                        )}
                    </C.WhitelistClosed>
                )}

                {item.status === State.PASSIVE && (
                    <C.WhitelistClosed>
                        <C.WhitelistClosedTitle>Coming Soon</C.WhitelistClosedTitle>
                        <C.WhitelistClosedText>
                            Stay tuned for more information on this whitelist.
                        </C.WhitelistClosedText>
                    </C.WhitelistClosed>
                )}

                {(item.status === State.ACTIVE && !isRequiredSocialMediaConnected()) && (
                    <C.SocialMediaRequired>
                        <C.GhostImage>
                            <img src="/images/etc/ghost.png" alt="ghost" />
                        </C.GhostImage>
                        <C.SocialMediaRequiredTitle>
                            Oops!
                        </C.SocialMediaRequiredTitle>
                        <C.SocialMediaRequiredText>
                            You must connect your <span>social media</span> to join whitelist.
                        </C.SocialMediaRequiredText>
                        <C.SocialMediaRequiredButtons>
                            {user.twitter === "" && (
                                <C.Social hc="#1DA1F2" onClick={oauthTwitterRedirect}>
                                    <FontAwesomeIcon icon={faTwitter} /> Connect Twitter
                                </C.Social>
                            )}
                            {user.discord === "" && (
                                <C.Social hc="#5865F2" onClick={oauthDiscordRedirect}>
                                    <FontAwesomeIcon icon={faDiscord} /> Connect Discord
                                </C.Social>
                            )}
                        </C.SocialMediaRequiredButtons>
                    </C.SocialMediaRequired>
                )}

                {(item.status === State.ACTIVE && isRequiredSocialMediaConnected()) && (
                    <>

                        {(item.recruitType === Recruit.TwitterNomination) && (() => {
                            const recruit = item.recruitDetails as _RecruitTwitterNomination
                            return (
                                <>
                                    {(item.entryCount < item.whitelistCount && wldata.eligible === false) && (
                                        <>
                                            {wldata.ogCount < recruit.ogLimit && (
                                                <C.OG>
                                                    <C.OGRocket>
                                                        <C.OGRocketBg>
                                                            <img src="/images/etc/wlrocketbg.png" alt="rocket" />
                                                        </C.OGRocketBg>
                                                        <C.OGRocketImage>
                                                            <img src="/images/etc/wlrocket.png" alt="rocket" />
                                                        </C.OGRocketImage>
                                                        <C.OGRocketText>
                                                            {wldata.ogCount}<span>/</span>{recruit.ogLimit}
                                                        </C.OGRocketText>
                                                    </C.OGRocket>
                                                    <C.OGTitle><span>Alpha Spot Available</span></C.OGTitle>
                                                    <C.OGDesc>Alpha spots are reserved for the <span>first {recruit.ogLimit} people</span> that claim them. Alpha People doesn't need to be nominated.</C.OGDesc>
                                                    <Button loading={joiningWl ? "true" : "false"} disabled={joiningWl} onClick={join} theme="gradient" block="true" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)}>Claim an Alpha Spot</Button>
                                                </C.OG>
                                            )}
                                            {wldata.ogCount >= recruit.ogLimit && (
                                                <C.OG>
                                                    <C.NeedtoNominatedImage>
                                                        <img src="/images/etc/needtonominated.png" alt="need to nominated" />
                                                    </C.NeedtoNominatedImage>
                                                    <C.NeedtoNominatedText>
                                                        <span>You need to be</span> <div>nominated</div> <span>to join the</span> whitelist
                                                    </C.NeedtoNominatedText>

                                                    <C.AskForNomination>
                                                        <C.AskForNominationIcon>
                                                            <img src="/images/map/whitelist.png" alt="" />
                                                        </C.AskForNominationIcon>
                                                        <C.AskForNominationText>
                                                            Ask nomination from<br /><span>whitelisted people</span> in map
                                                        </C.AskForNominationText>
                                                    </C.AskForNomination>

                                                    <C.ButtonGroup>
                                                        <Button onClick={checkNominated} loading={checkingNominated ? "true" : "false"} disabled={checkingNominated} theme="gradient" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)}>Check someone nominated me</Button>
                                                    </C.ButtonGroup>
                                                    <C.RedeemCode onClick={fcfsRedeemPopup}>
                                                        Redeem fcfs code
                                                    </C.RedeemCode>
                                                </C.OG>
                                            )}
                                        </>
                                    )}

                                    {wldata.entry && wldata.entry.whitelisted && (
                                        <>
                                            <C.Whitelisted>

                                                <C.WlistedRocket>
                                                    <C.WlistedRocketBg>
                                                        <img src="/images/etc/wlistedrocketbg.png" alt="rocket" />
                                                    </C.WlistedRocketBg>
                                                    <C.WlistedRocketImage>
                                                        <img src="/images/etc/wlistedrocket.png" alt="rocket" />
                                                    </C.WlistedRocketImage>
                                                </C.WlistedRocket>

                                                <C.WhitelistedTitle>
                                                    You are Whitelisted!
                                                </C.WhitelistedTitle>

                                                <C.WhitelistedDesc>
                                                    All you have to do now is to wait for minting to start.<br />
                                                    <a href={`https://twitter.com/intent/tweet?text=I have been ${recruit.nominationWordPT} on ${item.name} whitelist${GetTwitterHandle(item.twitter)}%0a%0aGet yours too!%0a${env.API.WEB}app/whitelist/${toLink(item.name)}%0a${GetRandomGif(item)}${item.shareSuffix ? "%0a" + item.shareSuffix : ""}`} target="_blank">Share <FontAwesomeIcon icon={faUpRightFromSquare} /></a>
                                                </C.WhitelistedDesc>

                                                <C.SelectWallet onClick={selectWallet}>
                                                    <C.SelectWalletLeft>
                                                        <C.SelectWalletTitle>
                                                            {!wldata.entry.wallet && "Whitelisted Wallet"}
                                                            {wldata.entry.wallet && (<>Selected Wallet <span>{user.wallets.find((x: any) => x.address === wldata.entry.wallet)?.name ?? ""}</span></>)}
                                                        </C.SelectWalletTitle>
                                                        <C.SelectWalletAddress>
                                                            {wldata.entry.wallet ? shortenPublicKey(wldata.entry.wallet) : (<><FontAwesomeIcon icon={faCircleExclamation} /> No wallet selected</>)}
                                                        </C.SelectWalletAddress>
                                                    </C.SelectWalletLeft>
                                                    <FontAwesomeIcon icon={faChevronRight} />
                                                </C.SelectWallet>

                                                <Button onClick={nominateMorePopup} block="true" theme="gradient" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)}>Nominate More People</Button>

                                                <C.TotalNominated>
                                                    <span>Total Nominated: </span>
                                                    {wldata.entry.recruitDetails.nominations.filter((x: any) => x.verified).length}/{recruit.maximumNominationCount}
                                                </C.TotalNominated>



                                            </C.Whitelisted>
                                        </>
                                    )}

                                    {(item.entryCount >= item.whitelistCount && (!wldata.entry || (wldata.entry && !wldata.entry.whitelisted))) && (
                                        <>
                                            <C.WhitelistFull>
                                                <C.GhostImage>
                                                    <img src="/images/etc/ghost.png" alt="ghost" />
                                                </C.GhostImage>
                                                <C.WhitelistFullTitle>
                                                    Whitelist is <span>Full :(</span>
                                                </C.WhitelistFullTitle>

                                                <Button link="routerlink" to="/app/whitelist" theme="gradient" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)}>Explore More</Button>
                                            </C.WhitelistFull>
                                        </>
                                    )}

                                    {(item.entryCount < item.whitelistCount && (wldata.eligible && (wldata.entry && wldata.entry.whitelisted) === false)) && (
                                        <>
                                            {wldata.entry === false && (
                                                <>
                                                    <C.Eligible>
                                                        <C.EligibleTitle>
                                                            You are <span>Eligible</span> to join the whitelist
                                                        </C.EligibleTitle>
                                                        <C.EligibleDesc>
                                                            You can join the whitelist by clicking the button below.
                                                        </C.EligibleDesc>

                                                        {nominator && (
                                                            <C.EligibleNominator>
                                                                Your nominator: <Link to={"/app/profile/" + nominator.id}>{nominator.username}</Link>
                                                            </C.EligibleNominator>
                                                        )}

                                                        <Button block="true" loading={joiningWl ? "true" : "false"} disabled={joiningWl} onClick={join} theme="gradient" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)}>Join Whitelist</Button>
                                                    </C.Eligible>
                                                </>
                                            )}

                                            {wldata.entry && (
                                                <>
                                                    <C.Entry>
                                                        <C.Nominate>
                                                            <C.Title>
                                                                <C.TitleText>
                                                                    Nominations
                                                                </C.TitleText>
                                                                <C.TitleStatus type={isNominationsDone() ? "completed" : "not-completed"}>
                                                                    {isNominationsDone() ? "Completed" : "Not Completed"}
                                                                </C.TitleStatus>
                                                            </C.Title>
                                                            <C.NominateDesc className={isNominationsDone() ? "completed" : ""}>
                                                                You need to nominate <span>{recruit.minimumNominationCount} Twitter user</span> to earn a whitelist spot
                                                            </C.NominateDesc>
                                                            <C.NominateButtons className={isNominationsDone() ? "completed" : ""}>
                                                                <Button block="true" disabled={isNominationsDone()} onClick={nominatePopup} theme="gradient" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)}>Nominate People</Button>

                                                                {(wldata.entry.recruitDetails && wldata.entry.recruitDetails.nominations) && (
                                                                    <Button block="true" disabled={checkingNomination || isNominationsDone()} onClick={checkNomination} loading={checkingNomination ? "true" : "false"} theme="gradient-border-transparent-bg-on-hover" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)}>Verify Nominations</Button>
                                                                )}
                                                            </C.NominateButtons>

                                                            {!isNominationsDone() && (
                                                                <C.NominationMessage>
                                                                    If you don't verify your nominations, nominations won't be counted.
                                                                </C.NominationMessage>
                                                            )}

                                                        </C.Nominate>

                                                        {item.quests && item.quests.length > 0 && (

                                                            <C.Quests>
                                                                <C.QuestsTitle>
                                                                    <C.TitleText>
                                                                        Quests
                                                                    </C.TitleText>
                                                                    <C.TitleStatus type={(wldata.entry.questDetails ? Array.isArray(Object.values(wldata.entry.questDetails)) ? Object.values(wldata.entry.questDetails).filter((x: any) => x).length >= item!.quests.length : false : false) ? "completed" : "not-completed"}>
                                                                        {(wldata.entry.questDetails ? Array.isArray(Object.values(wldata.entry.questDetails)) ? Object.values(wldata.entry.questDetails).filter((x: any) => x).length >= item!.quests.length : false : false) ? "Completed" : "Not Completed"}
                                                                    </C.TitleStatus>
                                                                </C.QuestsTitle>
                                                                <C.QuestsDesc>
                                                                    You need to do and verify <span>{item.quests.length} quests</span> to earn a whitelist spot
                                                                </C.QuestsDesc>
                                                                <C.QuestsList>

                                                                    {item.quests.map((quest, i) => (

                                                                        <C.QuestsListItem key={i}>
                                                                            <C.QuestsListItemTitle>
                                                                                <C.QuestsListItemTitleText>
                                                                                    {quest.id === '_QuestJoinDiscord' && 'Join Discord'}
                                                                                    {quest.id === '_QuestTwitterFollow' && 'Follow Twitter'}
                                                                                    {quest.id === '_QuestTweetInteraction' && (
                                                                                        (quest.details as _QuestTweetInteraction).like && (quest.details as _QuestTweetInteraction).retweet ? 'Like & Retweet' : (quest.details as _QuestTweetInteraction).like ? 'Like Tweet' : 'Like & Retweet'
                                                                                    )}
                                                                                </C.QuestsListItemTitleText>
                                                                                <C.QuestsListItemTitleInfo>
                                                                                    {quest.id === '_QuestJoinDiscord' && (<a href={(quest.details as _QuestJoinDiscord).discord} target="_blank">{(quest.details as _QuestJoinDiscord).discordName} <FontAwesomeIcon icon={faUpRightFromSquare} /></a>)}
                                                                                    {quest.id === '_QuestTwitterFollow' && (<a href={"https://twitter.com/" + (quest.details as _QuestTwitterFollow).twitter} target="_blank">{"@" + (quest.details as _QuestTwitterFollow).twitter} <FontAwesomeIcon icon={faUpRightFromSquare} /></a>)}
                                                                                    {quest.id === '_QuestTweetInteraction' && (<a href={"https://twitter.com/anyuser/status/" + (quest.details as _QuestTweetInteraction).tweetId} target="_blank">Tweet <FontAwesomeIcon icon={faUpRightFromSquare} /></a>)}
                                                                                </C.QuestsListItemTitleInfo>
                                                                                {!isQuestDone(i) && (
                                                                                    <C.QuestsListItemCheck onClick={() => checkQuest(i)}>
                                                                                        Verify Quest
                                                                                    </C.QuestsListItemCheck>
                                                                                )}
                                                                            </C.QuestsListItemTitle>
                                                                            <C.QuestsListItemStatus>
                                                                                {(!isQuestDone(i) && !attemptedQuests.includes(i)) && (
                                                                                    <Button onClick={() => doQuest(quest, i)} theme="gradient" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)}>Do Quest</Button>
                                                                                )}
                                                                                {isQuestDone(i) && (
                                                                                    <Button className="completed" theme="gradient" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)}>Completed</Button>
                                                                                )}
                                                                                {(!isQuestDone(i) && attemptedQuests.includes(i)) && (
                                                                                    <>
                                                                                        <Button onClick={() => checkQuest(i)} theme="gradient" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)}>Verify Quest</Button>
                                                                                        <C.QuestsListItemCheck onClick={() => doQuest(quest, i)}>
                                                                                            Do Quest
                                                                                        </C.QuestsListItemCheck>
                                                                                    </>
                                                                                )}

                                                                            </C.QuestsListItemStatus>
                                                                        </C.QuestsListItem>

                                                                    ))}
                                                                </C.QuestsList>
                                                            </C.Quests>

                                                        )}
                                                    </C.Entry>
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            )
                        })()}

                        {(item.recruitType === Recruit.DiscordRole) && (() => {
                            const recruit = item.recruitDetails as _RecruitDiscordRole
                            return (
                                <>
                                    {(item.entryCount < item.whitelistCount && wldata.entry === null) && (
                                        <>
                                            <C.DiscordRecruit>
                                                <C.DiscordRecruitImage>
                                                    <FontAwesomeIcon icon={faDiscord} />
                                                </C.DiscordRecruitImage>
                                                <C.DiscordRecruitTitle>
                                                    Discord Role Requirement
                                                </C.DiscordRecruitTitle>
                                                <C.DiscordRecruitDesc>
                                                    <span>You need to join the Discord channel </span>{recruit.channelName}<span> and have the </span>{recruit.roleName} <span>role to join the whitelist.</span>
                                                </C.DiscordRecruitDesc>
                                                <C.DiscordRecruitButtons>
                                                    <Button onClick={joinDiscord} block="true" theme="gradient" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)}>Join Discord</Button>
                                                    <Button onClick={verifyDiscord} block="true" theme="gradient-border-transparent-bg-on-hover" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)}>Verify Role</Button>
                                                </C.DiscordRecruitButtons>
                                            </C.DiscordRecruit>
                                        </>
                                    )}
                                    {(item.entryCount >= item.whitelistCount && (!wldata.entry || (wldata.entry && !wldata.entry.whitelisted))) && (
                                        <>
                                            <C.WhitelistFull>
                                                <C.GhostImage>
                                                    <img src="/images/etc/ghost.png" alt="ghost" />
                                                </C.GhostImage>
                                                <C.WhitelistFullTitle>
                                                    Whitelist is <span>Full :(</span>
                                                </C.WhitelistFullTitle>

                                                <Button link="routerlink" to="/app/whitelist" theme="gradient" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)}>Explore More</Button>
                                            </C.WhitelistFull>
                                        </>
                                    )}
                                    {(wldata.entry && wldata.entry.whitelisted && user) && (
                                        <>
                                            <C.Whitelisted>

                                                <C.WlistedRocket>
                                                    <C.WlistedRocketBg>
                                                        <img src="/images/etc/wlistedrocketbg.png" alt="rocket" />
                                                    </C.WlistedRocketBg>
                                                    <C.WlistedRocketImage>
                                                        <img src="/images/etc/wlistedrocket.png" alt="rocket" />
                                                    </C.WlistedRocketImage>
                                                </C.WlistedRocket>

                                                <C.WhitelistedTitle>
                                                    You are Whitelisted!
                                                </C.WhitelistedTitle>

                                                <C.WhitelistedDesc>
                                                    All you have to do now is to wait for minting to start.<br />
                                                    <a href={`https://twitter.com/intent/tweet?text=I secured ${GetTwitterHandle(item.twitter)} whitelist on @hub3ee dont miss it%0a${env.API.WEB}app/whitelist/${toLink(item.name)}${item.shareSuffix ? "%0a" + item.shareSuffix : ""}`} target="_blank">Share <FontAwesomeIcon icon={faUpRightFromSquare} /></a>

                                                </C.WhitelistedDesc>

                                                <C.SelectWallet onClick={selectWallet}>
                                                    <C.SelectWalletLeft>
                                                        <C.SelectWalletTitle>
                                                            {!wldata.entry.wallet && "Whitelisted Wallet"}
                                                            {wldata.entry.wallet && (<>Selected Wallet <span>{user.wallets.find((x: any) => x.address === wldata.entry.wallet)?.name ?? ""}</span></>)}
                                                        </C.SelectWalletTitle>
                                                        <C.SelectWalletAddress>
                                                            {wldata.entry.wallet ? shortenPublicKey(wldata.entry.wallet) : (<><FontAwesomeIcon icon={faCircleExclamation} /> No wallet selected</>)}
                                                        </C.SelectWalletAddress>
                                                    </C.SelectWalletLeft>
                                                    <FontAwesomeIcon icon={faChevronRight} />
                                                </C.SelectWallet>
                                            </C.Whitelisted>
                                        </>
                                    )}
                                    {(item.entryCount < item.whitelistCount && wldata.entry && !wldata.entry.whitelisted) && (
                                        <>
                                            <C.Entry>

                                                <C.DiscordRecruitEntry>
                                                    <C.Title>
                                                        <C.TitleText>
                                                            Verify Discord Role
                                                        </C.TitleText>
                                                        <C.TitleStatus type={"completed"}>
                                                            Completed
                                                        </C.TitleStatus>
                                                    </C.Title>

                                                </C.DiscordRecruitEntry>

                                                {item.quests && item.quests.length > 0 && (

                                                    <C.Quests>
                                                        <C.QuestsTitle>
                                                            <C.TitleText>
                                                                Quests
                                                            </C.TitleText>
                                                            <C.TitleStatus type={(wldata.entry.questDetails ? Array.isArray(Object.values(wldata.entry.questDetails)) ? Object.values(wldata.entry.questDetails).filter((x: any) => x).length >= item!.quests.length : false : false) ? "completed" : "not-completed"}>
                                                                {(wldata.entry.questDetails ? Array.isArray(Object.values(wldata.entry.questDetails)) ? Object.values(wldata.entry.questDetails).filter((x: any) => x).length >= item!.quests.length : false : false) ? "Completed" : "Not Completed"}
                                                            </C.TitleStatus>
                                                        </C.QuestsTitle>
                                                        <C.QuestsDesc>
                                                            You need to do and verify <span>{item.quests.length} quests</span> to earn a whitelist spot
                                                        </C.QuestsDesc>
                                                        <C.QuestsList>

                                                            {item.quests.map((quest, i) => (

                                                                <C.QuestsListItem key={i}>
                                                                    <C.QuestsListItemTitle>
                                                                        <C.QuestsListItemTitleText>
                                                                            {quest.id === '_QuestJoinDiscord' && 'Join Discord'}
                                                                            {quest.id === '_QuestTwitterFollow' && 'Follow Twitter'}
                                                                            {quest.id === '_QuestTweetInteraction' && (
                                                                                (quest.details as _QuestTweetInteraction).like && (quest.details as _QuestTweetInteraction).retweet ? 'Like & Retweet' : (quest.details as _QuestTweetInteraction).like ? 'Like Tweet' : 'Retweet'
                                                                            )}
                                                                        </C.QuestsListItemTitleText>
                                                                        <C.QuestsListItemTitleInfo>
                                                                            {quest.id === '_QuestJoinDiscord' && (<a href={(quest.details as _QuestJoinDiscord).discord} target="_blank">{(quest.details as _QuestJoinDiscord).discordName} <FontAwesomeIcon icon={faUpRightFromSquare} /></a>)}
                                                                            {quest.id === '_QuestTwitterFollow' && (<a href={"https://twitter.com/" + (quest.details as _QuestTwitterFollow).twitter} target="_blank">{"@" + (quest.details as _QuestTwitterFollow).twitter} <FontAwesomeIcon icon={faUpRightFromSquare} /></a>)}
                                                                            {quest.id === '_QuestTweetInteraction' && (<a href={"https://twitter.com/anyuser/status/" + (quest.details as _QuestTweetInteraction).tweetId} target="_blank">Tweet <FontAwesomeIcon icon={faUpRightFromSquare} /></a>)}
                                                                        </C.QuestsListItemTitleInfo>
                                                                        {!isQuestDone(i) && (
                                                                            <C.QuestsListItemCheck onClick={() => checkQuest(i)}>
                                                                                Check Quest
                                                                            </C.QuestsListItemCheck>
                                                                        )}
                                                                    </C.QuestsListItemTitle>
                                                                    <C.QuestsListItemStatus>
                                                                        {(!isQuestDone(i) && !attemptedQuests.includes(i)) && (
                                                                            <Button onClick={() => doQuest(quest, i)} theme="gradient" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)}>Do Quest</Button>
                                                                        )}
                                                                        {isQuestDone(i) && (
                                                                            <Button className="completed" theme="gradient" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)}>Completed</Button>
                                                                        )}
                                                                        {(!isQuestDone(i) && attemptedQuests.includes(i)) && (
                                                                            <>
                                                                                <Button onClick={() => checkQuest(i)} theme="gradient" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)}>Verify Quest</Button>
                                                                                <C.QuestsListItemCheck onClick={() => doQuest(quest, i)}>
                                                                                    Do Quest
                                                                                </C.QuestsListItemCheck>
                                                                            </>
                                                                        )}

                                                                    </C.QuestsListItemStatus>
                                                                </C.QuestsListItem>

                                                            ))}
                                                        </C.QuestsList>
                                                    </C.Quests>

                                                )}

                                            </C.Entry>
                                        </>
                                    )}
                                </>
                            )
                        })()}

                    </>
                )}

            </C.Body>

        </C.Whitelist>
    )
}

export default Whitelist