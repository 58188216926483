import axios from "axios"
import env from "env"
import { getLocalData, removeLocalData, setLocalData } from "utils/localData"

export const updateProfile = async (data : any) => {
    return axios.put(env.API.URL + "user/profile/update", data, {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}

//multi part form data upload profile picture
export const updateAvatar = async (data : any) => {
    return axios.put(env.API.URL + "user/profile/update/avatar", data, {
        headers: {
            Authorization: "Bearer " + getLocalData("token"),
            'Content-Type': 'multipart/form-data'
        }
    })
}

//multi part form data upload avatar picture
export const updateCover = async (data : any) => {
    return axios.put(env.API.URL + "user/profile/update/cover", data, {
        headers: {
            Authorization: "Bearer " + getLocalData("token"),
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const deleteAvatar = async () => {
    return axios.delete(env.API.URL + "user/profile/delete/avatar", {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}

export const deleteCover = async () => {
    return axios.delete(env.API.URL + "user/profile/delete/cover", {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}

export const getMe = async () => {
    return axios.get(env.API.URL + "user/actions/get/me", {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}

export const updateLocation = async (data : any) => {
    return axios.put(env.API.URL + "user/profile/update/location", data, {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}

export const removeLocation = async () => {
    return axios.delete(env.API.URL + "user/profile/delete/location", {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}

export const addCollectionToFavorites = async (id : any) => {
    return axios.post(env.API.URL + "user/actions/collections/favorites",{ id }, {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}

export const removeCollectionFromFavorites = async (id : any) => {
    return axios.delete(env.API.URL + "user/actions/collections/favorites/"+ id, {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}

export const introduced = async () => {
    return axios.get(env.API.URL + "user/actions/introduced", {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}

export const getAirdropPoints = async () => {
    return axios.get(env.API.URL + "user/profile/airdrop-points", {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}