import { useSelector } from "react-redux"
import * as P from "./style"

import BaseMobilePage from "components/mobile/base"
import { IconCollections } from "utils/icons"

import { MainParts } from "utils/parts"
import { faUser } from "@fortawesome/free-solid-svg-icons"
import Shares from "components/parts/shares"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const MobilePage = () => {

    const show = useSelector((state: any) => state.data.currentPage)

    return (
        <P.MobilePage>
            <BaseMobilePage title="Keys" show={show === MainParts.Shares} icon={<IconCollections/>} glasmorph={true}>
                <Shares />
            </BaseMobilePage>
        </P.MobilePage>
    )
}

export default MobilePage