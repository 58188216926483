import axios from "axios"
import env from "env"
import { getLocalData } from "utils/localData"

export const AmIEligible = async (id : string) => {
    return axios.get(`${env.API.URL}whitelist/actions/eligible/${id}`,{
        headers: {
            Authorization: "Bearer " + getLocalData("token"),
            
        }
    })
}

export const GetWhitelistEntry = async (id : string) => {
    return axios.get(`${env.API.URL}whitelist/actions/get/${id}`,{
        headers: {
            Authorization: "Bearer " + getLocalData("token"),
            
        }
    })
}

export const GetWhitelistEntries = async () => {
    return axios.get(`${env.API.URL}whitelist/actions/get/entries`,{
        headers: {
            Authorization: "Bearer " + getLocalData("token"),
            
        }
    })
}

export const JoinWhitelist = async (id : string) => {
    return axios.get(`${env.API.URL}whitelist/actions/join/${id}`,{
        headers: {
            Authorization: "Bearer " + getLocalData("token"),
            
        }
    })
}

export const JoinWhitelistFcfs = async (id : string, code : string) => {
    return axios.post(`${env.API.URL}whitelist/actions/join/redeem/${id}`, {fcfsCode:code}, {
        headers: {
            Authorization: "Bearer " + getLocalData("token"),
            
        }
    })
}

export const SetInitialNominations = async (id : string, nominations : string[]) => {
    return axios.post(`${env.API.URL}whitelist/actions/set/${id}/nominations/initial`, {
        nominations
    },{
        headers: {
            Authorization: "Bearer " + getLocalData("token"),
            
        }
    })
}

export const SetMoreNominations = async (id : string, nominations : string[]) => {
    return axios.post(`${env.API.URL}whitelist/actions/set/${id}/nominations/more`, {
        nominations
    },{
        headers: {
            Authorization: "Bearer " + getLocalData("token"),
            
        }
    })
}

export const RemoveMoreNominations = async (id : string) => {
    return axios.delete(`${env.API.URL}whitelist/actions/set/${id}/nominations/more`, {
        headers: {
            Authorization: "Bearer " + getLocalData("token"),
        }
    })
}

export const VerifyNomination = async (id : string) => {
    return axios.get(`${env.API.URL}whitelist/actions/verify/${id}/nomination`,{
        headers: {
            Authorization: "Bearer " + getLocalData("token"),
            
        }
    })
}

export const VerifyQuest = async (id : string, quest: number) => {
    return axios.get(`${env.API.URL}whitelist/actions/verify/${id}/quest/${quest}`,{
        headers: {
            Authorization: "Bearer " + getLocalData("token"),
            
        }
    })
}

export const updateWalletOfWhitelistEntry = async (id : string, address : string) => {
    return axios.put(`${env.API.URL}whitelist/actions/update/${id}/wallet`, {
        wallet:address
    },{
        headers: {
            Authorization: "Bearer " + getLocalData("token"),
            
        }
    })
}

export const VerifyDiscordRole = async (id : string) => {
    return axios.get(`${env.API.URL}whitelist/actions/verify/${id}/discord-role`,{
        headers: {
            Authorization: "Bearer " + getLocalData("token"),
            
        }
    })
}