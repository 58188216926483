import axios from "axios"
import env from "env"
import { store } from "services/store"
import { getLocalData, removeLocalData, setLocalData } from "utils/localData"

export const getRooms = async () => {
    return axios.get(env.API.URL + "chat/get/rooms", {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}

export const getRoomHistory = async (id:string, before?:string) => {
    return axios.post(env.API.URL + "chat/get/rooms/"+id+"/history", {before}, {
        headers: {
            Authorization: "Bearer " + getLocalData("token"),
            "X-share-chat-token": "Bearer " +  store.getState().chat.chatToken
        }
    })
}
