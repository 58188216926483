import styled from "styled-components"

import { color } from "styles/theme"
import { PageBody, MobilePage as Page } from "../base/style"


export const MobilePage = styled.div`

    & ${PageBody} {
        padding:0;
    }

`