import axios from "axios"
import toast from "react-hot-toast"

export const responseInterceptor = () => {

    //if response is 429, show a toast
    axios.interceptors.response.use(
        response => response,
        error => {
            if (error.response.status === 429) {
                toast.error("Too many requests, try again later")
            }
            return Promise.reject(error)
        }
    )
}