import React, { useEffect } from "react"
import * as C from "./style"
import ReactTooltip from "react-tooltip"
import { useSelector } from "react-redux"
import AppBar from "components/parts/appBar"
import Header from "components/parts/header"

const BarWidget = () => {

    useEffect(() => {
        ReactTooltip.rebuild()
        ReactTooltip.hide()
    }, [])

    const isMobile = useSelector((state: any) => state.data.isMobile)

    return (
        <C.MenuWidget>

            {!isMobile ? (
                <Header/>
            ) : (
                <AppBar/>
            )}

        </C.MenuWidget>
    )
}

export default BarWidget