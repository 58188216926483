import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { deviceMax } from "styles/media"

export const LaunchMintResult = styled.div`

`

export const Title = styled.div`
    font-size:24px;
    background: linear-gradient(90deg, ${color.gradient1.start}, ${color.gradient1.middle}, ${color.gradient1.end});
    font-weight:600;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align:center;
    margin-top: 12px;
`

export const Buttons = styled.div`
    display:flex;
    & > * {
        flex:1;
    }

    & > *:not(:last-child) {
        margin-right: 10px;
    }
`


export const Covers = styled.div`
    margin-top:16px;
`

export const Cover = styled.div`
& img{
    width:100%;
    border-radius:16px;
}
`

export const CoverSlider = styled.div`

    & *{
        outline: none !important;
    }

    & .slick-list {
        padding-top: 50px !important;
        padding-bottom: 50px !important;
    }

    & .slick-slide {
        transition: transform 0.2s ease;
    }

    & .slick-center {
        transform: scale(1.3);

        & ${Cover} {
            box-shadow: 0px 0px 20px 0px ${Hex2Rgba(color.black, 0.2)};
        }
    }

    & .slick-next {
        right: 16px;
        z-index: 1;
    }

    & .slick-prev {
        left: 16px;
        z-index: 1;
    }
`

export const Text = styled.div`
    text-align:center;
    & span{
        color:${color.primary}
    }
    font-size:14px;
    
    margin-bottom:16px;
`

export const Mints = styled.div`
    & > *:not(:last-child) {
        margin-bottom:8px;
    }
    margin-bottom:16px;
`

export const Mint = styled.div`
    display:flex;
    justify-content:space-between;
`

export const MintName = styled.div`
    font-size:14px;
`

export const View = styled.div``