import React, { useEffect, useState } from "react"
import * as C from "./style"

import { useDispatch, useSelector } from "react-redux"
import { WhitelistCollection } from "models/whitelist/collection"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import Button from "components/ui/button"
import { copyObject, Hex2Rgba } from "utils/helpers"
import { color } from "styles/theme"
import { updateWalletOfWhitelistEntry } from "services/api/whitelist"
import toast from "react-hot-toast"
import { showConnectedWalletSelectPopup } from "services/slices/popup"
import { setEntry } from "services/slices/whitelist"
import { displaySettings, displayWalletConnectPopup } from "utils/parts"
import { findWallet } from "modules/wallet"
import { Network } from "models/enums/network"
import { setGlobalSelectedWallet } from "services/slices/user"
import { setLocalData } from "utils/localData"

const ConnectedWalletSelect = () => {

    const [solanaWallets, setSolanaWallets] = React.useState<any>([])
    const user = useSelector((state: any) => state.user.user)
    const [selectedWallet, setSelectedWallet] = useState<any>(null)
    const globalSelectedWallet = useSelector((state: any) => state.user.selectedWallet)
    const dispatch = useDispatch()

    useEffect(() => {
        initializeWallets()

        if (globalSelectedWallet) {
            setSelectedWallet(globalSelectedWallet)
        }
    }, [user])

    const isActive = (wallet: any, i: number) => {
        if (selectedWallet === wallet) return true
        if (selectedWallet !== null) return false
        return false
    }

    const save = () => {
        if (!selectedWallet) {
            toast.error("Please select a wallet")
            return
        }

        dispatch(setGlobalSelectedWallet(selectedWallet))
        dispatch(showConnectedWalletSelectPopup(false))

        setLocalData("selectedSolanaWallet", selectedWallet)
    }

    const initializeWallets = async () => {

        if (!user) return

        let wallets: any = copyObject(user.wallets)

        

        for (var i = 0; i < wallets.length; i++) {
            let wallet = findWallet(wallets[i].address)

            if (wallet) {
                let isConnected = await wallet.classInstance.isConnected()

                if (isConnected) {
                    wallets[i].provider = wallet.provider
                    wallets[i].connected = true
                }
            } else {
                wallets[i].connected = false
            }
        }

        setSolanaWallets(wallets.filter((w: any) => w.network === Network.Solana && w.connected))
    }

    return (
        <C.WalletSelect>

            <C.Title>
                Select wallet to use
            </C.Title>

            <C.Wallets>
                {solanaWallets.map((w: any, index: number) => {

                    return (
                        <C.Wallet onClick={() => setSelectedWallet(w.address)} active={isActive(w.address, index) ? "true" : "false"} key={index}>
                            <C.WalletHeader>
                                <C.WalletName>
                                    {w.name}
                                </C.WalletName>
                                <C.WalletAddress>{w.address}</C.WalletAddress>
                            </C.WalletHeader>
                            <C.WalletSelectIcon>
                                {isActive(w.address, index) && <FontAwesomeIcon icon={faCheck} />}
                            </C.WalletSelectIcon>
                        </C.Wallet>
                    )

                })}
            </C.Wallets>

            <Button onClick={save} theme="gradient-border-transparent-bg-on-hover" bg={Hex2Rgba(color.secondaryFade, .5)} color="white">Save</Button>


        </C.WalletSelect>
    )
}

export default ConnectedWalletSelect