import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { PageBody } from "../base/style"

export const MobilePage = styled.div`
& ${PageBody} {
    padding: 0;
}

& ${PageBody} {
}
`