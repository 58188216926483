import Header from "components/parts/header"
import React, { useEffect } from "react"
import SetLocationMap from "components/parts/setLocation"
import Menu from "components/widgets/menu"
import Bar from "components/widgets/bar"
import MapWidgets from "components/widgets/mapWidgets"
import * as P from "./style"
import { closeAll } from "utils/parts"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
const SetLocation = () => {

    const user = useSelector((state: any) => state.user.user)
    const navigate = useNavigate()

    useEffect(() => {
        if (!user) 
            return navigate("/")

        if (user.shareAddress === null)
            return navigate("/")

        closeAll()
    }, [])

    return (
        <P.SetLocation>
            <Menu />
            <Bar/>
            <MapWidgets />
            <SetLocationMap/>
        </P.SetLocation>
    )
}

export default SetLocation