import React, { useState, useEffect } from "react"
import * as C from './style'
import { useDispatch, useSelector } from "react-redux"
import { Hex2Rgba, shortenPublicKey } from "utils/helpers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretRight, faChevronRight, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { toast } from "react-hot-toast"
import { showShuffleHistoryPopup, showShuffleMyTicketsPopup } from "services/slices/popup"
import Button from "components/ui/button"
import { color } from "styles/theme"

import { ParseAwardAddress, ParseCollectionAddress } from "modules/solana/shuffle/helpers"
import { LAMPORTS_PER_SOL } from "@solana/web3.js"
import { getAllNftsByMintList } from "modules/solana/nft"
import axios from "axios"
import { setWidgetRedirect } from "services/slices/shuffle"
import Slider from "react-slick"
import { getMultipleTickets } from "modules/solana/shuffle/raffle"

const ShuffleWinnerWidget = (props: any) => {

    const dispatch = useDispatch()
    const [prizes, setPrizes] = useState<any>([])
    const programId = useSelector((state: any) => state.shuffle.programId)
    const selectedWallet = useSelector((state: any) => state.user.selectedWallet)
    const [loading, setLoading] = useState<boolean>(true)
    const [count, setCount] = useState<number>(0)

    useEffect(() => {
        parseAwards()
    }, [])

    const sliderSettings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "50px",
        slidesToShow: 1,
        arrows: true,
        mobileFirst: true
    }

    const parseAwards = async () => {


        //let pda = props.raffle.account.purchasePDAs.filter((w: any) => w.account.buyer.toBase58() === props.address)
        let mytickets = props.raffle.tickets;

        let winningTickets = props.raffle.account.winningTickets.filter((w: any) => mytickets.includes(w))

        getMultipleTickets(programId, props.raffle.publicKey.toBase58(), winningTickets).then(async (res: any) => {


            let _prizes: any = {}

            let _count = 0

            for (let i = 0; i < res.length; i++) {
                const win = res[i];

                let parsed: any = ParseAwardAddress(props.raffle.account.inventory[win.target].address.toBase58())

                let itemType = props.raffle.account.inventory[win.target].itemType

                if (typeof itemType.token !== "undefined") {
                    parsed.type = "token"
                } else if (typeof itemType.nft !== "undefined") {
                    parsed.type = "nft"
                } else {
                    parsed.type = "unknown"
                }

                if (parsed.type === "nft") {
                    let topush = {
                        ...parsed,
                        address: props.raffle.account.inventory[win.target].address.toBase58(),
                        mint: win.mint.toBase58(),
                        claimed: win.claimed,
                    }

                    let check = ParseCollectionAddress(props.raffle.account.inventory[win.target].address.toBase58())

                    if (check !== null){
                        topush.image = check.image
                        topush.name = check.name
                        topush.symbol = check.symbol
                    }
                    _prizes[win.mint.toBase58()] = topush
                }else{
                    _prizes[win.mint.toBase58()] = {
                        ...parsed,
                        address: props.raffle.account.inventory[win.target].address.toBase58(),
                        mint: win.mint.toBase58(),
                        claimed: win.claimed,
                    }
                }
               

                _count += 1
            }

            _prizes = Object.values(_prizes)

            let mints = _prizes.filter((p: any) => p.type === "nft").map((p: any) => p.mint)
            let nfts: any = await getAllNftsByMintList(mints)

            for (let i = 0; i < nfts.length; i++) {
                for (let j = 0; j < _prizes.length; j++) {
                    if (_prizes[j].mint === nfts[i].mintAddress.toBase58() && (_prizes[j].name === undefined || _prizes[j].name === null || _prizes[j].name === "")) {
                        try {
                            let metadata = await axios.get(nfts[i].uri)
                            _prizes[j].name = metadata.data.name
                            _prizes[j].symbol = metadata.data.name
                            _prizes[j].image = metadata.data.image
                        } catch (err) {
                            _prizes[j].name = "Unknown"
                            _prizes[j].symbol = "Unknown"
                            _prizes[j].image = ""
                        }
                    }
                }
            }

            setPrizes(_prizes)
            setLoading(false)
            setCount(_count)


        }).catch((err: any) => {
            console.log(err)
            toast.error("Error loading winning tickets")
        })

    }

    const viewPopup = () => {
        dispatch(setWidgetRedirect(true))
        dispatch(showShuffleMyTicketsPopup(true))
    }

    if (loading) {
        return (
            <C.Loading>
                <FontAwesomeIcon icon={faSpinner} spin />
            </C.Loading>
        )
    }

    return (
        <C.ShuffleWinnerWidget>

            <C.ShuffleWinningItems>
                <Slider {...sliderSettings}>
                    {prizes.map((w: any, i: number) => (
                        <C.WinnerItem key={i} >
                            <img src={w.image ? w.image : "/images/etc/ghost.png"} alt={w.name} onError={(e: any) => { e.target.onerror = null; e.target.src = "/images/etc/ghost.png" }} />
                        </C.WinnerItem>
                    ))}

                </Slider>
            </C.ShuffleWinningItems>

            <C.ShuffleWinnerText>
                <C.ShuffleWinnerTitle>
                    You WIN {count} {count > 1 ? "Prizes" : "Prize"}!!!
                </C.ShuffleWinnerTitle>
                <C.ShuffleWinnerSubTitle>
                    with {props.raffle.tickets.length} {props.raffle.tickets.length > 1 ? "Tickets" : "Ticket"}
                </C.ShuffleWinnerSubTitle>
                <Button theme="gradient" block="true" color={color.black} bg={Hex2Rgba(color.tertiary, .1)} onClick={viewPopup} fromcolor={color.gradient3.start} tocolor={color.gradient3.end}>
                    See & Claim Your Prizes
                </Button>
            </C.ShuffleWinnerText>



        </C.ShuffleWinnerWidget>
    )

}

export default ShuffleWinnerWidget