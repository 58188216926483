import axios from "axios"
import env from "env"
import { getLocalData, removeLocalData, setLocalData } from "utils/localData"

export const getUsersByAddresses = async (addresses: string) => {

    return axios.post(env.API.URL + "user/actions/bulk-wallet-to-user", {
        wallets: addresses
    })

}

export const getUsersAvatar = async (userId: string) => {

    return axios.get(env.API.URL + "user/profile/avatar-of/" + userId, {
        responseType: 'arraybuffer'
    })

}