import React, { useEffect, useState } from 'react'
import * as C from './style'

import Button from 'components/ui/button'
import { color } from 'styles/theme'
import { copyObject, Hex2Rgba } from 'utils/helpers'
import Badge, { Badges } from "components/ui/badge"
import Collection from 'components/widgets/collection'
import { useSelector, useDispatch } from 'react-redux'
import { addHoldersFilterBadges, removeHoldersFilterBadges, resetFilters, setCurrentViewingCollection, setCurrentViewingToken, setHoldersFilterChain, setHoldersFilters, setHoldersFilterType } from 'services/slices/holders'
import { Chains } from 'models/enums/network'
import { displayMapFilterPopup } from 'utils/parts'
import { ManageHolderStates } from 'services/managers/holders'
import Token from 'components/widgets/token'
import { useNavigate } from 'react-router-dom'

const MapFilter = () => {

    const collections = useSelector((state: any) => state.data.collections)
    const tokens = useSelector((state: any) => state.data.tokens)
    const currentViewingCollection = useSelector((state: any) => state.holders.currentViewingCollection)
    const currentViewingToken = useSelector((state: any) => state.holders.currentViewingToken)
    const currentViewingLaunch = useSelector((state: any) => state.holders.currentViewingLaunch)
    const [collection, setCollection] = useState<any>(null)
    const [token, setToken] = useState<any>(null)

    const filters = useSelector((state: any) => state.holders.filters)

    const [_filters, setFilters] = useState<any>(filters)

    const dispatch = useDispatch()

    useEffect(() => {
        if (currentViewingCollection) {
            setCollection(collections.find((c: any) => c.id == currentViewingCollection))
        } else {
            setCollection(null)
        }
    }, [currentViewingCollection, collections])

    useEffect(() => {
        if (currentViewingToken) {
            setToken(tokens.find((c: any) => c.id == currentViewingToken))
        } else {
            setToken(null)
        }
    }, [currentViewingToken, tokens])

    const setType = (type: string) => {
        //dispatch(setHoldersFilterType(type))
        setFilters({
            ..._filters,
            type: type
        })
    }

    const setChain = (chain: string) => {

        setFilters({
            ..._filters,
            chain: chain
        })

        //dispatch(setHoldersFilterChain(chain))
    }

    const setMarkerType = (type: string) => {

        setFilters({
            ..._filters,
            markerType: type
        })

    }

    const toggleBadge = (badge: string) => {

        let badges = copyObject(_filters.badges)

        if (badges.includes(badge)) {
            badges = badges.filter((b: string) => b != badge)
        } else {
            badges.push(badge)
        }

        setFilters({
            ..._filters,
            badges: badges
        })

        /*if (filters.badges.includes(badge)) {
            dispatch(removeHoldersFilterBadges(badge))
        } else {
            dispatch(addHoldersFilterBadges(badge))
        }*/
    }

    const reset = () => {
        dispatch(resetFilters())
        displayMapFilterPopup(false)
        ManageHolderStates(false, "map filter reset")
    }

    const apply = () => {
        dispatch(setHoldersFilters(_filters))
        ManageHolderStates(false, "map filter applied")
        displayMapFilterPopup(false)
    }

    const navigate = useNavigate()


    return (
        <C.MapFilter>

            {!currentViewingLaunch && (
                <>
                    {collection && (
                        <C.Filter>
                            <C.FilterTitle>
                                <C.FilterTitleText>
                                    Filtered By Collection:
                                </C.FilterTitleText>
                                <Button onClick={() => dispatch(setCurrentViewingCollection(null))} theme="gradient-border-transparent-bg-on-hover" bg={Hex2Rgba(color.white, .05)} color="white">
                                    Clear
                                </Button>
                            </C.FilterTitle>
                            <Collection alert={false} social={true} stats={false} item={collection} />
                        </C.Filter>
                    )}

                    {token && (
                        <C.Filter>
                            <C.FilterTitle>
                                <C.FilterTitleText>
                                    Filtered By Token:
                                </C.FilterTitleText>
                                <Button onClick={() => dispatch(setCurrentViewingToken(null))} theme="gradient-border-transparent-bg-on-hover" bg={Hex2Rgba(color.white, .05)} color="white">
                                    Clear
                                </Button>
                            </C.FilterTitle>
                            <Token alert={false} social={true} stats={false} item={token} />
                        </C.Filter>
                    )}



                    {(!collection && !token) && (
                        <>
                            {/*<C.Filter>
                                <C.FilterTitle>Collections</C.FilterTitle>
                                <C.FilterOptions>
                                    <Button theme={"gradient-border-transparent-bg" + (_filters.type !== "all" ? "-on-hover" : '')} bg={Hex2Rgba(color.secondaryFade, .5)} color="white" onClick={() => setType("all")}>All</Button>
                                    <Button theme={"gradient-border-transparent-bg" + (_filters.type !== "owned" ? "-on-hover" : '')} bg={Hex2Rgba(color.secondaryFade, .5)} color="white" onClick={() => setType("owned")}>Owned</Button>
                                    <Button theme={"gradient-border-transparent-bg" + (_filters.type !== "favorites" ? "-on-hover" : '')} bg={Hex2Rgba(color.secondaryFade, .5)} color="white" onClick={() => setType("favorites")}>Favorites</Button>
                                </C.FilterOptions>
                    </C.Filter>*/}

                            {/*<C.Filter>
                                <C.FilterTitle>Chain</C.FilterTitle>
                                <C.FilterOptions>
                                    <Button theme={"gradient-border-transparent-bg" + (_filters.chain !== "all" ? "-on-hover" : '')} bg={Hex2Rgba(color.secondaryFade, .5)} color="white" onClick={() => setChain("all")}>All</Button>
                                    <Button theme={"gradient-border-transparent-bg" + (_filters.chain !== Chains.solana.name ? "-on-hover" : '')} bg={Hex2Rgba(color.secondaryFade, .5)} color="white" onClick={() => setChain(Chains.solana.name)}> <img src="/images/chains/solana-icon.png" alt="Solana icon" /> Solana</Button>
                                    <Button theme={"gradient-border-transparent-bg" + (_filters.chain !== Chains.aptos.name ? "-on-hover" : '')} bg={Hex2Rgba(color.secondaryFade, .5)} color="white" onClick={() => setChain(Chains.aptos.name)}> <img src="/images/chains/aptos-icon.png" alt="Aptos icon" /> Aptos</Button>
                                </C.FilterOptions>
                    </C.Filter>*/}

                            <C.Filter>
                                <C.FilterTitle>Type</C.FilterTitle>
                                <C.FilterOptions>
                                    <Button theme={"gradient-border-transparent-bg" + (_filters.markerType !== "all" ? "-on-hover" : '')} bg={Hex2Rgba(color.secondaryFade, .5)} color="white" onClick={() => setMarkerType("all")}>All</Button>
                                    <Button theme={"gradient-border-transparent-bg" + (_filters.markerType !== "holder" ? "-on-hover" : '')} bg={Hex2Rgba(color.secondaryFade, .5)} color="white" onClick={() => setMarkerType("holder")}> Users</Button>
                                    <Button theme={"gradient-border-transparent-bg" + (_filters.markerType !== "place" ? "-on-hover" : '')} bg={Hex2Rgba(color.secondaryFade, .5)} color="white" onClick={() => setMarkerType("place")}> Places {/*<C.NewBadge>New</C.NewBadge>*/}</Button>
                                </C.FilterOptions>
                            </C.Filter>

                        </>

                    )}

                </>
            )}

            {!collection && !token && (
                <C.Filter>
                    <Button block="true" theme={"gradient-border-transparent-bg-on-hover"} bg={Hex2Rgba(color.secondaryFade, .5)} color="white" onClick={() => {navigate("/app/communities");displayMapFilterPopup(false);}}>Filter by Communities</Button>
                </C.Filter>
            )}

            <C.FilterBadges>
                <C.FilterTitle>Badges <small>Only list who owns these badges</small></C.FilterTitle>

                <C.FilterBadgesList>
                    {Badges.map((badge, index) => (
                        <Badge className={_filters.badges.includes(badge.value) ? "a" : ""} key={index} type={badge.value} onClick={() => toggleBadge(badge.value)} />
                    ))}
                </C.FilterBadgesList>
            </C.FilterBadges>

            <C.Actions>
                <Button theme="gradient-border-transparent-bg-on-hover" bg={Hex2Rgba(color.secondaryFade, .5)} color="white" onClick={reset}>Reset</Button>
                <Button theme="gradient-border-transparent-bg-on-hover" bg={Hex2Rgba(color.secondaryFade, .5)} color="white" onClick={apply}>Apply</Button>
            </C.Actions>

        </C.MapFilter>
    )
}

export default MapFilter