import Token from "components/widgets/token"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { ManageHolderStates } from "services/managers/holders"
import { setCurrentViewingToken, setCurrentViewingTokenCode } from "services/slices/holders"
import * as C from "./style"

const CurrentToken = () => {

    const dispatch = useDispatch()

    const tokens = useSelector((state: any) => state.data.tokens)
    const currentViewingToken = useSelector((state: any) => state.holders.currentViewingToken)
    const [token, setToken] = useState<any>(null)
    const holders = useSelector((state: any) => state.holders.holders)
    const navigate = useNavigate()
    const holdersCount = useSelector((state: any) => state.holders.count)

    useEffect(() => {
        if (currentViewingToken) {
            setToken(tokens.find((c: any) => c.id == currentViewingToken))
        }
    }, [currentViewingToken, tokens])

    useEffect(() => {

    }, [holders])

    const close = () => {
        dispatch(setCurrentViewingToken(null))
        dispatch(setCurrentViewingTokenCode(null))
        navigate("/app")
    }

    return (
        <C.CurrentToken>
            <C.Header>
                <C.Title>
                    Filtered by Token Owners {holdersCount > 0 ? `(${holdersCount})` : ""}
                </C.Title>
                <C.Times onClick={() => close()}>
                    &times;
                </C.Times>
            </C.Header>
            <C.Item>
                <Token alert={false} social={true} stats={false} item={token} />
            </C.Item>
        </C.CurrentToken>
    )
}

export default CurrentToken