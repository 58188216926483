import { faTwitter, faDiscord } from "@fortawesome/free-brands-svg-icons"
import { faBars, faCaretRight, faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "components/ui/button"
import Logo from "components/ui/logo_home"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Container } from "styles"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"
import { closeAll } from "utils/parts"
import * as P from "./style"

const Home = () => {

    const [menuVisible, setMenuVisible] = useState(false)

    useEffect(() => {
        //add home class to body
        document.body.classList.add("home")
        document.title = "hub3 - Empower the Web3 Experience"

        closeAll()

        return () => {
            //remove home class from body
            document.body.classList.remove("home")
        }


    }, [])

    return (
        <P.Home>
            <P.Header>
                <Container>

                    <P.LogoMobile>
                        <Logo />
                    </P.LogoMobile>

                    <P.Menu>
                        <P.Logo>
                            <Logo />
                        </P.Logo>
                        <Link to="/app">Map</Link>
                        <Link to="/app/shares">Keys</Link>
                        {/*<a  target="_blank" href="https://shuffle.pub/">Shuffle</a>
                        <a target="_blank" href="https://hub3.gitbook.io/hub3.ee/">About</a>*/}
                    </P.Menu>
                    <P.MenuMobile>
                        <FontAwesomeIcon icon={faBars} onClick={() => setMenuVisible(true)} />
                    </P.MenuMobile>
                    <P.Actions>
                        <a href="https://twitter.com/hub3ee" target="_blank">
                            <FontAwesomeIcon icon={faTwitter} />
                        </a>
                        <a href="https://discord.gg/fD5gwNjcZJ" target="_blank">
                            <FontAwesomeIcon icon={faDiscord} />
                        </a>
                        <Button link="routerlink" to="/app" theme="gradient" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)}>Launch App</Button>
                    </P.Actions>
                </Container>
            </P.Header>

            {menuVisible && (
                <P.MobileMenu visible={menuVisible ? "true" : "false"}>
                    <P.MenuTimes onClick={() => setMenuVisible(false)}>&times;</P.MenuTimes>
                    <Link to="/app">Map</Link>
                    <Link to="/app/shares">Shares</Link>
                    {/*<Link to="/app/shuffle">Shuffle</Link>
                    <a target="_blank" href="https://hub3.gitbook.io/hub3.ee/">About</a>*/}
                </P.MobileMenu>
            )}

            <P.Landing>
                <Container>
                    <P.LandingContent>
                        <P.LandingTitle1>
                            Unlock Exclusive Contents
                        </P.LandingTitle1>
                        <P.LandingTitle2>
                        Trade Keys, Connect Directly
                        </P.LandingTitle2>
                        <P.LandingDescription>
                        Join with Twitter, create your key, and dive into a dynamic marketplace. As keys change hands, their value shifts. Hold a key, start a chat.
                        </P.LandingDescription>
                        <P.LandingActions>
                            <Button link="routerlink" to="/app" theme="gradient" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)}>Launch App <FontAwesomeIcon icon={faCaretRight} /> </Button>
                            {/*<Button link="link" href="https://airtable.com/shruF4FqWtuwdn5lb" target="_blank" theme="gradient-border-transparent-bg-on-hover" color={color.white} bg={Hex2Rgba(color.secondaryFade, .5)}>Apply Project </Button>*/}
                        </P.LandingActions>
                    </P.LandingContent>
                </Container>
            </P.Landing>

            <P.Footer>
                <Container>
                    <P.FooterLeft>
                        <Logo />
                    </P.FooterLeft>
                    <P.FooterRight>
                        <a href="/privacy-policy.html" target="_blank">
                            privacy policy
                        </a>
                        <a href="https://twitter.com/hub3ee" target="_blank">
                            <FontAwesomeIcon icon={faTwitter} />
                        </a>
                        <a href="https://discord.gg/fD5gwNjcZJ" target="_blank">
                            <FontAwesomeIcon icon={faDiscord} />
                        </a>
                        <a href="mailto:hello@hub3.ee" target="_blank">
                            <FontAwesomeIcon icon={faEnvelope} />
                        </a>
                    </P.FooterRight>
                </Container>
            </P.Footer>
        </P.Home>
    )
}

export default Home