import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"

export const NftView = styled.div`

`

export const Loading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    font-size: 24px;
`

export const NftImage = styled.div`
    width: 100%;
    & img {
        width: 100%;
        border: 1px solid ${Hex2Rgba(color.primary, 0.1)};
        border-radius: 8px;
    }
`

export const NftInfo = styled.div`

`

export const NftName = styled.div`
    margin-bottom: 5px;
    font-size: 20px;
`

export const NftDescription = styled.div`
    margin-bottom: 10px;
    font-size: 14px;
`

export const Traits = styled.div`
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
`

export const Trait = styled.div`
    flex:1;
    padding: 10px;
    background-color: ${Hex2Rgba(color.primary, 0.1)};
    border-radius: 8px;
    border: 1px solid ${Hex2Rgba(color.primary, 0.2)};
    margin-bottom: 10px;
`

export const TraitName = styled.div`
    font-size: 12px;
    color: ${Hex2Rgba(color.white, 0.8)};
`

export const TraitValue = styled.div`
    
`