import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Collection } from 'models/collection'
import { deviceSize } from 'styles/media'
import { hex2a, ToGeoJson } from 'utils/helpers'
import { WhitelistCollection } from 'models/whitelist/collection'
import { Launch } from 'models/launch'
import { Token } from 'models/token'

export interface DataState {
    collections: Collection[] // Array of collections
    tokens: Token[] // Array of tokens
    whitelistCollections: WhitelistCollection[] // Array of whitelist collections
    launches: Launch[] // Array of launches
    sidebarActive: boolean // Is the sidebar visible?

    isMobile: boolean // Is the app being viewed on a mobile sized screen?
    currentPage: string | null // Current page being viewed

    currentViewingHolder: any // Holder being viewed
    currentViewingNft: any // Nft being viewed
    currentViewingNftList: any // List of nfts being viewed
    firstTime: boolean // is user first time?

    _collectinsLoaded: boolean // Is the collections data loaded?
    _tokensLoaded: boolean // Is the tokens data loaded?
    _wlLoaded: boolean // is whitelist collections loaded?
    _launchesLoaded: boolean // is launches loaded?
    currentViewingHolderWhitelisted: boolean // is holder whitelisted?
    displayGuide: boolean // is guide displayed?
    displayChat: boolean // is guide displayed?
    currentViewingPlace: any // place being viewed
    currentViewingStory: any // story being viewed
    currentViewingTreasure: any // treasure being viewed
    currentViewingProfileMap: any // treasure being viewed
    currentViewingShareUser: any // treasure being viewed

    _walletConnectSettings: any
    loadingPopupText: string
    globalConfettiShowing: boolean,
    walletsChanged: boolean,
}

const initialState: DataState = {
    collections: [],
    tokens: [],
    whitelistCollections: [],
    launches: [],
    sidebarActive: true,
    isMobile: window.innerWidth <= deviceSize.tablet,
    currentPage: null,
    currentViewingHolder: null,
    currentViewingNft: null,

    currentViewingNftList: {},
    firstTime: false,

    _collectinsLoaded: false,

    _wlLoaded: false,
    _tokensLoaded: false,
    _launchesLoaded: false,
    currentViewingHolderWhitelisted:false,
    displayGuide: false,
    displayChat: false,
    currentViewingPlace: null,
    currentViewingStory: null,
    currentViewingTreasure: null,
    currentViewingProfileMap: null,
    currentViewingShareUser: null,
    _walletConnectSettings: {
        network: 'any'
    },
    loadingPopupText: '',
    globalConfettiShowing: false,
    walletsChanged: false
}

export const analyticsSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setCollections: (state, action: PayloadAction<Collection[]>) => {
            state.collections = action.payload
            state._collectinsLoaded = true
        },
        setTokens: (state, action: PayloadAction<Token[]>) => {
            state.tokens = action.payload
            state._tokensLoaded = true
        },
        setWhitelistCollections: (state, action: PayloadAction<any[]>) => {
            state.whitelistCollections = action.payload
            state._wlLoaded = true
        },
        setLaunches: (state, action: PayloadAction<any[]>) => {
            state.launches = action.payload
            state._launchesLoaded = true
        },
        updateWhitelistCollections: (state, action: PayloadAction<any>) => {
            let collection = state.whitelistCollections.find((collection: any) => collection.id === action.payload.id)
            if (collection) {
                state.whitelistCollections = state.whitelistCollections.map((collection: any) => {
                    if (collection.id === action.payload.id) {
                        return action.payload
                    }
                    return collection
                })
            }
        },
        setSidebarActive: (state, action: PayloadAction<boolean>) => {
            state.sidebarActive = action.payload
        },

        setIsMobile: (state, action: PayloadAction<boolean>) => {
            state.isMobile = action.payload
        },
        setCurrentPage: (state, action: PayloadAction<string | null>) => {
            state.currentPage = action.payload
        },

        setCurrentViewingHolder: (state, action: PayloadAction<any>) => {
            state.currentViewingHolder = action.payload
        },
        setCurrentViewingNft: (state, action: PayloadAction<any>) => {
            state.currentViewingNft = action.payload
        },
        decreaseCollectionFavoriteCount: (state, action: PayloadAction<any>) => {
            const collection = state.collections.find((collection) => collection.id === action.payload)
            if (collection) {
                collection.favoriteCount -= 1
            }
        },
        increaseCollectionFavoriteCount: (state, action: PayloadAction<any>) => {
            const collection = state.collections.find((collection) => collection.id === action.payload)
            if (collection) {
                collection.favoriteCount += 1
            }
        },
        setCurrentViewingNftList: (state, action: PayloadAction<any>) => {

            let list = action.payload
            state.currentViewingNftList = {}

            Object.keys(list).sort((a:any, b:any) => {
                return new Date((state.collections.find((collection: any) => collection.id === a) as any).updatedAt) < new Date((state.collections.find((collection: any) => collection.id === b) as any).updatedAt) ? 1 : -1
            }).forEach((key) => {
                state.currentViewingNftList[key] = list[key]
            })
        },
        updateImageOnViewingNftList: (state, action: PayloadAction<any>) => {
            const { id, index, image } = action.payload
            const collection = state.currentViewingNftList[id]
            if (collection) {
                collection[index].image = image
            }
        },
        setFirstTime: (state, action: PayloadAction<boolean>) => {
            state.firstTime = action.payload
        },
        setCurrentViewingHolderWhitelisted: (state, action: PayloadAction<boolean>) => {
            state.currentViewingHolderWhitelisted = action.payload
        },
        setDisplayGuide: (state, action: PayloadAction<boolean>) => {
            state.displayGuide = action.payload
        },
        setDisplayChat: (state, action: PayloadAction<boolean>) => {
            state.displayChat = action.payload
        },
        setCurrentViewingPlace: (state, action: PayloadAction<any>) => {
            state.currentViewingPlace = action.payload
        },
        setCurrentViewingStory: (state, action: PayloadAction<any>) => {
            state.currentViewingStory = action.payload
        },
        setWalletConnectSettings: (state, action: PayloadAction<any>) => {
            state._walletConnectSettings = action.payload
        },
        setLoadingPopupText: (state, action: PayloadAction<string>) => {
            state.loadingPopupText = action.payload
        },
        setGlobalConfettiShowing: (state, action: PayloadAction<boolean>) => {
            state.globalConfettiShowing = action.payload
        },
        setWalletsChanged: (state, action: PayloadAction<boolean>) => {
            state.walletsChanged = action.payload
        },
        setCurrentViewingTreasure: (state, action: PayloadAction<any>) => {
            state.currentViewingTreasure = action.payload
        },
        setCurrentViewingProfileMap: (state, action: PayloadAction<any>) => {
            state.currentViewingProfileMap = action.payload
        },
        setCurrentViewingShareUser: (state, action: PayloadAction<any>) => {
            state.currentViewingShareUser = action.payload
        },
    }
})

export const { setCollections,setWalletsChanged,setCurrentViewingTreasure,setCurrentViewingProfileMap,setCurrentViewingShareUser,setTokens,setGlobalConfettiShowing, setLoadingPopupText,setLaunches,setCurrentViewingStory, setSidebarActive, setWalletConnectSettings, setCurrentViewingPlace, setCurrentViewingHolderWhitelisted, updateWhitelistCollections, setIsMobile, setCurrentPage, setCurrentViewingHolder, setCurrentViewingNft, decreaseCollectionFavoriteCount, increaseCollectionFavoriteCount, setCurrentViewingNftList, setFirstTime, setWhitelistCollections, updateImageOnViewingNftList, setDisplayChat,setDisplayGuide} = analyticsSlice.actions

export default analyticsSlice.reducer