import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { User } from 'models/user'

export interface UserState {
    user : User | null,
    userLoaded : boolean,
    loginNotification : boolean,
    selectedWallet : string,
    cloudWalletBalance:string
}

const initialState: UserState = {
    user : null,
    userLoaded : false,
    loginNotification : false,
    selectedWallet : '',
    cloudWalletBalance:'0'
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<User | null>) => {
            state.user = action.payload
            if (action.payload) {
                state.loginNotification = false
            }
        },
        setIntroduced: (state, action: PayloadAction<any>) => {
            
            if (state.user) {
                state.user.firstTime.intro = action.payload
            }
        },
        setAvatar: (state, action: PayloadAction<string>) => {
            if (state.user)
                state.user.avatar = action.payload
        },
        setCover: (state, action: PayloadAction<string>) => {
            if (state.user)
                state.user.cover = action.payload
        },
        addCollectionToUsersFavorites: (state, action: PayloadAction<string>) => {
            if (state.user)
                state.user.favorites.push(action.payload)
        },
        removeCollectionFromUsersFavorites: (state, action: PayloadAction<string>) => {
            if (state.user)
                state.user.favorites = state.user.favorites.filter((id) => id !== action.payload)
        },
        removeWalletFromUsersWallets: (state, action: PayloadAction<string>) => {
            if (state.user)
                state.user.wallets = state.user.wallets.filter((wallet) => wallet.address !== action.payload)
        },
        setUserLoaded: (state, action: PayloadAction<boolean>) => {
            state.userLoaded = action.payload
        },
        setUserIntroduced: (state, action: PayloadAction<boolean>) => {
            if (state.user)
                state.user.firstTime.intro = action.payload
        },
        setLoginNotification: (state, action: PayloadAction<boolean>) => {
            state.loginNotification = action.payload
        },
        setGlobalSelectedWallet : (state, action: PayloadAction<string>) => {
            state.selectedWallet = action.payload
        },
        setCloudWalletBalance : (state, action: PayloadAction<string>) => {
            state.cloudWalletBalance = action.payload
        }
    }
})

export const { setUser, setAvatar, setCover, setIntroduced, setCloudWalletBalance, setGlobalSelectedWallet, setUserIntroduced, addCollectionToUsersFavorites, removeCollectionFromUsersFavorites, removeWalletFromUsersWallets , setUserLoaded, setLoginNotification} = userSlice.actions

export default userSlice.reducer