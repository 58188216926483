import {
    callCandyGuardRouteBuilder,
    CandyMachine,
    getMerkleProof,
    getMerkleTree,
    IdentitySigner,
    Metadata,
    Metaplex,
    mintFromCandyMachineBuilder,
    Nft,
    NftWithToken,
    PublicKey,
    Sft,
    SftWithToken,
    TransactionBuilder,
    walletAdapterIdentity,
} from "@metaplex-foundation/js";
import { Connection, GetProgramAccountsFilter } from "@solana/web3.js"
import env from "env";
import * as anchor from "@project-serum/anchor";
import { MintLayout, TOKEN_PROGRAM_ID } from '@solana/spl-token';

const SESSION_HASH = 'HUB3' + Math.ceil(Math.random() * 1e9)
export const SOLANA_CONNECTION = new Connection(env.WEB3.SOLANA.RPC_MINT, { commitment: 'finalized', httpHeaders: { "x-session-hash": SESSION_HASH } });
export const SOLANA_CONNECTION_RPC_DEF = new Connection(env.WEB3.SOLANA.RPC, { commitment: 'finalized', httpHeaders: { "x-session-hash": SESSION_HASH } });

export const GetMetaplex = (): Metaplex => {
    return Metaplex.make(SOLANA_CONNECTION)
}

export const GetCandyMachine = async (address: string) => {
    return GetMetaplex().candyMachines().findByAddress({ address: new PublicKey(address) })
}

export const mintCm3 = async (quantity: number, candyMachine: any, wallet: any, groupLabel: string, allowlist: string[] | null) => {

    //console.log("wallet", wallet)

    const mx = Metaplex.make(SOLANA_CONNECTION)
    mx.use(walletAdapterIdentity(wallet))
    const transactionBuilders: TransactionBuilder[] = []

    //console.log(allowlist)

    let allowListGuard = null

    if (allowlist) {
        let proof = getMerkleProof(allowlist, wallet.publicKey.toBase58())
        //console.log(proof)
        allowListGuard = proof;
        transactionBuilders.push(callCandyGuardRouteBuilder(mx, {
            candyMachine,
            guard: 'allowList',
            group: groupLabel,
            settings: {
                path: 'proof',
                merkleProof: proof
            }
        }))
        //console.log(proof)
    }

    for (let i = 0; i < quantity; i++) {
        if (allowlist) {
            transactionBuilders.push(
                await mintFromCandyMachineBuilder(mx, {
                    candyMachine,
                    collectionUpdateAuthority: candyMachine.authorityAddress,
                    group: groupLabel,
                    guards: {
                        allowList: allowListGuard
                    }
                })
            )
        } else {
            transactionBuilders.push(
                await mintFromCandyMachineBuilder(mx, {
                    candyMachine,
                    collectionUpdateAuthority: candyMachine.authorityAddress,
                    group: groupLabel
                })
            )
        }
    }


    const blockhash = await mx.rpc().getLatestBlockhash();

    const transactions = transactionBuilders.map((t) =>
        t.toTransaction(blockhash)
    );

    const signers: { [k: string]: IdentitySigner } = {};
    transactions.forEach((tx, i) => {
        tx.feePayer = wallet.publicKey;
        tx.recentBlockhash = blockhash.blockhash;
        transactionBuilders[i].getSigners().forEach((s) => {
            if ("signAllTransactions" in s) signers[s.publicKey.toString()] = s;
            else if ("secretKey" in s) tx.partialSign(s);
            // @ts-ignore
            else if ("_signer" in s) tx.partialSign(s._signer);
        });
    });

    let signedTransactions = transactions;

    for (let signer in signers) {
        await signers[signer].signAllTransactions(transactions);
    }

    if (allowlist) {
        const allowListCallGuardRouteTx = signedTransactions.shift();
        const allowListCallGuardRouteTxBuilder = transactionBuilders.shift();
        await mx.rpc().sendAndConfirmTransaction(allowListCallGuardRouteTx!, {
            commitment: "processed",
        });
    }

    //console.log(signedTransactions)

    const output = await Promise.all(
        signedTransactions.map((tx, i) => {
            return mx
                .rpc()
                .sendAndConfirmTransaction(tx, { commitment: "finalized" })
                .then((tx) => ({
                    ...tx,
                    context: transactionBuilders[i].getContext() as any,
                }));
        })
    );

    //console.log(output)

    let nfts = await Promise.all(
        output.map(async (x) => {
            //console.log(x)
            const context = x.context
            let n = await mx
                .nfts()
                .findByMint({
                    mintAddress: context.mintSigner.publicKey,
                    tokenAddress: context.tokenAddress,
                })
                .catch((e) => null)

            if (n === null) {
                return null
            } else {
                let json = n.json
                return {
                    json,
                    signature: x.signature
                }
            }
        })
    );

    return Promise.resolve(nfts)


}

export const getTokenBalance = async (wallet: string) => {

    try {

        const filters: GetProgramAccountsFilter[] = [
            {
                dataSize: 165,    //size of account (bytes)
            },
            {
                memcmp: {
                    offset: 32,     //location of our query in the account (bytes)
                    bytes: wallet,  //our search criteria, a base58 encoded string
                }
            }
        ];

        const accounts = await SOLANA_CONNECTION.getParsedProgramAccounts(
            TOKEN_PROGRAM_ID,   //SPL Token Program, new PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA")
            { filters: filters }
        );

        return accounts
    } catch (err: any) {
        console.log(err)
    }

}