import { useEffect } from "react"
import { Routes, Route, useLocation, Navigate } from "react-router-dom"

// pages
import Home from "pages/home"
import App from "pages/app"
import useWindowDimensions from "hooks/useWindowDimensions"
import { deviceSize } from "styles/media"
import { useDispatch, useSelector } from "react-redux"
import { setIsMobile } from "services/slices/data"
import { AutoConnectAllWallets } from "modules/wallet"
import { autoLogin, connectDiscord, loginWithWeb3Auth } from "services/api/auth"
import { getLocalData, removeLocalData, setLocalData } from "utils/localData"
import { setCloudWalletBalance, setLoginNotification, setUser, setUserLoaded } from "services/slices/user"
import { GetHolders, ManageHolderStates } from "services/managers/holders"
import { GetAllCollections, ManageCurrentViewingCollection } from "services/managers/collections"
import { ManageSocket } from "services/managers/socket"
import SetLocation from "pages/app/setLocation"
import { manageDiscord, manageTwitter } from "services/managers/user"
import { GetAllData } from "services/managers/data"
import { setCurrentViewingTokenCode } from "services/slices/holders"
import { ManageCurrentViewingToken } from "services/managers/token"
import { WalletConnectionChecker } from "services/managers/wallet"
import { LAMPORTS_PER_SOL } from "@solana/web3.js"
import BigNumber from "bignumber.js"
import { formatPrice } from "modules/solana/share/helpers"
import { getWalletSolBalance } from "modules/solana/wallet"
import { useWeb3Auth } from "hooks/useWeb3Auth"
import { SolanaWallet } from "@web3auth/solana-provider"
import toast from "react-hot-toast"
import { displayLoginPopup } from "utils/parts"
import { showWelcomePopup } from "services/slices/popup"

export default () => {

    const { windowWidth } = useWindowDimensions()
    const isMobile = useSelector((state: any) => state.data.isMobile)
    const dispatch = useDispatch()

    const user = useSelector((state: any) => state.user.user)
    const currentViewingCollectionCode = useSelector((state: any) => state.holders.currentViewingCollectionCode)
    const currentViewingTokenCode = useSelector((state: any) => state.holders.currentViewingTokenCode)
    const collectionsLoaded = useSelector((state: any) => state.data._collectinsLoaded)

    const { web3auth, setProvider, provider, connected } = useWeb3Auth()

    useEffect(() => {
        if (windowWidth <= deviceSize.tablet) {
            if (!isMobile) {
                dispatch(setIsMobile(true))
            }
        } else {
            if (isMobile) {
                dispatch(setIsMobile(false))
            }
        }
    }, [windowWidth])

    const location = useLocation()

    // scroll to top on route change
    useEffect(() => {
        document!.querySelector('html')!.style.scrollBehavior = 'auto'
        window.scroll({ top: 0 })
        document!.querySelector('html')!.style.scrollBehavior = ''
    }, [location.pathname])

    useEffect(() => {

        const init = async () => {
            if (getLocalData("token") && connected)
                autoLogin().then((res: any) => {

                    // auto connect all wallets if they are connected before
                    AutoConnectAllWallets().then(() => {
                        dispatch(setUser(res))
                        dispatch(setUserLoaded(true))

                        // connect discord if url has code
                        manageDiscord()
                    })

                    getWalletSolBalance(res.cloudWallet).then((res: any) => {
                        dispatch(setCloudWalletBalance(
                            formatPrice(new BigNumber(res).div(LAMPORTS_PER_SOL))
                        ))
                    })

                }).catch((err: any) => {
                    //console.log(err)
                    dispatch(setUserLoaded(true))
                    web3auth?.logout().then(() => {
                        localStorage.removeItem("openlogin_store")
                        localStorage.removeItem("Web3Auth-cachedAdapter")
                        window.location.reload()
                    })

                    //if axios error 401
                    if (err.response && err.response.status === 401) {
                        dispatch(setLoginNotification(true))
                    }
                })
            else if (!getLocalData("token") && connected) {

                let loading = toast.loading("Logging in...")

                const user = await web3auth!.getUserInfo();

                // parse the idToken from the user object
                const base64Url = user?.idToken!.split(".")[1];
                const base64 = base64Url.replace("-", "+").replace("_", "/");
                const parsedToken = JSON.parse(window.atob(base64));

                const solanaWallet = new SolanaWallet(provider!);
                const acc = await solanaWallet.requestAccounts();

                loginWithWeb3Auth(parsedToken.wallets[0].public_key, acc[0], user?.idToken!).then(async (res) => {
                    setLocalData("token", res.data.token)
                    dispatch(setUser(res.data.user))
                    dispatch(setUserLoaded(true))
                    console.log(res.data.user)
                    if (res.data.user.firstTime.firstTime)
                        dispatch(showWelcomePopup(true))

                    getWalletSolBalance(res.data.user.cloudWallet).then((res: any) => {
                        dispatch(setCloudWalletBalance(
                            formatPrice(new BigNumber(res).div(LAMPORTS_PER_SOL))
                        ))
                    })

                    displayLoginPopup(false)
                    toast.success("Login successful")
                }).catch((err) => {
                    console.log(err)
                    toast.error("Login failed")
                }).finally(() => {
                    toast.dismiss(loading)
                })
            }
            else {
                dispatch(setUserLoaded(true))
                dispatch(setLoginNotification(true))
                removeLocalData("token")
            }

            //Get holders
            //GetHolders("all")
            //ManageHolderStates()

            //Get collections, whitelistCollections
            GetAllData()

            //Manage socket
            ManageSocket()

            //Manage connected wallets
            //WalletConnectionChecker()

        }

        init()
    }, [])

    useEffect(() => {

        if (collectionsLoaded && currentViewingCollectionCode)
            ManageCurrentViewingCollection()

        if (collectionsLoaded && currentViewingTokenCode)
            ManageCurrentViewingToken()

        //ManageHolderStates(true)

    }, [currentViewingCollectionCode, currentViewingTokenCode, collectionsLoaded])

    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/app" element={<App target="" />} />
            <Route path="/app/communities" element={<App target="collections" />} />
            <Route path="/app/collections/:name" element={<App target="collection" />} />
            <Route path="/app/tokens/:name" element={<App target="token" />} />
            {/*<Route path="/app/whitelist" element={<App target="whitelistCollections" />} />
            <Route path="/app/whitelist/:name" element={<App target="whitelist" />} />*/}
            {/*<Route path="/app/launchmap" element={<App target="launches" />} />
            <Route path="/app/launchmap/:name" element={<App target="launch" />} />*/}
            <Route path="/app/settings" element={<App target="settings" />} />
            <Route path="/app/myprofile" element={<App target="myprofile" />} />
            <Route path="/app/chat" element={<App target="chat" />} />
            <Route path="/app/share/:userId" element={<App target="share" />} />
            <Route path="/app/shares" element={<App target="shares" />} />
            <Route path="/app/profile" element={<App target="profile" />} />
            <Route path="/app/profile/:userId" element={<App target="profile-view" />} />
            <Route path="/app/map" element={<Navigate to={"/app"} />} />
            <Route path="/app/set-location" element={<SetLocation />} />
            {/*<Route path="/app/shuffle" element={<App target="shuffle" />} />
            <Route path="/app/shuffle/liquidation" element={<App target="shuffle-liquidation" />} />*/}
            <Route path="*" element={<Navigate to={"/"} />} />
        </Routes>
    )

}